import { Component, OnInit } from '@angular/core';
import {Chart} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {ActivatedRoute, Router} from '@angular/router';
import { StatsService } from 'src/app/shared/services/stats.service';
@Component({
  selector: 'app-closed-requests-and-pending-requests-by-theme-and-month',
  templateUrl: './closed-requests-and-pending-requests-by-theme-and-month.component.html',
  styleUrls: ['./closed-requests-and-pending-requests-by-theme-and-month.component.scss']
})
export class ClosedRequestsAndPendingRequestsByThemeAndMonthComponent implements OnInit {

  public chartTotalRequest2: any;
  public chartStatusRequest2: any;
  currentYear = new Date().getFullYear();
  lastYear = this.currentYear - 1;
  requestsLast: any;
  requests: any;
  chartData: any;
  chartDataYear: any;
  monthName : any;
  theme!: any [];
  stock1!: any [];
  stock1Year!: any [];
  idMonth!: any;
  idUser!: any;
  token!: any;
  idWorkGroup!: any;

  stock2!: any [];
  stock2Year!: any [];

  themeYear!: any [];

  labels: any[]=[];
  color1 = ['#0e6d0f'];
  color2= ['#940000'];
  grafico: any;
  public loading = true;
  public loading2 = true;

  constructor(private router:Router, private statsService: StatsService, private activeRoute: ActivatedRoute){

  }

  ngOnInit(): void {
    this.loading = true;

    Promise.all([ this.getCountByMonth()])
      .then(() => {
                this.loading = false;
      })
      .catch(error => {
        console.error('Error while loading data:', error);
        this.loading = false; // asegurarse de quitar el loader en caso de error
      });
  }

  getCountByMonth() {
    return new Promise((resolve, reject) => {
      this.activeRoute.params.subscribe((routeParams) => {
        console.log(routeParams);
        this.statsService
          .getCountByMonth(routeParams['idWorkGroup'], routeParams['month'])
          .subscribe((data) => {
            console.log(data);
            const theme = [];
            const stock1 = [];
            const stock2 = [];
            const monthNames = [
              'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
              'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
            ];

            const monthNumber = parseInt(routeParams['month']) - 1;
            this.monthName = monthNames[monthNumber].charAt(0).toUpperCase() + monthNames[monthNumber].slice(1);


            console.log(this.monthName); // "mayo"
            for (var i in data) {
              theme.push(data[i].themeName);
              stock1.push(data[i].requests.openRequests);
              stock2.push(data[i].requests.closedRequests);
            }
            console.log(theme);
            console.log(stock1);
            console.log(stock2);

            const chartData = {
              labels: theme,
              datasets: [
                {
                  label: 'Metas cumplidas',
                  backgroundColor: this.color1,
                  data: stock2,
                },
                {
                  label: 'Solicitudes',
                  backgroundColor: this.color2,
                  data: stock1,
                },
              ],
            };
            const dataValues = stock2;

            const maxDataValue = Math.max(...dataValues); // Valor máximo de los datos

            // Calcular el valor máximo deseado en el eje x (agregar un cuadro adicional)
            const maxXValue = Math.ceil(maxDataValue / 2) * 2 + 2;
            this.chartTotalRequest2 = new Chart("StatTotalRequest", {
              type: 'bar',
              data: chartData,
              plugins: [ChartDataLabels],
              options: {
                indexAxis: 'y',
                aspectRatio:2,
                plugins: {
                  legend: {
                    position: 'bottom'
                  },
                  datalabels: {
                    color: 'black',
                    anchor: 'end',
                    align: 'end',
                    labels: {
                      title: {
                        font: {
                          weight: 'bold',
                          size: 17,
                        }
                      },

                    }
                  }

                },
                scales: {
                  x: {
                    min: 0, // Valor mínimo deseado en el eje x
                    max: maxXValue, // Valor máximo deseado en el eje x
                  }
                },
                onClick: (e) => {
                  this.router.navigate(['requests-subthemes']);
                }
              }
            });

            resolve(true);
          });
      });
    });
  }





}
