import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreatePointComponent } from './create-point/create-point.component';
import { ListPointsComponent } from './list-points/list-points.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { POIComponent } from './poi/poi.component';
import { MatStepperModule } from '@angular/material/stepper';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { FormMapComponent } from '../components/map/form-map/form-map.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { OrderModule } from '../components/form/order/order.module';
import { FormMapModule } from '../components/map/form-map/form-map.module';
import { S3UploaderModule } from '../components/s3-uploader/s3-uploader.module';
import { RequestsPipe } from 'src/app/shared/pipes/requests.pipe';
import { POIPipe } from 'src/app/shared/pipes/poi.pipe';



@NgModule({
  declarations: [
    CreatePointComponent,
    ListPointsComponent,
    POIComponent,
    POIPipe
  ],
  imports: [
    CommonModule,
    NgxPaginationModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    NgbDropdownModule,
    MatAutocompleteModule,
    OrderModule,
    FormMapModule,
   //S3UploaderModule
  ],
  exports: [ListPointsComponent],

})
export class InterestPointModule { }
