
<div>

    <h2>Solicitudes y metas cumplidas por temas</h2>
    <div >
        <canvas id="ChartStatusRequest">{{ grafico }}</canvas>
    </div>
</div>


