import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormDataService } from 'src/app/shared/services/form-data.service';
import { CustomeValidationService } from 'src/app/shared/services/custome-validation.service';

@Component({
  selector: 'app-request-edit-documentation',
  templateUrl: './request-edit-documentation.component.html',
  styleUrls: ['./request-edit-documentation.component.scss']
})
export class RequestEditDocumentationComponent implements OnInit {

  documentationForm: FormGroup = this.FB.group({
    docIne: [''],
    docAcuse: [''],
    docExtra: [''],

    docCertificate: [''],
    docPassport: [''],
    docProof: [''],
    docCurp: [''],
    docRfc: [''],
    docOthers: [''],
    docWhich: [''],
    propertyTax: [''],
    bitacora: [''],
  });

  selectedFileINE: any = [];
  selectedFileAcuse: any = [];
  selectedFileExtra: any = [];

  //fileIne: any;

  AllUrlIne: any = [];
  AllUrlAcuse: any = [];
  AllUrlExtra: any = [];


  constructor(private FB: FormBuilder, private formDataService: FormDataService, private CustomeValidationService: CustomeValidationService) {
    this.getRequestData();
  }

  ngOnInit(): void {
    this.documentationForm.valueChanges.subscribe(data => {
      this.formDataService.setRequestDocumentationFormData(this.documentationForm, this.selectedFileINE, this.AllUrlIne, this.selectedFileAcuse, this.AllUrlAcuse, this.selectedFileExtra, this.AllUrlExtra);
    });
  }

  getFileName(url: string): string {
    const parts = url.split('/');
    return decodeURIComponent(parts[parts.length - 1]);
  }


  removeFile(url: string, type: string) {

    switch (type) {
      case 'INE':
        this.AllUrlIne = this.AllUrlIne.filter((item: any) => item !== url);

        break;
      case 'Acuse':
        this.AllUrlAcuse = this.AllUrlAcuse.filter((item: any) => item !== url);

        break;
      case 'Extra':
        this.AllUrlExtra = this.AllUrlExtra.filter((item: any) => item !== url);

        break;
    }




    this.formDataService.setRequestDocumentationFormData(this.documentationForm, this.selectedFileINE, this.AllUrlIne, this.selectedFileAcuse, this.AllUrlAcuse, this.selectedFileExtra, this.AllUrlExtra);
  }

  getRequestData() {
    this.formDataService.requestDataResults$.subscribe(data => {
      console.log(data);

      this.AllUrlIne = this.CustomeValidationService.checkForServerLocation(data.url_image_ine)
      this.AllUrlAcuse = this.CustomeValidationService.checkForServerLocation(data.url_image_Acuse)
      this.AllUrlExtra = this.CustomeValidationService.checkForServerLocation(data.url_image_Extra)


      console.log(this.AllUrlIne);

      this.documentationForm.patchValue({
        docIne: data.docIne,
        url_image_ine: data.url_image_ine,
        docCertificate: data.docCertificate,
        docPassport: data.docPassport,
        docProof: data.docProof,
        docCurp: data.docCurp,
        docRfc: data.docRfc,
        docOthers: data.docOthers,
        docWhich: data.docWhich,
        propertyTax: data.propertyTax,
        bitacora: data.bitacora,
        docAcuse: data.docAcuse,
        docExtra: data.docExtra,

      });
    });
  }

  openImage(url: string) {
    window.open(url, '_blank');
  }

  updateCheckbox(event: Event, type: string) {
    if (event && event.target instanceof HTMLInputElement) {
      if (type == 'Ine')
        this.documentationForm.get('docIne')?.setValue(event.target.checked ? 1 : null);
      if (type == 'Certificate')
        this.documentationForm.get('docCertificate')?.setValue(event.target.checked ? 1 : null);
      if (type == 'Passport')
        this.documentationForm.get('docPassport')?.setValue(event.target.checked ? 1 : null);
      if (type == 'Proof')
        this.documentationForm.get('docProof')?.setValue(event.target.checked ? 1 : null);
      if (type == 'Curp')
        this.documentationForm.get('docCurp')?.setValue(event.target.checked ? 1 : null);
      if (type == 'Rfc')
        this.documentationForm.get('docRfc')?.setValue(event.target.checked ? 1 : null);
      if (type == 'Which')
        this.documentationForm.get('docWhich')?.setValue(event.target.checked ? 1 : null);
      if (type == 'Property')
        this.documentationForm.get('propertyTax')?.setValue(event.target.checked ? 1 : null);
      if (type == 'Acuse')
        this.documentationForm.get('docAcuse')?.setValue(event.target.checked ? 1 : null);
      if (type == 'Extra')
        this.documentationForm.get('docExtra')?.setValue(event.target.checked ? 1 : null);
    }
  }

  onFileChange(event: any) {
    const files: FileList = event.target.files;

    for (let i = 0; i < files.length; i++) {
      this.selectedFileINE.push({ file: files[i] });
    }
    console.log(this.selectedFileINE);
  }

  onFileAcuseChange(event: any) {
    const files: FileList = event.target.files;

    for (let i = 0; i < files.length; i++) {
      this.selectedFileAcuse.push({ file: files[i] });
    }
    console.log(this.selectedFileAcuse);
  }

  onFileExtraChange(event: any) {
    const files: FileList = event.target.files;

    for (let i = 0; i < files.length; i++) {
      this.selectedFileExtra.push({ file: files[i] });
    }
    console.log(this.selectedFileExtra);
  }

}
