<div class="contenedor">

    <ng-container *ngIf="IdPOI == null; else update">
        <h2 class="page-header">
            <p>Crear un punto de interés</p>
            <hr class="mb-5">

        </h2>
    </ng-container>
    <ng-template #update>
        <h2 class="page-header">
            <div class="back btn btn-sm mt-3 ml-3 mb-2">
                <i class="fa fa-arrow-left fa-lg" style="cursor: pointer;" (click)="goBack()" aria-hidden="true"></i>
            </div>
            <p>
                Editar un punto de interés
            </p>
            <hr class="mb-5">
        </h2>
    </ng-template>



    <div class="row tHeader" style="margin: 0 !important; ">
        <div class="col-6 tab">
            <div class="sectionForm">
                <div>
                    <span class="info">Agregar datos</span>
                </div>
            </div>
        </div>
    </div>

    <div class="panel panel-white">
        <div class="" id="winModal1-tab-0">
            <form class="form-validate" [formGroup]="POIForm">

                <div class="row">
                    <div class="row ml-2 mb-0">
                        <div class="col-4 form-group">
                            <label class="label-tittle col-md-12 ">
                                <span class="reqForm">*</span>
                                Tipo:
                            </label>
                            <div class="col-md-12 pl-0">
                                <select class="form-control" formControlName="type" (change)="getSelectedSkill()">
                                    <option selected value="">Selecciona una opción</option>
                                    <option *ngFor="let type of types" value="{{type.id}}">{{type.name}}</option>
                                    <option value="6">OTRO</option>
                                </select>
                                <div class="invalid-feedback">
                                    Seleccione un tipo válido.
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <label>Periodo:</label>
                            <select class="form-control" formControlName="period">
                                <option selected value="">Selecciona una opción</option>
                                <option *ngFor="let period of periodOptions" value="{{period.value}}">{{period.name}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="row ml-2 mb-5" *ngIf="POIForm.controls['type'].value === '6'">
                        <div class="col-4">
                            <label class="label-tittle col-md-12 ">¿Cuál?:</label>
                            <div class="col-md-12 pl-0">
                                <input type="text" formControlName="otherType" placeholder="Nombre del punto de interés"
                                    class="form-control">
                            </div>
                        </div>
                    </div>

                    <div class="row ml-2 mb-2">
                        <div class="col-md-4">
                            <div>
                                <label class="label-tittle col-md-12 ">
                                    <span class="reqForm">*</span>
                                    Nombre:
                                </label>
                                <div class="col-md-12 pl-0  has-validation">
                                    <input type="text" formControlName="name" placeholder="Nombre del punto de interés"
                                        class="form-control "
                                        [ngClass]="{'is-invalid': POIForm.controls['name'].invalid && POIForm.controls['name'].touched}"
                                        aria-describedby="inputGroupPrepend3 validationServerUsernameFeedback">
                                    <div id="validationServerUsernameFeedback" class="invalid-feedback">
                                        Ingrese un nombre válido.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div>
                                <label class="label-tittle col-md-12 "> Correo electrónico:
                                </label>
                                <!-- <div class="col-md-12 pl-0 has-validation">
                                    <input type="email" formControlName="email"
                                        [ngClass]="{'is-invalid': POIForm.controls['email'].invalid && POIForm.controls['email'].touched}"
                                        placeholder="Correo relacionado al punto" class="form-control">
                                    <div id="validationServerUsernameFeedback" class="invalid-feedback">
                                        Ingrese un correo electrónico válido.
                                    </div>
                                </div> -->

                                <div class="col-md-12 pl-0 has-validation">
                                    <input type="email" formControlName="email"
                                        placeholder="Correo relacionado al punto" class="form-control">

                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div>
                                <label class="label-tittle col-md-12 ">
                                    Teléfono:
                                </label>
                                <!-- <div class="col-md-12 pl-0">
                                    <input type="number" formControlName="tel"
                                        placeholder="Número de teléfono relacionado al punto" class="form-control"
                                        [ngClass]="{'is-invalid': POIForm.controls['tel'].invalid && POIForm.controls['tel'].touched}">
                                    <div id="validationServerUsernameFeedback" class="invalid-feedback">
                                        Ingrese un número de teléfono válido.
                                    </div>
                                </div> -->

                                <div class="col-md-12 pl-0">
                                    <input type="text" minlength="10" maxlength="10" formControlName="tel"
                                        placeholder="Número de teléfono relacionado al punto" class="form-control">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="panel-info padding ">
                    <p class="divData">Dirección</p>
                </div>
                <div class="row">
                    <div class="row ml-2">
                        <!-- <div class="col-md-4">
                            <label class="label-tittle col-md-12">
                                Calle:</label>
                            <div class="col-md-12 pl-0">
                                <input type="text" formControlName="street" placeholder="Calle del punto"
                                    class="form-control"
                                    [ngClass]="{'is-invalid': POIForm.controls['street'].invalid && POIForm.controls['street'].touched}"
                                    aria-describedby="inputGroupPrepend3 validationServerUsernameFeedback">
                                <div id="validationServerUsernameFeedback" class="invalid-feedback">
                                    Ingrese una calle válida.
                                </div>
                            </div>
                        </div> -->

                        <div class="col-md-4">
                            <label class="label-tittle col-md-12">
                                Calle:</label>
                            <div class="col-md-12 pl-0">
                                <input type="text" formControlName="street" placeholder="Calle del punto"
                                    class="form-control"
                                    aria-describedby="inputGroupPrepend3 validationServerUsernameFeedback">

                            </div>
                        </div>

                        <!-- <div class="col-md-4">
                            <div>
                                <label class="label-tittle col-md-12 ">
                                    Número exterior:
                                </label>
                                <div class="col-md-12 pl-0">

                                    <input type="number" formControlName="streetNumExt"
                                        placeholder="Ingrese el número exterior" class="form-control"
                                        [ngClass]="{'is-invalid': POIForm.controls['streetNumExt'].invalid && POIForm.controls['streetNumExt'].touched}"
                                        aria-describedby="inputGroupPrepend3 validationServerUsernameFeedback">
                                    <div id="validationServerUsernameFeedback" class="invalid-feedback">
                                        Ingrese un número interior válido.
                                    </div>
                                </div>
                            </div>
                        </div> -->

                        <div class="col-md-4">
                            <div>
                                <label class="label-tittle col-md-12 ">
                                    Número exterior:
                                </label>
                                <div class="col-md-12 pl-0">
                                    <input type="text" formControlName="streetNumExt"
                                        placeholder="Ingrese el número exterior" class="form-control"
                                        aria-describedby="inputGroupPrepend3 validationServerUsernameFeedback">
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div>
                                <label class="label-tittle col-md-12">Número interior:
                                </label>
                                <div class="col-md-12 pl-0">
                                    <input type="text" formControlName="streetNumInt"
                                        placeholder="Ingrese el número interior" class="form-control"
                                        [ngClass]="{'is-invalid': POIForm.controls['streetNumInt'].invalid && POIForm.controls['streetNumInt'].touched}"
                                        aria-describedby="inputGroupPrepend3 validationServerUsernameFeedback">
                                    <div id="validationServerUsernameFeedback" class="invalid-feedback">
                                        Please choose a username.
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>

                <div class="row">

                    <div class="row ml-2">

                        <div class="col-md-4">


                            <div *ngIf="postalCodeSelected == false else selectedPostal">
                                <label class="label-tittle col-md-12">Código postal:</label>
                                <div class=" col-md-12 pl-0">
                                    <div class="input-group">
                                        <input type="number" formControlName="postalCode"
                                            placeholder="Código postal del punto" class="form-control"
                                            [ngClass]="{'is-invalid': POIForm.controls['postalCode'].invalid && POIForm.controls['postalCode'].touched}">
                                        <div class="input-group-prepend">
                                            <button class="btn btn-outline-secondary" type="button"
                                                (click)="searchPostalCode()">
                                                <i class="fa fa-search" aria-hidden="true"></i>
                                            </button>
                                        </div>
                                    </div>

                                    <ng-container #menu
                                        *ngIf="postalCodeSearch.length != 0 && postalCodeSelected == false">

                                        <div
                                            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 content-seach contenedorSearchbar">

                                            <ng-container *ngIf="postalCodeSearch.length == 0 else searchMenu">
                                                <label class="darkGray">No hay registros</label>
                                            </ng-container>

                                            <ng-template #searchMenu>

                                                <ng-container *ngFor="let result of postalCodeSearch; let i = index">
                                                    <ng-container>
                                                        <label (click)="selectPostalCode(result.identifier)"
                                                            [ngClass]=" i%2==0 ? 'darkGray' : 'lowGray'">
                                                            {{result.identifier}}
                                                        </label>
                                                    </ng-container>
                                                </ng-container>

                                                <br>
                                            </ng-template>

                                        </div>
                                    </ng-container>

                                </div>




                            </div>
                            <ng-template #selectedPostal>
                                <div class="alert fade show mt-3 mb-0">
                                    <span class="close" (click)="clearPostalCode()" data-dismiss="alert">×</span><span
                                        style="color: #707478;">{{POIForm.controls['postalCode'].value}}</span>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>

                <div class="row">

                    <div class="row ml-2">




                        <div class="col-md-4">
                            <div>
                                <label class="label-tittle col-md-12 ">Colonia:
                                </label>
                                <div class="col-md-12 pl-0">
                                    <select class="form-control" formControlName="neighborhood"
                                        [ngClass]="{'is-invalid': POIForm.controls['neighborhood'].invalid && POIForm.controls['neighborhood'].touched}">
                                        <option selected value="">Cargando listado...</option>

                                        <option *ngFor="let neighborhoodOption of neighborhoodOptions"
                                            value="{{neighborhoodOption.id}}">
                                            {{neighborhoodOption.name}}</option>

                                    </select>
                                    <div id="validationServerUsernameFeedback" class="invalid-feedback">
                                        Por favor selecciona un vecindario
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="col-md-4">
                            <div>
                                <label class="label-tittle col-md-12">Municipio:
                                </label>
                                <div class="col-md-12 pl-0">
                                    <input class="form-control" placeholder="Cargando listado..." type="text" disabled
                                        [value]="municipalitieName" />
                                    <div id="validationServerUsernameFeedback" class="invalid-feedback">
                                        Please choose a username.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <label class="label-tittle col-md-12">Estado:</label>
                            <div class="col-md-12 pl-0">
                                <input type="text" class="form-control" placeholder="Cargando listado..." disabled
                                    [value]="entitieName" />

                                <div id="validationServerUsernameFeedback" class="invalid-feedback">
                                    Please choose a username.
                                </div>
                            </div>
                        </div>


                    </div>

                    <div class="row ml-2">

                        <div class="col-md-4">
                            <div>
                                <label class="label-tittle col-md-12">Referencia:
                                </label>
                                <div class="col-md-12 pl-0">
                                    <input type="text" (input)="onNameInput($event)" formControlName="reference"
                                        placeholder="Ingrese referencia al domicilio" class="form-control"
                                        [ngClass]="{'is-invalid': POIForm.controls['reference'].invalid && POIForm.controls['reference'].touched}"
                                        aria-describedby="inputGroupPrepend3 validationServerUsernameFeedback">
                                    <div id="validationServerUsernameFeedback" class="invalid-feedback">
                                        Please choose a username.
                                    </div>

                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                <div class="row mt-3" *ngIf="IdPOI != null && long != null;">
                    <app-form-map [lat]="lat" [lon]="long"></app-form-map>
                </div>

            </form>
        </div>

        <div>
            <ng-container *ngIf="IdPOI == null; else elseTemplate">

                <div class="row ">
                    <div class="offset-md-10 col-md-2 align-items-end">
                        <button type="submit" class="btn btn-primary btn-lg btn-block" (click)="savePOI()">Guardar</button>
                    </div>
                </div>

            </ng-container>
            <ng-template #elseTemplate>
                <div class="row justify-content-end pt-3">
                    <div class="offset-md-10 col-md-2 align-items-end">
                        <button type="submit" class="btn btn-primary btn-lg btn-block" (click)="updatePOI()">Guardar</button>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
    <!-- <div class="footer-form  ">

        <ng-container *ngIf="IdPOI == null; else elseTemplate">
            <div class="row">
                <div class="">
                    <div class="offset-md-8 col-md-4">
                        <div>
                            <div class="col-md-12">
                                <button type="submit" class="btn btn-primary btn-lg btn-block"
                                    [disabled]="!POIForm.valid" (click)="savePOI()">Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #elseTemplate>
            <div class="row">
                <div class="">
                    <div class="offset-md-8 col-md-4">
                        <div>
                            <div class="col-md-12">
                                <button type="submit" class="btn btn-primary btn-lg btn-block"
                                    [disabled]="!POIForm.valid" (click)="updatePOI()">Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>

    </div> -->



</div>
