import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormDataService } from './form-data.service';

@Injectable({
  providedIn: 'root'
})
export class PromotedService {
  url = environment.apiUrl;

  constructor(public http: HttpClient, public formDataService: FormDataService) { }

  getPromoted(idPromoted: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get(this.url + 'promoted/' + idPromoted, options);
  }

  getVisitData(idPromoted: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get(this.url + 'promoted/visits/' + idPromoted, options);
  }

  promotedVoted(idPromoted: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.put(this.url + 'promoted/voted/' + idPromoted, options);
  }

  getOtherVisitsData(idPromoted: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get(this.url + 'promoted/otherVisits/' + idPromoted, options);
  }

  getHistoryData(idPromoted: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get(this.url + 'promoted/history/' + idPromoted, options);
  }

  getPromoters(page: number, itemsPerPage: number, idWorkGroup: string, userLevel: string, idUser: string) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get(this.url + 'promoted/' + page + '/' + itemsPerPage + '/' + idWorkGroup + '/' + userLevel + '/' + idUser, options);
  }

  getSearchPromoted(searchTerm: string | null, p: any, itemsPerPage: any,userLevel: string, idUser: string) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get(this.url + 'promoted/search/' + p + '/' + itemsPerPage + '/' + searchTerm + '/' + localStorage.getItem('idWorkGroup') + '/' + userLevel + '/' + idUser, options);
  }

  saveVisit(visitData: any) {
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return this.http.post<any>(this.url + 'promoted/visit/store', visitData, options);
  }

  saveHistory(historyData: any) {
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return this.http.post<any>(this.url + 'promoted/history/store', historyData, options);
  }

  savePromoted(promotedData: any ) {
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return this.http.post<any>(this.url + 'promoted/store', promotedData, options);
  }

  updatePromoted(promotedData: any, idPromoted: any) {
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return this.http.put<any>(this.url + 'promoted/update/' + idPromoted, promotedData, options);
  }
}
