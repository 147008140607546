import { Component, OnInit } from '@angular/core';
import {Chart, registerables } from 'chart.js'
import {ActivatedRoute, Router} from '@angular/router';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { StatsService } from 'src/app/shared/services/stats.service';

@Component({
  selector: 'app-applicants-and-beneficiaries-by-vulnerable-group',
  templateUrl: './applicants-and-beneficiaries-by-vulnerable-group.component.html',
  styleUrls: ['./applicants-and-beneficiaries-by-vulnerable-group.component.scss']
})
export class ApplicantsAndBeneficiariesByVulnerableGroupComponent implements OnInit {

  public chartTotalRequest2: any;
  public chartStatusRequest2: any;
  currentYear = new Date().getFullYear();
  lastYear = this.currentYear - 1;
  requestsLast: any;
  requests: any;
  chartData: any;
  chartDataYear: any;

  theme!: any [];
  stockLastYear: any ;
  stockYear: any ;


  grafico: any;
  public loading = true;
  public loading2 = true;

  constructor(private router:Router, private statsService: StatsService, private activeRoute: ActivatedRoute){

  }

  ngOnInit(): void {


    this.activeRoute.params.subscribe(routeParams => {
      localStorage.setItem('token', routeParams['token'])
    });

    this.loading = true;

    Promise.all([this.getApplicantsByVulnerableGroup(), this.getBeneficiaryByVulnerableGroup()])
      .then(() => {

    this.StatusRequestChartTheme2();
    this.totalRequestChartTheme2();
        this.loading = false;
      })
      .catch(error => {
        console.error('Error while loading data:', error);
        this.loading = false; // asegurarse de quitar el loader en caso de error
      });
  }
  getApplicantsByVulnerableGroup(){
    const promise = new Promise((resolve, reject) => {
      this.activeRoute.params.subscribe(routeParams => {
      this.statsService.getApplicantsByVulnerableGroup(routeParams['idWorkGroup']).subscribe(data => {
          console.log(data);
          this.stockLastYear=data;
          console.log(this.stockLastYear);
          resolve(true);

        }
        )
      });
    });

    return promise;
  }

  getBeneficiaryByVulnerableGroup(){
    const promise = new Promise((resolve, reject) => {
      this.activeRoute.params.subscribe(routeParams => {
      this.statsService.getBeneficiaryByVulnerableGroup(routeParams['idWorkGroup']).subscribe(data => {
          console.log(data);
        this.stockYear = data;

          resolve(true);

        }
        )
      });
    });
    return promise;
  }

  StatusRequestChartTheme2() {

    this.chartStatusRequest2 = new Chart("StatStatusRequest", {
      type: 'bar',
      data: {
        labels: ['Sin información', 'Victimas de maltrato / abuso', 'Grupos indigenas u originarios', 'Personas en pobreza extrema', 'Personas adultas mayores', 'Personas LGBTTTIQA+', 'Migrantes/Refugiados*', 'Personas con discapacidad', 'Madres/ padres solteras (os)', 'Mujeres', 'Niñas, niños y adolescentes'],
        datasets: [
          {
            data: [this.stockLastYear.sin_definir, this.stockLastYear.results['Víctimas de maltrato/abuso'], this.stockLastYear.results['Grupos indígenas u originarios'], this.stockLastYear.results['Personas en pobreza extrema'], this.stockLastYear.results['Personas adultas mayores'], this.stockLastYear.results['Personas LGBTTTIQA+'],this.stockLastYear.results['Migrantes, refugiados y solicitantes de asilo'],this.stockLastYear.results['Personas con discapacidad'],this.stockLastYear.results['Madres/ padres solteras (os)'],this.stockLastYear.results['Mujeres'],this.stockLastYear.results['Niñas, niños y adolescentes']],
            backgroundColor: ['#ffc000', '#2e75b6', '#afabab', '#00b050', '#002060', '#2e75b6','#bf9000','#ffd966','#8faadc','#c55a11','#7030a0'],

            label: '*Migrantes/Refugiados/Solicitantes de asilo.',

          }
        ],

      },
      plugins: [ChartDataLabels],
      options: {

        indexAxis: 'y',
        aspectRatio: 1.2,
        plugins: {
          legend: {
            display: true,
            position: 'bottom', // Mover la leyenda a la parte inferior
            labels: {
              color: 'black', // Cambiar el color de las etiquetas de la leyenda
              boxWidth: 12, // Cambiar el ancho del cuadro de la leyenda
              usePointStyle: true, // Usar un estilo de punto en lugar de un cuadro
              boxHeight: 12, // Cambiar la altura del cuadro de la leyenda
              generateLabels: function(chart) {
                var originalLabels = Chart.defaults.plugins.legend.labels.generateLabels(chart);
                originalLabels[0].fillStyle = '#bf9000'; // Cambiar el color del cuadro de la leyenda
                return originalLabels;
              }
            },
            align: 'center', // Centrar la leyenda
          },
          datalabels: {
            color: 'black',
            anchor: 'end',
            align: 'end',
            labels: {
              title: {
                font: {
                  weight: 'bold',
                  size: 14,
                }
              },

            }
          }

        },



      }
    });
  }
  totalRequestChartTheme2() {
    this.chartTotalRequest2 = new Chart("StatTotalRequest", {
      type: 'bar',
      data: {
        labels: ['Sin información', 'Victimas de maltrato / abuso', 'Grupos indigenas u originarios', 'Personas en pobreza extrema', 'Personas adultas mayores', 'Personas LGBTTTIQA+', 'Migrantes/Refugiados*', 'Personas con discapacidad', 'Madres/ padres solteras (os)', 'Mujeres', 'Niñas, niños y adolescentes'],
        datasets: [
          {
            data: [this.stockYear.sin_definir, this.stockYear.results['Víctimas de maltrato/abuso'], this.stockYear.results['Grupos indígenas u originarios'], this.stockYear.results['Personas en pobreza extrema'], this.stockYear.results['Personas adultas mayores'], this.stockYear.results['Personas LGBTTTIQA+'],this.stockYear.results['Migrantes, refugiados y solicitantes de asilo'],this.stockYear.results['Personas con discapacidad'],this.stockYear.results['Madres/ padres solteras (os)'],this.stockYear.results['Mujeres'],this.stockYear.results['Niñas, niños y adolescentes']],
            backgroundColor: ['#ffc000', '#2e75b6', '#afabab', '#00b050', '#002060', '#2e75b6','#bf9000','#ffd966','#8faadc','#c55a11','#7030a0'],
            label: '*Migrantes/Refugiados/Solicitantes de asilo.',
          }
        ],
      },
      plugins: [ChartDataLabels],
      options: {
        indexAxis: 'y',
        aspectRatio: 1.2,
        plugins: {
          legend: {
            display: true,
            position: 'bottom', // Mover la leyenda a la parte inferior
            labels: {
              color: 'black', // Cambiar el color de las etiquetas de la leyenda
              boxWidth: 12, // Cambiar el ancho del cuadro de la leyenda
              usePointStyle: true, // Usar un estilo de punto en lugar de un cuadro
              boxHeight: 12, // Cambiar la altura del cuadro de la leyenda
              generateLabels: function(chart) {
                var originalLabels = Chart.defaults.plugins.legend.labels.generateLabels(chart);
                originalLabels[0].fillStyle = '#bf9000'; // Cambiar el color del cuadro de la leyenda
                return originalLabels;
              }
            },
            align: 'center', // Centrar la leyenda
          },
          datalabels: {
            color: 'black',
            anchor: 'end',
            align: 'end',
            labels: {
              title: {
                font: {
                  weight: 'bold',
                  size: 14,
                }
              },
            }
          }
        },
      }
    });
  }


}
