import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProposalService } from 'src/app/shared/services/proposal.service';
import { CustomeValidationService } from 'src/app/shared/services/custome-validation.service';
import { PlayerComponent } from 'src/app/pages/components/modal/player/player.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-proposal-information',
  templateUrl: './proposal-information.component.html',
  styleUrls: ['./proposal-information.component.scss']
})
export class ProposalInformationComponent implements OnInit {
  idProposal: any;
  proposal: any = [];
  idWorkGroup: any;
  AllurlDocument: any = [];
  AllurlVideo: any = [];
  AllurlImage: any = [];
  constructor(
    private proposalService: ProposalService,
    private CustomeValidationService: CustomeValidationService,
    private activeRoute: ActivatedRoute, private cdRef: ChangeDetectorRef,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.getProposal();
  }

  openLink(url: string) {
    window.open(url, '_blank');
  }

  getFileName(url: string): string {
    const parts = url.split('/');
    return decodeURIComponent(parts[parts.length - 1]);
  }

  openFile(url: string) {
    if (/\.pdf$/i.test(url)) {
      // Es un archivo PDF, abrir en una nueva ventana
      window.open(url, '_blank');
    } else {
      // No es un archivo PDF, realizar alguna acción o manejar según sea necesario
      let dialogRef = this.dialog.open(PlayerComponent, {
        data: { selectedMedia: url },
        maxHeight: '80%',
        maxWidth: '80%',
      });
      dialogRef.afterClosed().subscribe(result => {

      });
    }
  }

  getProposal() {
    this.idProposal = this.activeRoute.snapshot.paramMap.get('idProposal');
    this.idWorkGroup = this.activeRoute.snapshot.paramMap.get('idWorkGroup');

    if (this.idProposal != null) {
      this.proposalService.getProposalData(this.idProposal, this.idWorkGroup).subscribe((response: any) => {
        this.proposal = response;

        // this.AllurlDocument =  JSON.parse(response.urlDocument);
        // this.AllurlVideo =  JSON.parse(response.urlVideo);
        // this.AllurlImage =  JSON.parse(response.urlImage);

        this.AllurlImage = this.CustomeValidationService.checkForServerLocation(response.urlImage)
        this.AllurlVideo = this.CustomeValidationService.checkForServerLocation(response.urlVideo)
        this.AllurlDocument = this.CustomeValidationService.checkForServerLocation(response.urlDocument)

      })
    }
  }
}
