import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import * as L from 'leaflet';
const iconRetinaUrl = 'assets/marker-icon-2x.png';
const iconUrl = 'assets/marker-icon.png';
const shadowUrl = 'assets/marker-shadow.png';
export const DEFAULT_LAT = 25.794452215964732;
export const DEFAULT_LON = -100.37729304123305;

@Component({
  selector: 'app-sp-map',
  templateUrl: './sp-map.component.html',
  styleUrls: ['./sp-map.component.scss']
})
export class SpMapComponent implements OnInit {

  map: any;
  marker!: L.Marker;
  @Input() lat: number = DEFAULT_LAT;
  @Input() lon: number = DEFAULT_LON;
  mapLoaded = true;
  options = {
    layers: [
      L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 18, attribution: '...'
      })
    ],
    zoom: 7,
    center: L.latLng(DEFAULT_LAT, DEFAULT_LON)
  };


  constructor() { }

  ngOnInit(): void {
    this.initMap(DEFAULT_LAT, DEFAULT_LON);
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes.lat && changes.lon) {
      if (changes.lat.currentValue != undefined && changes.lon.currentValue != undefined) {
        console.log('Input "initialLocation" changed:', changes.lat.currentValue);
        this.changeMapCenter(changes.lat.currentValue, changes.lon.currentValue);

      }
    }
  }

  ngOnDestroy() {


    // Destruye el mapa si ya existe
    if (this.map) {
      this.map.remove();
    }

    // Inicializa el nuevo mapa
    this.initMap(DEFAULT_LAT, DEFAULT_LON);
  }

  public changeMapCenter(lat: number, lon: number): void {

    if (this.map && !isNaN(lat) && !isNaN(lon)) {
      this.marker = L.marker([lat, lon]).addTo(this.map);

      const newCenter = L.latLng(lat, lon);
      this.map.setView(newCenter, 13); // Cambia la vista del mapa al nuevo centro y nivel de zoom
      this.mapLoaded = true;
    }

  }

  private initMap(lat: number, lon: number): void {

    //configuración del mapa
    this.map = L.map('mapsp', {
      center: [lat, lon],
      attributionControl: false,
      zoom: 14
    });
    //iconos personalizados
    var iconDefault = L.icon({
      iconRetinaUrl,
      iconUrl,
      shadowUrl,
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      tooltipAnchor: [16, -28],
      shadowSize: [41, 41]
    });
    L.Marker.prototype.options.icon = iconDefault;

    //titulo
    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '&copy; <a href="https://1938.com.es">Gesco 2023</a>'
    });
    tiles.addTo(this.map);



  }

}
