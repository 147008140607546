<div class="contenedor">
    <div class="back btn btn-sm mt-3 ml-3">
        <i class="fa fa-arrow-left fa-lg" style="cursor: pointer;" (click)="goBack()" aria-hidden="true"></i>
    </div>
    <h1 class="page-header">Datos del simpatizante &nbsp;<small></small></h1>
    <hr>
    <div class="row custom-row m-2">
        <div class="col-12 col-md-4">
            <label class="p-t-2">Folio asignado: </label>
            <div class="form-control">{{ sympathizer.qrcode }}</div>
        </div>
        <div class="col-12 col-md-4 p-0" style="justify-content:end;">
            <button class="btn btn-secondary mr-5 mb-0 buttonOptions" (click)="goToUpdate(sympathizer.idSympathizer,sympathizer.idUser)"><i class="fa fa-edit"></i> Editar</button>
        </div>
    </div>
    <div class="sectionBody">
        <div class="row tHeader" style="margin: 0 !important; ">
            <div class="col-4 tab" (click)="changeSelected(0)" [ngClass]="{'active': selectedTab == 0}" >
                <div class="sectionForm">
                    <span class="numberNotSelected" *ngIf="selectedTab != 0 else one">
                        <div class="fa-solid fa-check"></div>
                    </span>
                    <ng-template #one>
                        <span class="number">
                            1
                        </span>
                    </ng-template>
                    <div>
                        <span class="info">Datos generales </span>
                        <i class="fa fa-arrow-right"></i>
                        <small>Nombre, Dirección, Teléfono...</small>
                    </div>
                </div>
            </div>
            <div class="col-4 tab" (click)="changeSelected(1)" [ngClass]="{'active': selectedTab == 1}" >
                <div class="sectionForm">
                    <span class="numberNotSelected" *ngIf="selectedTab != 1 else second">
                        <div class="fa-solid fa-check"></div>
                    </span>
                    <ng-template #second>
                        <span class="number">
                            2
                        </span>
                    </ng-template>
                    <div>
                        <span class="info">Documentación </span>
                        <i class="fa fa-arrow-right"></i>
                        <small>Documentación</small>
                    </div>
                </div>
            </div>
            <div class="col-4 tab" (click)="changeSelected(2)" [ngClass]="{'active': selectedTab == 2}" >
                <div class="sectionForm">
                    <span class="numberNotSelected" *ngIf="selectedTab != 2 else third">
                        <div class="fa-solid fa-check"></div>
                    </span>
                    <ng-template #third>
                        <span class="number">
                            3
                        </span>
                    </ng-template>
                    <div>
                        <span class="info">Historial </span>
                        <i class="fa fa-arrow-right"></i>
                        <small>Registro de la actividad</small>
                    </div>
                </div>
            </div>
        </div>
        <mat-stepper (selectionChange)="onStepChange($event)" #stepper>
            <mat-step>
                <app-sympathizer-general-data></app-sympathizer-general-data>
                <div class="footer-form">
                    <button class="btn btn-primary" (click)="changeSelected(1)">Siguiente</button>
                </div>
            </mat-step>
            <mat-step>
                <app-sympathizer-documentation></app-sympathizer-documentation>
                <div class="footer-form">
                    <button class="btn btn-primary" (click)="changeSelected(2)">Siguiente</button>
                    <button class="btn btn-light mr-2" (click)="changeSelected(0)">Anterior</button>
                </div>
            </mat-step>
            <mat-step>
                <app-sympathizer-history></app-sympathizer-history>
                <div class="footer-form">
                    <button class="btn btn-light" (click)="changeSelected(1)">Anterior</button>
                </div>
            </mat-step>
        </mat-stepper>
    </div>
</div>