import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { log } from 'console';
import { privateDecrypt } from 'crypto';
import { PassDataComponent } from 'src/app/pages/components/modal/pass-data/pass-data.component';
import { CustomeValidationService } from 'src/app/shared/services/custome-validation.service';
import { FormDataService } from 'src/app/shared/services/form-data.service';
import { MapService } from 'src/app/shared/services/map.service';
import { PeriodService } from 'src/app/shared/services/period.service';
import { POIService } from 'src/app/shared/services/poi.service';
import { RequestService } from 'src/app/shared/services/request.service';

declare var $: any;
@Component({
  selector: 'app-request-general-data',
  templateUrl: './request-general-data.component.html',
  styleUrls: ['./request-general-data.component.scss']
})
export class RequestGeneralDataComponent implements OnInit {
  @ViewChild('menu') menu!: ElementRef;
  @ViewChild('searchMenuSecc') searchMenuSecc!: ElementRef;
  applicantForm: FormGroup = this.FB.group({
    idApplicant: [''],
    name: ['', [Validators.required]],
    next_name: ['', [Validators.required]],
    last_name: ['', [Validators.required]],
    gender: [''],
    birthDate: [''],
    curp: ['', [this.curpValidator.bind(this)]],
    email: ['', [Validators.email]],
    phone: [''],
    cellPhone: ['', [Validators.required]],
    civilStatus: [''],
    birthPlace: [''],
    birthPlaceM: [{ value: '', disabled: true }],
    scholarship: [''],
    ocupation: [''],
    workplace: [''],
    cellPhoneOffice: [''],
    facebook: [''],
    instagram: [''],
    street: [''],
    streetNumExt: [''],
    streetNumInt: [''],
    postalCode: [''],
    idPostalCode: [''],
    idNeighborhood: [{ value: '', disabled: true }],
    idMunicipality: [''],
    idEntity: [''],
    electoralSection: [''],
    reference: [''],
    idWorkGroup: [''],
    location: ['0'],
    idlifting: ['', [Validators.required]],
    typeManagment: [''],
    groupBe: [0],
    federalSupport: [0],
    typeSupport: [''],
    privacyNotice: ['', [Validators.required]],
    solicitingBeneficiary: [0],
    POIrelated: 0,
    idPOI: 0,
    origen: ['1'],
    participation: 0,
    participationType: [''],
    otherType: [''],
    actor: 0,
    actorType: [''],
    otherActor: [''],
    period: ['']
  });

  postalCodeSearch: any = [];
  neighborhoodOptions: any[] = [];
  municipalitieName: String = '';
  entitieName: String = '';
  entities: any = '';
  municipalities: any = '';
  postalCodeSelected: boolean = false;
  sectionSelected: boolean = false;
  idRequest: any;
  sectionOptions: any = [];
  idWorkGroup: string = '';
  isReadMore = true;
  POIoptions: any;
  lat!: number;
  long!: number;
  sectionSearch: string = '';
  showSections: boolean = false;

  periodOptions: any = [];

  constructor(
    private FB: FormBuilder,
    private customizeService: CustomeValidationService,
    private formDataService: FormDataService,
    private requestService: RequestService,
    private activeRoute: ActivatedRoute,
    private mapService: MapService,
    public dialog: MatDialog,
    private POIService: POIService,
    private renderer: Renderer2,
    private periodService: PeriodService

  ) {
    this.attachInputHandlers();
    this.backdropDismiss();
  }


  openModal() {
    $('#exampleModal').modal('show');
  }

  closeModal() {
    $('#exampleModal').modal('hide');
  }

  toggleText() {
    this.isReadMore = !this.isReadMore;
  }

  ngOnInit(): void {
    this.getUserToken();
    this.getEntities();
    this.getPeriodOptions();
    this.getSearchData();
    this.getPOIs();
    this.fillAfterCreatePOI();
    if(this.idWorkGroup == 'd41d8cd98f00b204e9800998ecf8427e')
      this.applicantForm.controls['idlifting'].setValue('Campaña');
    this.applicantForm.valueChanges.subscribe(data => {
      this.formDataService.setRequestGeneralFormData(this.applicantForm);
    });
  }

  getPeriodOptions(){
    this.periodOptions = this.periodService.options;
  }

  onInputClick() {
    this.showSections = true;
  }

  backdropDismiss() {
    this.renderer.listen('window', 'click', (e: Event) => {

      if (e.target !== this.menu.nativeElement && e.target !== this.searchMenuSecc.nativeElement) {
        this.showSections = false;
      }
    });
  }

  fillAfterCreatePOI() {
    if (this.formDataService.lastForm) {

      console.log(this.formDataService.lastForm.value);
      const data = this.formDataService.lastForm.value;

      this.applicantForm.patchValue({
        idApplicant: data.idApplicant,
        name: data.name,
        next_name: data.next_name,
        last_name: data.last_name,
        gender: data.gender ?? '',
        birthDate: data.birthDate,
        curp: data.curp,
        email: data.email,
        phone: data.phone,
        cellPhone: data.cellPhone,
        civilStatus: data.civilStatus ?? '',
        birthPlace: data.birthPlace ?? '',
        birthPlaceM: data.birthPlaceM ?? '',
        scholarship: data.scholarship ?? '',
        ocupation: data.ocupation ?? '',
        workplace: data.workplace,
        cellPhoneOffice: data.cellPhoneOffice,
        facebook: data.facebook,
        instagram: data.instagram,
        street: data.street,
        streetNumExt: data.streetNumExt,
        streetNumInt: data.streetNumInt,
        postalCode: data.postalCode,
        idPostalCode: data.idPostalCode,
        idNeighborhood: data.idNeighborhood ?? '',
        idMunicipality: data.idMunicipality,
        idEntity: data.idEntity,
        electoralSection: data.electoralSection,
        reference: data.reference,
        idWorkGroup: data.idWorkGroup,
        location: data.location,
        POIrelated: 1,
        idPOI: this.formDataService.selectedPOI,
        participation: data.participation,
        participationType: data.participationType,
        otherType: data.otherType,
        actor: data.actor,
        actorType: data.actorType,
        otherActor: data.otherActor,
        privacyNotice: data.privacyNotice,
        typeManagment: data.typeManagment,
        idlifting: data.idlifting,
        period: data.period,
      });

    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(PassDataComponent, {
      data: {
        form: this.applicantForm,
        idUser: this.activeRoute.snapshot.paramMap.get('idUser'),
        idWorkGroup: this.activeRoute.snapshot.paramMap.get('idWorkGroup'),
        token: this.activeRoute.snapshot.paramMap.get('token'),
        environment: this.activeRoute.snapshot.paramMap.get('environment'),
        from: 'request'

      },
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      //this.animal = result;
    });
  }

  getPOIs() {
    return new Promise((resolve, reject) => {
      this.POIService.getPOIOptions().subscribe(
        (data: any) => {
          this.POIoptions = data;
          resolve(data); // Resolvemos la promesa con los datos
        },
        (error) => {
          reject(error); // Rechazamos la promesa en caso de error
        }
      );
    });
  }

  getSearchData() {
    this.formDataService.applicantSearchResults$.subscribe(data => {
      console.log(data);
      this.applicantForm.patchValue({
        idApplicant: data.idApplicant,
        name: data.name,
        next_name: data.next_name,
        last_name: data.last_name,
        gender: data.gender ?? '',
        birthDate: data.birthDate,
        curp: data.curp,
        email: data.email,
        phone: data.phone,
        cellPhone: data.cellPhone,
        civilStatus: data.civilStatus ?? '',
        birthPlace: data.birthPlace ?? '',
        birthPlaceM: data.birthPlaceM ?? '',
        scholarship: data.scholarship ?? '',
        ocupation: data.ocupation ?? '',
        workplace: data.workplace,
        cellPhoneOffice: data.cellPhoneOffice,
        facebook: data.facebook,
        instagram: data.instagram,
        street: data.street,
        streetNumExt: data.streetNumExt,
        streetNumInt: data.streetNumInt,
        postalCode: data.postalCode,
        idPostalCode: data.idPostalCode,
        idNeighborhood: data.idNeighborhood ?? '',
        idMunicipality: data.idMunicipality,
        idEntity: data.idEntity,
        electoralSection: data.electoralSection,
        reference: data.reference,
        idWorkGroup: data.idWorkGroup,
        location: data.location,
        POIrelated: data.POIrelated ?? 0,
        idPOI: data.idPOI ?? 0,
        participation: data.participationType ? 1 : 0,
        participationType: data.participationType ?? '',
        otherType: data.otherType,
        actor: data.actorType ? 1 : 0,
        actorType: data.actorType ?? '',
        otherActor: data.otherActor,
      });
      this.clearSection();
      this.municipalitieName = '';
      this.entitieName = '';
      this.sectionOptions = [];
      if (data.birthPlace) {
        this.onFirstSelectChange();
      }
      if (data.postalCode) {
        this.getDirectionByPostalCode(data.postalCode, data.idPostalCode);
      }
      if (data.electoralSection) {
        this.selectSecction(data.electoralSection);
      }
    });
  }

  getUserToken() {
    this.activeRoute.params.subscribe(routeParams => {
      localStorage.setItem('token', routeParams['token'])
    });
    this.idWorkGroup = localStorage.getItem('idWorkGroup') ?? '';
    this.applicantForm.controls['idWorkGroup'].setValue(this.idWorkGroup);
  }

  attachInputHandlers() {
    const nameFields = ['name', 'next_name', 'last_name'];
    const uppercaseFields = ['curp', 'workplace', 'typeSupport', 'street', 'reference', 'otherType', 'otherActor'];
    const numberFields = ['phone', 'cellPhone', 'cellPhoneOffice', 'postalCode'];
    uppercaseFields.forEach(fieldName => {
      const fieldControl = this.applicantForm.get(fieldName);
      if (fieldControl) {
        fieldControl.valueChanges.subscribe((newValue: string) => {
          if (newValue) {
            this.applicantForm.get(fieldName)?.setValue(newValue.toUpperCase(), { emitEvent: false });
          }
        });
      }
    });
    nameFields.forEach(fieldName => {
      const fieldControl = this.applicantForm.get(fieldName);
      if (fieldControl) {
        fieldControl.valueChanges.subscribe((newValue: string) => {
          if (newValue) {
            this.applicantForm.get(fieldName)?.setValue(newValue.toUpperCase().replace(/[^A-Z\sÑ]/g, ''), { emitEvent: false });
          }
        });
      }
    });
    numberFields.forEach(fieldName => {
      const fieldControl = this.applicantForm.get(fieldName);
      if (fieldControl) {
        fieldControl.valueChanges.subscribe((newValue: string) => {
          if (newValue) {
            this.applicantForm.get(fieldName)?.setValue(newValue.replace(/\D/g, ''), { emitEvent: false });
          }
        });
      }
    });
  }

  curpValidator(control: FormControl): { [key: string]: any } | null {
    const curp = control.value;
    if (curp?.length != 0 && curp != null) {
      const isValid = this.customizeService.validCurp(curp);

      if (isValid) {
        return null; // La CURP es válida
      } else {
        return { 'invalidCurp': true }; // La CURP es inválida
      }
    } else {
      return null;
    }
  }

  searchPostalCode() {
    this.requestService.searchPostalCode(this.applicantForm.controls['postalCode'].value).subscribe((data: any) => {
      this.postalCodeSelected = false;
      this.postalCodeSearch = data;
    });
  }

  selectPostalCode(postalCode: String, id:number) {
    this.getDirectionByPostalCode(postalCode, id);
  }

  selectSecction(Secction: String) {
    this.sectionSelected = true;
    this.applicantForm.controls['electoralSection'].setValue(Secction);
  }

  clearSection() {
    this.sectionSelected = false;
    this.applicantForm.controls['electoralSection'].reset();
  }

  getDirectionByPostalCode(postalCode: String, id:number) {
    const idPC = this.postalCodeSearch.find((option: any) => option.identifier == postalCode);
    this.applicantForm.controls['postalCode'].setValue(postalCode);
    this.applicantForm.controls['idMunicipality'].reset();
    this.applicantForm.controls['idEntity'].reset();

    this.requestService.getDirectionByPostalCode(postalCode).subscribe(
      (data: any) => {
        this.postalCodeSelected = true;

        this.municipalitieName = data.municipality.name;
        this.applicantForm.controls['idMunicipality'].setValue(data.municipality.id);

        this.entitieName = data.entity.name;
        this.applicantForm.controls['idEntity'].setValue(data.entity.id);
        this.getSecctions(data.municipality.identifier, data.entity.identifier)

        // Clear previous neighborhood options
        this.neighborhoodOptions = [];

        if (data.neighborhoods.length > 1) {
          this.applicantForm.controls['idNeighborhood'].enable();
          this.neighborhoodOptions = data.neighborhoods;
        } else {
          this.applicantForm.controls['idNeighborhood'].enable();
          this.neighborhoodOptions = data.neighborhoods;
          this.applicantForm.controls['idNeighborhood'].setValue(data.neighborhoods[0].id);
        }
      },
      (error) => {
        console.error(error); // Maneja el error de manera adecuada
      }
    );
    this.applicantForm.controls['idPostalCode'].setValue(id);
  }

  getSecctions(idMunicipality: number, idEntity: number) {
    this.mapService.getSections(idMunicipality, idEntity).subscribe((data: any) => {
      console.log(data);
      this.sectionOptions = data;

    })
  }

  getEntities(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.requestService.getEntities().subscribe(
        (data: any) => {
          this.entities = data;
          resolve(data); // Resolvemos la promesa con los datos
        },
        (error) => {
          reject(error); // Rechazamos la promesa en caso de error
        }
      );
    });
  }

  getMunicipalities(idEntity: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.requestService.getMunicipalities(idEntity).subscribe(
        (data: any) => {
          this.municipalities = data;
          resolve(data); // Resolvemos la promesa con los datos
        },
        (error) => {
          reject(error); // Rechazamos la promesa en caso de error
        }
      );
    });
  }

  async onFirstSelectChange() {
    this.applicantForm.controls['birthPlaceM'].enable();
    try {
      const idEntity = this.applicantForm.get('birthPlace')?.value;
      await this.getMunicipalities(idEntity);
      // Aquí puedes continuar con cualquier lógica que dependa de las municipalities
    } catch (error) {
      console.error('Error al obtener las municipalities:', error);
    }
  }

  updateCheckbox(event: Event) {
    if (event && event.target instanceof HTMLInputElement) {
      this.applicantForm.get('privacyNotice')?.setValue(event.target.checked ? 1 : null);
    }
  }
}
