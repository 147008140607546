import { Component, Input, Output, OnInit, EventEmitter, SimpleChanges } from '@angular/core'
import { resolve } from 'dns';
import { RequestService } from 'src/app/shared/services/request.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-s3-uploader',
  templateUrl: './s3-uploader.component.html',
  styleUrls: ['./s3-uploader.component.scss']
})
export class S3UploaderComponent implements OnInit {
  @Input() URLs = new Array;
  @Input() type = 'image';
  @Input() multiFile = true;
  descType = '(JPEG/PNG/PDF)';
  acceptedFileTypes = ".pdf,.doc,.png,.jpg,.jpeg";
  @Output() valueResponse: EventEmitter<String> = new EventEmitter();

  selectedFiles: any = [];


  constructor(private requestService: RequestService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.type) {
      if (changes.type.currentValue != undefined) {
        console.log('Input "type" changed:', changes.type.currentValue);
        this.type = changes.type.currentValue;
        this.handleTypes();
      }
    }
  }

  handleTypes() {
    switch (this.type) {
      case 'image':
        this.descType = '(JPEG/PNG/PDF)';
        this.acceptedFileTypes = ".pdf,.doc,.png,.jpg,.jpeg";
        break;
      case 'document':
        this.descType = '(DOX/CSV/PDF)';
        this.acceptedFileTypes = ".pdf,.doc";
        break;
      case 'video':
        this.descType = '(DOX/CSV/PDF)';
        this.acceptedFileTypes = "video/*";
        break;
      default:
        break;
    }
  }

  onFileChange(event: any) {
    const files: FileList = event.target.files;

    for (let i = 0; i < files.length; i++) {
      this.selectedFiles.push({ file: files[i] });
    }
    console.log(this.selectedFiles);
  }

  removeFile(url: string) {
    this.URLs = this.URLs.filter((item: any) => item !== url);
  }

  openFile(url: string) {
    window.open(url, '_blank');
  }

  loaderAlert() {
    Swal.fire({
      icon: 'warning',
      title: "Subiendo archivos",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton())
      },
      willClose: () => {

      }
    }).then((result) => {

    })
  }

  succesAlert() {

    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

    Toast.fire({
      icon: 'success',
      title: "Archivos subidos",
    })

  }

  errorAlert() {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

    Toast.fire({
      icon: 'error',
      title: "Erro al subir archivos",
    })
  }

  uploadImage(module: string, type: string, id: number) {
    if(this.selectedFiles){
      this.loaderAlert();
      return new Promise((resolve, reject) => {
        const uploadPromises = [];
        console.log(this.selectedFiles);

        for (const file of this.selectedFiles) {
          console.log(file);

          const uploadPromise = new Promise((innerResolve, innerReject) => {
            this.requestService.uploadImage(file.file, module, type, id).subscribe(
              (data: any) => {
                console.log(data);
                this.URLs.push(data.path);
                innerResolve(data);
              },
              (error) => {
                innerReject(error);
              }
            );
          });

          uploadPromises.push(uploadPromise);
        }

        Promise.all(uploadPromises)
          .then((data) => {
            // Todas las imágenes se han cargado con éxito
            Swal.close();
            this.succesAlert();

            resolve(data);
          })
          .catch((error) => {
            // Al menos una carga de imagen falló
            Swal.close();
            this.errorAlert();

            reject(error);
          });
      });
    } else {
      return new Promise((resolve) =>{
        resolve([]);
      })
    }
  }

}
