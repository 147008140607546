<head>
  <link href="https://cdnjs.cloudflare.com/ajax/libs/flowbite/1.8.0/flowbite.min.css" rel="stylesheet" />
</head>

<div class="contenedor">
  <form [formGroup]="clasificationForm">
    <div class="row">
      <div class="col-12 col-md-3">
        <label>Buscador de categorías (Opcional):</label>
        <div class="input-group">
          <input type="text" placeholder="Buscar Categoría" class="form-control" [(ngModel)]="searchValue"
            (ngModelChange)="onSearchChange($event)" [ngModelOptions]="{standalone: true}">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="button" (click)="searchCategory()">
              <i class="fa fa-search" aria-hidden="true"></i>
            </button>
          </div>
        </div>
        <ng-container #menu *ngIf="responseSearch.length != 0 && selectedSearch == false">
          <div class="col-12 content-seach searchList">
            <ng-container *ngIf="responseSearch.length == 0 else searchMenu">
              <label class="darkGray">No hay registros</label>
            </ng-container>
            <ng-template #searchMenu>
              <ng-container *ngFor="let result of responseSearch; let i = index">
                <ng-container>
                  <label (click)="selectOption(result.ids)"
                    [ngClass]=" i%2==0 ? 'darkGray' : 'lowGray'">{{result.name}}</label>
                </ng-container>
              </ng-container>
              <br>
            </ng-template>
          </div>
        </ng-container>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-12 col-md-4">
        <span style="color: red">* </span>
        <label>Tema:</label>
        <select class="form-control" formControlName="theme_id" (change)="onThemeSelectChange()">
          <option value="">Selecciona una opción</option>
          <option *ngFor="let theme of themes" value="{{theme.id}}">{{theme.name}}</option>
        </select>
      </div>
      <div class="col-12 col-md-4">
        <span style="color: red">* </span>
        <label>Subtema:</label>
        <select class="form-control" formControlName="subtheme_id" (change)="onSubthemeSelectChange()">
          <option value="">Selecciona una opción</option>
          <option *ngFor="let subtheme of subthemes" value="{{subtheme.id}}">{{subtheme.name}}</option>
        </select>
      </div>
      <div class="col-12 col-md-4">
        <span style="color: red">* </span>
        <label>Categoría:</label>
        <select class="form-control" formControlName="categories_id">
          <option value="">Selecciona una opción</option>
          <option *ngFor="let category of categories" value="{{category.id}}">{{category.name}}</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-4">
        <label class="col-md-12">Descripción:</label>
        <textarea id="description" class="form-control" placeholder="Ingresa una descripción" maxlength="250"
          formControlName="description"></textarea>
        <app-maxchardec [inputValue]="clasificationForm.controls['description'].value ?? ''">
        </app-maxchardec>
      </div>
      <div class="col-12 col-md-4">
        <label>Cantidad:</label>
        <input class="form-control" type="number" formControlName="quantity_c" />
      </div>
      <div class="col-12 col-md-4">
        <div class="col-md-12">
          <label>Resolver a petición del titular:</label>
        </div>
        <div class="col-md-12 offset-md-1 checkbox checkbox-css checkbox-inline">
          <input id="checkboxSolve" type="checkbox" [checked]="clasificationForm.get('solve')?.value === 1"
            (change)="updateCheckbox($event)" />
          <label for="checkboxSolve">Si</label>
        </div>
      </div>
      <div class="col-12 col-md-4" *ngIf="clasificationForm.get('categories_id')?.value == 855">
        <label>Monto económico:</label>
        <input class="form-control" type="number" placeholder="1000" formControlName="amount" />
      </div>
      <div class="col-12 col-md-4" *ngIf="showUrgent">
        <div class="col-md-12">
          <label>¿La solicitud es urgente?</label>
        </div>
        <div class="col-md-12 offset-md-1 checkbox checkbox-css checkbox-inline">
          <input id="checkboxUrgent" type="checkbox" [checked]="clasificationForm.get('urgent')?.value === 1"
            (change)="updateCheckbox($event)" />
          <label for="checkboxUrgent">Si</label>
        </div>
      </div>
      <div class="col-12 col-md-4"
        *ngIf="clasificationForm.get('theme_id')?.value == 15 || clasificationForm.get('theme_id')?.value == 4">
        <div class="col-md-12">
          <label>¿La solicitud es de respuesta rápida?</label>
        </div>
        <div class="col-md-12 offset-md-1 checkbox checkbox-css checkbox-inline">
          <input id="checkboxUrgentOP" type="checkbox" [checked]="clasificationForm.get('urgent')?.value === 1"
            (change)="updateCheckbox($event)" />
          <label for="checkboxUrgentOP">Si</label>
        </div>
      </div>
      <div class="col-12 col-md-4" *ngIf="showAditionBeneficiaries">
        <label>Beneficiarios adicionales:</label>
        <input class="form-control" type="number" formControlName="aditionBe" placeholder="Beneficiarios adicionales" />
      </div>
      <div class="col-12 col-md-4" *ngIf="showSolicitingBeneficiary">
        <label>
          <span class="reqForm">*</span> ¿Usted es el beneficiario?
        </label>
        <div class="col-md-12">
          <div class="radio radio-css radio-inline">
            <input id="solicitingBeneficiaryYes" formControlName="solicitingBeneficiary" type="radio" [value]="0"
              checked="">
            <label for="solicitingBeneficiaryYes">Sí</label>
          </div>
          <div class="radio radio-css radio-inline">
            <input id="solicitingBeneficiaryNo" formControlName="solicitingBeneficiary" type="radio" [value]="1">
            <label for="solicitingBeneficiaryNo">No</label>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4" *ngIf="clasificationForm.get('solicitingBeneficiary')?.value">
        <label><span class="reqForm">*</span> ¿Qué es de usted el beneficiario?</label>
        <div class="col-md-12">
          <select class="form-control" type="text" formControlName="relationshipBeneficiary">
            <option value="">Selecciona una opción</option>
            <option value="Abuelo/a">Abuelo/a</option>
            <option value="Cónyuge">Cónyuge</option>
            <option value="Hermano/a">Hermano/a</option>
            <option value="Hijo/a">Hijo/a</option>
            <option value="Madre">Madre</option>
            <option value="Nieto/a">Nieto/a</option>
            <option value="Padre">Padre</option>
            <option value="Sobrino/a">Sobrino/a</option>
            <option value="Tío/a">Tío/a</option>
            <option value="otro">Otro</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="clasificationForm.get('solicitingBeneficiary')?.value">
      <div class="panel panel-info">
        <p class="divData">Datos del beneficiario</p>
      </div>
      <div class="col-12 col-md-4">
        <span style="color: red">* </span>
        <label>Nombre(s):</label>
        <input class="form-control" type="text" placeholder="Ingrese el nombre del solicitante" formControlName="nameB"
          [ngClass]="{'is-invalid': clasificationForm.controls['nameB'].invalid && clasificationForm.controls['nameB'].touched}" />
        <div id="validationServerUsernameFeedback" class="invalid-feedback">
          Por favor ingresa el nombre.
        </div>
      </div>
      <div class="col-12 col-md-4">
        <label>Apellido paterno:</label>
        <input class="form-control" type="text" placeholder="Ingrese apellido paterno" formControlName="next_nameB"
          [ngClass]="{'is-invalid': clasificationForm.controls['next_nameB'].invalid && clasificationForm.controls['next_nameB'].touched}" />
        <div id="validationServerUsernameFeedback" class="invalid-feedback">
          Por favor ingresa el apellido paterno.
        </div>
      </div>
      <div class="col-12 col-md-4">
        <label>Apellido materno:</label>
        <input class="form-control" type="text" placeholder="Ingrese apellido materno" formControlName="last_nameB"
          [ngClass]="{'is-invalid': clasificationForm.controls['last_nameB'].invalid && clasificationForm.controls['last_nameB'].touched}" />
        <div id="validationServerUsernameFeedback" class="invalid-feedback">
          Por favor ingresa el apellido materno.
        </div>
      </div>
      <div class="col-12 col-md-4">
        <label>Fecha de nacimiento:</label>
        <input class="form-control" type="date" placeholder="dd/mm/aaaa" formControlName="birthDateB" />
      </div>
      <div class="col-12 col-md-4">
        <label>Sexo:</label>
        <select class="form-control" formControlName="genderB">
          <option selected value="">Selecciona una opción</option>
          <option value="Femenino">Femenino</option>
          <option value="Masculino">Masculino</option>
          <option value="Otro">Otro</option>
        </select>
      </div>
      <div class="col-12 col-md-4">
        <label>CURP:</label>
        <input class="form-control" type="text" placeholder="Ingrese el CURP" formControlName="curpB" maxlength="18"
          [ngClass]="{'is-invalid': clasificationForm.controls['curpB'].invalid && clasificationForm.controls['curpB'].touched}" />
        <div id="validationServerUsernameFeedback" class="invalid-feedback">
          Por favor ingresa una CURP válida.
        </div>
      </div>
      <div class="col-12 col-md-4">
        <label>Teléfono celular:</label>
        <input class="form-control" type="text" placeholder="Ingrese un teléfono celular" minlength="10" maxlength="10"
          formControlName="cellPhoneB"
          [ngClass]="{'is-invalid': clasificationForm.controls['cellPhoneB'].invalid && clasificationForm.controls['cellPhoneB'].touched}" />
        <div id="validationServerUsernameFeedback" class="invalid-feedback">
          Por favor ingresa un teléfono celular.
        </div>
      </div>
      <div class="col-12 col-md-4">
        <label>Lugar de nacimiento (Estado):</label>
        <select class="form-control" formControlName="birthPlaceB" (change)="onFirstSelectChange()">
          <option selected value="">Selecciona una opción</option>
          <option *ngFor="let entitie of entities" value="{{entitie.id}}">{{entitie.name}}</option>
        </select>
      </div>
      <div class="col-12 col-md-4">
        <label>Lugar de nacimiento (Municipio):</label>
        <select class="form-control" formControlName="birthPlaceMB">
          <option selected value="">Selecciona una opción</option>
          <option *ngFor="let municipalitie of municipalities" value="{{municipalitie.id}}">{{municipalitie.name}}
          </option>
        </select>
      </div>
    </div>
    <div class="row" *ngIf="clasificationForm.get('theme_id')?.value">
      <div class="panel panel-info">
        <p class="divData">Formulario</p>
      </div>
      <div class="col-12 col-md-4">
        <label>Información del beneficiario:</label>
        <select class="form-control" formControlName="information">
          <option value="">Selecciona una opción</option>
          <option *ngFor="let res of information" value="{{res.id}}">{{res.name}}</option>
        </select>
      </div>
      <div class="col-12 col-md-4">
        <label>Grupo vulnerable:</label>
        <select class="form-control" formControlName="vulnerable">
          <option value="">Selecciona una opción</option>
          <option *ngFor="let res of groupsVuln" value="{{res.id}}">{{res.name}}</option>
        </select>
      </div>
      <ng-container *ngIf="showInstitution">
        <div class="col-12 col-md-4">
          <label>
            ¿Estas actualmente en una institución educativa?
          </label>
          <div class="col-md-12">
            <div class="radio radio-css radio-inline">
              <input id="institutionyes" formControlName="institutionBe" type="radio" [value]="1" checked="">
              <label for="institutionyes">Sí</label>
            </div>
            <div class="radio radio-css radio-inline">
              <input id="institutionNo" formControlName="institutionBe" type="radio" [value]="0">
              <label for="institutionNo">No</label>
            </div>
          </div>
        </div>
        <ng-container *ngIf="clasificationForm.get('institutionBe')?.value">
          <div class="col-12 col-md-4">
            <label>Escuela o institución educativa:</label>
            <input class="form-control" type="text" formControlName="institution"
              placeholder="Ingrese el nombre de la escuela o institución" />
          </div>
          <div class="col-12 col-md-4">
            <label>Matrícula:</label>
            <input class="form-control" type="text" formControlName="enrollment"
              placeholder="Ingrese la matrícula de la institución" />
          </div>
          <div class="col-12 col-md-4">
            <label>Nivel educativo:</label>
            <select class="form-control" formControlName="educationLevel">
              <option value="">Selecciona una opción</option>
              <option *ngFor="let res of educationLevel" value="{{res.id}}">{{res.name}}</option>
            </select>
          </div>
        </ng-container>

        <ng-container *ngIf="clasificationForm.get('subtheme_id')?.value == 181">
          <div class="col-12 col-md-4">
            <label>Tipo de disciplina:</label>
            <select class="form-control" formControlName="disciplineClass" (change)="changeDiscipline($event)">
              <option value="">Selecciona una opción</option>
              <option *ngFor="let res of disciplineClass" value="{{res.id}}">{{res.name}}</option>
            </select>
          </div>
          <div class="col-12 col-md-4">
            <label>Rama específica:</label>
            <select class="form-control" formControlName="typeDiscipline">
              <option value="">Selecciona una opción</option>
              <option *ngFor="let res of typeDiscipline" value="{{res.id}}">{{res.name}}</option>
            </select>
          </div>
          <div class="col-12 col-md-4">
            <label>Nivel de disciplina:</label>
            <select class="form-control" formControlName="disciplineNivel">
              <option value="">Selecciona una opción</option>
              <option *ngFor="let res of disciplineNivel" value="{{res.id}}">{{res.name}}</option>
            </select>
          </div>
        </ng-container>

        <ng-container *ngIf="clasificationForm.get('subtheme_id')?.value == 196">
          <div class="col-12 col-md-4">
            <label>Tipo de deporte:</label>
            <select class="form-control" formControlName="sport">
              <option value="">Selecciona una opción</option>
              <option *ngFor="let res of sport" value="{{res.id}}">{{res.name}}</option>
            </select>
          </div>
          <div class="col-12 col-md-4">
            <label>Nivel de aprendizaje:</label>
            <select class="form-control" formControlName="level">
              <option value="">Selecciona una opción</option>
              <option *ngFor="let res of level" value="{{res.id}}">{{res.name}}</option>
            </select>
          </div>
          <div class="col-12 col-md-4">
            <label>Categoría del deporte:</label>
            <select class="form-control" formControlName="categorie">
              <option value="">Selecciona una opción</option>
              <option *ngFor="let res of categorieF" value="{{res.id}}">{{res.name}}</option>
            </select>
          </div>
        </ng-container>

        <ng-container *ngIf="clasificationForm.get('subtheme_id')?.value == 208">
          <div class="col-12 col-md-4">
            <label>Tipo de disciplina:</label>
            <select class="form-control" formControlName="typeDiscipline">
              <option value="">Selecciona una opción</option>
              <option *ngFor="let res of typeDiscipline" value="{{res.id}}">{{res.name}}</option>
            </select>
          </div>
          <div class="col-12 col-md-4">
            <label>Finalidad de la beca:</label>
            <select class="form-control" formControlName="purposeScholarship">
              <option value="">Selecciona una opción</option>
              <option *ngFor="let res of purposeScholarship" value="{{res.id}}">{{res.name}}</option>
            </select>
          </div>
          <div class="col-12 col-md-4">
            <label>Tipo de beca:</label>
            <select class="form-control" formControlName="typeScholarship">
              <option value="">Selecciona una opción</option>
              <option *ngFor="let res of typeScholarship" value="{{res.id}}">{{res.name}}</option>
            </select>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="showHealth">
        <div class="col-12 col-md-4">
          <label>Seguro médico:</label>
          <select class="form-control" formControlName="health">
            <option value="">Selecciona una opción</option>
            <option *ngFor="let res of health" value="{{res.id}}">{{res.name}}</option>
          </select>
        </div>
        <div class="col-12 col-md-4">
          <label>Número de afiliación:</label>
          <input class="form-control" type="text" formControlName="social"
            placeholder="Ingrese el número de afiliación" />
        </div>
        <div class="col-12 col-md-4">
          <label>Apoyo específico:</label>
          <select class="form-control" formControlName="federalSupportForm" (change)="changeFederalSupport($event)">
            <option value="">Selecciona una opción</option>
            <option *ngFor="let res of federalSupportForm" value="{{res.id}}">{{res.name}}</option>
          </select>
        </div>
        <div class="col-12 col-md-4">
          <label>¿Cuál?</label>
          <select class="form-control" formControlName="typeSupportForm">
            <option value="">Selecciona una opción</option>
            <option *ngFor="let res of typeSupportForm" value="{{res.id}}">{{res.name}}</option>
          </select>
        </div>
      </ng-container>
    </div>
    <div class="row"
      *ngIf="clasificationForm.get('theme_id')?.value == 15 || clasificationForm.get('theme_id')?.value == 4">
      <div class="panel panel-info">
        <p class="divData">Ubicar el lugar del reporte</p>
      </div>
      <div class="col-12 col-md-4">
        <label>Calle:</label>
        <input class="form-control" type="text" formControlName="street_sp" placeholder="Ingrese la calle" />
      </div>
      <div class="col-12 col-md-4">
        <label>Número:</label>
        <input class="form-control" type="text" formControlName="streetNum_sp" placeholder="Ingrese el número" />
      </div>
      <div class="col-12 col-md-4">
        <label>Código postal:</label>
        <div class="input-group">
          <input type="text" formControlName="postalCode_sp" placeholder="Ingrese el código postal" class="form-control"
            [ngClass]="{'is-invalid': clasificationForm.controls['postalCode_sp'].invalid && clasificationForm.controls['postalCode_sp'].touched}">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="button" (click)="searchPostalCodeClas()">
              <i class="fa fa-search" aria-hidden="true"></i>
            </button>
          </div>
        </div>
        <ng-container #clas *ngIf="postalCodeSearchClas.length != 0 && postalCodeSelectedClas == false">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 content-seach searchList">
            <ng-container *ngIf="postalCodeSearchClas.length == 0 else searchClas">
              <label class="darkGray">No hay registros</label>
            </ng-container>
            <ng-template #searchClas>
              <ng-container *ngFor="let result of postalCodeSearchClas; let i = index">
                <ng-container>
                  <label (click)="selectPostalCode(result.identifier)"
                    [ngClass]=" i%2==0 ? 'darkGray' : 'lowGray'">{{result.identifier}}</label>
                </ng-container>
              </ng-container>
              <br>
            </ng-template>
          </div>
        </ng-container>
      </div>
      <div class="col-12 col-md-4">
        <label>Colonia:</label>
        <select class="form-control" formControlName="idNeighborhood_sp"
          [ngClass]="{'is-invalid': clasificationForm.controls['idNeighborhood_sp'].invalid && clasificationForm.controls['idNeighborhood_sp'].touched}">
          <option selected value="">Seleccione una colonia</option>
          <option *ngFor="let neighborhoodOption of neighborhoodOptions" value="{{neighborhoodOption.id}}">
            {{neighborhoodOption.name}}</option>
        </select>
        <div id="validationServerUsernameFeedback" class="invalid-feedback">
          Escoge una opción.
        </div>
      </div>
      <div class="col-12 col-md-4">
        <label>Municipio:</label>
        <input class="form-control" placeholder="Cargando listado..." type="text" [value]="municipalitieName">
      </div>
      <div class="col-12 col-md-4">
        <label>Estado:</label>
        <input class="form-control" type="text" placeholder="Cargando listado..." [value]="entitieName">
      </div>
      <div class="col-10 offset-1"
        *ngIf="clasificationForm.get('theme_id')?.value == 4 || clasificationForm.get('theme_id')?.value == 15">
        <div class="cont-image">
          <label class="block mb-2 text-sm text-gesco dark" for="multiple_files">Agregar archivo(s)</label>
          <input class="block w-full text-sm text-gesco border-gesco rounded-lg cursor-pointer" id="multiple_files"
            type="file" accept=".pdf,.doc,.png,.jpg,.jpeg" multiple (change)="onFileChange($event)">
          <p class="mt-1 text-sm text-gesco" id="file_input_help">Agregar evidencia del reporte.</p>
        </div>
      </div>
    </div>
    <div class="row mt-3"
      *ngIf="clasificationForm.get('theme_id')?.value == 4 ||  clasificationForm.get('theme_id')?.value == 15 ">
      <app-sp-map [lat]="lat" [lon]="long"></app-sp-map>
    </div>
  </form>
</div>
