import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { entitiesModel } from '../models/entities.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  url = environment.apiUrl;
  idUser = 38;
  idWorkgroup = 'a5771bce93e200c36f7cd9dfd0e5deaa';
  profileEntitie: number = 0;
  profileMunicipalitie: number = 0;
  profileLevel: number = 0;
  profileInfo: any;
  modules: any;
  onProfileInfo = new EventEmitter<{ idProfileEntity: number, idProfileMunicipality: number, userLevel: number, idProfile: number, idHolder: number, idTypeProfile: number }>();
  onSympathizer = new EventEmitter<boolean>();
  onPromoted = new EventEmitter<boolean>();

  

  constructor(public http: HttpClient) {
  }

  // getProfileMunicipalitie() {
  //   return new Promise((resolve) => {
  //     if (this.profileMunicipalitie != 0) {
  //       console.log(this.profileMunicipalitie);
  //       resolve(this.profileMunicipalitie);
  //     }
  //   });
  // }

  getProfile(userId: number, idWorkGroup: string) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any>(this.url + 'getProfileInfo/' + userId + '/' + idWorkGroup, options);
  }

  getHolderProfile(userId: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any>(this.url + 'account/holder/profile/' + userId, options);
  }

  getEnabledModules(userId: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any>(this.url + 'user/enabled/modules/' + userId , options);
  }


  checkToken(token: string | null) {

    let request;
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.append("Authorization", "Bearer " + token);

    const options = {
      headers: httpHeaders,
    };

    return request = this.http.get<any>(this.url + 'checkToken', options);

  }

  getWorkGroupTheme(userId: number,idWorkGroup: string, theme_id: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any>(this.url + 'getWorkGroupTheme/' + userId + '/' + idWorkGroup + '/' + theme_id, options);
  }

  getUserInfo(idUser: any){
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any>(this.url + 'getUserInfo/' + idUser, options);
  }

  getUserRequestInfo(idAssigned:number){
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any>(this.url + 'getUserRequestInfo/' + idAssigned, options);
  }

  getPoliticalParties(){
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any>(this.url + 'politicalParties', options);
  }
}
