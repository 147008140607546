import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MapComponent } from './pages/map/map/map.component';
import { PanelControlComponent } from './pages/panel-control/panel-control.component';
import { AccesGuard } from './shared/guards/acces.guard';
import { DashboardComponent } from './pages/components/estadistica/dashboard/dashboard.component';
import { RequestsAndAccomplishedByThemesComponent } from './pages/components/estadistica/requests-and-accomplished-by-themes/requests-and-accomplished-by-themes.component';
import { RequestsAndAccomplishedBySubthemesComponent } from './pages/components/estadistica/requests-and-accomplished-by-subthemes/requests-and-accomplished-by-subthemes.component';
import { RequestsAndAccomplishedByCategoriesComponent } from './pages/components/estadistica/requests-and-accomplished-by-categories/requests-and-accomplished-by-categories.component';
import { ClosedRequestsAndPendingRequestsByThemeAndYearComponent } from './pages/components/estadistica/annual-history/components/closed-requests-and-pending-requests-by-theme-and-year/closed-requests-and-pending-requests-by-theme-and-year.component';
import { ClosedRequestsAndPendingRequestsByMonthComponent } from './pages/components/estadistica/annual-history/components/closed-requests-and-pending-requests-by-month/closed-requests-and-pending-requests-by-month.component';
import { ClosedRequestsAndPendingRequestsByThemeAndMonthComponent } from './pages/components/estadistica/annual-history/components/closed-requests-and-pending-requests-by-theme-and-month/closed-requests-and-pending-requests-by-theme-and-month.component';
import { ClosedRequestsAndPendingRequestsByYearComponent } from './pages/components/estadistica/annual-history/components/closed-requests-and-pending-requests-by-year/closed-requests-and-pending-requests-by-year.component';
import { AnnualHistoryComponent } from './pages/components/estadistica/annual-history/annual-history.component';

import { SolicitudesComponent } from './pages/solicitudes/solicitudes/solicitudes.component';
import { GeneralStadisticComponent } from './pages/estadistica/general-stadistic/general-stadistic.component';
import { PlaceOfFilingApplicationsComponent } from './pages/components/estadistica/place-of-filing-applications/place-of-filing-applications.component';
import { TypeOfApplicantsByGenderAndTypeOfBeneficiaryByGenderComponent } from './pages/components/estadistica/type-of-applicants-by-gender-and-type-of-beneficiary-by-gender/type-of-applicants-by-gender-and-type-of-beneficiary-by-gender.component';
import { TypeOfApplicantsByAgeAndTypeOfBeneficiaryByAgeComponent } from './pages/components/estadistica/type-of-applicants-by-age-and-type-of-beneficiary-by-age/type-of-applicants-by-age-and-type-of-beneficiary-by-age.component';
import { ApplicantsAndBeneficiariesByVulnerableGroupComponent } from './pages/components/estadistica/applicants-and-beneficiaries-by-vulnerable-group/applicants-and-beneficiaries-by-vulnerable-group.component';
import { DataCompleteComponent } from './pages/components/estadistica/data-complete/data-complete.component';
import { ApplicantsByGroupOldComponent } from './pages/components/estadistica/annual-history/components/applicants-by-group-old/applicants-by-group-old.component';
import { ApplicantsGenderMonthComponent } from './pages/components/estadistica/annual-history/components/applicants-gender-month/applicants-gender-month.component';
import { ApplicantsGroupVulnerableMonthComponent } from './pages/components/estadistica/annual-history/components/applicants-group-vulnerable-month/applicants-group-vulnerable-month.component';
import { ApplicantsGroupOldMonthComponent } from './pages/components/estadistica/annual-history/components/applicants-group-old-month/applicants-group-old-month.component';
import { GeneralDataComponent } from './pages/components/form/general-data/general-data.component';
import { ProposalInitiativeComponent } from './pages/components/form/proposal-initiative/proposal-initiative.component';
import { CompleteProposalComponent } from './pages/components/form/complete-proposal/complete-proposal.component';
import { ProposalFormComponent } from './pages/components/form/proposal-form/proposal-form.component';
import { ListComponent } from './pages/components/form/proposal/list/list.component';
import { CreatePointComponent } from './pages/interest-point/create-point/create-point.component';
import { ListPointsComponent } from './pages/interest-point/list-points/list-points.component';
import { POIComponent } from './pages/interest-point/poi/poi.component';
import { DataProposalComponent } from './pages/components/form/proposal/data-proposal/data-proposal.component';
import { PromoterInformationComponent } from './pages/components/form/proposal/dataProposal/components/promoter-information/promoter-information.component';
import { ProposalInformationComponent } from './pages/components/form/proposal/dataProposal/components/proposal-information/proposal-information.component';
import { HistorialInformationComponent } from './pages/components/form/proposal/dataProposal/components/historial-information/historial-information.component';
import { CreateSympathizerComponent } from './pages/sympathizer/create-sympathizer/create-sympathizer.component';
import { ListKeyManager } from '@angular/cdk/a11y';
import { ListSympathizerComponent } from './pages/sympathizer/list-sympathizer/list-sympathizer.component';
import { SympathizerDetailComponent } from './pages/sympathizer/sympathizer-detail/sympathizer-detail.component';
import { RequestFormComponent } from './pages/requests/request-form/request-form.component';
import { RequestGeneralDataComponent } from './pages/requests/requestForm/request-general-data/request-general-data.component';
import { RequestClasificationComponent } from './pages/requests/requestForm/request-clasification/request-clasification.component';
import { RequestCompleteComponent } from './pages/requests/requestForm/request-complete/request-complete.component';
import { EditRequestFormComponent } from './pages/requests/edit-request-form/edit-request-form.component';
import { RequestEditClasificationComponent } from './pages/requests/editRequestForm/request-edit-clasification/request-edit-clasification.component';
import { RequestEditGeneralDataComponent } from './pages/requests/editRequestForm/request-edit-general-data/request-edit-general-data.component';
import { RequestEditCompleteComponent } from './pages/requests/editRequestForm/request-edit-complete/request-edit-complete.component';
import { RequestEditDocumentationComponent } from './pages/requests/editRequestForm/request-edit-documentation/request-edit-documentation.component';
import { DetailRequestComponent } from './pages/requests/detail-request/detail-request.component';
import { PromotedFormComponent } from './pages/promoted/promoted-form/promoted-form.component';
import { PromotedListComponent } from './pages/promoted/promoted-list/promoted-list.component';
import { PromotedDetailComponent } from './pages/promoted/promoted-detail/promoted-detail.component';
import { PromotedEditFormComponent } from './pages/promoted/promoted-edit-form/promoted-edit-form.component';

const routes: Routes = [

  {
    path: 'map/:idUser/:idWorkgroup/:token/:parentUrl/:sympathizer',
    pathMatch: 'full',
    canActivate: [AccesGuard],
    component: MapComponent,
  },
  {
    path: 'add/poi/:idUser/:idWorkGroup/:token/:environment',
    pathMatch: 'full',
    component: CreatePointComponent,
  },
  {
    path: 'add/poi/:idUser/:idWorkGroup/:token/:environment',
    pathMatch: 'full',
    component: CreatePointComponent,
    data: { some_data: 'some value' }
  },
  {
    path: 'poi/update/:id',
    pathMatch: 'full',
    component: CreatePointComponent,
  },
  {
    path: 'detail/poi/:id/:idUser/:idWorkGroup/:token/:environment',
    pathMatch: 'full',
    component: POIComponent,
  },
  {
    path: 'all/poi/:idUser/:idWorkGroup/:token/:environment',
    pathMatch: 'full',
    component: ListPointsComponent,
  },
  {
    path: 'panel-control/:idUser/:token',
    pathMatch: 'full',
    component: PanelControlComponent,
  },
  {
    path: 'dashboard/:idUser/:idWorkGroup/:token',
    pathMatch: 'full',
    component: DashboardComponent,
  },
  {
    path: 'requests-themes/:idUser/:idWorkGroup/:token',
    pathMatch: 'full',
    component: RequestsAndAccomplishedByThemesComponent,
  },
  {
    path: 'requests-subthemes/:idUser/:idWorkGroup/:token/:themeId',
    pathMatch: 'full',
    component: RequestsAndAccomplishedBySubthemesComponent,
  },
  {
    path: 'requests-categories/:idUser/:idWorkGroup/:token/:subthemeId',
    pathMatch: 'full',
    component: RequestsAndAccomplishedByCategoriesComponent,
  },
  {
    path: 'monthly-history',
    pathMatch: 'full',
    component: ClosedRequestsAndPendingRequestsByMonthComponent,
  },
  {
    path: 'annual-history/:idUser/:idWorkGroup/:token',
    pathMatch: 'full',
    component: ClosedRequestsAndPendingRequestsByYearComponent,
  },
  {
    path: 'historical-theme-month/:idUser/:idWorkGroup/:token/:month',
    pathMatch: 'full',
    component: ClosedRequestsAndPendingRequestsByThemeAndMonthComponent,
  },
  {
    path: 'historical-gender-month/:idUser/:idWorkGroup/:token/:month',
    pathMatch: 'full',
    component: ApplicantsGenderMonthComponent,
  },
  {
    path: 'historical-group-old-month/:idUser/:idWorkGroup/:token/:month',
    pathMatch: 'full',
    component: ApplicantsGroupOldMonthComponent,
  },

  {
    path: 'historical-grou-vulnerable-month/:idUser/:idWorkGroup/:token/:month',
    pathMatch: 'full',
    component: ApplicantsGroupVulnerableMonthComponent,
  },
  {
    path: 'historical-theme-year',
    pathMatch: 'full',
    component: ClosedRequestsAndPendingRequestsByThemeAndYearComponent,
  },
  {
    path: 'dashboard-annual-history/:idUser/:idWorkGroup/:token',
    pathMatch: 'full',
    component: AnnualHistoryComponent,
  },
  {
    path: 'solicitudes',
    pathMatch: 'full',
    component: SolicitudesComponent,
  },
  {
    path: 'estadisticasGenerales/:idUser/:idWorkGroup/:token',
    pathMatch: 'full',
    component: GeneralStadisticComponent,
  },
  {
    path: 'levantamiento/:idUser/:idWorkGroup/:token',
    pathMatch: 'full',
    component: PlaceOfFilingApplicationsComponent,
  },
  {
    path: 'typeOfGender/:idUser/:idWorkGroup/:token',
    pathMatch: 'full',
    component: TypeOfApplicantsByGenderAndTypeOfBeneficiaryByGenderComponent,
  },
  {
    path: 'typeOfAge/:idUser/:idWorkGroup/:token',
    pathMatch: 'full',
    component: TypeOfApplicantsByAgeAndTypeOfBeneficiaryByAgeComponent,
  },
  {
    path: 'groupVulnerable/:idUser/:idWorkGroup/:token',
    pathMatch: 'full',
    component: ApplicantsAndBeneficiariesByVulnerableGroupComponent,
  },
  {
    path: 'dataComplete/:idUser/:idWorkGroup/:token',
    pathMatch: 'full',
    component: DataCompleteComponent,
  },
  {
    path: 'proposalForm/:idUser/:idWorkGroup/:token/:environment',
    pathMatch: 'full',
    component: ProposalFormComponent,
    children: [{
      path: 'form',
      pathMatch: 'full',
      component: GeneralDataComponent,
    },
    {
      path: 'proposalInitiative',
      pathMatch: 'full',
      component: ProposalInitiativeComponent,
    },
    {
      path: 'completeProposal',
      pathMatch: 'full',
      component: CompleteProposalComponent,
    }]
  },
  {
    path: 'updateProposal/:idProposal/:idUser/:idWorkGroup/:token',
    pathMatch: 'full',
    component: ProposalFormComponent,
    children: [{
      path: 'form',
      pathMatch: 'full',
      component: GeneralDataComponent,
    },
    {
      path: 'proposalInitiative',
      pathMatch: 'full',
      component: ProposalInitiativeComponent,
    },
    {
      path: 'completeProposal',
      pathMatch: 'full',
      component: CompleteProposalComponent,
    }]
  },
  {
    path: 'proposalList/:idUser/:idWorkGroup/:token/:environment',
    pathMatch: 'full',
    component: ListComponent,
  },
  {
    path: 'dataProposal/:idProposal/:idWorkGroup/:token',
    pathMatch: 'full',
    component: DataProposalComponent,
    children: [{
      path: 'promoterInformation',
      pathMatch: 'full',
      component: PromoterInformationComponent,
    },
    {
      path: 'proposalInformation',
      pathMatch: 'full',
      component: ProposalInformationComponent,
    },
    {
      path: 'historialInformation',
      pathMatch: 'full',
      component: HistorialInformationComponent,
    },]
  },
  {
    path: 'createSympathizer/:idUser/:idWorkGroup/:token/:environment',
    pathMatch: 'full',
    component: CreateSympathizerComponent
  },
  {
    path: 'updateSympathizer/:idSympathizer/:idUser/:idWorkGroup/:token/:environment',
    pathMatch: 'full',
    component: CreateSympathizerComponent
  },
  {
    path: 'listSympathizer/:idUser/:idWorkGroup/:token/:environment',
    pathMatch: 'full',
    component: ListSympathizerComponent
  },
  {
    path: 'sympathizerDetail/:idSympathizer/:idWorkGroup/:token/:environment',
    pathMatch: 'full',
    component: SympathizerDetailComponent
  },
  {
    path: 'createRequest/:idUser/:idWorkGroup/:token/:environment',
    pathMatch:'full' ,
    component: RequestFormComponent,
    children: [{
      path: 'requestData',
      pathMatch: 'full',
      component: RequestGeneralDataComponent,
    },
    {
      path: 'requestClasification',
      pathMatch: 'full',
      component: RequestClasificationComponent,
    },
    {
      path: 'requestComplete',
      pathMatch: 'full',
      component: RequestCompleteComponent,
    }]
  },
  {
    path: 'updateRequest/:idRequest/:idUser/:idWorkGroup/:token/:environment',
    pathMatch:'full' ,
    component: EditRequestFormComponent,
    children: [{
      path: 'editRequestData',
      pathMatch: 'full',
      component: RequestEditGeneralDataComponent,
    },
    {
      path: 'editRequestClasification',
      pathMatch: 'full',
      component: RequestEditClasificationComponent,
    },
    {
      path: 'editRequestDocumentation',
      pathMatch: 'full',
      component: RequestEditDocumentationComponent,
    },
    {
      path: 'editRequestComplete',
      pathMatch: 'full',
      component: RequestEditCompleteComponent,
    }],
  },
  {
    path: 'detailRequest/:idRequest/:idUser/:idWorkGroup/:token/:environment',
    pathMatch:'full' ,
    component: DetailRequestComponent,
    // children: [{
    //   path: 'requestData',
    //   pathMatch: 'full',
    //   component: RequestGeneralDataComponent,
    // },
    // {
    //   path: 'requestClasification',
    //   pathMatch: 'full',
    //   component: RequestClasificationComponent,
    // },
    // {
    //   path: 'requestComplete',
    //   pathMatch: 'full',
    //   component: RequestCompleteComponent,
    // }]
  },
  {
    path: 'createPromoted/:idUser/:idWorkGroup/:token/:environment',
    pathMatch: 'full',
    component: PromotedFormComponent
  },
  {
    path: 'editPromoted/:idPromoted',
    pathMatch: 'full',
    component:PromotedEditFormComponent
  },
  {
    path: 'promotedList/:idUser/:idWorkGroup/:token/:environment',
    pathMatch: 'full',
    component: PromotedListComponent
  },
  {
    path: 'promotedDetail/:idPromoted/:idWorkGroup/:token/:environment',
    pathMatch: 'full',
    component: PromotedDetailComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
