import { Component, OnInit } from '@angular/core';
import { bottom } from '@popperjs/core';
import { Chart, registerables } from 'chart.js';
import { ActivatedRoute, Router } from '@angular/router';
import { StatsService } from 'src/app/shared/services/stats.service';
import { statusModel } from 'src/app/shared/models/status.model';
import { themeStatsModel } from 'src/app/shared/models/themeStats.model';


@Component({
  selector: 'app-requests-and-accomplished-by-categories',
  templateUrl: './requests-and-accomplished-by-categories.component.html',
  styleUrls: ['./requests-and-accomplished-by-categories.component.scss']
})
export class RequestsAndAccomplishedByCategoriesComponent implements OnInit {
  public chartStatusRequest: any;
  public chartStatusRequest2: any;
  chartData: any;
  theme!: any[];
  stock1!: any[];
  stock2!: any[];
  id!: any;

  labels: any[] = [];
  color1 = ['#70319f'];
  color2 = ['#fde69a'];
  grafico: any;

  constructor(
    private router: Router,
    private statsService: StatsService,
    private activeRoute: ActivatedRoute
  ) {}

  ngAfterViewInit() {}

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.getStatusCount().then((data) => {
      this.StatusRequestChart();
    });
  }

  getStatusCount() {
    const promise = new Promise((resolve, reject) => {
      this.activeRoute.params.subscribe((routeParams) => {
        console.log(routeParams);
        this.statsService
          .getCategoriesCount(routeParams['idWorkGroup'], routeParams['subthemeId'])
          .subscribe((data) => {
            console.log(data);
            this.id = data;
            this.theme = [];
            this.stock1 = [];
            this.stock2 = [];

            for (var i in data) {
              this.theme.push(data[i].categorieName);
              this.stock1.push(data[i].requests.openRequests);
              this.stock2.push(data[i].requests.closedRequests);
            }
            console.log(this.theme);
            console.log(this.stock1);
            console.log(this.stock2);

            this.chartData = {
              labels: this.theme,
              datasets: [
                {
                  label: 'Metas cumplidas',
                  backgroundColor: this.color1,
                  data: this.stock1,
                },
                {
                  label: 'Solicitudes',
                  backgroundColor: this.color2,
                  data: this.stock2,
                },
              ],
            };

            resolve(true);
          });
      });
    });
    return promise;
  }

  StatusRequestChart() {
  this.grafico = new Chart('ChartStatusRequest', {
    type: 'bar',
    data: this.chartData,
    options: {
      indexAxis: 'y',
      aspectRatio: 2.5,
      plugins: {
        legend: {
          position: 'bottom',
        },
      },
    },
  });
}

}
