import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProposalService } from 'src/app/shared/services/proposal.service';

@Component({
  selector: 'app-promoter-information',
  templateUrl: './promoter-information.component.html',
  styleUrls: ['./promoter-information.component.scss']
})
export class PromoterInformationComponent implements OnInit {
  idProposal:any;
  promoter: any = [];
  idWorkGroup: any;
  constructor(private proposalService: ProposalService, private activeRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.getProposal();
  }
  getProposal(){
    this.idProposal = this.activeRoute.snapshot.paramMap.get('idProposal');
    this.idWorkGroup = this.activeRoute.snapshot.paramMap.get('idWorkGroup');

    if (this.idProposal != null) {
      this.proposalService.getProposalData(this.idProposal,this.idWorkGroup).subscribe((response: any) => {
        this.promoter = response;
      })
    }
  }
}
