import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute } from '@angular/router';
import { FormDataService } from 'src/app/shared/services/form-data.service';
import { RequestService } from 'src/app/shared/services/request.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-edit-request-form',
  templateUrl: './edit-request-form.component.html',
  styleUrls: ['./edit-request-form.component.scss']
})
export class EditRequestFormComponent implements OnInit {
  @ViewChild('stepper') stepper!: MatStepper;

  formApplicant: FormGroup = this.fb.group({});
  formClasification: FormGroup = this.fb.group({});

  selectedTab: number = 0;
  search: string = '';
  applicantSelected: boolean = false;
  applicantSearch: any = [];
  idRequest: any;
  requestData: any = [];
  nameAssigned: string = '-';
  nameSecretary: string = '-';

  constructor(private activeRoute: ActivatedRoute,
    private requestService: RequestService,
    private formDataService: FormDataService,
    private fb: FormBuilder,
    private location: Location,
    private userService: UserService) {
    this.getUserAuth();
    this.getAndSendChilds();
  }

  ngOnInit(): void {
    this.getFormGroupApplicant();
    this.getFormGroupClasifcation();
  }

  getAndSendChilds(){
    this.idRequest = this.activeRoute.snapshot.paramMap.get('idRequest');
    this.requestService.getRequestData(this.idRequest).subscribe(data =>{
      this.requestData = data[0];
      this.userService.getUserRequestInfo(this.requestData.idAssigned).subscribe(data =>{
        this.nameAssigned = data[0]!.userName ?? '-';
        this.nameSecretary = data[0]!.positionInstance ?? '-';
      });
      this.formDataService.eventRequestData(this.requestData);
    });
  }

  getFormGroupApplicant(){
    this.formDataService.requestGeneralFormData$.subscribe(form => {
      if (form) {
        this.formApplicant = form;
      }
    });
  }

  getFormGroupClasifcation(){
    this.formDataService.requestClasificationFormData$.subscribe(form => {
      if (form) {
        this.formClasification = form;
      }
    });
  }

  getUserAuth() {
    this.activeRoute.params.subscribe(routeParams => {
      if (routeParams['token'] && routeParams['idWorkGroup'] && routeParams['idUser']) {
        localStorage.setItem('token', routeParams['token']);
        localStorage.setItem('idWorkGroup', routeParams['idWorkGroup']);
        localStorage.setItem('environment', routeParams['environment']);
        localStorage.setItem('idUser', routeParams['idUser']);
      }
    });
  }

  searchApplicant() {
    this.requestService.searchApplicant(this.search).subscribe((data: any) => {
      this.applicantSelected = false;
      this.applicantSearch = data;
    });
  }

  selectedApplicant(idApplicant:number,name:string){
    let applicantData = [];
    this.requestService.getApplicantData(idApplicant).subscribe(data =>{
      this.applicantSelected = true;
      applicantData = data[0];
      this.formDataService.eventApplicantSearchData(applicantData);
      this.search = name;
    });
  }

  onStepChange(event: any) {
    const isFormValid = this.formApplicant.valid && this.formClasification.valid;
    if(isFormValid === true){
      this.selectedTab = event.selectedIndex;
    }

  }

  changeSelected(index: number) {
    const isFormValid = this.formApplicant.valid && this.formClasification.valid;
    if(isFormValid === true){
      this.stepper.selectedIndex = index;
    } else {
      Swal.fire(
        {
          icon: 'error',
          title: 'No haz llenado todos los campos requeridos!',
          text: '',
          confirmButtonText: 'Aceptar'
        }
      );
    }
  }

  goBack(){
    this.location.back();
  }

  getStatusLabel(idStatus: number): string {
    switch (idStatus) {
      case 1:
        return 'Registro';
      case 2:
        return 'Asignado';
      case 3:
        return 'No viable';
      case 4:
        return 'Cancelado';
      case 5:
        return 'En proceso';
      case 6:
        return 'Meta cumplida';
      default:
        return 'Desconocido';
    }
  }
}
