import { Component, OnInit } from '@angular/core';
import {Chart, registerables } from 'chart.js'
import {ActivatedRoute, Router} from '@angular/router';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { StatsService } from 'src/app/shared/services/stats.service';

@Component({
  selector: 'app-place-of-filing-applications',
  templateUrl: './place-of-filing-applications.component.html',
  styleUrls: ['./place-of-filing-applications.component.scss']
})
export class PlaceOfFilingApplicationsComponent implements OnInit {
  public chartPlace: any;

  stock: any ;

  public loading = true;


  constructor(private router:Router, private statsService: StatsService, private activeRoute: ActivatedRoute){

  }
  ngOnInit(): void {
    this.loading = true;

    this.activeRoute.params.subscribe(routeParams => {
      localStorage.setItem('token', routeParams['token'])
    });

    Promise.all([this.getLiftingOfRequests()])
      .then(() => {

    this.StatusRequestChart();

        this.loading = false;
      })
      .catch(error => {
        console.error('Error while loading data:', error);
        this.loading = false; // asegurarse de quitar el loader en caso de error
      });
  }
  getLiftingOfRequests(){
    const promise = new Promise((resolve, reject) => {
      this.activeRoute.params.subscribe(routeParams => {
      this.statsService.getLiftingOfRequests(routeParams['idWorkGroup']).subscribe(data => {
          console.log(data);
          this.stock=data;
          console.log(this.stock);
          resolve(true);

        }
        )
      });
    });

    return promise;
  }

  StatusRequestChart(){
    this.chartPlace = new Chart("ChartPlace", {
       type: 'pie',
      data: {
        labels:['Campo','Oficina','Página de internet','Llamada telefónica', 'Correo electrónico','Facebook','WhatsApp', 'Bot', 'SolicitApp'],
	      datasets: [
          {
            data: [this.stock.results['Campo'], this.stock.results['Oficina'], this.stock.results['Página de internet'], this.stock.results['Llamada telefónica'], this.stock.results['Correo electrónico'],this.stock.results['Facebook'],this.stock.results['WhatsApp'],this.stock.results['Bot'],this.stock.results['SolicitApp']],
            backgroundColor: ['#ae088e', '#b5b67a', '#ed7d31', '#a5a5a5', '#ffc000', '#4472c4', '#70ad47', '#d7549e', '#bf3d39'],


          }
        ],

      },
      options: {
        indexAxis: 'y',
        aspectRatio:2.5,
        plugins: {
          legend: {
              position: 'bottom'
          },
      },


      }
    });

  }
}
