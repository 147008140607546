<head>
    <link href="https://cdnjs.cloudflare.com/ajax/libs/flowbite/1.8.0/flowbite.min.css" rel="stylesheet" />
</head>

<div class="cont-image" *ngIf="multiFile == true || (URLs.length == 0 && multiFile == false )">
    
    <label class="block mb-2 text-sm text-gesco dark:text-white" for="multiple_files">Agregar archivo(s)</label>

    <input *ngIf="multiFile;else single" class="block w-full text-sm text-gesco border-gesco rounded-lg cursor-pointer"
        type="file" id="multiple_files" [attr.accept]="acceptedFileTypes" multiple (change)="onFileChange($event)" />

    <ng-template #single>
        <input class="block w-full text-sm text-gesco border-gesco rounded-lg cursor-pointer" type="file" [attr.accept]="acceptedFileTypes" (change)="onFileChange($event)" />
    </ng-template>

    <p class="mt-1 text-sm text-gesco" id="file_input_help">{{descType}}</p>

</div>


<div class="row" *ngIf="URLs.length > 0">
    <div class="col">
        <div class="row ml-3">
            <label class="col-md-12 p-t-2">
                Archivos cargados:
            </label>

            <ng-container *ngFor="let url of URLs; let i = index">
                <div class="drop-file" (click)="openFile(url)">
                    <a target="_blank" class="alert alert-drop-file show text-left cursor-pointer">
                        <span class="close text-danger" data-idx="0" data-dismiss="alert"
                            (click)="removeFile(url)">×</span>
                        <i style="margin-top:-10px;" class="fa fa-file-image text-purple p-r-5 fa-3x pull-left"></i>
                        Archivo
                        adjunto {{i}}
                    </a>
                </div>
            </ng-container>

        </div>
    </div>
</div>