import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SpMapComponent } from './sp-map.component';



@NgModule({
  declarations: [SpMapComponent],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    NgxSkeletonLoaderModule,
  ],
  exports: [SpMapComponent],

})
export class SpMapModule { }
