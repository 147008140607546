<div *ngIf="loading; else loaded">
  <div class="loader"></div>
</div>

  <div>

    <div class="container">
      <div class="row graphicTwo">
        <div class="col-md-6">

          <ng-template #loaded>
            <div class="parent">
            <div class="div1">
            <h1>
            HISTÓRICO ANUAL <br />
            (EJERCICIO {{ currentYear }})
          </h1>
          <h2>Solicitudes pendientes y solicitudes cerradas por tema</h2>
            </div>
            <div class="div2">
          <h1>
            HISTÓRICO ANUAL <br />
            (EJERCICIO {{ lastYear }})
          </h1>
          <h2>Solicitudes pendientes y solicitudes cerradas por tema</h2>
        </div>
      </div>
        </ng-template>
          <div class="chart-container">
            <canvas id="StatStatusRequest">{{ chartTotalRequest2 }}</canvas>

          </div>
        </div>
        <div class="col-md-6">
          <div class="chart-container">
            <canvas id="StatTotalRequest">{{ chartStatusRequest2}}</canvas>
          </div>
        </div>
      </div>
    </div>
  </div>
