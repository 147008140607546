import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { categoriesModel } from 'src/app/shared/models/categories.model';
import { subThemesModel } from 'src/app/shared/models/subthemes.model';
import { themesModel } from 'src/app/shared/models/themes.model';
import { AnswersFormService } from 'src/app/shared/services/answers-form.service';
import { CustomeValidationService } from 'src/app/shared/services/custome-validation.service';
import { FormDataService } from 'src/app/shared/services/form-data.service';
import { MapService } from 'src/app/shared/services/map.service';
import { RequestService } from 'src/app/shared/services/request.service';

@Component({
  selector: 'app-request-clasification',
  templateUrl: './request-clasification.component.html',
  styleUrls: ['./request-clasification.component.scss']
})
export class RequestClasificationComponent implements OnInit {

  clasificationForm: FormGroup = this.FB.group({
    theme_id: ['', [Validators.required]],
    subtheme_id: [{ value: '', disabled: true }, [Validators.required]],
    categories_id: [{ value: '', disabled: true }, [Validators.required]],
    description: ['', [Validators.maxLength(250)]],
    quantity_c: ['1'],
    amount: [''],
    urgent: [''],
    ordenUrgent: [0],
    solve: [0],
    aditionBe: [''],
    solicitingBeneficiary: [0],
    relationshipBeneficiary: [''],
    nameB: [''],
    next_nameB: [''],
    last_nameB: [''],
    curpB: ['', [this.curpValidator.bind(this)]],
    birthDateB: [''],
    genderB: [''],
    birthPlaceB: [''],
    birthPlaceMB: [''],
    cellPhoneB: [''],
    institutionBe: [0],
    institution: [''],
    enrollment: [''],
    educationLevel: [''],
    disciplineClass: [''],
    typeDiscipline: [''],
    disciplineNivel: [''],
    sport: [''],
    level: [''],
    categorie: [''],
    purposeScholarship: [''],
    typeScholarship: [''],
    health: [''],
    social: [''],
    federalSupportForm: [''],
    typeSupportForm: [''],
    street_sp: [''],
    streetNum_sp: [''],
    postalCode_sp: [''],
    idPostalCode_sp: [''],
    idNeighborhood_sp: [{ value: '', disabled: true }],
    municipality_id: [''],
    entity_id: [''],
    information: [''],
    vulnerable: [''],
    origen: ['1']
  });

  allowedIds: number[] = [208, 166, 181, 182, 195, 196, 197, 61, 64, 71, 223, 215, 216, 218, 219, 220, 221, 222, 224, 226, 74, 84, 161, 199, 200, 201, 202, 203, 204];
  allowedIdsInstitution: number[] = [208, 181, 182, 195, 196, 197];
  allowedIdsHealth: number[] = [61, 64, 71];
  allowedIdsUrgent: number[] = [61, 64, 71, 105, 212, 215];
  allowedIdsAdition: number[] = [29, 77, 92, 101, 109, 114, 119, 3, 69, 116, 117];

  showAditionBeneficiaries: boolean = false;
  showInstitution: boolean = false;
  showHealth: boolean = false;
  showUrgent: boolean = false;

  themes: Array<themesModel> = [];
  subthemes: Array<subThemesModel> = [];
  categories: Array<categoriesModel> = [];
  information: any[] = [];
  groupsVuln: any[] = [];
  educationLevel: any[] = [];
  searchValue: string = '';
  responseSearch: any = [];
  selectedSearch: boolean = false;
  idProposal: any;
  postalCodeSelectedClas: boolean = false;
  postalCodeSearchClas: any[] = [];
  municipalitieName: String = '';
  entitieName: String = '';
  entities: any = '';
  municipalities: any = '';
  neighborhoodOptions: any[] = [];
  selectedSubthemeId: any;
  showSolicitingBeneficiary: boolean = false;
  selectedThemeId: any;
  typeDiscipline: any[] = [];
  purposeScholarship: any[] = [];
  typeScholarship: any[] = [];
  disciplineClass: any[] = [];
  disciplineNivel: any[] = [];
  sport: any[] = [];
  level: any[] = [];
  categorieF: any[] = [];
  health: any[] = [];
  federalSupportForm: any[] = [];
  typeSupportForm: any[] = [];

  lat: number = 25.794452215964732;
  long: number = -100.37729304123305;
  location: any;
  selectedFiles: any = [];
  //AllUrlFiles: any;

  constructor(
    private FB: FormBuilder,
    private formDataService: FormDataService,
    private requestService: RequestService,
    private activeRoute: ActivatedRoute,
    private customizeService: CustomeValidationService,
    private answersFormService: AnswersFormService,
    private mapingService: MapService

  ) {
    this.attachInputHandlers();
  }

  ngOnInit(): void {
    this.getThemes();
    this.clasificationForm.valueChanges.subscribe(data => {
      this.formDataService.setRequestClasificationFormData(this.clasificationForm, this.selectedFiles);
    });
  }

  curpValidator(control: FormControl): { [key: string]: any } | null {
    const curp = control.value;
    if (curp?.length != 0 && curp != null) {
      const isValid = this.customizeService.validCurp(curp);

      if (isValid) {
        return null; // La CURP es válida
      } else {
        return { 'invalidCurp': true }; // La CURP es inválida
      }
    } else {
      return null;
    }
  }

  onSearchChange(newValue: string) {
    this.searchValue = newValue.toUpperCase();
  }

  getDirection() {

    const _street = this.clasificationForm.get('street_sp')?.value;
    const _numExt = this.clasificationForm.get('streetNum_sp')?.value;
    const _pc = this.clasificationForm.get('postalCode_sp')?.value;
    const _colony = this.clasificationForm.get('idNeighborhood_sp')?.value;
    const _munic = this.clasificationForm.get('municipality_id')?.value;
    const _estad = this.clasificationForm.get('entity_id')?.value;

    this.getLocation(_street, _numExt, _colony, _munic, _pc, _estad);

  }

  getLocation(_street: number, _numExt: number, _colony: number, _munic: number, _pc: number, _estad: number) {
    this.mapingService.getLocationByDirection(_street, _numExt, _munic, _pc, _estad).subscribe((data: any) => {
      this.location = data;

      if (data) {
        this.lat = data.split(',')[0];
        this.long = data.split(',')[1];
      }

      console.log(data);
    });
  }

  attachInputHandlers() {
    const nameFields = ['nameB', 'next_nameB', 'last_nameB'];
    const uppercaseFields = ['curpB', 'street_sp', 'description', 'institution'];
    const numberFields = ['cellPhoneB'];
    uppercaseFields.forEach(fieldName => {
      const fieldControl = this.clasificationForm.get(fieldName);
      if (fieldControl) {
        fieldControl.valueChanges.subscribe((newValue: string) => {
          if (newValue) {
            this.clasificationForm.get(fieldName)?.setValue(newValue.toUpperCase(), { emitEvent: false });
          }
        });
      }
    });
    nameFields.forEach(fieldName => {
      const fieldControl = this.clasificationForm.get(fieldName);
      if (fieldControl) {
        fieldControl.valueChanges.subscribe((newValue: string) => {
          if (newValue) {
            this.clasificationForm.get(fieldName)?.setValue(newValue.toUpperCase().replace(/[^A-Z\s]/g, ''), { emitEvent: false });
          }
        });
      }
    });
    numberFields.forEach(fieldName => {
      const fieldControl = this.clasificationForm.get(fieldName);
      if (fieldControl) {
        fieldControl.valueChanges.subscribe((newValue: string) => {
          if (newValue) {
            this.clasificationForm.get(fieldName)?.setValue(newValue.replace(/\D/g, ''), { emitEvent: false });
          }
        });
      }
    });
  }

  getThemes() {
    this.requestService.getAllThemes().subscribe(data => {
      this.themes = data;
    });
  }

  async getSubthemes(themeId: number) {
    const promise = new Promise((resolve, reject) => {
      if (themeId != null) {
        this.clasificationForm.controls['subtheme_id'].enable()
        this.requestService.getAllSubthemes(themeId).subscribe(data => {
          this.subthemes = data;
          resolve(true);
        });
      }
    });
    return promise;
  }

  async getCategories(subthemeId: number) {
    const promise = new Promise((resolve, reject) => {
      if (subthemeId != null) {
        this.clasificationForm.controls['categories_id'].enable()
        this.requestService.getAllCategories(subthemeId).subscribe(data => {
          this.categories = data;
          resolve(true);
        });
      }
    });
    return promise;
  }

  onThemeSelectChange() {
    this.clasificationForm.controls['subtheme_id'].enable();
    this.getSubthemes(this.clasificationForm.get('theme_id')?.value);
    this.getFormInformation();
  }

  onSubthemeSelectChange() {
    this.clasificationForm.controls['categories_id'].enable();
    this.getCategories(this.clasificationForm.get('subtheme_id')?.value);
    this.displayForm();
  }

  displayForm() {
    this.selectedSubthemeId = this.clasificationForm.get('subtheme_id')?.value;
    this.showSolicitingBeneficiary = this.allowedIds.includes(parseInt(this.selectedSubthemeId));
    this.showInstitution = this.allowedIdsInstitution.includes(parseInt(this.selectedSubthemeId));
    this.showHealth = this.allowedIdsHealth.includes(parseInt(this.selectedSubthemeId));
    this.showUrgent = this.allowedIdsUrgent.includes(parseInt(this.selectedSubthemeId));
    if (this.showSolicitingBeneficiary) {
      this.getEntities();
    }
    this.showAditionBeneficiaries = this.allowedIdsAdition.includes(parseInt(this.selectedSubthemeId));
    this.getDropdownsAnswers();
  }

  getFormInformation() {
    this.answersFormService.getFormType(this.clasificationForm.get('theme_id')?.value, 'information').subscribe(data => {
      this.information = data;
      if(data.length == 1){
        this.clasificationForm.controls['information'].disable();
        this.clasificationForm.controls['information'].setValue(data[0].id);
      }
    });
    this.answersFormService.getFormType(this.clasificationForm.get('theme_id')?.value, 'vulnerable').subscribe(data => {
      this.groupsVuln = data;
    });
  }

  getDropdownsAnswers() {
    if (this.showInstitution) {
      this.answersFormService.getFormType(this.clasificationForm.get('theme_id')?.value, 'educationLevel').subscribe(data => {
        this.educationLevel = data;
      });
    }

    if (this.clasificationForm.get('subtheme_id')?.value == 208) {
      this.answersFormService.getFormType(this.clasificationForm.get('theme_id')?.value, 'typeDiscipline').subscribe(data => {
        this.typeDiscipline = data;
      });
      this.answersFormService.getFormType(this.clasificationForm.get('theme_id')?.value, 'purposeScholarship').subscribe(data => {
        this.purposeScholarship = data;
      });
      this.answersFormService.getFormType(this.clasificationForm.get('theme_id')?.value, 'typeScholarship').subscribe(data => {
        this.typeScholarship = data;
      });
    }

    if (this.clasificationForm.get('subtheme_id')?.value == 181) {
      this.answersFormService.getFormType(this.clasificationForm.get('theme_id')?.value, 'disciplineClass').subscribe(data => {
        this.disciplineClass = data;
      });
      this.answersFormService.getFormType(this.clasificationForm.get('theme_id')?.value, 'disciplineNivel').subscribe(data => {
        this.disciplineNivel = data;
      });
    }

    if (this.clasificationForm.get('subtheme_id')?.value == 196) {
      this.answersFormService.getFormType(this.clasificationForm.get('theme_id')?.value, 'sport').subscribe(data => {
        this.sport = data;
      });
      this.answersFormService.getFormType(this.clasificationForm.get('theme_id')?.value, 'level').subscribe(data => {
        this.level = data;
      });
      this.answersFormService.getFormType(this.clasificationForm.get('theme_id')?.value, 'categorie').subscribe(data => {
        this.categorieF = data;
      });
    }

    if (this.showHealth) {
      this.answersFormService.getFormType(this.clasificationForm.get('theme_id')?.value, 'health').subscribe(data => {
        this.health = data;
      });
      this.answersFormService.getFormType(this.clasificationForm.get('theme_id')?.value, 'federalSupportForm').subscribe(data => {
        this.federalSupportForm = data;
      });
    }
  }

  changeDiscipline(event: any) {
    this.answersFormService.getFormType(parseInt(event.target.value), 'typeartCult').subscribe(data => {
      this.typeDiscipline = data;
    });
  }

  changeFederalSupport(event: any) {
    this.answersFormService.getFormType(parseInt(event.target.value), 'typeSupportForm').subscribe(data => {
      this.typeSupportForm = data;
    });
  }

  searchCategory() {
    this.getSearchCategory(this.searchValue);
  }

  getSearchCategory(search: string) {
    this.requestService.getSearchCategory(search).subscribe(data => {
      this.responseSearch = data;
      this.selectedSearch = false;
    });
  }

  selectOption(ids: string) {
    const idsArray = ids.split(',');
    this.selectedSearch = true;
    this.clasificationForm.controls['theme_id'].setValue(idsArray[0]);
    this.getFormInformation();
    this.getSubthemes(this.clasificationForm.get('theme_id')?.value).then(() => {
      this.clasificationForm.controls['subtheme_id'].setValue(idsArray[1]);
      this.displayForm();
      this.getCategories(this.clasificationForm.get('subtheme_id')?.value).then(() => {
        this.clasificationForm.controls['categories_id'].setValue(idsArray[2]);
      });
    });
  }

  onFileChange(event: any) {
    const files: FileList = event.target.files;

    for (let i = 0; i < files.length; i++) {
      this.selectedFiles.push({ file: files[i] });
    }
    console.log(this.selectedFiles);
  }

  updateCheckbox(event: Event) {
    if (event && event.target instanceof HTMLInputElement) {
      this.clasificationForm.get('urgent')?.setValue(event.target.checked ? 1 : null);
    }
  }

  searchPostalCodeClas() {
    this.requestService.searchPostalCode(this.clasificationForm.controls['postalCode_sp'].value).subscribe((data: any) => {
      this.postalCodeSelectedClas = false;
      this.postalCodeSearchClas = data;
    });
  }

  selectPostalCode(postalCode: String) {
    this.getDirectionByPostalCode(postalCode);
  }

  getDirectionByPostalCode(postalCode: String) {
    const idPC = this.postalCodeSearchClas.find((option: any) => option.identifier == postalCode);

    this.clasificationForm.controls['postalCode_sp'].setValue(postalCode);
    this.clasificationForm.controls['municipality_id'].reset();
    this.clasificationForm.controls['entity_id'].reset();

    this.requestService.getDirectionByPostalCode(postalCode).subscribe(
      (data: any) => {
        this.postalCodeSelectedClas = true;

        this.municipalitieName = data.municipality.name;
        this.clasificationForm.controls['municipality_id'].setValue(data.municipality.id);

        this.entitieName = data.entity.name;
        this.clasificationForm.controls['entity_id'].setValue(data.entity.id);
        // Clear previous neighborhood options
        this.neighborhoodOptions = [];

        if (data.neighborhoods.length > 1) {
          this.clasificationForm.controls['idNeighborhood_sp'].enable();
          this.neighborhoodOptions = data.neighborhoods;
          this.getDirection();

        } else {
          this.clasificationForm.controls['idNeighborhood_sp'].enable();
          this.neighborhoodOptions = data.neighborhoods;
          this.getDirection();
          this.clasificationForm.controls['idNeighborhood_sp'].setValue(data.neighborhoods[0].id);
        }
      },
      (error) => {
        console.error(error); // Maneja el error de manera adecuada
      }
    );
    this.clasificationForm.controls['idPostalCode_sp'].setValue(idPC.id);

  }

  getEntities(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.requestService.getEntities().subscribe(
        (data: any) => {
          this.entities = data;
          resolve(data); // Resolvemos la promesa con los datos
        },
        (error) => {
          reject(error); // Rechazamos la promesa en caso de error
        }
      );
    });
  }

  getMunicipalities(idEntity: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.requestService.getMunicipalities(idEntity).subscribe(
        (data: any) => {
          this.municipalities = data;
          resolve(data); // Resolvemos la promesa con los datos
        },
        (error) => {
          reject(error); // Rechazamos la promesa en caso de error
        }
      );
    });
  }

  async onFirstSelectChange() {
    this.clasificationForm.controls['birthPlaceMB'].enable();
    try {
      const idEntity = this.clasificationForm.get('birthPlaceB')?.value;
      await this.getMunicipalities(idEntity);
      // Aquí puedes continuar con cualquier lógica que dependa de las municipalities
    } catch (error) {
      console.error('Error al obtener las municipalities:', error);
    }
  }
}
