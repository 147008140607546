import { Component, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { Location } from '@angular/common';
import { PromotedService } from 'src/app/shared/services/promoted.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormDataService } from 'src/app/shared/services/form-data.service';
import { PlayerComponent } from '../../components/modal/player/player.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-promoted-detail',
  templateUrl: './promoted-detail.component.html',
  styleUrls: ['./promoted-detail.component.scss']
})
export class PromotedDetailComponent implements OnInit {
  @ViewChild('stepper') stepper!: MatStepper;

  selectedTab: number = 0;
  promoted: any = [];
  idPromoted: any;
  questions: any = [];
  AllurlParticipation: any = [];
  urlIne: any = [];
  issues: any = [];
  visits: any = [];
  promotedHistories: any = [];
  idWorkGroup: any = '';
  otherVisits: any = [];
  formID = 1;
  surveyTittle: any;

  constructor(private location: Location, private router: Router, public dialog: MatDialog,

    private promotedService: PromotedService, public formDataService: FormDataService, private activeRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.idPromoted = this.activeRoute.snapshot.paramMap.get('idPromoted');
    this.idWorkGroup = this.activeRoute.snapshot.paramMap.get('idWorkGroup');

    this.getData();
    this.getAccountSurveyId();
  }


  getAccountSurveyId() {
    this.formDataService.getAccountSurveyId(this.idWorkGroup).subscribe((data: any) => {
      this.formID = data.form_id;
      this.getSurvey(data.form_id);
    });
  }

  getVisit() {
    if (this.idPromoted != undefined) {
      this.promotedService.getVisitData(this.idPromoted).subscribe(data => {
        console.log(data);
        this.visits = data;
      });
    }
  }

  getOtherVisits() {
    if (this.idPromoted != undefined) {
      this.promotedService.getOtherVisitsData(this.idPromoted).subscribe(data => {
        console.log(data);
        this.otherVisits = data;
      });
    }
  }

  goToUpdate(id: number, idUser: number) {
    // this.router.navigate(['/updateProposal/' + id + '/' + idUser + '/' + this.idWorkGroup + '/' + localStorage.getItem('token')]);
    this.router.navigate(['/editPromoted/' + id]);

  }

  openLink(url: string) {
    window.open(url, '_blank');
  }

  getFileName(url: string): string {
    const parts = url.split('/');
    return decodeURIComponent(parts[parts.length - 1]);
  }

  openFile(url: string) {
    if (/\.pdf$/i.test(url)) {
      // Es un archivo PDF, abrir en una nueva ventana
      window.open(url, '_blank');
    } else {
      // No es un archivo PDF, realizar alguna acción o manejar según sea necesario
      let dialogRef = this.dialog.open(PlayerComponent, {
        data: { selectedMedia: url },
        maxHeight: '80%',
        maxWidth: '80%',
      });
      dialogRef.afterClosed().subscribe(result => {

      });
    }
  }

  getData() {
    this.promotedService.getPromoted(this.idPromoted).subscribe((response: any) => {
      this.promoted = response;
      this.issues = response.issues || [];
      console.log(this.issues)
    })

    this.getVisit();
    this.getOtherVisits();
    this.getPromotedHistories();
  }

  getSurvey(survey_id: number) {
    this.formDataService.getSurveyUpdate(survey_id, this.idPromoted).subscribe((data: any) => {
      console.log(data);
      this.surveyTittle = data.name;
      this.questions = data.form_questions;

    })
  }

  getPromotedHistories() {
    this.promotedService.getHistoryData(this.idPromoted).subscribe((response: any) => {
      this.promotedHistories = response;
      this.getVisit();
    });
  }
  goBack() {
    this.location.back();
  }

  onStepChange(event: any) {
    this.selectedTab = event.selectedIndex;
  }

  changeSelected(index: number) {
    this.stepper.selectedIndex = index;
  }
}
