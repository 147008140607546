import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { FormGroup } from '@angular/forms';
import { tagsModel } from '../models/tags.model';
@Injectable({
  providedIn: 'root'
})
export class MapService {
  url = environment.apiUrl;
  mapsKey = environment.mapsKey;

  onSelectStatus = new EventEmitter<{ idStatus: number }>();
  onSelectButton = new EventEmitter<{ idStatus: number }>();
  onClearStatus = new EventEmitter<boolean>();
  onFilterSpecific = new EventEmitter<any>();
  onClearSecction = new EventEmitter<boolean>();

  dataSearch = new EventEmitter<{ results: [] }>();
  loading = new EventEmitter<boolean>();
  onPublicServiceButton = new EventEmitter<{ idWorkGroup: string }>();
  onVisitedBeneficiaryButton = new EventEmitter<{ idUser: number }>();
  onMunicipalitieChange = new EventEmitter<{ idMunicipalitie: number, idEntitie: number }>();
  onEntitieChange = new EventEmitter<{ idEntitie: number }>();

  onMunicipalitieSelected = new EventEmitter<boolean>();
  onPOI = new EventEmitter<boolean>();

  selectedIdStatus = 0;


  constructor(public http: HttpClient) {
  }


  specificFilter(idworkgroup: string, FormGroup: any, level: number, idUser: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };

    console.log(FormGroup);
    return request = this.http.post(this.url + 'specificFilter/' + idworkgroup + '/' + level + '/' + idUser,
      {
        idEntity: FormGroup.entity,
        idMunicipality: parseInt(FormGroup.municipalities),
        idNeighborhood: FormGroup.neighborhoods,
        idTheme: FormGroup.themes,
        idSubtheme: FormGroup.subthemes,
        idCategorie: FormGroup.categories,
        electoralSection: FormGroup.seccion,
        proposals: FormGroup.proposals,
        visits: FormGroup.visits,
        promoted_position: FormGroup.promoted_position,

      }, options
    );
  }

  getNameProfile(idMunicipality: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any[]>(this.url + 'request/municipality/name/' + idMunicipality, options);
  }

  getSections(idMunicipality: number, idEntitie: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any[]>(this.url + 'secctions/by/' + idMunicipality + '/' + idEntitie, options);
  }

  getSectionsByUser(idUser: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any[]>(this.url + 'sections/user/' + idUser, options);
  }


  getLocationByDirection(_street: number, _numExt: number,  _munic: number, _pc: number, _estad: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any[]>(this.url + 'coordinates/' + _street + '/' + _numExt  + '/' + _munic + '/' + _pc + '/' + _estad, options);
  }

  getLocationByName(name: string) {
    let request;

    return request = this.http.get<any[]>('https://maps.googleapis.com/maps/api/geocode/json?address=' + name + '&key=' + this.mapsKey);
  }

  filterByStatus(idworkgroup: string, idstatus: number, level: number, idUser: number) {

    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any[]>(this.url + 'pin/getStatusFilterPin/' + idworkgroup + "/" + idstatus + "/" + level + "/" + idUser, options);

  }


  filterByBenficiary(idworkgroup: string, level: number, idUser: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any[]>(this.url + 'getBeneficiaryFilter/' + idworkgroup + '/' + level + '/' + idUser, options);
  }

  filterByAssigned(idAssigned: number, idworkgroup: string) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any[]>(this.url + 'myRequests/' + idAssigned + '/' + idworkgroup, options);
  }


  getSympathizers(idworkgroup: string, level: number, idUser: number) {
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    let request = this.http.get<any[]>(this.url + 'getSympathizers/' + idworkgroup + '/' + level + '/' + idUser, options)

    return request;
  }

  getPOIsMarkers(idworkgroup: string, level: number, idUser: number) {
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    let request = this.http.get<any[]>(this.url + 'location/POI/' + idworkgroup + '/' + level + '/' + idUser, options)

    return request;
  }

  getPromotedMarkers(idworkgroup: string, level: number, idUser: number) {
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    let request = this.http.get<any[]>(this.url + 'location/promoted/' + idworkgroup + '/' + level + '/' + idUser, options)
    return request;
  }


  getMarkerCords(idworkgroup: string, level: number, idUser: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any[]>(this.url + 'pin/' + idworkgroup + '/' + level + '/' + idUser, options);
  }

  getPinInformation(idRequest: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any[]>(this.url + 'pinInformation/' + idRequest, options);
  }
  
  getPinProposalInformation(idPromoter: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any[]>(this.url + 'pinInformation/promoter/' + idPromoter, options);
  }

  getPinSympathizerInformation(idSympathizer: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any[]>(this.url + 'pinInformation/sympathizer/' + idSympathizer, options);
  }

  getPinPromotedInformation(id: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any[]>(this.url + 'promoted/pinInformation/' + id, options);
  }


  getLayer(type: String, nomenclature: string, id: number) {
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    let request = this.http.get<any[]>(this.url + 'getLayer/' + type + '/' + nomenclature + '/' + id, options)
    return request;
  }
  getAllCountryLayer() {
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    let request = this.http.get<any[]>(this.url + 'get/layer/states/all', options)
    return request;
  }
  getEntitieLayer(entitieId: number) {
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    let request = this.http.get<any[]>(this.url + 'getEstatesLayer/' + entitieId, options)
    return request;
  }
  getNomenclature(idEntity: number) {
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    let request = this.http.get<any[]>(this.url + 'getNomenclature/' + idEntity, options)
    return request;
  }
  getPublicService(idWorkGroup: string, level: number, idUser: number) {
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    let request = this.http.get<any[]>(this.url + 'getPublicServices/' + idWorkGroup + '/' + level + '/' + idUser, options);
    return request;
  }
  getBeneficiaryVisited(idUser: number) {
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    let request = this.http.get<any[]>(this.url + 'getBeneficiaryVisited/' + idUser, options);
    return request;
  }
  getSearch(search: string, idWorkGroup: string) {
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    let request = this.http.get<any[]>(this.url + 'search/' + search + '/' + idWorkGroup, options);
    return request;
  }

  getLocalDistrictsByEntities(idEntitie: number) {
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    let request = this.http.get<any[]>(this.url + 'localDistricts/' + idEntitie, options);
    return request;
  }

  getFederalDistrictsByEntities(idEntitie: number) {
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    let request = this.http.get<any[]>(this.url + 'federalDistricts/' + idEntitie, options);
    return request;
  }


  getSecctionByDistricts(idFD: number, idLD: number, idE: number) {
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    let request = this.http.get<any[]>(this.url + 'sections/by/districts/' + idFD + '/' + idLD + '/' + idE, options);
    return request;
  }


  getEstatesLayer(id: number) {
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    let request = this.http.get<any[]>(this.url + 'getEstatesLayer/' + id, options)
    return request;
  }

  getMunicipalitieLayer(nomenclature: string, idMunicipalitie: number, idEntitie: number) {
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    let request = this.http.get<any[]>(this.url + 'getMunicipalitieLayer/' + nomenclature + '/' + idMunicipalitie + '/' + idEntitie, options)
    return request;
  }

  getMunicilapiltyNomenclature(id: number) {
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    let request = this.http.get<any[]>(this.url + 'getNomenclature/municipalitie' + id, options)
    return request;
  }

  getLocalDistrictLayer(type: String, nomenclature: string) {
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    let request = this.http.get<any[]>(this.url + 'getLocalDistrictLayer/' + type + '/' + nomenclature, options)
    return request;
  }

  getNeighborhoodsLayer(nomenclature: string, idMunicipalitie: number) {
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    let request = this.http.get<any[]>(this.url + 'getNeighborhoodLayer/' + nomenclature + '/' + idMunicipalitie, options)
    return request;
  }
  getFederalDistrictsLayer(idEntitie: number) {
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    let request = this.http.get<any[]>(this.url + 'getFederalDistrictsLayer/' + idEntitie, options)
    return request;
  }

  getSecctionLayer(nomenclature: string, idMunicipalitie: number, idEntitie: number, type: number) {
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    let request = this.http.get<any[]>(this.url + 'getSecctionLayer/' + nomenclature + '/' + idMunicipalitie + '/' + idEntitie + '/' + type, options)
    return request;
  }

}
