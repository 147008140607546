import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute } from '@angular/router';
import { FormDataService } from 'src/app/shared/services/form-data.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';

@Component({
  selector: 'app-proposal-form',
  templateUrl: './proposal-form.component.html',
  styleUrls: ['./proposal-form.component.scss']
})
export class ProposalFormComponent implements OnInit {
  @ViewChild('stepper') stepper!: MatStepper;

  formPromoter: FormGroup = this.fb.group({});
  formProposal: FormGroup = this.fb.group({});
  formComplete: FormGroup = this.fb.group({});

  selectedTab: number = 0;
  idWorkGroup: any;
  idUser: number = 0;
  idProposal: any;

  constructor( private route: ActivatedRoute, private formDataService: FormDataService, private fb: FormBuilder,private location: Location) {
    this.isUpdating();
   }

  ngOnInit(): void {
    this.getUserToken();
    this.route.params.subscribe(params => {
      this.idWorkGroup = params['idWorkGroup'];
      this.idUser = params['idUser'];
    });
    this.getFormGroupPromoter();
    this.getFormGroupProposal();
    // this.getFormGroupComplete();
  }

  isUpdating() {
    this.idProposal = this.route.snapshot.paramMap.get('idProposal');
  }

  getUserToken() {
    if(this.idProposal == null || this.idProposal == undefined){
      this.route.params.subscribe(routeParams => {
        localStorage.setItem('token', routeParams['token']);
        localStorage.setItem('environment', routeParams['environment']);
        localStorage.setItem('idWorkGroup', routeParams['idWorkGroup']);
      });
    }
  }

  getFormGroupPromoter(){
    this.formDataService.generalFormData$.subscribe(form => {
      if (form) {
        this.formPromoter = form;
      }
    });
  }

  getFormGroupProposal(){
    this.formDataService.proposalFormData$.subscribe(form =>{
      if(form) {
        this.formProposal = form;
      }
    });
  }

  // getFormGroupComplete(){
  //   this.formDataService.completeFormData$.subscribe(form =>{
  //     if(form) {
  //       this.formComplete = form;
  //     }
  //   });
  // }

  onStepChange(event: any) {
    const isFormValid = this.formPromoter.valid && this.formProposal.valid;
    if(isFormValid === true){
      this.selectedTab = event.selectedIndex;
    }
  }

  changeSelected(index: number) {
    const isFormValid = this.formPromoter.valid && this.formProposal.valid;
    if(isFormValid === true){
      this.stepper.selectedIndex = index;
    } else {
      Swal.fire(
        {
          icon: 'error',
          title: 'No haz llenado todos los campos requeridos!',
          text: '',
          confirmButtonText: 'Aceptar'
        }
      );
    }
  }

  goBack(){
    this.location.back();
  }
}
