<!-- <head>
    <link href="https://cdnjs.cloudflare.com/ajax/libs/flowbite/1.8.0/flowbite.min.css" rel="stylesheet" />
</head> -->
<div class="contenedor">
    <div class="back btn btn-sm ml-3 mt-3">
        <i class="fa fa-arrow-left fa-lg" style="cursor: pointer;" (click)="goBack()" aria-hidden="true"></i>
    </div>
    <h1 class="page-header">Datos de la solicitud &nbsp;<small></small></h1>
    <hr>
    <div class="row m-3">
        <div class="col data">
            <u style="text-decoration: none">
                <li>Estatus: <b>{{ getStatusLabel(requestData.idstatus) }}</b></li>
                <li>Asignado a: <b>{{ nameAssigned }}</b></li>
                <li>Fecha de inicio: <b>{{requestData.created_at}}</b></li>
            </u>
        </div>
        <div class="col data d-grid gap-2 d-md-flex justify-content-md-end align-items-end">
            <button *ngIf="requestData.idstatus == 1 || (requestData.idAssigned != idUser && requestData.idstatus == 2)" class="btn btn-secondary btn-inverse mr-1 buttonOptions" (click)="openModal('attend')"><i class="fa fa-hand-pointer"></i> Atender solicitud</button>
            <button *ngIf="requestData.idstatus == 1" class="btn btn-secondary btn-inverse mr-1 buttonOptions" (click)="openModal('assign')"><i class="fa fa-tasks"></i> Asignar solicitud</button>
            <button *ngIf="requestData.idstatus == 2" class="btn btn-secondary btn-inverse mr-1 buttonOptions" (click)="openModal('reassign')"><i class="fa fa-tasks"></i> Resignar solicitud</button>
            <button *ngIf="requestData.idstatus == 2" class="btn btn-secondary btn-inverse mr-1 buttonOptions" (click)="openModal('seg')"><i class="fa fa-hand-pointer"></i> Seguimiento</button>
            <button *ngIf="requestData.idstatus == 5 && requestData.idAssigned == idUser" class="btn btn-secondary btn-inverse mr-1 buttonOptions" (click)="openModal('close')"><i class="fa fa-sync"></i> Cambiar estatus</button>
            <button class="btn btn-secondary btn-inverse mr-1 buttonOptions" (click)="goToUpdate(requestData.idrequests,idUser)"><i class="fa fa-edit"></i> Editar</button>
            <button class="btn btn-secondary btn-inverse mr-1 buttonOptions" (click)="goToEmail(requestData.idrequests)"><i class="fa fa-envelope"></i> Correo</button>
            <a *ngIf="requestData.idstatus == 5 || requestData.idstatus == 6" class="btn btn-secondary btn-inverse mr-1 buttonOptions" href="{{url}}acknowledgeInfo/{{requestData.idrequests}}" target="_blank"><i class="fa fa-download"></i> Acuse</a>
            <div ngbDropdown class="d-inline-block">
                <button class="btn btn-secondary dropdown-toggle mr-1 buttonOptions" id="dropdownBasic1" ngbDropdownToggle><i class="fa fa-file-download"></i> Reportes</button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <a ngbDropdownItem class="btn btn-sm btn-block btn-white selectHover" style="color: black;" href="{{url}}report/{{requestData.idrequests}}" target="_blank"> <i class="fa fa-file-pdf" style="color:#333;"></i> PDF</a>
                    <a ngbDropdownItem class="btn btn-sm btn-block btn-white selectHover" style="color: black;" href="{{url}}report/detailReport/{{requestData.idrequests}}" target="_blank"> <i class="fa fa-file-excel" style="color:#333;"></i> Excel</a>
                </div>
            </div>
        </div>
    </div>
    <div class="sectionBody">
        <div class="row tHeader" style="margin: 0 !important; ">
            <div class="col-3 tab" (click)="changeSelected(0)" [ngClass]="{'active': selectedTab == 0}" >
                <div class="sectionForm">
                    <span class="numberNotSelected" *ngIf="selectedTab != 0 else one">
                        <div class="fa-solid fa-check"></div>
                    </span>
                    <ng-template #one>
                        <span class="number">
                            1
                        </span>
                    </ng-template>
                    <div>
                        <span class="info">Datos generales </span>
                        <i class="fa fa-arrow-right"></i>
                        <small>Nombre, Dirección, Teléfono...</small>
                    </div>
                </div>
            </div>
            <div class="col-3 tab" (click)="changeSelected(1)" [ngClass]="{'active': selectedTab == 1}" >
                <div class="sectionForm">
                    <span class="numberNotSelected" *ngIf="selectedTab != 1 else second">
                        <div class="fa-solid fa-check"></div>
                    </span>
                    <ng-template #second>
                        <span class="number">
                            2
                        </span>
                    </ng-template>
                    <span class="info">Clasificación </span>
                    <i class="fa fa-arrow-right"></i>
                    <small>Tema, Subtema, Categoría</small>
                </div>
            </div>
            <div class="col-3 tab" (click)="changeSelected(2)" [ngClass]="{'active': selectedTab == 2}" >
                <div class="sectionForm">
                    <span class="numberNotSelected" *ngIf="selectedTab != 2 else third">
                        <div class="fa-solid fa-check"></div>
                    </span>
                    <ng-template #third>
                        <span class="number">
                            3
                        </span>
                    </ng-template>
                    <div>
                        <span class="info">Documentación </span>
                        <i class="fa fa-arrow-right"></i>
                        <small>Documentación</small>
                    </div>
                </div>
            </div>
            <div class="col-3 tab" (click)="changeSelected(3)" [ngClass]="{'active': selectedTab == 3}" >
                <div class="sectionForm">
                    <span class="numberNotSelected" *ngIf="selectedTab != 3 else fourth">
                        <div class="fa-solid fa-check"></div>
                    </span>
                    <ng-template #fourth>
                        <span class="number">
                            4
                        </span>
                    </ng-template>
                    <div>
                        <span class="info">Historial </span>
                        <i class="fa fa-arrow-right"></i>
                        <small>Registro de la actividad</small>
                    </div>
                </div>
            </div>
        </div>
        <mat-stepper (selectionChange)="onStepChange($event)" #stepper [orientation]="'horizontal'">
            <mat-step>
                <app-detail-request-general-data></app-detail-request-general-data>
                <div class="footer-form">
                    <button class="btn btn-primary" (click)="changeSelected(1)">Siguiente</button>
                </div>
            </mat-step>
            <mat-step>
                <app-detail-request-clasification></app-detail-request-clasification>
                <div class="footer-form">
                    <button class="btn btn-primary" (click)="changeSelected(2)">Siguiente</button>
                    <button class="btn btn-light mr-2" (click)="changeSelected(0)">Anterior</button>
                </div>
            </mat-step>
            <mat-step>
                <app-detail-request-documentation></app-detail-request-documentation>
                <div class="footer-form">
                    <button class="btn btn-primary" (click)="changeSelected(3)">Siguiente</button>
                    <button class="btn btn-light mr-2" (click)="changeSelected(1)">Anterior</button>
                </div>
            </mat-step>
            <mat-step>
                <app-detail-request-history [idApplicant]="idApplicant"></app-detail-request-history>
                <div class="footer-form">
                    <button class="btn btn-light" (click)="changeSelected(2)">Anterior</button>
                </div>
            </mat-step>
        </mat-stepper>
    </div>
</div>
<!-- Modal -->
<div class="modal fade" id="attendModal" tabindex="-1" role="dialog" aria-labelledby="attendModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header" style="background: #49B6D6;">
                <h5 class="modal-title" id="attendModalLabel" style="color:black;">{{title}}</h5>
                <button type="button" class="close" (click)="closeModal()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="color:#707478;font-size:14px;">
                <label>Folio de la solicitud:</label>
                <div class="form-control-disable">{{requestData.qrcode}}</div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-white" style="font-size: 14px;" (click)="closeModal()">Cancelar</button>
                <button type="button" class="btn btn-primary" style="background-color: #49B6D6;border-color: #49B6D6;font-size: 14px;" (click)="typeModal == 'attend' ? saveAttend():(typeModal == 'seg' ? saveSeg(): '')">Aceptar</button>
            </div>
        </div>
    </div>
</div>
<!-- Modal -->
<div class="modal fade" id="assignModal" tabindex="-1" role="dialog" aria-labelledby="visitModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header" style="background: #49B6D6;">
                <h5 class="modal-title" id="exampleModalLabel" style="color:black;font-size: 17px;font-weight: 600;">{{title}}</h5>
                <button type="button" class="close" (click)="closeModal()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="color:#707478;font-size:14px;">
                <form [formGroup]="reassignForm">
                    <div>
                        <span style="color: red">* </span>
                        <label class="p-t-2">Usuario:</label>
                        <select class="form-control" formControlName="idAssigned">
                            <option selected value="">Selecciona una opción</option>
                            <option *ngFor="let user of usersOptions" value="{{user.idUser}}">{{user.workgroup}}</option>
                        </select>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-white" style="font-size: 14px;" (click)="closeModal()">Cancelar</button>
                <button type="button" class="btn btn-primary" (click)="saveAssign()" style="background-color: #49B6D6;border-color: #49B6D6;font-size: 14px;">Aceptar</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="closeModal" tabindex="-1" role="dialog" aria-labelledby="visitModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header" style="background: #49B6D6;">
                <h5 class="modal-title" id="exampleModalLabel" style="color:black;font-size: 17px;font-weight: 600;">{{title}}</h5>
                <button type="button" class="close" (click)="closeModal()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="color:#707478;font-size:14px;">
                <form [formGroup]="closeForm">
                    <div>
                        <span style="color: red">* </span>
                        <label class="p-t-2">Estatus:</label>
                        <select class="form-control" formControlName="idstatus">
                            <option selected value="">Selecciona una opción</option>
                            <option selected value="3">No viable</option>
                            <option selected value="4">Cancelado</option>
                            <option selected value="6">Meta cumplida</option>
                        </select>
                    </div>
                    <div *ngIf="closeForm.get('idstatus')?.value == 3">
                        <p class="divData"></p>
                        <span class="reqForm">*</span>
                        <label>Opciones:</label>
                        <select class="form-control" formControlName="idReason">
                            <option selected value="">Selecciona una opción</option>
                            <option selected value="1">Se canalizó a la institución correspondiente fuera del municipio</option>
                            <option selected value="2">Está fuera de los apoyos</option>
                        </select>
                        <ng-container *ngIf="closeForm.get('idReason')?.value == 1">
                            <label>Nombre de la institución:</label>
                            <input class="form-control" type="text" placeholder="Agregar nombre de la institución" formControlName="channel"/>            
                        </ng-container>
                    </div>
                    <div *ngIf="closeForm.get('idstatus')?.value == 4">
                        <p class="divData"></p>
                        <span class="reqForm">*</span>
                        <label>Opciones:</label>
                        <select class="form-control" formControlName="idReason">
                            <option selected value="">Selecciona una opción</option>
                            <option selected value="1">El solicitante no cumplió con los requisitos necesarios</option>
                            <option selected value="3">No hubo recursos para cumplir la solicitud</option>
                        </select>
                    </div>
                    <div *ngIf="closeForm.get('idstatus')?.value == 6">
                        <label>¿Se entregó directo el apoyo?</label>
                        <div class="col-md-12">
                            <div class="radio radio-css radio-inline">
                                <input id="deliveredYes" formControlName="delivered" type="radio" [value]="1" checked="">
                                <label for="deliveredYes">Sí</label>
                            </div>
                            <div class="radio radio-css radio-inline">
                                <input id="deliveredNo" formControlName="delivered" type="radio" [value]="0">
                                <label for="deliveredNo">No</label>
                            </div>
                        </div>
                        <ng-container *ngIf="closeForm.get('delivered')?.value == 0">
                            <label>Se canalizó a:</label>
                            <div class="col-md-12">
                                <div class="radio radio-css radio-inline">
                                    <input id="channeledMun" formControlName="channeled" type="radio" [value]="1" checked="">
                                    <label for="channeledMun">Municipio</label>
                                </div>
                                <div class="radio radio-css radio-inline">
                                    <input id="channledEst" formControlName="channeled" type="radio" [value]="2">
                                    <label for="channledEst">Estado</label>
                                </div>
                                <div class="radio radio-css radio-inline">
                                    <input id="channeledFed" formControlName="channeled" type="radio" [value]="3">
                                    <label for="channeledFed">Federal</label>
                                </div>
                                <div class="radio radio-css radio-inline">
                                    <input id="channeledOth" formControlName="channeled" type="radio" [value]="4">
                                    <label for="channeledOth">Otro</label>
                                </div>
                            </div>
                            <label>Nombre de la institución:</label>
                            <input class="form-control" type="text" placeholder="Agregar nombre de la institución" formControlName="channelInst"/>            
                        </ng-container>
                        <label>¿El recibo de entrega fue firmado?</label>
                        <div class="col-md-12">
                            <div class="radio radio-css radio-inline">
                                <input id="receiptYes" formControlName="receipt" type="radio" [value]="1">
                                <label for="receiptYes">Sí</label>
                            </div>
                            <div class="radio radio-css radio-inline">
                                <input id="receiptNo" formControlName="receipt" type="radio" [value]="0" checked="">
                                <label for="receiptNo">No</label>
                            </div>
                        </div>
                        <span class="reqForm">* </span>
                        <label>Acepto que esto es verdad y declaro que firmo la solicitud.</label>
                        <div class="col-md-12 offset-md-1 checkbox checkbox-css checkbox-inline">
                            <input id="checkboxReceipt" type="checkbox" [checked]="closeForm.get('accepted')?.value === 1" (change)="updateCheckbox($event)" [ngClass]="{'is-invalid': closeForm.controls['accepted'].invalid && closeForm.controls['accepted'].touched}"/>
                            <label for="checkboxReceipt">Si</label>
                            <div id="validationServerUsernameFeedback" class="invalid-feedback">
                                Por favor aceptar el termino.
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="closeForm.get('idstatus')?.value">
                        <label>{{closeForm.get('idstatus')?.value == 3 ? 'Nota:' : 'Nota para el beneficiario:'}}</label>
                        <textarea id="note" class="form-control" placeholder="Agregar nota del cambio de estatus" formControlName="note"></textarea>    
                    </ng-container>
                    <div class="cont-image" *ngIf="closeForm.get('idstatus')?.value == 6">
                        <label class="block mb-2 text-sm text-gesco dark:text-white" for="multiple_files">Agregar evidencia:</label>
                        <input class="block w-full text-sm text-gesco border-gesco rounded-lg cursor-pointer" type="file" id="multiple_files" accept=".pdf,.doc,.png,.jpg,.jpeg" (change)="onFileChange($event)" multiple />
                        <p class="mt-1 text-sm text-gesco" id="file_input_help">Cargar imágenes o archivos (3 máximo)</p>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-white" style="font-size: 14px;" (click)="closeModal()">Cancelar</button>
                <button type="button" class="btn btn-primary" style="background-color: #49B6D6;border-color: #49B6D6;font-size: 14px;" (click)="saveClose()">Cambiar</button>
            </div>
        </div>
    </div>
</div>