import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StatsService {
  url = environment.apiUrl;
  profileLevel: number = 0;
  constructor(public http: HttpClient) {

  }

  getTopThemes(idWorkgroup: String) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any>(this.url + 'getTopThemesCount/' + idWorkgroup, options);
  }
  getStatusCount(idWorkgroup: String, idUser: Number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any>(this.url + 'stats/' + idWorkgroup + '/' + this.profileLevel + '/' + idUser, options);
  }
  getThemesCount(idWorkgroup: String) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any>(this.url + 'getThemesCount/' + idWorkgroup, options);
  }
  getSubthemesCount(idWorkgroup: String, themeId: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any>(this.url + 'getSubthemesCount/' + idWorkgroup + '/' + themeId, options);
  }
  getCategoriesCount(idWorkgroup: String, subthemeId: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any>(this.url + 'getCategoriesCount/' + idWorkgroup + '/' + subthemeId, options);
  }
  getTotalBeneficiaries(idWorkgroup: String, level: number, idUser: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any>(this.url + 'getTotalBeneficiaries/' + idWorkgroup + '/' + level + '/' + idUser, options);
  }
  getTotalBeneficiariesYear(idWorkgroup: String, level: number, idUser: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any>(this.url + 'getTotalBeneficiariesYear/' + idWorkgroup + '/' + level + '/' + idUser, options);
  }
  getTotalBeneficiariesLastYear(idWorkgroup: String, level: number, idUser: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any>(this.url + 'getTotalBeneficiariesLastYear/' + idWorkgroup + '/' + level + '/' + idUser, options);
  }
  getApplicantByGender(idWorkgroup: String) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any>(this.url + 'getApplicantByGender/' + idWorkgroup, options);
  }
  getBeneficiaryByGender(idWorkgroup: String) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any>(this.url + 'getBeneficiaryByGender/' + idWorkgroup, options);
  }
  
  getEntitiesGeneralStatics(idUser: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any>(this.url + 'getEntitiesGeneralStatics/' + idUser, options);
  }
  getTotalRequestsCount(idWorkgroup: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any>(this.url + 'getTotalRequestsCount/' + idWorkgroup, options);
  }
  getThemesCountByLastYear(idWorkgroup: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any>(this.url + 'getThemesCountByLastYear/' + idWorkgroup, options);
  }
  getThemesRequestsByYear(idWorkgroup: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any>(this.url + 'getThemesRequestsByYear/' + idWorkgroup, options);
  }
  getThemesCountByYear(idWorkgroup: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any>(this.url + 'getThemesCountByYear/' + idWorkgroup, options);
  }
  getThemesRequestsByLastYear(idWorkgroup: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any>(this.url + 'getThemesRequestsByLastYear/' + idWorkgroup, options);
  }
  getBeneficiaryByGenderLastYear(idWorkgroup: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any>(this.url + 'getBeneficiaryByGenderLastYear/' + idWorkgroup, options);
  }
  getBeneficiaryByGenderYear(idWorkgroup: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any>(this.url + 'getBeneficiaryByGenderYear/' + idWorkgroup, options);
  }
  getApplicantByAgeGroup(idWorkgroup: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any>(this.url + 'getApplicantByAgeGroup/' + idWorkgroup, options);
  }
  getBeneficiaryByAgeGroup(idWorkgroup: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any>(this.url + 'getBeneficiaryByAgeGroup/' + idWorkgroup, options);
  }
  getApplicantByAgeGroupYear(idWorkgroup: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any>(this.url + 'getApplicantByAgeGroupYear/' + idWorkgroup, options);
  }
  getApplicantByAgeGroupLastYear(idWorkgroup: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any>(this.url + 'getApplicantByAgeGroupLastYear/' + idWorkgroup, options);
  }
  getApplicantsByVulnerableGroupAndYear(idWorkgroup: String) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any>(this.url + 'getApplicantsByVulnerableGroupAndYear/' + idWorkgroup, options);
  }
  getApplicantsByVulnerableGroupAndLastYear(idWorkgroup: String) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any>(this.url + 'getApplicantsByVulnerableGroupAndLastYear/' + idWorkgroup, options);
  }
  getApplicantsByVulnerableGroup(idWorkgroup: String) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any>(this.url + 'getApplicantsByVulnerableGroup/' + idWorkgroup, options);
  }
  getRequestsDataComplete(idWorkgroup: String) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any>(this.url + 'getRequestsDataComplete/' + idWorkgroup, options);
  }
  getSympathizerDataComplete(idWorkgroup: String) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any>(this.url + 'getSympathizerDataComplete/' + idWorkgroup, options);
  }
  getBeneficiaryByVulnerableGroup(idWorkgroup: String) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any>(this.url + 'getBeneficiaryByVulnerableGroup/' + idWorkgroup, options);
  }
  getLiftingOfRequests(idWorkgroup: String) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any>(this.url + 'getLiftingOfRequests/' + idWorkgroup, options);
  }
  getCountByMonths(idWorkgroup: String) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any>(this.url + 'getCountByMonths/' + idWorkgroup, options);
  }
  getCountByMonth(idWorkgroup: String, month: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any>(this.url + 'getCountByMonth/' + idWorkgroup + '/' + month, options);
  }
  getTotalBeneficiariesByMonth(idWorkgroup: String) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any>(this.url + 'getTotalBeneficiariesByMonth/' + idWorkgroup, options);
  }
  getApplicantGenderByMonths(idWorkgroup: String) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any>(this.url + 'getApplicantGenderByMonths/' + idWorkgroup, options);
  }
  getApplicantGenderByMonth(idWorkgroup: String, month: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any>(this.url + 'getApplicantGenderByMonth/' + idWorkgroup + '/' + month, options);
  }
  getApplicantsByVulnerableGroupAndMonths(idWorkgroup: String) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any>(this.url + 'getApplicantsByVulnerableGroupAndMonths/' + idWorkgroup, options);
  }
  getApplicantsByVulnerableGroupAndMonth(idWorkgroup: String, month: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any>(this.url + 'getApplicantsByVulnerableGroupAndMonth/' + idWorkgroup + '/' + month, options);
  }
  getApplicantsByAgeGroupAndMonths(idWorkgroup: String) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any>(this.url + 'getApplicantsByAgeGroupAndMonths/' + idWorkgroup, options);
  }
  getApplicantsByAgeGroupAndMonth(idWorkgroup: String, month: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any>(this.url + 'getApplicantsByAgeGroupAndMonth/' + idWorkgroup + '/' + month, options);
  }
}
