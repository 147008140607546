import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormDataService } from './form-data.service';

@Injectable({
  providedIn: 'root'
})
export class ProposalService {
  url = environment.apiUrl;

  constructor(public http: HttpClient, private formDataService: FormDataService,
  ) { }

  savePromoter(promoterData: any) {
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return this.http.post<any>(this.url + 'promoter/save', promoterData, options);
  }

  saveProposal(proposalData: any, urlDocument: any, urlVideo: any, urlImage: any) {

    const urlFields = {
      urlDocument: urlDocument,
      urlVideo: urlVideo,
      urlImage: urlImage
    };
    const requestData = {
      ...proposalData,
      ...urlFields
    };
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return this.http.post<any>(this.url + 'proposal/save', requestData, options);
  }

  saveProposalHistory(historyData: any) {
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return this.http.post<any>(this.url + 'saveHistory', historyData, options);
  }

  saveVisit(visitData: any) {
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return this.http.post<any>(this.url + 'saveVisitPromoter', visitData, options);
  }

  updateStatus(idProposal: number, statusData: any) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return this.http.put<any>(this.url + 'updateStatusProposal/' + idProposal, statusData, options);
  }
  
  getProposals(page: number, itemsPerPage: number, idWorkGroup: string, orderByField: String, orderByDirection: String,userLevel: string, idUser: string) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get(this.url + 'getProposals/' + page + '/' + itemsPerPage + '/' + idWorkGroup + '/' + orderByField + '/' + orderByDirection + '/' + userLevel + '/' + idUser, options);
  }

  getSearchProposals(searchTerm: string | null, p: any, itemsPerPage: any,userLevel: string, idUser: string) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get(this.url + 'getSearchProposalsList/' + searchTerm + '/' + localStorage.getItem('idWorkGroup') + '/' + p + '/' + itemsPerPage + '/' + userLevel + '/' + idUser, options);
  }

  getProposal(idProposal: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get(this.url + 'getProposal/' + idProposal, options);
  }

  getProposalData(idProposal: number, idWorkGroup: string) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get(this.url + 'getProposalData/' + idProposal + '/' + idWorkGroup, options);
  }

  getProposalHistoryData(idProposal: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get(this.url + 'getProposalHistoryData/' + idProposal, options);
  }

  getVisitData(idPromoter: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get(this.url + 'getVisitPromoter/' + idPromoter, options);
  }

  updatePromoter(idPromoter: number, promoterData: any) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return this.http.put<any>(this.url + 'updatePromoter/' + idPromoter, promoterData, options);
  }


  updateProposal(idProprosal: number, proposalData: any, urlDocument: any, urlVideo: any, urlImage: any) {


    if (this.formDataService.AllurlDocument) {
      this.formDataService.AllurlDocument.forEach((url: any) => {
        urlDocument.push(url)
      });
    }
    
    if (this.formDataService.AllurlVideo) {

      this.formDataService.AllurlVideo.forEach((url: any) => {
        urlVideo.push(url)
      });
    }

    if (this.formDataService.AllurlImage) {

      this.formDataService.AllurlImage.forEach((url: any) => {
        urlImage.push(url)
      });
    }

    console.log(urlDocument);

    const urlFields = {
      urlDocument: urlDocument,
      urlVideo: urlVideo,
      urlImage: urlImage
    };
    const requestData = {
      ...proposalData,
      ...urlFields
    };

    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return this.http.put<any>(this.url + 'updateProposal/' + idProprosal, requestData, options);
  }
}
