<div class="panel panel-white">

    <h1 class="page-header" style="padding-left:15px;">Mapeo &nbsp;<small></small></h1>
    <hr>

    <div *ngIf="statusCountLoaded == true;else skeleton " [@loaded] class="row targetCounts" style="margin:0;">
        <a class="col-xs-12 col-sm-12 col-md-12 col-lg-3"
            style="text-align:left;text-decoration:none;color: #343a40;align-self: center;">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="margin-bottom :10px;">
                <div class="targetTotals"
                    style="border-radius: 4px;box-shadow: 1px 1px 5px 0px;padding: 7px;text-align: center;height: 100%;background:#fff;padding-top: 45px;border-bottom: 17px solid;">
                    <p class="dataBenf">TOTAL SOLICITUDES:<br /> <b style="font-size: 1.8em;">{{counts.Total}}</b></p>
                </div>
            </div>
        </a>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-9" style="padding-left: 1%;">
            <div class="row targetCounts">
                <a class="col-xs-6 col-sm-6 col-md-4 col-lg-4" style="text-align:left;" (click)="filterClicked(1)">
                    <div class="boxes filterf focusf-1"
                        [ngClass]="{'desctive': activeStatus != 1 && activefilters == true}" style="text-align:center;">

                        <span>REGISTRO</span>:<br>
                        <b style="font-size: 24px;">{{counts.Registro}}</b><i class="fa fa-filter m-b-20"
                            style="color: #e6007e;position: absolute;right: 10%;bottom: 25%;"></i>
                    </div>

                </a>

                <a class="col-xs-6 col-sm-6 col-md-4 col-lg-4" style="text-align:left;" (click)="filterClicked(2)">
                    <div class="boxes filterf focusf-2"
                        [ngClass]="{'desctive': activeStatus != 2 && activefilters == true}" style="text-align:center;">
                        <span>ASIGNADO</span>:<br>
                        <b style="font-size: 24px;">{{counts.Asignado}}</b><i class="fa fa-filter m-b-20"
                            style="color: #e6007e;position: absolute;right: 10%;bottom: 25%;"></i>
                    </div>
                </a>
                <a class="col-xs-6 col-sm-6 col-md-4 col-lg-4" style="text-align:left;" (click)="filterClicked(5)">
                    <div class="boxes filterf focusf-4 "
                        [ngClass]="{'desctive': activeStatus != 5 && activefilters == true}" style="text-align:center;">
                        <span>EN PROCESO</span>:<br>
                        <b style="font-size: 24px;">{{counts.EnProceso}}</b><i class="fa fa-filter m-b-20 m-b-20"
                            style="color: #e6007e;position: absolute;right: 10%;bottom: 25%;"></i>
                    </div>
                </a>
                <a class="col-xs-6 col-sm-6 col-md-4 col-lg-4" style="text-align:left;" (click)="filterClicked(6)">
                    <div class="boxes filterf focusf-16"
                        [ngClass]="{'desctive': activeStatus != 6 && activefilters == true}" style="text-align:center;">
                        <span>META CUMPLIDA</span>:<br>
                        <b style="font-size: 24px;">{{counts.MetaCumplida}}</b><i class="fa fa-filter m-b-20"
                            style="color: #e6007e;position: absolute;right: 10%;bottom: 25%;"></i>
                    </div>
                </a>
                <a class="col-xs-6 col-sm-6 col-md-4 col-lg-4" style="text-align:left;" (click)="filterClicked(4)">
                    <div class="boxes filterf focusf-64"
                        [ngClass]="{'desctive': activeStatus != 4 && activefilters == true}" style="text-align:center;">
                        <span>CANCELADO</span>:<br>
                        <b style="font-size: 24px;">{{counts.Cancelado}}</b><i class="fa fa-filter m-b-20"
                            style="color: #e6007e;position: absolute;right: 10%;bottom: 25%;"></i>
                    </div>
                </a>

                <a class="col-xs-6 col-sm-6 col-md-4 col-lg-4" style="text-align:left;" (click)="filterClicked(3)">
                    <div class="boxes filterf focusf-32"
                        [ngClass]="{'desctive': activeStatus != 3 && activefilters == true}" style="text-align:center;">
                        <span>NO VIABLE</span>:<br>
                        <b style="font-size: 24px;">{{counts.NoViable}}</b>
                        <i class="fa fa-filter m-b-20"
                            style="color: #e6007e;position: absolute;right: 10%;bottom: 25%;"></i>
                    </div>
                </a>
            </div>
        </div>


        <div class="row searchGeneral col-xs-12 col-sm-12 col-md-12 col-lg-6">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                style=" display: inherit; margin-bottom: 5px; padding:0;">
                <span class="spanSearch">Buscar</span>
                <input [(ngModel)]="search" id="FiltrarContenido" (keyup)="getSearch($event)" type="search" (ngModelChange)="onSearchChange($event)" class=""
                    (click)="toggleMenu()" placeholder=" Ingrese su búsqueda" aria-controls="data-table-autofill">
                <button class="btnSearch" (click)="buttonSearch(results)">Ir
                </button>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                style=" display: inherit; margin-bottom: 5px; padding:0;color: #a09baa;">
                Para realizar búsquedas, puede ingresar nombre, folio, número de teléfono o CURP.
            </div>

            <ng-container #menu *ngIf="isMenuOpen">

                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 content-seach contenedor"
                    *ngIf="search.length >= 3;">

                    <ng-container *ngIf="results.length == 0 && loading == false; else searchMenu">
                        <label class="darkGray">No hay registros</label>
                    </ng-container>

                    <ng-template #searchMenu>
                        <p>Total registros: <b>{{results.length}}</b>
                        </p>
                        <ng-container *ngFor="let result of results; let i = index">

                            <ng-container *ngIf="result.type === 'applicant'">
                                <label [ngClass]=" i%2==0 ? 'darkGray' : 'lowGray'">
                                    {{result.nameApplicant}} ({{result.subthemeName}})- {{result.statusName}}
                                </label>
                                <mat-chip color="primary">
                                    Solicitante
                                </mat-chip>
                            </ng-container>

                            <ng-container *ngIf="result.type === 'sympathizer'">
                                <label [ngClass]=" i%2==0 ? 'darkGray' : 'lowGray'">
                                    {{result.nameApplicant}}
                                </label>
                                <mat-chip color="accent">
                                    Simpatizante
                                </mat-chip>
                            </ng-container>

                            <ng-container *ngIf="result.type === 'promoted'">
                                <label [ngClass]=" i%2==0 ? 'darkGray' : 'lowGray'">
                                    {{result.nameApplicant}}
                                </label>
                                <mat-chip color="accent">
                                    Promovido
                                </mat-chip>
                            </ng-container>



                        </ng-container>

                        <br>
                    </ng-template>

                    <div class="row" *ngIf="loading">
                        <div class=" offset-5 col-md-6">
                            <mat-spinner></mat-spinner>
                        </div>
                    </div>

                </div>
            </ng-container>


            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-8">
                <nav class="navbar navbar-light bg-light m-0 p-0" id="pageTop">

                </nav>

            </div>
        </div>


    </div>


    <ng-template #skeleton>

        <div class="row targetCounts" style="margin:0;">
            <a class="col-xs-12 col-sm-12 col-md-12 col-lg-3" href="">
                <div style="padding: 7px;text-align: center;height: 100%;padding-top: 35px;padding-bottom: 45px ;">
                    <ngx-skeleton-loader [theme]="{height: '100%'}" count="1"></ngx-skeleton-loader>
                </div>
            </a>

            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-9" style="padding-left: 1%;">
                <div class="row targetCounts">
                    <a class="col-xs-6 col-sm-6 col-md-4 col-lg-4" style="text-align:left;">
                        <ngx-skeleton-loader class="boxes" [theme]="{height: '100%'}" count="1"></ngx-skeleton-loader>
                    </a>

                    <a class="col-xs-6 col-sm-6 col-md-4 col-lg-4" style="text-align:left;">
                        <ngx-skeleton-loader class="boxes" [theme]="{height: '100%'}" count="1"></ngx-skeleton-loader>
                    </a>
                    <a class="col-xs-6 col-sm-6 col-md-4 col-lg-4" style="text-align:left;">
                        <ngx-skeleton-loader class="boxes" [theme]="{height: '100%'}" count="1"></ngx-skeleton-loader>
                    </a>
                    <a class="col-xs-6 col-sm-6 col-md-4 col-lg-4" style="text-align:left;">
                        <ngx-skeleton-loader class="boxes" [theme]="{height: '100%'}" count="1"></ngx-skeleton-loader>
                    </a>

                    <a class="col-xs-6 col-sm-6 col-md-4 col-lg-4" style="text-align:left;">
                        <ngx-skeleton-loader class="boxes" [theme]="{height: '100%'}" count="1"></ngx-skeleton-loader>
                    </a>

                    <a class="col-xs-6 col-sm-6 col-md-4 col-lg-4" style="text-align:left;">
                        <ngx-skeleton-loader class="boxes" [theme]="{height: '100%'}" count="1"></ngx-skeleton-loader>
                    </a>
                </div>
            </div>

            <div class="row searchGeneral col-xs-12 col-sm-12 col-md-12 col-lg-6">
                <ngx-skeleton-loader [theme]="{height: '5vh'}" count="1"></ngx-skeleton-loader>
            </div>

        </div>
    </ng-template>

    <div class="p-15 panel-expand-box">
    </div>

    <div class="tags" *ngIf="tags.length > 0" style="display: block;">
        <div *ngFor="let tag of tags">{{tag.name}}<a (click)="clearStatusMarker(tag)">X</a></div>
    </div>

    <div class="row showGral m-t-10">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">



            <h5 class="m-t-10 m-b-10 m-r-5 total-count" *ngIf="markerType === 'POI';">

                <i class="fa fa-list m-r-5"></i> <span id="showCount">Mostrando {{markerCount}} puntos de
                    interés</span>
            </h5>


            <h5 class="m-t-10 m-b-10 m-r-5 total-count" *ngIf="markerType === 'sympathizer';">

                <i class="fa fa-list m-r-5"></i> <span id="showCount">Mostrando {{markerCount}} simpatizantes de
                    {{counts.Simpatizante}}</span>
            </h5>

            <h5 class="m-t-10 m-b-10 m-r-5 total-count" *ngIf="markerType === 'promoted';">

                <i class="fa fa-list m-r-5"></i> <span id="showCount">Mostrando {{markerCount}} promovidos de
                    {{counts.Promoted}}</span>
            </h5>

            <h5 class="m-t-10 m-b-10 m-r-5 total-count" *ngIf="markerType === 'request'">

                <i class="fa fa-list m-r-5"></i> <span id="showCount">Mostrando {{markerCount}} solicitudes de
                    {{counts.Total}}</span>
            </h5>

            <div [ngSwitch]="markerType">

                <div *ngSwitchCase="'registeredMarkerGroup'">
                    <h5 class="m-t-10 m-b-10 m-r-5 total-count">
                        <i class="fa fa-list m-r-5"></i> <span id="showCount">Mostrando {{markerCount}} solicitudes
                            de
                            {{counts.Registro}}</span>
                    </h5>
                </div>

                <div *ngSwitchCase="'assignedMarkerGroup'">
                    <h5 class="m-t-10 m-b-10 m-r-5 total-count">
                        <i class="fa fa-list m-r-5"></i> <span id="showCount">Mostrando {{markerCount}} solicitudes
                            de
                            {{counts.Asignado}}</span>
                    </h5>
                </div>

                <div *ngSwitchCase="'onProcessMarkerGroup'">
                    <h5 class="m-t-10 m-b-10 m-r-5 total-count">
                        <i class="fa fa-list m-r-5"></i> <span id="showCount">Mostrando {{markerCount}} solicitudes
                            de
                            {{counts.EnProceso}}</span>
                    </h5>
                </div>

                <div *ngSwitchCase="'accomplishedMarkerGroup'">
                    <h5 class="m-t-10 m-b-10 m-r-5 total-count">
                        <i class="fa fa-list m-r-5"></i> <span id="showCount">Mostrando {{markerCount}} solicitudes
                            de
                            {{counts.MetaCumplida}}</span>
                    </h5>
                </div>

                <div *ngSwitchCase="'canceledMarkerGroup'">
                    <h5 class="m-t-10 m-b-10 m-r-5 total-count">
                        <i class="fa fa-list m-r-5"></i> <span id="showCount">Mostrando {{markerCount}} solicitudes
                            de
                            {{counts.Cancelado}}</span>
                    </h5>
                </div>

                <div *ngSwitchCase="'notViableMarkerGroup'">
                    <h5 class="m-t-10 m-b-10 m-r-5 total-count">
                        <i class="fa fa-list m-r-5"></i> <span id="showCount">Mostrando {{markerCount}} solicitudes
                            de
                            {{counts.NoViable}}</span>
                    </h5>
                </div>

            </div>

            <h5 class="m-t-10 m-b-10 m-r-5 total-count" *ngIf="markerType === 'registers'">

                <i class="fa fa-list m-r-5"></i> <span id="showCount">Mostrando {{markerCount}} registros de
                    {{counts.Total}}</span>
            </h5>




        </div>
        <div class="col-xs-12 col-sm-8 col-md-8 col-lg-8">
            <div class="panel-heading-btn">
                <div class="row">
                    <div class="col">

                    </div>

                </div>

                <div class="btn-group m-b-5 btn-filter desactiveF">
                    <span class="btn btn-white btn-sm width-32">
                        <a href="" class="btn btn-sm btn-white"><i class="fa fa-ban" aria-hidden="true"></i>
                            Limpiar filtros</a>
                    </span>
                </div>
                <div class="btn-group  m-b-5" *ngIf="showMyRequest; else hideMyRequest">
                    <span class="btn btn-white btn-sm width-32">
                        <a (click)="myRequestClicked(true)" class="collapsed  btn btn-sm btn-white">
                            <i class="fa fa-layer-group" aria-hidden="true"></i>
                            Mis solicitudes
                        </a>
                    </span>
                </div>
                <ng-template #hideMyRequest>
                    <div class="btn-group  m-b-5">
                        <span class="btn btn-white btn-sm width-32">
                            <a (click)="myRequestClicked(false)" class="collapsed  btn btn-sm btn-white">
                                <i class="fa fa-user" aria-hidden="true"></i>
                                Ocultar Mis solicitudes
                            </a>
                        </span>
                    </div>
                </ng-template>


                <ng-template #hideMyRequest>
                    <div class="btn-group  m-b-5">
                        <span class="btn btn-white btn-sm width-32">
                            <a (click)="beneficiaryClicked(false)" class="collapsed  btn btn-sm btn-white">
                                <i class="fa fa-user" aria-hidden="true"></i>
                                Ocultar Beneficiarios
                            </a>
                        </span>
                    </div>
                </ng-template>

                <div class="btn-group  m-b-5" *ngIf="showBenefiary; else hideBenef">
                    <span class="btn btn-white btn-sm width-32">
                        <a (click)="beneficiaryClicked(true)" class="collapsed  btn btn-sm btn-white">
                            <i class="fa fa-user-check" aria-hidden="true"></i>
                            Beneficiarios
                        </a>
                    </span>
                </div>

                <ng-template #hideBenef>
                    <div class="btn-group  m-b-5">
                        <span class="btn btn-white btn-sm width-32">
                            <a (click)="beneficiaryClicked(false)" class="collapsed  btn btn-sm btn-white">
                                <i class="fa fa-user-check" aria-hidden="true"></i>
                                Ocultar Beneficiarios
                            </a>
                        </span>
                    </div>
                </ng-template>

                <div *ngIf="showSympathizer; else elseBlock" class="btn-group  m-b-5">
                    <span class="btn btn-white btn-sm width-32">
                        <a (click)="symapthizerClicked(true)" class="collapsed  btn btn-sm btn-white">
                            <i class="fa fa-user" aria-hidden="true"></i>
                            Simpatizantes
                        </a>
                    </span>
                </div>
                <ng-template #elseBlock>
                    <div class="btn-group  m-b-5">
                        <span class="btn btn-white btn-sm width-32">
                            <a (click)="symapthizerClicked(false)" class="collapsed  btn btn-sm btn-white">
                                <i class="fa fa-user" aria-hidden="true"></i>
                                Ocultar simpatizantes
                            </a>
                        </span>
                    </div>
                </ng-template>

                <ng-container *ngIf="promotedActive == true">

                    <div *ngIf="showPromoted; else hidePromoted" class="btn-group  m-b-5">
                        <span class="btn btn-white btn-sm width-32">
                            <a (click)="promotedClicked(true)" class="collapsed  btn btn-sm btn-white">
                                <i class="fa fa-user-friends" aria-hidden="true"></i>
                                Promovidos
                            </a>
                        </span>
                    </div>
                    <ng-template #hidePromoted>
                        <div class="btn-group  m-b-5">
                            <span class="btn btn-white btn-sm width-32">
                                <a (click)="promotedClicked(false)" class="collapsed  btn btn-sm btn-white">
                                    <i class="fa fa-user-friends" aria-hidden="true"></i>
                                    Ocultar promovidos
                                </a>
                            </span>
                        </div>
                    </ng-template>
                </ng-container>

                <div *ngIf="showPublicServices; else hidePublicServices" class="btn-group  m-b-5">
                    <span class="btn btn-white btn-sm width-32">
                        <a (click)="publicServiceButtonClicked(true)" class="collapsed  btn btn-sm btn-white">
                            <i class="fa fa-hammer" aria-hidden="true"></i>
                            Servicios Públicos
                        </a>
                    </span>
                </div>
                <ng-template #hidePublicServices>
                    <div class="btn-group  m-b-5">
                        <span class="btn btn-white btn-sm width-32">
                            <a (click)="publicServiceButtonClicked(false)" class="collapsed  btn btn-sm btn-white">
                                <i class="fa fa-hammer" aria-hidden="true"></i>
                                Ocultar servicios públicos
                            </a>
                        </span>
                    </div>
                </ng-template>


                <div class="btn-group m-b-5" *ngIf="showPOI; else hidePOI">
                    <span class="btn btn-white btn-sm width-32">
                        <a (click)="POIButtonClicked(true)" class="collapsed  btn btn-sm btn-white">
                            <i class="fa fa-thumb-tack" aria-hidden="true"></i>
                            Puntos de interés
                        </a>
                    </span>
                </div>

                <ng-template #hidePOI>
                    <div class="btn-group  m-b-5">
                        <span class="btn btn-white btn-sm width-32">
                            <a (click)="POIButtonClicked(false)" class="collapsed  btn btn-sm btn-white">
                                <i class="fa fa-thumb-tack" aria-hidden="true"></i>
                                Ocultar puntos de interés
                            </a>
                        </span>
                    </div>
                </ng-template>



                <div class="btn-group  m-b-5 filterSpecific">
                    <span class="btn btn-white btn-sm width-32" (click)="isCollapsed = !isCollapsed">
                        <a class="btn btn-sm btn-white" data-toggle="collapse" role="button"
                            [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
                            <i class="fa fa-filter" aria-hidden="true"></i>

                            {{!isCollapsed?'Cerrar filtros':'Filtros'}}
                        </a>
                    </span>
                </div>

                <div class="btn-group  m-b-5 btn-filter" *ngIf="!isCollapsed">
                    <span class="btn btn-white btn-sm width-32" (click)="clearAllFilter()">
                        <button class="btn btn-sm btn-white"><i class="fa fa-ban" aria-hidden="true"></i>
                            Limpiar filtros</button>
                    </span>
                </div>

            </div>
        </div>
    </div>
    <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
        <div id="idForm">
            <div id="winModal1" class="panel panel-info">
                <div id="winModal1-body" class="panel-body">
                    <form id="winModal1-form" class="ocultar">
                        <ul class="nav nav-tabs">
                            <li id="form1" class="nav-items ">
                                <a href="#winModal1-tab-0" data-toggle="tab" class="nav-link active">
                                    <span class="d-sm-none"></span>
                                    <span class="d-sm-block d-none"></span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content">
                            <div class="tab-pane fade active show " id="winModal1-tab-0">
                                <form class="form-validate" [formGroup]="filterForm">

                                    <!-- <div class="row m-b-5 ">
                                        <div class="col-3">
                                            <div class="btn-group m-b-5"
                                                *ngIf="showVisitBeneficiary; else hideVisitBeneficiary">
                                                <span class="btn  btn-sm width-32">
                                                    <a (click)="visitedBeneficiaryButtonClicked(true)"
                                                        class="collapsed  btn btn-sm btn-white">
                                                        <i class="fa fa-house-user " aria-hidden="true"></i>
                                                        Visitas a beneficiarios
                                                    </a>
                                                </span>
                                            </div>

                                            <ng-template #hideVisitBeneficiary>
                                                <div class="btn-group  m-b-5">
                                                    <span class="btn btn-white btn-sm width-32">
                                                        <a (click)="visitedBeneficiaryButtonClicked(false)"
                                                            class="collapsed  btn btn-sm btn-white">
                                                            <i class="fa fa-house-user" aria-hidden="true"></i>
                                                            Ocultar Visitas a beneficiarios
                                                        </a>
                                                    </span>
                                                </div>
                                            </ng-template>
                                        </div>
                                    </div> -->

                                    <div class="form-group row m-b-5 ">
                                        <label class="align-middle col-md-12 p-t-2">Tema:</label>
                                        <div class="col-md-11">
                                            <select class="form-control" formControlName="themes">
                                                <option selected="selected" value="">Seleciona una opcion</option>
                                                <option *ngFor="let theme of themes" value="{{theme.id}}">
                                                    {{theme.name}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <span class=" levelLevel">
                                        <div class="form-group row m-b-5 ">
                                            <label class="align-middle col-md-12 p-t-2">Subtema:</label>
                                            <div class="col-md-11">
                                                <select class="form-control " formControlName="subthemes">
                                                    <option selected="selected" value="">Seleciona una opcion
                                                    </option>
                                                    <option *ngFor="let subthemes of subthemes"
                                                        value="{{subthemes.id}}">{{subthemes.name}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </span>
                                    <span class=" assign">
                                        <div class="form-group row m-b-5 ">
                                            <label class="align-middle col-md-12 p-t-2">Categoría:</label>
                                            <div class="col-md-11">
                                                <select class="form-control " formControlName="categories">
                                                    <option selected="selected" value="">Seleciona una opcion
                                                    </option>
                                                    <option *ngFor="let categorie of categories"
                                                        value="{{categorie.id}}">{{categorie.name}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </span>
                                    <div class="form-group row m-b-5 ">
                                        <label class="align-middle col-md-12 p-t-2">Estado:</label>
                                        <div class="col-md-11">
                                            <select id="" name="" formControlName="entity" class="form-control">
                                                <option selected="selected" value="">Seleciona una opcion</option>
                                                <option *ngFor="let entitie of entities" value="{{entitie.id}}">
                                                    {{entitie.name}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-group row m-b-5 ">
                                        <label class="align-middle col-md-12 p-t-2">Municipio:</label>
                                        <div class="col-md-11">
                                            <select id="" name="" class="form-control "
                                                formControlName="municipalities">
                                                <option selected="selected" value="">Seleciona una opcion</option>
                                                <option *ngFor="let municipalitie of municipalities"
                                                    value="{{municipalitie.id}}">{{municipalitie.name}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <span class=" gropC">
                                        <div class="form-group row m-b-5 ">
                                            <label class="align-middle col-md-12 p-t-2">Colonia:</label>
                                            <div class="col-md-11">
                                                <select id="" name="" class="form-control"
                                                    formControlName="neighborhoods">
                                                    <option selected="selected" value="">Seleciona una opcion
                                                    </option>
                                                    <option *ngFor="let neighborhood of neighborhoods"
                                                        value="{{neighborhood.id}}">{{neighborhood.name}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </span>

                                    <div class="form-group row m-b-5 ">
                                        <label class="align-middle col-md-12 p-t-2">Sección electoral:</label>
                                        <div class="col-md-11">
                                            <input class="form-control" formControlName="seccion" type="number">
                                        </div>
                                    </div>

                                    <div class="form-group row m-b-5 ">
                                        <label class="align-middle col-md-12 p-t-2">Propuestas ciudadanas:</label>
                                        <div class="col-md-11">

                                            <select id="" name="" class="form-control" formControlName="proposals">
                                                <option selected="selected" value="">Seleciona una opcion</option>
                                                <option *ngFor="let proposalOption of proposalOptions"
                                                    value="{{proposalOption.value}}">{{proposalOption.name}}
                                                </option>

                                            </select>

                                        </div>
                                    </div>

                                    <div class="form-group row m-b-5 ">
                                        <label class="align-middle col-md-12 p-t-2">Visitas:</label>
                                        <div class="col-md-11">

                                            <select id="" name="" class="form-control" formControlName="visits">
                                                <option selected="selected" value="">Seleciona una opcion</option>
                                                <option *ngFor="let visitsOption of visitsOptions">
                                                    {{visitsOption.name}}
                                                </option>
                                            </select>

                                        </div>
                                    </div>
                                    <ng-container *ngIf="promotedActive == true">
                                        <div class="form-group row m-b-5 ">
                                            <label class="align-middle col-md-12 p-t-2">Clasificación de
                                                promovido:</label>
                                            <div class="col-md-11">
                                                <select id="" name="" class="form-control"
                                                    formControlName="promoted_position">
                                                    <option selected="selected" value="">Seleciona una opcion</option>
                                                    <option *ngFor="let positionOption of positionOptions">
                                                        {{positionOption.name}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="form-group row m-b-5 ">
                                        </div>
                                        <div class="form-group row m-b-5 ">
                                        </div>
                                    </ng-container>
                                    <div class="form-group row m-b-5 ">
                                    </div>
                                    <div class="form-group row m-b-5 ">
                                    </div>

                                    <div class="form-group row m-b-5 ">
                                        <label class="align-middle col-md-12 p-t-2"></label>
                                        <div class="offset-5 col-md-6">
                                            <button (click)="filter()"
                                                class="btn btn-lg btn-block btn-info winModal1-btn-send">
                                                <i class="fa fa-search fa-lg m-r-5"></i>Filtrar
                                            </button>
                                        </div>
                                    </div>


                                </form>

                            </div>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    </div>
    <p class="divData"></p>
    <div class='row'>
        <!-- <div id='map' class='col'></div> -->
        <app-map-frame (markerCount)="setMarkerCount($event)">

        </app-map-frame>
    </div>
    <div class='row' style='padding: 18px 29px;'>
        <p style="font-weight: 400;">
            * El número de pines en mapa puede ser diferente al número de solicitudes, simpatizantes y promovidos
            registrados; esto dependerá de las direcciones guardadas en cada registro (solicitudes/atención
            política/promovidos).
        </p>
        <p style="font-weight: 400;">
            * La información contenida en las capas del mapa, corresponden a polígonos del Marco Geográfico
            Electoral
            Federal, con fecha de corte 30 de marzo de 2023 y el Condensado Estatal Distrital (CED), el Condensado
            Estatal Seccional (CES) y el Plano Distrital Seccional (PDS), con fecha de corte 14 de diciembre de
            2022,
            mismas que se encuentran actualizadas en relación a la distritación vigente y aplicable aprobada por el
            Instituto Nacional Electoral (INE).
        </p>

    </div>
</div>