<div class="contenedor">
  <div class="back btn btn-sm mt-3 ml-3">
    <i class="fa fa-arrow-left fa-lg" (click)="goBack()" style="cursor: pointer;" aria-hidden="true"></i>
  </div>
  <h1 class="page-header">Datos del promovido &nbsp;<small></small></h1>
  <hr>
  <div class="row custom-row m-2">
    <div class="col-12 col-md-4">
      <label class="p-t-2">Folio asignado: </label>
      <div class="form-control">{{ promoted.qrcode }}</div>
    </div>
    <div class="col-12 col-md-4 p-0" style="justify-content:end;">
      <button class="btn btn-secondary mr-5 ml-2 mb-0 buttonOptions"
        (click)="goToUpdate(promoted.id_promoted,promoted.id_user)"><i class="fa fa-edit"></i> Editar</button>
      <!-- <button class="btn btn-secondary mb-0 buttonOptions" style="background-color: rgb(180, 6, 41);border: none;">VOTÓ</button> -->
    </div>
  </div>
  <div class="sectionBody">
    <div class="row tHeader">
      <div class="col-3 tab" (click)="changeSelected(0)" [ngClass]="{'active': selectedTab == 0}">
        <div class="sectionForm">
          <span class="numberNotSelected" *ngIf="selectedTab != 0 else one">
            <div class="fa-solid fa-check"></div>
          </span>
          <ng-template #one>
            <span class="number">
              1
            </span>
          </ng-template>
          <div>
            <span class="info">Datos generales </span>
            <i class="fa fa-arrow-right"></i>
            <small>Nombre, Dirección, Teléfono...</small>
          </div>
        </div>
      </div>
      <div class="col-3 tab" (click)="changeSelected(1)" [ngClass]="{'active': selectedTab == 1}">
        <div class="sectionForm">
          <span class="numberNotSelected" *ngIf="selectedTab != 1 else second">
            <div class="fa-solid fa-check"></div>
          </span>
          <ng-template #second>
            <span class="number">
              2
            </span>
          </ng-template>
          <div>
            <span class="info">Encuestas </span>
            <i class="fa fa-arrow-right"></i>
            <small>Encuesta, Evidencia...</small>
          </div>
        </div>
      </div>
      <div class="col-3 tab" (click)="changeSelected(2)" [ngClass]="{'active': selectedTab == 2}">
        <div class="sectionForm">
          <span class="numberNotSelected" *ngIf="selectedTab != 2 else third">
            <div class="fa-solid fa-check"></div>
          </span>
          <ng-template #third>
            <span class="number">
              3
            </span>
          </ng-template>
          <div>
            <span class="info">Documentación</span>
            <i class="fa fa-arrow-right"></i>
            <small>Documentación</small>
          </div>
        </div>
      </div>
      <div class="col-3 tab" (click)="changeSelected(3)" [ngClass]="{'active': selectedTab == 3}">
        <div class="sectionForm">
          <span class="numberNotSelected" *ngIf="selectedTab != 3 else four">
            <div class="fa-solid fa-check"></div>
          </span>
          <ng-template #four>
            <span class="number">
              4
            </span>
          </ng-template>
          <div>
            <span class="info">Historial </span>
            <i class="fa fa-arrow-right"></i>
            <small>Registro de la actividad</small>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col p-0">
        <mat-stepper (selectionChange)="onStepChange($event)" #stepper>
          <mat-step>
            <div class="row">
              <div class="col">
                <div class="contenedor">
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <label class="p-t-2">Periodo:</label>
                      <div class="form-control">{{ promoted.period || 'Sin información'}}
                      </div>
                    </div>
                    <div class="col-12 col-md-4">
                      <label class="p-t-2">Lugar de levantamiento:</label>
                      <div class="form-control">{{ promoted.id_lifting || 'Sin información'}}
                      </div>
                    </div>
                  </div>
                  <div class="panel panel-info">
                    <p class="divData">Datos del promovido</p>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <label class="p-t-2">Nombre completo:</label>
                      <div class="form-control">{{ promoted.complete_name || 'Sin información' }}
                      </div>
                    </div>
                    <div class="col-12 col-md-4">
                      <label class="p-t-2">Fecha de nacimiento:</label>
                      <div class="form-control">{{ (promoted.birth_date | date:'dd/MM/yyyy') ||
                        'Sin información'}}</div>
                    </div>
                    <div class="col-12 col-md-4">
                      <label class="p-t-2">Sexo:</label>
                      <div class="form-control">{{ promoted.gender || 'Sin información'}}</div>
                    </div>
                    <div class="col-12 col-md-4">
                      <label class="p-t-2">Edad:</label>
                      <div class="form-control">{{ promoted.age || 'Sin información'}}</div>
                    </div>
                    <div class="col-12 col-md-4">
                      <label class="p-t-2">Curp:</label>
                      <div class="form-control">{{ promoted.curp || 'Sin información'}}</div>
                    </div>
                    <div class="col-12 col-md-4">
                      <label class="p-t-2">Correo electrónico:</label>
                      <div class="form-control">{{ promoted.email || 'Sin información'}}</div>
                    </div>
                    <div class="col-12 col-md-4">
                      <label class="p-t-2">Teléfono de casa:</label>
                      <div class="form-control">{{ promoted.phone || 'Sin información'}}</div>
                    </div>
                    <div class="col-12 col-md-4">
                      <label class="p-t-2">Teléfono celular:</label>
                      <div class="form-control">{{ promoted.cell_phone || 'Sin información'}}
                      </div>
                    </div>
                    <div class="col-12 col-md-4">
                      <label class="p-t-2">Estado civil:</label>
                      <div class="form-control">{{ promoted.civil_status || 'Sin información'}}
                      </div>
                    </div>
                    <div class="col-12 col-md-4">
                      <label class="p-t-2">Lugar de nacimiento (Estado):</label>
                      <div class="form-control">{{ promoted.birth_entity_name ||
                        'Sininformación'}}</div>
                    </div>
                    <div class="col-12 col-md-4">
                      <label class="p-t-2">Lugar de nacimiento (Municipio):</label>
                      <div class="form-control">
                        {{ promoted.birth_municipality_name || 'Sin información'}}
                      </div>
                    </div>
                    <div class="col-12 col-md-4">
                      <label class="p-t-2">Escolaridad:</label>
                      <div class="form-control">{{ promoted.scholarship || 'Sin información'}}
                      </div>
                    </div>
                    <div class="col-12 col-md-4">
                      <label class="p-t-2">Ocupación:</label>
                      <div class="form-control">{{ promoted.occupation || 'Sin información'}}
                      </div>
                    </div>
                    <div class="col-12 col-md-4">
                      <label class="p-t-2">Lugar de trabajo:</label>
                      <div class="form-control">{{ promoted.workplace || 'Sin información'}}</div>
                    </div>
                    <div class="col-12 col-md-4">
                      <label class="p-t-2">Teléfono de trabajo:</label>
                      <div class="form-control">
                        {{ promoted.cell_phone_office || 'Sin información'}}</div>
                    </div>
                    <div class="col-12 col-md-4">
                      <label class="p-t-2">
                        ¿Cuántas personas mayores de edad habitan en esta vivienda?</label>
                      <div class="form-control">{{ promoted.elderly || 'Sin información'}}</div>
                    </div>
                    <div class="col-12 col-md-4">
                      <label class="p-t-2">
                        Folio interno:</label>
                      <div class="form-control">{{ promoted.folio_interno || 'Sin información'}}</div>
                    </div>
                  </div>
                  <div class="panel panel-info">
                    <p class="divData">Dirección</p>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <label class="p-t-2">Calle:</label>
                      <div class="form-control">{{ promoted.street || 'Sin información'}}</div>
                    </div>
                    <div class="col-12 col-md-4">
                      <label class="p-t-2">Número exterior:</label>
                      <div class="form-control">{{ promoted.street_num_ext
                        || 'Sin información'}}
                      </div>
                    </div>
                    <div class="col-12 col-md-4">
                      <label class="p-t-2">Número interior:</label>
                      <div class="form-control">{{ promoted.street_num_int
                        || 'Sin información'}}
                      </div>
                    </div>
                    <div class="col-12 col-md-4">
                      <label class="p-t-2">Código postal:</label>
                      <div class="form-control">{{ promoted.postal_code
                        || 'Sin información'}}
                      </div>
                    </div>
                    <div class="col-12 col-md-4">
                      <label class="p-t-2">Colonia:</label>
                      <div class="form-control">
                        {{ promoted.neighborhoodName || 'Sin información'}}</div>
                    </div>
                    <div class="col-12 col-md-4">
                      <label class="p-t-2">Municipio:</label>
                      <div class="form-control">
                        {{ promoted.municipalityName || 'Sin información'}}</div>
                    </div>
                    <div class="col-12 col-md-4">
                      <label class="p-t-2">Estado:</label>
                      <div class="form-control">
                        {{ promoted.entityName || 'Sin información'}}
                      </div>
                    </div>
                    <div class="col-12 col-md-4">
                      <label class="p-t-2">Sección electoral:</label>
                      <div class="form-control">
                        {{ promoted.electoral_section || 'Sin información'}}</div>
                    </div>
                    <div class="col-12 col-md-4">
                      <label class="p-t-2">Distrito local:</label>
                      <div class="form-control">
                        {{ promoted.local_district || 'Sin información'}}</div>
                    </div>
                    <div class="col-12 col-md-4">
                      <label class="p-t-2">Distrito federal:</label>
                      <div class="form-control">
                        {{ promoted.federal_district || 'Sin información'}}</div>
                    </div>
                    <div class="col-12 col-md-4">
                      <label class="p-t-2">Referencia:</label>
                      <div class="form-control">{{ promoted.reference || 'Sin información'}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="footer-form">
              <button class="btn btn-primary" (click)="changeSelected(1)">Siguiente</button>
            </div>
          </mat-step>
          <mat-step>
            <div class="row">
              <div class="col-12">
                <div class="accordion accordion-flush" id="accordionPanelsStayOpenExample">


                  <div class="accordion-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                      <button class="accordion-button collapsed accordion-primary" type="button"
                        data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseOne">
                        <div class="col-12">
                          <i class="fa fa-check mr-3"></i>
                          {{surveyTittle}}
                        </div>
                      </button>
                    </h2>
                    <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse"
                      aria-labelledby="panelsStayOpen-headingOne">
                      <div class="accordion-body">
                        <div class="row">
                          <div class="col">
                            <div class="contenedor p-4">
                              <div class="row" *ngIf="questions">

                                <ng-container *ngFor="let question of questions">
                                  <div class="form-group col-12 col-md-4">
                                    <span class="reqForm">
                                      {{question.order}}.
                                    </span>
                                    <label class="p-t-2">{{question.name}}</label>
                                    <div class="form-control">
                                      {{question.answers[0]?.value
                                      || 'Sin información' }}
                                    </div>
                                  </div>

                                </ng-container>

                                <div class="row mb-3 mt-3">
                                  <div class="col-12 col-md-6">
                                    <label class="p-t-2">Bitácora:</label>

                                    <div class="form-control">{{
                                      promoted.bitacora ||
                                      'Sin información'}}</div>
                                  </div>
                                </div>

                              </div>

                              <div class="row">
                                <div class="col-12">
                                  <div class="panel panel-info">
                                    <p class="divData">
                                      Visita
                                    </p>
                                  </div>
                                </div>

                                <div class="contenedor">
                                  <div class="col-md-12 m-b-5" *ngFor="let visit of visits">
                                    <div class="row">

                                      <div class="col-md-10">
                                        <p>{{ visit.nameUserVisitor }} realizó
                                          la visita.</p>
                                        <p>{{ visit.note }}</p>
                                      </div>
                                      <div class="col-md-2">{{visit.date}}</div>

                                      <div class="row" *ngIf="visit.evidence_image">
                                        <div class="col-md-12">
                                          <label>Evidencia </label>
                                          <div *ngFor="let evidence_image of visit.evidence_image" class="row">
                                            <div class="col mb-3">
                                              <img style="max-width: 8rem;" src="{{evidence_image}}" alt="">
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                </div>

                              </div>

                              <div class="row" *ngIf="issues[0] || issues[1] || issues[2] || issues[3]">
                                <div class="col-12">
                                  <div class="panel panel-info">
                                    <p class="divData">
                                      Gestión (opcional)
                                    </p>
                                  </div>
                                </div>

                                <div class="col-12">
                                  <div class="accordion accordion-flush" id="accordionPanelsStayOpenExample">
                                    <div class="accordion-item">
                                      <h2 class="accordion-header" id="panelsStayOpen-headingissue1">
                                        <button class="accordion-button accordion-secondary collapsed" type="button"
                                          data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-issue1"
                                          aria-expanded="false" aria-controls="panelsStayOpen-issue1">
                                          <div class="col-12">
                                            Primera problemática de la calle

                                          </div>
                                        </button>
                                      </h2>
                                      <div id="panelsStayOpen-issue1" class="accordion-collapse collapse"
                                        aria-labelledby="panelsStayOpen-headingissue1">
                                        <div class="accordion-body">
                                          <div class="row">
                                            <div class="col-12 col-md-4">
                                              <label class="p-t-2">Tema</label>
                                              <div class="form-control">{{
                                                issues[0].theme_name
                                                || 'Sin información' }}
                                              </div>
                                            </div>

                                            <div class="col-12 col-md-4">
                                              <label class="p-t-2">Subtema</label>
                                              <div class="form-control">{{
                                                issues[0].subtheme_name
                                                || 'Sin información' }}
                                              </div>
                                            </div>

                                            <div class="col-12 col-md-4">
                                              <label class="p-t-2">Clasificación</label>
                                              <div class="form-control">{{
                                                issues[0].category_name
                                                || 'Sin información' }}
                                              </div>
                                            </div>

                                            <div class="col-12 col-md-4">
                                              <label class="p-t-2">Descripción</label>
                                              <div class="form-control">{{
                                                issues[0].description
                                                || 'Sin información' }}
                                              </div>
                                            </div>

                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="accordion-item">
                                      <h2 class="accordion-header" id="panelsStayOpen-headingissue2">
                                        <button class="accordion-button collapsed accordion-secondary" type="button"
                                          data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-issue2"
                                          aria-expanded="false" aria-controls="panelsStayOpen-issue2">
                                          <div class="col-12">
                                            Segunda problemática de la calle

                                          </div>
                                        </button>
                                      </h2>
                                      <div id="panelsStayOpen-issue2" class="accordion-collapse collapse"
                                        aria-labelledby="panelsStayOpen-headingissue2">
                                        <div class="accordion-body">
                                          <div class="row">
                                            <div class="col-12 col-md-4">
                                              <label class="p-t-2">Tema</label>
                                              <div class="form-control">{{
                                                issues[1].theme_name
                                                || 'Sin información' }}
                                              </div>
                                            </div>

                                            <div class="col-12 col-md-4">
                                              <label class="p-t-2">Subtema</label>
                                              <div class="form-control">{{
                                                issues[1].subtheme_name
                                                || 'Sin información' }}
                                              </div>
                                            </div>

                                            <div class="col-12 col-md-4">
                                              <label class="p-t-2">Clasificación</label>
                                              <div class="form-control">{{
                                                issues[1].category_name
                                                || 'Sin información' }}
                                              </div>
                                            </div>

                                            <div class="col-12 col-md-4">
                                              <label class="p-t-2">Descripción</label>
                                              <div class="form-control">{{
                                                issues[1].description
                                                || 'Sin información' }}
                                              </div>
                                            </div>

                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="accordion-item">
                                      <h2 class="accordion-header" id="panelsStayOpen-headingissue3">
                                        <button class="accordion-button collapsed accordion-secondary" type="button"
                                          data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-issue3"
                                          aria-expanded="false" aria-controls="panelsStayOpen-issue3">
                                          <div class="col-12">
                                            Primera problemática de la
                                            colonia

                                          </div>
                                        </button>
                                      </h2>
                                      <div id="panelsStayOpen-issue3" class="accordion-collapse collapse"
                                        aria-labelledby="panelsStayOpen-headingissue3">
                                        <div class="accordion-body">
                                          <div class="row">
                                            <div class="col-12 col-md-4">
                                              <label class="p-t-2">Tema</label>
                                              <div class="form-control">{{
                                                issues[2].theme_name
                                                || 'Sin información' }}
                                              </div>
                                            </div>

                                            <div class="col-12 col-md-4">
                                              <label class="p-t-2">Subtema</label>
                                              <div class="form-control">{{
                                                issues[2].subtheme_name
                                                || 'Sin información' }}
                                              </div>
                                            </div>

                                            <div class="col-12 col-md-4">
                                              <label class="p-t-2">Clasificación</label>
                                              <div class="form-control">{{
                                                issues[2].category_name
                                                || 'Sin información' }}
                                              </div>
                                            </div>

                                            <div class="col-12 col-md-4">
                                              <label class="p-t-2">Descripción</label>
                                              <div class="form-control">{{
                                                issues[2].description
                                                || 'Sin información' }}
                                              </div>
                                            </div>

                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="accordion-item">
                                      <h2 class="accordion-header" id="panelsStayOpen-headingissue4">
                                        <button class="accordion-button collapsed accordion-secondary" type="button"
                                          data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-issue4"
                                          aria-expanded="false" aria-controls="panelsStayOpen-issue4">
                                          <div class="col-12">
                                            Segunda problemática de la
                                            colonia

                                          </div>
                                        </button>
                                      </h2>
                                      <div id="panelsStayOpen-issue4" class="accordion-collapse collapse"
                                        aria-labelledby="panelsStayOpen-headingissue4">
                                        <div class="accordion-body">
                                          <div class="row">
                                            <div class="col-12 col-md-4">
                                              <label class="p-t-2">Tema</label>
                                              <div class="form-control">{{
                                                issues[3].theme_id
                                                || 'Sin información' }}
                                              </div>
                                            </div>

                                            <div class="col-12 col-md-4">
                                              <label class="p-t-2">Subtema</label>
                                              <div class="form-control">{{
                                                issues[3].subtheme_name
                                                || 'Sin información' }}
                                              </div>
                                            </div>

                                            <div class="col-12 col-md-4">
                                              <label class="p-t-2">Clasificación</label>
                                              <div class="form-control">{{
                                                issues[3].category_name
                                                || 'Sin información' }}
                                              </div>
                                            </div>

                                            <div class="col-12 col-md-4">
                                              <label class="p-t-2">Descripción</label>
                                              <div class="form-control">{{
                                                issues[3].description
                                                || 'Sin información' }}
                                              </div>
                                            </div>

                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <ng-container *ngIf="idWorkGroup == '95131a90d2da7ce01cac1eeed6da7cf0'">
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                        <button class="accordion-button collapsed accordion-primary" type="button"
                          data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseOne">
                          <div class="col-12">
                            <i class="fa fa-house"></i>
                            {{surveyTittle}}
                          </div>
                        </button>
                      </h2>
                      <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse"
                        aria-labelledby="panelsStayOpen-headingOne">
                        <div class="accordion-body">
                          <div class="row">
                            <div class="col">
                              <div class="contenedor p-4">
                                <div class="row" *ngIf="questions">

                                  <ng-container *ngFor="let question of questions">
                                    <div class="form-group col-12 col-md-4">
                                      <label class="p-t-2">{{question.name}}</label>
                                      <div class="form-control">
                                        {{question.answers[0]?.value
                                        || 'Sin información' }}
                                      </div>
                                    </div>

                                  </ng-container>

                                  <div class="row mb-3 mt-3">
                                    <div class="col-12 col-md-6">
                                      <label class="p-t-2">Bitácora:</label>

                                      <div class="form-control">{{
                                        promoted.bitacora ||
                                        'Sin información'}}</div>
                                    </div>
                                  </div>

                                </div>

                                <div class="row">
                                  <div class="col-12">
                                    <div class="panel panel-info">
                                      <p class="divData">
                                        Visita
                                      </p>
                                    </div>
                                  </div>

                                  <div class="contenedor">
                                    <div class="col-md-12 m-b-5" *ngFor="let visit of visits">
                                      <div class="row">

                                        <div class="col-md-10">
                                          <p>{{ visit.nameUserVisitor }}
                                            realizó
                                            la visita.</p>
                                          <p>{{ visit.note }}</p>
                                        </div>
                                        <div class="col-md-2">{{visit.date}}
                                        </div>

                                        <div class="row" *ngIf="visit.evidence_image">
                                          <div class="col-md-12">
                                            <label>Evidencia </label>
                                            <div *ngFor="let evidence_image of visit.evidence_image" class="row">
                                              <div class="col mb-3">
                                                <img style="max-width: 8rem;" src="{{evidence_image}}" alt="">
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                      </div>
                                    </div>
                                  </div>

                                </div>

                                <div class="row" *ngIf="issues[0] || issues[1] || issues[2] || issues[3]">
                                  <div class="col-12">
                                    <div class="panel panel-info">
                                      <p class="divData">
                                        Gestión (opcional)
                                      </p>
                                    </div>
                                  </div>

                                  <div class="col-12">
                                    <div class="accordion accordion-flush" id="accordionPanelsStayOpenExample">
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="panelsStayOpen-headingissue1">
                                          <button class="accordion-button accordion-secondary collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-issue1"
                                            aria-expanded="false" aria-controls="panelsStayOpen-issue1">
                                            <div class="col-12">
                                              Primera problemática de la
                                              calle

                                            </div>
                                          </button>
                                        </h2>
                                        <div id="panelsStayOpen-issue1" class="accordion-collapse collapse"
                                          aria-labelledby="panelsStayOpen-headingissue1">
                                          <div class="accordion-body">
                                            <div class="row">
                                              <div class="col-12 col-md-4">
                                                <label class="p-t-2">Tema</label>
                                                <div class="form-control">
                                                  {{
                                                  issues[0].theme_name
                                                  || 'Sin información'
                                                  }}
                                                </div>
                                              </div>

                                              <div class="col-12 col-md-4">
                                                <label class="p-t-2">Subtema</label>
                                                <div class="form-control">
                                                  {{
                                                  issues[0].subtheme_name
                                                  || 'Sin información'
                                                  }}
                                                </div>
                                              </div>

                                              <div class="col-12 col-md-4">
                                                <label class="p-t-2">Clasificación</label>
                                                <div class="form-control">
                                                  {{
                                                  issues[0].category_name
                                                  || 'Sin información'
                                                  }}
                                                </div>
                                              </div>

                                              <div class="col-12 col-md-4">
                                                <label class="p-t-2">Descripción</label>
                                                <div class="form-control">
                                                  {{
                                                  issues[0].description
                                                  || 'Sin información'
                                                  }}
                                                </div>
                                              </div>

                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="panelsStayOpen-headingissue2">
                                          <button class="accordion-button collapsed accordion-secondary" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-issue2"
                                            aria-expanded="false" aria-controls="panelsStayOpen-issue2">
                                            <div class="col-12">
                                              Segunda problemática de la
                                              calle

                                            </div>
                                          </button>
                                        </h2>
                                        <div id="panelsStayOpen-issue2" class="accordion-collapse collapse"
                                          aria-labelledby="panelsStayOpen-headingissue2">
                                          <div class="accordion-body">
                                            <div class="row">
                                              <div class="col-12 col-md-4">
                                                <label class="p-t-2">Tema</label>
                                                <div class="form-control">
                                                  {{
                                                  issues[1].theme_name
                                                  || 'Sin información'
                                                  }}
                                                </div>
                                              </div>

                                              <div class="col-12 col-md-4">
                                                <label class="p-t-2">Subtema</label>
                                                <div class="form-control">
                                                  {{
                                                  issues[1].subtheme_name
                                                  || 'Sin información'
                                                  }}
                                                </div>
                                              </div>

                                              <div class="col-12 col-md-4">
                                                <label class="p-t-2">Clasificación</label>
                                                <div class="form-control">
                                                  {{
                                                  issues[1].category_name
                                                  || 'Sin información'
                                                  }}
                                                </div>
                                              </div>

                                              <div class="col-12 col-md-4">
                                                <label class="p-t-2">Descripción</label>
                                                <div class="form-control">
                                                  {{
                                                  issues[1].description
                                                  || 'Sin información'
                                                  }}
                                                </div>
                                              </div>

                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="panelsStayOpen-headingissue3">
                                          <button class="accordion-button collapsed accordion-secondary" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-issue3"
                                            aria-expanded="false" aria-controls="panelsStayOpen-issue3">
                                            <div class="col-12">
                                              Primera problemática de la
                                              colonia

                                            </div>
                                          </button>
                                        </h2>
                                        <div id="panelsStayOpen-issue3" class="accordion-collapse collapse"
                                          aria-labelledby="panelsStayOpen-headingissue3">
                                          <div class="accordion-body">
                                            <div class="row">
                                              <div class="col-12 col-md-4">
                                                <label class="p-t-2">Tema</label>
                                                <div class="form-control">
                                                  {{
                                                  issues[2].theme_name
                                                  || 'Sin información'
                                                  }}
                                                </div>
                                              </div>

                                              <div class="col-12 col-md-4">
                                                <label class="p-t-2">Subtema</label>
                                                <div class="form-control">
                                                  {{
                                                  issues[2].subtheme_name
                                                  || 'Sin información'
                                                  }}
                                                </div>
                                              </div>

                                              <div class="col-12 col-md-4">
                                                <label class="p-t-2">Clasificación</label>
                                                <div class="form-control">
                                                  {{
                                                  issues[2].category_name
                                                  || 'Sin información'
                                                  }}
                                                </div>
                                              </div>

                                              <div class="col-12 col-md-4">
                                                <label class="p-t-2">Descripción</label>
                                                <div class="form-control">
                                                  {{
                                                  issues[2].description
                                                  || 'Sin información'
                                                  }}
                                                </div>
                                              </div>

                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="panelsStayOpen-headingissue4">
                                          <button class="accordion-button collapsed accordion-secondary" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-issue4"
                                            aria-expanded="false" aria-controls="panelsStayOpen-issue4">
                                            <div class="col-12">
                                              Segunda problemática de la
                                              colonia

                                            </div>
                                          </button>
                                        </h2>
                                        <div id="panelsStayOpen-issue4" class="accordion-collapse collapse"
                                          aria-labelledby="panelsStayOpen-headingissue4">
                                          <div class="accordion-body">
                                            <div class="row">
                                              <div class="col-12 col-md-4">
                                                <label class="p-t-2">Tema</label>
                                                <div class="form-control">
                                                  {{
                                                  issues[3].theme_id
                                                  || 'Sin información'
                                                  }}
                                                </div>
                                              </div>

                                              <div class="col-12 col-md-4">
                                                <label class="p-t-2">Subtema</label>
                                                <div class="form-control">
                                                  {{
                                                  issues[3].subtheme_name
                                                  || 'Sin información'
                                                  }}
                                                </div>
                                              </div>

                                              <div class="col-12 col-md-4">
                                                <label class="p-t-2">Clasificación</label>
                                                <div class="form-control">
                                                  {{
                                                  issues[3].category_name
                                                  || 'Sin información'
                                                  }}
                                                </div>
                                              </div>

                                              <div class="col-12 col-md-4">
                                                <label class="p-t-2">Descripción</label>
                                                <div class="form-control">
                                                  {{
                                                  issues[3].description
                                                  || 'Sin información'
                                                  }}
                                                </div>
                                              </div>

                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="accordion-item">
                      <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                        <button class="accordion-button collapsed accordion-primary" type="button"
                          data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseTwo">
                          <div class="col-12">
                            <i class="fa fa-house"></i>
                            Visita 2
                          </div>
                        </button>
                      </h2>
                      <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse"
                        aria-labelledby="panelsStayOpen-headingTwo">
                        <div class="accordion-body" style="display: flex;align-items: center;justify-content: center;">
                          <div style="text-align: center; padding: 20px;">
                            <i class="fas fa-cog fa-spin" style="font-size: 48px; color: #888;"></i>
                            <p>Prepárate para descubrir nuevas funcionalidades proximamente.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                        <button class="accordion-button collapsed accordion-primary" type="button"
                          data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree">
                          <div class="col-12">
                            <i class="fa fa-house"></i>
                            Visita 3
                          </div>
                        </button>
                      </h2>
                      <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse"
                        aria-labelledby="panelsStayOpen-headingThree">
                        <div class="accordion-body" style="display: flex;align-items: center;justify-content: center;">
                          <div style="text-align: center; padding: 20px;">
                            <i class="fas fa-cog fa-spin" style="font-size: 48px; color: #888;"></i>
                            <p>Prepárate para descubrir nuevas funcionalidades proximamente.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="panelsStayOpen-headingFour">
                        <button class="accordion-button collapsed accordion-primary" type="button"
                          data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseFour">
                          <div class="col-12">
                            <i class="fa fa-house"></i>
                            Visita 4
                          </div>
                        </button>
                      </h2>
                      <div id="panelsStayOpen-collapseFour" class="accordion-collapse collapse"
                        aria-labelledby="panelsStayOpen-headingFour">
                        <div class="accordion-body" style="display: flex;align-items: center;justify-content: center;">
                          <div style="text-align: center; padding: 20px;">
                            <i class="fas fa-cog fa-spin" style="font-size: 48px; color: #888;"></i>
                            <p>Prepárate para descubrir nuevas funcionalidades proximamente.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                  </ng-container>
                </div>
              </div>
            </div>
            <div class="contenedor p-0">
              <div class="row">
                <div class="col-12">
                  <div class="col-12">
                    <div class="panel panel-info">
                      <p class="divData">Clasificación del Promovido</p>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="row mb-3">
                      <div class="col-12 col-md-4">
                        <label class="p-t-2">¿Cuál es su preferencia partidaria?</label>
                      </div>
                      <div class="col-12 col-md-4">
                        <div class="form-control">{{ promoted.position || 'Sin información'}}
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3" *ngIf="promoted.position  == 'POSITIVO'">
                      <div class="col-12 col-md-4">
                        <label class="p-t-2">¿Le gustaría participar en la campaña?</label>
                      </div>
                      <div class="col-12 col-md-4">
                        <div class="form-control">
                          {{ promoted.position_type || 'Sin información'}}</div>
                      </div>
                    </div>
                    <div class="row mb-3" *ngIf="promoted.position  == 'NEUTRO'">
                      <div class="col-12 col-md-4">
                        <label class="p-t-2">¿Cuál es la preferencia de su voto?</label>
                      </div>
                      <div class="col-12 col-md-4">
                        <div class="form-control">
                          {{ promoted.vote_preference || 'Sin información'}}</div>
                      </div>
                    </div>
                    <div class="row mb-3" *ngIf="promoted.position  == 'POSITIVO' || promoted.position  == 'NEUTRO'">
                      <div class="col-12 col-md-4">
                        <label class="p-t-2">¿Tiene algún tipo de participación?</label>
                      </div>
                      <div class="col-12 col-md-4">
                        <div class="col-md-12">
                          <div class="radio radio-css radio-inline">
                            <input id="Participationyes" disabled type="radio" [checked]="promoted.participation == 1">
                            <label for="Participationyes">Sí</label>
                          </div>
                          <div class="radio radio-css radio-inline">
                            <input id="Participationnot" disabled type="radio" [checked]="promoted.participation == 0">
                            <label for="Participationnot">No</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3" *ngIf="promoted.participation  == 1 ">
                      <div class="col-12 col-md-4">
                        <label class="p-t-2">¿Cuál?</label>
                      </div>


                      <div class="col-12 col-md-4">
                        <div class="form-control">{{
                          promoted.participation_type ||
                          'Sin información'}}</div>
                      </div>
                    </div>
                    <ng-container *ngIf="promoted.position  == 'NEGATIVO'">
                      <div class="row mb-3">
                        <div class="col-12 col-md-4">
                          <label class="p-t-2">¿Considera que hay un rechazo
                            de su parte hacia los partidos
                            políticos?</label>
                        </div>

                        <div class="col-12 col-md-4">
                          <div class="form-control">{{
                            promoted.political_rejection ||
                            'Sin información'}}</div>
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col-12 col-md-4">
                          <label class="p-t-2">¿Simpatiza y/o es militante
                            de la oposición?</label>
                        </div>

                        <div class="col-12 col-md-4">
                          <div class="form-control">{{
                            promoted.political_militant ||
                            'Sin información'}}</div>
                        </div>
                      </div>

                    </ng-container>
                    <div class="row mb-3">
                      <div class="col-12 col-md-4">
                        <label class="p-t-2">¿Se relaciona con un punto de
                          interés? </label>
                      </div>

                      <div class="col-12 col-md-4">
                        <div class="col-md-12">
                          <div class="radio radio-css radio-inline">
                            <input id="Poiyes" type="radio" [checked]="promoted.poi_related == 1" disabled>
                            <label for="Poiyes">Sí</label>
                          </div>
                          <div class="radio radio-css radio-inline">
                            <input id="Poinot" type="radio" [checked]="promoted.poi_related == 0" disabled>
                            <label for="Poinot">No</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3" *ngIf="promoted.poi_related == '1'">
                      <div class="col-12 col-md-4">
                        <label class="p-t-2">¿Cuál?</label>
                      </div>

                      <div class="col-12 col-md-4">
                        <div class="form-control">{{
                          promoted.POI_name ||
                          'Sin información'}}</div>
                      </div>

                    </div>
                    <div class="row mb-3">
                      <div class="col-12 col-md-4">
                        <label class="p-t-2">¿Es un actor importante?
                        </label>
                      </div>

                      <div class="col-12 col-md-4">
                        <div class="col-md-12">
                          <div class="radio radio-css radio-inline">
                            <input id="Actoryes" type="radio" [checked]="promoted.actor == 1" disabled>
                            <label for="Actoryes">Sí</label>
                          </div>
                          <div class="radio radio-css radio-inline">
                            <input id="Actornot" type="radio" [checked]="promoted.actor == 0" disabled>
                            <label for="Actornot">No</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3" *ngIf="promoted.actor == '1'">
                      <div class="col-12 col-md-4">
                        <label class="p-t-2">¿Qué tipo de actor es?
                        </label>
                      </div>

                      <div class="col-12 col-md-4">
                        <div class="form-control">{{
                          promoted.actor_type ||
                          'Sin información'}}</div>
                      </div>
                    </div>
                    <div class="row mb-3" *ngIf="promoted.actor_type == 'OTRO'">
                      <div class="col-12 col-md-4">
                        <label class="p-t-2">¿Qué tipo de actor es?
                        </label>
                      </div>

                      <div class="col-12 col-md-4">
                        <div class="form-control">{{
                          promoted.other_actor ||
                          'Sin información'}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="footer-form">
              <button class="btn btn-primary" (click)="changeSelected(2)">Siguiente</button>
              <button class="btn btn-light mr-2" (click)="changeSelected(0)">Anterior</button>
            </div>
          </mat-step>
          <mat-step>
            <div class="row">
              <div class="col-12">
                <div class="contenedor">
                  <div class="panel panel-info">
                    <p class="divData">Documentación entregada
                    </p>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <label class="p-t-2">
                        <i class="fa fa-user-times" [ngClass]="{'fa-user-check': promoted.ine == 1}"></i> INE
                      </label>
                    </div>
                    <div class="row" *ngIf="promoted.url_image_ine && promoted.url_image_ine.length">
                      <div class="col-12 col-md-12">
                        <label>Archivos cargados:</label>
                      </div>
                      <div class="col-12 col-md-12">

                        <img style="max-width: 5vw; max-width: 5vw;"
                          [src]="formDataService.getFileType(promoted.url_image_ine[0])"
                          (click)="openFile(promoted.url_image_ine[0])" alt="">
                      </div>
                    </div>
                    <div class="col-12 col-md-4">
                      <label class="p-t-2">
                        <i class="fa fa-user-times" [ngClass]="{'fa-user-check': promoted.acta_nacimiento == 1}"></i>
                        Acta de nacimiento
                      </label>
                    </div>
                    <div class="col-12 col-md-4">
                      <label class="p-t-2">
                        <i class="fa fa-user-times" [ngClass]="{'fa-user-check': promoted.pasaporte == 1}"></i>
                        Pasaporte
                      </label>
                    </div>
                    <div class="col-12 col-md-4">
                      <label class="p-t-2">
                        <i class="fa fa-user-times"
                          [ngClass]="{'fa-user-check': promoted.comprobante_domicilio == 1}"></i>
                        Comprobante de domicilio
                      </label>
                    </div>
                    <div class="col-12 col-md-4">
                      <label class="p-t-2">
                        <i class="fa fa-user-times" [ngClass]="{'fa-user-check': promoted.curp_doc == 1}"></i> CURP
                      </label>
                    </div>
                    <div class="col-12 col-md-4">
                      <label class="p-t-2">
                        <i class="fa fa-user-times" [ngClass]="{'fa-user-check': promoted.rfc == 1}"></i> RFC
                      </label>
                    </div>

                    <div class="col-12 col-md-4">
                      <label class="p-t-2">
                        <i class="fa fa-user-times" [ngClass]="{'fa-user-check': promoted.docAcuse == 1}"></i> Acuse
                      </label>
                    </div>
                    <div class="row" *ngIf="promoted.url_image_Acuse && promoted.url_image_Acuse.length">
                      <div class="col-12 col-md-12">
                        <label>Archivos cargados:</label>
                      </div>
                      <div class="col-12 col-md-12">

                        <a *ngFor="let url of promoted.url_image_Acuse" style=" color: #348ee3;"
                          class="align-middle col-md-12 p-t-2 cursor-pointer" (click)="openFile(url)" target="_blank">
                          {{getFileName(url)}}
                        </a>
                      </div>
                    </div>

                    <div class="col-12 col-md-4">
                      <label class="p-t-2">
                        <i class="fa fa-user-times" [ngClass]="{'fa-user-check': promoted.docExtra == 1}"></i>
                        Documentación adicional
                      </label>
                    </div>
                    <div class="row" *ngIf="promoted.url_image_Extra && promoted.url_image_Extra.length">
                      <div class="col-12 col-md-12">
                        <label>Archivos cargados:</label>
                      </div>
                      <div class="col-12 col-md-12">

                        <a *ngFor="let url of promoted.url_image_Extra" style=" color: #348ee3;"
                          class="align-middle col-md-12 p-t-2 cursor-pointer" (click)="openFile(url)" target="_blank">
                          {{getFileName(url)}}
                        </a>
                      </div>
                    </div>

                  </div>




                </div>

              </div>
            </div>
            <div class="footer-form">
              <button class="btn btn-primary" (click)="changeSelected(3)">Siguiente</button>
              <button class="btn btn-light mr-2" (click)="changeSelected(1)">Anterior</button>
            </div>
          </mat-step>
          <mat-step>

            <div class="contenedor">
              <div class="col-md-12 m-b-5" *ngFor="let visit of otherVisits">
                <div class="comment row">
                  <div class="col-md-9">
                    <i class="fa fa-home"></i>
                    <cite> <b> {{ visit.nameUser }} => {{visit.positionInstance}}</b></cite>
                  </div>
                  <div class="col-md-3 date">{{visit.date}}</div>
                  <div class="col-md-12">
                    <hr>
                    <p>{{ visit.nameUserVisitor }} realizó la visita.</p>
                    <p>{{ visit.note }}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-12 m-b-5" *ngFor="let promotedHistory of promotedHistories">
                <div class="comment row">
                  <div class="col-md-9">
                    <i class="fa fa-history"></i>
                    <cite> <b> {{ promotedHistory.nameUser }}</b></cite>:
                  </div>
                  <div class="col-md-3 date">{{promotedHistory.date}}</div>
                  <div class="col-md-12">
                    <hr>
                    <p>{{ promotedHistory.message }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="footer-form">
              <button class="btn btn-light" (click)="changeSelected(2)">Anterior</button>
            </div>
          </mat-step>
        </mat-stepper>
      </div>
    </div>
  </div>
</div>
