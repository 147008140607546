import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-historical-monthly',
  templateUrl: './historical-monthly.component.html',
  styleUrls: ['./historical-monthly.component.scss']
})
export class HistoricalMonthlyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
