import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormDataService } from 'src/app/shared/services/form-data.service';
import { RequestService } from 'src/app/shared/services/request.service';
import { CustomeValidationService } from 'src/app/shared/services/custome-validation.service';

@Component({
  selector: 'app-detail-request-history',
  template: 'Detail Request History: {{ idApplicant }}',
  templateUrl: './detail-request-history.component.html',
  styleUrls: ['./detail-request-history.component.scss'],
})
export class DetailRequestHistoryComponent implements OnInit {
  @Input() idApplicant: number = 0;

  data: any = [];
  requestH: any = [];
  visits: any = [];
  idRequest: any;
  urlAccomplished: any;
  urlAccomplishedWithType: any[] = [];

  constructor(
    private requestService: RequestService,
    private CustomeValidationService: CustomeValidationService,
    private activeRoute: ActivatedRoute,
    private formDataService: FormDataService
  ) { }

  ngOnInit(): void {
    this.getRequestHistory();
    this.getRequestData();
  }

  getRequestData() {
    this.formDataService.requestDataResults$.subscribe((data) => {
      this.data = data;
      if(data.url_image_accom && data.url_image_accom != '' && data.url_image_accom != 'null'){
        this.urlAccomplished = this.CustomeValidationService.checkForServerLocation(
          data.url_image_accom
        );
        console.log(this.urlAccomplished);
        // Asignar el tipo a cada elemento en el array
        this.urlAccomplishedWithType = this.urlAccomplished.map((item: any) => ({
          item,
          type: this.determineFileType(item),
        }));
        console.log(this.urlAccomplishedWithType);
      }
    });
  }

  getRequestHistory() {
    this.idRequest = this.activeRoute.snapshot.paramMap.get('idRequest');
    this.requestService.getRequestHistoryData(this.idRequest).subscribe((response: any) => {
      this.requestH = response;
      this.getVisit();
    });
  }

  getVisit() {
    if (this.idApplicant != undefined) {
      this.requestService.getVisitData(this.idApplicant).subscribe((data) => {
        this.visits = data;
      });
    }
  }

  // Función para determinar el tipo basado en la extensión
  determineFileType(url: string) {
    const splitUrl = url.split('.');
    if (splitUrl.length > 1) {
      const fileExtension = splitUrl.pop()!.toLowerCase();
      if (['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension)) {
        return 1; // Es una imagen
      }
    }
    return 2; // Por defecto, es un archivo
  }

  getFileName(url: string): string {
    const parts = url.split('/');
    return decodeURIComponent(parts[parts.length - 1]);
  }
}
