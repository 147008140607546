<br>
<div>
  <div class="container">
    <h1>Datos completos, ID de ciudadano/a
    </h1>
    <br>
    <div class="row graphicTwo">
      <div class="col-md-6">
    <h2>Personas solicitantes: {{this.totalR}}
    </h2>
    <br>
    <div class="chart-container">
      <canvas  id="ChartStatusRequest">{{ chartStatusRequest }}</canvas>
  </div>
</div>
<div class="col-md-6">
  <h2> Personas simpatizantes: {{this.totalS}}
  </h2>
  <br>
  <div class="chart-container">
    <canvas  id="ChartTotalRequest">{{ chartTotalRequest }}</canvas>
</div>
</div>
    </div>
</div>
</div>
