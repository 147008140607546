import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {Chart} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { StatsService } from 'src/app/shared/services/stats.service';
import { AnnualHistoryComponent } from '../../annual-history.component';

@Component({
  selector: 'app-closed-requests-and-pending-requests-by-year',
  templateUrl: './closed-requests-and-pending-requests-by-year.component.html',
  styleUrls: ['./closed-requests-and-pending-requests-by-year.component.scss']
})
export class ClosedRequestsAndPendingRequestsByYearComponent {
   chartTotalRequest: any;
   chartStatusRequest: any;
  requestsLast: any;
  requests: any;
  public loading = true;
  showClosedRequestsAndPendingRequests: boolean = false; // Variable de control para mostrar el botón

   currentYear = new Date().getFullYear();
   lastYear = this.currentYear - 1;
   constructor(private statsService: StatsService, private activeRoute: ActivatedRoute, private annualHistoryComponent: AnnualHistoryComponent) { }

   ngAfterViewInit(): void {
    this.loadData();
  }

  loadData(): void {
    // Lógica para cargar los datos

    Promise.all([this.getThemesCountByLastYear(), this.getThemesCountByYear()])
      .then(() => {

        this.StatusRequestChart();
        this.totalRequestChart();
        this.loading = false;

        this.annualHistoryComponent.showClosedRequestsAndPendingRequests = true;
        this.annualHistoryComponent.updateView();
      })
      .catch(error => {
        console.error('Error while loading data:', error);
      });
  }


  getThemesCountByLastYear(){
    const promise = new Promise((resolve, reject) => {
      this.activeRoute.params.subscribe(routeParams => {
      this.statsService.getThemesCountByLastYear(routeParams['idWorkGroup']).subscribe(data => {
          console.log(data);
        this.requestsLast=data;
          console.log(this.requestsLast);

          resolve(true);
        }
      )
    });
  });
    return promise;
  }
  getThemesCountByYear(){
    const promise = new Promise((resolve, reject) => {
      this.activeRoute.params.subscribe(routeParams => {
      this.statsService.getThemesCountByYear(routeParams['idWorkGroup']).subscribe(data => {
          console.log(data);
        this.requests=data;
          console.log(this.requests);
          resolve(true);
        }
      )
    });
  });
    return promise;
  }
  StatusRequestChart(){

    this.chartStatusRequest = new Chart("ChartStatusRequest", {
      type: 'doughnut',

      data: {
        labels:['Cerradas','Pendientes'],
	      datasets: [
          {
            data:[this.requestsLast[0].total.closedRequestsTotal, this.requestsLast[0].total.openRequestsTotal],
            backgroundColor: ['#0e6d0f','#940000'],
          },
        ]
      },
      plugins: [ChartDataLabels],
      options: {
        aspectRatio:1.3,
        plugins: {
          legend: {
              position: 'bottom'
          },

          datalabels: {
            color: 'white',
            labels: {
              title: {
                font: {
                  weight: 'bold',
                  size: 20
                }
              },

            }
          }

      },
      parsing: {
        key: 'nested.value'
      }

      }
    });
  }
  totalRequestChart(){
    if (this.chartTotalRequest) {
      this.chartTotalRequest.destroy();
  }
    this.chartTotalRequest = new Chart("ChartTotalRequest", {
      type: 'doughnut',
      data: {
        labels:['Cerradas','Pendientes'],
	      datasets: [
          {
            data:[this.requests[0].total.closedRequestsTotal,this.requests[0].total.openRequestsTotal,],
            backgroundColor: ['#0e6d0f','#940000'],
          },
        ]
      },
      plugins: [ChartDataLabels],

      options: {

        aspectRatio:1.3,
        parsing: {
          key: 'nested.value'
        },
        plugins: {

          legend: {
              position: 'bottom'
          },
          datalabels: {
            color: 'white',
            labels: {
              title: {
                font: {
                  weight: 'bold',
                  size: 20
                }
              },

            }
          }


      },

      }
    });
  }

}
