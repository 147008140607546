import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {Chart} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { StatsService } from 'src/app/shared/services/stats.service';
@Component({
  selector: 'app-applicants-group-old-month',
  templateUrl: './applicants-group-old-month.component.html',
  styleUrls: ['./applicants-group-old-month.component.scss']
})
export class ApplicantsGroupOldMonthComponent implements OnInit {

  public chartTotalRequest2: any;
  public chartStatusRequest2: any;
  currentYear = new Date().getFullYear();
  lastYear = this.currentYear - 1;
  requestsLast: any;
  requests: any;
  chartData: any;
  chartDataYear: any;
  monthName : any;
  theme!: any [];
  stock1!: any [];
  stock1Year: any;
  idMonth!: any;
  idUser!: any;
  token!: any;
  idWorkGroup!: any;


  stock2!: any [];
  stockLastYear!: any;

  themeYear!: any [];

  labels: any[]=[];
  color1 = ['#0e6d0f'];
  color2= ['#940000'];
  grafico: any;
  public loading = true;
  public loading2 = true;

  constructor(private router:Router, private statsService: StatsService, private activeRoute: ActivatedRoute){

  }

  ngOnInit(): void {
    this.loading = true;

    Promise.all([ this.getCountByMonth()])
      .then(() => {
                this.loading = false;
      })
      .catch(error => {
        console.error('Error while loading data:', error);
        this.loading = false; // asegurarse de quitar el loader en caso de error
      });
  }

  getCountByMonth() {
    return new Promise((resolve, reject) => {
      this.activeRoute.params.subscribe((routeParams) => {
        console.log(routeParams);
        this.statsService
          .getApplicantsByAgeGroupAndMonth(routeParams['idWorkGroup'], routeParams['month'])
          .subscribe((data) => {
            console.log(data);
            const theme = [];
            const stock1 = [];
            const stock2 = [];
            const results = data.results;
            console.log(results);
            const monthNames = [
              'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
              'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
            ];
            this.stockLastYear =data;
            const monthNumber = parseInt(routeParams['month']) - 1;
            this.monthName = monthNames[monthNumber].charAt(0).toUpperCase() + monthNames[monthNumber].slice(1);
            this.stock1Year = data;

            console.log(this.monthName); // "mayo"


            const dataValues = [
              results["Indefinido"],
              results["60 o más"],
              results["27-59 años"],
              results["19-26 años"],
              results["12-18 años"],
              results["06-11 años"],
              results["00-05 años"]
            ];

            const maxDataValue = Math.max(...dataValues); // Valor máximo de los datos

            // Calcular el valor máximo deseado en el eje x (agregar un cuadro adicional)
            const maxXValue = Math.ceil(maxDataValue / 10) * 10 + 10;

            this.chartTotalRequest2 = new Chart("StatTotalRequest", {
              type: 'bar',
     data: {
      labels: ['Sin información','60 o más','27-59 años','19-26 años','12-18 años','06-11 años', '00-05 años'],
      datasets: [
        {
          data: [
            results["Indefinido"],
            results["60 o más"],
            results["27-59 años"],
            results["19-26 años"],
            results["12-18 años"],
            results["06-11 años"],
            results["00-05 años"]
          ],
        backgroundColor: ['#ffc000', '#2e75b6', '#afabab', '#00b050', '#002060', '#2e75b6','#bf9000','#ffd966','#8faadc','#c55a11','#7030a0'],
  },
      ]
    },
    plugins: [ChartDataLabels],
    options: {

      indexAxis: 'y',
      aspectRatio: 3,
      plugins: {
        legend: {
          display: false

        },
        datalabels: {
          color: 'black',
          align: 'end',
          anchor: 'end',
          labels: {
            title: {
              font: {
                weight: 'bold',
                size: 14,
              }
            },

          }
        }

      },
      scales: {
        x: {
          min: 0, // Valor mínimo deseado en el eje x
          max: maxXValue, // Valor máximo deseado en el eje x
          ticks: {
            stepSize: 5 // Incremento entre los valores en el eje x
          }
        }
      },



    }
            });

            resolve(true);
          });
      });
    });
  }





}
