import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-annual-history',
  templateUrl: './annual-history.component.html',
  styleUrls: ['./annual-history.component.scss']
})
export class AnnualHistoryComponent implements OnInit {

  componentName: string = 'closed-requests-and-pending-requests-by-year';
  showClosedRequestsAndPendingRequests: boolean = false;
  showBeneficiaryStatistics: boolean = false;
  showApplicantsByGender: boolean = false;
  showApplicantsByGroupVulnerable: boolean = false;
  showApplicantsByGroupOld: boolean = false;


  constructor(    private activeRoute: ActivatedRoute,
    ) { }

  ngOnInit(): void {
    
    this.activeRoute.params.subscribe(routeParams => {
      localStorage.setItem('token', routeParams['token'])
    });
    console.log(this.showClosedRequestsAndPendingRequests);
  }
  updateView() {
  }
  closedRequestsAndPendingRequestsByThemeAndYear(){
    this.componentName = 'closed-requests-and-pending-requests-by-theme-and-year';
    this.showClosedRequestsAndPendingRequests = false;
    this.showBeneficiaryStatistics = false;
    this.showApplicantsByGender = false;
    this.showApplicantsByGroupVulnerable = false;
    this.showApplicantsByGroupOld = false;
  }
  closedRequestsAndPendingRequestsByYear(){
    this.componentName = 'closed-requests-and-pending-requests-by-year';
    this.showBeneficiaryStatistics = false;
    this.showApplicantsByGender = false;
    this.showApplicantsByGroupVulnerable = false;
    this.showApplicantsByGroupOld = false;


  }
  beneficiaryStatistics(){
    this.componentName = 'beneficiary-Statistics';
    this.showClosedRequestsAndPendingRequests = false;
    this.showApplicantsByGender = false;
    this.showApplicantsByGroupVulnerable = false;
    this.showApplicantsByGroupOld = false;



  }
  applicantsByGender(){
    this.componentName = 'applicants-by-gender';
    this.showClosedRequestsAndPendingRequests = false;
    this.showBeneficiaryStatistics = false;
    this.showApplicantsByGroupVulnerable = false;
    this.showApplicantsByGroupOld = false;

  }
  applicantsByGroupVulnerable(){
    this.componentName = 'applicants-by-group-vulnerable';
    this.showClosedRequestsAndPendingRequests = false;
    this.showBeneficiaryStatistics = false;
    this.showApplicantsByGender = false;
    this.showApplicantsByGroupOld = false;


  }
  applicantsByOld(){
    this.componentName = 'applicants-by-Old';
    this.showClosedRequestsAndPendingRequests = false;
    this.showBeneficiaryStatistics = false;
    this.showApplicantsByGender = false;
    this.showApplicantsByGroupVulnerable = false;


  }
  peopleRegisteredSupporters(){
    this.componentName = 'people-registered-supporters';
    this.showClosedRequestsAndPendingRequests = false;
    this.showBeneficiaryStatistics = false;
    this.showApplicantsByGender = false;
    this.showApplicantsByGroupVulnerable = false;
    this.showApplicantsByGroupOld = false;


  }
  closedRequestsAndPendingRequestsByMonth(){
    this.componentName = 'closed-requests-and-pending-requests-by-month';
    this.showClosedRequestsAndPendingRequests = false;
    this.showBeneficiaryStatistics = false;
    this.showApplicantsByGender = false;
    this.showApplicantsByGroupVulnerable = false;
    this.showApplicantsByGroupOld = false;


  }
  beneficiaryMonths(){
    this.componentName = 'beneficiary-Months'
    this.showClosedRequestsAndPendingRequests = false;
    this.showBeneficiaryStatistics = false;
    this.showApplicantsByGender = false;
    this.showApplicantsByGroupVulnerable = false;
    this.showApplicantsByGroupOld = false;


  }
  applicantsByGenderMonths(){
    this.componentName = 'applicants-by-gender-months'
    this.showClosedRequestsAndPendingRequests = false;
    this.showBeneficiaryStatistics = false;
    this.showApplicantsByGender = false;
    this.showApplicantsByGroupVulnerable = false;
    this.showApplicantsByGroupOld = false;


  }
  applicantsByGroupVulnerableMonths(){
    this.componentName = 'applicants-group-vulnerable-months'
    this.showClosedRequestsAndPendingRequests = false;
    this.showBeneficiaryStatistics = false;
    this.showApplicantsByGender = false;
    this.showApplicantsByGroupVulnerable = false;
    this.showApplicantsByGroupOld = false;


  }
  applicantsgroupoldmonths(){
    this.componentName = 'applicants-group-old-months'
    this.showClosedRequestsAndPendingRequests = false;
    this.showBeneficiaryStatistics = false;
    this.showApplicantsByGender = false;
    this.showApplicantsByGroupVulnerable = false;
    this.showApplicantsByGroupOld = false;


  }
}
