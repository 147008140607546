<head>
  <link href="https://cdnjs.cloudflare.com/ajax/libs/flowbite/1.8.0/flowbite.min.css" rel="stylesheet" />
</head>
<div class="contenedor">
  <form [formGroup]="documentationForm">
    <div class="panel panel-info">
      <p class="divData">Documentos entregados</p>
    </div>
    <div class="row">
      <div class="col-12 col-md-4">
        <div class="col-md-12">
          <label>INE:</label>
        </div>
        <div class="col-md-12 offset-md-1 checkbox checkbox-css checkbox-inline">
          <input id="checkboxIne" type="checkbox" [checked]="documentationForm.get('docIne')?.value === 1"
            (change)="updateCheckbox($event,'Ine')" />
          <label for="checkboxIne">Si</label>
        </div>
      </div>
      <div class="row" *ngIf="documentationForm.get('docIne')?.value ">
        <div class="col-10 offset-1" *ngIf="AllUrlIne.length == 0 ">
          <div class="cont-image">
            <label class="block mb-2 text-sm text-gesco dark:text-white" for="multiple_files">Agregar
              INE</label>
            <input class="block w-full text-sm text-gesco border-gesco rounded-lg cursor-pointer" type="file"
              id="multiple_files" accept=".pdf,.doc,.png,.jpg,.jpeg" multiple (change)="onFileChange($event)" />
            <p class="mt-1 text-sm text-gesco" id="file_input_help">(JPEG/PNG/PDF)</p>
          </div>
        </div>

        <div class="row" *ngIf="AllUrlIne.length > 0">
          <div class="col">
            <label>Archivo de iniciativa:</label>
            <div class="row ml-3">
              <label class="col-md-12 p-t-2">
                Archivos cargados:
              </label>


              <ng-container *ngFor="let url of AllUrlIne; let i = index">
                <div class="drop-file" (click)="openImage(url)">
                  <a target="_blank" class="alert alert-drop-file show text-left cursor-pointer">
                    <span class="close text-danger" data-idx="0" data-dismiss="alert" (click)="removeFile(url,'INE')">×</span>
                    <i style="margin-top:-10px;" class="fa fa-file-image text-purple p-r-5 fa-3x pull-left"></i>
                    {{getFileName(url)}}
                  </a>
                </div>
              </ng-container>

            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="col-md-12">
          <label>Acta de nacimiento:</label>
        </div>
        <div class="col-md-12 offset-md-1 checkbox checkbox-css checkbox-inline">
          <input id="checkboxCertificate" type="checkbox"
            [checked]="documentationForm.get('docCertificate')?.value === 1"
            (change)="updateCheckbox($event,'Certificate')" />
          <label for="checkboxCertificate">Si</label>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="col-md-12">
          <label>Pasaporte:</label>
        </div>
        <div class="col-md-12 offset-md-1 checkbox checkbox-css checkbox-inline">
          <input id="checkboxPassport" type="checkbox" [checked]="documentationForm.get('docPassport')?.value === 1"
            (change)="updateCheckbox($event,'Passport')" />
          <label for="checkboxPassport">Si</label>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="col-md-12">
          <label>Comprobante de domicilio:</label>
        </div>
        <div class="col-md-12 offset-md-1 checkbox checkbox-css checkbox-inline">
          <input id="checkboxProof" type="checkbox" [checked]="documentationForm.get('docProof')?.value === 1"
            (change)="updateCheckbox($event,'Proof')" />
          <label for="checkboxProof">Si</label>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="col-md-12">
          <label>CURP:</label>
        </div>
        <div class="col-md-12 offset-md-1 checkbox checkbox-css checkbox-inline">
          <input id="checkboxCurp" type="checkbox" [checked]="documentationForm.get('docCurp')?.value === 1"
            (change)="updateCheckbox($event,'Curp')" />
          <label for="checkboxCurp">Si</label>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="col-md-12">
          <label>RFC:</label>
        </div>
        <div class="col-md-12 offset-md-1 checkbox checkbox-css checkbox-inline">
          <input id="checkboxRfc" type="checkbox" [checked]="documentationForm.get('docRfc')?.value === 1"
            (change)="updateCheckbox($event,'Rfc')" />
          <label for="checkboxRfc">Si</label>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="col-md-12">
          <label>Otros:</label>
        </div>
        <div class="col-md-12 offset-md-1 checkbox checkbox-css checkbox-inline">
          <input id="checkboxWhich" type="checkbox" [checked]="documentationForm.get('docWhich')?.value === 1"
            (change)="updateCheckbox($event,'Which')" />
          <label for="checkboxWhich">Si</label>
        </div>
      </div>

      <div class="col-12 col-md-4" *ngIf="documentationForm.get('docWhich')?.value">
        <label>¿Cuáles?</label>
        <input class="form-control" type="text" placeholder="¿Cuáles?" />
      </div>

      <div class="col-12 col-md-4">
        <div class="col-md-12">
          <label>Acuse:</label>
        </div>
        <div class="col-md-12 offset-md-1 checkbox checkbox-css checkbox-inline">
          <input id="checkboxAcuse" type="checkbox" [checked]="documentationForm.get('docAcuse')?.value === 1"
            (change)="updateCheckbox($event,'Acuse')" />
          <label for="checkboxAcuse">Si</label>
        </div>
      </div>

      <div class="row" *ngIf="documentationForm.get('docAcuse')?.value ">
        <div class="col-10 offset-1" *ngIf="AllUrlAcuse.length == 0 ">
          <div class="cont-image">
            <label class="block mb-2 text-sm text-gesco dark:text-white" for="acuse">Agregar
              acuse</label>
            <input class="block w-full text-sm text-gesco border-gesco rounded-lg cursor-pointer" type="file"
              id="acuse" accept=".pdf,.doc,.png,.jpg,.jpeg"  (change)="onFileAcuseChange($event)" />
            <p class="mt-1 text-sm text-gesco" id="file_input_help">(JPEG/PNG/PDF)</p>
          </div>
        </div>

        <div class="row" *ngIf="AllUrlAcuse.length > 0">
          <div class="col">
            <div class="row ml-3">
              <label class="col-md-12 p-t-2">
                Archivos cargados:
              </label>


              <ng-container *ngFor="let url of AllUrlAcuse; let i = index">
                <div class="drop-file" (click)="openImage(url)">
                  <a target="_blank" class="alert alert-drop-file show text-left cursor-pointer">
                    <span class="close text-danger" data-idx="0" data-dismiss="alert" (click)="removeFile(url,'Acuse')">×</span>
                    <i style="margin-top:-10px;" class="fa fa-file-image text-purple p-r-5 fa-3x pull-left"></i>
                    {{getFileName(url)}}
                  </a>
                </div>
              </ng-container>

            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-4">
        <div class="col-md-12">
          <label>Documentación adicional:</label>
        </div>
        <div class="col-md-12 offset-md-1 checkbox checkbox-css checkbox-inline">
          <input id="checkboxExtra" type="checkbox" [checked]="documentationForm.get('docExtra')?.value === 1"
            (change)="updateCheckbox($event,'Extra')" />
          <label for="checkboxExtra">Si</label>
        </div>
      </div>

      <div class="row" *ngIf="documentationForm.get('docExtra')?.value">
        <div class="col-10 offset-1">
          <div class="cont-image">
            <label class="block mb-2 text-sm text-gesco dark:text-white" for="extra">Agregar documentación adicional</label>
            <input class="block w-full text-sm text-gesco border-gesco rounded-lg cursor-pointer" type="file"
              id="extra" accept=".pdf,.doc,.png,.jpg,.jpeg" multiple (change)="onFileExtraChange($event)" />
            <p class="mt-1 text-sm text-gesco" id="file_input_help">(JPEG/PNG/PDF)</p>
          </div>
        </div>

        <div class="row" *ngIf="AllUrlExtra.length > 0">
          <div class="col">
            <div class="row ml-3">
              <label class="col-md-12 p-t-2">
                Archivos cargados:
              </label>


              <ng-container *ngFor="let url of AllUrlExtra; let i = index">
                <div class="drop-file" (click)="openImage(url)">
                  <a target="_blank" class="alert alert-drop-file show text-left cursor-pointer">
                    <span class="close text-danger" data-idx="0" data-dismiss="alert" (click)="removeFile(url,'Extra')">×</span>
                    <i style="margin-top:-10px;" class="fa fa-file-image text-purple p-r-5 fa-3x pull-left"></i>
                    {{getFileName(url)}}
                  </a>
                </div>
              </ng-container>

            </div>
          </div>
        </div>
      </div>


    </div>



    <div class="panel panel-info">
      <p class="divData">Ciudadano responsable</p>
    </div>
    <div class="row">
      <div class="col-12 col-md-4">
        <div class="col-md-12">
          <label>Predial:</label>
        </div>
        <div class="col-md-12 offset-md-1 checkbox checkbox-css checkbox-inline">
          <input id="checkboxPropertyTax" type="checkbox" [checked]="documentationForm.get('propertyTax')?.value === 1"
            (change)="updateCheckbox($event,'Property')" />
          <label for="checkboxPropertyTax">Si</label>
        </div>
      </div>
    </div>
    <div class="panel panel-info">
      <p class="divData"></p>
    </div>
    <div class="row">
      <div class="col-12 col-md-4">
        <label class="col-md-12">Bitácora:</label>
        <textarea id="description" class="form-control" formControlName="bitacora" placeholder=""></textarea>
      </div>
    </div>
  </form>
</div>
