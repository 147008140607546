<div class="contenedor">
    <div class="col-md-12 m-b-5" *ngFor="let visit of visits">
        <div class="comment row">
            <div class="col-md-9">
                <i class="fa fa-history"></i>
                <cite> <b> {{ visit.nameUser }} => {{visit.positionInstance}}</b></cite>
            </div>
            <div class="col-md-3 date">{{visit.date}}</div>
            <div class="col-md-12">
                <hr>
                <p>{{ visit.nameUserVisitor }} realizó la visita.</p>
                <p>{{ visit.note }}</p>
            </div>
        </div>
    </div>
    <div class="col-md-12 m-b-5" *ngFor="let request of requestH">
        <div class="comment row">
            <div class="col-md-9">
                <i class="fa fa-history"></i>
                <cite> <b> {{ request.nameUser }}</b></cite>:
            </div>
            <div class="col-md-3 date">{{request.date}}</div>
            <div class="col-md-12">
                <hr>
                <p>{{ request.message }}</p>
                <div class="col-md-12" *ngIf="request.channeled">
                    <hr>
                    <b>Se canalizó a:</b> {{request.channeled}} <br><br>
                    <b>Nombre de la institución: </b> {{request.channeledInst}}
                    <hr><br>
                </div>
                <p *ngIf="request.note"><b>Nota: </b>{{request.note}}</p>
                <ng-container *ngIf="request.type == 6 && data.url_image_accom != '' && data.url_image_accom != 'null'">
                    <label class="col-md-12 pt-2">Evidencia del cierre de la solicitud:</label>
                    <div class="row" style="text-align: center;">
                        <div class="col horizontal-align-containers" *ngFor="let url of urlAccomplishedWithType">
                            <ng-container *ngIf="url.type === 1; else fileTemplate">
                                <img src="{{ url.item }}" width="auto" height="auto" style="max-width: 60%;" />
                            </ng-container>
                            <ng-template #fileTemplate>
                                <!-- Aquí puedes agregar el código HTML para mostrar archivos -->
                                <a [href]="url.item" target="_blank">{{ getFileName(url.item) }}</a>
                            </ng-template>
                        </div>
                    </div>
                </ng-container>                
            </div>
        </div>
    </div>
</div>