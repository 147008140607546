<div class="panel">
  <div class="parent">
    <div class="div1">
      <h1>
        Top cinco de <br />
        temas solicitados
      </h1>

     <div *ngFor="let top of tops;">
      <div class="targets">
        <div
        [ngClass]="{
          'tittleTarget ':
            top.nameTheme==='Servicios públicos',
            'tittleTarget2 ':
            top.nameTheme==='Desarrollo social',
            'tittleTarget3 ':
            top.nameTheme==='Transporte',
            'tittleTarget4 ':
            top.nameTheme==='Educación',
            'tittleTarget5 ':
            top.nameTheme==='Vivienda',
            'tittleTarget6 ':
            top.nameTheme==='Salud',
            'tittleTarget7 ':
            top.nameTheme==='Seguridad'
        }"
        >
          <p>{{top.nameTheme}}</p>
        </div>
        <div class="informationTarget">
          <p class="dataBenf">
            <b style="font-size: 1.8em"
              >{{top.requests.openRequests}} abiertas <br />
              {{top.requests.closedRequests}} cerradas
            </b>
            <br>
          </p>
        </div>
      </div>
      <br />
    </div>


    </div>
    <div class="div2">
      <h2 class="page-header" style="padding-left: 15px">
        Estadísticas &nbsp;<small></small>
      </h2>
      <hr />
      <div class="parent2">
        <div class="div3">
          <div class="parent3">
            <div class="div5">
              <img
                src="assets\img\Entidades\{{dataEntitie?.Estado?.[0]?.identifier}}.png"
              />
            </div>
            <div class="div6">
              <br />

              <h4><b> Entidad: </b> {{dataEntitie?.Estado?.[0]?.name}}</h4>
              <br>
              <h4><b> Municipio: </b> {{dataEntitie?.Municipio?.[0]?.name}}</h4>
              <br>
              <h4><b> Cargo: </b> {{dataEntitie?.Rol?.[0]?.name}}</h4>

            </div>
          </div>
        </div>
        <div class="div4">
          <div class="parent5">
            <div class="div10">
              <div class="ancho">

                <canvas id="ChartStatusRequest">{{
                  chartStatusRequest
                }}</canvas>

            </div>
            </div>
            <div class="div6">
              <br />
              <h3>
                <b>
                  Total de <br />
                  solicitudes
                </b>
              </h3>

              <h5><b> Total: {{stats.Total}} </b></h5>
              <h5><b> Metas cumplidas: </b> {{stats.MetaCumplida}}</h5>
              <h5><b> Beneficiarios: </b> {{totalBeneficiares}}</h5>
            </div>
          </div>
        </div>
      </div>
      <h3>Grado de cumplimiento</h3>


      <div class="parent4">

        <div class="div7">
          <div class="targets2" style="grid-column: 1 / 4; grid-row: 1;">
            <div class="tittleTarget" style="background-color:#ffc000;">
                <p>Registro</p>
            </div>
            <div class="informationTarget">
                <p class="dataBenf"><b style="font-size: 1.8em;">{{stats.Registro}}</b></p>
            </div>
        </div>
          <br />
          <div class="targets2" style="grid-column: 1 / 4; grid-row: 2;">
            <div class="tittleTarget" style="background-color:#98d454;">
                <p>Asignado</p>
            </div>
            <div class="informationTarget">
                <p class="dataBenf"><b style="font-size: 1.8em;">{{stats.Asignado}}</b></p>
            </div>
        </div>
          <br />
          <div class="targets2" style="grid-column: 1 / 4; grid-row: 3;">
            <div class="tittleTarget" style="background-color:#04b4f8;">
                <p>En proceso</p>
            </div>
            <div class="informationTarget">
                <p class="dataBenf"><b style="font-size: 1.8em;">{{stats.EnProceso}}</b></p>
            </div>
        </div>

        </div>
        <div class="div8">


             <br>
              <div class="circulo">
              <div class="circulo2">

              <div class="circulo3">

              <h3>{{stats.Total}}</h3>
              <h5>Solicitudes</h5>
              </div>
            </div>

              </div>

        </div>
        <div class="div9">
          <div class="targets2" style="grid-column: 4 / 4; grid-row: 1;" (click)="goStatickGoals()">
            <div class="tittleTarget" style="background-color:#a81cf4;">
                <p>Meta cumplida</p>
            </div>
            <div class="informationTarget">
                <p class="dataBenf"><b style="font-size: 1.8em;">{{stats.MetaCumplida}}</b></p>
            </div>
        </div>
          <br />
          <div class="targets2" style="grid-column: 4 / 4; grid-row: 2;">
            <div class="tittleTarget" style="background-color: #ff5b57;">
                <p>Canceladas</p>
            </div>
            <div class="informationTarget">
                <p class="dataBenf"><b style="font-size: 1.8em;">{{stats.Cancelado}}</b></p>
            </div>
        </div>
          <br />
          <div class="targets2" style="grid-column: 4 / 4; grid-row: 3 ;">
            <div class="tittleTarget" style="background-color: #707478;">
                <p>No viable</p>
            </div>
            <div class="informationTarget">
                <p class="dataBenf"><b style="font-size: 1.8em;">{{stats.NoViable}}</b></p>
            </div>
        </div>

        </div>

        </div>


    </div>
  </div>

</div>

<!-- <div class="containerCumpliments">
        <div class="targets" style="grid-column: 1 / 4; grid-row: 1;">
            <div class="tittleTarget" style="background-color:#ffc000;">
                <p>Registro</p>
            </div>
            <div class="informationTarget">
                <p class="dataBenf"><b style="font-size: 1.8em;">85</b></p>
            </div>
        </div>

        <div class="targets" style="grid-column: 4 / 4; grid-row: 1;">
            <div class="tittleTarget" style="background-color:#a81cf4;">
                <p>Meta cumplida</p>
            </div>
            <div class="informationTarget">
                <p class="dataBenf"><b style="font-size: 1.8em;">65</b></p>
            </div>
        </div>

        <div class="targets" style="grid-column: 1 / 4; grid-row: 2;">
            <div class="tittleTarget" style="background-color:#98d454;">
                <p>Asignado</p>
            </div>
            <div class="informationTarget">
                <p class="dataBenf"><b style="font-size: 1.8em;">66</b></p>
            </div>
        </div>

        <div class="targets" style="grid-column: 4 / 4; grid-row: 2;">
            <div class="tittleTarget" style="background-color:#b0acac;">
                <p>Canceladas</p>
            </div>
            <div class="informationTarget">
                <p class="dataBenf"><b style="font-size: 1.8em;">5</b></p>
            </div>
        </div>

        <div class="targets" style="grid-column: 1 / 4; grid-row: 3;">
            <div class="tittleTarget" style="background-color:#04b4f8;">
                <p>En proceso</p>
            </div>
            <div class="informationTarget">
                <p class="dataBenf"><b style="font-size: 1.8em;">12</b></p>
            </div>
        </div>

        <div class="targets" style="grid-column: 4 / 4; grid-row: 3 ;">
            <div class="tittleTarget" style="background-color:#e03484;">
                <p>No viable</p>
            </div>
            <div class="informationTarget">
                <p class="dataBenf"><b style="font-size: 1.8em;">7</b></p>
            </div>
        </div>
    </div>

    <div class="containerThemes">
        <div class="targets">
            <div class="tittleTarget" style="background-color:#ffc000;">
                <p>Registro</p>
            </div>
            <div class="informationTarget">
                <p class="dataBenf"><b style="font-size: 1.8em;">85</b></p>
            </div>
        </div>

        <div class="targets">
            <div class="tittleTarget" style="background-color:#a81cf4;">
                <p>Meta cumplida</p>
            </div>
            <div class="informationTarget">
                <p class="dataBenf"><b style="font-size: 1.8em;">65</b></p>
            </div>
        </div>

        <div class="targets">
            <div class="tittleTarget" style="background-color:#98d454;">
                <p>Asignado</p>
            </div>
            <div class="informationTarget">
                <p class="dataBenf"><b style="font-size: 1.8em;">66</b></p>
            </div>
        </div>
    </div>
</div> -->`
