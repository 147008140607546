import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { PromotedService } from 'src/app/shared/services/promoted.service';
import { UserService } from 'src/app/shared/services/user.service';
import { environment } from 'src/environments/environment';

declare var $: any;
@Component({
  selector: 'app-promoted-list',
  templateUrl: './promoted-list.component.html',
  styleUrls: ['./promoted-list.component.scss']
})
export class PromotedListComponent implements OnInit {

  p: number = 1;
  promoted: any = [];
  itemsPerPage: number = 10;
  totalRecords: number = 0;
  idWorkGroup: any;
  idUser: any;
  token: any;
  search: string = "";
  private searchTerms = new Subject<string>();
  results: any = [];
  loading: boolean = false;
  currentArray: any;
  environment: any;
  userLevel: any;
  identifier: any;
  enabledReport: any;
  parentURL: any;
  url: any;

  visitForm: FormGroup = this.FB.group({
    date: ['', [Validators.required, this.dateValidator]],
    id_user_visitor: [0],
    id_promoted: [''],
    note: ['', [Validators.required]],
    id_user: [''],
    visit: 0
  });
  usersOptions: any = [];

  constructor(private promotedService: PromotedService,
    private activeRoute: ActivatedRoute,
    private userService: UserService,
    private router: Router,
    private FB: FormBuilder
    ) { }

  ngOnInit(): void {
    this.getUserAuth();
    this.searchhold();
  }

  openModal(idPromoted: number) {
    $('#visitModal').modal('show');
    this.getWorkGroupTheme();
    this.visitForm.controls['id_promoted'].setValue(idPromoted);
  }

  closeModal() {
    $('#visitModal').modal('hide');
  }

  onSearchChange(newValue: string) {
    this.search = newValue.toUpperCase();
  }

  saveVisit(){
    this.visitForm.controls['id_user'].setValue(this.activeRoute.snapshot.paramMap.get('idUser'));
    if (this.visitForm.get('id_user_visitor')?.value == 0)
      this.visitForm.controls['id_user_visitor'].setValue(this.activeRoute.snapshot.paramMap.get('idUser'));

    this.promotedService.saveVisit(this.visitForm.value).subscribe((data: any) => {
      console.log(data);
      window.location.reload();
    });
  }

  dateValidator(control: any) {
    const date = new Date(control.value);
    const actualDate = new Date();

    if (date < actualDate) {
      return null; // La fecha es válida
    } else {
      return { invalidDate: true }; // La fecha es inválida
    }
  }

  getWorkGroupTheme() {
    this.userService.getWorkGroupTheme(this.idUser, this.idWorkGroup, 0).subscribe((data) => {
      this.usersOptions = data;
    });
  }

  getUserAuth() {
    this.activeRoute.params.subscribe(routeParams => {
      localStorage.setItem('token', routeParams['token']);
      this.idWorkGroup = routeParams['idWorkGroup'];
      this.idUser = routeParams['idUser'];
      this.url = environment.apiUrl;
      this.userService.getUserInfo(this.idUser).subscribe(data => {
        this.identifier = data[0].identifier;
        this.enabledReport = data[0].enableReports;
        this.userLevel = data[0].userLevel;
        this.getData();
      });
      this.token = routeParams['token'];
      localStorage.setItem('environment', routeParams['environment']);
      localStorage.setItem('idWorkGroup', routeParams['idWorkGroup']);
      localStorage.setItem('idUser', routeParams['idUser']);

    });
  }

  getData() {
    this.promotedService.getPromoters(this.p, this.itemsPerPage, this.idWorkGroup, this.userLevel, this.idUser).subscribe((response: any) => {
      this.promoted = response.data;
      this.totalRecords = response.total;
      this.currentArray = response.data;
    });
  }

  goToDetail(idPromoted: number) {
    this.idUser = localStorage.getItem('idUser');
    this.token = localStorage.getItem('token');
    this.idWorkGroup = localStorage.getItem('idWorkGroup');
    this.environment = localStorage.getItem('environment');
    this.router.navigate(['/promotedDetail/' + idPromoted + '/' + this.idWorkGroup + '/' + this.token + '/' + this.environment])
  }

  getTooltip(missingDataColor: string): string {
    switch (missingDataColor) {
      case 'red':
        return 'Sin CURP y datos faltantes';
      case 'yellow':
        return 'CURP registrada con datos faltantes';
      case 'green':
        return 'Datos completos';
      default:
        return '';
    }
  }

  getBackgroundColor(missingDataColor: string): string {
    switch (missingDataColor) {
      case 'red':
        return '#FF0000';
      case 'yellow':
        return '#f59c1a';
      case 'green':
        return '#7CFC00';
      default:
        return '';
    }
  }

  get totalPages(): number {
    return Math.ceil(this.totalRecords / this.itemsPerPage);
  }

  onPageChange(newPageIndex: number): void {
    this.p = newPageIndex;
    this.getData();
  }

  getSearch(event: any) {
    const searchTerm = this.search;
    this.searchTerms.next(searchTerm);

    if (searchTerm.trim() === '') {
      this.clearSearch();
    }
  }

  clearSearch() {
    this.search = '';
    this.results = [];
  }

  searchhold() {
    this.searchTerms.pipe(
      debounceTime(2000), // Reducido a 300 ms para una respuesta más rápida
      distinctUntilChanged(),
      switchMap((term: string) => {
        if (term.trim() === '') {
          this.currentArray = this.promoted;
          this.loading = false;
          return [];
        } else if (term.length >= 3) {
          this.loading = true;
          return this.promotedService.getSearchPromoted(term, this.p, this.itemsPerPage,this.userLevel, this.idUser);
        } else {
          this.currentArray = this.promoted;
          this.loading = false;
          return [];
        }
      })
    ).subscribe((response:any) => {
      this.results = response.data;
      this.currentArray = response.data;
      this.loading = false;
    });
  }

  goToUpdate(id: number) {
    this.router.navigate(['/editPromoted/' + id]);
  }

}
