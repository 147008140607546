import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pOI'
})
export class POIPipe implements PipeTransform {

 
  transform(items: any[], searchTerm: string): any[] {
    if (!items || !searchTerm) {
      return items;
    }

    return items.filter(item => item.SECCION.toLowerCase().includes(searchTerm));
  }

}
