import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequestFormComponent } from './request-form/request-form.component';
import { RequestGeneralDataComponent } from './requestForm/request-general-data/request-general-data.component';
import { RequestClasificationComponent } from './requestForm/request-clasification/request-clasification.component';
import { RequestCompleteComponent } from './requestForm/request-complete/request-complete.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatStepperModule } from '@angular/material/stepper';
import { RequestEditGeneralDataComponent } from './editRequestForm/request-edit-general-data/request-edit-general-data.component';
import { RequestEditClasificationComponent } from './editRequestForm/request-edit-clasification/request-edit-clasification.component';
import { RequestEditDocumentationComponent } from './editRequestForm/request-edit-documentation/request-edit-documentation.component';
import { RequestEditCompleteComponent } from './editRequestForm/request-edit-complete/request-edit-complete.component';
import { EditRequestFormComponent } from './edit-request-form/edit-request-form.component';
import { FormMapModule } from '../components/map/form-map/form-map.module';
import { SpMapModule } from './components/sp-map/sp-map.module';
import { MaxchardecModule } from '../components/form/maxchardec/maxchardec.module';
import { DetailRequestComponent } from './detail-request/detail-request.component';
import { DetailRequestGeneralDataComponent } from './detailRequest/detail-request-general-data/detail-request-general-data.component';
import { DetailRequestClasificationComponent } from './detailRequest/detail-request-clasification/detail-request-clasification.component';
import { DetailRequestDocumentationComponent } from './detailRequest/detail-request-documentation/detail-request-documentation.component';
import { DetailRequestHistoryComponent } from './detailRequest/detail-request-history/detail-request-history.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { RequestsPipe } from 'src/app/shared/pipes/requests.pipe';


@NgModule({
  declarations: [
    RequestFormComponent,
    RequestGeneralDataComponent,
    RequestClasificationComponent,
    RequestCompleteComponent,
    RequestEditGeneralDataComponent,
    RequestEditClasificationComponent,
    RequestEditDocumentationComponent,
    RequestEditCompleteComponent,
    EditRequestFormComponent,
    DetailRequestComponent,
    DetailRequestGeneralDataComponent,
    DetailRequestClasificationComponent,
    DetailRequestDocumentationComponent,
    DetailRequestHistoryComponent,
    RequestsPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatStepperModule,
    FormMapModule,
    SpMapModule,
    MaxchardecModule,
    NgbDropdownModule,
  ]
})
export class RequestsModule { }
