import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CustomeValidationService } from 'src/app/shared/services/custome-validation.service';
import { FormDataService } from 'src/app/shared/services/form-data.service';
import { RequestService } from 'src/app/shared/services/request.service';
import { UserService } from 'src/app/shared/services/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-request-complete',
  templateUrl: './request-complete.component.html',
  styleUrls: ['./request-complete.component.scss'],
})
export class RequestCompleteComponent implements OnInit {
  formApplicant: FormGroup = this.fb.group({});
  formClasification: FormGroup = this.fb.group({});

  completeForm: FormGroup = this.fb.group({
    idUser: [''],
    idAssigned: [''],
    idInstance: [''],
    idstatus: [''],
    qrcode: [''],
    missing_data_color: [''],
    idForm: [0],
  });

  beneficiaryForm: FormGroup = this.fb.group({
    name: [''],
    next_name: [''],
    last_name: [''],
    curp: [''],
    birthDate: [''],
    gender: [''],
    birthPlace: [''],
    birthPlaceM: [''],
    cellPhone: [''],
    relationshipBeneficiary: [''],
    idWorkGroup: [''],
    origen: [1],
    location: [0],
  });

  attendRequest: boolean = false;
  workGroup: number = 0;
  usersOptions: any[] = [];
  idWorkGroup: string = '';
  idUser: number = 0;
  theme_id: number = 0;
  formGroupValid = false;
  idProposal: any;
  idPromoter: any;
  loading: boolean = false;
  themeService: boolean = false;
  currentThemeId: number = 0;
  idApplicant: number = 0;
  idBeneficiary: number = 0;
  idForm: number = 0;

  allFiles: any;

  constructor(
    private fb: FormBuilder,
    private formDataService: FormDataService,
    private userService: UserService,
    private activeRoute: ActivatedRoute,
    private requestService: RequestService,
    private customizeService: CustomeValidationService
  ) {}

  ngOnInit(): void {
    this.getParams();
    this.getFormGroupApplicant();
    this.getFormGroupClasification();
  }

  getParams() {
    this.idWorkGroup = localStorage.getItem('idWorkGroup') ?? '';
    this.idUser = parseInt(localStorage.getItem('idUser') ?? '');
    this.completeForm.controls['qrcode'].setValue(this.generateRandomString());
  }

  getFormGroupApplicant() {
    this.formDataService.requestGeneralFormData$.subscribe((form) => {
      if (form) {
        this.formApplicant = form;
      }
    });
  }
  generateRandomString(): string {
    const length = 5;
    const characters =
      '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const charactersLength = characters.length;
    let randomString = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charactersLength);
      randomString += characters.charAt(randomIndex);
    }
    return randomString;
  }

  disableButton() {
    const formValuesApplicant = this.formApplicant.value;
    const numberOfFields = Object.keys(formValuesApplicant).length;
    let validApplicant = false;
    if (numberOfFields != 0) {
      validApplicant = true;
    }
    const formValuesClas = this.formClasification.value;
    const numberOfFieldsClas = Object.keys(formValuesClas).length;
    let validClas = false;
    if (numberOfFieldsClas != 0) {
      validClas = true;
    }
    this.formGroupValid = validApplicant == true && validClas == true;
  }

  getFormGroupClasification() {
    this.formDataService.requestClasificationFormData$.subscribe((form) => {
      if (form) {
        this.formClasification = form;
        if (this.theme_id == 0) {
          this.usersOptions = [];
          this.getWorkGroupTheme();
        }
        if (
          this.themeService == false &&
          form.get('theme_id')?.value != undefined
        ) {
          this.currentThemeId = form.get('theme_id')?.value;
          this.theme_id = form.get('theme_id')?.value;
          this.usersOptions = [];
          this.getWorkGroupTheme();
          this.themeService = true;
        } else {
          this.currentThemeId == form.get('theme_id')?.value
            ? null
            : (this.theme_id = form.get('theme_id')?.value);
          if (this.currentThemeId != this.theme_id) {
            this.currentThemeId = this.theme_id;
            this.usersOptions = [];
            this.getWorkGroupTheme();
          }
        }
        this.disableButton();
      }
    });
  }

  getWorkGroupTheme() {
    if (this.theme_id != undefined) {
      this.userService
        .getWorkGroupTheme(this.idUser, this.idWorkGroup, this.theme_id)
        .subscribe((data) => {
          this.usersOptions = data;
        });
    }
  }

  handleChange() {
    this.completeForm.controls['idUser'].setValue(this.idUser);
    if (this.attendRequest) {
      this.completeForm.controls['idAssigned'].setValue(this.idUser);
      this.completeForm.controls['idstatus'].setValue(2);
    } else if (this.workGroup) {
      this.completeForm.controls['idAssigned'].setValue(this.workGroup);
      this.completeForm.controls['idstatus'].setValue(2);
      const user = this.usersOptions.find(
        (option) => option.idUser == this.completeForm.get('idAssigned')?.value
      );
      this.completeForm.controls['idInstance'].setValue(user.idInstance);
    } else {
      this.completeForm.controls['idstatus'].setValue(1);
      this.completeForm.controls['idAssigned'].setValue(0);
    }
  }

  // Función para verificar si un campo está vacío o nulo
  isNullOrEmpty(control: AbstractControl | null): boolean {
    return control === null || control.value === '' || control.value === null;
  }

  updateMissingDataColor(form: FormGroup) {
    const curp = form.get('curp');
    const requiredFields = [
      'name',
      'next_name',
      'last_name',
      'birthDate',
      'cellPhone',
      'birthPlace',
      'birthPlaceM',
      'idPostalCode',
      'gender',
      'email',
      'civilStatus',
      'scholarship',
      'ocupation',
      'street',
      'streetNumExt',
      'idNeighborhood',
      'electoralSection',
    ];
    // Comprobar si todos los campos requeridos están llenos
    const allFieldsFilled = requiredFields.every(
      (fieldName) => !this.isNullOrEmpty(form.get(fieldName))
    );

    let federalSupportCondition = true;
    if (
      form.get('federalSupport')?.value == 1 &&
      !this.isNullOrEmpty(form.get('typeSupport'))
    ) {
      federalSupportCondition = true;
    } else if (
      form.get('federalSupport')?.value == 0 &&
      this.isNullOrEmpty(form.get('typeSupport'))
    ) {
      federalSupportCondition = true;
    } else {
      federalSupportCondition = false;
    }
    if (allFieldsFilled && federalSupportCondition) {
      this.completeForm.controls['missing_data_color'].setValue('green');
    } else if (!this.isNullOrEmpty(curp)) {
      this.completeForm.controls['missing_data_color'].setValue('yellow');
    } else {
      this.completeForm.controls['missing_data_color'].setValue('red');
    }
  }

  loaderAlert() {
    Swal.fire({
      icon: 'warning',
      title: 'Subiendo archivos',
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton());
      },
      willClose: () => {},
    }).then((result) => {});
  }

  succesAlert() {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: 'success',
      title: 'Archivos subidos',
    });
  }

  errorAlert() {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: 'error',
      title: 'Erro al subir archivos',
    });
  }

  saveFormService(postForm: any) {
    return new Promise((resolve, reject) => {
      this.requestService.saveForm(postForm).subscribe((data) => {
        this.idForm = data.id;
        this.completeForm.controls['idForm'].setValue(data.id);
        resolve(data);
      });
    });
  }

  async buttonSave() {
    this.loading = true;
    this.formApplicant.controls['solicitingBeneficiary'].setValue(
      this.formClasification.get('solicitingBeneficiary')?.value
    );
    this.updateMissingDataColor(this.formApplicant);
    this.handleChange();

    const time = 5000;
    let timerInterval: any;

    const swalLoading = Swal.fire({
      title: 'Cargando...',
      timer: time,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton());
        timerInterval = setInterval(() => {}, 100);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    });

    try {
      const applicantData = this.formApplicant.value;
      var applicantResponse;
      if (applicantData.idApplicant) {
        applicantResponse = await this.requestService
          .updateApplicant(applicantData.idApplicant, applicantData)
          .toPromise();
      } else {
        applicantResponse = await this.requestService
          .saveApplicant(applicantData)
          .toPromise();
      }

      this.idApplicant = applicantResponse.idApplicant;
      this.idBeneficiary = applicantResponse.idApplicant;

      await this.saveForm();
      await this.saveRequest(this.idApplicant);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      Swal.close();
      this.loading = false;
    }
  }

  async saveForm() {
    const postForm: any = {
      vulnerable: this.formClasification.get('vulnerable')?.value,
      espVulnerable: this.formClasification.get('espVulnerable')?.value,
      institution: this.formClasification.get('institution')?.value,
      enrollment: this.formClasification.get('enrollment')?.value,
      information: this.formClasification.get('information')?.value,
      sport: this.formClasification.get('sport')?.value,
      level: this.formClasification.get('level')?.value,
      health: this.formClasification.get('health')?.value,
      social: this.formClasification.get('social')?.value,
      educationLevel: this.formClasification.get('educationLevel')?.value,
      typeDiscipline: this.formClasification.get('typeDiscipline')?.value,
      disciplineClass: this.formClasification.get('disciplineClass')?.value,
      typeartCult: this.formClasification.get('typeartCult')?.value,
      disciplineNivel: this.formClasification.get('disciplineNivel')?.value,
      purposeScholarship:
        this.formClasification.get('purposeScholarship')?.value,
      typeScholarship: this.formClasification.get('typeScholarship')?.value,
      population: 0,
      federalSupportForm:
        this.formClasification.get('federalSupportForm')?.value,
      typeSupportForm: this.formClasification.get('typeSupportForm')?.value,
      categorie: this.formClasification.get('categorie')?.value,
    };
    const formResponse = await this.saveFormService(postForm);
  }

  async saveRequest(idApplicant: number) {
    const allFiles: any = this.formDataService.allUploadablesRequest;
    const promises = [];

    if ((this.formClasification.get('theme_id')?.value == 4 || this.formClasification.get('theme_id')?.value == 15) && allFiles) {
      const requestFiles = allFiles.filter((file: any) => {
        const fileType = file.file.type;
        return fileType === 'application/pdf' || fileType.startsWith('image/');
      });
      console.log(requestFiles);

      if (requestFiles)
        promises.push(this.stageToUpload(requestFiles, this.idApplicant));
    }
    this.loaderAlert();

    Promise.all(promises)
      .then(async () => {
        Swal.close();
        this.succesAlert();

        let idRequest = 0;

        if (this.formClasification.get('urgent')?.value == 1)
          this.formClasification.get('theme_id')?.value == 2 ||
          this.formClasification.get('theme_id')?.value == 12
            ? this.formClasification.controls['ordenUrgent'].setValue(2)
            : this.formClasification.controls['ordenUrgent'].setValue(1);

        if (this.formClasification.get('solicitingBeneficiary')?.value == 1) {
          var beneficiaryData = {
            name: this.formClasification.get('nameB')?.value,
            next_name: this.formClasification.get('next_nameB')?.value,
            last_name: this.formClasification.get('last_nameB')?.value,
            curp: this.formClasification.get('curpB')?.value,
            birthDate: this.formClasification.get('birthDateB')?.value,
            gender: this.formClasification.get('genderB')?.value,
            birthPlace: this.formClasification.get('birthPlaceB')?.value,
            birthPlaceM: this.formClasification.get('birthPlaceMB')?.value,
            cellPhone: this.formClasification.get('cellPhoneB')?.value,
            relationshipBeneficiary: this.formClasification.get(
              'relationshipBeneficiary'
            )?.value,
            idWorkGroup: this.idWorkGroup,
            origen: 1,
            location: 0,
          };
          this.beneficiaryForm.setValue(beneficiaryData);

          const beneficiaryResponse = await this.requestService
            .saveApplicant(this.beneficiaryForm.value)
            .toPromise();
          this.idBeneficiary = beneficiaryResponse.idApplicant;
        }

        if (this.idApplicant != this.idBeneficiary) {
          var dataRequest = {
            idApplicant: this.idApplicant,
            idBeneficiary: this.idBeneficiary,
            ...this.formClasification.value,
            idlifting: this.formApplicant.get('idlifting')?.value,
            typeManagment: this.formApplicant.get('typeManagment')?.value,
            groupBe: this.formApplicant.get('groupBe')?.value,
            curp: this.formApplicant.get('curp')?.value,
            idWorkGroup: this.idWorkGroup,
            ...this.completeForm.value,
            url_image_sp: this.allFiles,
            period: this.formApplicant.get('period')?.value,
          };
          console.log(dataRequest);

          const requestResponse = await this.requestService
            .saveRequest(dataRequest)
            .toPromise();
          idRequest = requestResponse.idrequests;
        } else {
          var dataRequest = {
            idApplicant: idApplicant,
            idBeneficiary: idApplicant,
            ...this.formClasification.value,
            idlifting: this.formApplicant.get('idlifting')?.value,
            typeManagment: this.formApplicant.get('typeManagment')?.value,
            groupBe: this.formApplicant.get('groupBe')?.value,
            curp: this.formApplicant.get('curp')?.value,
            idWorkGroup: this.idWorkGroup,
            ...this.completeForm.value,
            url_image_sp: this.allFiles,
            period: this.formApplicant.get('period')?.value,
          };
          const requestResponse = await this.requestService
            .saveRequest(dataRequest)
            .toPromise();
          idRequest = requestResponse.idrequests;
        }

        this.saveHistory(idRequest);
        this.sendWhatsappNotification(idRequest);
        this.sendEmailNotification(idRequest);
      })
      .catch((error) => {
        // Manejar errores si es necesario
        console.error('Ocurrió un error en una de las operaciones:', error);

        Swal.close();
        this.errorAlert();
      });
  }

  stageToUpload(files: any, id: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      console.log(files);
      this.uploadImage(files, id)
        .then((data) => {
          console.log(data);
          this.allFiles = data;
          // Avísanos que la operación se ha completado
          resolve();
        })
        .catch((error) => {
          // Maneja errores si es necesario
          reject(error);
        });
    });
  }

  uploadImage(selectedFiles: any[], id: number) {
    return new Promise((resolve, reject) => {
      const uploadPromises = [];
      const urls: any = [];
      console.log(selectedFiles);

      for (const file of selectedFiles) {
        console.log(file);

        const uploadPromise = new Promise((innerResolve, innerReject) => {
          this.requestService
            .uploadImage(file.file, 'requests', 'files', id)
            .subscribe(
              (data: any) => {
                console.log(data);
                urls.push(data.path);
                innerResolve(data);
              },
              (error) => {
                innerReject(error);
              }
            );
        });

        uploadPromises.push(uploadPromise);
      }

      Promise.all(uploadPromises)
        .then(() => {
          // Todas las imágenes se han cargado con éxito
          resolve(urls);
        })
        .catch((error) => {
          // Al menos una carga de imagen falló
          reject(error);
        });
    });
  }

  async saveHistory(idRequest: number) {
    let complement = ' ';
    if (this.completeForm.get('idAssigned')?.value != 0) {
      if (
        this.completeForm.get('idAssigned')?.value ==
        this.completeForm.get('idUser')?.value
      ) {
        complement = ' y ha decidido atenderla ';
      } else if (
        this.completeForm.get('idAssigned')?.value !=
        this.completeForm.get('idUser')?.value
      ) {
        const user = this.usersOptions.find(
          (option) => option.idUser == this.workGroup
        );
        complement = ' y la ha asignado a ' + user.userName;
      }
    }
    const message = 'Ha dado de alta la solicitud' + complement + 'desde Web';
    const historyData = {
      idRequest: idRequest,
      idUser: this.idUser,
      message: message,
      note: null,
      type: this.completeForm.get('idstatus')?.value,
      date: null,
    };
    try {
      const historyResponse = await this.requestService
        .saveRequestHistory(historyData)
        .toPromise();
      alert('Tu registro ha sido guardado con éxito');
      const parentURL = this.customizeService.getUrl();
      window.parent.location.href =
        parentURL + '/RequestsRequest/index/' + idRequest + '/idx.html';
    } catch (error) {
      // Handle errors here
      console.error('Error:', error);
    }
  }

  sendWhatsappNotification(idRequest: number) {
    this.requestService
      .sendWhatsappNotification(idRequest, this.idUser, this.idWorkGroup)
      .subscribe(
        (data) => {
          console.log('Envío de notificación exitoso', data);
        },
        (error) => {
          console.error('Error al enviar la notificación de WhatsApp:', error);
        }
      );
  }

  sendEmailNotification(idRequest: number) {
    this.requestService
      .sendEmailNotification(idRequest, this.idUser, this.idWorkGroup)
      .subscribe(
        (data) => {
          console.log('Envío de notificación exitoso');
        },
        (error) => {
          console.error('Error al enviar la notificación de Correo:', error);
        }
      );
  }
}
