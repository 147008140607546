import { Component, OnInit } from '@angular/core';
import { FormDataService } from 'src/app/shared/services/form-data.service';
import { RequestService } from 'src/app/shared/services/request.service';

@Component({
  selector: 'app-detail-request-general-data',
  templateUrl: './detail-request-general-data.component.html',
  styleUrls: ['./detail-request-general-data.component.scss']
})
export class DetailRequestGeneralDataComponent implements OnInit {

  request: any = [];
  beneficiary: any = [];

  constructor(private formDataService: FormDataService, private requestService: RequestService) { }

  ngOnInit(): void {
    this.getRequestData();
  }

  getRequestData(){
    this.formDataService.requestDataResults$.subscribe(data =>{
      this.request = data;
      if(this.request.idApplicant != this.request.idBeneficiary){
        this.requestService.getApplicantData(this.request.idBeneficiary).subscribe(data =>{
          this.beneficiary = data;
        });
      }
    });
  }
}
