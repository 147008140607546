<div class="row m-2">
  <div class="col">
      <div class="row" style="display: flex;align-items: center; padding-top: 1rem;">
          <div class="col-md-6">
              <div class="form-group m-b-5">
                  <h1 class="page-header" style="padding-left: 15px; color: black;">Agregar Promovido
                      &nbsp;<small></small></h1>
              </div>
          </div>
          <div class="col-md-6">
              <div class="d-flex flex-column justify-content-end align-items-end h-100">
                  <div class="m-b-5">
                      <button type="button" class="btn btn-secondary" style="padding-top: 10px; padding-bottom: 10px;"
                          (click)="cleanForm()">
                          Vaciar formulario
                      </button>
                  </div>
              </div>
          </div>
      </div>
      <hr style="margin-bottom: 1.5rem;">
      <div class="row tHeader">
          <div class="col" (click)="changeSelected(0)" [ngClass]="{'active': selectedTab == 0}">
              <div class="sectionForm">
                  <span class="numberNotSelected" *ngIf="selectedTab != 0 else one">
                      <div class="fa-solid fa-check"></div>
                  </span>
                  <ng-template #one>
                      <span class="number">
                          1
                      </span>
                  </ng-template>
                  <div>
                      <span class="info">Datos del Promovido </span>
                      <i class="fa fa-arrow-right"></i>
                      <small>Nombre, Dirección, Teléfono...</small>
                  </div>
              </div>
          </div>
          <div class="col" (click)="changeSelected(1)" [ngClass]="{'active': selectedTab == 1}">
              <div class="sectionForm">
                  <span class="numberNotSelected" *ngIf="selectedTab != 1 else second">
                      <div class="fa-solid fa-check"></div>
                  </span>
                  <ng-template #second>
                      <span class="number">
                          2
                      </span>
                  </ng-template>
                  <div>
                      <span class="info">Encuestas </span>
                      <i class="fa fa-arrow-right"></i>
                      <small> Encuesta, Clasificación, Evidencia…
                      </small>
                  </div>
              </div>
          </div>
          <div class="col" (click)="changeSelected(2)" [ngClass]="{'active': selectedTab == 2}">
              <div class="sectionForm">
                  <span class="numberNotSelected" *ngIf="selectedTab != 2 else third">
                      <div class="fa-solid fa-check"></div>
                  </span>
                  <ng-template #third>
                      <span class="number">
                          3
                      </span>
                  </ng-template>
                  <div>
                      <span class="info">Gestión </span>
                      <i class="fa fa-arrow-right"></i>
                      <small>Problemáticas de la calle y/o colonia…</small>
                  </div>
              </div>
          </div>
      </div>
      <div class="row">
          <div class="col p-0">
              <mat-stepper (selectionChange)="onStepChange($event)" #stepper>
                  <mat-step>
                      <div class="row">
                          <div class="col">
                              <form [formGroup]="promotedForm">
                                  <div class="contenedor p-2">
                                      <div class="row" style="display: flex;">
                                          <div class="col-12 col-md-4">
                                              <label class="align-middle col-md-12 p-t-2">
                                                  <span class="reqForm">*</span>
                                                  <a (click)="openModal()" class="privacy">Aviso de privacidad
                                                      <cite>click aquí</cite></a>
                                              </label>
                                              <div class="col-md-12">
                                                  <div class="checkbox checkbox-css">
                                                      <input id="privacyNotice" type="checkbox"
                                                          formControlName="privacy_notice"
                                                          [checked]="promotedForm.get('privacy_notice')?.value === 1"
                                                          (change)="updateCheckbox($event)">
                                                      <label for="privacyNotice">Aceptar</label>
                                                  </div>
                                              </div>
                                          </div>
                                          <div class="col-12 col-md-4">
                                              <label>Periodo:</label>
                                              <select class="form-control" formControlName="period">
                                                  <option selected value="">Selecciona una opción</option>
                                                  <option *ngFor="let period of periodOptions" value="{{period.value}}">{{period.name}}</option>
                                              </select>
                                          </div>
                                          <div class="col-12 col-md-4">
                                              <label><span style="color: red">* </span>Lugar de levantamiento:</label>
                                              <select class="form-control" formControlName="id_lifting">
                                                  <option selected value="">Selecciona una opción</option>
                                                  <option value="Campaña">Campaña</option>
                                                  <option value="Oficina">Oficina</option>
                                                  <option value="Página de internet">Página de internet</option>
                                                  <option value="Llamada telefónica">Llamada telefónica</option>
                                                  <option value="Correo electrónico">Correo electrónico</option>
                                                  <option value="Facebook">Facebook</option>
                                                  <option value="WhatsApp">WhatsApp</option>
                                                  <option value="Campo">Campo</option>
                                                  <option value="SolicitApp">SolicitApp</option>
                                                  <option value="Chatbot">Chatbot</option>
                                                  <option value="Micrositio">Micrositio</option>
                                              </select>
                                          </div>
                                      </div>
                                      <div class="panel panel-info" style="padding-bottom: 0;margin-bottom: 0;">
                                          <p class="divData">Datos del promovido</p>
                                      </div>
                                      <div class="row">
                                          <div class="col-12 col-md-4">
                                              <span style="color: red">* </span>
                                              <label>Nombre(s):</label>
                                              <input class="form-control" type="text"
                                                  placeholder="Ingrese el nombre del promovido"
                                                  formControlName="name">
                                          </div>
                                          <div class="col-12 col-md-4">
                                              <span style="color: red">* </span>
                                              <label>Apellido paterno:</label>
                                              <input class="form-control" type="text"
                                                  placeholder="Ingrese el apellido paterno"
                                                  formControlName="next_name">
                                          </div>
                                          <div class="col-12 col-md-4">
                                              <span style="color: red">* </span>
                                              <label>Apellido materno:</label>
                                              <input class="form-control" type="text"
                                                  placeholder="Ingrese el apellido materno"
                                                  formControlName="last_name">
                                          </div>
                                          <div class="col-12 col-md-4">
                                              <label>Fecha de nacimiento:</label>
                                              <input class="form-control" type="date" placeholder="dd/mm/aaaa"
                                                  formControlName="birth_date" />
                                          </div>
                                          <div class="col-12 col-md-4">
                                              <label>Sexo:</label>
                                              <select class="form-control" formControlName="gender">
                                                  <option selected value="">Selecciona una opción</option>
                                                  <option value="Femenino">Femenino</option>
                                                  <option value="Masculino">Masculino</option>
                                                  <option value="Otro">Otro</option>
                                              </select>
                                          </div>
                                          <div class="col-12 col-md-4">
                                              <span style="color: red">* </span>
                                              <label>Edad:</label>
                                              <input class="form-control" type="number" placeholder="Ingrese la edad"
                                                  formControlName="age">
                                          </div>
                                          <div class="col-12 col-md-4">
                                              <label>CURP:</label>
                                              <input class="form-control" type="text" placeholder="Ingrese el CURP"
                                                  maxlength="18" formControlName="curp" />
                                              <div class="row m-b-10 text-right p-r-20"
                                                  style="display: block; color: #1d6cb6;">
                                                  <a href="https://consultas.curp.gob.mx/CurpSP/gobmx/inicio.jsp"
                                                      target="_blank">Consultar CURP</a>
                                              </div>
                                          </div>
                                          <div class="col-12 col-md-4">
                                              <label>Correo electrónico:</label>
                                              <input class="form-control" type="email"
                                                  placeholder="Ingrese un correo electrónico"
                                                  formControlName="email" />
                                          </div>
                                          <div class="col-12 col-md-4">
                                              <label>Teléfono de casa:</label>
                                              <input class="form-control" type="text"
                                                  placeholder="Ingrese un teléfono local" minlength="10"
                                                  maxlength="10" formControlName="phone" />
                                          </div>
                                          <div class="col-12 col-md-4">
                                              <span style="color: red">* </span>
                                              <label>Teléfono celular:</label>
                                              <input class="form-control" type="text"
                                                  placeholder="Ingrese un teléfono celular" minlength="10"
                                                  maxlength="10" formControlName="cell_phone" />
                                          </div>
                                          <div class="col-12 col-md-4">
                                              <label>Estado civil:</label>
                                              <select class="form-control" formControlName="civil_status">
                                                  <option selected value="">Selecciona una opción</option>
                                                  <option value="Casado/a">Casado/a</option>
                                                  <option value="Divorciado/a">Divorciado/a</option>
                                                  <option value="Separado/a">Separado/a</option>
                                                  <option value="Soltero/a">Soltero/a</option>
                                                  <option value="Unión libre">Unión libre</option>
                                                  <option value="Viudo/a">Viudo/a</option>
                                              </select>
                                          </div>
                                          <div class="col-12 col-md-4">
                                              <label>Lugar de nacimiento (Estado):</label>
                                              <select class="form-control" formControlName="birth_place"
                                                  (change)="onFirstSelectChange()">
                                                  <option selected value="">Selecciona una opción</option>
                                                  <option *ngFor="let entitie of entities" value="{{entitie.id}}">
                                                      {{entitie.name}}</option>
                                              </select>
                                          </div>
                                          <div class="col-12 col-md-4">
                                              <label>Lugar de nacimiento (Municipio):</label>
                                              <select class="form-control" formControlName="birth_placeM">
                                                  <option selected value="">Selecciona una opción</option>
                                                  <option *ngFor="let municipalitie of municipalities"
                                                      value="{{municipalitie.id}}">{{municipalitie.name}}</option>
                                              </select>
                                          </div>

                                          <div class="col-12 col-md-4">
                                              <label>Escolaridad:</label>
                                              <select class="form-control" formControlName="scholarship">
                                                  <option selected value="">Selecciona una opción</option>
                                                  <option value="Primaria">Primaria</option>
                                                  <option value="Secundaria">Secundaria</option>
                                                  <option value="Preparatoria">Preparatoria</option>
                                                  <option value="Estudios técnicos">Estudios técnicos</option>
                                                  <option value="Licenciatura">Licenciatura</option>
                                                  <option value="Posgrado">Posgrado</option>
                                                  <option value="Ninguna">Ninguna</option>
                                              </select>
                                          </div>
                                          <div class="col-12 col-md-4">
                                              <label>Ocupación:</label>
                                              <select class="form-control" formControlName="occupation">
                                                  <option selected value="">Selecciona una opción</option>
                                                  <option value="Agricultor/a y/o Productor/a rural">Agricultor/a y/o
                                                      Productor/a rural</option>
                                                  <option value="Labores domésticas">Labores domésticas</option>
                                                  <option value="Comerciante">Comerciante</option>
                                                  <option value="Docente">Docente</option>
                                                  <option value="Empleado/a">Empleado/a</option>
                                                  <option value="Empresario/a">Empresario/a</option>
                                                  <option value="Estudiante">Estudiante</option>
                                                  <option value="Obrero/a">Obrero/a</option>
                                                  <option value="Profesionista">Profesionista</option>
                                                  <option value="Servidor público">Servidor público</option>
                                                  <option value="Técnico/a">Técnico/a</option>
                                                  <option value="Ni estudia ni trabaja">Ni estudia ni trabaja</option>
                                                  <option value="Otro">Otro</option>
                                              </select>
                                          </div>
                                          <div class="col-12 col-md-4">
                                              <label>Lugar de trabajo:</label>
                                              <input class="form-control" type="text"
                                                  placeholder="Nombre de la empresa" formControlName="workplace" />
                                          </div>
                                          <div class="col-12 col-md-4">
                                              <label>Teléfono del trabajo:</label>
                                              <input class="form-control" type="text"
                                                  placeholder="Ingrese un teléfono del trabajo" minlength="10"
                                                  maxlength="10" formControlName="cell_phone_office" />
                                          </div>
                                          <div class="col-12 col-md-4">
                                              <label>Facebook:</label>
                                              <input class="form-control" type="text" placeholder="Ingrese Facebook"
                                                  formControlName="facebook" />
                                          </div>
                                          <div class="form-group col-12 col-md-4">
                                              <label>Instagram:</label>
                                              <input class="form-control" type="text" placeholder="Ingrese Instagram"
                                                  formControlName="instagram" />
                                          </div>
                                          <div class="form-group  col-12 col-md-4">
                                              <label> <span style="color: red">* </span>
                                                  ¿Cuántas personas mayores de edad habitan en esta vivienda?:</label>
                                              <input class="form-control" type="number"
                                                  placeholder="Ingrese el número" formControlName="elderly" />
                                          </div>

                                          <div class="form-group  col-12 col-md-4">
                                              <label>
                                                  Folio interno:</label>
                                              <input class="form-control" type="number"
                                                  placeholder="Ingrese el folio" formControlName="folio_interno" />
                                          </div>
                                      </div>
                                      <div class="panel panel-info" style="padding-bottom: 0;margin-bottom: 0;">
                                          <p class="divData">Dirección</p>
                                      </div>
                                      <div class="row">
                                          <div class="col-12 col-md-4">
                                              <label>Calle:</label>
                                              <input class="form-control" type="text" placeholder="Ingrese la calle"
                                                  formControlName="street" />
                                          </div>
                                          <div class="col-12 col-md-4">
                                              <label>Número exterior:</label>
                                              <input class="form-control" type="text"
                                                  placeholder="Ingrese el número exterior"
                                                  formControlName="street_num_ext" />
                                          </div>
                                          <div class="col-12 col-md-4">
                                              <label>Número interior:</label>
                                              <input class="form-control" type="text"
                                                  placeholder="Ingrese el número interior"
                                                  formControlName="street_num_int" />
                                          </div>
                                          <div class="col-12 col-md-4"
                                              *ngIf="postalCodeSelected == false else selectedPostal">
                                              <label>Código postal:</label>
                                              <div class="input-group">
                                                  <input type="text" placeholder="Ingrese el código postal"
                                                      class="form-control" formControlName="postal_code">
                                                  <div class="input-group-append">
                                                      <button class="btn btn-outline-secondary" type="button"
                                                          (click)="searchPostalCode()">
                                                          <i class="fa fa-search" aria-hidden="true"></i>
                                                      </button>
                                                  </div>
                                              </div>
                                              <ng-container #menu
                                                  *ngIf="postalCodeSearch.length != 0 && postalCodeSelected == false">
                                                  <div
                                                      class="col-xs-12 col-sm-12 col-md-12 col-lg-12 content-seach searchList">
                                                      <ng-container
                                                          *ngIf="postalCodeSearch.length == 0 else searchMenu">
                                                          <label class="darkGray">No hay registros</label>
                                                      </ng-container>
                                                      <ng-template #searchMenu>
                                                          <ng-container
                                                              *ngFor="let result of postalCodeSearch; let i = index">
                                                              <ng-container>
                                                                  <label
                                                                      (click)="selectPostalCode(result.identifier,result.id)"
                                                                      [ngClass]=" i%2==0 ? 'darkGray' : 'lowGray'">{{result.identifier}}</label>
                                                              </ng-container>
                                                          </ng-container>
                                                          <br>
                                                      </ng-template>
                                                  </div>
                                              </ng-container>
                                          </div>
                                          <ng-template #selectedPostal>
                                              <div class="col">
                                                  <label>Código postal:</label>
                                                  <div class="alert fade show">
                                                      <span class="close" (click)="clearPostalCode()"
                                                          data-dismiss="alert">×</span>
                                                      <span
                                                          style="color: #707478; font-size: 15px;">{{promotedForm.controls['postal_code'].value}}</span>
                                                  </div>
                                              </div>
                                          </ng-template>
                                          <div class="col-12 col-md-4">
                                              <label>Colonia:</label>
                                              <select class="form-control" formControlName="id_neighborhood">
                                                  <option selected value="">Seleccione una colonia</option>
                                                  <option *ngFor="let neighborhoodOption of neighborhoodOptions"
                                                      value="{{neighborhoodOption.id}}">{{neighborhoodOption.name}}
                                                  </option>
                                              </select>
                                          </div>
                                          <div class="col-12 col-md-4">
                                              <label>Municipio:</label>
                                              <input class="form-control" placeholder="Cargando listado..."
                                                  type="text" disabled [value]="municipalitieName">
                                          </div>
                                          <div class="col-12 col-md-4">
                                              <label>Estado:</label>
                                              <input class="form-control" type="text"
                                                  placeholder="Cargando listado..." disabled [value]="entitieName">
                                          </div>
                                          <div class="col-12 col-md-4">
                                              <label>Sección electoral:</label>
                                              <ng-container *ngIf="sectionSelected == false else selectedSecction">
                                                  <input formControlName="electoral_section" type="text"
                                                      class="form-control" [(ngModel)]="sectionSearch"
                                                      placeholder="Sección electoral">
                                                  <div class="list-group"
                                                      *ngIf="sectionSelected == false && sectionOptions.length != 0">
                                                      <ng-container
                                                          *ngIf="sectionOptions.length == 0 else searchMenuSecc">
                                                          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 content-seach searchList"
                                                              style="z-index:2;">
                                                              <label class="darkGray">No hay registros</label>
                                                          </div>
                                                      </ng-container>
                                                      <ng-template #searchMenuSecc>
                                                          <ng-container
                                                              *ngIf="promotedForm.get('electoral_section')?.value != 0 && promotedForm.get('electoral_section')?.value != ''">
                                                              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 content-seach searchList"
                                                                  style="z-index:2;">
                                                                  <ng-container
                                                                      *ngFor="let result of sectionOptions | proposal:sectionSearch ; let i = index">
                                                                      <ng-container *ngIf="i < 4">
                                                                          <label
                                                                              (click)="selectSecction(result.SECCION)"
                                                                              [ngClass]=" i%2==0 ? 'darkGray' : 'lowGray'">
                                                                              {{result.SECCION}}</label>
                                                                      </ng-container>
                                                                  </ng-container>
                                                              </div>
                                                          </ng-container>
                                                      </ng-template>
                                                  </div>
                                              </ng-container>
                                              <ng-template #selectedSecction>
                                                  <div class="alert fade show">
                                                      <span
                                                          style="color: #707478; font-size: 15px;">{{promotedForm.controls['electoral_section'].value}}</span>
                                                      <span class="close" (click)="clearSection()"
                                                          data-dismiss="alert">×</span>
                                                  </div>
                                              </ng-template>
                                          </div>
                                          <div class="col-12 col-md-4">
                                              <label>Referencia:</label>
                                              <input class="form-control" type="text"
                                                  placeholder="Ingrese referencia al domicilio"
                                                  formControlName="reference" />
                                          </div>
                                      </div>
                                  </div>
                              </form>
                          </div>
                      </div>
                      <div class="footer-form">
                          <button class="btn btn-primary" (click)="changeSelected(1)">Siguiente</button>
                      </div>
                  </mat-step>
                  <mat-step>
                      <div class="row">
                          <div class="col-12">
                              <div class="accordion accordion-flush" id="accordionPanelsStayOpenExample">
                                  <div class="accordion-item">
                                      <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                                          <button class="accordion-button  accordion-primary show" type="button"
                                              data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne"
                                              aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
                                              <div class="col-12">
                                                  <i class="fa fa-check mr-3"></i>
                                                  {{surveyTittle}}
                                              </div>
                                          </button>
                                      </h2>
                                      <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show"
                                          aria-labelledby="panelsStayOpen-headingOne">
                                          <div class="accordion-body">
                                              <div class="row">
                                                  <div class="col-12" *ngIf="questions.length > 0">
                                                      <form [formGroup]="surveyForm">
                                                          <div class="contenedor p-4">
                                                              <div class="row">
                                                                  <ng-container *ngFor="let question of questions">

                                                                      <div class="form-group col-12 col-sm-12 col-xs-12 col-md-6 col-lg-4 mb-2 "
                                                                          [id]="question.id"
                                                                          [class.hidden]="question.hidden === 1">

                                                                          <label>
                                                                              <span class="reqForm">
                                                                                  {{question.order}}.
                                                                              </span>
                                                                              {{question.name}}
                                                                          </label>
                                                                          <div #input [ngSwitch]="question.type">
                                                                              <div *ngSwitchCase="'select'">
                                                                                  <select class="form-control"
                                                                                      [formControlName]="question.control"
                                                                                      (change)="handleOption(question)">
                                                                                      <option selected value="">
                                                                                          Selecciona una opción
                                                                                      </option>
                                                                                      <option
                                                                                          *ngFor="let option of question.form_options">
                                                                                          {{option.label}}
                                                                                      </option>
                                                                                  </select>
                                                                              </div>
                                                                              <div *ngSwitchCase="'binary'">
                                                                                  <select class="form-control"
                                                                                      [formControlName]="question.control"
                                                                                      (change)="handleOption(question)">
                                                                                      <option selected value="">
                                                                                          Selecciona una opción
                                                                                      </option>
                                                                                      <option
                                                                                          *ngFor="let option of question.binaries">
                                                                                          {{option.label}}
                                                                                      </option>
                                                                                  </select>
                                                                              </div>
                                                                              <div *ngSwitchCase="'input'">
                                                                                  <input class="form-control"
                                                                                      type="text"
                                                                                      [placeholder]="question.placeholder"
                                                                                      [formControlName]="question.control">
                                                                              </div>
                                                                          </div>

                                                                      </div>
                                                                  </ng-container>
                                                              </div>
                                                          </div>
                                                      </form>
                                                  </div>

                                                  <div class="col-12">
                                                      <form [formGroup]="promotedForm">
                                                          <div class="row mb-3 p-4">
                                                              <div class="col-12 col-md-4">
                                                                  <label>Bitácora:</label>
                                                                  <textarea class="form-control"
                                                                      formControlName="bitacora"
                                                                      placeholder=""></textarea>
                                                              </div>
                                                              <div class="col-12 col-md-4">
                                                                  <div class="d-flex justify-content-end align-self-center"
                                                                      style="height: 100%;">
                                                                      <label
                                                                          class="d-flex justify-content-end align-self-center"
                                                                          style="font-size: large;">Registrar Visita
                                                                      </label>
                                                                  </div>


                                                              </div>
                                                              <div class="col-12 col-md-2">
                                                                  <div class="d-flex justify-content-start align-self-center"
                                                                      style="height: 100%;">

                                                                      <button
                                                                          class="btn btn-secondary btn-lg btn-inverse mr-1 buttonOptions d-flex justify-content-start align-self-center"
                                                                          (click)="openVisitModal()"><i
                                                                              class="fa fa-home "
                                                                              style="font-size: large;"></i></button>
                                                                  </div>
                                                              </div>


                                                          </div>
                                                      </form>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <ng-container *ngIf="idWorkGroup == '95131a90d2da7ce01cac1eeed6da7cf0'">
                                      <div class="accordion-item">
                                          <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                                              <button class="accordion-button  accordion-primary show" type="button"
                                                  data-bs-toggle="collapse"
                                                  data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="false"
                                                  aria-controls="panelsStayOpen-collapseOne">
                                                  <div class="col-12">
                                                      <i class="fa fa-house"></i>
                                                      {{surveyTittle}}
                                                  </div>
                                              </button>
                                          </h2>
                                          <div id="panelsStayOpen-collapseOne"
                                              class="accordion-collapse collapse show"
                                              aria-labelledby="panelsStayOpen-headingOne">
                                              <div class="accordion-body">
                                                  <div class="row">
                                                      <div class="col-12" *ngIf="questions.length > 0">
                                                          <form [formGroup]="surveyForm">
                                                              <div class="contenedor p-4">
                                                                  <div class="row">
                                                                      <ng-container
                                                                          *ngFor="let question of questions">

                                                                          <div class="form-group col-12 col-sm-12 col-xs-12 col-md-6 col-lg-4 mb-2 "
                                                                              [id]="question.id"
                                                                              [class.hidden]="question.hidden === 1">

                                                                              <label>
                                                                                  <span class="reqForm">
                                                                                      {{question.order}}.
                                                                                  </span>
                                                                                  {{question.name}}
                                                                              </label>
                                                                              <div #input [ngSwitch]="question.type">
                                                                                  <div *ngSwitchCase="'select'">
                                                                                      <select class="form-control"
                                                                                          [formControlName]="question.control"
                                                                                          (change)="handleOption(question)">
                                                                                          <option selected value="">
                                                                                              Selecciona una opción
                                                                                          </option>
                                                                                          <option
                                                                                              *ngFor="let option of question.form_options">
                                                                                              {{option.label}}
                                                                                          </option>
                                                                                      </select>
                                                                                  </div>
                                                                                  <div *ngSwitchCase="'binary'">
                                                                                      <select class="form-control"
                                                                                          [formControlName]="question.control"
                                                                                          (change)="handleOption(question)">
                                                                                          <option selected value="">
                                                                                              Selecciona una opción
                                                                                          </option>
                                                                                          <option
                                                                                              *ngFor="let option of question.binaries">
                                                                                              {{option.label}}
                                                                                          </option>
                                                                                      </select>
                                                                                  </div>
                                                                                  <div *ngSwitchCase="'input'">
                                                                                      <input class="form-control"
                                                                                          type="text"
                                                                                          [placeholder]="question.placeholder"
                                                                                          [formControlName]="question.control">
                                                                                  </div>
                                                                              </div>

                                                                          </div>
                                                                      </ng-container>
                                                                  </div>
                                                              </div>
                                                          </form>
                                                      </div>

                                                      <div class="col-12">
                                                          <form [formGroup]="promotedForm">
                                                              <div class="row mb-3 p-4">
                                                                  <div class="col-12 col-md-4">
                                                                      <label> {{idWorkGroup ==
                                                                          '95131a90d2da7ce01cac1eeed6da7cf0' ?
                                                                          "Bitácora:" : "Cuéntanos si tienes otra
                                                                          necesidad en la que podamos ayudarte:"}}
                                                                      </label>
                                                                      <textarea class="form-control"
                                                                          formControlName="bitacora"
                                                                          placeholder=""></textarea>
                                                                  </div>
                                                                  <div class="col-12 col-md-4">
                                                                      <div class="d-flex justify-content-end align-self-center"
                                                                          style="height: 100%;">
                                                                          <label
                                                                              class="d-flex justify-content-end align-self-center"
                                                                              style="font-size: large;">Registrar
                                                                              Visita
                                                                          </label>
                                                                      </div>


                                                                  </div>
                                                                  <div class="col-12 col-md-2">
                                                                      <div class="d-flex justify-content-start align-self-center"
                                                                          style="height: 100%;">

                                                                          <button
                                                                              class="btn btn-secondary btn-lg btn-inverse mr-1 buttonOptions d-flex justify-content-start align-self-center"
                                                                              (click)="openVisitModal()"><i
                                                                                  class="fa fa-home "
                                                                                  style="font-size: large;"></i></button>
                                                                      </div>
                                                                  </div>


                                                              </div>
                                                          </form>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </ng-container>
                              </div>
                          </div>
                      </div>
                      <div class="contenedor" style="padding: 20px; padding-top: 0;">
                          <div class="panel panel-info" style="padding-bottom: 0;margin-bottom: 0;">
                              <p class="divData">Clasificación del promovido</p>
                          </div>
                          <form [formGroup]="promotedForm">
                              <div class="row mb-3">
                                  <div class="col-12 col-md-4">
                                      <label>¿Cuál es su preferencia partidaria?</label>
                                  </div>

                                  <div class="col-12 col-md-4">
                                      <select class="form-control" formControlName="position"
                                          (change)="changeValues()">
                                          <option selected value="">Seleccione una opción</option>
                                          <option value="POSITIVO">POSITIVO</option>
                                          <option value="NEUTRO">NEUTRO</option>
                                          <option value="NEGATIVO">NEGATIVO</option>
                                      </select>
                                  </div>
                              </div>

                              <div class="row mb-3" *ngIf="promotedForm.get('position')?.value == 'POSITIVO'">

                                  <div class="col-12 col-md-4">
                                      <label>¿Le gustaría participar en la campaña?</label>
                                  </div>

                                  <div class="col-12 col-md-4">
                                      <select class="form-control" formControlName="position_type">
                                          <option selected value="">Seleccione una opción</option>
                                          <option value="APOYA">APOYA</option>
                                          <option value="DIFUNDE">DIFUNDE</option>
                                          <option value="VOTA">VOTA</option>
                                      </select>
                                  </div>
                              </div>

                              <div class="row mb-3" *ngIf="promotedForm.get('position')?.value == 'NEUTRO'">
                                  <div class="col-12 col-md-4">
                                      <label>¿Cuál es la preferencia de su voto?</label>
                                  </div>
                                  <div class="col-12 col-md-4">
                                      <select class="form-control" formControlName="vote_preference">
                                          <option selected value="">Seleccione una opción</option>
                                          <ng-container
                                              *ngIf="idWorkGroup == '95131a90d2da7ce01cac1eeed6da7cf0'; else PRI_option">
                                              <option value="PRO MORENA">PRO MORENA</option>
                                          </ng-container>
                                          <ng-template #PRI_option>
                                              <option value="PRO PRI">PRO PRI</option>
                                          </ng-template>
                                          <option value="PRO OPOSICIÓN">PRO OPOSICIÓN</option>
                                      </select>
                                  </div>
                              </div>

                              <div class="row mb-3"
                                  *ngIf="promotedForm.get('position')?.value == 'POSITIVO' || promotedForm.get('position')?.value == 'NEUTRO'">
                                  <div class="col-12 col-md-4">
                                      <label>¿Tiene algún tipo de participación?
                                      </label>
                                  </div>
                                  <div class="col-12 col-md-4">
                                      <div class="col-md-12">
                                          <div class="radio radio-css radio-inline">
                                              <input id="Participationyes" formControlName="participation"
                                                  type="radio" [value]="1">
                                              <label for="Participationyes">Sí</label>
                                          </div>
                                          <div class="radio radio-css radio-inline">
                                              <input id="Participationnot" formControlName="participation"
                                                  type="radio" [value]="0">
                                              <label for="Participationnot">No</label>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="row mb-3" *ngIf="promotedForm.get('participation')?.value == 1">
                                  <div class="col-12 col-md-4">
                                      <label>¿Cuál?</label>
                                  </div>
                                  <div class="col-12 col-md-4">
                                      <ng-container
                                          *ngIf="promotedForm.get('position')?.value == 'POSITIVO' else NeutroOptions">
                                          <select class="form-control" formControlName="participation_type">
                                              <option selected value="">Seleccione una opción</option>
                                              <option value="PROMOCIÓN DEL VOTO (APOYA)">PROMOCIÓN DEL VOTO
                                                  (APOYA)</option>
                                              <option value="CUIDANDO UNA CASILLA (APOYA)">CUIDANDO UNA
                                                  CASILLA (APOYA)</option>
                                              <option value="COLOCACIÓN Y DISTRIBUCIÓN DE PROPAGANDA (DIFUNDE)">
                                                  COLOCACIÓN Y DISTRIBUCIÓN DE PROPAGANDA (DIFUNDE)</option>
                                              <option
                                                  value="LLEVAR A CABO UNA REUNIÓN CON VISITA DEL CANDIDATO (DIFUNDE)">
                                                  LLEVAR A CABO UNA REUNIÓN CON VISITA DEL CANDIDATO (DIFUNDE)
                                              </option>
                                              <option value="PROMOCIÓN PERSONALIZADA (DIFUNDE)">
                                                  PROMOCIÓNPERSONALIZADA (DIFUNDE)</option>
                                              <option value="VOTANTE">VOTANTE</option>
                                          </select>
                                      </ng-container>
                                      <ng-template #NeutroOptions>
                                          <select class="form-control" formControlName="participation_type">
                                              <option selected value="">Seleccione una opción</option>
                                              <option value="REQUIERE UNA VISITA DEL CANDIDATO (CONVENCIMIENTO)">
                                                  REQUIERE UNA VISITA DEL CANDIDATO (CONVENCIMIENTO)</option>
                                              <option value="MÁS INFORMACIÓN (CONVENCIMIENTO)">MÁS INFORMACIÓN
                                                  (CONVENCIMIENTO)</option>
                                          </select>
                                      </ng-template>
                                  </div>
                              </div>


                              <ng-container *ngIf="promotedForm.get('position')?.value == 'NEGATIVO'">
                                  <div class="row mb-3">
                                      <div class="col-12 col-md-4">
                                          <label>¿Considera que hay un rechazo de su parte hacia los partidos
                                              políticos?</label>
                                      </div>

                                      <div class="col-12 col-md-4">

                                          <select class="form-control" formControlName="political_rejection">
                                              <option selected value="">Seleccione una opción</option>
                                              <option value="SÍ">SÍ</option>
                                              <option value="NO">NO</option>
                                          </select>
                                      </div>
                                  </div>
                                  <div class="row mb-3">
                                      <div class="col-12 col-md-4">
                                          <label>¿Simpatiza y/o es militante de la oposición?</label>
                                      </div>
                                      <div class="col-12 col-md-4">
                                          <select class="form-control" formControlName="political_militant">
                                              <option selected value="">Seleccione una opción</option>
                                              <option value="SÍ">SÍ</option>
                                              <option value="NO">NO</option>
                                          </select>
                                      </div>
                                  </div>
                                  <!-- <div class="row mb-3">
                                      <div class="col-12 col-md-4">
                                          <label>¿Hay un rechazo hacia Morena?</label>
                                      </div>
                                      <div class="col-12 col-md-4">
                                          <select class="form-control">
                                              <option selected value="">Seleccione una opción</option>
                                              <option value="SÍ">SÍ</option>
                                              <option value="NO">NO</option>
                                          </select>
                                      </div>
                                  </div> -->



                              </ng-container>

                              <div class="row mb-3">
                                  <div class="col-12 col-md-4">
                                      <label>¿Se relaciona con un punto de interés? </label>
                                  </div>

                                  <div class="col-12 col-md-4">
                                      <div class="col-md-12">
                                          <div class="radio radio-css radio-inline">
                                              <input id="Poiyes" formControlName="poi_related" type="radio"
                                                  [value]="1">
                                              <label for="Poiyes">Sí</label>
                                          </div>
                                          <div class="radio radio-css radio-inline">
                                              <input id="Poinot" formControlName="poi_related" type="radio"
                                                  [value]="0" checked="">
                                              <label for="Poinot">No</label>
                                          </div>
                                      </div>
                                  </div>
                              </div>

                              <div class="row mb-3" *ngIf="promotedForm.get('poi_related')?.value == '1'">
                                  <div class="col-12 col-md-4">
                                      <label>¿Cuál?</label>
                                  </div>

                                  <div class="col-12 col-md-4">
                                      <select class="form-control" formControlName="id_poi">
                                          <option selected value="0">Seleccione una opción</option>
                                          <option value="{{POI.id}}" *ngFor="let POI of POIoptions">{{POI.name}}
                                          </option>
                                      </select>
                                  </div>

                                  <div class="col-12 col-md-4">
                                      <label>Agregar un punto</label>
                                      <div class="col-md-5 p-0">
                                          <button type="submit" (click)="openDialog()"
                                              class="btn btn-primary btn-lg btn-block">+</button>
                                      </div>
                                  </div>
                              </div>

                              <div class="row mb-3">
                                  <div class="col-12 col-md-4">
                                      <label>¿Es un actor importante?
                                      </label>
                                  </div>

                                  <div class="col-12 col-md-4">
                                      <div class="col-md-12">
                                          <div class="radio radio-css radio-inline">
                                              <input id="Actoryes" formControlName="actor" type="radio" [value]="1">
                                              <label for="Actoryes">Sí</label>
                                          </div>
                                          <div class="radio radio-css radio-inline">
                                              <input id="Actornot" formControlName="actor" type="radio" [value]="0"
                                                  checked="">
                                              <label for="Actornot">No</label>
                                          </div>
                                      </div>
                                  </div>
                              </div>

                              <div class="row mb-3" *ngIf="promotedForm.get('actor')?.value == '1'">
                                  <div class="col-12 col-md-4">
                                      <label>¿Qué tipo de actor es?
                                      </label>
                                  </div>
                                  <div class="col-12 col-md-4">
                                      <select class="form-control" formControlName="actor_type">
                                          <option selected value="">Seleccione una opción</option>
                                          <option value="EMPRESARIO">EMPRESARIO</option>
                                          <option value="LÍDER EDUCATIVO">LÍDER EDUCATIVO</option>
                                          <option value="LÍDER RELIGIOSO">LÍDER RELIGIOSO</option>
                                          <option value="LÍDER DE MEDIO DE COMUNICACIÓN">LÍDER DE MEDIO DE
                                              COMUNICACIÓN</option>
                                          <option value="LÍDER DE CÁMARA O ASOCIACIÓN EMPRESARIAL">LÍDER DE CÁMARA O
                                              ASOCIACIÓN EMPRESARIAL</option>
                                          <option value="LÍDER DEL SECTOR SALUD">LÍDER DEL SECTOR SALUD</option>
                                          <option value="LÍDER SINDICAL">LÍDER SINDICAL</option>
                                          <option value="LÍDER POLÍTICO">LÍDER POLÍTICO</option>
                                          <option value="LÍDER DE COLONIA">LÍDER DE COLONIA</option>
                                          <option value="REPRESENTANTE DE A.C. / ONG">REPRESENTANTE DE A.C. / ONG
                                          </option>
                                          <option value="LÍDER JUVENIL">LÍDER JUVENIL</option>
                                          <option value="LÍDER DE MUJERES">LÍDER DE MUJERES</option>
                                          <option value="OTRO">OTRO</option>
                                      </select>
                                  </div>
                              </div>
                              <div class="row mb-3" *ngIf="promotedForm.get('actor_type')?.value == 'OTRO'">
                                  <div class="col-12 col-md-4">
                                      <label>¿Cuál?</label>
                                  </div>


                                  <div class="col-12 col-md-4">
                                      <input class="form-control" type="text" placeholder=""
                                          formControlName="other_actor" />
                                  </div>
                              </div>

                          </form>
                      </div>
                      <div class="footer-form">
                          <button class="btn btn-primary" (click)="changeSelected(2)">Siguiente</button>
                          <button class="btn btn-light mr-2" (click)="changeSelected(0)">Anterior</button>
                      </div>
                  </mat-step>
                  <mat-step>

                      <div class="row">
                          <div class="col-12">
                              <div class="accordion accordion-flush" id="accordionPanelsStayOpenExample">

                                  <div class="accordion-item">
                                      <h2 class="accordion-header" id="panelsStayOpen-headingP1">
                                          <button class="accordion-button collapsed accordion-secondary" type="button"
                                              data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseP1"
                                              aria-expanded="false" aria-controls="panelsStayOpen-collapseP1">
                                              <div class="col-12">
                                                  Primera problemática de la calle
                                              </div>
                                          </button>
                                      </h2>
                                      <div id="panelsStayOpen-collapseP1" class="accordion-collapse collapse"
                                          aria-labelledby="panelsStayOpen-headingP1">
                                          <div class="accordion-body">
                                              <div style=" padding: 20px;">

                                                  <form [formGroup]="issue1Form">
                                                      <div class="row">
                                                          <div class="col-12 col-md-3">
                                                              <label>Buscador de categorías (Opcional):</label>
                                                              <div class="input-group">
                                                                  <input type="text" placeholder="Buscar Categoría"
                                                                      class="form-control"
                                                                      [(ngModel)]="searchValue[1]"
                                                                      (ngModelChange)="onSearchChange(1, $event)"
                                                                      [ngModelOptions]="{standalone: true}">
                                                                  <div class="input-group-append">
                                                                      <button class="btn btn-outline-secondary"
                                                                          type="button" (click)="searchCategory(1)">
                                                                          <i class="fa fa-search"
                                                                              aria-hidden="true"></i>
                                                                      </button>
                                                                  </div>
                                                              </div>
                                                              <ng-container #menu
                                                                  *ngIf="responseSearch[1].length != 0 && selectedSearch[1] == false">
                                                                  <div class="col-12 content-seach searchList">
                                                                      <ng-container
                                                                          *ngIf="responseSearch[1].length == 0 else searchMenu">
                                                                          <label class="darkGray">No hay
                                                                              registros</label>
                                                                      </ng-container>
                                                                      <ng-template #searchMenu>
                                                                          <ng-container
                                                                              *ngFor="let result of responseSearch[1]; let i = index">
                                                                              <ng-container>
                                                                                  <label
                                                                                      (click)="selectOption(result.ids,this.issue1Form,1)"
                                                                                      [ngClass]=" i%2==0 ? 'darkGray' : 'lowGray'">{{result.name}}</label>
                                                                              </ng-container>
                                                                          </ng-container>
                                                                          <br>
                                                                      </ng-template>
                                                                  </div>
                                                              </ng-container>
                                                          </div>
                                                      </div>
                                                      <br />
                                                      <div class="row">
                                                          <div class="col-12 col-md-4">
                                                              <span style="color: red">* </span>
                                                              <label>Tema:</label>
                                                              <select class="form-control" formControlName="theme_id"
                                                                  (change)="onThemeSelectChange(this.issue1Form)">
                                                                  <option value="">Selecciona una opción</option>
                                                                  <option *ngFor="let theme of themes"
                                                                      value="{{theme.id}}">
                                                                      {{theme.name}}</option>
                                                              </select>
                                                          </div>
                                                          <div class="col-12 col-md-4">
                                                              <span style="color: red">* </span>
                                                              <label>Subtema:</label>
                                                              <select class="form-control"
                                                                  formControlName="subtheme_id"
                                                                  (change)="onSubthemeSelectChange(this.issue1Form)">
                                                                  <option value="">Selecciona una opción</option>
                                                                  <option *ngFor="let subtheme of subthemes[1]"
                                                                      value="{{subtheme.id}}">{{subtheme.name}}
                                                                  </option>
                                                              </select>
                                                          </div>
                                                          <div class="col-12 col-md-4">
                                                              <span style="color: red">* </span>
                                                              <label>Categoría:</label>
                                                              <select class="form-control"
                                                                  formControlName="categorie_id">
                                                                  <option value="">Selecciona una opción</option>
                                                                  <option *ngFor="let category of categories[1]"
                                                                      value="{{category.id}}">{{category.name}}
                                                                  </option>
                                                              </select>
                                                          </div>
                                                      </div>
                                                      <div class="row">
                                                          <div class="col-12 col-md-4">
                                                              <label class="col-md-12">Descripción:</label>
                                                              <textarea id="description" class="form-control"
                                                                  placeholder="Ingresa una descripción"
                                                                  maxlength="250"
                                                                  formControlName="description"></textarea>
                                                              <app-maxchardec
                                                                  [inputValue]="issue1Form.controls['description'].value ?? ''">
                                                              </app-maxchardec>
                                                          </div>
                                                      </div>

                                                  </form>

                                              </div>
                                          </div>
                                      </div>
                                  </div>

                                  <div class="accordion-item">
                                      <h2 class="accordion-header" id="panelsStayOpen-headingP2">
                                          <button class="accordion-button collapsed accordion-secondary" type="button"
                                              data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseP2"
                                              aria-expanded="false" aria-controls="panelsStayOpen-collapseP2">
                                              <div class="col-12">
                                                  Segunda problemática de la calle
                                              </div>
                                          </button>
                                      </h2>
                                      <div id="panelsStayOpen-collapseP2" class="accordion-collapse collapse"
                                          aria-labelledby="panelsStayOpen-headingP2">
                                          <div class="accordion-body">
                                              <div style=" padding: 20px;">

                                                  <form [formGroup]="issue2Form">
                                                      <div class="row">
                                                          <div class="col-12 col-md-3">
                                                              <label>Buscador de categorías (Opcional):</label>
                                                              <div class="input-group">
                                                                  <input type="text" placeholder="Buscar Categoría"
                                                                      class="form-control"
                                                                      [(ngModel)]="searchValue[2]"
                                                                      (ngModelChange)="onSearchChange(2, $event)"
                                                                      [ngModelOptions]="{standalone: true}">
                                                                  <div class="input-group-append">
                                                                      <button class="btn btn-outline-secondary"
                                                                          type="button" (click)="searchCategory(2)">
                                                                          <i class="fa fa-search"
                                                                              aria-hidden="true"></i>
                                                                      </button>
                                                                  </div>
                                                              </div>
                                                              <ng-container #menu
                                                                  *ngIf="responseSearch[2].length != 0 && selectedSearch[2] == false">
                                                                  <div class="col-12 content-seach searchList">
                                                                      <ng-container
                                                                          *ngIf="responseSearch[2].length == 0 else searchMenu">
                                                                          <label class="darkGray">No hay
                                                                              registros</label>
                                                                      </ng-container>
                                                                      <ng-template #searchMenu>
                                                                          <ng-container
                                                                              *ngFor="let result of responseSearch[2]; let i = index">
                                                                              <ng-container>
                                                                                  <label
                                                                                      (click)="selectOption(result.ids,this.issue2Form,2)"
                                                                                      [ngClass]=" i%2==0 ? 'darkGray' : 'lowGray'">{{result.name}}</label>
                                                                              </ng-container>
                                                                          </ng-container>
                                                                          <br>
                                                                      </ng-template>
                                                                  </div>
                                                              </ng-container>
                                                          </div>
                                                      </div>
                                                      <br />
                                                      <div class="row">
                                                          <div class="col-12 col-md-4">
                                                              <span style="color: red">* </span>
                                                              <label>Tema:</label>
                                                              <select class="form-control" formControlName="theme_id"
                                                                  (change)="onThemeSelectChange(this.issue2Form)">
                                                                  <option value="">Selecciona una opción</option>
                                                                  <option *ngFor="let theme of themes"
                                                                      value="{{theme.id}}">
                                                                      {{theme.name}}</option>
                                                              </select>
                                                          </div>
                                                          <div class="col-12 col-md-4">
                                                              <span style="color: red">* </span>
                                                              <label>Subtema:</label>
                                                              <select class="form-control"
                                                                  formControlName="subtheme_id"
                                                                  (change)="onSubthemeSelectChange(this.issue2Form)">
                                                                  <option value="">Selecciona una opción</option>
                                                                  <option *ngFor="let subtheme of subthemes[2]"
                                                                      value="{{subtheme.id}}">{{subtheme.name}}
                                                                  </option>
                                                              </select>
                                                          </div>
                                                          <div class="col-12 col-md-4">
                                                              <span style="color: red">* </span>
                                                              <label>Categoría:</label>
                                                              <select class="form-control"
                                                                  formControlName="categorie_id">
                                                                  <option value="">Selecciona una opción</option>
                                                                  <option *ngFor="let category of categories[2]"
                                                                      value="{{category.id}}">{{category.name}}
                                                                  </option>
                                                              </select>
                                                          </div>
                                                      </div>
                                                      <div class="row">
                                                          <div class="col-12 col-md-4">
                                                              <label class="col-md-12">Descripción:</label>
                                                              <textarea id="description" class="form-control"
                                                                  placeholder="Ingresa una descripción"
                                                                  maxlength="250"
                                                                  formControlName="description"></textarea>
                                                              <app-maxchardec
                                                                  [inputValue]="issue2Form.controls['description'].value ?? ''">
                                                              </app-maxchardec>
                                                          </div>
                                                      </div>

                                                  </form>

                                              </div>
                                          </div>
                                      </div>
                                  </div>

                                  <div class="accordion-item">
                                      <h2 class="accordion-header" id="panelsStayOpen-headingP3">
                                          <button class="accordion-button collapsed accordion-secondary" type="button"
                                              data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseP3"
                                              aria-expanded="false" aria-controls="panelsStayOpen-collapseP3">
                                              <div class="col-12">
                                                  Primera problemática de la colonia
                                              </div>
                                          </button>
                                      </h2>
                                      <div id="panelsStayOpen-collapseP3" class="accordion-collapse collapse"
                                          aria-labelledby="panelsStayOpen-headingP3">
                                          <div class="accordion-body">
                                              <div style=" padding: 20px;">

                                                  <form [formGroup]="issue3Form">
                                                      <div class="row">
                                                          <div class="col-12 col-md-3">
                                                              <label>Buscador de categorías (Opcional):</label>
                                                              <div class="input-group">
                                                                  <input type="text" placeholder="Buscar Categoría"
                                                                      class="form-control"
                                                                      [(ngModel)]="searchValue[3]"
                                                                      (ngModelChange)="onSearchChange(3, $event)"
                                                                      [ngModelOptions]="{standalone: true}">
                                                                  <div class="input-group-append">
                                                                      <button class="btn btn-outline-secondary"
                                                                          type="button" (click)="searchCategory(3)">
                                                                          <i class="fa fa-search"
                                                                              aria-hidden="true"></i>
                                                                      </button>
                                                                  </div>
                                                              </div>
                                                              <ng-container #menu
                                                                  *ngIf="responseSearch[3].length != 0 && selectedSearch[3] == false">
                                                                  <div class="col-12 content-seach searchList">
                                                                      <ng-container
                                                                          *ngIf="responseSearch[3].length == 0 else searchMenu">
                                                                          <label class="darkGray">No hay
                                                                              registros</label>
                                                                      </ng-container>
                                                                      <ng-template #searchMenu>
                                                                          <ng-container
                                                                              *ngFor="let result of responseSearch[3]; let i = index">
                                                                              <ng-container>
                                                                                  <label
                                                                                      (click)="selectOption(result.ids,this.issue3Form,3)"
                                                                                      [ngClass]=" i%2==0 ? 'darkGray' : 'lowGray'">{{result.name}}</label>
                                                                              </ng-container>
                                                                          </ng-container>
                                                                          <br>
                                                                      </ng-template>
                                                                  </div>
                                                              </ng-container>
                                                          </div>
                                                      </div>
                                                      <br />
                                                      <div class="row">
                                                          <div class="col-12 col-md-4">
                                                              <span style="color: red">* </span>
                                                              <label>Tema:</label>
                                                              <select class="form-control" formControlName="theme_id"
                                                                  (change)="onThemeSelectChange(this.issue3Form)">
                                                                  <option value="">Selecciona una opción</option>
                                                                  <option *ngFor="let theme of themes"
                                                                      value="{{theme.id}}">
                                                                      {{theme.name}}</option>
                                                              </select>
                                                          </div>
                                                          <div class="col-12 col-md-4">
                                                              <span style="color: red">* </span>
                                                              <label>Subtema:</label>
                                                              <select class="form-control"
                                                                  formControlName="subtheme_id"
                                                                  (change)="onSubthemeSelectChange(this.issue3Form)">
                                                                  <option value="">Selecciona una opción</option>
                                                                  <option *ngFor="let subtheme of subthemes[3]"
                                                                      value="{{subtheme.id}}">{{subtheme.name}}
                                                                  </option>
                                                              </select>
                                                          </div>
                                                          <div class="col-12 col-md-4">
                                                              <span style="color: red">* </span>
                                                              <label>Categoría:</label>
                                                              <select class="form-control"
                                                                  formControlName="categorie_id">
                                                                  <option value="">Selecciona una opción</option>
                                                                  <option *ngFor="let category of categories[3]"
                                                                      value="{{category.id}}">{{category.name}}
                                                                  </option>
                                                              </select>
                                                          </div>
                                                      </div>
                                                      <div class="row">
                                                          <div class="col-12 col-md-4">
                                                              <label class="col-md-12">Descripción:</label>
                                                              <textarea id="description" class="form-control"
                                                                  placeholder="Ingresa una descripción"
                                                                  maxlength="250"
                                                                  formControlName="description"></textarea>
                                                              <app-maxchardec
                                                                  [inputValue]="issue3Form.controls['description'].value ?? ''">
                                                              </app-maxchardec>
                                                          </div>
                                                      </div>

                                                  </form>

                                              </div>
                                          </div>
                                      </div>
                                  </div>

                                  <div class="accordion-item">
                                      <h2 class="accordion-header" id="panelsStayOpen-headingP4">
                                          <button class="accordion-button collapsed accordion-secondary" type="button"
                                              data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseP4"
                                              aria-expanded="false" aria-controls="panelsStayOpen-collapseP4">
                                              <div class="col-12">
                                                  Segunda problemática de la colonia
                                              </div>
                                          </button>
                                      </h2>
                                      <div id="panelsStayOpen-collapseP4" class="accordion-collapse collapse"
                                          aria-labelledby="panelsStayOpen-headingP4">
                                          <div class="accordion-body">
                                              <div style=" padding: 20px;">

                                                  <form [formGroup]="issue4Form">
                                                      <div class="row">
                                                          <div class="col-12 col-md-3">
                                                              <label>Buscador de categorías (Opcional):</label>
                                                              <div class="input-group">
                                                                  <input type="text" placeholder="Buscar Categoría"
                                                                      class="form-control"
                                                                      [(ngModel)]="searchValue[4]"
                                                                      (ngModelChange)="onSearchChange(4, $event)"
                                                                      [ngModelOptions]="{standalone: true}">
                                                                  <div class="input-group-append">
                                                                      <button class="btn btn-outline-secondary"
                                                                          type="button" (click)="searchCategory(4)">
                                                                          <i class="fa fa-search"
                                                                              aria-hidden="true"></i>
                                                                      </button>
                                                                  </div>
                                                              </div>
                                                              <ng-container #menu
                                                                  *ngIf="responseSearch[4].length != 0 && selectedSearch[4] == false">
                                                                  <div class="col-12 content-seach searchList">
                                                                      <ng-container
                                                                          *ngIf="responseSearch[4].length == 0 else searchMenu">
                                                                          <label class="darkGray">No hay
                                                                              registros</label>
                                                                      </ng-container>
                                                                      <ng-template #searchMenu>
                                                                          <ng-container
                                                                              *ngFor="let result of responseSearch[4]; let i = index">
                                                                              <ng-container>
                                                                                  <label
                                                                                      (click)="selectOption(result.ids,this.issue4Form,4)"
                                                                                      [ngClass]=" i%2==0 ? 'darkGray' : 'lowGray'">{{result.name}}</label>
                                                                              </ng-container>
                                                                          </ng-container>
                                                                          <br>
                                                                      </ng-template>
                                                                  </div>
                                                              </ng-container>
                                                          </div>
                                                      </div>
                                                      <br />
                                                      <div class="row">
                                                          <div class="col-12 col-md-4">
                                                              <span style="color: red">* </span>
                                                              <label>Tema:</label>
                                                              <select class="form-control" formControlName="theme_id"
                                                                  (change)="onThemeSelectChange(this.issue4Form)">
                                                                  <option value="">Selecciona una opción</option>
                                                                  <option *ngFor="let theme of themes"
                                                                      value="{{theme.id}}">
                                                                      {{theme.name}}</option>
                                                              </select>
                                                          </div>
                                                          <div class="col-12 col-md-4">
                                                              <span style="color: red">* </span>
                                                              <label>Subtema:</label>
                                                              <select class="form-control"
                                                                  formControlName="subtheme_id"
                                                                  (change)="onSubthemeSelectChange(this.issue4Form)">
                                                                  <option value="">Selecciona una opción</option>
                                                                  <option *ngFor="let subtheme of subthemes[4]"
                                                                      value="{{subtheme.id}}">{{subtheme.name}}
                                                                  </option>
                                                              </select>
                                                          </div>
                                                          <div class="col-12 col-md-4">
                                                              <span style="color: red">* </span>
                                                              <label>Categoría:</label>
                                                              <select class="form-control"
                                                                  formControlName="categorie_id">
                                                                  <option value="">Selecciona una opción</option>
                                                                  <option *ngFor="let category of categories[4]"
                                                                      value="{{category.id}}">{{category.name}}
                                                                  </option>
                                                              </select>
                                                          </div>
                                                      </div>
                                                      <div class="row">
                                                          <div class="col-12 col-md-4">
                                                              <label class="col-md-12">Descripción:</label>
                                                              <textarea id="description" class="form-control"
                                                                  placeholder="Ingresa una descripción"
                                                                  maxlength="250"
                                                                  formControlName="description"></textarea>
                                                              <app-maxchardec
                                                                  [inputValue]="issue4Form.controls['description'].value ?? ''">
                                                              </app-maxchardec>
                                                          </div>
                                                      </div>

                                                  </form>

                                              </div>
                                          </div>
                                      </div>
                                  </div>


                              </div>
                          </div>
                          <div class="panel panel-info">
                              <p class="divData"></p>
                          </div>
                      </div>

                      <div class="complete-label" style="padding: 4vh;padding-bottom: 6vh;">
                          <div class="row ">
                              <div class="d-flex justify-content-center">
                                  <p>Registro completo</p>
                              </div>
                          </div>
                          <div class="row mb-3">
                              <div class="d-flex justify-content-center">
                                  <button type="button" class="btn btn-primary"
                                      [disabled]="!promotedForm.valid || loading == true"
                                      (click)="buttonSave()">Guardar</button>
                              </div>
                          </div>
                      </div>


                      <div class="footer-form">
                          <button class="btn btn-light" (click)="changeSelected(1)">Anterior</button>
                      </div>
                  </mat-step>
              </mat-stepper>
          </div>
      </div>
  </div>
</div>


<!-- Modal -->
<div class="modal fade" id="visitModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
      <div class="modal-content">
          <div class="modal-header" style="background: #348fe2;">
              <h5 class="modal-title" id="exampleModalLabel">Visita hecha al promovido</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div class="modal-body" style="color:#707478;font-size:14px;">
              <form [formGroup]="visitForm">
                  <div>
                      <label><span style="color: red">* </span>Fecha de la visita:</label>
                      <input class="form-control" type="datetime-local" placeholder="dd/mm/aaaa"
                          formControlName="date"
                          [ngClass]="{'is-invalid': visitForm.controls['date'].invalid && visitForm.controls['date'].touched}" />
                      <div id="validationServerUsernameFeedback" class="invalid-feedback">
                          Favor de ingresar una fecha y que sea menor a la actual.
                      </div>
                  </div>
                  <div>
                      <label class="p-t-2">¿Quien realizó la visita?</label>
                      <select class="form-control" formControlName="id_user_visitor">
                          <option selected value=0>Selecciona una opción</option>
                          <option *ngFor="let user of usersOptions" value="{{user.idUser}}">{{user.workgroup}}
                          </option>
                      </select>
                  </div>
                  <div>
                      <label class="col-md-12 pl-1"><span style="color: red">* </span>Nota:</label>
                      <textarea id="description" class="form-control"
                          placeholder="Ingresa una descripción de la visita" formControlName="note"
                          [ngClass]="{'is-invalid': visitForm.controls['note'].invalid && visitForm.controls['note'].touched}"></textarea>
                      <div id="validationServerUsernameFeedback" class="invalid-feedback">
                          Por favor ingresa una nota.
                      </div>
                  </div>

                  <div class="cont-image">
                      <label class="block mb-2 text-sm text-gesco dark:text-white" for="multiple_files">Agregar
                          evidencia:</label>
                      <input class="block w-full text-sm text-gesco border-gesco rounded-lg cursor-pointer"
                          type="file" id="multiple_files" accept=".pdf,.doc,.png,.jpg,.jpeg" multiple
                          (change)="onFileChange($event)" />
                      <p class="mt-1 text-sm text-gesco" id="file_input_help">Cargar imágenes o archivos (3 máximo)
                      </p>
                  </div>
              </form>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-light showCount" (click)="closeVisitModal()"
                  style="font-size: 14px;">Cancelar</button>
              <button type="button" class="btn btn-primary" (click)="saveVisit()" [disabled]="!visitForm.valid"
                  style="font-size: 14px;">Guardar</button>
          </div>
      </div>
  </div>
</div>

<!-- Modal -->

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
      <div class="modal-content">
          <div class="modal-header" style="background: #348fe2;">
              <h5 class="modal-title" id="exampleModalLabel" style="color:black;">Aviso de privacidad</h5>
              <button type="button" class="close" (click)="closeModal()" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div class="modal-body" style="color:#707478;font-size:14px;" [ngSwitch]="idWorkGroup">
              <ng-container *ngSwitchCase="'e028af7a1f904614d856cfe4113cd51a'">
                  <p>
                  <p>
                      La Oficina de Atención Ciudadana del Diputado Local Waldo Fernández González, a través de la
                      persona moral ExprésateCon S.A. de C.V., y sus filiales y/o subsidiarias recabarán los datos
                      personales que Usted proporcione, estos estarán protegidos en términos de lo que dispone la
                      Ley Federal de Protección de Datos Personales en Posesión de Particulares y su Reglamento.
                      Para conocer el contenido completo del Aviso de Privacidad puede consultar el siguiente sitio
                      de internet: www.gesco.mx. También lo puedes consultar en: <a
                          href="https://waldofernandez.com/aviso-de-privacidad/">https://waldofernandez.com/aviso-de-privacidad/</a>
                      <br>
                      <span class="more" (click)="toggleText()">{{ isReadMore ? 'Leer más...' : 'Leer menos...'
                          }}</span>
                  </p>
                  <span class="complete">
                      <p><b>AVISO DE PRIVACIDAD</b></p>
                      <p>
                          Cuidamos de tus datos personales al igual que nos preocupa tu seguridad, la protección de tu
                          privacidad y de tus datos personales también es una prioridad para nosotros. Este Aviso de
                          Privacidad explica qué tipo de datos personales solicitamos o recabamos, cómo, para qué
                          fines
                          y con quién pueden ser compartidos o revelados.
                          <br>
                          De conformidad con lo establecido en la Ley Federal de Protección de Datos Personales en
                          Posesión de los Particulares, en lo sucesivo se denominará “La Ley”, en concordancia con su
                          reglamento, hacemos de conocimiento al “Usuario” que la Oficina de Atención Ciudadana del
                          Diputado Local Waldo Fernández González, ubicada en: Calle José Benítez Núm. 1925, Col.
                          Obispado, C.P. 64060, Monterrey, Nuevo León, a través de la persona moral ExprésateCon S.A.
                          de C.V., sus filiales y/o subsidiarias, con domicilio en Av. Paseo de la Reforma, Núm. 180,
                          Piso 12, Col. Juárez, Alcaldía Cuauhtémoc, C.P. 06600, Ciudad de México; que en lo sucesivo
                          se conocerán como los “Responsables”.
                          <br>
                          Recabarán y mantendrán los datos personales que el Usuario proporcione en los términos del
                          presente Aviso.
                          Asegurando que los datos personales se encuentren resguardados bajo estrictas MEDIDAS DE
                          SEGURIDAD ADMINISTRATIVAS,
                          TÉCNICAS y FÍSICAS las cuales han sido implementadas, con el objeto de preservar y
                          garantizar sus datos
                          personales contra daño, pérdida, alteración, destrucción o el uso, acceso o tratamiento no
                          autorizados.
                      </p>
                      <p><b>¿PARA QUÉ FINES RECABAMOS Y UTILIZAMOS SUS DATOS PERSONALES?</b></p>
                      <p>Los datos personales serán utilizados con las siguientes finalidades, mismas que podrán ser
                          utilizadas de manera individual o bien en su conjunto, siendo:
                          <br>
                          • Finalidades primarias: (a) Administrar, almacenar, gestionar, dar seguimiento, controlar
                          y mapear las solicitudes realizadas por la ciudadanía o las propias. (b) Atender solicitudes
                          de Derechos ARCO (Acceso, Rectificación, Cancelación u Oposición).
                          <br>
                          • Finalidades secundarias: Brindar la atención y seguimiento correspondiente a sus dudas,
                          quejas y/o aclaraciones sobre nuestros servicios.
                      </p>
                      <p><b>¿CÓMO OBTENEMOS SUS DATOS PERSONALES?</b></p>
                      <p>Para llevar a cabo las finalidades señaladas en el presente aviso de privacidad, se podrán
                          recabar los datos personales de diferentes maneras; cuando el Usuario los proporciona ya
                          sea de manera física, vía telefónica, digital o mediante alguna aplicación tecnológica de
                          comunicación; así como cuando vacía sus datos a través del formato de registro gesco.mx,
                          nuestro sitio de Internet en la sección de “Contacto”; cuando envíe un correo electrónico;
                          a través de fuentes de acceso público y/o a través de otras fuentes permitidas previstas
                          en el artículo 10 de la “Ley”.</p>
                      <p> <b>DATOS PERSONALES QUE PODRÁN SER RECABADOS Y TRATADOS.</b></p>
                      <p>Los datos personales a recabar son: nombre completo; apellidos, fecha de nacimiento, sexo,
                          CURP, estado civil, ocupación, domicilio completo, número de teléfono fijo y/o celular,
                          correo electrónico; en caso de que aplique, cargo o función que desempeña. Así como
                          documentos
                          que se llegarán a solicitar en caso de realizar algún trámite.</p>
                      <p><b>TRANSFERENCIA DE DATOS PERSONALES.</b></p>
                      <p> Los Responsables se comprometen a no transferir la información personal a terceros sin su
                          previo
                          consentimiento, salvo las excepciones previstas en el artículo 37 de la “Ley” y el artículo
                          38 de
                          su Reglamento, así como a realizar esta transferencia en los términos que fija esa Ley.</p>
                      <p><b>DERECHOS ARCO Y REVOCACIÓN DEL CONSENTIMIENTO.</b></p>
                      <p>
                          En todo momento el Usuario tiene derecho de acceder, rectificar y cancelar sus datos
                          personales, así
                          como de oponerse al tratamiento de los mismos o revocar el consentimiento que nos ha
                          otorgado, a fin
                          de que dejemos de hacer uso de ellos; para lo cual deberá solicitar nuestro formato de
                          “Solicitud de
                          Ejercicio de Derechos ARCO (Acceso, Rectificación, Cancelación y Oposición)” ante nuestro
                          Departamento
                          de Datos Personales enviando un correo a la dirección: contacto@gesco.mx con el asunto
                          “Formato ARCO”
                          y con gusto le enviaremos el formato correspondiente para que sea debidamente llenado, y nos
                          sea enviado
                          de vuelta por la misma vía. Para dar trámite a la solicitud, el Usuario deberá adjuntar
                          copia de su
                          documento de identificación vigente (credencial para votar, pasaporte emitido por la
                          Secretaría de
                          Relaciones Exteriores, cartilla del Servicio Militar Nacional o cédula profesional) y
                          exhibir el original
                          para su cotejo, a fin de que pueda llevarse a cabo la autenticación de quien ejerce el
                          Derecho ARCO. En
                          los casos en que el ejercicio de los Derechos ARCO se realice a través de su representante
                          legal, además
                          de la acreditación de la identidad de ambos, se deberá entregar el poder notarial
                          correspondiente, carta
                          poder firmada ante dos testigos o declaración en comparecencia personal de usted. Cuando se
                          quiera ejercer
                          el derecho de rectificación, se tendrá que entregar la documentación que acredite el cambio
                          solicitado
                          de acuerdo a los datos personales a rectificar La respuesta a su solicitud se llevará a cabo
                          dentro de
                          los 20 días hábiles siguientes contados a partir de la fecha en que la misma haya sido
                          recibida. “Los
                          responsables” podrán ampliar este plazo hasta por 20 días hábiles más, cuando el caso lo
                          amerite, previa
                          notificación de esto a usted. La resolución adoptada por “Los responsables” será comunicada
                          a usted a
                          través de la opción que elija de las que se encuentran establecidas en el formato de
                          Solicitud de Ejercicio
                          de Derechos ARCO. En aquellos casos en los que se desee ejercer el derecho de Acceso a sus
                          Datos Personales,
                          la información solicitada será proporcionada a través del medio que usted haya decidido en
                          el formato de
                          “Solicitud de Ejercicio de Derechos ARCO” y en el supuesto en el que “El responsable” no
                          cuente con sus
                          datos personales, se lo informaremos por los medios a través de los cuáles realizó la
                          solicitud. Para
                          cualquier duda o aclaración respecto del presente procedimiento favor de enviar un correo a
                          nuestro
                          Departamento de Datos Personales a las siguientes direcciones: contacto@gesco.mx y
                          agueda.ale.gestiones@gmail.com
                          y con gusto lo atenderemos. </p>
                      <p><b>OPCIONES Y MEDIOS PARA LIMITAR EL USO DE SUS DATOS PERSONALES.</b></p>
                      <p>Para limitar el uso o divulgación de sus datos, para no recibir promociones de nuestros
                          productos y/o
                          servicios, puede solicitar a nuestro Departamento de Datos Personales a través del correo
                          electrónico
                          que se indica en el párrafo anterior, la inscripción gratuita en nuestros listados internos
                          de exclusión,
                          indicándonos su nombre completo, número(s) telefónico(s) y acreditar su identidad en los
                          mismos términos
                          señalados para el ejercicio de sus Derechos ARCO.</p>
                      <p><b>MODIFICACIONES AL AVISO DE PRIVACIDAD.</b></p>
                      <p>
                          Nos reservamos el derecho de efectuar en cualquier momento modificaciones o actualizaciones
                          al presente
                          aviso de privacidad, para la atención de novedades legislativas, políticas internas o nuevos
                          requerimientos
                          para la prestación u ofrecimiento de nuestros productos y servicios. En tal caso, “Los
                          Responsables”
                          publicarán dichas modificaciones en su sitio de internet www.gesco.mx. De cualquier manera,
                          le recomendamos
                          que revise nuestro aviso de privacidad cada vez que haga uso de nuestra página de Internet,
                          a efecto de
                          conocer los cambios, modificaciones o actualizaciones que se realicen al mismo.
                      </p>
                      <p>
                          Revisamos regularmente este Aviso de Privacidad. La última versión fue actualizada en abril
                          de 2023.
                      </p>
                  </span>
              </ng-container>
              <ng-container *ngSwitchCase="'79acf91235e1f1b5395e58619078f113'">
                  <p>
                      La Oficina de Atención Ciudadana de la Regidora Águeda Ale Valdes, a través de la persona moral
                      ExprésateCon S.A. de C.V.,
                      y sus filiales y/o subsidiarias recabarán los datos personales que Usted proporcione, estos
                      estarán protegidos en términos
                      de lo que dispone la Ley Federal de Protección de Datos Personales en Posesión de Particulares y
                      su Reglamento. Para conocer
                      el contenido completo del Aviso de Privacidad puede consultar el siguiente sitio de internet:
                      www.gesco.mx<br>
                      <span class="more" (click)="toggleText()">{{ isReadMore ? 'Leer más...' : 'Leer menos...'
                          }}</span>
                  </p>
                  <span class="complete">
                      <p><b>AVISO DE PRIVACIDAD </b></p>
                      <p>Cuidamos de tus datos personales al igual que nos preocupa tu seguridad, la protección de tu
                          privacidad y de tus datos
                          personales también es una prioridad para nosotros. Este Aviso de Privacidad explica qué tipo
                          de datos personales solicitamos
                          o recabamos, cómo, para qué fines y con quién pueden ser compartidos o revelados.
                          <br>
                          De conformidad con lo establecido en la Ley Federal de Protección de Datos Personales en
                          Posesión de los Particulares,
                          en lo sucesivo se denominará “La Ley”, en concordancia con su reglamento, hacemos de
                          conocimiento al “Usuario” que la
                          Oficina de Atención Ciudadana de la Regidora del Municipio de Monterrey, N. L. Águeda Ale
                          Valdés, ubicada en: C. Ignacio
                          Zaragoza Sur S/N, 1er piso Regidores, Centro, 64000 Monterrey, N.L, a través de la persona
                          moral ExprésateCon S.A. de
                          C.V., sus filiales y/o subsidiarias, con domicilio en Av. Paseo de la Reforma, Núm. 180,
                          Piso 12, Col. Juárez, Alc.
                          Cuauhtémoc, C.P. 06600, Ciudad de México; que en lo sucesivo se conocerán como los
                          “Responsables”.
                          <br>
                          Recabarán y mantendrán los datos personales que el Usuario proporcione en los términos del
                          presente Aviso. Asegurando
                          que los datos personales se encuentren resguardados bajo estrictas MEDIDAS DE SEGURIDAD
                          ADMINISTRATIVAS, TÉCNICAS y
                          FÍSICAS las cuales han sido implementadas, con el objeto de preservar y garantizar sus datos
                          personales contra daño,
                          pérdida, alteración, destrucción o el uso, acceso o tratamiento no autorizados.

                      </p>
                      <p><b>¿PARA QUÉ FINES RECABAMOS Y UTILIZAMOS SUS DATOS PERSONALES?</b></p>
                      <p>Los datos personales serán utilizados con las siguientes finalidades, mismas que podrán ser
                          utilizadas de manera
                          individual o bien en su conjunto, siendo:
                          <br>
                          • Finalidades primarias: (a) administrar, almacenar, gestionar, dar seguimiento, controlar y
                          mapear las solicitudes
                          realizadas por la ciudadanía o las propias. (b) Atender solicitudes de Derechos ARCO
                          (Acceso, Rectificación,
                          Cancelación u Oposición).
                          <br>
                          • Finalidades secundarias: Brindar la atención y seguimiento correspondiente a sus dudas,
                          quejas y/o aclaraciones sobre nuestros servicios.
                      </p>
                      <p><b>¿CÓMO OBTENEMOS SUS DATOS PERSONALES?</b></p>
                      <p>Para llevar a cabo las finalidades señaladas en el presente aviso de privacidad, se podrán
                          recabar los datos
                          personales de diferentes maneras; cuando el Usuario los proporciona ya sea de manera física,
                          vía telefónica,
                          digital o mediante alguna aplicación tecnológica de comunicación; así como cuando vacía sus
                          datos a través
                          del formato de registro gesco.mx, nuestro sitio de Internet en la sección de “Contacto”;
                          cuando envíe un
                          correo electrónico; a través de fuentes de acceso público y/o a través de otras fuentes
                          permitidas
                          previstas en el artículo 10 de la “Ley”.</p>
                      <p> <b>DATOS PERSONALES QUE PODRÁN SER RECABADOS Y TRATADOS.</b></p>
                      <p>Los datos personales a recabar son: nombre completo; apellidos, fecha de nacimiento, sexo,
                          CURP, estado civil,
                          ocupación, domicilio completo, número de teléfono fijo y/o celular, correo electrónico; en
                          caso de que aplique,
                          cargo o función que desempeña. Así como documentos que se llegarán a solicitar en caso de
                          realizar algún trámite.</p>
                      <p><b>TRANSFERENCIA DE DATOS PERSONALES.</b></p>
                      <p> Los Responsables se comprometen a no transferir la información personal a terceros sin su
                          previo consentimiento,
                          salvo las excepciones previstas en el artículo 37 de la “Ley” y el artículo 38 de su
                          Reglamento, así como a
                          realizar esta transferencia en los términos que fija esa Ley.</p>
                      <p><b>DERECHOS ARCO Y REVOCACIÓN DEL CONSENTIMIENTO.</b></p>
                      <p>En todo momento el Usuario tiene derecho de acceder, rectificar y cancelar sus datos
                          personales, así como de oponerse
                          al tratamiento de los mismos o revocar el consentimiento que nos ha otorgado, a fin de que
                          dejemos de hacer uso
                          de ellos; para lo cual deberá solicitar nuestro formato de “Solicitud de Ejercicio de
                          Derechos ARCO
                          (Acceso, Rectificación, Cancelación y Oposición)” ante nuestro Departamento de Datos
                          Personales enviando un
                          correo a la dirección: contacto@gesco.mx con el asunto “Formato ARCO” y con gusto le
                          enviaremos el formato
                          correspondiente para que sea debidamente llenado, y nos sea enviado de vuelta por la misma
                          vía. Para dar trámite
                          a la solicitud, el Usuario deberá adjuntar copia de su documento de identificación vigente
                          (credencial para votar, pasaporte emitido por la Secretaría de Relaciones Exteriores,
                          cartilla del Servicio
                          Militar Nacional o cédula profesional) y exhibir el original para su cotejo, a fin de que
                          pueda llevarse a
                          cabo la autenticación de quien ejerce el Derecho ARCO. En los casos en que el ejercicio de
                          los Derechos ARCO
                          se realice a través de su representante legal, además de la acreditación de la identidad de
                          ambos, se deberá
                          entregar el poder notarial correspondiente, carta poder firmada ante dos testigos o
                          declaración en comparecencia
                          personal de usted. Cuando se quiera ejercer el derecho de rectificación, se tendrá que
                          entregar la documentación
                          que acredite el cambio solicitado de acuerdo a los datos personales a rectificar La
                          respuesta a su solicitud
                          se llevará a cabo dentro de los 20 días hábiles siguientes contados a partir de la fecha en
                          que la misma haya
                          sido recibida. “Los Responsables” podrán ampliar este plazo hasta por 20 días hábiles más,
                          cuando el caso lo
                          amerite, previa notificación de esto a usted. La resolución adoptada por “Los Responsables”
                          será comunicada a
                          usted a través de la opción que elija de las que se encuentran establecidas en el formato de
                          Solicitud de
                          Ejercicio de Derechos ARCO. En aquellos casos en los que se desee ejercer el derecho de
                          Acceso a sus Datos
                          Personales, la información solicitada será proporcionada a través del medio que usted haya
                          decidido en el
                          formato de “Solicitud de Ejercicio de Derechos ARCO” y en el supuesto en el que “El
                          Responsable” no cuente
                          con sus datos personales, se lo informaremos por los medios a través de los cuáles realizó
                          la solicitud.
                          Para cualquier duda o aclaración respecto del presente procedimiento favor de enviar un
                          correo a nuestro
                          Departamento de Datos Personales a las siguientes direcciones: contacto@gesco.mx y
                          agueda.ale.gestiones@gmail.com
                          y con gusto lo atenderemos.
                      </p>
                      <p><b>OPCIONES Y MEDIOS PARA LIMITAR EL USO DE SUS DATOS PERSONALES.</b></p>
                      <p>Para limitar el uso o divulgación de sus datos, para no recibir promociones de nuestros
                          productos y/o servicios,
                          puede solicitar a nuestro Departamento de Datos Personales a través del correo electrónico
                          que se indica
                          en el párrafo anterior, la inscripción gratuita en nuestros listados internos de exclusión,
                          indicándonos su
                          nombre completo, número(s) telefónico(s) y acreditar su identidad en los mismos términos
                          señalados
                          para el ejercicio de sus Derechos ARCO.</p>
                      <p><b>MODIFICACIONES AL AVISO DE PRIVACIDAD.</b></p>
                      <p>Nos reservamos el derecho de efectuar en cualquier momento modificaciones o actualizaciones
                          al presente aviso de
                          privacidad, para la atención de novedades legislativas, políticas internas o nuevos
                          requerimientos para la
                          prestación u ofrecimiento de nuestros productos y servicios. En tal caso, “Los Responsables”
                          publicaran
                          dichas modificaciones en su sitio de internet www.gesco.mx. De cualquier manera, le
                          recomendamos que revise
                          nuestro aviso de privacidad cada vez que haga uso de nuestra página de Internet, a efecto de
                          conocer los
                          cambios, modificaciones o actualizaciones que se realicen al mismo.</p>
                      <p>
                          Revisamos regularmente este Aviso de Privacidad. La última versión fue actualizada en abril
                          de 2023.
                      </p>
                  </span>
              </ng-container>
              <ng-container *ngSwitchCase="'d41d8cd98f00b204e9800998ecf8427e'">
                  <p>
                  <p>
                      La Oficina de Atención Ciudadana de la Diputada Local Daniela de los Santos Torres, a través de
                      la persona moral
                      ExprésateCon S.A. de C.V., y sus filiales y/o subsidiarias recabarán los datos personales que
                      Usted proporcione,
                      estos estarán protegidos en términos de lo que dispone la Ley Federal de Protección de Datos
                      Personales en Posesión
                      de Particulares y su Reglamento. Para conocer el contenido completo del Aviso de Privacidad
                      puede consultar el
                      siguiente sitio de internet: www.gesco.mx
                      <br>
                      <span class="more" (click)="toggleText()">{{ isReadMore ? 'Leer más...' : 'Leer menos...'
                          }}</span>
                  </p>
                  <span class="complete">
                      <p><b>AVISO DE PRIVACIDAD</b></p>
                      <p>Cuidamos de tus datos personales al igual que nos preocupa tu seguridad, la protección de tu
                          privacidad y de tus
                          datos personales también es una prioridad para nosotros. Este Aviso de Privacidad explica
                          qué tipo de datos
                          personales solicitamos o recabamos, cómo, para qué fines y con quién pueden ser compartidos
                          o revelados.
                          <br>
                          De conformidad con lo establecido en la Ley Federal de Protección de Datos Personales en
                          Posesión de los Particulares,
                          en lo sucesivo se denominará “La Ley”, en concordancia con su reglamento, hacemos de
                          conocimiento al “Usuario” que
                          la Oficina de Atención Ciudadana del Diputada Local Daniela de los Santos Torres, ubicada
                          en: Av. Francisco I. Madero
                          Ote 97, Centro Histórico de Morelia, C. P. 58000 Morelia, Michoacán, a través de la persona
                          moral ExprésateCon S.A.
                          de C.V., sus filiales y/o subsidiarias, con domicilio en Av. Paseo de la Reforma, Núm. 180,
                          Piso 12, Col. Juárez, Alc.
                          Cuauhtémoc, C.P. 06600, Ciudad de México; que en lo sucesivo se conocerán como los
                          “Responsables”.
                          <br>
                          Recabarán y mantendrán los datos personales que el Usuario proporcione en los términos del
                          presente Aviso. Asegurando
                          que los datos personales se encuentren resguardados bajo estrictas MEDIDAS DE SEGURIDAD
                          ADMINISTRATIVAS, TÉCNICAS y
                          FÍSICAS las cuales han sido implementadas, con el objeto de preservar y garantizar sus datos
                          personales contra daño,
                          pérdida, alteración, destrucción o el uso, acceso o tratamiento no autorizados.
                      </p>
                      <p><b>¿PARA QUÉ FINES RECABAMOS Y UTILIZAMOS SUS DATOS PERSONALES?</b></p>
                      <p>Los datos personales serán utilizados con las siguientes finalidades, mismas que podrán ser
                          utilizadas de manera
                          individual o bien en su conjunto, siendo:
                          <br>
                          • Finalidades primarias: (a) administrar, almacenar, gestionar, dar seguimiento, controlar y
                          mapear las solicitudes
                          realizadas por la ciudadanía o las propias. (b) Atender solicitudes de Derechos ARCO
                          (Acceso, Rectificación,
                          Cancelación u Oposición).
                          <br>
                          • Finalidades secundarias: Brindar la atención y seguimiento correspondiente a sus dudas,
                          quejas y/o aclaraciones sobre nuestros servicios.
                      </p>
                      <p><b>¿CÓMO OBTENEMOS SUS DATOS PERSONALES?</b></p>
                      <p>Para llevar a cabo las finalidades señaladas en el presente aviso de privacidad, se podrán
                          recabar los datos
                          personales de diferentes maneras; cuando el Usuario los proporciona ya sea de manera física,
                          vía telefónica,
                          digital o mediante alguna aplicación tecnológica de comunicación; así como cuando vacía sus
                          datos a través
                          del formato de registro gesco.mx, nuestro sitio de Internet en la sección de “Contacto”;
                          cuando envíe un
                          correo electrónico; a través de fuentes de acceso público y/o a través de otras fuentes
                          permitidas
                          previstas en el artículo 10 de la “Ley”.</p>
                      <p> <b>DATOS PERSONALES QUE PODRÁN SER RECABADOS Y TRATADOS.</b></p>
                      <p>Los datos personales a recabar son: nombre completo; apellidos, fecha de nacimiento, sexo,
                          CURP, estado civil,
                          ocupación, domicilio completo, número de teléfono fijo y/o celular, correo electrónico; en
                          caso de que aplique,
                          cargo o función que desempeña. Así como documentos que se llegarán a solicitar en caso de
                          realizar algún trámite.</p>
                      <p><b>TRANSFERENCIA DE DATOS PERSONALES.</b></p>
                      <p> Los Responsables se comprometen a no transferir la información personal a terceros sin su
                          previo consentimiento,
                          salvo las excepciones previstas en el artículo 37 de la “Ley” y el artículo 38 de su
                          Reglamento, así como a
                          realizar esta transferencia en los términos que fija esa Ley.</p>
                      <p><b>DERECHOS ARCO Y REVOCACIÓN DEL CONSENTIMIENTO.</b></p>
                      <p>En todo momento el Usuario tiene derecho de acceder, rectificar y cancelar sus datos
                          personales, así como de oponerse
                          al tratamiento de los mismos o revocar el consentimiento que nos ha otorgado, a fin de que
                          dejemos de hacer uso
                          de ellos; para lo cual deberá solicitar nuestro formato de “Solicitud de Ejercicio de
                          Derechos ARCO
                          (Acceso, Rectificación, Cancelación y Oposición)” ante nuestro Departamento de Datos
                          Personales enviando un
                          correo a la dirección: contacto@gesco.mx con el asunto “Formato ARCO” y con gusto le
                          enviaremos el formato
                          correspondiente para que sea debidamente llenado, y nos sea enviado de vuelta por la misma
                          vía. Para dar trámite
                          a la solicitud, el Usuario deberá adjuntar copia de su documento de identificación vigente
                          (credencial para votar, pasaporte emitido por la Secretaría de Relaciones Exteriores,
                          cartilla del Servicio
                          Militar Nacional o cédula profesional) y exhibir el original para su cotejo, a fin de que
                          pueda llevarse a
                          cabo la autenticación de quien ejerce el Derecho ARCO. En los casos en que el ejercicio de
                          los Derechos ARCO
                          se realice a través de su representante legal, además de la acreditación de la identidad de
                          ambos, se deberá
                          entregar el poder notarial correspondiente, carta poder firmada ante dos testigos o
                          declaración en comparecencia
                          personal de usted. Cuando se quiera ejercer el derecho de rectificación, se tendrá que
                          entregar la documentación
                          que acredite el cambio solicitado de acuerdo a los datos personales a rectificar La
                          respuesta a su solicitud
                          se llevará a cabo dentro de los 20 días hábiles siguientes contados a partir de la fecha en
                          que la misma haya
                          sido recibida. “Los Responsables” podrán ampliar este plazo hasta por 20 días hábiles más,
                          cuando el caso lo
                          amerite, previa notificación de esto a usted. La resolución adoptada por “Los Responsables”
                          será comunicada a
                          usted a través de la opción que elija de las que se encuentran establecidas en el formato de
                          Solicitud de
                          Ejercicio de Derechos ARCO. En aquellos casos en los que se desee ejercer el derecho de
                          Acceso a sus Datos
                          Personales, la información solicitada será proporcionada a través del medio que usted haya
                          decidido en el
                          formato de “Solicitud de Ejercicio de Derechos ARCO” y en el supuesto en el que “El
                          Responsable” no cuente
                          con sus datos personales, se lo informaremos por los medios a través de los cuáles realizó
                          la solicitud.
                          Para cualquier duda o aclaración respecto del presente procedimiento favor de enviar un
                          correo a nuestro
                          Departamento de Datos Personales a la siguiente dirección: contacto@gesco.mx con gusto lo
                          atenderemos.
                      </p>
                      <p><b>OPCIONES Y MEDIOS PARA LIMITAR EL USO DE SUS DATOS PERSONALES.</b></p>
                      <p>Para limitar el uso o divulgación de sus datos, para no recibir promociones de nuestros
                          productos y/o servicios,
                          puede solicitar a nuestro Departamento de Datos Personales a través del correo electrónico
                          que se indica
                          en el párrafo anterior, la inscripción gratuita en nuestros listados internos de exclusión,
                          indicándonos su
                          nombre completo, número(s) telefónico(s) y acreditar su identidad en los mismos términos
                          señalados
                          para el ejercicio de sus Derechos ARCO.</p>
                      <p><b>MODIFICACIONES AL AVISO DE PRIVACIDAD.</b></p>
                      <p>Nos reservamos el derecho de efectuar en cualquier momento modificaciones o actualizaciones
                          al presente aviso de
                          privacidad, para la atención de novedades legislativas, políticas internas o nuevos
                          requerimientos para la
                          prestación u ofrecimiento de nuestros productos y servicios. En tal caso, “Los Responsables”
                          publicaran
                          dichas modificaciones en su sitio de internet www.gesco.mx. De cualquier manera, le
                          recomendamos que revise
                          nuestro aviso de privacidad cada vez que haga uso de nuestra página de Internet, a efecto de
                          conocer los
                          cambios, modificaciones o actualizaciones que se realicen al mismo.</p>
                      <p>
                          Revisamos regularmente este Aviso de Privacidad. La última versión fue actualizada en abril
                          de 2023.
                      </p>
                  </span>
              </ng-container>
              <ng-container *ngSwitchCase="'57d58eb28773276c0a9b963e7d7bc59a'">
                  <p>
                      La Oficina de Atención Ciudadana de la Diputada Federal Cecilia Patrón Laviada, a través de la
                      persona moral
                      ExprésateCon S.A. de C.V., y sus filiales y/o subsidiarias recabarán los datos personales que
                      Usted proporcione,
                      estos estarán protegidos en términos de lo que dispone la Ley Federal de Protección de Datos
                      Personales en
                      Posesión de Particulares y su Reglamento. Para conocer el contenido completo del Aviso de
                      Privacidad puede
                      consultar el siguiente sitio de internet: www.gesco.mx
                      <br>
                      <span class="more" (click)="toggleText()">{{ isReadMore ? 'Leer más...' : 'Leer menos...'
                          }}</span>
                  </p>
                  <span class="complete">
                      <p><b>AVISO DE PRIVACIDAD</b></p>
                      <p>
                          Cuidamos de tus datos personales al igual que nos preocupa tu seguridad, la protección de tu
                          privacidad y de
                          tus datos personales también es una prioridad para nosotros. Este Aviso de Privacidad
                          explica qué tipo de datos
                          personales solicitamos o recabamos, cómo, para qué fines y con quién pueden ser compartidos
                          o revelados.
                          <br>
                          De conformidad con lo establecido en la Ley Federal de Protección de Datos Personales en
                          Posesión de los Particulares,
                          en lo sucesivo se denominará “La Ley”, en concordancia con su reglamento, hacemos de
                          conocimiento al “Usuario”
                          que la Oficina de Atención Ciudadana de la Diputada Federal Cecilia Patrón Laviada, ubicada
                          en: calle 23 #201 X
                          Av. Universidad Pedagógica Fracc. Vergel II C. P. 97173, Mérida Yucatán, a través de la
                          persona moral ExprésateCon
                          S.A. de C.V., sus filiales y/o subsidiarias, con domicilio en Av. Paseo de la Reforma, Núm.
                          180, Piso 12, Col. Juárez,
                          Alc. Cuauhtémoc, C.P. 06600, Ciudad de México; que en lo sucesivo se conocerán como los
                          “Responsables”.
                          <br>
                          Recabarán y mantendrán los datos personales que el Usuario proporcione en los términos del
                          presente Aviso. Asegurando
                          que los datos personales se encuentren resguardados bajo estrictas MEDIDAS DE SEGURIDAD
                          ADMINISTRATIVAS, TÉCNICAS y
                          FÍSICAS las cuales han sido implementadas, con el objeto de preservar y garantizar sus datos
                          personales contra daño,
                          pérdida, alteración, destrucción o el uso, acceso o tratamiento no autorizados.
                      </p>
                      <p><b>¿PARA QUÉ FINES RECABAMOS Y UTILIZAMOS SUS DATOS PERSONALES?</b></p>
                      <p>Los datos personales serán utilizados con las siguientes finalidades, mismas que podrán ser
                          utilizadas de manera
                          individual o bien en su conjunto, siendo:
                          <br>
                          • Finalidades primarias: (a) administrar, almacenar, gestionar, dar seguimiento, controlar y
                          mapear las solicitudes
                          realizadas por la ciudadanía o las propias. (b) Atender solicitudes de Derechos ARCO
                          (Acceso, Rectificación,
                          Cancelación u Oposición).
                          <br>
                          • Finalidades secundarias: Brindar la atención y seguimiento correspondiente a sus dudas,
                          quejas y/o aclaraciones sobre nuestros servicios.
                      </p>
                      <p><b>¿CÓMO OBTENEMOS SUS DATOS PERSONALES?</b></p>
                      <p>Para llevar a cabo las finalidades señaladas en el presente aviso de privacidad, se podrán
                          recabar los datos
                          personales de diferentes maneras; cuando el Usuario los proporciona ya sea de manera física,
                          vía telefónica,
                          digital o mediante alguna aplicación tecnológica de comunicación; así como cuando vacía sus
                          datos a través
                          del formato de registro gesco.mx, nuestro sitio de Internet en la sección de “Contacto”;
                          cuando envíe un
                          correo electrónico; a través de fuentes de acceso público y/o a través de otras fuentes
                          permitidas
                          previstas en el artículo 10 de la “Ley”.</p>
                      <p> <b>DATOS PERSONALES QUE PODRÁN SER RECABADOS Y TRATADOS.</b></p>
                      <p>Los datos personales a recabar son: nombre completo; apellidos, fecha de nacimiento, sexo,
                          CURP, estado civil,
                          ocupación, domicilio completo, número de teléfono fijo y/o celular, correo electrónico; en
                          caso de que aplique,
                          cargo o función que desempeña. Así como documentos que se llegarán a solicitar en caso de
                          realizar algún trámite.</p>
                      <p><b>TRANSFERENCIA DE DATOS PERSONALES.</b></p>
                      <p> Los Responsables se comprometen a no transferir la información personal a terceros sin su
                          previo consentimiento,
                          salvo las excepciones previstas en el artículo 37 de la “Ley” y el artículo 38 de su
                          Reglamento, así como a
                          realizar esta transferencia en los términos que fija esa Ley.</p>
                      <p><b>DERECHOS ARCO Y REVOCACIÓN DEL CONSENTIMIENTO.</b></p>
                      <p>
                          En todo momento el Usuario tiene derecho de acceder, rectificar y cancelar sus datos
                          personales, así como de
                          oponerse al tratamiento de los mismos o revocar el consentimiento que nos ha otorgado, a fin
                          de que dejemos de
                          hacer uso de ellos; para lo cual deberá solicitar nuestro formato de “Solicitud de Ejercicio
                          de Derechos ARCO
                          (Acceso, Rectificación, Cancelación y Oposición)” ante nuestro Departamento de Datos
                          Personales enviando un correo
                          a la dirección: contacto@gesco.mx con el asunto “Formato ARCO” y con gusto le enviaremos el
                          formato correspondiente
                          para que sea debidamente llenado, y nos sea enviado de vuelta por la misma vía. Para dar
                          trámite a la solicitud,
                          el Usuario deberá adjuntar copia de su documento de identificación vigente (credencial para
                          votar, pasaporte emitido
                          por la Secretaría de Relaciones Exteriores, cartilla del Servicio Militar Nacional o cédula
                          profesional) y exhibir
                          el original para su cotejo, a fin de que pueda llevarse a cabo la autenticación de quien
                          ejerce el Derecho ARCO.
                          En los casos en que el ejercicio de los Derechos ARCO se realice a través de su
                          representante legal, además de la
                          acreditación de la identidad de ambos, se deberá entregar el poder notarial correspondiente,
                          carta poder firmada
                          ante dos testigos o declaración en comparecencia personal de usted. Cuando se quiera ejercer
                          el derecho de rectificación,
                          se tendrá que entregar la documentación que acredite el cambio solicitado de acuerdo a los
                          datos personales a rectificar
                          La respuesta a su solicitud se llevará a cabo dentro de los 20 días hábiles siguientes
                          contados a partir de la fecha en
                          que la misma haya sido recibida. “Los responsables” podrán ampliar este plazo hasta por 20
                          días hábiles más, cuando el
                          caso lo amerite, previa notificación de esto a usted. La resolución adoptada por “Los
                          responsables” será comunicada a
                          usted a través de la opción que elija de las que se encuentran establecidas en el formato de
                          Solicitud de Ejercicio de
                          Derechos ARCO. En aquellos casos en los que se desee ejercer el derecho de Acceso a sus
                          Datos Personales, la información
                          solicitada será proporcionada a través del medio que usted haya decidido en el formato de
                          “Solicitud de Ejercicio de Derechos
                          ARCO” y en el supuesto en el que “El responsable” no cuente con sus datos personales, se lo
                          informaremos por los medios a
                          través de los cuáles realizó la solicitud. Para cualquier duda o aclaración respecto del
                          presente procedimiento favor de
                          enviar un correo a nuestro Departamento de Datos Personales a las siguientes direcciones:
                          contacto@gesco.mx y casadeenlacecpl@gmail.com
                          y con gusto lo atenderemos.
                      </p>
                      <p><b>OPCIONES Y MEDIOS PARA LIMITAR EL USO DE SUS DATOS PERSONALES.</b></p>
                      <p>
                          Para limitar el uso o divulgación de sus datos, para no recibir promociones de nuestros
                          productos y/o servicios, puede
                          solicitar a nuestro Departamento de Datos Personales a través del correo electrónico que se
                          indica en el párrafo anterior,
                          la inscripción gratuita en nuestros listados internos de exclusión, indicándonos su nombre
                          completo, número(s) telefónico(s)
                          y acreditar su identidad en los mismos términos señalados para el ejercicio de sus Derechos
                          ARCO.
                      </p>
                      <p><b>MODIFICACIONES AL AVISO DE PRIVACIDAD.</b></p>
                      <p>
                          Nos reservamos el derecho de efectuar en cualquier momento modificaciones o actualizaciones
                          al presente aviso de privacidad,
                          para la atención de novedades legislativas, políticas internas o nuevos requerimientos para
                          la prestación u ofrecimiento de
                          nuestros productos y servicios. En tal caso, “Los Responsables” publicarán dichas
                          modificaciones en su sitio de internet
                          www.gesco.mx. De cualquier manera, le recomendamos que revise nuestro aviso de privacidad
                          cada vez que haga uso de nuestra
                          página de Internet, a efecto de conocer los cambios, modificaciones o actualizaciones que se
                          realicen al mismo.
                      </p>
                      <p>
                          Revisamos regularmente este Aviso de Privacidad. La última versión fue actualizada en abril
                          de 2023.
                      </p>
                  </span>
              </ng-container>
              <ng-container *ngSwitchDefault>
                  <p>
                  <p><b>AVISO DE PRIVACIDAD INTEGRAL RESPONSABLE DEL TRATAMIENTO DE SUS DATOS PERSONALES.</b></p>
                  <p>De conformidad con lo establecido en la Ley Federal de Protección de Datos Personales en Posesión
                      de los Particulares (en lo sucesivo la “Ley”) y su Reglamento le informamos que
                      ExprésateCon S.A. de C.V., sus filiales y/o subsidiarias (en lo sucesivo el “Responsable”), con
                      domicilio en Avenida Paseo de la Reforma, No. 180, piso 12, Col. Juárez, Del. Cuauhtémoc,
                      C.P. 06600, Ciudad de México, recabará los datos personales alojados en la plataforma en
                      términos del presente Aviso de Privacidad (en lo sucesivo “Aviso”). MEDIDAS DE SEGURIDAD. Los
                      datos personales se encuentran resguardados bajo estrictas medidas de seguridad administrativas,
                      técnicas y físicas las cuales han sido implementadas, en términos del Reglamento, con
                      el objeto de preservar y garantizar los datos personales contra daño, pérdida, alteración,
                      destrucción o el uso, acceso o tratamiento no autorizados.</p>
                  <p>Los datos personales podrán ser utilizados para llevar a cabo alguna o todas las finalidades
                      siguientes: • Finalidades primarias: (a) administrar, almacenar, gestionar, dar seguimiento,
                      controlar y mapear las solicitudes realizadas por la ciudadanía o las propias. (b) Atender
                      solicitudes de Derechos ARCO (Acceso, Rectificación, Cancelación y Oposición). • Finalidades
                      secundarias: Brindar la atención y seguimiento correspondiente a sus dudas, quejas y/o
                      aclaraciones sobre nuestros servicios.</p>
                  <p><b>¿CÓMO OBTENEMOS SUS DATOS PERSONALES?</b></p>
                  <p>Para las finalidades señaladas en este aviso de privacidad, podemos recabar datos personales
                      cuando nos sean proporcionados mediante el ingreso de los mismos a la plataforma Gesco, Gesco
                      App o SolicitApp y/o a través de otras fuentes permitidas previstas en el artículo 10 “Ley”.</p>
                  <p><b>DATOS PERSONALES QUE PODRÁN SER RECABADOS Y TRATADOS.</b></p>
                  <p>Los datos personales que se recabarán son: nombre completo; apellidos, fecha de nacimiento, sexo,
                      CURP, estado civil, ocupación, domicilio completo, número de teléfono fijo y/o celular,
                      correo electrónico; en caso de que aplique, cargo o función que desempeña. Así como documentos
                      que se llegarán a solicitar en caso de realizar algún trámite.</p>
                  <p><b>TRANSFERENCIA DE DATOS PERSONALES.</b></p>
                  <p>Nos comprometemos a no transferir la información personal a terceros sin su previo
                      consentimiento, salvo las excepciones previstas en el artículo 37 de la “Ley” y el artículo 38
                      de su
                      Reglamento, así como a realizar esta transferencia en los términos que fija esa Ley.</p>
                  <p><b>DERECHOS ARCO Y REVOCACIÓN DEL CONSENTIMIENTO.</b></p>
                  <p>En todo momento la persona dueña de los datos personales tiene derecho de acceder, rectificar y
                      cancelar sus datos, así como de oponerse al tratamiento de los mismos o revocar el
                      consentimiento que nos ha otorgado, a fin de que dejemos de hacer uso de ellos; para lo cual
                      deberá solicitar nuestro formato de “Solicitud de Ejercicio de Derechos ARCO (Acceso,
                      Rectificación, Cancelación y Oposición)” ante nuestro Departamento de Datos Personales enviando
                      un correo a la dirección: contacto@gesco.mx con el asunto “Formato ARCO” y con gusto
                      le enviaremos el formato correspondiente para que sea debidamente llenado, y nos sea enviado de
                      vuelta por la misma vía. Para dar trámite a su solicitud, deberá adjuntar copia de su
                      documento de identificación vigente (credencial para votar, pasaporte emitido por la Secretaría
                      de Relaciones Exteriores, cartilla del Servicio Militar Nacional o cédula profesional)
                      y exhibir el original para su cotejo, a fin de que pueda llevarse a cabo la autenticación de
                      quien ejerce el Derecho ARCO. En los casos en que el ejercicio de los Derechos ARCO se realice
                      a través de su representante legal, además de la acreditación de la identidad de ambos, se
                      deberá entregar el poder notarial correspondiente, carta poder firmada ante dos testigos o
                      declaración en comparecencia personal de usted. Cuando se quiera ejercer el derecho de
                      rectificación, se tendrá que entregar la documentación que acredite el cambio solicitado de
                      acuerdo
                      a los datos personales a rectificar La respuesta a su solicitud se llevará a cabo dentro de los
                      20 días hábiles siguientes contados a partir de la fecha en que la misma haya sido recibida.
                      “El responsable” podrá ampliar este plazo hasta por 20 días hábiles más, cuando el caso lo
                      amerite, previa notificación de esto a usted. La resolución adoptada por “El responsable” será
                      comunicada a usted a través de la opción que elija de las que se encuentran establecidas en el
                      formato de Solicitud de Ejercicio de Derechos ARCO. En aquellos casos en los que se desee
                      ejercer el derecho de Acceso a sus Datos Personales, la información solicitada será
                      proporcionada a través del medio que usted haya decidido en el formato de “Solicitud de
                      Ejercicio de
                      Derechos ARCO” y en el supuesto en el que “El responsable” no cuente con sus datos personales,
                      se lo informaremos por los medios a través de los cuáles realizó la solicitud. Para cualquier
                      duda o aclaración respecto del presente procedimiento favor de enviar un correo a nuestro
                      Departamento de Datos Personales a la siguiente dirección: contacto@gesco.mx y con gusto lo
                      atenderemos.</p>
                  <p><b>OPCIONES Y MEDIOS PARA LIMITAR EL USO DE SUS DATOS PERSONALES.</b></p>
                  <p>Para limitar el uso o divulgación de datos, para no recibir promociones de nuestros productos y/o
                      servicios, puede solicitar a nuestro Departamento de Datos Personales a través del correo
                      electrónico que se indica en el párrafo anterior, la inscripción gratuita en nuestros listados
                      internos de exclusión, indicándonos su nombre completo, número(s) telefónico(s) y acreditar
                      su identidad en los mismos términos señalados para el ejercicio de sus Derechos ARCO.</p>
                  <p><b>MODIFICACIONES AL AVISO DE PRIVACIDAD.</b></p>
                  <p>Nos reservamos el derecho de efectuar en cualquier momento modificaciones o actualizaciones al
                      presente aviso de privacidad, para la atención de novedades legislativas, políticas internas
                      o nuevos requerimientos para la prestación u ofrecimiento de nuestros productos y servicios. En
                      tal caso, “El responsable” publicará dichas modificaciones en su sitio de internet www.gesco.mx
                      De cualquier manera, le recomendamos que revise nuestro aviso de privacidad cada vez que haga
                      uso de nuestra página de Internet, a efecto de conocer los cambios, modificaciones o
                      actualizaciones que se realicen al mismo.<br><br>Revisamos regularmente este Aviso de
                      Privacidad. La última versión fue actualizada en abril de 2023.</p>
              </ng-container>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-light" (click)="closeModal()"
                  style="font-size: 14px;">Cerrar</button>
          </div>
      </div>
  </div>
</div>
