import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute } from '@angular/router';
import { FormDataService } from 'src/app/shared/services/form-data.service';
import { RequestService } from 'src/app/shared/services/request.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-request-form',
  templateUrl: './request-form.component.html',
  styleUrls: ['./request-form.component.scss']
})
export class RequestFormComponent implements OnInit {
  @ViewChild('stepper') stepper!: MatStepper;

  formApplicant: FormGroup = this.fb.group({});
  formClasification: FormGroup = this.fb.group({});

  selectedTab: number = 0;
  search: string = '';
  applicantSelected: boolean = false;
  applicantSearch: any = [];

  constructor(private activeRoute: ActivatedRoute,private requestService: RequestService, private formDataService: FormDataService,private fb: FormBuilder) { 
    this.getUserAuth();
  }

  ngOnInit(): void {
    this.getFormGroupApplicant();
    this.getFormGroupClasifcation();
  }

  onSearchChange(newValue: string) {
    this.search = newValue.toUpperCase();
  }

  getFormGroupApplicant(){
    this.formDataService.requestGeneralFormData$.subscribe(form => {
      if (form) {
        this.formApplicant = form;
      }
    });
  }

  getFormGroupClasifcation(){
    this.formDataService.requestClasificationFormData$.subscribe(form => {
      if (form) {
        this.formClasification = form;
      }
    });
  }

  getUserAuth() {
    this.activeRoute.params.subscribe(routeParams => {
      if (routeParams['token'] && routeParams['idWorkGroup'] && routeParams['idUser']) {
        localStorage.setItem('token', routeParams['token']);
        localStorage.setItem('idWorkGroup', routeParams['idWorkGroup']);
        localStorage.setItem('environment', routeParams['environment']);
        localStorage.setItem('idUser', routeParams['idUser']);
      }
    });
  }

  cleanForm() {
    const excludedVal = {
      idWorkGroup: this.formApplicant.get('idWorkGroup')?.value,
      missing_data_color: this.formApplicant.get('missing_data_color')?.value,
      origen: this.formApplicant.get('origen')?.value,
    };
    this.formApplicant.reset(excludedVal);
    this.formApplicant.patchValue({
      gender: [''],
      civilStatus: [''],
      birthPlace: [''],
      birthPlaceM: [''],
      scholarship: [''],
      ocupation: [''],
      idNeighborhood: [''],
      typeManagment: [''],
      groupBe: [''],
      federalSupport: 0,
      typeSupport: [''],
      idlifting: [''],
      POIrelated: 0,
      idPOI: 0,
      participation:0,
      participationType:[''],
      otherType:[''],
      actor:0,
      actorType:[''],
      otherActor:[''],
    });
    this.search = '';
    this.applicantSearch = [];
    this.formDataService.eventApplicantSearchData(this.formApplicant.value);
  }

  searchApplicant() {
    this.requestService.searchApplicant(this.search).subscribe((data: any) => {
      this.applicantSelected = false;
      this.applicantSearch = data;
    });
  }

  selectedApplicant(idApplicant:number,name:string){
    let applicantData = [];
    this.requestService.getApplicantData(idApplicant).subscribe(data =>{
      this.applicantSelected = true;
      applicantData = data;
      this.formDataService.eventApplicantSearchData(applicantData);
      this.search = name;
    });
  }

  onStepChange(event: any) {
    const isFormValid = this.formApplicant.valid && this.formClasification.valid;
    if(isFormValid === true){
      this.selectedTab = event.selectedIndex;
    }
    
  }

  changeSelected(index: number) {
    const isFormValid = this.formApplicant.valid && this.formClasification.valid;
    if(isFormValid === true){
      this.stepper.selectedIndex = index;
    } else {
      Swal.fire(
        {
          icon: 'error',
          title: 'No haz llenado todos los campos requeridos!',
          text: '',
          confirmButtonText: 'Aceptar'
        }
      );
    }
  }

}
