import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CustomeValidationService } from 'src/app/shared/services/custome-validation.service';
import { FormDataService } from 'src/app/shared/services/form-data.service';
import { RequestService } from 'src/app/shared/services/request.service';
import { UserService } from 'src/app/shared/services/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-request-edit-complete',
  templateUrl: './request-edit-complete.component.html',
  styleUrls: ['./request-edit-complete.component.scss']
})
export class RequestEditCompleteComponent implements OnInit {


  formApplicant: FormGroup = this.fb.group({});
  formClasification: FormGroup = this.fb.group({});
  formDocumentation: FormGroup = this.fb.group({});

  beneficiaryForm: FormGroup = this.fb.group({
    idApplicant: [''],
    name: [''],
    next_name: [''],
    last_name: [''],
    curp: [''],
    birthDate: [''],
    gender: [''],
    birthPlace: [''],
    birthPlaceM: [''],
    cellPhone: [''],
    relationshipBeneficiary: [''],
    idWorkGroup: [''],
    origen: [1],
    location: [0],
  });

  formGroupValid: boolean = false;
  idApplicant: any;
  idBeneficiary: any;
  idUser: any;
  idRequest: any;

  allFiles: any = [];
  ineFile: any;
  acuseFile: any = [];
  extraFile: any = [];

  nameAssigned: any = [];
  idForm: any;

  constructor(private formDataService: FormDataService,
    private fb: FormBuilder,
    private requestService: RequestService,
    private activeRoute: ActivatedRoute,
    private customizeService: CustomeValidationService,
    private userService: UserService) { }

  ngOnInit(): void {
    this.getGeneralData();
    this.getClasificationData();
    this.getDocumentationData();
    this.idUser = this.activeRoute.snapshot.paramMap.get('idUser');
    this.idRequest = this.activeRoute.snapshot.paramMap.get('idRequest');
  }

  getGeneralData() {
    this.formDataService.requestEditGeneralFormData$.subscribe(data => {
      if (data)
        this.formApplicant = data;

      console.log(this.formApplicant.value);
    });
  }

  getClasificationData() {
    this.formDataService.requestEditClasificationFormData$.subscribe(data => {
      if (data) {
        this.formClasification = data;
        this.disableButton();
      }
      this.userService.getUserRequestInfo(this.formClasification.get('idAssigned')?.value).subscribe((data: any) => {
        this.nameAssigned = data[0].userName ?? '-';
        console.log(this.formClasification.value);

      });
    });
  }

  getDocumentationData() {
    this.formDataService.requestDocumentationFormData$.subscribe(data => {
      if (data)
        this.formDocumentation = data;

      console.log(this.formDocumentation.value);
    });
  }

  disableButton() {
    const formValuesApplicant = this.formApplicant.value;
    const numberOfFields = Object.keys(formValuesApplicant).length;
    let validApplicant = false;
    if (numberOfFields != 0) {
      validApplicant = true;
    }
    const formValuesClas = this.formClasification.value;
    const numberOfFieldsClas = Object.keys(formValuesClas).length;
    let validClas = false;
    if (numberOfFieldsClas != 0) {
      validClas = true;
    }
    this.formGroupValid = validApplicant == true && validClas == true;
  }

  // Función para verificar si un campo está vacío o nulo
  isNullOrEmpty(control: AbstractControl | null): boolean {
    return control === null || control.value === '' || control.value === null;
  }

  updateMissingDataColor(form: FormGroup) {
    const curp = form.get('curp');
    const requiredFields = [
      'name',
      'next_name',
      'last_name',
      'birthDate',
      'cellPhone',
      'birthPlace',
      'birthPlaceM',
      'idPostalCode',
      'gender',
      'email',
      'civilStatus',
      'scholarship',
      'ocupation',
      'street',
      'streetNumExt',
      'idNeighborhood',
      'electoralSection',
    ];
    // Comprobar si todos los campos requeridos están llenos
    const allFieldsFilled = requiredFields.every(
      (fieldName) => !this.isNullOrEmpty(form.get(fieldName))
    );

    let federalSupportCondition = true;
    if (form.get('federalSupport')?.value == 1 && !this.isNullOrEmpty(form.get('typeSupport'))) {
      federalSupportCondition = true;
    } else if (form.get('federalSupport')?.value == 0 && this.isNullOrEmpty(form.get('typeSupport'))) {
      federalSupportCondition = true;
    } else {
      federalSupportCondition = false;
    }
    if (allFieldsFilled && federalSupportCondition) {
      this.formClasification.controls['missing_data_color'].setValue('green');
    } else if (!this.isNullOrEmpty(curp)) {
      this.formClasification.controls['missing_data_color'].setValue('yellow');
    } else {
      this.formClasification.controls['missing_data_color'].setValue('red');
    }
  }

  async buttonSave() {
    this.formApplicant.controls['solicitingBeneficiary'].setValue(this.formClasification.get('solicitingBeneficiary')?.value);
    this.updateMissingDataColor(this.formApplicant);

    const time = 5000;
    let timerInterval: any;

    const swalLoading = Swal.fire({
      title: 'Cargando...',
      timer: time,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton());
        timerInterval = setInterval(() => {
        }, 100);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    });

    try {
      const applicantData = this.formApplicant.value;
      const applicantResponse = await this.requestService.updateApplicant(applicantData.idApplicant, applicantData).toPromise();
      console.log(applicantResponse);

      this.idApplicant = applicantData.idApplicant;
      this.idBeneficiary = applicantData.idApplicant;

      if (this.formClasification.get('idForm')?.value == 0 || this.formClasification.get('idForm')?.value == null) {
        await this.saveFormService();
      } else {
        await this.updateForm();
      }
      await this.updateRequest();
    } catch (error) {
      console.error('Error:', error);
    } finally {
      Swal.close();
    }
  }

  async saveFormService() {
    return new Promise((resolve, reject) => {
      const postForm: any = {
        vulnerable: this.formClasification.get('vulnerable')?.value,
        espVulnerable: this.formClasification.get('espVulnerable')?.value,
        institution: this.formClasification.get('institution')?.value,
        enrollment: this.formClasification.get('enrollment')?.value,
        information: this.formClasification.get('information')?.value,
        sport: this.formClasification.get('sport')?.value,
        level: this.formClasification.get('level')?.value,
        health: this.formClasification.get('health')?.value,
        social: this.formClasification.get('social')?.value,
        educationLevel: this.formClasification.get('educationLevel')?.value,
        typeDiscipline: this.formClasification.get('typeDiscipline')?.value,
        disciplineClass: this.formClasification.get('disciplineClass')?.value,
        typeartCult: this.formClasification.get('typeartCult')?.value,
        disciplineNivel: this.formClasification.get('disciplineNivel')?.value,
        purposeScholarship: this.formClasification.get('purposeScholarship')?.value,
        typeScholarship: this.formClasification.get('typeScholarship')?.value,
        population: 0,
        federalSupportForm: this.formClasification.get('federalSupportForm')?.value,
        typeSupportForm: this.formClasification.get('typeSupportForm')?.value,
        categorie: this.formClasification.get('categorie')?.value,
      };
      this.requestService.saveForm(postForm).subscribe((data) => {
        this.idForm = data.id;
        this.formClasification.controls['idForm'].setValue(data.id);
        console.log(this.formClasification.value)
        resolve(data);
      });
    });
  }

  async updateForm() {
    const postForm: any = {
      vulnerable: this.formClasification.get('vulnerable')?.value,
      espVulnerable: this.formClasification.get('espVulnerable')?.value,
      institution: this.formClasification.get('institution')?.value,
      enrollment: this.formClasification.get('enrollment')?.value,
      information: this.formClasification.get('information')?.value,
      sport: this.formClasification.get('sport')?.value,
      level: this.formClasification.get('level')?.value,
      health: this.formClasification.get('health')?.value,
      social: this.formClasification.get('social')?.value,
      educationLevel: this.formClasification.get('educationLevel')?.value,
      typeDiscipline: this.formClasification.get('typeDiscipline')?.value,
      disciplineClass: this.formClasification.get('disciplineClass')?.value,
      typeartCult: this.formClasification.get('typeartCult')?.value,
      disciplineNivel: this.formClasification.get('disciplineNivel')?.value,
      purposeScholarship: this.formClasification.get('purposeScholarship')?.value,
      typeScholarship: this.formClasification.get('typeScholarship')?.value,
      population: 0,
      federalSupportForm: this.formClasification.get('federalSupportForm')?.value,
      typeSupportForm: this.formClasification.get('typeSupportForm')?.value,
      categorie: this.formClasification.get('categorie')?.value,
    };
    if (this.formClasification.get('id')?.value != '' && this.formClasification.get('id')?.value != null)
      await this.requestService.updateForm(this.formClasification.get('id')?.value, postForm).toPromise();
  }

  async updateRequest() {
    var allFiles: any = this.formDataService.allUploadablesRequest;
    var ineFile: any = this.formDataService.ineFile;

    var acuseFile: any = this.formDataService.acuseFile;
    var extraFile: any = this.formDataService.extraFile;


    const promises = [];
    console.log(this.formClasification.value);

    if ((this.formClasification.get('theme_id')?.value == 4 || this.formClasification.get('theme_id')?.value == 15) && allFiles) {
      const requestFiles = allFiles.filter((file: any) => {
        const fileType = file.file.type;
        return fileType === 'application/pdf' || fileType.startsWith('image/');
      });
      console.log(requestFiles);

      if (requestFiles)
        promises.push(this.stageToUpload(requestFiles, 'SP', this.idApplicant));
    } else {
      this.allFiles = [];
    }

    if (ineFile) {
      const file = ineFile.filter((file: any) => {
        const fileType = file.file.type;
        return fileType === 'application/pdf' || fileType.startsWith('image/');
      });
      console.log(file);

      if (file)
        promises.push(this.stageToUpload(file, 'INE', this.idApplicant));
    }

    console.log(acuseFile);


    if (acuseFile) {
      const file = acuseFile.filter((file: any) => {
        const fileType = file.file.type;
        return fileType === 'application/pdf' || fileType.startsWith('image/');
      });
      console.log(file);

      if (file)
        promises.push(this.stageToUpload(file, 'Acuse', this.idApplicant));
    }

    if (extraFile) {
      const file = extraFile.filter((file: any) => {
        const fileType = file.file.type;
        return fileType === 'application/pdf' || fileType.startsWith('image/');
      });
      console.log(file);

      if (file)
        promises.push(this.stageToUpload(file, 'Extra', this.idApplicant));
    } else {
      this.extraFile = [];
    }

    Promise.all(promises).then(async () => {
      if (this.formClasification.get('urgent')?.value == 1)
        this.formClasification.get('theme_id')?.value == 2 || this.formClasification.get('theme_id')?.value == 12 ? this.formClasification.controls['ordenUrgent'].setValue(2) : this.formClasification.controls['ordenUrgent'].setValue(1);

      if (this.formClasification.get('solicitingBeneficiary')?.value == 1) {
        let beneficiaryData = {
          idApplicant: this.formApplicant.get('idBeneficiary')?.value,
          name: this.formClasification.get('nameB')?.value,
          next_name: this.formClasification.get('next_nameB')?.value,
          last_name: this.formClasification.get('last_nameB')?.value,
          curp: this.formClasification.get('curpB')?.value,
          birthDate: this.formClasification.get('birthDateB')?.value,
          gender: this.formClasification.get('genderB')?.value,
          birthPlace: this.formClasification.get('birthPlaceB')?.value,
          birthPlaceM: this.formClasification.get('birthPlaceMB')?.value,
          cellPhone: this.formClasification.get('cellPhoneB')?.value,
          relationshipBeneficiary: this.formClasification.get('relationshipBeneficiary')?.value,
          idWorkGroup: this.formApplicant.get('idWorkGroup')?.value,
          origen: 1,
          location: 0,
        };
        console.log(beneficiaryData)
        this.beneficiaryForm.setValue(beneficiaryData);

        const beneficiaryResponse = await this.requestService.updateApplicant(this.formApplicant.get('idBeneficiary')?.value, this.beneficiaryForm.value).toPromise();
        this.idBeneficiary = this.formApplicant.get('idBeneficiary')?.value;
      }

      if (this.idApplicant != this.idBeneficiary) {
        let dataRequest = {
          idApplicant: this.idApplicant,
          idBeneficiary: this.idBeneficiary,
          qrcode: this.formApplicant.get('qrcode')?.value,
          ...this.formClasification.value,
          idlifting: this.formApplicant.get('idlifting')?.value,
          typeManagment: this.formApplicant.get('typeManagment')?.value,
          groupBe: this.formApplicant.get('groupBe')?.value,
          curp: this.formApplicant.get('curp')?.value,
          idWorkGroup: this.formApplicant.get('idWorkGroup')?.value,
          ...this.formDocumentation.value,
          url_image_sp: this.allFiles,
          url_image_ine: this.ineFile,
          url_image_Acuse: this.acuseFile,
          url_image_Extra: this.extraFile,
          period: this.formApplicant.get('period')?.value,
        };
        console.log(dataRequest);

        const requestResponse = await this.requestService.updateRequest(this.idRequest, dataRequest).toPromise();
      } else {
        let dataRequest = {
          idApplicant: this.idApplicant,
          idBeneficiary: this.idApplicant,
          qrcode: this.formApplicant.get('qrcode')?.value,
          ...this.formClasification.value,
          idlifting: this.formApplicant.get('idlifting')?.value,
          typeManagment: this.formApplicant.get('typeManagment')?.value,
          groupBe: this.formApplicant.get('groupBe')?.value,
          curp: this.formApplicant.get('curp')?.value,
          idWorkGroup: this.formApplicant.get('idWorkGroup')?.value,
          ...this.formDocumentation.value,
          url_image_sp: this.allFiles,
          url_image_ine: this.ineFile,
          url_image_Acuse: this.acuseFile,
          url_image_Extra: this.extraFile,
          period: this.formApplicant.get('period')?.value,
        };
        console.log(dataRequest);
        const requestResponse = await this.requestService.updateRequest(this.idRequest, dataRequest).toPromise();
      }
      this.saveRequestNotification();
      this.updateHistory(this.idRequest);
    });
  }

  saveRequestNotification() {
    const dataNotification = {
      date: null,
      link: this.idRequest,
      idUser: this.idUser,
      idUserNotify: 0,
      message: this.nameAssigned + ', solicitud: ' + (this.formClasification.get('idstatus')?.value == 2 ? 'urgente' : 'de rápida respuesta'),
      view: 0,
      typeModule: 'request'
    };
    this.requestService.saveRequestNotification(dataNotification).subscribe(data => {
      console.log(data);
    });
  }

  stageToUpload(files: any, type: string, id: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      console.log(files);
      this.uploadImage(files, id)
        .then((data) => {
          console.log(data);
          switch (type) {
            case 'SP':
              this.allFiles = data;
              break;
            case 'INE':
              this.ineFile = data;
              break;
            case 'Acuse':
              this.acuseFile = data;
              break;
            case 'Extra':
              this.extraFile = data;
              break;

          }
          // Avísanos que la operación se ha completado
          resolve();
        })
        .catch((error) => {
          // Maneja errores si es necesario
          reject(error);
        });
    });
  }

  uploadImage(selectedFiles: any[], id: number) {
    return new Promise((resolve, reject) => {
      const uploadPromises = [];
      const urls: any = [];
      console.log(selectedFiles);

      for (const file of selectedFiles) {
        console.log(file);

        const uploadPromise = new Promise((innerResolve, innerReject) => {
          this.requestService.uploadImage(file.file, 'requests', 'files', id).subscribe(
            (data: any) => {
              console.log(data);
              urls.push(data.path);
              innerResolve(data);
            },
            (error) => {
              innerReject(error);
            }
          );
        });

        uploadPromises.push(uploadPromise);
      }

      Promise.all(uploadPromises)
        .then(() => {
          // Todas las imágenes se han cargado con éxito
          resolve(urls);
        })
        .catch((error) => {
          // Al menos una carga de imagen falló
          reject(error);
        });
    });
  }

  updateHistory(idRequest: number) {
    const message = 'Ha modificado la solicitud desde Web';
    const historyData = {
      idRequest: idRequest,
      idUser: this.idUser,
      message: message,
      note: null,
      type: 0,
      date: null
    };
    this.requestService.saveRequestHistory(historyData).subscribe(data => {
      alert('Tu registro ha sido actualizada con éxito');
      const parentURL = this.customizeService.getUrl();
      window.parent.location.href = parentURL + '/RequestsRequest/index/' + idRequest + '/idx.html';
    });
  }

}
