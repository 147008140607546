<div *ngIf="loading; else loaded">
  <div class="loader"></div>
</div>

  <div>

    <div class="container">


          <ng-template #loaded>
            <h1>
            HISTÓRICO Mensual <br />
             {{ monthName }}
          </h1>
          <h2>Solicitudes levantadas por grupo vulnerable.</h2>

        </ng-template>
          <div class="chart-container">
            <canvas id="StatTotalRequest">{{ chartTotalRequest2 }}</canvas>

          </div>
        </div>

      </div>

