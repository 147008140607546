<div *ngIf="loading; else loaded">
  <div class="loader"></div>
</div>
<ng-template #loaded>
  <h2>Solicitudes levantadas por sexo</h2>
  <div class="parent">
    <div class="div1">
      <h1>
        {{ currentYear }}
      </h1>
    </div>
    <div class="div2">
      <h1>
        {{ lastYear }}
      </h1>
    </div>
  </div>
</ng-template>
<div>
  <div class="container">
    <div class="row graphicTwo">
      <div class="col-md-6">
        <div class="chart-container">
          <canvas id="ChartStatusRequest">{{ chartStatusRequest }}</canvas>
        </div>
      </div>
      <div class="col-md-6">
        <div class="chart-container">
          <canvas id="ChartTotalRequest">{{ chartTotalRequest }}</canvas>
        </div>
      </div>
    </div>
  </div>
</div>
