import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {Chart} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { StatsService } from 'src/app/shared/services/stats.service';
import { AnnualHistoryComponent } from '../../annual-history.component';
@Component({
  selector: 'app-applicants-by-gender',
  templateUrl: './applicants-by-gender.component.html',
  styleUrls: ['./applicants-by-gender.component.scss']
})
export class ApplicantsByGenderComponent {
  chartTotalRequest: any;
  chartStatusRequest: any;
 requestsLast: any;
 public loading = true;

 requests: any;

  currentYear = new Date().getFullYear();
  lastYear = this.currentYear - 1;
  showApplicantsByGender: boolean = false;

  constructor(private statsService: StatsService, private activeRoute: ActivatedRoute, private annualHistoryComponent: AnnualHistoryComponent) { }



  ngAfterViewInit(): void {
    this.loadData();
  }

  loadData(): void {
    // Lógica para cargar los datos

    Promise.all([this.getBeneficiaryByGenderLastYear(), this.getBeneficiaryByGenderYear()])
      .then(() => {

        this.StatusRequestChart();
        this.totalRequestChart();
        this.loading = false;

        this.annualHistoryComponent.showApplicantsByGender = true;
        this.annualHistoryComponent.updateView();
      })
      .catch(error => {
        console.error('Error while loading data:', error);
      });
  }
 getBeneficiaryByGenderLastYear(){
   const promise = new Promise((resolve, reject) => {
     this.activeRoute.params.subscribe(routeParams => {
     this.statsService.getBeneficiaryByGenderLastYear(routeParams['idWorkGroup']).subscribe(data => {
         console.log(data);
       this.requestsLast=data;
         console.log(this.requestsLast);
         // Llama al método aquí
         resolve(true);
       }
     )
   });
 });
   return promise;
 }
 getBeneficiaryByGenderYear(){
   const promise = new Promise((resolve, reject) => {
     this.activeRoute.params.subscribe(routeParams => {
     this.statsService.getBeneficiaryByGenderYear(routeParams['idWorkGroup']).subscribe(data => {
         console.log(data);
       this.requests=data;
         console.log(this.requests);
         resolve(true);
       }
     )
   });
 });
   return promise;
 }
 StatusRequestChart(){

   this.chartStatusRequest = new Chart("ChartStatusRequest", {
     type: 'doughnut',

     data: {
      labels:['Masculino','Femenino', 'Otro','Sin información'],
      datasets: [
        {
          data:[this.requestsLast.Masculino, this.requestsLast.Femenino, this.requestsLast.Otro, this.requestsLast.Indefinido],
          backgroundColor: ['#00afef','#f32af3', '#959595','#ffbf00'],
        },
      ]
    },
     plugins: [ChartDataLabels],
     options: {
       aspectRatio:1.3,
       plugins: {
         legend: {
             position: 'bottom'
         },

         datalabels: {
           color: 'white',
           labels: {
             title: {
               font: {
                 weight: 'bold',
                 size: 20
               }
             },

           }
         }

     },
     parsing: {
       key: 'nested.value'
     }

     }
   });
 }
 totalRequestChart(){
   if (this.chartTotalRequest) {
     this.chartTotalRequest.destroy();
 }
   this.chartTotalRequest = new Chart("ChartTotalRequest", {
     type: 'doughnut',
     data: {
       labels:['Masculino','Femenino', 'Otro','Sin información'],
       datasets: [
         {
           data:[this.requests.Masculino, this.requests.Femenino, this.requests.Otro, this.requests.Indefinido],
           backgroundColor: ['#00afef','#f32af3', '#959595','#ffbf00'],
          },
       ]
     },
     plugins: [ChartDataLabels],

     options: {

       aspectRatio:1.3,
       parsing: {
         key: 'nested.value'
       },
       plugins: {

         legend: {
             position: 'bottom'
         },
         datalabels: {
           color: 'white',
           labels: {
             title: {
               font: {
                 weight: 'bold',
                 size: 20
               }
             },

           }
         }


     },

     }
   });
 }

}
