<div class="contenedor">
  <div class="panel panel-info">
    <p class="divData">Clasificación</p>
  </div>
  <div class="row">
    <div class="col-12 col-md-4">
      <label>Tema:</label>
      <div class="form-control">{{ request.themeName }}</div>
    </div>
    <div class="col-12 col-md-4">
      <label>Subtema:</label>
      <div class="form-control">{{ request.subthemeName }}</div>
    </div>
    <div class="col-12 col-md-4">
      <label>Clasificación:</label>
      <div class="form-control">{{ request.categoryName }}</div>
    </div>
    <div class="col-12 col-md-4" *ngIf="request.description">
      <label>Descripción:</label>
      <div class="form-control">{{ request.description }}</div>
    </div>
    <div class="col-12 col-md-4" *ngIf="request.solve !=0">
      <label>Resolver a petición del titular:</label>
      <div class="form-control">Si </div>
    </div>
    <div class="col-12 col-md-4" *ngIf="request.urgent != 0 && request.urgent">
      <label *ngIf="request.theme_id == 2 else Other">Es urgente:</label>
      <ng-template #Other>
        <label>Es de rápida respuesta:</label>
      </ng-template>
      <div class="form-control">Si </div>
    </div>
    <div class="col-12 col-md-4" *ngIf="request.aditionBe">
      <label>Número de beneficiarios adicionales:</label>
      <div class="form-control">{{ request.aditionBe }}</div>
    </div>
  </div>
  <div class="panel panel-info">
    <p class="divData">Formulario</p>
  </div>
  <div class="row">
    <div class="col-12 col-md-4" *ngIf="answersName && answersName.educationLevel">
      <label>Nivel educativo:</label>
      <div class="form-control">{{ answersName.educationLevel }}</div>
    </div>
    <div class="col-12 col-md-4" *ngIf="answersName && answersName.institution">
      <label>Escuela o Institución educativa:</label>
      <div class="form-control">{{ answersName.institution }}</div>
    </div>
    <div class="col-12 col-md-4" *ngIf="answersName && answersName.enrollment">
      <label>Matrícula:</label>
      <div class="form-control">{{ answersName.enrollment }}</div>
    </div>
    <div class="col-12 col-md-4" *ngIf="answersName && answersName.sport">
      <label>Tipo de deporte:</label>
      <div class="form-control">{{ answersName.sport }}</div>
    </div>
    <div class="col-12 col-md-4" *ngIf="answersName && answersName.health">
      <label>Seguro médico:</label>
      <div class="form-control">{{ answersName.health }}</div>
    </div>
    <div class="col-12 col-md-4" *ngIf="answersName && answersName.social">
      <label>Número de afiliación:</label>
      <div class="form-control">{{ answersName.social }}</div>
    </div>
    <div class="col-12 col-md-4" *ngIf="answersName && answersName.information">
      <label>Información del beneficiario:</label>
      <div class="form-control">{{ answersName.information }}</div>
    </div>
    <div class="col-12 col-md-4" *ngIf="answersName && answersName.vulnerable">
      <label>Grupo vulnerable:</label>
      <div class="form-control">{{ answersName.vulnerable }}</div>
    </div>
    <div class="col-12 col-md-4" *ngIf="answersName && answersName.espVulnerable">
      <label>Grupo vulnerable específico:</label>
      <div class="form-control">{{ answersName.espVulnerable }}</div>
    </div>
    <div class="col-12 col-md-4" *ngIf="answersName && answersName.federalSupportForm">
      <label>Apoyo específico:</label>
      <div class="form-control">{{ answersName.federalSupportForm }}</div>
    </div>
    <div class="col-12 col-md-4" *ngIf="answersName && answersName.disciplineClass">
      <label>Tipo de disciplina:</label>
      <div class="form-control">{{ answersName.disciplineClass }}</div>
    </div>
    <div class="col-12 col-md-4" *ngIf="answersName && answersName.typeDiscipline">
      <label>Rama específica:</label>
      <div class="form-control">{{ answersName.typeDiscipline }}</div>
    </div>
    <div class="col-12 col-md-4" *ngIf="answersName && answersName.disciplineNivel">
      <label>Nivel de disciplina:</label>
      <div class="form-control">{{ answersName.disciplineNivel }}</div>
    </div>
    <div class="col-12 col-md-4" *ngIf="answersName && answersName.purposeScholarship">
      <label>Finalidad de la beca:</label>
      <div class="form-control">{{ answersName.purposeScholarship }}</div>
    </div>
    <div class="col-12 col-md-4" *ngIf="answersName && answersName.typeScholarship">
      <label>Tipo de beca:</label>
      <div class="form-control">{{ answersName.typeScholarship }}</div>
    </div>
    <div class="col-12 col-md-4" *ngIf="answersName && answersName.level">
      <label>Nivel de aprendizaje en el deporte:</label>
      <div class="form-control">{{ answersName.level }}</div>
    </div>
    <div class="col-12 col-md-4" *ngIf="answersName && answersName.categorie">
      <label>Categoría:</label>
      <div class="form-control">{{ answersName.categorie }}</div>
    </div>
  </div>
  <div class="panel panel-info" *ngIf="request.theme_id == 4 ||  request.theme_id == 15 ">
    <p class="divData">Ubicación del reporte</p>
  </div>
  <div class="row" *ngIf="request.theme_id == 4 ||  request.theme_id == 15 ">
    <div class="col-12 col-md-4" *ngIf="request.street_sp">
      <label>Calle:</label>
      <div class="form-control">{{ request.street_sp }}</div>
    </div>
    <div class="col-12 col-md-4" *ngIf="request.streetNum_sp != 0 && request.streetNum_sp">
      <label>Número:</label>
      <div class="form-control">{{ request.streetNum_sp }}</div>
    </div>
    <div class="col-12 col-md-4" *ngIf="request.postalCode_sp">
      <label>Código postal:</label>
      <div class="form-control">{{ request.postalCode_sp }}</div>
    </div>
    <div class="col-12 col-md-4" *ngIf="request.neighborhoodName_sp">
      <label>Colonia:</label>
      <div class="form-control">{{ request.neighborhoodName_sp }}</div>
    </div>
    <div class="col-12 col-md-4" *ngIf="request.municipalityName_sp">
      <label>Municipio:</label>
      <div class="form-control">{{ request.municipalityName_sp }}</div>
    </div>
    <div class="col-12 col-md-4" *ngIf="request.entityName_sp">
      <label>Estado:</label>
      <div class="form-control">{{ request.entityName_sp }}</div>
    </div>
    <div class="col-12 col-md-4" *ngIf="request.federal_district_sp">
      <label>Distrito federal:</label>
      <div class="form-control">{{ request.federal_district_sp }}</div>
    </div>
    <div class="col-12 col-md-4" *ngIf="request.local_district_sp">
      <label>Distrito local:</label>
      <div class="form-control">{{ request.local_district_sp }}</div>
    </div>
    <div class="col-12 col-md-4" *ngIf="request.electoralSection_sp">
      <label>Sección electoral:</label>
      <div class="form-control">{{ request.electoralSection_sp }}</div>
    </div>
  </div>
  <div class="row mt-3" *ngIf="(request.theme_id == 4 ||  request.theme_id == 15) && request.ubication != 0">
    <app-sp-map #test [lat]="lat" [lon]="long"></app-sp-map>
  </div>
</div>
