<div class="container">
    <div class="card">
        <div class="card-body">
            <h4 class="card-title">Panel de control</h4>
            <b>Módulos:</b><br>
            <div class="row">
                <div class="col">
                    <span>Correo: </span>
                    <ng-toggle [(ngModel)]="emailModule" [color]="{unchecked: '#000000',checked: '#2dbf35'}" [labels]="{checked: 'On', unchecked: 'Off'}" [width]="50"></ng-toggle>
                </div>
                <div class="col">
                    <span>Otros/SolicitApp: </span>
                    <ng-toggle [(ngModel)]="othersModule" [color]="{unchecked: '#000000',checked: '#2dbf35'}" [labels]="{checked: 'On', unchecked: 'Off'}" [width]="50"></ng-toggle>
                </div>
            </div>
            <b>Notificaciones: </b><br>
            <div class="row">
                <div class="col">
                    <span>Correo electrónico: </span>
                    <ng-toggle [(ngModel)]="email" [color]="{unchecked: '#000000',checked: '#2dbf35'}" [labels]="{checked: 'On', unchecked: 'Off'}" [width]="50"></ng-toggle>
                </div>
                <div class="col">
                    <span>Whatsapp: </span>
                    <ng-toggle [(ngModel)]="whatsapp" [color]="{unchecked: '#000000',checked: '#2dbf35'}" [labels]="{checked: 'On', unchecked: 'Off'}" [width]="50"></ng-toggle>
                </div>
            </div>
            <div *ngIf="email || whatsapp" >
                <b>Estatus: </b><br>
                <div class="row">
                    <div class="col">
                        <span>Registro: </span>
                        <ng-toggle [(ngModel)]="register" [color]="{unchecked: '#000000',checked: '#2dbf35'}" [labels]="{checked: 'On', unchecked: 'Off'}" [width]="50"></ng-toggle>
                    </div>
                    <div class="col">
                        <span>Asignado: </span>
                        <ng-toggle [(ngModel)]="assigned" [color]="{unchecked: '#000000',checked: '#2dbf35'}" [labels]="{checked: 'On', unchecked: 'Off'}" [width]="50"></ng-toggle>
                    </div>
                    <div class="col">
                        <span>En proceso: </span>
                        <ng-toggle [(ngModel)]="onProcess" [color]="{unchecked: '#000000',checked: '#2dbf35'}" [labels]="{checked: 'On', unchecked: 'Off'}" [width]="50"></ng-toggle>
                    </div>
                    <div class="col">
                        <span>Meta cumplida: </span>
                        <ng-toggle [(ngModel)]="accomplished" [color]="{unchecked: '#000000',checked: '#2dbf35'}" [labels]="{checked: 'On', unchecked: 'Off'}" [width]="50"></ng-toggle>
                    </div>
                    <div class="col">
                        <span>Cancelado: </span>
                        <ng-toggle [(ngModel)]="canceled" [color]="{unchecked: '#000000',checked: '#2dbf35'}" [labels]="{checked: 'On', unchecked: 'Off'}" [width]="50"></ng-toggle>
                    </div>
                    <div class="col">
                        <span>No viable: </span>
                        <ng-toggle [(ngModel)]="noViable" [color]="{unchecked: '#000000',checked: '#2dbf35'}" [labels]="{checked: 'On', unchecked: 'Off'}" [width]="50"></ng-toggle>
                    </div>
                    <div class="col">
                        <span>Simpatizantes: </span>
                        <ng-toggle [(ngModel)]="sympathizer" [color]="{unchecked: '#000000',checked: '#2dbf35'}" [labels]="{checked: 'On', unchecked: 'Off'}" [width]="50"></ng-toggle>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col divButton">
                    <button type="button" class="btn btn-primary" (click)="saveParams()">Guardar</button>
                </div>
            </div>
        </div>
    </div>
</div>
