import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { StatsService } from 'src/app/shared/services/stats.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-applicants-gender-months',
  templateUrl: './applicants-gender-months.component.html',
  styleUrls: ['./applicants-gender-months.component.scss']
})
export class ApplicantsGenderMonthsComponent implements OnInit {
  public chartStatus: any;
  requestsLast: any;
  requests: any;
  public loading = true;
  id!: any;
  month!: any;
  idUser!: any;
  token!: any;
  idWorkGroup!: any;

  currentYear = new Date().getFullYear();
  lastYear = this.currentYear - 1;
  constructor(private statsService: StatsService, private activeRoute: ActivatedRoute, private router: Router) {
    this.idUser = this.activeRoute.snapshot.params.idUser;
    this.idWorkGroup = this.activeRoute.snapshot.params.idWorkGroup;
    this.token = this.activeRoute.snapshot.params.token;

  }

  ngOnInit(): void {
    this.loading = true;

    Promise.all([this.getApplicantGenderByMonths()])
      .then(() => {

        this.StatusRequestChart();
        this.loading = false;
      })
      .catch(error => {
        console.error('Error while loading data:', error);
        this.loading = false; // asegurarse de quitar el loader en caso de error
      });
  }
  getApplicantGenderByMonths() {
    const promise = new Promise((resolve, reject) => {
      this.activeRoute.params.subscribe(routeParams => {
        this.statsService.getApplicantGenderByMonths(routeParams['idWorkGroup']).subscribe(data => {
          console.log(data);
          this.requests = data;
          this.id = data;
          console.log(this.requests);
          resolve(true);
        }
        )
      });
    });
    return promise;
  }
  StatusRequestChart() {
    // Crea los arrays para los datos
    const totalData = [];
    const monthLabels = [];

    // Itera sobre los objetos del array de datos y agrega los datos correspondientes en los arrays
    for (let i = 0; i < this.requests.length; i++) {
        const requestData = this.requests[i];
        const monthIndex = parseInt(requestData.monthId) - 1;

        totalData[monthIndex] = requestData.totalRequests;
        monthLabels[monthIndex] = this.getMonthLabel(monthIndex);
    }

    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const filteredMonthLabels = monthLabels.slice(0, currentMonth + 1);

    // Filtra el dataset que tiene valores iguales a 0
    const datasets = [
        { data: totalData, backgroundColor: '#0e6d0f', label: 'Total' }
    ];

    // Crea el gráfico con los datos
    this.chartStatus = new Chart("ChartStatus", {
        type: 'bar',
        data: {
            labels: filteredMonthLabels,
            datasets: datasets,
        },
        plugins: [ChartDataLabels],
        options: {
            indexAxis: 'y',
            aspectRatio: 2.3,
            plugins: {
                legend: {
                    position: 'bottom'
                },
                datalabels: {
                    color: 'black',
                    anchor: 'end',
                    align: 'end',
                    labels: {
                        title: {
                            font: {
                                weight: 'bold',
                                size: 24,
                            }
                        },
                    }
                }
            },
            onClick: (e, elements) => {
              console.log(elements[0].index);
              const themeIndex = elements[0].index;
              const themeId = this.id[themeIndex].monthId;
              console.log(themeId);
              this.router.navigate(['historical-gender-month', this.idUser, this.idWorkGroup, this.token, themeId]);
            },
            scales: {
                x: {
                    stacked: true,
                },
                y: {
                    stacked: true,
                }
            }
        }
    });
}


  getMonthLabel(monthIndex: number) {
    const monthNames = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    return monthNames[monthIndex];
  }








}
