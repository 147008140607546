import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { ProposalService } from 'src/app/shared/services/proposal.service';
import { UserService } from 'src/app/shared/services/user.service';
import { environment } from 'src/environments/environment';

declare var $:any;
@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  p: number = 1;
  proposals: any = [];
  itemsPerPage: number = 10;
  totalRecords: number = 0;
  idWorkGroup: any;
  idUser: any;
  token: any;
  search: string = "";
  private searchTerms = new Subject<string>();
  results: any = [];
  loading: boolean = false;
  currentArray: any;
  environment: any;
  userLevel: any;
  identifier: any;
  enabledReport: any;
  parentURL: any;
  url: any;
  currentFilter: String = 'none';
  selectedField: String = "created_at";
  selectedOrder: String = "desc";

  usersOptions: any[] = [];

  visitForm: FormGroup = this.FB.group({
    date: ['', [Validators.required,this.dateValidator]],
    idUserVisitor: [0],
    idPromoter: [''],
    note: ['', [Validators.required]],
    idUser: [''],
  });

  constructor(
    private FB: FormBuilder,
    private proposalService: ProposalService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private userService: UserService) {}

  ngOnInit(): void {
    this.getUserAuth();
    this.searchhold();
  }

  onSearchChange(newValue: string) {
    this.search = newValue.toUpperCase();
  }
  
  openModal(theme_id:number,idPromoter:number){
    $('#visitModal').modal('show');
    this.getWorkGroupTheme(theme_id);
    this.visitForm.controls['idPromoter'].setValue(idPromoter);
  }

  closeModal(){
    $('#visitModal').modal('hide');
  }

  saveVisit(){
    this.visitForm.controls['idUser'].setValue(this.activeRoute.snapshot.paramMap.get('idUser'));
    if(this.visitForm.get('idUserVisitor')?.value == 0)
      this.visitForm.controls['idUserVisitor'].setValue(this.activeRoute.snapshot.paramMap.get('idUser'));
    
    this.proposalService.saveVisit(this.visitForm.value).subscribe(data =>{
      console.log(data);
      window.location.reload();
    });
  }

  getWorkGroupTheme(theme_id:number) {
    this.userService.getWorkGroupTheme(this.idUser, this.idWorkGroup, theme_id).subscribe((data) => {
      this.usersOptions = data;
    });
  }

  dateValidator(control:any) {
    const date = new Date(control.value);
    const actualDate = new Date();

    if (date < actualDate) {
      return null; // La fecha es válida
    } else {
      return { invalidDate: true }; // La fecha es inválida
    }
  }

  getUserAuth() {
    this.activeRoute.params.subscribe(routeParams => {
      this.idWorkGroup = routeParams['idWorkGroup'];
      this.idUser = routeParams['idUser'];
      this.url = environment.apiUrl;
      localStorage.setItem('token', routeParams['token']);

      this.userService.getUserInfo(routeParams['idUser']).subscribe(data => {
        this.identifier = data[0].identifier;
        this.enabledReport = data[0].enableReports;
        this.userLevel = data[0].userLevel;
        this.getProposals();
      });

      if (routeParams['token'] && routeParams['idWorkGroup'] && routeParams['idUser']) {
        localStorage.setItem('idWorkGroup', routeParams['idWorkGroup']);
        localStorage.setItem('environment', routeParams['environment']);
        localStorage.setItem('idUser', routeParams['idUser']);
      }
    });
  }

  getProposals(orderByField: String = 'created_at', orderByDirection: String = 'desc') {
    this.proposalService.getProposals(this.p,this.itemsPerPage,this.idWorkGroup,orderByField, orderByDirection,this.userLevel, this.idUser).subscribe((response:any) => {
      this.proposals = response.data;
      this.totalRecords = response.total;
      this.currentArray = response.data;
    });
  }

  goToUpdate(id: number) {
    console.log(id);
    this.router.navigate(['/updateProposal/' + id + '/'+ this.idUser +'/' + this.idWorkGroup + '/' + this.token]);
  }

  goToDetail(idProposal: number) {
    this.idUser = localStorage.getItem('idUser');
    this.token = localStorage.getItem('token');
    this.idWorkGroup = localStorage.getItem('idWorkGroup');
    this.environment = localStorage.getItem('environment');

    // const parentURL = this.customizeService.getUrl();
    // window.parent.location.href = parentURL + '/PointsPoints/listPoints.html';
    this.router.navigate(['/dataProposal/' + idProposal + '/' + this.idWorkGroup + '/' + this.token + '/']);// + this.environment]);
  }

  getTooltip(missingDataColor: string): string {
    switch (missingDataColor) {
      case 'red':
        return 'Sin CURP y datos faltantes';
      case 'yellow':
        return 'CURP registrada con datos faltantes';
      case 'green':
        return 'Datos completos';
      default:
        return '';
    }
  }

  getBackgroundColor(missingDataColor: string): string {
    switch (missingDataColor) {
      case 'red':
        return '#FF0000';
      case 'yellow':
        return '#f59c1a';
      case 'green':
        return '#7CFC00';
      default:
        return '';
    }
  }

  get totalPages(): number {
    return Math.ceil(this.totalRecords / this.itemsPerPage);
  }

  onPageChange(newPageIndex: number): void {
    this.p = newPageIndex;
    this.getProposals();
  }

  getSearch(event: any) {
    const searchTerm = this.search;
    this.searchTerms.next(searchTerm);
  
    if (searchTerm.trim() === '') {
      this.clearSearch();
    }
  }

  clearSearch() {
    this.search = '';
    this.results = [];
  }

  searchhold() {
    this.searchTerms.pipe(
      debounceTime(2000), // Reducido a 300 ms para una respuesta más rápida
      distinctUntilChanged(),
      switchMap((term: string) => {
        if (term.trim() === '') {
          //this.toggleArray();
          this.currentArray = this.proposals;
          this.loading = false;
          return [];
        } else if (term.length >= 3) {
          this.loading = true;
          return this.proposalService.getSearchProposals(term,this.p,this.itemsPerPage,this.userLevel, this.idUser);
        } else {
          this.currentArray = this.proposals;
          this.loading = false;
          return [];
        }
      })
    ).subscribe(data => {
      this.results = data;
      this.currentArray = data;
      //this.toggleArray();
      this.loading = false;
    });
  }

  getOrder(field: String, order: any) {

    this.selectedField = field;
    this.selectedOrder = order;
    console.log(field);
    console.log(order);

    if (order == 'default') {
      this.currentFilter = 'none';
      this.getProposals();
    }else{
      this.currentFilter = field;
      this.getProposals(field,order);
    }

  }

  // toggleArray() {
  //   this.currentArray = this.currentArray === this.proposals ? this.results : this.proposals;
  // }
}
