import { Component, OnInit } from '@angular/core';
import {Chart} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {ActivatedRoute, Router} from '@angular/router';
import { StatsService } from 'src/app/shared/services/stats.service';

@Component({
  selector: 'app-type-of-applicants-by-age-and-type-of-beneficiary-by-age',
  templateUrl: './type-of-applicants-by-age-and-type-of-beneficiary-by-age.component.html',
  styleUrls: ['./type-of-applicants-by-age-and-type-of-beneficiary-by-age.component.scss']
})
export class TypeOfApplicantsByAgeAndTypeOfBeneficiaryByAgeComponent implements OnInit {

  public chartTotalRequest2: any;
  public chartStatusRequest2: any;
  currentYear = new Date().getFullYear();
  lastYear = this.currentYear - 1;
  requestsLast: any;
  requests: any;
  chartData: any;
  chartDataYear: any;

  theme!: any [];
  stock1: any;
  stock1Year: any;

  stock2: any;
  themeYear!: any [];

  labels: any[]=[];
  color1 = ['#008000'];
  color2= ['#ff0000'];
  grafico: any;
  public loading = true;
  public loading2 = true;

  constructor(private router:Router, private statsService: StatsService, private activeRoute: ActivatedRoute){

  }

  ngOnInit(): void {
    this.loading = true;
    this.activeRoute.params.subscribe(routeParams => {
      localStorage.setItem('token', routeParams['token'])
    });
    Promise.all([this.getApplicantByAgeGroup(), this.getBeneficiaryByAgeGroup()])
      .then(() => {

    this.StatusRequestChartTheme2();
    this.totalRequestChartTheme2();
        this.loading = false;
      })
      .catch(error => {
        console.error('Error while loading data:', error);
        this.loading = false; // asegurarse de quitar el loader en caso de error
      });
  }

  getApplicantByAgeGroup(){
    const promise = new Promise((resolve, reject) => {
      this.activeRoute.params.subscribe(routeParams => {
      this.statsService.getApplicantByAgeGroup(routeParams['idWorkGroup']).subscribe(data => {
          console.log(data);
          this.stock1= data;
          resolve(true);

        }
        )
      });
    });

    return promise;
  }
  getBeneficiaryByAgeGroup(){
    const promise = new Promise((resolve, reject) => {
      this.activeRoute.params.subscribe(routeParams => {
      this.statsService.getBeneficiaryByAgeGroup(routeParams['idWorkGroup']).subscribe(data => {
          console.log(data);
          this.stock2= data;
          resolve(true);

        }
        )
      });
    });

    return promise;
  }

  StatusRequestChartTheme2(){

    this.chartStatusRequest2 = new Chart("StatStatusRequest", {
      type: 'bar',
      data: {
        labels:['Sin información','60 o más','27-59 años','19-26 años','12-18 años','06-11 años', '00-05 años'],
	      datasets: [
          {
            data:[this.stock1["Indefinido"][0].count,this.stock1["60 o más"][0].count,this.stock1["27-59 años"][0].count,this.stock1["19-26 años"][0].count,this.stock1["12-18 años"][0].count,this.stock1["06-11 años"][0].count,this.stock1["00-05 años"][0].count ],
            backgroundColor: ['#ffc000', '#2e75b6', '#bf9000', '#ffd966', '#8faadc', '#c55a11', '#7030a0'],

            label:'Solicitudes cerradas' ,
          }
        ],

      },
      plugins: [ChartDataLabels],
      options: {

        indexAxis: 'y',
        aspectRatio:1.2,
        plugins: {
          legend: {
            display: false

          },
          datalabels: {
            color: 'black',
            anchor: 'end',
            align: 'end',
            labels: {
              title: {
                font: {
                  weight: 'bold',
                  size: 14,
                }
              },

            }
          }

      },

      onClick: (e, elements) => {
        console.log(elements[0].index);
        switch (elements[0].index) {
          case 0:
            this.router.navigate(['levantamiento']);
            break;
          case 1:
            this.router.navigate(['requests-subthemes']);
            break;
          case 2:
            this.router.navigate(['requests-subthemes']);
            break;
          case 3:
            this.router.navigate(['requests-subthemes']);
            break;
        }},



    }});
  }

  totalRequestChartTheme2(){
    this.chartTotalRequest2 = new Chart("StatTotalRequest", {
      type: 'bar',
      data: {
        labels:['Sin información','60 o más','27-59 años','19-26 años','12-18 años','06-11 años', '00-05 años'],

	      datasets: [

          {
            data:[this.stock2["Indefinido"][0].count,this.stock2["60 o más"][0].count,this.stock2["27-59 años"][0].count,this.stock2["19-26 años"][0].count,this.stock2["12-18 años"][0].count,this.stock2["06-11 años"][0].count,this.stock2["00-05 años"][0].count ],
            backgroundColor: ['#ffc000', '#2e75b6', '#bf9000', '#ffd966', '#8faadc', '#c55a11', '#7030a0'],

            },


        ],


      },
      plugins: [ChartDataLabels],
      options: {
        indexAxis: 'y',
        aspectRatio:1.2,
        plugins: {
          legend: {

              display: false

          },
          datalabels: {
            color: 'black',
            anchor: 'end',
            align: 'end',
            labels: {
              title: {
                font: {
                  weight: 'bold',
                  size: 17,
                }
              },

            }
          }

      },
      onClick: (e) => {

        this.router.navigate(['requests-subthemes']);
      }


      }
    });
}}
