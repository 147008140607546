import { Component, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { ProposalService } from 'src/app/shared/services/proposal.service';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/shared/services/user.service';
import { RequestService } from 'src/app/shared/services/request.service';

declare var $: any;
@Component({
  selector: 'app-data-proposal',
  templateUrl: './data-proposal.component.html',
  styleUrls: ['./data-proposal.component.scss']
})

export class DataProposalComponent implements OnInit {
  @ViewChild('stepper') stepper!: MatStepper;

  reassignForm: FormGroup = this.FB.group({
    idAssigned: ['', [Validators.required]],
  });

  closeForm: FormGroup = this.FB.group({
    idstatus: ['', [Validators.required]],
    note: [''],
  });
  
  selectedTab: number = 0;
  idProposal:any;
  idPromoter: any;
  proposal: any = [];
  idWorkGroup: any;
  token: any;
  idUser: any;
  typeModal: any;
  title: any;
  usersOptions: any = [];
  clickButton: boolean = true;

  nameAssigned: string = '-';

  constructor(private proposalService: ProposalService, 
    private activeRoute: ActivatedRoute,
    private router: Router,
    private location: Location, 
    private FB: FormBuilder,
    private userService: UserService,
    private requestService: RequestService) { }

  ngOnInit(): void {
    this.idUser = localStorage.getItem('idUser');
    this.getProposal();
  }

  openModal(type: any) {
    this.typeModal=type;
    if(type == 'attend'){
      $('#attendModal').modal('show');
      this.title = '¿Desea atender esta propuesta?';
    }
    if(type == 'assign'){
      $('#assignModal').modal('show');
      this.title = 'Asignar la propuesta';
      this.getWorkGroupTheme();
    }
    if(type == 'reassign'){
      $('#assignModal').modal('show');
      this.title = 'Reasignar la propuesta';
      this.getWorkGroupTheme();
    }
    if(type == 'seg'){
      $('#attendModal').modal('show');
      this.title = '¿Desea darle seguimiento a esta propuesta?';
    }
    if(type == 'close'){
      $('#closeModal').modal('show');
      this.title = 'Cambiar el estatus de la propuesta';
    }
  }

  getWorkGroupTheme() {
    this.userService.getWorkGroupTheme(this.idUser, this.idWorkGroup, this.proposal.theme_id).subscribe((data) => {
      this.usersOptions = data;
    });
  }

  saveAttend(){
    if(this.clickButton){
      this.clickButton = false;
      const attend = {
        idAssigned: this.idUser,
        idstatus: 2,
        idInstance: 0,
        idOwner: this.idUser,
      };
      this.proposalService.updateStatus(this.idProposal,attend).subscribe(data =>{
        const attendHistory = {
          date: null,
          idProposal: this.idProposal,
          idUser: this.idUser,
          message: 'Decidió atender la propuesta, el estatus ha cambiado a: Asignado desde Web',
          type: 2
        };
        this.proposalService.saveProposalHistory(attendHistory).subscribe(data =>{
          window.location.reload();
        });
      });
    }
  }

  saveAssign(){
    if(this.clickButton){
      this.clickButton = false;
      const assign = {
        idAssigned: this.reassignForm.get('idAssigned')?.value,
        idstatus: 2,
        idInstance: 0,
        idOwner: this.idUser,
      };
      this.proposalService.updateStatus(this.idProposal,assign).subscribe(data =>{
        const user = this.usersOptions.find((option:any) => option.idUser == this.reassignForm.get('idAssigned')?.value);
        const attendHistory = {
          date: null,
          idProposal: this.idProposal,
          idUser: this.idUser,
          message: this.proposal.idAssigned == 0 ? 'Asignó la propuesta a: ' + user.userName + ' desde Web': 'Reasignó la propuesta a: ' + user.userName + ' desde Web',
          type: 2
        };
        const asignNotification = {
          date: null,
          link: this.idProposal,
          idUser: this.idUser,
          idUserNotify: this.reassignForm.get('idAssigned')?.value,
          message: 'Se te ha asignado una propuesta por parte de: ' + user.userName,
          view: 0,
          typeModule: 'proposal'
        };
        this.requestService.saveRequestNotification(asignNotification).subscribe(data =>{
          console.log(data);
        });
        this.proposalService.saveProposalHistory(attendHistory).subscribe(data =>{
          window.location.reload();
        });
      });
    }
  }

  saveSeg(){
    if(this.clickButton){
      this.clickButton = false;
      const seg = {
        idstatus: 5
      };
      this.proposalService.updateStatus(this.idProposal,seg).subscribe(data =>{
        const attendHistory = {
          date: null,
          idProposal: this.idProposal,
          idUser: this.idUser,
          message: 'Decidió darle seguimiento a la propuesta, el estatus ha cambiado a: En proceso desde Web',
          type: 5
        };
        this.proposalService.saveProposalHistory(attendHistory).subscribe(data =>{
          window.location.reload();
        });
      });
    }
  }

  saveClose(){
    if(this.clickButton){
      this.clickButton = false;
      let close = {
        idstatus: this.closeForm.get("idstatus")?.value,
      }
      let nameStatus = this.getStatusLabel(parseInt(this.closeForm.get("idstatus")?.value));
      let history = {
        date: null,
        idProposal: this.idProposal,
        idUser: this.idUser,
        message: 'Cambio la propuesta al estatus: '+ nameStatus + ' desde Web',
        type: this.closeForm.get("idstatus")?.value,
        note: this.closeForm.get("note")?.value
      }
      this.proposalService.updateStatus(this.idProposal,close).subscribe(data =>{
        if(this.closeForm.get('idstatus')?.value == 4 || this.closeForm.get('idstatus')?.value == 6){
          const closedNotification = {
            date: null,
            link: this.idProposal,
            idUser: this.idUser,
            idUserNotify: 0,
            message: this.nameAssigned + ' cambio la propuesta: ' + this.proposal.qrcode + ' al estatus: ' + nameStatus,
            view: 0,
            typeModule: 'proposal'
          };
          this.requestService.saveRequestNotification(closedNotification).subscribe(data =>{
            console.log(data);
          });
        }
        this.proposalService.saveProposalHistory(history).subscribe(data =>{
          window.location.reload();
        })
      });
    }
  }

  closeModal() {
    $('#attendModal').modal('hide');
    $('#assignModal').modal('hide');
    $('#closeModal').modal('hide');
  }

  getProposal(){
    this.idProposal = this.activeRoute.snapshot.paramMap.get('idProposal');
    this.idWorkGroup = this.activeRoute.snapshot.paramMap.get('idWorkGroup');

    if (this.idProposal != null) {
      this.proposalService.getProposalData(this.idProposal,this.idWorkGroup).subscribe((response: any) => {
        this.proposal = response;
        this.idPromoter = this.proposal.idPromoter;
        if(this.proposal.idstatus != 1){
          this.userService.getUserRequestInfo(this.proposal.idAssigned).subscribe(data =>{
            this.nameAssigned = data[0].userName ?? '-';
          });
        }
      })
    }
  }

  getStatusLabel(idStatus: number): string {
    switch (idStatus) {
      case 1:
        return 'Registro';
      case 2:
        return 'Asignado';
      case 3:
        return 'No viable';
      case 4:
        return 'Cancelado';
      case 5:
        return 'En proceso';
      case 6:
        return 'Meta cumplida';
      default:
        return 'Desconocido';
    }
  }

  goToUpdate(id: number,idUser : number) {
    this.token =localStorage.getItem('token');
    this.router.navigate(['/updateProposal/' + id + '/' + idUser +'/' + this.idWorkGroup + '/' + this.token]);
  }

  onStepChange(event: any) {
    this.selectedTab = event.selectedIndex;
  }

  changeSelected(index: number) {
    this.stepper.selectedIndex = index;
  }

  goBack(){
    this.location.back();
  }

}
