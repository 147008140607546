<div class="contenedor">
  <div class="back btn btn-sm mt-3 ml-3" (click)="goBack()">
    <i class="fa fa-arrow-left fa-lg" style="cursor: pointer;" aria-hidden="true"></i>
  </div>
  <h1 class="page-header pt-1" style="padding-left: 15px; color: black;">Editar datos del promovido
    &nbsp;<small></small></h1>
  <hr>
  <div class="row custom-row m-2">
    <div class="col-12 col-md-4">
      <label class="p-t-2">Folio asignado: </label>
      <div class="form-control-disable">{{ qrcode }}</div>
    </div>
  </div>
  <div class="sectionBody">
    <div class="row tHeader">
      <div class="col-3 tab" (click)="changeSelected(0)" [ngClass]="{'active': selectedTab == 0}">
        <div class="sectionForm">
          <span class="numberNotSelected" *ngIf="selectedTab != 0 else one">
            <div class="fa-solid fa-check"></div>
          </span>
          <ng-template #one>
            <span class="number">
              1
            </span>
          </ng-template>
          <div>
            <span class="info">Datos generales </span>
            <i class="fa fa-arrow-right"></i>
            <small>Nombre, Dirección, Teléfono...</small>
          </div>
        </div>
      </div>
      <div class="col-3 tab" (click)="changeSelected(1)" [ngClass]="{'active': selectedTab == 1}">
        <div class="sectionForm">
          <span class="numberNotSelected" *ngIf="selectedTab != 1 else second">
            <div class="fa-solid fa-check"></div>
          </span>
          <ng-template #second>
            <span class="number">
              2
            </span>
          </ng-template>
          <div>
            <span class="info">Encuestas </span>
            <i class="fa fa-arrow-right"></i>
            <small>Encuesta, Evidencia, Clasificación...</small>
          </div>
        </div>
      </div>
      <div class="col-3 tab" (click)="changeSelected(2)" [ngClass]="{'active': selectedTab == 2}">
        <div class="sectionForm">
          <span class="numberNotSelected" *ngIf="selectedTab != 2 else third">
            <div class="fa-solid fa-check"></div>
          </span>
          <ng-template #third>
            <span class="number">
              3
            </span>
          </ng-template>
          <div>
            <span class="info">Documentación</span>
            <i class="fa fa-arrow-right"></i>
            <small>Documentación entregada</small>
          </div>
        </div>
      </div>
      <div class="col-3 tab" (click)="changeSelected(3)" [ngClass]="{'active': selectedTab == 3}">
        <div class="sectionForm">
          <span class="numberNotSelected" *ngIf="selectedTab != 3 else four">
            <div class="fa-solid fa-check"></div>
          </span>
          <ng-template #four>
            <span class="number">
              4
            </span>
          </ng-template>
          <div>
            <span class="info">Completado</span>
            <i class="fa fa-arrow-right"></i>
            <small>Registro completo</small>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col p-0">
        <mat-stepper (selectionChange)="onStepChange($event)" #stepper>
          <mat-step>
            <div class="row">
              <div class="col">
                <form [formGroup]="promotedForm">
                  <div class="contenedor p-2">
                    <div class="row" style="display: flex; justify-content: end;">
                      <div class="col-12 col-md-4">
                        <label>Periodo:</label>
                        <select class="form-control" formControlName="period">
                          <option selected value="">Selecciona una opción</option>
                          <option *ngFor="let period of periodOptions" value="{{period.value}}">{{period.name}}</option>
                        </select>
                      </div>
                      <div class="col-12 col-md-4">
                        <label><span style="color: red">* </span> Lugar de
                          levantamiento:</label>
                        <select class="form-control" formControlName="id_lifting">
                          <option selected value="">Selecciona una opción</option>
                          <option value="Campaña">Campaña</option>
                          <option value="Oficina">Oficina</option>
                          <option value="Página de internet">Página de internet</option>
                          <option value="Llamada telefónica">Llamada telefónica</option>
                          <option value="Correo electrónico">Correo electrónico</option>
                          <option value="Facebook">Facebook</option>
                          <option value="WhatsApp">WhatsApp</option>
                          <option value="Campo">Campo</option>
                          <option value="SolicitApp">SolicitApp</option>
                          <option value="Chatbot">Chatbot</option>
                          <option value="Micrositio">Micrositio</option>
                        </select>
                      </div>
                    </div>
                    <div class="panel panel-info" style="padding-bottom: 0;margin-bottom: 0;">
                      <p class="divData">Datos del promovido</p>
                    </div>
                    <div class="row">
                      <div class="col-12 col-md-4">
                        <span style="color: red">* </span>
                        <label>Nombre(s):</label>
                        <input class="form-control" type="text" placeholder="Ingrese el nombre del promovido"
                          formControlName="name">
                      </div>
                      <div class="col-12 col-md-4">
                        <span style="color: red">* </span>
                        <label>Apellido paterno:</label>
                        <input class="form-control" type="text" placeholder="Ingrese el apellido paterno"
                          formControlName="next_name">
                      </div>
                      <div class="col-12 col-md-4">
                        <span style="color: red">* </span>
                        <label>Apellido materno:</label>
                        <input class="form-control" type="text" placeholder="Ingrese el apellido materno"
                          formControlName="last_name">
                      </div>
                      <div class="col-12 col-md-4">
                        <label>Fecha de nacimiento:</label>
                        <input class="form-control" type="date" placeholder="dd/mm/aaaa" formControlName="birth_date" />
                      </div>
                      <div class="col-12 col-md-4">
                        <label>Sexo:</label>
                        <select class="form-control" formControlName="gender">
                          <option selected value="">Selecciona una opción</option>
                          <option value="Femenino">Femenino</option>
                          <option value="Masculino">Masculino</option>
                          <option value="Otro">Otro</option>
                        </select>
                      </div>
                      <div class="col-12 col-md-4">
                        <label>
                          <span style="color: red">* </span>
                          Edad:
                        </label>
                        <input class="form-control" type="text" placeholder="" formControlName="age">
                      </div>
                      <div class="col-12 col-md-4">
                        <label>CURP:</label>
                        <input class="form-control" type="text" placeholder="Ingrese el CURP" maxlength="18"
                          formControlName="curp" />
                        <div class="row m-b-10 text-right p-r-20" style="display: block; color: #1d6cb6;">
                          <a href="https://consultas.curp.gob.mx/CurpSP/gobmx/inicio.jsp" target="_blank">Consultar
                            CURP</a>
                        </div>
                      </div>
                      <div class="col-12 col-md-4">
                        <label>Correo electrónico:</label>
                        <input class="form-control" type="email" placeholder="Ingrese un correo electrónico"
                          formControlName="email" />
                      </div>
                      <div class="col-12 col-md-4">
                        <label>Teléfono de casa:</label>
                        <input class="form-control" type="text" placeholder="Ingrese un teléfono local" minlength="10"
                          maxlength="10" formControlName="phone" />
                      </div>
                      <div class="col-12 col-md-4">
                        <span style="color: red">* </span>
                        <label>Teléfono celular:</label>
                        <input class="form-control" type="text" placeholder="Ingrese un teléfono celular" minlength="10"
                          maxlength="10" formControlName="cell_phone" />
                      </div>
                      <div class="col-12 col-md-4">
                        <label>Estado civil:</label>
                        <select class="form-control" formControlName="civil_status">
                          <option selected value="">Selecciona una opción</option>
                          <option value="Casado/a">Casado/a</option>
                          <option value="Divorciado/a">Divorciado/a</option>
                          <option value="Separado/a">Separado/a</option>
                          <option value="Soltero/a">Soltero/a</option>
                          <option value="Unión libre">Unión libre</option>
                          <option value="Viudo/a">Viudo/a</option>
                        </select>
                      </div>
                      <div class="col-12 col-md-4">
                        <label>Lugar de nacimiento (Estado):</label>
                        <select class="form-control" formControlName="birth_place" (change)="onFirstSelectChange()">
                          <option selected value="">Selecciona una opción</option>
                          <option *ngFor="let entitie of entities" value="{{entitie.id}}">
                            {{entitie.name}}</option>
                        </select>
                      </div>
                      <div class="col-12 col-md-4">
                        <label>Lugar de nacimiento (Municipio):</label>
                        <select class="form-control" formControlName="birth_placeM">
                          <option selected value="">Selecciona una opción</option>
                          <option *ngFor="let municipalitie of municipalities" value="{{municipalitie.id}}">
                            {{municipalitie.name}}</option>
                        </select>
                      </div>
                      <div class="col-12 col-md-4">
                        <label>Escolaridad:</label>
                        <select class="form-control" formControlName="scholarship">
                          <option selected value="">Selecciona una opción</option>
                          <option value="Primaria">Primaria</option>
                          <option value="Secundaria">Secundaria</option>
                          <option value="Preparatoria">Preparatoria</option>
                          <option value="Estudios técnicos">Estudios técnicos</option>
                          <option value="Licenciatura">Licenciatura</option>
                          <option value="Posgrado">Posgrado</option>
                          <option value="Ninguna">Ninguna</option>
                        </select>
                      </div>
                      <div class="col-12 col-md-4">
                        <label>Ocupación:</label>
                        <select class="form-control" formControlName="occupation">
                          <option selected value="">Selecciona una opción</option>
                          <option value="Agricultor/a y/o Productor/a rural">Agricultor/a y/o
                            Productor/a rural</option>
                          <option value="Labores domésticas">Labores domésticas</option>
                          <option value="Comerciante">Comerciante</option>
                          <option value="Docente">Docente</option>
                          <option value="Empleado/a">Empleado/a</option>
                          <option value="Empresario/a">Empresario/a</option>
                          <option value="Estudiante">Estudiante</option>
                          <option value="Obrero/a">Obrero/a</option>
                          <option value="Profesionista">Profesionista</option>
                          <option value="Servidor público">Servidor público</option>
                          <option value="Técnico/a">Técnico/a</option>
                          <option value="Ni estudia ni trabaja">Ni estudia ni trabaja</option>
                          <option value="Otro">Otro</option>
                        </select>
                      </div>
                      <div class="col-12 col-md-4">
                        <label>Lugar de trabajo:</label>
                        <input class="form-control" type="text" placeholder="Nombre de la empresa"
                          formControlName="workplace" />
                      </div>
                      <div class="col-12 col-md-4">
                        <label>Teléfono del trabajo:</label>
                        <input class="form-control" type="text" placeholder="Ingrese un teléfono del trabajo"
                          minlength="10" maxlength="10" formControlName="cell_phone_office" />
                      </div>
                      <div class="col-12 col-md-4">
                        <label>Facebook:</label>
                        <input class="form-control" type="text" placeholder="Ingrese Facebook"
                          formControlName="facebook" />
                      </div>
                      <div class="form-group col-12 col-md-4">
                        <label>Instagram:</label>
                        <input class="form-control" type="text" placeholder="Ingrese Instagram"
                          formControlName="instagram" />
                      </div>
                      <div class="form-group  col-12 col-md-4">
                        <label> <span style="color: red">* </span>¿Cuántas personas mayores de
                          edad habitan en esta vivienda?:</label>
                        <input class="form-control" type="text" placeholder="Ingrese un número"
                          formControlName="elderly" />
                      </div>
                      <div class="form-group  col-12 col-md-4">
                        <label>
                          Folio interno:</label>
                        <input class="form-control" type="number" placeholder="Ingrese el folio"
                          formControlName="folio_interno" />
                      </div>
                    </div>
                    <div class="panel panel-info" style="padding-bottom: 0;margin-bottom: 0;">
                      <p class="divData">Dirección</p>
                    </div>
                    <div class="row">
                      <div class="col-12 col-md-4">
                        <label>Calle:</label>
                        <input class="form-control" type="text" placeholder="Ingrese la calle"
                          formControlName="street" />
                      </div>
                      <div class="col-12 col-md-4">
                        <label>Número exterior:</label>
                        <input class="form-control" type="text" placeholder="Ingrese el número exterior"
                          formControlName="street_num_ext" />
                      </div>
                      <div class="col-12 col-md-4">
                        <label>Número interior:</label>
                        <input class="form-control" type="text" placeholder="Ingrese el número interior"
                          formControlName="street_num_int" />
                      </div>
                      <div class="col-12 col-md-4" *ngIf="postalCodeSelected == false else selectedPostal">
                        <label>Código postal:</label>
                        <div class="input-group">
                          <input type="text" placeholder="Ingrese el código postal" class="form-control"
                            formControlName="postal_code">
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary" type="button" (click)="searchPostalCode()">
                              <i class="fa fa-search" aria-hidden="true"></i>
                            </button>
                          </div>
                        </div>
                        <ng-container #menu *ngIf="postalCodeSearch.length != 0 && postalCodeSelected == false">
                          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 content-seach searchList">
                            <ng-container *ngIf="postalCodeSearch.length == 0 else searchMenu">
                              <label class="darkGray">No hay registros</label>
                            </ng-container>
                            <ng-template #searchMenu>
                              <ng-container *ngFor="let result of postalCodeSearch; let i = index">
                                <ng-container>
                                  <label (click)="selectPostalCode(result.identifier)"
                                    [ngClass]=" i%2==0 ? 'darkGray' : 'lowGray'">{{result.identifier}}</label>
                                </ng-container>
                              </ng-container>
                              <br>
                            </ng-template>
                          </div>
                        </ng-container>
                      </div>
                      <ng-template #selectedPostal>
                        <div class="col">
                          <label>Código postal:</label>
                          <div class="alert fade show">
                            <span class="close" (click)="clearPostalCode()" data-dismiss="alert">×</span>
                            <span
                              style="color: #707478; font-size: 15px;">{{promotedForm.controls['postal_code'].value}}</span>
                          </div>
                        </div>
                      </ng-template>
                      <div class="col-12 col-md-4">
                        <label>Colonia:</label>
                        <select class="form-control" formControlName="id_neighborhood">
                          <option selected value="">Seleccione una colonia</option>
                          <option *ngFor="let neighborhoodOption of neighborhoodOptions"
                            value="{{neighborhoodOption.id}}">{{neighborhoodOption.name}}
                          </option>
                        </select>
                      </div>
                      <div class="col-12 col-md-4">
                        <label>Municipio:</label>
                        <input class="form-control" placeholder="Cargando listado..." type="text" disabled
                          [value]="municipalitieName">
                      </div>
                      <div class="col-12 col-md-4">
                        <label>Estado:</label>
                        <input class="form-control" type="text" placeholder="Cargando listado..." disabled
                          [value]="entitieName">
                      </div>

                      <div class="col-12 col-md-4">
                        <label>Referencia:</label>
                        <input class="form-control" type="text" placeholder="Ingrese referencia al domicilio"
                          formControlName="reference" />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="footer-form">
              <button class="btn btn-primary" (click)="changeSelected(1)">Siguiente</button>
            </div>
          </mat-step>
          <mat-step>
            <div class="row">
              <div class="col-12">
                <div class="accordion accordion-flush" id="accordionPanelsStayOpenExample">

                  <div class="accordion-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                      <button class="accordion-button accordion-primary collapsed" type="button"
                        data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseOne">
                        <div class="col-12">
                          <i class="fa fa-check mr-3"></i>
                          {{surveyTittle}}
                        </div>
                      </button>
                    </h2>
                    <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse"
                      aria-labelledby="panelsStayOpen-headingOne">
                      <div class="accordion-body">
                        <div class="row">
                          <div class="col">
                            <div class="contenedor" style="padding: 0 !important;">
                              <div class="row">
                                <div class="col" *ngIf="questions.length > 0">
                                  <form [formGroup]="surveyForm">
                                    <div class="contenedor">
                                      <div class="row">
                                        <ng-container *ngFor="let question of questions">
                                          <div class="form-group  col-12 col-sm-12 col-xs-12 col-md-6 col-lg-4 mb-2"
                                            [id]="question.id" [class.hidden]="question.hidden === 1">
                                            <label><span class="reqForm">{{question.order
                                                }}.</span> {{
                                              question.name }}</label>
                                            <div #input [ngSwitch]="question.type">
                                              <div *ngSwitchCase="'select'">
                                                <select class="form-control" [formControlName]="question.control"
                                                  (change)="handleOption(question)">
                                                  <option selected value="">
                                                    Selecciona una
                                                    opción</option>
                                                  <option *ngFor="let option of question.form_options"
                                                    value="{{option.label}}">
                                                    {{option.label}}
                                                  </option>
                                                </select>
                                              </div>
                                              <div *ngSwitchCase="'binary'">
                                                <select class="form-control" [formControlName]="question.control"
                                                  (change)="handleOption(question)">
                                                  <option selected value="">
                                                    Selecciona una
                                                    opción</option>
                                                  <option *ngFor="let option of question.binaries"
                                                    value="{{option.label}}">
                                                    {{option.label}}
                                                  </option>
                                                </select>
                                              </div>
                                              <div *ngSwitchCase="'input'">
                                                <input class="form-control" type="text"
                                                  [placeholder]="question.placeholder"
                                                  [formControlName]="question.control">
                                              </div>
                                            </div>
                                          </div>
                                        </ng-container>
                                      </div>
                                    </div>
                                  </form>
                                </div>

                                <div class="col-12">
                                  <form [formGroup]="promotedForm">
                                    <div class="row mb-3 p-4">
                                      <div class="col-12 col-md-4">
                                        <label>Bitácora:</label>
                                        <textarea class="form-control" formControlName="bitacora"
                                          placeholder=""></textarea>
                                      </div>
                                    </div>
                                  </form>
                                </div>

                              </div>
                              <div class="row">
                                <div class="panel panel-info" style="padding-bottom: 0;margin-bottom: 0;">
                                  <p class="divData">Visita</p>
                                </div>
                                <div class="col-12" *ngIf="visits">
                                  <div class="row">
                                    <div class="col-md-9">
                                      <p>{{ visits.nameUserVisitor }} realizó la
                                        visita.</p>
                                    </div>
                                    <div class="col-md-3 date">{{ visits.date }}
                                    </div>
                                    <div class="col-md-12">
                                      <p>{{ visits.note }} </p>
                                    </div>
                                    <div class="col-md-12">
                                      <label>Evidencia</label>
                                      <div *ngFor="let evidence_image of visits.evidence_image" class="row">
                                        <div class="col mb-3">
                                          <img style="max-width: 8rem;" src="{{evidence_image}}" alt="">
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="panel panel-info" style="padding-bottom: 0;margin-bottom: 0;">
                                  <p class="divData">Gestión (opcional)</p>
                                </div>
                                <div class="col-12">
                                  <div class="accordion accordion-flush" id="subAccordionPanelsStayOpenExample">
                                    <div class="accordion-item">
                                      <h2 class="accordion-header" id="subPanelsStayOpen-headingOne">
                                        <button class="accordion-button accordion-secondary collapsed" type="button"
                                          data-bs-toggle="collapse" data-bs-target="#subPanelsStayOpen-collapseOne"
                                          aria-expanded="false" aria-controls="subPanelsStayOpen-collapseOne">
                                          <div class="col-12">
                                            Primera problemática de la calle
                                          </div>
                                        </button>
                                      </h2>
                                      <div id="subPanelsStayOpen-collapseOne" class="accordion-collapse collapse"
                                        aria-labelledby="subPanelsStayOpen-headingOne">
                                        <div class="accordion-body">
                                          <div class="row">
                                            <div class="col">
                                              <div class="contenedor">
                                                <div class="row" *ngIf="problematics[0] && problematics[0].theme_id">
                                                  <div class="col-12 col-md-4">
                                                    <label class="p-t-2">Tema:</label>
                                                    <div class="form-control-disable">
                                                      {{
                                                      problematics[0].theme_name
                                                      }}</div>
                                                  </div>
                                                  <div class="col-12 col-md-4">
                                                    <label class="p-t-2">Subtema:</label>
                                                    <div class="form-control-disable">
                                                      {{
                                                      problematics[0].subtheme_name
                                                      }}</div>
                                                  </div>
                                                  <div class="col-12 col-md-4">
                                                    <label class="p-t-2">Clasificación:</label>
                                                    <div class="form-control-disable">
                                                      {{
                                                      problematics[0].category_name
                                                      }}</div>
                                                  </div>
                                                  <div class="col-12 col-md-4">
                                                    <label class="p-t-2">Descripción:
                                                    </label>
                                                    <div class="form-control-disable">
                                                      {{
                                                      problematics[0].description
                                                      }}</div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="accordion-item">
                                      <h2 class="accordion-header" id="subPanelsStayOpen-headingTwo">
                                        <button class="accordion-button accordion-secondary collapsed" type="button"
                                          data-bs-toggle="collapse" data-bs-target="#subPanelsStayOpen-collapseTwo"
                                          aria-expanded="false" aria-controls="subPanelsStayOpen-collapseTwo">
                                          <div class="col-12">
                                            Segunda problemática de la calle
                                          </div>
                                        </button>
                                      </h2>
                                      <div id="subPanelsStayOpen-collapseTwo" class="accordion-collapse collapse"
                                        aria-labelledby="subPanelsStayOpen-headingTwo">
                                        <div class="accordion-body">
                                          <div class="row">
                                            <div class="col">
                                              <div class="contenedor">
                                                <div class="row" *ngIf="problematics[1] && problematics[1].theme_id">
                                                  <div class="col-12 col-md-4">
                                                    <label class="p-t-2">Tema:</label>
                                                    <div class="form-control-disable">
                                                      {{
                                                      problematics[1].theme_name
                                                      }}</div>
                                                  </div>
                                                  <div class="col-12 col-md-4">
                                                    <label class="p-t-2">Subtema:</label>
                                                    <div class="form-control-disable">
                                                      {{
                                                      problematics[1].subtheme_name
                                                      }}</div>
                                                  </div>
                                                  <div class="col-12 col-md-4">
                                                    <label class="p-t-2">Clasificación:</label>
                                                    <div class="form-control-disable">
                                                      {{
                                                      problematics[1].category_name
                                                      }}</div>
                                                  </div>
                                                  <div class="col-12 col-md-4">
                                                    <label class="p-t-2">Descripción:</label>
                                                    <div class="form-control-disable">
                                                      {{
                                                      problematics[1].description
                                                      }}</div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="accordion-item">
                                      <h2 class="accordion-header" id="subPanelsStayOpen-headingThree">
                                        <button class="accordion-button accordion-secondary collapsed" type="button"
                                          data-bs-toggle="collapse" data-bs-target="#subPanelsStayOpen-collapseThree"
                                          aria-expanded="false" aria-controls="SubPanelsStayOpen-collapseThree">
                                          <div class="col-12">
                                            Primera problemática de la
                                            colonia
                                          </div>
                                        </button>
                                      </h2>
                                      <div id="subPanelsStayOpen-collapseThree" class="accordion-collapse collapse"
                                        aria-labelledby="subPanelsStayOpen-headingThree">
                                        <div class="accordion-body">
                                          <div class="row">
                                            <div class="col">
                                              <div class="contenedor">
                                                <div class="row" *ngIf="problematics[2] && problematics[2].theme_id">
                                                  <div class="col-12 col-md-4">
                                                    <label class="p-t-2">Tema:</label>
                                                    <div class="form-control-disable">
                                                      {{
                                                      problematics[2].theme_name
                                                      }}</div>
                                                  </div>
                                                  <div class="col-12 col-md-4">
                                                    <label class="p-t-2">Subtema:</label>
                                                    <div class="form-control-disable">
                                                      {{
                                                      problematics[2].subtheme_name
                                                      }}</div>
                                                  </div>
                                                  <div class="col-12 col-md-4">
                                                    <label class="p-t-2">Clasificación:</label>
                                                    <div class="form-control-disable">
                                                      {{
                                                      problematics[2].category_name
                                                      }}</div>
                                                  </div>
                                                  <div class="col-12 col-md-4">
                                                    <label class="p-t-2">Descripción:</label>
                                                    <div class="form-control-disable">
                                                      {{
                                                      problematics[2].description
                                                      }}</div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="accordion-item">
                                      <h2 class="accordion-header" id="subPanelsStayOpen-headingFour">
                                        <button class="accordion-button accordion-secondary collapsed" type="button"
                                          data-bs-toggle="collapse" data-bs-target="#subPanelsStayOpen-collapseFour"
                                          aria-expanded="false" aria-controls="subPanelsStayOpen-collapseFour">
                                          <div class="col-12">
                                            Segunda problemática de la
                                            colonia
                                          </div>
                                        </button>
                                      </h2>
                                      <div id="subPanelsStayOpen-collapseFour" class="accordion-collapse collapse"
                                        aria-labelledby="subPanelsStayOpen-headingFour">
                                        <div class="accordion-body">
                                          <div class="row">
                                            <div class="col">
                                              <div class="contenedor">
                                                <div class="row" *ngIf="problematics[3] && problematics[3].theme_id">
                                                  <div class="col-12 col-md-4">
                                                    <label class="p-t-2">Tema:</label>
                                                    <div class="form-control-disable">
                                                      {{
                                                      problematics[3].theme_name
                                                      }}</div>
                                                  </div>
                                                  <div class="col-12 col-md-4">
                                                    <label class="p-t-2">Subtema:</label>
                                                    <div class="form-control-disable">
                                                      {{
                                                      problematics[3].subtheme_name
                                                      }}</div>
                                                  </div>
                                                  <div class="col-12 col-md-4">
                                                    <label class="p-t-2">Clasificación:</label>
                                                    <div class="form-control-disable">
                                                      {{
                                                      problematics[3].category_name
                                                      }}</div>
                                                  </div>
                                                  <div class="col-12 col-md-4">
                                                    <label class="p-t-2">Descripción:</label>
                                                    <div class="form-control-disable">
                                                      {{
                                                      problematics[3].description
                                                      }}</div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ng-container *ngIf="idWorkGroup == '95131a90d2da7ce01cac1eeed6da7cf0'">
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                        <button class="accordion-button accordion-primary collapsed" type="button"
                          data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseOne">
                          <div class="col-12">
                            <i class="fa fa-house"></i>
                            {{surveyTittle}}
                          </div>
                        </button>
                      </h2>
                      <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse"
                        aria-labelledby="panelsStayOpen-headingOne">
                        <div class="accordion-body">
                          <div class="row">
                            <div class="col">
                              <div class="contenedor" style="padding: 0 !important;">
                                <div class="row">
                                  <div class="col" *ngIf="questions.length > 0">
                                    <form [formGroup]="surveyForm">
                                      <div class="contenedor">
                                        <div class="row">
                                          <ng-container *ngFor="let question of questions">
                                            <div class="form-group  col-12 col-sm-12 col-xs-12 col-md-6 col-lg-4 mb-2"
                                              [id]="question.id" [class.hidden]="question.hidden === 1">
                                              <label><span class="reqForm">{{question.order
                                                  }}.</span> {{
                                                question.name }}</label>
                                              <div #input [ngSwitch]="question.type">
                                                <div *ngSwitchCase="'select'">
                                                  <select class="form-control" [formControlName]="question.control"
                                                    [disabled]="true" (change)="handleOption(question)">
                                                    <option selected value="">
                                                      Selecciona
                                                      una
                                                      opción
                                                    </option>
                                                    <option *ngFor="let option of question.form_options"
                                                      value="{{option.label}}">
                                                      {{option.label}}
                                                    </option>
                                                  </select>
                                                </div>
                                                <div *ngSwitchCase="'binary'">
                                                  <select class="form-control" [formControlName]="question.control"
                                                    [disabled]="true" (change)="handleOption(question)">
                                                    <option selected value="">
                                                      Selecciona
                                                      una
                                                      opción
                                                    </option>
                                                    <option *ngFor="let option of question.binaries"
                                                      value="{{option.label}}">
                                                      {{option.label}}
                                                    </option>
                                                  </select>
                                                </div>
                                                <div *ngSwitchCase="'input'">
                                                  <input class="form-control" type="text"
                                                    [placeholder]="question.placeholder"
                                                    [formControlName]="question.control" [disabled]="true">
                                                </div>
                                              </div>
                                            </div>
                                          </ng-container>
                                        </div>
                                      </div>
                                    </form>
                                  </div>

                                  <div class="col-12">
                                    <form [formGroup]="promotedForm">
                                      <div class="row mb-3 p-4">
                                        <div class="col-12 col-md-4">
                                          <label>Bitácora:</label>
                                          <textarea class="form-control" formControlName="bitacora"
                                            placeholder=""></textarea>
                                        </div>
                                      </div>
                                    </form>
                                  </div>

                                </div>
                                <div class="row">
                                  <div class="panel panel-info" style="padding-bottom: 0;margin-bottom: 0;">
                                    <p class="divData">Visita</p>
                                  </div>
                                  <div class="col-12" *ngIf="visits">
                                    <div class="row">
                                      <div class="col-md-9">
                                        <p>{{ visits.nameUserVisitor }} realizó
                                          la
                                          visita.</p>
                                      </div>
                                      <div class="col-md-3 date">{{ visits.date }}
                                      </div>
                                      <div class="col-md-12">
                                        <p>{{ visits.note }} </p>
                                      </div>
                                      <div class="col-md-12">
                                        <label>Evidencia</label>
                                        <div *ngFor="let evidence_image of visits.evidence_image" class="row">
                                          <div class="col mb-3">
                                            <img style="max-width: 8rem;" src="{{evidence_image}}" alt="">
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="panel panel-info" style="padding-bottom: 0;margin-bottom: 0;">
                                    <p class="divData">Gestión (opcional)</p>
                                  </div>
                                  <div class="col-12">
                                    <div class="accordion accordion-flush" id="subAccordionPanelsStayOpenExample">
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="subPanelsStayOpen-headingOne">
                                          <button class="accordion-button accordion-secondary collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#subPanelsStayOpen-collapseOne"
                                            aria-expanded="false" aria-controls="subPanelsStayOpen-collapseOne">
                                            <div class="col-12">
                                              Primera problemática de la
                                              calle
                                            </div>
                                          </button>
                                        </h2>
                                        <div id="subPanelsStayOpen-collapseOne" class="accordion-collapse collapse"
                                          aria-labelledby="subPanelsStayOpen-headingOne">
                                          <div class="accordion-body">
                                            <div class="row">
                                              <div class="col">
                                                <div class="contenedor">
                                                  <div class="row" *ngIf="problematics[0] && problematics[0].theme_id">
                                                    <div class="col-12 col-md-4">
                                                      <label class="p-t-2">Tema:</label>
                                                      <div class="form-control-disable">
                                                        {{
                                                        problematics[0].theme_name
                                                        }}</div>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                      <label class="p-t-2">Subtema:</label>
                                                      <div class="form-control-disable">
                                                        {{
                                                        problematics[0].subtheme_name
                                                        }}</div>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                      <label class="p-t-2">Clasificación:</label>
                                                      <div class="form-control-disable">
                                                        {{
                                                        problematics[0].category_name
                                                        }}</div>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                      <label class="p-t-2">Descripción:
                                                      </label>
                                                      <div class="form-control-disable">
                                                        {{
                                                        problematics[0].description
                                                        }}</div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="subPanelsStayOpen-headingTwo">
                                          <button class="accordion-button accordion-secondary collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#subPanelsStayOpen-collapseTwo"
                                            aria-expanded="false" aria-controls="subPanelsStayOpen-collapseTwo">
                                            <div class="col-12">
                                              Segunda problemática de la
                                              calle
                                            </div>
                                          </button>
                                        </h2>
                                        <div id="subPanelsStayOpen-collapseTwo" class="accordion-collapse collapse"
                                          aria-labelledby="subPanelsStayOpen-headingTwo">
                                          <div class="accordion-body">
                                            <div class="row">
                                              <div class="col">
                                                <div class="contenedor">
                                                  <div class="row" *ngIf="problematics[1] && problematics[1].theme_id">
                                                    <div class="col-12 col-md-4">
                                                      <label class="p-t-2">Tema:</label>
                                                      <div class="form-control-disable">
                                                        {{
                                                        problematics[1].theme_name
                                                        }}</div>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                      <label class="p-t-2">Subtema:</label>
                                                      <div class="form-control-disable">
                                                        {{
                                                        problematics[1].subtheme_name
                                                        }}</div>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                      <label class="p-t-2">Clasificación:</label>
                                                      <div class="form-control-disable">
                                                        {{
                                                        problematics[1].category_name
                                                        }}</div>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                      <label class="p-t-2">Descripción:</label>
                                                      <div class="form-control-disable">
                                                        {{
                                                        problematics[1].description
                                                        }}</div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="subPanelsStayOpen-headingThree">
                                          <button class="accordion-button accordion-secondary collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#subPanelsStayOpen-collapseThree"
                                            aria-expanded="false" aria-controls="SubPanelsStayOpen-collapseThree">
                                            <div class="col-12">
                                              Primera problemática de la
                                              colonia
                                            </div>
                                          </button>
                                        </h2>
                                        <div id="subPanelsStayOpen-collapseThree" class="accordion-collapse collapse"
                                          aria-labelledby="subPanelsStayOpen-headingThree">
                                          <div class="accordion-body">
                                            <div class="row">
                                              <div class="col">
                                                <div class="contenedor">
                                                  <div class="row" *ngIf="problematics[2] && problematics[2].theme_id">
                                                    <div class="col-12 col-md-4">
                                                      <label class="p-t-2">Tema:</label>
                                                      <div class="form-control-disable">
                                                        {{
                                                        problematics[2].theme_name
                                                        }}</div>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                      <label class="p-t-2">Subtema:</label>
                                                      <div class="form-control-disable">
                                                        {{
                                                        problematics[2].subtheme_name
                                                        }}</div>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                      <label class="p-t-2">Clasificación:</label>
                                                      <div class="form-control-disable">
                                                        {{
                                                        problematics[2].category_name
                                                        }}</div>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                      <label class="p-t-2">Descripción:</label>
                                                      <div class="form-control-disable">
                                                        {{
                                                        problematics[2].description
                                                        }}</div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="subPanelsStayOpen-headingFour">
                                          <button class="accordion-button accordion-secondary collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#subPanelsStayOpen-collapseFour"
                                            aria-expanded="false" aria-controls="subPanelsStayOpen-collapseFour">
                                            <div class="col-12">
                                              Segunda problemática de la
                                              colonia
                                            </div>
                                          </button>
                                        </h2>
                                        <div id="subPanelsStayOpen-collapseFour" class="accordion-collapse collapse"
                                          aria-labelledby="subPanelsStayOpen-headingFour">
                                          <div class="accordion-body">
                                            <div class="row">
                                              <div class="col">
                                                <div class="contenedor">
                                                  <div class="row" *ngIf="problematics[3] && problematics[3].theme_id">
                                                    <div class="col-12 col-md-4">
                                                      <label class="p-t-2">Tema:</label>
                                                      <div class="form-control-disable">
                                                        {{
                                                        problematics[3].theme_name
                                                        }}</div>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                      <label class="p-t-2">Subtema:</label>
                                                      <div class="form-control-disable">
                                                        {{
                                                        problematics[3].subtheme_name
                                                        }}</div>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                      <label class="p-t-2">Clasificación:</label>
                                                      <div class="form-control-disable">
                                                        {{
                                                        problematics[3].category_name
                                                        }}</div>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                      <label class="p-t-2">Descripción:</label>
                                                      <div class="form-control-disable">
                                                        {{
                                                        problematics[3].description
                                                        }}</div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                        <button class="accordion-button accordion-primary collapsed" type="button"
                          data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseTwo">
                          <div class="col-12">
                            <i class="fa fa-house"></i>
                            Visita 2
                          </div>
                        </button>
                      </h2>
                      <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse"
                        aria-labelledby="panelsStayOpen-headingTwo">
                        <div class="accordion-body" style="display: flex;align-items: center;justify-content: center;">
                          <div style="text-align: center; padding: 20px;">
                            <i class="fas fa-cog fa-spin" style="font-size: 48px; color: #888;"></i>
                            <p>Prepárate para descubrir nuevas funcionalidades proximamente.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                        <button class="accordion-button accordion-primary collapsed" type="button"
                          data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree">
                          <div class="col-12">
                            <i class="fa fa-house"></i>
                            Visita 3
                          </div>
                        </button>
                      </h2>
                      <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse"
                        aria-labelledby="panelsStayOpen-headingThree">
                        <div class="accordion-body" style="display: flex;align-items: center;justify-content: center;">
                          <div style="text-align: center; padding: 20px;">
                            <i class="fas fa-cog fa-spin" style="font-size: 48px; color: #888;"></i>
                            <p>Prepárate para descubrir nuevas funcionalidades proximamente.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="panelsStayOpen-headingFour">
                        <button class="accordion-button accordion-primary collapsed" type="button"
                          data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseFour">
                          <div class="col-12">
                            <i class="fa fa-house"></i>
                            Visita 4
                          </div>
                        </button>
                      </h2>
                      <div id="panelsStayOpen-collapseFour" class="accordion-collapse collapse"
                        aria-labelledby="panelsStayOpen-headingFour">
                        <div class="accordion-body" style="display: flex;align-items: center;justify-content: center;">
                          <div style="text-align: center; padding: 20px;">
                            <i class="fas fa-cog fa-spin" style="font-size: 48px; color: #888;"></i>
                            <p>Prepárate para descubrir nuevas funcionalidades proximamente.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
              <div class="col-12">
                <div class="contenedor" style="padding: 20px; padding-top: 0;">
                  <div class="panel panel-info" style="padding-bottom: 0;margin-bottom: 0;">
                    <p class="divData">Clasificación del promovido</p>
                  </div>
                  <form [formGroup]="promotedForm">
                    <div class="row mb-3">
                      <div class="col-12 col-md-4">
                        <label>¿Cuál es su preferencia partidaria?</label>
                      </div>
                      <div class="col-12 col-md-4">
                        <select class="form-control" formControlName="position">
                          <option selected value="">Seleccione una opción</option>
                          <option value="POSITIVO">POSITIVO</option>
                          <option value="NEUTRO">NEUTRO</option>
                          <option value="NEGATIVO">NEGATIVO</option>
                        </select>
                      </div>
                    </div>
                    <div class="row mb-3" *ngIf="promotedForm.get('position')?.value == 'POSITIVO'">
                      <div class="col-12 col-md-4">
                        <label>¿Le gustaría participar en la campaña?</label>
                      </div>
                      <div class="col-12 col-md-4">
                        <select class="form-control" formControlName="position_type">
                          <option selected value="">Seleccione una opción</option>
                          <option value="APOYA">APOYA</option>
                          <option value="DIFUNDE">DIFUNDE</option>
                          <option value="VOTA">VOTA</option>
                        </select>
                      </div>
                    </div>
                    <div class="row mb-3" *ngIf="promotedForm.get('position')?.value == 'NEUTRO'">
                      <div class="col-12 col-md-4">
                        <label>¿Cuál es la preferencia de su voto?</label>
                      </div>
                      <div class="col-12 col-md-4">
                        <select class="form-control" formControlName="vote_preference">
                          <option selected value="">Seleccione una opción</option>
                          <ng-container *ngIf="idWorkGroup == '95131a90d2da7ce01cac1eeed6da7cf0'; else PRI_option">
                            <option value="PRO MORENA">PRO MORENA</option>
                          </ng-container>
                          <ng-template #PRI_option>
                            <option value="PRO PRI">PRO PRI</option>
                          </ng-template>
                          <option value="PRO OPOSICIÓN">PRO OPOSICIÓN</option>
                        </select>
                      </div>
                    </div>
                    <div class="row mb-3"
                      *ngIf="promotedForm.get('position')?.value == 'POSITIVO' || promotedForm.get('position')?.value == 'NEUTRO'">
                      <div class="col-12 col-md-4">
                        <label>¿Tiene algún tipo de participación?</label>
                      </div>
                      <div class="col-12 col-md-4">
                        <div class="col-md-12">
                          <div class="radio radio-css radio-inline">
                            <input id="Participationyes" formControlName="participation" type="radio" [value]="1"
                              [checked]="promotedForm.get('position')?.value == 'POSITIVO' || promotedForm.get('position')?.value == 'NEUTRO'">
                            <label for="Participationyes">Sí</label>
                          </div>
                          <div class="radio radio-css radio-inline">
                            <input id="Participationnot" formControlName="participation" type="radio" [value]="0">
                            <label for="Participationnot">No</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3"
                      *ngIf="(promotedForm.get('position')?.value == 'POSITIVO' || promotedForm.get('position')?.value == 'NEUTRO') && promotedForm.get('participation')?.value == 1">
                      <div class="col-12 col-md-4">
                        <label>¿Cuál?</label>
                      </div>
                      <div class="col-12 col-md-4">
                        <ng-container *ngIf="promotedForm.get('position')?.value == 'POSITIVO' else NeutroOptions">
                          <select class="form-control" formControlName="participation_type">
                            <option selected value="">Seleccione una opción</option>
                            <option value="PROMOCIÓN DEL VOTO (APOYA)">PROMOCIÓN DEL VOTO
                              (APOYA)</option>
                            <option value="CUIDANDO UNA CASILLA (APOYA)">CUIDANDO UNA
                              CASILLA (APOYA)</option>
                            <option value="COLOCACIÓN Y DISTRIBUCIÓN DE PROPAGANDA (DIFUNDE)">
                              COLOCACIÓN Y DISTRIBUCIÓN DE PROPAGANDA (DIFUNDE)</option>
                            <option value="LLEVAR A CABO UNA REUNIÓN CON VISITA DEL CANDIDATO (DIFUNDE)">
                              LLEVAR A CABO UNA REUNIÓN CON VISITA DEL CANDIDATO (DIFUNDE)
                            </option>
                            <option value="PROMOCIÓN PERSONALIZADA (DIFUNDE)">
                              PROMOCIÓNPERSONALIZADA (DIFUNDE)</option>
                            <option value="VOTANTE">VOTANTE</option>
                          </select>
                        </ng-container>
                        <ng-template #NeutroOptions>
                          <select class="form-control" formControlName="participation_type">
                            <option selected value="">Seleccione una opción</option>
                            <option value="REQUIERE UNA VISITA DEL CANDIDATO (CONVENCIMIENTO)">
                              REQUIERE UNA VISITA DEL CANDIDATO (CONVENCIMIENTO)</option>
                            <option value="MÁS INFORMACIÓN (CONVENCIMIENTO)">MÁS INFORMACIÓN
                              (CONVENCIMIENTO)</option>
                          </select>
                        </ng-template>
                      </div>
                    </div>
                    <ng-container *ngIf="promotedForm.get('position')?.value == 'NEGATIVO'">
                      <div class="row mb-3">
                        <div class="col-12 col-md-4">
                          <label>¿Considera que hay un rechazo de su parte hacia los partidos
                            políticos?</label>
                        </div>
                        <div class="col-12 col-md-4">
                          <select class="form-control" formControlName="political_rejection">
                            <option selected value="">Seleccione una opción</option>
                            <option value="SÍ">SÍ</option>
                            <option value="NO">NO</option>
                          </select>
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col-12 col-md-4">
                          <label>¿Simpatiza y/o es militante de la oposición?</label>
                        </div>
                        <div class="col-12 col-md-4">
                          <select class="form-control" formControlName="political_militant">
                            <option selected value="">Seleccione una opción</option>
                            <option value="SÍ">SÍ</option>
                            <option value="NO">NO</option>
                          </select>
                        </div>
                      </div>
                      <!-- <div class="row mb-3">
                                                <div class="col-12 col-md-4">
                                                    <label>¿Hay un rechazo hacia Morena?</label>
                                                </div>
                                                <div class="col-12 col-md-4">
                                                    <select class="form-control">
                                                        <option selected value="">Seleccione una opción</option>
                                                        <option value="SÍ">SÍ</option>
                                                        <option value="NO">NO</option>
                                                    </select>
                                                </div>
                                            </div> -->
                    </ng-container>
                    <div class="row mb-3">
                      <div class="col-12 col-md-4">
                        <label>¿Se relaciona con un punto de interés? </label>
                      </div>
                      <div class="col-12 col-md-4">
                        <div class="col-md-12">
                          <div class="radio radio-css radio-inline">
                            <input id="Poiyes" formControlName="poi_related" type="radio" [value]="1">
                            <label for="Poiyes">Sí</label>
                          </div>
                          <div class="radio radio-css radio-inline">
                            <input id="Poinot" formControlName="poi_related" type="radio" [value]="0" checked="">
                            <label for="Poinot">No</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3" *ngIf="promotedForm.get('poi_related')?.value == '1'">
                      <div class="col-12 col-md-4">
                        <label>¿Cuál?</label>
                      </div>
                      <div class="col-12 col-md-4">
                        <select class="form-control" formControlName="id_poi">
                          <option selected value="0">Seleccione una opción</option>
                          <option value="{{POI.id}}" *ngFor="let POI of POIoptions">
                            {{POI.name}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12 col-md-4">
                        <label>¿Es un actor importante?</label>
                      </div>
                      <div class="col-12 col-md-4">
                        <div class="col-md-12">
                          <div class="radio radio-css radio-inline">
                            <input id="Actoryes" formControlName="actor" type="radio" [value]="1">
                            <label for="Actoryes">Sí</label>
                          </div>
                          <div class="radio radio-css radio-inline">
                            <input id="Actornot" formControlName="actor" type="radio" [value]="0" checked="">
                            <label for="Actornot">No</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3" *ngIf="promotedForm.get('actor')?.value == '1'">
                      <div class="col-12 col-md-4">
                        <label>¿Qué tipo de actor es?</label>
                      </div>
                      <div class="col-12 col-md-4">
                        <select class="form-control" formControlName="actor_type">
                          <option selected value="">Seleccione una opción</option>
                          <option value="EMPRESARIO">EMPRESARIO</option>
                          <option value="LÍDER EDUCATIVO">LÍDER EDUCATIVO</option>
                          <option value="LÍDER RELIGIOSO">LÍDER RELIGIOSO</option>
                          <option value="LÍDER DE MEDIO DE COMUNICACIÓN">LÍDER DE MEDIO DE
                            COMUNICACIÓN</option>
                          <option value="LÍDER DE CÁMARA O ASOCIACIÓN EMPRESARIAL">LÍDER DE
                            CÁMARA O ASOCIACIÓN EMPRESARIAL</option>
                          <option value="LÍDER DEL SECTOR SALUD">LÍDER DEL SECTOR SALUD
                          </option>
                          <option value="LÍDER SINDICAL">LÍDER SINDICAL</option>
                          <option value="LÍDER POLÍTICO">LÍDER POLÍTICO</option>
                          <option value="LÍDER DE COLONIA">LÍDER DE COLONIA</option>
                          <option value="REPRESENTANTE DE A.C. / ONG">REPRESENTANTE DE A.C. /
                            ONG</option>
                          <option value="LÍDER JUVENIL">LÍDER JUVENIL</option>
                          <option value="LÍDER DE MUJERES">LÍDER DE MUJERES</option>
                          <option value="OTRO">OTRO</option>
                        </select>
                      </div>
                    </div>
                    <div class="row mb-3" *ngIf="promotedForm.get('actor_type')?.value == 'OTRO'">
                      <div class="col-12 col-md-4">
                        <label>¿Cuál?</label>
                      </div>
                      <div class="col-12 col-md-4">
                        <input class="form-control" type="text" placeholder="" formControlName="other_actor" />
                      </div>
                    </div>

                  </form>
                </div>
              </div>
            </div>
            <div class="footer-form">
              <button class="btn btn-primary" (click)="changeSelected(2)">Siguiente</button>
              <button class="btn btn-light mr-2" (click)="changeSelected(0)">Anterior</button>
            </div>
          </mat-step>
          <mat-step>
            <div class="row">
              <div class="col-12">
                <div class="contenedor" style="padding: 10px; padding-top: 0;">
                  <div class="panel panel-info" style="padding-bottom: 0;margin-bottom: 0;">
                    <p class="divData">Documentación entregada</p>
                  </div>
                </div>
                <div class="row p-4 pt-0">
                  <div class="col-12 col-md-4">
                    <div class="col-md-12">
                      <label>INE:</label>
                    </div>
                    <div class="col-md-12 offset-md-1 checkbox checkbox-css checkbox-inline">
                      <input id="checkboxIne" type="checkbox" [checked]="promotedForm.get('ine')?.value === 1"
                        (change)="updateCheckbox($event,'Ine')" />
                      <label for="checkboxIne">Si</label>
                    </div>
                  </div>
                  <div class="row" *ngIf="promotedForm.get('ine')?.value ">
                    <div class="col-10 offset-1" *ngIf="!AllUrlIne.length">
                      <div class="cont-image">
                        <label class="form-label" for="multiple_files">Agregar INE</label>
                        <input class="form-control-file" type="file" id="multiple_files"
                          accept=".pdf,.doc,.png,.jpg,.jpeg" multiple (change)="onFileChange($event)" />
                        <p class="mt-1 text-sm text-gesco" id="file_input_help">(JPEG/PNG/PDF)
                        </p>
                      </div>
                    </div>
                    <div class="row" *ngIf="AllUrlIne.length">
                      <div class="col">
                        <label>Archivo de iniciativa:</label>
                        <div class="row ml-3">
                          <label class="col-md-12 p-t-2">Archivos cargados:</label>
                          <ng-container *ngFor="let url of AllUrlIne; let i = index">
                            <div class="drop-file">
                              <a class="alert alert-drop-file show text-left cursor-pointer">
                                <span class="close text-danger" data-idx="0" (click)="removeFile(url,'INE')">×</span>
                                <div (click)="openImage(url)">
                                  <i style="margin-top:-10px;"
                                    class="fa fa-file-image text-purple p-r-5 fa-3x pull-left"></i>
                                  {{getFileName(url)}}
                                </div>
                              </a>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-4">
                    <div class="col-md-12">
                      <label>Acta de nacimiento:</label>
                    </div>
                    <div class="col-md-12 offset-md-1 checkbox checkbox-css checkbox-inline">
                      <input id="checkboxCertificate" type="checkbox"
                        [checked]="promotedForm.get('acta_nacimiento')?.value === 1"
                        (change)="updateCheckbox($event,'Certificate')" />
                      <label for="checkboxCertificate">Si</label>
                    </div>
                  </div>
                  <div class="col-12 col-md-4">
                    <div class="col-md-12">
                      <label>Pasaporte:</label>
                    </div>
                    <div class="col-md-12 offset-md-1 checkbox checkbox-css checkbox-inline">
                      <input id="checkboxPassport" type="checkbox"
                        [checked]="promotedForm.get('pasaporte')?.value === 1"
                        (change)="updateCheckbox($event,'Passport')" />
                      <label for="checkboxPassport">Si</label>
                    </div>
                  </div>
                  <div class="col-12 col-md-4">
                    <div class="col-md-12">
                      <label>Comprobante de domicilio:</label>
                    </div>
                    <div class="col-md-12 offset-md-1 checkbox checkbox-css checkbox-inline">
                      <input id="checkboxProof" type="checkbox"
                        [checked]="promotedForm.get('comprobante_domicilio')?.value === 1"
                        (change)="updateCheckbox($event,'Proof')" />
                      <label for="checkboxProof">Si</label>
                    </div>
                  </div>
                  <div class="col-12 col-md-4">
                    <div class="col-md-12">
                      <label>CURP:</label>
                    </div>
                    <div class="col-md-12 offset-md-1 checkbox checkbox-css checkbox-inline">
                      <input id="checkboxCurp" type="checkbox" [checked]="promotedForm.get('curp_doc')?.value === 1"
                        (change)="updateCheckbox($event,'Curp')" />
                      <label for="checkboxCurp">Si</label>
                    </div>
                  </div>
                  <div class="col-12 col-md-4">
                    <div class="col-md-12">
                      <label>RFC:</label>
                    </div>
                    <div class="col-md-12 offset-md-1 checkbox checkbox-css checkbox-inline">
                      <input id="checkboxRfc" type="checkbox" [checked]="promotedForm.get('rfc')?.value === 1"
                        (change)="updateCheckbox($event,'Rfc')" />
                      <label for="checkboxRfc">Si</label>
                    </div>
                  </div>
                  <div class="col-12 col-md-4">
                    <div class="col-md-12">
                      <label>Otros:</label>
                    </div>
                    <div class="col-md-12 offset-md-1 checkbox checkbox-css checkbox-inline">
                      <input id="checkboxWhich" type="checkbox" [checked]="promotedForm.get('other_file')?.value === 1"
                        (change)="updateCheckbox($event,'Which')" />
                      <label for="checkboxWhich">Si</label>
                    </div>
                  </div>
                  <div class="col-12 col-md-4" *ngIf="promotedForm.get('other_file')?.value">
                    <label>¿Cuáles?</label>
                    <input class="form-control" type="text" placeholder="¿Cuáles?" formControlName="other_file_type" />
                  </div>

                  <div class="col-12 col-md-4">
                    <div class="col-md-12">
                      <label>Acuse:</label>
                    </div>
                    <div class="col-md-12 offset-md-1 checkbox checkbox-css checkbox-inline">
                      <input id="checkboxAcuse" type="checkbox" [checked]="promotedForm.get('docAcuse')?.value === 1"
                        (change)="updateCheckbox($event,'Acuse')" />
                      <label for="checkboxAcuse">Si</label>
                    </div>
                  </div>

                  <div class="row" *ngIf="promotedForm.get('docAcuse')?.value ">
                    <div class="col-10 offset-1" *ngIf="!AllUrlAcuse.length">
                      <div class="cont-image">
                        <label class="form-label" for="multiple_files">Agregar acuse</label>
                        <input class="form-control-file" type="file" id="multiple_files"
                          accept=".pdf,.doc,.png,.jpg,.jpeg" multiple (change)="onFileAcuseChange($event)" />
                        <p class="mt-1 text-sm text-gesco" id="file_input_help">(JPEG/PNG/PDF)
                        </p>
                      </div>
                    </div>
                    <div class="row" *ngIf="AllUrlAcuse.length">
                      <div class="col">
                        <div class="row ml-3">
                          <label class="col-md-12 p-t-2">Archivos cargados:</label>
                          <ng-container *ngFor="let url of AllUrlAcuse; let i = index">
                            <div class="drop-file">
                              <a class="alert alert-drop-file show text-left cursor-pointer">
                                <span class="close text-danger" data-idx="0" (click)="removeFile(url,'Acuse')">×</span>
                                <div (click)="openImage(url)">
                                  <i style="margin-top:-10px;"
                                    class="fa fa-file-image text-purple p-r-5 fa-3x pull-left"></i>
                                  {{getFileName(url)}}
                                </div>
                              </a>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-md-4">
                    <div class="col-md-12">
                      <label>Documentación adicional:</label>
                    </div>
                    <div class="col-md-12 offset-md-1 checkbox checkbox-css checkbox-inline">
                      <input id="checkboxExtra" type="checkbox" [checked]="promotedForm.get('docExtra')?.value === 1"
                        (change)="updateCheckbox($event,'Extra')" />
                      <label for="checkboxExtra">Si</label>
                    </div>
                  </div>

                  <div class="row" *ngIf="promotedForm.get('docExtra')?.value ">
                    <div class="col-10 offset-1" *ngIf="!AllUrlExtra.length">
                      <div class="cont-image">
                        <label class="form-label" for="multiple_files">Agregar documentación adicional</label>
                        <input class="form-control-file" type="file" id="multiple_files"
                          accept=".pdf,.doc,.png,.jpg,.jpeg" multiple (change)="onFileExtraChange($event)" />
                        <p class="mt-1 text-sm text-gesco" id="file_input_help">(JPEG/PNG/PDF)
                        </p>
                      </div>
                    </div>
                    <div class="row" *ngIf="AllUrlExtra.length">
                      <div class="col">
                        <div class="row ml-3">
                          <label class="col-md-12 p-t-2">Archivos cargados:</label>
                          <ng-container *ngFor="let url of AllUrlExtra; let i = index">
                            <div class="drop-file">
                              <a class="alert alert-drop-file show text-left cursor-pointer">
                                <span class="close text-danger" data-idx="0" (click)="removeFile(url,'Extra')">×</span>
                                <div (click)="openImage(url)">
                                  <i style="margin-top:-10px;"
                                    class="fa fa-file-image text-purple p-r-5 fa-3x pull-left"></i>
                                  {{getFileName(url)}}
                                </div>
                              </a>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
            <div class="footer-form">
              <button class="btn btn-primary" (click)="changeSelected(3)">Siguiente</button>
              <button class="btn btn-light mr-2" (click)="changeSelected(1)">Anterior</button>
            </div>
          </mat-step>
          <mat-step>
            <div class="contenedor" style="display: flex; justify-content: center; align-items: center;">
              <div class="row" style="margin-top: 5rem;margin-bottom: 5rem;">
                <div class="col-12" style="text-align: center;">
                  <h2 class="" style="font-size: 30px;font-weight: 600;color: #242a30;">Registro
                    completo</h2>
                </div>
                <div class="col-12" style="display: flex; justify-content: center; align-items: center;">
                  <button type="button" (click)="buttonSave()" class="btn btn-primary btn-lg">Guardar</button>
                </div>
              </div>
            </div>
            <div class="footer-form">
              <button class="btn btn-light mr-2" (click)="changeSelected(2)">Anterior</button>
            </div>
          </mat-step>
        </mat-stepper>
      </div>
    </div>
  </div>
</div>
