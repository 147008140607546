import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormDataService } from 'src/app/shared/services/form-data.service';
import { SympathizerService } from 'src/app/shared/services/sympathizer.service';

@Component({
  selector: 'app-sympathizer-history',
  templateUrl: './sympathizer-history.component.html',
  styleUrls: ['./sympathizer-history.component.scss']
})
export class SympathizerHistoryComponent implements OnInit {
  sympathizer: any = [];
  visits: any = [];
  sympathizerH: any = [];
  idSympathizer: any;

  constructor(private formDataService: FormDataService,private sympathizerService: SympathizerService, private activeRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.getSympathizerData();
    this.getVisit();
    this.getHistoryData();
  }

  getSympathizerData(){
    this.formDataService.sympathizerDataResults$.subscribe(data =>{
      console.log(data);
      this.sympathizer= data;
    });
  }

  getVisit(){
    this.idSympathizer = this.activeRoute.snapshot.paramMap.get('idSympathizer');
    if(this.idSympathizer != undefined){
      this.sympathizerService.getVisitData(this.idSympathizer).subscribe(data =>{
        console.log(data);
        this.visits = data;
      });
    }
  }

  getHistoryData(){
    this.sympathizerService.getHistoryData(this.idSympathizer).subscribe(data =>{
      this.sympathizerH = data;
    });
  }

}
