import { Component, HostBinding, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss']
})
export class PlayerComponent {
  @HostBinding('class.custom-dialog-container') customDialogClass = true;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { selectedMedia: string }) {
  }


  isVideo(url: string): boolean {
    const videoExtensions = ['mp4', 'webm', 'ogg']; // Agrega las extensiones de video que desees
    const extension = this.getFileExtension(url);
    return videoExtensions.includes(extension);
  }

  isImage(url: string): boolean {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif']; // Agrega las extensiones de imagen que desees
    const extension = this.getFileExtension(url);
    return imageExtensions.includes(extension);
  }

  getFileExtension(url: string): string {
    return url.split('.').pop()?.toLowerCase() || '';
  }

}

