<div class="contenedor">
    <div class="row">
        <div class="col">
            <label>Tema:</label>
            <div class="col-md-12">
                <div class="form-control">{{proposal.themeName || 'Sin información'}}</div>
            </div>
        </div>
        <div class="col">
            <label>Subtema:</label>
            <div class="col-md-12">
                <div class="form-control">{{proposal.subthemeName|| 'Sin información'}}</div>
            </div>
        </div>
        <div class="col">
            <label>Categoría:</label>
            <div class="col-md-12">
                <div class="form-control">{{proposal.categoryName || 'Sin información'}}</div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <label>Descripción:</label>
            <div class="col-md-12">
                <div class="form-control">{{proposal.description || 'Sin información'}}</div>
            </div>
        </div>
        <div class="col">
            <div class="col-md-12">
            </div>
        </div>
        <div class="col">
            <div class="col-md-12">
            </div>
        </div>
    </div>
    <div class="row" *ngIf="AllurlDocument.length > 0">
        <div class="col">
            <label>Archivo de iniciativa:</label>
            <div class="row ml-3">
                <label class="col-md-12 p-t-2">
                    Archivos cargados:
                </label>
                <a *ngFor="let url of AllurlDocument" style=" color: #348ee3;" class="align-middle col-md-12 p-t-2 cursor-pointer" (click)="openFile(url)"
                    target="_blank">
                    {{getFileName(url)}}
                </a>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="AllurlVideo.length > 0">
        <div class="col">
            <label>Video de propuesta ciudadana:</label>
            <div class="row ml-3">
                <label class="col-md-12 p-t-2">
                    Archivos cargados:
                </label>
                <!-- <a *ngFor="let url of AllurlVideo" style=" color: #348ee3;" class="align-middle col-md-12 p-t-2 cursor-pointer" (click)="openFile(url)"
                    target="_blank">
                    Archivo adjunto
                </a> -->
                <a *ngFor="let url of AllurlVideo" style=" color: #348ee3;" class="align-middle col-md-12 p-t-2 cursor-pointer" (click)="openFile(url)"
                    target="_blank">
                    {{getFileName(url)}}
                </a>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="AllurlImage.length > 0">
        <div class="col">
            <label>Foto de participación:</label>
            <div class="row ml-3">
                <label class="col-md-12 p-t-2">
                    Archivos cargados:
                </label>
                <!-- <a *ngFor="let url of AllurlImage" style=" color: #348ee3;" class="align-middle col-md-12 p-t-2 cursor-pointer" (click)="openFile(url)"
                    target="_blank">
                    Archivo adjunto
                </a> -->
                <a *ngFor="let url of AllurlImage" style=" color: #348ee3;" class="align-middle col-md-12 p-t-2 cursor-pointer" (click)="openFile(url)"
                    target="_blank">
                    {{getFileName(url)}}
                </a>
            </div>
        </div>
    </div>
</div>