
  <div class="map-container" [ngClass]="{'loading': mapLoaded==false, 'loaded': mapLoaded==true}">

    <div class="map-frame">
      <div id="map"></div>
    </div>
  </div>


  <div class="map-container" [ngClass]="{'loading': mapLoaded==!false, 'loaded': mapLoaded==!true}">
    <div class="map-frame">
      <ngx-skeleton-loader [theme]="{height: '100%'}" count="1"></ngx-skeleton-loader>
    </div>
  </div>
