import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapFrameComponent } from '../components/map-frame/map-frame.component';
import { MapComponent } from './map.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import {MatChipsModule} from '@angular/material/chips';


@NgModule({
  declarations: [
    MapFrameComponent,
    MapComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    MatProgressSpinnerModule,
    NgxSkeletonLoaderModule,
    MatChipsModule,
    
  ],
  exports: [MapFrameComponent],

})
export class MapModule { }
