import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProposalService } from 'src/app/shared/services/proposal.service';

@Component({
  selector: 'app-historial-information',
  template: 'Historial Information: {{ idPromoter }}',
  templateUrl: './historial-information.component.html',
  styleUrls: ['./historial-information.component.scss']
})
export class HistorialInformationComponent implements OnInit {
  @Input() idPromoter: number = 0;
  idProposal:any;
  proposalH: any = [];
  visits: any = [];
  constructor(private proposalService: ProposalService, private activeRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.getProposal();
  }

  getProposal(){
    this.idProposal = this.activeRoute.snapshot.paramMap.get('idProposal');
    if (this.idProposal != null) {
      this.proposalService.getProposalHistoryData(this.idProposal).subscribe((response: any) => {
        this.proposalH = response;
        this.getVisit();
      })
    }
  }

  getVisit(){
    if(this.idPromoter != undefined){
      this.proposalService.getVisitData(this.idPromoter).subscribe(data =>{
        console.log(data);
        this.visits = data;
      });
    }
  }
}
