import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CustomeValidationService {
  parentUrl: String = '';

  constructor() { }

  formatInputName(input: string): string {
    return input.toUpperCase().replace(/[^A-Z\s]/g, '');
  }

  formatInputCapitalize(input: string): string {
    return input.toUpperCase().replace(/[^A-Z0-9\s]/g, '');
  }

  checkForServerLocation(url: string) {
    console.log(url);

    var searchString = "https://gesco-data.s3.us-west-2.amazonaws.com/uploaded-files";
    var regex = new RegExp(searchString, "i"); // La "i" en la bandera hace que la búsqueda sea insensible a mayúsculas y minúsculas

    if (url != null && url != "" && url != "null") {

      if (/\[.*\]/.test(url)) {
        const imageUrls = JSON.parse(url);
        return imageUrls;
      }
      else if (url.includes('https://wabo-core-media.s3.amazonaws.com/media/')) {
        const imageUrls = url;
        return [imageUrls];
      }
      else if (url.includes('data/request')) {
        console.log('data');
        const photo = [this.getUrl() + '/' + url];
        return photo;
      }
      else if (regex.test(JSON.parse(url ?? ''))) {
        console.log('accom');

        const imageUrls = JSON.parse(url);
        return [imageUrls];
      }
      else {
        // console.log('data');
        // const photo = [this.getUrl() + '/' + url];
        return '';
      }

    } else {
      return '';
    }

  }

  validateImage(url: string) {

    const _url = this.checkForServerLocation(url);

    if (_url != null || _url != "") {
      if (/\[.*\]/.test(_url)) {
        return JSON.parse(_url);
      } else {
        return [_url]
      }
    }
  }

  getUrl() {
    console.log(localStorage.getItem('environment'));

    switch (localStorage.getItem('environment')) {
      case '1':
        return 'http://pruebas-integracion.gesco.click/sistema';
        break;
      case '2':
        return 'https://gesco.click/sistema';
        break;
      case '3':
        return 'https://gesco.mx/sistema';
        break;
      default:
        return 'https://gesco.mx/sistema';
        break;
    }

  }

  validCurp(curp: string): boolean {
    curp = curp.toUpperCase();
    const re = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
    const va = curp.match(re);
    if (!va) return false;
    const dic = '0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ';
    let su = 0.0,
      di = 0.0;
    for (let i = 0; i < 17; i++) {
      su += dic.indexOf(va[1].charAt(i)) * (18 - i);
    }
    di = 10 - (su % 10);
    di = di === 10 ? 0 : di;
    return va[2] === di.toString();
  }
}
