<div class="contenedor">
    <!-- <ng-container *ngIf="idProposal == null; else update">
    </ng-container>
    <ng-template #update>
        <div class="back btn btn-sm ml-3 mt-3">
            <i class="fa fa-arrow-left fa-lg" style="cursor: pointer;" (click)="goBack()" aria-hidden="true"></i>
        </div>
    </ng-template> -->
    <h1 class="page-header">Agregar solicitud &nbsp;<small></small></h1>
    <hr>
    <div class="row m-3">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-8" style=" margin-bottom:5px;">
            <div style="display: flex; align-items: center;">
                <span class="spanSearch">Buscar</span>
                <div class="input-group">
                    <input id="FiltrarContenido" type="text" placeholder="Ingrese su búsqueda" class="" [(ngModel)]="search" (ngModelChange)="onSearchChange($event)" style="height: 50px;">
                    <div class="input-group-append">
                        <button class="btn btn-dark" type="button" (click)="searchApplicant()" style="background-color: black;">
                            <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </div>
            <ng-container #menu *ngIf="applicantSearch.length != 0 && applicantSelected == false">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 content-seach searchList">
                    <ng-container *ngIf="applicantSearch.length == 0 else searchMenu">
                    <label class="darkGray">No hay registros</label>
                    </ng-container>
                    <ng-template #searchMenu>
                    <ng-container *ngFor="let result of applicantSearch; let i = index">
                        <ng-container>
                        <label (click)="selectedApplicant(result.id,result.name)" [ngClass]=" i%2==0 ? 'darkGray' : 'lowGray'">{{result.name}}</label>
                        </ng-container>
                    </ng-container>
                    <br>
                    </ng-template>
                </div>
            </ng-container>
        </div>
        <div class="col-md-4">
            <div class="d-flex flex-column justify-content-end align-items-end h-100">
                <div class="m-b-5">
                    <button type="button" class="btn btn-secondary" style="padding-top: 10px; padding-bottom: 10px;background-color: #6c757d;" (click)="cleanForm()">
                        Vaciar formulario
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="sectionBody">
        <div class="row tHeader" style="margin: 0 !important; ">
            <div class="col-4 tab" (click)="changeSelected(0)" [ngClass]="{'active': selectedTab == 0}" >
                <div class="sectionForm">
                    <span class="numberNotSelected" *ngIf="selectedTab != 0 else one">
                        <div class="fa-solid fa-check"></div>
                    </span>
                    <ng-template #one>
                        <span class="number">
                            1
                        </span>
                    </ng-template>
                    <div>
                        <span class="info">Datos generales </span>
                        <i class="fa fa-arrow-right"></i>
                        <small>Nombre, Dirección, Teléfono...</small>
                    </div>
                </div>
            </div>
            <div class="col-4 tab" (click)="changeSelected(1)" [ngClass]="{'active': selectedTab == 1}" >
                <div class="sectionForm">
                    <span class="numberNotSelected" *ngIf="selectedTab != 1 else second">
                        <div class="fa-solid fa-check"></div>
                    </span>
                    <ng-template #second>
                        <span class="number">
                            2
                        </span>
                    </ng-template>
                    <span class="info">Clasificación </span>
                    <i class="fa fa-arrow-right"></i>
                    <small>Tema, Subtema, Categoría</small>
                </div>
            </div>
            <div class="col-4 tab" (click)="changeSelected(2)" [ngClass]="{'active': selectedTab == 2}" >
                <div class="sectionForm">
                    <span class="numberNotSelected" *ngIf="selectedTab != 2 else third">
                        <div class="fa-solid fa-check"></div>
                    </span>
                    <ng-template #third>
                        <span class="number">
                            3
                        </span>
                    </ng-template>
                    <div>
                        <span class="info">Completado </span>
                        <i class="fa fa-arrow-right"></i>
                        <small>Registro completo</small>
                    </div>
                </div>
            </div>
        </div>
        <mat-stepper (selectionChange)="onStepChange($event)" #stepper>
            <mat-step>
                <app-request-general-data></app-request-general-data>
                <div class="footer-form">
                    <button class="btn btn-primary" (click)="changeSelected(1)">Siguiente</button>
                </div>
            </mat-step>
            <mat-step>
                <app-request-clasification></app-request-clasification>
                <div class="footer-form">
                    <button class="btn btn-primary" (click)="changeSelected(2)">Siguiente</button>
                    <button class="btn btn-light mr-2" (click)="changeSelected(0)">Anterior</button>
                </div>
            </mat-step>
            <mat-step>
                <app-request-complete></app-request-complete>
                <div class="footer-form">
                    <button class="btn btn-light" (click)="changeSelected(1)">Anterior</button>
                </div>
            </mat-step>
        </mat-stepper>
    </div>
</div>


