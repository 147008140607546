import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pinStatus'
})
export class PinStatusPipe implements PipeTransform {

  transform(items: any, searchTextModel: string, fieldsToFilterBy: Array<string>): any {

    if (!items) {
      return [];
    }
    if (!searchTextModel) {
      return items;
    }

    searchTextModel = searchTextModel.toLowerCase();

    return this.filterByStatus(items, searchTextModel);
    
  }

  filterByStatus(items: any[], searchText: string) {
    return items.filter((item: { [x: string]: string; }) => {
      return item['idstatus'].toLowerCase().indexOf(searchText) !== -1
    });
  }

}
