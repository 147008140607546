<div class="contenedor">
  <h2 class="page-header">
    <p>Puntos de interés </p>
  </h2>
  <hr>
  <div id="idInfo" class="note note-warning" *ngIf="!totalRecords">
    <div class="note-icon"><i class="fa fa-user-check"></i></div>
    <div class="note-content">
      <h4><b>Administración de las puntos de interés</b></h4>
      <p>En esté apartado podrás consultar y editar a los puntos en el sistema.</p>
    </div>
  </div>
  <div class="row">
    <a class="col-xs-12 col-sm-12 col-md-12 col-lg-3"
      style="text-align:left;text-decoration:none;color: #343a40;align-self: center;">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="margin-bottom :10px;">
        <div class="targetTotals"
          style="border-radius: 4px;box-shadow: 1px 1px 5px 0px;padding: 7px;text-align: center;height: 100%;background:#fff;padding-top: 45px;border-bottom: 17px solid;">
          <p class="dataBenf">TOTAL PUNTOS:<br /> <b style="font-size: 1.8em;">{{totalRecords}}</b></p>
        </div>
      </div>
    </a>
  </div>

  <br>
  <div class="row" style="text-align: center;">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4" style=" display: inherit; margin-bottom:5px;">
      <span class="spanSearch">Buscar</span>
      <input id="FiltrarContenido" type="search" [(ngModel)]="search" (ngModelChange)="getSearch($event)" (ngModelChange)="onSearchChange($event)" class=""
        placeholder=" Ingrese su búsqueda" aria-controls="data-table-autofill">
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6" style="font-size: 16px;">
      <pagination-controls (pageChange)="onPageChange($event)" previousLabel="" nextLabel=""></pagination-controls>
    </div>
  </div>

  <div class="row">
    <div class="row showGral m-t-10">
      <div class="col-sm-12 col-md-6">
        <h5 class="m-t-10 m-b-10 m-r-5 showCount">
          <i class="fa fa-list"></i><span> Mostrando <strong>{{currentArray.length}}</strong> de
            <strong>{{totalRecords}}</strong> registros</span>
        </h5>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="panel-heading-btn">
          <div class="btn-group m-b-5" *ngIf="identifier == 7 || identifier == 1; else denied">
            <ng-container *ngIf="enabledReport == 1 else notLevel">
              <a class="btn btn-light showCount" style="color: black;" href="{{url}}report/exportPOIReportFilter/{{idWorkGroup}}/{{userLevel}}/{{identifier}}/{{idUser}}/{{search}}" target="_blank"> <i class="fa fa-file-download"></i> Descargar puntos de interés
              </a>
            </ng-container>
          </div>
          <ng-template #denied>
              <div class="row">
                  <div class="col">
                      <div ngbDropdown class="d-inline-block">
                          <button class="btn btn-light dropdown-toggle showCount" id="dropdownBasic1" ngbDropdownToggle><i class="fa fa-file-download"></i> Descargar puntos de interés</button>
                          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                              <p ngbDropdownItem style="text-align: left;">Funcionalidad no disponible para esta cuenta</p>
                          </div>
                      </div>
                  </div>
              </div>
          </ng-template>
          <ng-template #notLevel>
            <div class="row">
                <div class="col">
                    <div ngbDropdown class="d-inline-block">
                        <button class="btn btn-light dropdown-toggle showCount" id="dropdownBasic1" ngbDropdownToggle><i class="fa fa-file-download"></i> Descargar Directorio</button>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                            <p ngbDropdownItem style="text-align: left;">Funcionalidad no disponible para cuentas gratuitas</p>
                        </div>
                    </div>
                </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  <div class="row ">
    <div class="table-responsive" style="padding: 0%;">
      <table class="table table-hover table-striped table-bordered m-b-0">
        <thead class="truncate-text">
          <tr>
            <th class="actions-sortable none">Opciones</th>
            <th class="actions-sortable none">Folio</th>
            <th>Nombre
              <app-order *ngIf="currentFilter == 'none' || currentFilter == 'name'"
                (valueResponse)="getOrder('name',$event)"></app-order>
            </th>
            <th>Punto de Interés
              <app-order *ngIf="currentFilter == 'none' || currentFilter == 'type'"
                (valueResponse)="getOrder('type',$event)"></app-order>
            </th>
            <th>Correo electrónico
              <app-order *ngIf="currentFilter == 'none' || currentFilter == 'email'"
                (valueResponse)="getOrder('email',$event)"></app-order>
            </th>
            <th>Teléfono de contacto
              <app-order *ngIf="currentFilter == 'none' || currentFilter == 'tel'"
                (valueResponse)="getOrder('tel',$event)"></app-order>
            </th>
            <th>Calle
              <app-order *ngIf="currentFilter == 'none' || currentFilter == 'street'"
                (valueResponse)="getOrder('street',$event)"></app-order>
            </th>
            <th>No. Ext
              <app-order *ngIf="currentFilter == 'none' || currentFilter == 'streetNumExt'" [field]="'numeric'"
                (valueResponse)="getOrder('streetNumExt',$event)"></app-order>
            </th>
            <th>No. Int.
              <app-order *ngIf="currentFilter == 'none' || currentFilter == 'streetNumInt'" [field]="'numeric'"
                (valueResponse)="getOrder('streetNumInt',$event)"></app-order>
            </th>
            <th>C. P.
              <app-order *ngIf="currentFilter == 'none' || currentFilter == 'postalCode'" [field]="'numeric'"
                (valueResponse)="getOrder('postalCode',$event)"></app-order>
            </th>
            <th>Municipio
              <app-order *ngIf="currentFilter == 'none' || currentFilter == 'municipalitie'"
                (valueResponse)="getOrder('municipalitie',$event)"></app-order>
            </th>
            <th>Estado
              <app-order *ngIf="currentFilter == 'none' || currentFilter == 'entitie'"
                (valueResponse)="getOrder('entitie',$event)"></app-order>
            </th>
            <th>Sección electoral
              <app-order *ngIf="currentFilter == 'none' || currentFilter == 'section'" [field]="'numeric'"
                (valueResponse)="getOrder('section',$event)"></app-order>
            </th>

          </tr>
        </thead>
        <tbody>

          <tr
            *ngFor="let POI of currentArray | paginate: { itemsPerPage: itemsPerPage, currentPage: p, totalItems: totalRecords }">
            <td>

              <div class="row">
                <div class="col">
                  <div ngbDropdown class="d-inline-block">
                    <button class="btn btn-sm btn-primary dropdown-toggle" id="dropdownBasic1" ngbDropdownToggle>
                      <i class="fa fa-align-justify">
                      </i>
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                      <button ngbDropdownItem>

                        <span (click)="goToUpdate(POI.id)" class="btn btn-sm btn-block btn-white selectHover"
                          style="text-align: left;" title="Editar"><i class="fa fa-edit"></i>
                          Editar</span>
                      </button>
                      <button ngbDropdownItem>
                        <span (click)="openModal(POI.id, POI.qrcode)" class="btn btn-sm btn-block btn-white selectHover"
                          style="text-align: left;" title="Eliminar"><i class="fa fa-trash"></i>
                          Eliminar</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

            </td>
            <td class="">

              <a style="font-family: monospace; color: #348ee3; cursor: pointer;" (click)="goToDetail(POI.id)"> <i
                  class="fa fa-external-link-alt"></i>
                {{POI.qrcode}}</a>

            </td>
            <td class="">
              <a style="color: #707478;">{{POI.name|| '-'}}</a>
            </td>
            <td class="">
              <a style="color: #707478;">{{POI.type|| '-'}}</a>
            </td>

            <td class="">
              <a style="color: #707478;">{{POI.email|| '-'}}</a>
            </td>
            <td class="">
              <a style="color: #707478;">{{POI.tel|| '-'}}</a>
            </td>
            <td class="">
              <a style="color: #707478;">{{POI.street|| '-'}}</a>
            </td>
            <td class="">
              <a style="color: #707478;">{{POI.streetNumExt|| '-'}}</a>
            </td>
            <td class="">
              <a style="color: #707478;">{{POI.streetNumInt|| '-'}}</a>
            </td>
            <td class="">
              <a style="color: #707478;">{{POI.postalCode|| '-'}}</a>
            </td>
            <td class="">
              <a style="color: #707478;">{{POI.municipalitie|| '-'}}</a>

            </td>
            <td class="">
              <a style="color: #707478;">{{POI.entitie|| '-'}}</a>
            </td>
            <td class="">
              <a style="color: #707478;">{{POI.section|| '-'}}</a>
            </td>
          </tr>

        </tbody>
      </table>
    </div>


  </div>
</div>
 <!-- Modal -->
 <div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-labelledby="visitModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
      <div class="modal-content">
          <div class="modal-header" style="background: #FF5B57;">
              <h5 class="modal-title" id="exampleModalLabel" style="color:black;font-size: 17px;font-weight: 600;">¿Deseas eliminar este Punto de interés?</h5>
              <button type="button" class="close" (click)="closeModal()" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div class="modal-body" style="color:#707478;font-size:14px;">
            <div class="col-12">
              <label>Folio:</label>
              <div class="form-control">{{ qrcode }}</div>
            </div>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-light showCount"(click)="closeModal()" style="font-size: 14px;">Cancelar</button>
              <button type="button" class="btn btn-danger" (click)="deleteRegister(idPOI)" style="font-size: 14px;">Eliminar</button>
          </div>
      </div>
  </div>
</div>