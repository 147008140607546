import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PlayerComponent } from 'src/app/pages/components/modal/player/player.component';
import { FormDataService } from 'src/app/shared/services/form-data.service';
import { SympathizerService } from 'src/app/shared/services/sympathizer.service';

@Component({
  selector: 'app-sympathizer-documentation',
  templateUrl: './sympathizer-documentation.component.html',
  styleUrls: ['./sympathizer-documentation.component.scss']
})
export class SympathizerDocumentationComponent implements OnInit {

  sympathizer: any = [];
  AllurlParticipation: any = [];

  constructor(private formDataService: FormDataService, private sympathizerService: SympathizerService, public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.getSympathizerData();
  }

  getSympathizerData() {
    this.formDataService.sympathizerDataResults$.subscribe(data => {
      this.sympathizer = data;
      console.log(this.sympathizer);
      this.AllurlParticipation = JSON.parse(data.url_image_participatio);
    });
  }
  openLink(url: string) {
    window.open(url, '_blank');
  }

  getFileName(url: string): string {
    const parts = url.split('/');
    return decodeURIComponent(parts[parts.length - 1]);
  }

  openFile(url: string) {
    if (/\.pdf$/i.test(url)) {
      // Es un archivo PDF, abrir en una nueva ventana
      window.open(url, '_blank');
    } else {
      // No es un archivo PDF, realizar alguna acción o manejar según sea necesario
      let dialogRef = this.dialog.open(PlayerComponent, {
        data: { selectedMedia: url },
        maxHeight: '80%',
        maxWidth: '80%',
      });
      dialogRef.afterClosed().subscribe(result => {

      });
    }
  }

}
