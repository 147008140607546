<br />
<div>
  <div class="container">
    <div class="row graphicTwo">
      <div class="col-md-6">
        <h2>Solicitudes levantadas por grupo vulnerable</h2>
        <br /><br />
        <div class="chart-container">
          <canvas id="StatStatusRequest">{{ chartStatusRequest2 }}</canvas>
        </div>
      </div>
      <div class="col-md-6">
        <h2>Beneficiarios por grupo vulnerable</h2>
        <br /><br />
        <div class="chart-container">
          <canvas id="StatTotalRequest">{{ chartTotalRequest2 }}</canvas>
        </div>
      </div>
    </div>
  </div>
</div>
