import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PeriodService {

  options: any = [
    { name: 'Diputada(o) Federal', value: 'Diputada(o) Federal' },
    { name: 'Diputada(o) Local', value: 'Diputada(o) Local' },
    { name: 'Gobernadora(or)', value: 'Gobernadora(or)' },
    { name: 'Presidenta(e) de la República', value: 'Presidenta(e) de la República' },
    { name: 'Presidenta(e) Municipal', value: 'Presidenta(e) Municipal' },
    { name: 'Regidora(or)', value: 'Regidora(or)' },
    { name: 'Senadora(or)', value: 'Senadora(or)' },
    { name: 'Campaña Diputada(o) Federal', value: 'Campaña Diputada(o) Federal' },
    { name: 'Campaña Diputada(o) Local', value: 'Campaña Diputada(o) Local' },
    { name: 'Campaña Gobernadora(or)', value: 'Campaña Gobernadora(or)' },
    { name: 'Campaña Presidenta(e) de la República', value: 'Campaña Presidenta(e) de la República' },
    { name: 'Campaña Presidenta(e) Municipal', value: 'Campaña Presidenta(e) Municipal' },
    { name: 'Campaña Regidora(or)', value: 'Campaña Regidora(or)' },
    { name: 'Campaña Senadora(or)', value: 'Campaña Senadora(or)' }
  ];

  constructor() { }
}
