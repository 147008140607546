import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AnswersFormService {
  url = environment.apiUrl;
  private typeMapping: Record<string, Record<string,number>> = {
    vulnerable: {
      1: 1,   // Educación
      5: 1,   // Agropecuario
      11: 1,  // Deporte
      12: 1,  // Desarrollo social
      15: 1,  // Obra pública
      10: 1,  // Desarrollo económico
      3: 1,   // Ecología
      2: 1,   // Salud
      17: 1,  // Seguridad
      4: 1,   // Servicios públicos
      9: 1,   // Transporte
      8: 1,   // Turismo
      7: 1,   // Vivienda
      38: 1,  // Arte y cultura
    },
    information: {
      1: 6,   // Educación
      2: 17,  // Salud
      3: 14,  // Ecología
      12: 16, // Desarrollo Social
      11: 10, // Deporte
      17: 18, // Seguridad
      5: 29,  // Agropecuario
      10: 12, // Desarrollo económico
      15: 27, // Obra pública
      4: 35,  // Servicios públicos
      9: 37,  // Transporte
      8: 38,  // Turismo
      7: 39,  // Vivienda
      38: 44, // Arte y cultura
    },
    level: {
      11: 8, // Deporte
    },
    educationLevel: {
      1: 22,  // Educación
      12: 26, // Desarrollo social
      11: 8,  // Deportes
      38: 43, // Arte y cultura
    },
    typeDiscipline: {
      1 : 23,    // Educacion
    },
    purposeScholarship: {
      1: 24     // Educacion
    },
    typeScholarship: {
      1: 25      // Educacion
    },
    health: {
      2: 15
    },
    sport: {
      11: 7
    },
    categorie: {
      11: 30
    },
    disciplineClass: {
      38: 46
    },
    disciplineNivel: {
      38: 48
    },
    typeartCult: {
      345: 6000,
      346: 7000,
      347: 8000,
      348: 9000,
      349: 10000,
      350: 11000,
      351: 12000,
      352: 13000,
      353: 14000,
      355: 15000,
      356: 16000,
      357: 17000,
      358: 18000,
      359: 19000, 
    },
    federalSupportForm: {
      2: 33
    },
    typeSupportForm: {
      214: 1000,
      215: 2000,
      216: 3000,
      217: 4000,
      218: 5000
    }
  };

  constructor(public http: HttpClient) {
  }

  getFormType(id: number, type: string) {
    const idq = this.typeMapping[type]?.[String(id)] || 0;

    return this.getAnswer(idq);
  }

  getAnswer(id: number){
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any[]>(this.url + 'getInformationAnswer/' + id, options);
  }
}
