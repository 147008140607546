import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaxchardecComponent } from './maxchardec.component';



@NgModule({
  declarations: [MaxchardecComponent],
  imports: [
    CommonModule
  ],
  exports: [MaxchardecComponent],

})
export class MaxchardecModule { }
