<div class="contenedor">
  <div class="row">
    <div class="col-12 col-md-3">
      <label>Folio:</label>
      <div class="form-control">{{request.qrcode}}</div>
    </div>
    <div class="col-12 col-md-3">
      <label>Periodo:</label>
      <div class="form-control">{{request.period || 'Sin información'}}</div>
    </div>
    <div class="col-12 col-md-3">
      <label>Tipo de gestoría:</label>
      <div class="form-control">{{request.typeManagment || 'Sin información'}}</div>
    </div>
    <div class="col-12 col-md-3">
      <label>Lugar de levantamiento:</label>
      <div class="form-control">{{request.idlifting}}</div>
    </div>
  </div>
  <div class="panel panel-info">
    <p class="divData">Datos del solicitante</p>
  </div>
  <div class="row">
    <div class="col-12 col-md-4">
      <label>Nombre completo:</label>
      <div class="form-control">{{request.name}} {{request.next_name}} {{request.last_name}}</div>
    </div>
    <div class="col-12 col-md-4">
      <label>Fecha de nacimiento:</label>
      <div class="form-control">{{ (request.birthDate != '0000-00-00' ?
        (request.birthDate | date:'dd/MM/yyyy') : 'Sin información') || 'Sin información'}}</div>
    </div>
    <div class="col-12 col-md-4">
      <label>Sexo:</label>
      <div class="form-control">{{request.gender || 'Sin información'}}</div>
    </div>
    <div class="col-12 col-md-4">
      <label>CURP:</label>
      <div class="form-control">{{request.curp || 'Sin información'}}</div>
    </div>
    <div class="col-12 col-md-4">
      <label>Correo electrónico:</label>
      <div class="form-control">{{request.email || 'Sin información'}}</div>
    </div>
    <div class="col-12 col-md-4">
      <label>Teléfono de casa:</label>
      <div class="form-control">{{request.phone || 'Sin información'}}</div>
    </div>
    <div class="col-12 col-md-4">
      <label>Teléfono celular:</label>
      <div class="form-control">{{request.cellPhone || 'Sin información'}}</div>
    </div>
    <div class="col-12 col-md-4">
      <label>Estado civil:</label>
      <div class="form-control">{{request.civilStatus || 'Sin información'}}</div>
    </div>
    <div class="col-12 col-md-4">
      <label>Lugar de nacimiento (Estado):</label>
      <div class="form-control">{{request.birth_entity_name || 'Sin información'}}</div>
    </div>
    <div class="col-12 col-md-4">
      <label>Lugar de nacimiento (Municipio):</label>
      <div class="form-control">{{request.birth_municipality_name || 'Sin información'}}</div>
    </div>
    <div class="col-12 col-md-4">
      <label>Escolaridad:</label>
      <div class="form-control">{{request.scholarship || 'Sin información'}}</div>
    </div>
    <div class="col-12 col-md-4">
      <label>Ocupación:</label>
      <div class="form-control">{{request.ocupation || 'Sin información'}}</div>
    </div>
    <div class="col-12 col-md-4">
      <label>Lugar de trabajo:</label>
      <div class="form-control">{{request.workplace || 'Sin información'}}</div>
    </div>
    <div class="col-12 col-md-4">
      <label>Teléfono del trabajo:</label>
      <div class="form-control">{{request.cellPhoneOffice || 'Sin información'}}</div>
    </div>
    <div class="col-12 col-md-4" *ngIf="request.federalSupport != 0 else noFed">
      <label>Recibe apoyos federales:</label>
      <div class="form-control">{{request.typeSupport}}</div>
    </div>
    <ng-template #noFed>
      <div class="col-12 col-md-4">
        <label>Recibe apoyos federales:</label>
        <div class="form-control">No</div>
      </div>
    </ng-template>
    <div class="col-12 col-md-4">
      <label>Facebook:</label>
      <div class="form-control">{{request.facebook || 'Sin información'}}</div>
    </div>
    <div class="col-12 col-md-4">
      <label>Instagram:</label>
      <div class="form-control">{{request.instagram || 'Sin información'}}</div>
    </div>
  </div>
  <ng-container *ngIf="beneficiary && beneficiary.length != 0">
    <div class="row">
      <div class="panel panel-info">
        <p class="divData">Datos del beneficiario</p>
      </div>
      <div class="col-12 col-md-4">
        <label>Nombre del beneficiario:</label>
        <div class="form-control">{{beneficiary.name}} {{beneficiary.next_name}} {{beneficiary.last_name}}</div>
      </div>
      <div class="col-12 col-md-4">
        <label>Fecha de nacimiento:</label>
        <div class="form-control">{{ (beneficiary.birthDate != '0000-00-00' ? (beneficiary.birthDate |
          date:'dd/MM/yyyy') : 'Sin información') || 'Sin información'}}</div>
      </div>
      <div class="col-12 col-md-4">
        <label>Sexo:</label>
        <div class="form-control">{{beneficiary.gender || 'Sin información'}}</div>
      </div>
      <div class="col-12 col-md-4">
        <label>CURP:</label>
        <div class="form-control">{{beneficiary.curp || 'Sin información'}}</div>
      </div>
      <div class="col-12 col-md-4">
        <label>Teléfono celular:</label>
        <div class="form-control">{{beneficiary.cellPhone || 'Sin información'}}</div>
      </div>
      <div class="col-12 col-md-4">
        <label>Lugar de nacimiento (Estado):</label>
        <div class="form-control">{{beneficiary.birth_entity_name || 'Sin información'}}</div>
      </div>
      <div class="col-12 col-md-4">
        <label>Lugar de nacimiento (Municipio):</label>
        <div class="form-control">{{beneficiary.birth_municipality_name || 'Sin información'}}</div>
      </div>
      <div class="col-12 col-md-4">
        <label>Parentesco:</label>
        <div class="form-control">{{beneficiary.relationshipBeneficiary || 'Sin información'}}</div>
      </div>
    </div>
  </ng-container>
  <div class="panel panel-info">
    <p class="divData">Dirección</p>
  </div>
  <div class="row">
    <div class="col-12 col-md-4">
      <label>Calle:</label>
      <div class="form-control">{{request.street || 'Sin información'}}</div>
    </div>
    <div class="col-12 col-md-4">
      <label>Número exterior:</label>
      <div class="form-control">{{request.streetNumExt || 'Sin información'}}</div>
    </div>
    <div class="col-12 col-md-4">
      <label>Número interior:</label>
      <div class="form-control">{{request.streetNumInt || 'Sin información'}}</div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-4">
      <label>Código postal:</label>
      <div class="form-control">{{request.postalCode || 'Sin información'}}</div>
    </div>
    <div class="col-12 col-md-4">
      <label>Colonia:</label>
      <div class="form-control">{{request.neighborhood_name || 'Sin información'}}</div>
    </div>
    <div class="col-12 col-md-4">
      <label>Municipio:</label>
      <div class="form-control">{{request.municipality_name || 'Sin información'}}</div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-4">
      <label>Estado:</label>
      <div class="form-control">{{request.entity_name || 'Sin información'}}</div>
    </div>
    <div class="col-12 col-md-4">
      <label>Sección electoral:</label>
      <div class="form-control">{{request.electoralSection || 'Sin información'}}</div>
    </div>
    <div class="col-12 col-md-4">
      <label class="p-t-2">Distrito local:</label>
      <div class="form-control">
        {{ request.local_district || 'Sin información'}}</div>
    </div>
    <div class="col-12 col-md-4">
      <label class="p-t-2">Distrito federal:</label>
      <div class="form-control">
        {{ request.federal_district || 'Sin información'}}</div>
    </div>
    <div class="col-12 col-md-4">
      <label>Referencia:</label>
      <div class="form-control">{{request.reference || 'Sin información'}}</div>
    </div>
  </div>
  <div class="panel panel-info">
    <p class="divData">Clasificación del Solicitante</p>
  </div>
  <div class="row">
    <div class="col">
      <label>Punto de interés:</label>
      <div class="col-md-12">
        <div class="form-control">{{request.POI_name || 'NO'}}</div>
      </div>
    </div>
    <div class="col">
      <label>Tipo de participación:</label>
      <div class="col-md-12">
        <div class="form-control">{{request.participationType || 'NO'}}</div>
      </div>
    </div>
    <div class="col">
      <label>Actor importante:</label>
      <div class="col-md-12">
        <div class="form-control">{{request.actorName || 'NO'}}</div>
      </div>
    </div>
  </div>
</div>
