import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {Chart} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { StatsService } from 'src/app/shared/services/stats.service';
@Component({
  selector: 'app-applicants-gender-month',
  templateUrl: './applicants-gender-month.component.html',
  styleUrls: ['./applicants-gender-month.component.scss']
})
export class ApplicantsGenderMonthComponent implements OnInit {

  public chartTotalRequest2: any;
  public chartStatusRequest2: any;
  currentYear = new Date().getFullYear();
  lastYear = this.currentYear - 1;
  requestsLast: any;
  requests: any;
  chartData: any;
  chartDataYear: any;
  monthName : any;
  theme!: any [];
  stock1!: any [];
  stock1Year!: any [];
  idMonth!: any;
  idUser!: any;
  token!: any;
  idWorkGroup!: any;

  stock2!: any [];
  stock2Year!: any [];

  themeYear!: any [];

  labels: any[]=[];
  color1 = ['#0e6d0f'];
  color2= ['#940000'];
  grafico: any;
  public loading = true;
  public loading2 = true;

  constructor(private router:Router, private statsService: StatsService, private activeRoute: ActivatedRoute){

  }

  ngOnInit(): void {
    this.loading = true;

    Promise.all([ this.getCountByMonth()])
      .then(() => {
                this.loading = false;
      })
      .catch(error => {
        console.error('Error while loading data:', error);
        this.loading = false; // asegurarse de quitar el loader en caso de error
      });
  }

  getCountByMonth() {
    return new Promise((resolve, reject) => {
      this.activeRoute.params.subscribe((routeParams) => {
        console.log(routeParams);
        this.statsService
          .getApplicantGenderByMonth(routeParams['idWorkGroup'], routeParams['month'])
          .subscribe((data) => {
            console.log(data);
            const theme = [];
            const stock1 = [];
            const stock2 = [];
            const monthNames = [
              'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
              'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
            ];
            this.requestsLast =data;
            const monthNumber = parseInt(routeParams['month']) - 1;
            this.monthName = monthNames[monthNumber].charAt(0).toUpperCase() + monthNames[monthNumber].slice(1);


            console.log(this.monthName);
            this.chartTotalRequest2 = new Chart("StatTotalRequest", {
              type: 'doughnut',
     data: {
      labels:['Masculino','Femenino', 'Otro','Sin información'],
      datasets: [
        {
          data:[this.requestsLast.Masculino, this.requestsLast.Femenino, this.requestsLast.Otro, this.requestsLast.Indefinido],
          backgroundColor: ['#00afef','#f32af3', '#959595','#ffbf00'],
        },
      ]
    },
              plugins: [ChartDataLabels],
              options: {
                aspectRatio:2.3,
                plugins: {
                  legend: {
                      position: 'bottom'
                  },

                  datalabels: {
                    color: 'white',
                    labels: {
                      title: {
                        font: {
                          weight: 'bold',
                          size: 20
                        }
                      },

                    }
                  }

              },
              parsing: {
                key: 'nested.value'
              }

              }
            });

            resolve(true);
          });
      });
    });
  }





}
