import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { categoriesModel } from 'src/app/shared/models/categories.model';
import { subThemesModel } from 'src/app/shared/models/subthemes.model';
import { themesModel } from 'src/app/shared/models/themes.model';
import { FormDataService } from 'src/app/shared/services/form-data.service';
import { ProposalService } from 'src/app/shared/services/proposal.service';
import { RequestService } from 'src/app/shared/services/request.service';

@Component({
  selector: 'app-proposal-initiative',
  templateUrl: './proposal-initiative.component.html',
  styleUrls: ['./proposal-initiative.component.scss']
})
export class ProposalInitiativeComponent implements OnInit {


  proposalInitiativeForm: FormGroup = this.FB.group({
    theme_id: ['', [Validators.required]],
    subtheme_id: [{ value: '', disabled: true }, [Validators.required]],
    category_id: [{ value: '', disabled: true }, [Validators.required]],
    description: ['', [Validators.maxLength(250)]],
    urgent: [''],
    urlDocument: [],
    urlVideo: [],
    urlImage: []
  });
  themes: Array<themesModel> = [];
  subthemes: Array<subThemesModel> = [];
  categories: Array<categoriesModel> = [];
  searchValue: string = '';
  responseSearch: any = [];
  selectedSearch: boolean = false;
  idProposal: any;
  imagesURLs: any;
  selectedFiles: any = [];
  AllurlDocument: any = [];
  AllurlVideo: any = [];
  AllurlImage: any = [];

  constructor(
    private FB: FormBuilder,
    private formDataService: FormDataService,
    private requestService: RequestService,
    private activeRoute: ActivatedRoute,
    private proposalService: ProposalService
  ) {
    this.attachInputHandlers();
    this.isUpdating();
  }

  ngOnInit(): void {
    this.getThemes();
    this.proposalInitiativeForm.valueChanges.subscribe(data => {
      this.formDataService.setProposalFormData(this.proposalInitiativeForm, this.selectedFiles, this.AllurlDocument, this.AllurlVideo, this.AllurlImage);
    });
  }

  onSearchChange(newValue: string) {
    this.searchValue = newValue.toUpperCase();
  }

  getFileName(url: string): string {
    const parts = url.split('/');
    return decodeURIComponent(parts[parts.length - 1]);
  }


  attachInputHandlers() {
    const uppercaseFields = ['description'];
    uppercaseFields.forEach(fieldName => {
      const fieldControl = this.proposalInitiativeForm.get(fieldName);
      if (fieldControl) {
        fieldControl.valueChanges.subscribe((newValue: string) => {
          if (newValue) {
            this.proposalInitiativeForm.get(fieldName)?.setValue(newValue.toUpperCase(), { emitEvent: false });
          }
        });
      }
    });
  }

  openImage(url: string) {
    window.open(url, '_blank');
  }

  removeFile(type: string, url: string) {

    switch (type) {
      case "doc":
        this.AllurlDocument = this.AllurlDocument.filter((item: any) => item !== url);
        break;
      case "vid":
        this.AllurlVideo = this.AllurlDocument.filter((item: any) => item !== url);
        break;
      case "img":
        this.AllurlImage = this.AllurlDocument.filter((item: any) => item !== url);
        break;
    }
    this.formDataService.setProposalFormData(this.proposalInitiativeForm, this.selectedFiles, this.AllurlDocument, this.AllurlVideo, this.AllurlImage);

  }

  isUpdating() {
    this.idProposal = this.activeRoute.snapshot.paramMap.get('idProposal');

    if (this.idProposal != null) {
      this.proposalService.getProposal(this.idProposal).subscribe((response: any) => {
        const data = response[0];
        console.log(data);
        this.proposalInitiativeForm.patchValue({
          theme_id: data.theme_id,
          subtheme_id: data.subtheme_id,
          category_id: data.category_id,
          description: data.description,
          urgent: data.urgent,
        });

        this.getSubthemes(this.proposalInitiativeForm.get('theme_id')?.value).then(() => {
          this.proposalInitiativeForm.controls['subtheme_id'].setValue(data.subtheme_id);
          this.getCategories(this.proposalInitiativeForm.get('subtheme_id')?.value).then(() => {
            this.proposalInitiativeForm.controls['category_id'].setValue(data.category_id);
          });
        });

        if(data.urlDocument)
          this.AllurlDocument = JSON.parse(data.urlDocument);
        if(data.urlVideo)
          this.AllurlVideo = JSON.parse(data.urlVideo);
        if(data.urlImage)
          this.AllurlImage = JSON.parse(data.urlImage);
      });
    }
  }

  getThemes() {
    this.requestService.getAllThemes().subscribe(data => {
      this.themes = data;
    });
  }

  async getSubthemes(themeId: number) {
    const promise = new Promise((resolve, reject) => {
      if (themeId != null) {
        this.proposalInitiativeForm.controls['subtheme_id'].enable()
        this.requestService.getAllSubthemes(themeId).subscribe(data => {
          this.subthemes = data;
          resolve(true);
        });
      }
    });
    return promise;
  }

  async getCategories(subthemeId: number) {
    const promise = new Promise((resolve, reject) => {
      if (subthemeId != null) {
        this.proposalInitiativeForm.controls['category_id'].enable()
        this.requestService.getAllCategories(subthemeId).subscribe(data => {
          this.categories = data;
          resolve(true);
        });
      }
    });
    return promise;
  }

  onThemeSelectChange() {
    this.proposalInitiativeForm.controls['subtheme_id'].enable();
    this.getSubthemes(this.proposalInitiativeForm.get('theme_id')?.value);
  }

  onSubthemeSelectChange() {
    this.proposalInitiativeForm.controls['category_id'].enable();
    this.getCategories(this.proposalInitiativeForm.get('subtheme_id')?.value);
  }

  searchCategory() {
    this.getSearchCategory(this.searchValue);
  }

  getSearchCategory(search: string) {
    this.requestService.getSearchCategory(search).subscribe(data => {
      this.responseSearch = data;
      this.selectedSearch = false;
    });
  }

  selectOption(ids: string) {
    const idsArray = ids.split(',');
    this.selectedSearch = true;
    this.proposalInitiativeForm.controls['theme_id'].setValue(idsArray[0]);
    this.getSubthemes(this.proposalInitiativeForm.get('theme_id')?.value).then(() => {
      this.proposalInitiativeForm.controls['subtheme_id'].setValue(idsArray[1]);
      this.getCategories(this.proposalInitiativeForm.get('subtheme_id')?.value).then(() => {
        this.proposalInitiativeForm.controls['category_id'].setValue(idsArray[2]);
      });
    });
  }

  onFileChange(event: any, control: string) {
    const files: FileList = event.target.files;
    for (let i = 0; i < files.length; i++) {
      this.selectedFiles.push({ type: control, file: files[i] });
    }
  }

  updateCheckbox(event: Event) {
    if (event && event.target instanceof HTMLInputElement) {
      this.proposalInitiativeForm.get('urgent')?.setValue(event.target.checked ? 1 : null);
    }
  }
}
