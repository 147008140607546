<div class="contenedor">
    <div class="col-md-12 m-b-5" *ngFor="let visit of visits">
        <div class="comment row">
            <div class="col-md-9">
                <i class="fa fa-history"></i>
                <cite> <b> {{ visit.nameUser }} => {{visit.positionInstance}}</b></cite>
            </div>
            <div class="col-md-3 date">{{visit.date}}</div>
            <div class="col-md-12">
                <hr>
                <p>{{ visit.nameUserVisitor }} realizó la visita.</p>
                <p>{{ visit.note }}</p>
            </div>
        </div>
    </div>
    <div class="col-md-12 m-b-5" *ngFor="let proposal of proposalH">
        <div class="comment row">
            <div class="col-md-9">
                <i class="fa fa-history"></i>
                <cite> <b> {{ proposal.nameUser }}</b></cite>:
            </div>
            <div class="col-md-3 date">{{proposal.date}}</div>
            <div class="col-md-12">
                <hr>
                <p>{{ proposal.message }}</p>
                <p *ngIf="proposal.note"><b>Nota: </b>{{proposal.note}}</p>
            </div>
        </div>
    </div>
</div>
