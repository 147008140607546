<div class="row" *ngIf="auxLayersLoaded === false">
  <div class="col-xl-10 col-md-8">
  </div>
  <div class="col-xl-2 col-md-4">
    <div class="row">

      <div class="col-xl-10 col-md-8" style="text-align: end;">
       <b>Cargando</b>  capas auxiliares ... 
      </div>
      <div class="col-xl-2 col-md-4" style="text-align: end;">
        <mat-spinner [diameter]="20"></mat-spinner>

      </div>
    </div>
  </div>
</div>

<div class="map-container" [ngClass]="{'loading': mapLoaded==false, 'loaded': mapLoaded==true}">

  <div class="map-frame">
    <div id="map"></div>
  </div>
</div>


<div class="map-container" [ngClass]="{'loading': mapLoaded==!false, 'loaded': mapLoaded==!true}">
  <div class="map-frame">
    <ngx-skeleton-loader [theme]="{height: '100%'}" count="1"></ngx-skeleton-loader>
  </div>
</div>