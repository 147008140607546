import { Component, OnInit } from '@angular/core';
import {Chart} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {ActivatedRoute, Router} from '@angular/router';
import { StatsService } from 'src/app/shared/services/stats.service';
import { AnnualHistoryComponent } from '../../annual-history.component';

@Component({
  selector: 'app-applicants-by-group-old',
  templateUrl: './applicants-by-group-old.component.html',
  styleUrls: ['./applicants-by-group-old.component.scss']
})
export class ApplicantsByGroupOldComponent {

  public chartTotalRequest2: any;
  public chartStatusRequest2: any;
  currentYear = new Date().getFullYear();
  lastYear = this.currentYear - 1;
  requestsLast: any;
  requests: any;
  chartData: any;
  chartDataYear: any;
  stockLastYear: any;

  theme!: any [];
  stock1: any;
  stock1Year: any;

  stock2!: any [];
  stock2Year!: any [];

  themeYear!: any [];

  labels: any[]=[];
  color1 = ['#008000'];
  color2= ['#ff0000'];
  grafico: any;
  public loading = true;
  public loading2 = true;
  showApplicantsByGroupOld: boolean = false;

  constructor(private router:Router, private statsService: StatsService, private activeRoute: ActivatedRoute, private annualHistoryComponent: AnnualHistoryComponent){

  }

  ngAfterViewInit(): void {
    this.loadData();
  }

  loadData(): void {
    // Lógica para cargar los datos

    Promise.all([this.getApplicantByAgeGroupLastYear(), this.getApplicantByAgeGroupYear()])
      .then(() => {

        this.StatusRequestChartTheme2();
        this.totalRequestChartTheme2();
        this.loading = false;

        this.annualHistoryComponent.showApplicantsByGroupOld = true;
        this.annualHistoryComponent.updateView();
      })
      .catch(error => {
        console.error('Error while loading data:', error);
      });
  }
  getApplicantByAgeGroupLastYear(){
    const promise = new Promise((resolve, reject) => {
      this.activeRoute.params.subscribe(routeParams => {
      this.statsService.getApplicantByAgeGroupLastYear(routeParams['idWorkGroup']).subscribe(data => {
          console.log(data);
          this.stock1= data;
          resolve(true);

        }
        )
      });
    });

    return promise;
  }

  getApplicantByAgeGroupYear(){
    const promise = new Promise((resolve, reject) => {
      this.activeRoute.params.subscribe(routeParams => {
      this.statsService.getApplicantByAgeGroupYear(routeParams['idWorkGroup']).subscribe(data => {
          console.log(data);

          this.stock1Year=data;

          resolve(true);

        }
        )
      });
    });
    return promise;
  }

  StatusRequestChartTheme2(){
    const dataValues = [
      this.stock1Year["Indefinido"][0].count,
      this.stock1Year["60 o más"][0].count,
      this.stock1Year["27-59 años"][0].count,
      this.stock1Year["19-26 años"][0].count,
      this.stock1Year["12-18 años"][0].count,
      this.stock1Year["06-11 años"][0].count,
      this.stock1Year["00-05 años"][0].count
    ];

    const maxDataValue = Math.max(...dataValues); // Valor máximo de los datos

    // Calcular el valor máximo deseado en el eje x (agregar un cuadro adicional)
    const maxXValue = Math.ceil(maxDataValue / 20) * 20 + 20;

    this.chartStatusRequest2 = new Chart("StatStatusRequest", {
      type: 'bar',
      data: {
        labels: [
          'Sin información',
          '60 o más',
          '27-59 años',
          '19-26 años',
          '12-18 años',
          '06-11 años',
          '00-05 años'
        ],
        datasets: [
          {
            data: dataValues,
            backgroundColor: [
              '#ffc000',
              '#2e75b6',
              '#bf9000',
              '#ffd966',
              '#8faadc',
              '#c55a11',
              '#7030a0'
            ],
            label: 'Sin información'
          }
        ]
      },
      plugins: [ChartDataLabels],
      options: {
        indexAxis: 'y',
        aspectRatio: 1.2,
        plugins: {
          legend: {
            display: false
          },
          datalabels: {
            color: 'black',
            anchor: 'end',
            align: 'end',
            labels: {
              title: {
                font: {
                  weight: 'bold',
                  size: 17
                }
              }
            }
          }
        },
        scales: {
          x: {
            min: 0, // Valor mínimo deseado en el eje x
            max: maxXValue, // Valor máximo deseado en el eje x
            ticks: {
              stepSize: 20 // Incremento entre los valores en el eje x
            }
          }
        },
        onClick: (e) => {
          this.router.navigate(['requests-subthemes']);
        }
      }
    });
  }

  totalRequestChartTheme2(){
    const dataValues = [
      this.stock1["Indefinido"][0].count,
      this.stock1["60 o más"][0].count,
      this.stock1["27-59 años"][0].count,
      this.stock1["19-26 años"][0].count,
      this.stock1["12-18 años"][0].count,
      this.stock1["06-11 años"][0].count,
      this.stock1["00-05 años"][0].count
    ];

    const maxDataValue = Math.max(...dataValues); // Valor máximo de los datos

    // Calcular el valor máximo deseado en el eje x (agregar un cuadro adicional)
    const maxXValue = Math.ceil(maxDataValue / 20) * 20 + 20;

    this.chartTotalRequest2 = new Chart('StatTotalRequest', {
      type: 'bar',
      data: {
        labels: [
          'Sin información',
          '60 o más',
          '27-59 años',
          '19-26 años',
          '12-18 años',
          '06-11 años',
          '00-05 años'
        ],
        datasets: [
          {
            data: dataValues,
            backgroundColor: [
              '#ffc000',
              '#2e75b6',
              '#bf9000',
              '#ffd966',
              '#8faadc',
              '#c55a11',
              '#7030a0'
            ],
            label: 'Sin información'
          }
        ]
      },
      plugins: [ChartDataLabels],
      options: {
        indexAxis: 'y',
        aspectRatio: 1.2,
        plugins: {
          legend: {
            display: false
          },
          datalabels: {
            color: 'black',
            anchor: 'end',
            align: 'end',
            labels: {
              title: {
                font: {
                  weight: 'bold',
                  size: 17
                }
              }
            }
          }
        },
        scales: {
          x: {
            min: 0, // Valor mínimo deseado en el eje x
            max: maxXValue, // Valor máximo deseado en el eje x
            ticks: {
              stepSize: 20 // Incremento entre los valores en el eje x
            }
          }
        },
        onClick: (e) => {
          this.router.navigate(['requests-subthemes']);
        }
      }
    });
}}
