import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FormDataService {
  private generalFormDataSubject = new BehaviorSubject<FormGroup>(
    this.fb.group({})
  );
  generalFormData$ = this.generalFormDataSubject.asObservable();

  private proposalFormDataSubject = new BehaviorSubject<FormGroup>(
    this.fb.group({})
  );
  proposalFormData$ = this.proposalFormDataSubject.asObservable();

  private completeFormDataSubject = new BehaviorSubject<FormGroup>(
    this.fb.group({})
  );
  completeFormData$ = this.completeFormDataSubject.asObservable();

  private symphatizerFormDataSubject = new BehaviorSubject<FormGroup>(
    this.fb.group({})
  );
  symphatizerFormData$ = this.symphatizerFormDataSubject.asObservable();

  private requestGeneralFormDataSubject = new BehaviorSubject<FormGroup>(
    this.fb.group({})
  );
  requestGeneralFormData$ = this.requestGeneralFormDataSubject.asObservable();

  private requestClasificationFormDataSubject = new BehaviorSubject<FormGroup>(
    this.fb.group({})
  );
  requestClasificationFormData$ = this.requestClasificationFormDataSubject.asObservable();

  private applicantSearchSubject = new Subject<any>();
  applicantSearchResults$ = this.applicantSearchSubject.asObservable();

  private requestDataSubject = new Subject<any>();
  requestDataResults$ = this.requestDataSubject.asObservable();

  private requestEditGeneralFormDataSubject = new BehaviorSubject<FormGroup>(
    this.fb.group({})
  );
  requestEditGeneralFormData$ = this.requestEditGeneralFormDataSubject.asObservable();

  private requestEditClasificationFormDataSubject = new BehaviorSubject<FormGroup>(
    this.fb.group({})
  );
  requestEditClasificationFormData$ = this.requestEditClasificationFormDataSubject.asObservable();

  private requestDocumentationDataSubject = new BehaviorSubject<FormGroup>(
    this.fb.group({})
  );
  requestDocumentationFormData$ = this.requestDocumentationDataSubject.asObservable();

  private sympathizerDataSubject = new Subject<any>();
  sympathizerDataResults$ = this.sympathizerDataSubject.asObservable();

  private promotedFormDataSubject = new BehaviorSubject<FormGroup>(
    this.fb.group({})
  );
  promotedFormData$ = this.promotedFormDataSubject.asObservable();

  private visitFormDataSubject = new BehaviorSubject<FormGroup>(
    this.fb.group({})
  );
  visitFormData$ = this.visitFormDataSubject.asObservable();

  commingFrom: any;
  passing: boolean = false;


  constructor(private fb: FormBuilder, public http: HttpClient) { }
  ineFile: any;
  acuseFile: any;
  extraFile: any;

  allUploadablesRequest: any;
  allUploadablesPromotedVisit: any;
  allUploadables: any;
  AllurlDocument: any;
  AllurlVideo: any;
  AllurlImage: any;
  AllurlINE: any;
  AllurlSP: any;
  AllurlAcuse: any;
  AllurlExtra: any;

  lastForm!: FormGroup;
  lastFormSurvey!: FormGroup;
  lastFormVisit!: FormGroup;
  selectedPOI!: number;
  url = environment.apiUrl;

  getFileType(url: string = ''): string {
    // Extrae la extensión del archivo
    const fileExtension = this.getFileExtension(url);

    // Verifica si es una imagen o un archivo PDF
    if (this.isImageExtension(fileExtension)) {
      return url;
    } else if (this.isPdfExtension(fileExtension)) {
      return 'assets/img/PDF_file_icon.png';
    } else {
      return 'Tipo de archivo desconocido';
    }
  }

  private getFileExtension(url: string): string {
    const parts = url.split('.');
    return parts[parts.length - 1].toLowerCase();
  }

  private isImageExtension(extension: string): boolean {
    // Lista de extensiones de imagen
    const imageExtensions = ['png', 'jpg', 'jpeg', 'gif', 'bmp'];

    return imageExtensions.includes(extension);
  }

  private isPdfExtension(extension: string): boolean {
    return extension === 'pdf';
  }

  getAccountSurveyId(idworkgroup: string) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any[]>(this.url + 'form/workgroup/' + idworkgroup, options);
  }

  getSurvey(id: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any[]>(this.url + 'getFormData/' + id, options);
  }

  getSurveyUpdate(id: number, idPromoted: any) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any[]>(this.url + 'getFormDataUpdate/' + id + '/' + idPromoted, options);
  }


  storeAnswers(formData: any) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };

    console.log(FormGroup);
    return request = this.http.post(this.url + 'answers/store', formData, options);
  }


  setGeneralFormData(form: FormGroup) {
    this.generalFormDataSubject.next(form);
  }

  setProposalFormData(form: FormGroup, allFiles: any[], AllurlDocument: any[], AllurlVideo: any[], AllurlImage: any[]) {
    this.allUploadables = allFiles;
    this.AllurlDocument = AllurlDocument;
    this.AllurlVideo = AllurlVideo;
    this.AllurlImage = AllurlImage;
    this.proposalFormDataSubject.next(form);
  }

  setCompleteFormData(form: FormGroup) {
    this.completeFormDataSubject.next(form);
  }

  setRequestGeneralFormData(form: FormGroup) {
    this.requestGeneralFormDataSubject.next(form);
  }

  setRequestClasificationFormData(form: FormGroup, allFiles: any[]) {
    this.allUploadablesRequest = allFiles;
    this.requestClasificationFormDataSubject.next(form);
  }

  eventApplicantSearchData(data: any) {
    this.applicantSearchSubject.next(data);
  }

  eventRequestData(data: any) {
    this.requestDataSubject.next(data);
  }

  eventSympathizerData(data: any) {
    this.sympathizerDataSubject.next(data);
  }

  setRequestEditGeneralFormData(form: FormGroup) {
    this.requestEditGeneralFormDataSubject.next(form);
  }
  setRequestEditClasificationFormData(form: FormGroup, allFiles: any, AllurlSP: any[]) {
    this.AllurlSP = AllurlSP;
    this.allUploadablesRequest = allFiles;
    this.requestEditClasificationFormDataSubject.next(form);
  }
  setFormData(form: FormGroup, from: string) {
    this.commingFrom = from;
    this.lastForm = form;
    this.symphatizerFormDataSubject.next(form);
  }
  setRequestDocumentationFormData(form: FormGroup, ineFile: any, AllurlINE: any[], acuseFile: any , AllurlAcuse: any[], extraFile: any, AllurlExtra: any[]) {
    this.AllurlINE = AllurlINE;
    this.ineFile = ineFile;
    this.AllurlAcuse =  AllurlAcuse;
    this.acuseFile = acuseFile;
    this.AllurlExtra =  AllurlExtra;
    this.extraFile = extraFile;

    this.requestDocumentationDataSubject.next(form);
  }

  setSurveyFormData(form: FormGroup, from: string) {
    this.commingFrom = from;
    this.lastFormSurvey = form;
    this.promotedFormDataSubject.next(form);
  }

  setVisitFormData(form: FormGroup, from: string) {
    this.commingFrom = from;
    this.lastFormVisit = form;
    this.visitFormDataSubject.next(form);
  }

  setPromotedVisitEvidence(allFiles: any[]) {
    this.allUploadablesPromotedVisit = allFiles;
  }

  setPassing(passing: boolean) {
    this.passing = passing;
  }
}
