<div class="contenedor">
  <div class="panel panel-info">
    <p class="divData">Documentos entregados</p>
  </div>
  <div class="row">
    <div class="col-12 col-md-4">
      <label class="p-t-2">
        <i class="fa fa-user-times" [ngClass]="{'fa-user-check': request.docIne == 1}"></i> INE
      </label>
    </div>
    <div class="row" *ngIf="urlIne != ''">
      <div class="col-12 col-md-4">
        <label>Archivos cargados:</label>
        <!-- <a *ngFor="let url of urlIne" style=" color: #348ee3;" class="align-middle col-md-12 p-t-2 cursor-pointer" (click)="openFile(url)" target="_blank">
                Archivo adjunto</a> -->
        <a *ngFor="let url of urlIne" style=" color: #348ee3;" class="align-middle col-md-12 p-t-2 cursor-pointer"
          (click)="openFile(url)" target="_blank">
          {{getFileName(url)}}
        </a>
      </div>
    </div>
    <div class="col-12 col-md-4">
      <label class="p-t-2">
        <i class="fa fa-user-times" [ngClass]="{'fa-user-check': request.docCertificate == 1}"></i> Acta de
        nacimiento
      </label>
    </div>
    <div class="col-12 col-md-4">
      <label class="p-t-2">
        <i class="fa fa-user-times" [ngClass]="{'fa-user-check': request.docPassport == 1}"></i> Pasaporte
      </label>
    </div>
    <div class="col-12 col-md-4">
      <label class="p-t-2">
        <i class="fa fa-user-times" [ngClass]="{'fa-user-check': request.docProof == 1}"></i> Comprobante de
        domicilio
      </label>
    </div>
    <div class="col-12 col-md-4">
      <label class="p-t-2">
        <i class="fa fa-user-times" [ngClass]="{'fa-user-check': request.docCurp == 1}"></i> CURP
      </label>
    </div>
    <div class="col-12 col-md-4">
      <label class="p-t-2">
        <i class="fa fa-user-times" [ngClass]="{'fa-user-check': request.docRfc == 1}"></i> RFC
      </label>
    </div>

    <div class="col-12 col-md-4">
      <label class="p-t-2">
        <i class="fa fa-user-times" [ngClass]="{'fa-user-check': request.docAcuse == 1}"></i> Acuse
      </label>
    </div>
    <div class="row" *ngIf="urlAcuse && urlAcuse.length">
      <div class="col-12 col-md-12">
        <label>Archivos cargados:</label>
      </div>
      <div class="col-12 col-md-12">

        <a *ngFor="let url of urlAcuse" style=" color: #348ee3;"
          class="align-middle col-md-12 p-t-2 cursor-pointer" (click)="openFile(url)" target="_blank">
          {{getFileName(url)}}
        </a>
      </div>
    </div>

    <div class="col-12 col-md-4">
      <label class="p-t-2">
        <i class="fa fa-user-times" [ngClass]="{'fa-user-check': request.docExtra == 1}"></i>
        Documentación adicional
      </label>
    </div>
    <div class="row" *ngIf="urlsExtra && urlsExtra.length">
      <div class="col-12 col-md-12">
        <label>Archivos cargados:</label>
      </div>
      <div class="col-12 col-md-12">

        <a *ngFor="let url of urlsExtra" style=" color: #348ee3;"
          class="align-middle col-md-12 p-t-2 cursor-pointer" (click)="openFile(url)" target="_blank">
          {{getFileName(url)}}
        </a>
      </div>
    </div>

  </div>
  <div class="panel panel-info">
    <p class="divData">Ciudadano responsable</p>
  </div>
  <div class="row">
    <div class="col-12 col-md-4">
      <label class="p-t-2">
        <i class="fa fa-user-times" [ngClass]="{'fa-user-check': request.propertyTax == 1}"></i> Pago de
        impuestos
      </label>
    </div>
  </div>
  <div class="panel panel-info">
    <p class="divData"></p>
  </div>
  <div class="row" *ngIf="request.url_image_sp != '' && request.url_image_sp && request.url_image_sp != 'null' ">
    <div class="col-12 col-md-4">
      <label>Documento relacionado a la solicitud:</label>
      <!-- <a *ngFor="let url of urlSP" style=" color: #348ee3;" class="align-middle col-md-12 p-t-2 cursor-pointer" (click)="openFile(url)" target="_blank">
            Archivo adjunto</a> -->

      <a *ngFor="let url of urlSP" style=" color: #348ee3;" class="align-middle col-md-12 p-t-2 cursor-pointer"
        (click)="openFile(url)" target="_blank">
        {{getFileName(url)}}
      </a>
    </div>
  </div>
  <div class="row" *ngIf="request.bitacora">
    <div class="col-12 col-md-4">
      <label class="p-t-2">Bitácora:</label>
      <div class="form-control">{{ request.bitacora }}</div>
    </div>
  </div>
</div>
