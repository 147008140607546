import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { POI } from 'src/app/shared/models/POI.model';
import { entitiesModel } from 'src/app/shared/models/entities.model';
import { CustomeValidationService } from 'src/app/shared/services/custome-validation.service';
import { POIService } from 'src/app/shared/services/poi.service';
import { RequestService } from 'src/app/shared/services/request.service';
import { Location } from '@angular/common';
import { MapService } from 'src/app/shared/services/map.service';
import { FormDataService } from 'src/app/shared/services/form-data.service';
import { S3UploaderComponent } from '../../components/s3-uploader/s3-uploader.component';
import { PeriodService } from 'src/app/shared/services/period.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-create-point',
  templateUrl: './create-point.component.html',
  styleUrls: ['./create-point.component.scss']
})
export class CreatePointComponent implements OnInit {
  selectedSkill: any
  entity: entitiesModel = new entitiesModel;
  last: any;
  entities: any = '';
  municipalities: any = '';
  neighboorhoods: any = '';
  types: any;
  userId: number = 2
  userData!: POI;

  @ViewChild(S3UploaderComponent)
  private S3Uploader!: S3UploaderComponent;
  @ViewChild('menu') menu!: ElementRef;
  @ViewChild('searchMenuSecc') searchMenuSecc!: ElementRef;

  POIForm: FormGroup = this.fb.group({
    qrcode: [''],
    name: ['', [Validators.required]],
    type: ['', [Validators.required]],
    otherType: [''],
    email: ['',],
    tel: ['',],
    street: ['',],
    streetNumExt: ['',],
    streetNumInt: ['',],
    postalCode: ['',],
    neighborhood: [{ value: '', disabled: true },],
    municipalitie: ['',],
    entitie: ['',],
    section: ['',],
    reference: ['',],
    gender: ['',],
    assigned: [''],
    localDistrict: [''],
    federalDistrict: [''],

    idWorkGroup: ['', [Validators.required]],
    period: ['']

  });
  postalCodeSearch: any = [];
  neighborhoodOptions: any[] = [];
  municipalitieName: String = '';
  entitieName: String = '';
  postalCodeSelected: boolean = false;
  sectionSelected: boolean = false;

  IdPOI!: string | null;
  lat!: number;
  long!: number;
  sectionOptions: any = [];
  formSympharizer: FormGroup = this.fb.group({});
  commingFrom: string = '';
  localDistricts: any;
  federalDistricts: any;
  federalDistrictChanged: boolean = false;
  localDistrictChanged: boolean = false;
  sectionSearch: string = '';
  showSections: boolean = false;

  periodOptions: any = [];

  constructor(private fb: FormBuilder, private renderer: Renderer2,
    private formDataService: FormDataService, private location: Location, private mapService: MapService, private customizeService: CustomeValidationService, private POIService: POIService, private router: Router, private requestService: RequestService, private activeRoute: ActivatedRoute,
    private periodService: PeriodService
  ) {
    this.getPeriodOptions();
    this.getUserAuth();
    //this.checkformChanges();
    this.getOptions();
    this.attachInputHandlers();
  }

  ngOnInit(): void {
    this.getFormGroupSymphatizer();
    this.backdropDismiss();
  }

  getPeriodOptions(){
    this.periodOptions = this.periodService.options;
  }

  getFormGroupSymphatizer() {
    this.formDataService.symphatizerFormData$.subscribe(form => {
      if (this.formDataService.passing == true) {
        this.formSympharizer = form;

        if (this.formDataService.commingFrom === 'promoted') {
          this.POIForm.patchValue({
            street: form.controls['street'].value,
            streetNumExt: form.controls['street_num_ext'].value,
            streetNumInt: form.controls['street_num_int'].value,
            postalCode: form.controls['postal_code'].value,
            neighborhood: form.controls['id_neighborhood'].value,
            municipalitie: form.controls['id_municipality'].value,
            entitie: form.controls['id_entity'].value,
            section: form.controls['electoral_section'].value,
            reference: form.controls['reference'].value,
          });
          this.getDirectionByPostalCode(form.controls['postal_code'].value);

        } else {
          this.POIForm.patchValue({
            street: form.controls['street'].value,
            streetNumExt: form.controls['streetNumExt'].value,
            streetNumInt: form.controls['streetNumInt'].value,
            postalCode: form.controls['postalCode'].value,
            neighborhood: form.controls['idNeighborhood'].value,
            municipalitie: form.controls['idMunicipality'].value,
            entitie: form.controls['idEntity'].value,
            section: form.controls['electoralSection'].value,
            reference: form.controls['reference'].value,
          });

          this.getDirectionByPostalCode(form.controls['postalCode'].value);

        }
      }
    });
  }
  onInputClick() {
    this.showSections = true;
  }

  backdropDismiss() {
    this.renderer.listen('window', 'click', (e: Event) => {

      if (e.target !== this.menu.nativeElement && e.target !== this.searchMenuSecc.nativeElement) {
        this.showSections = false;
      }
    });
  }

  getOptions() {
    this.getPOITypes();
    //this.getEntities();
    this.isUpdating();
  }

  isUpdating() {
    this.IdPOI = this.activeRoute.snapshot.paramMap.get('id');
    console.log(this.IdPOI);

    if (this.IdPOI != null) {
      this.POIService.getPOI(this.IdPOI).subscribe((data: any) => {

        if (data.location) {
          this.lat = data.location.split(',')[0];
          this.long = data.location.split(',')[1];
        }

        this.POIForm.patchValue({
          qrcode: data.qrcode,
          name: data.name,
          type: data.type,
          email: data.email,
          tel: data.tel,
          street: data.street,
          streetNumExt: data.streetNumExt,
          streetNumInt: data.streetNumInt,
          postalCode: data.postalCode,
          neighborhood: data.neighborhood,
          municipalitie: data.municipalitie,
          entitie: data.entitie,
          section: data.section,
          reference: data.reference,
          gender: data.gender,
          assigned: data.assigned,
          idWorkGroup: data.idWorkGroup,
          period: data.period

        });

        this.getDirectionByPostalCode(data.postalCode);

        console.log(data);

        if (data.electoralSection) {
          this.selectSecction(data.electoralSection);
        }
      })
    }
  }

  getUserAuth() {
    this.activeRoute.params.subscribe(routeParams => {
      if (routeParams['token'] && routeParams['idWorkGroup'] && routeParams['idUser']) {
        localStorage.setItem('token', routeParams['token']);
        localStorage.setItem('idWorkGroup', routeParams['idWorkGroup']);
        localStorage.setItem('environment', routeParams['environment']);

        this.POIForm.controls['assigned'].setValue(routeParams['idUser']);
        this.POIForm.controls['idWorkGroup'].setValue(routeParams['idWorkGroup']);
      }
    });
  }

  getRoute() {
    const parentURL = this.customizeService.getUrl();

    switch (this.formDataService.commingFrom) {
      case 'request':
        this.goBack();
        break;
      case 'symphatizer':
        this.goBack();
        break;
      case 'promoted':
        this.goBack();
        break;

      default:
        window.parent.location.href = parentURL + '/PointsPoints/listPoints.html';
        break;
    }
  }
  selectSecction(Secction: String) {
    this.sectionSelected = true;
    this.POIForm.controls['section'].setValue(Secction);
  }

  clearSection() {
    this.sectionSelected = false;
    this.POIForm.controls['section'].reset();
  }
  async savePOI() {
    const isFormValid = this.POIForm.valid;
    if(isFormValid === true){
      try {

        this.POIForm.controls['qrcode'].setValue(this.generateRandomString());
        //await this.S3Uploader.uploadImage('POI', 'PDF', 2);

        this.POIService.storePOI(this.POIForm.value).subscribe((data: any) => {
          alert('El punto de interés ha sido creado con éxito');
          this.formDataService.selectedPOI = data.id;
          console.log(data);
          this.getRoute();
        })
      } catch (error) {
        // Manejar el error si ocurre algún problema en el proceso del hijo
        console.error('Error en el proceso del componente hijo:', error);
      }
    } else {
      Swal.fire(
        {
          icon: 'error',
          title: '¡No has llenado todos los campos requeridos! Asegúrate de registrar los campos obligatorios y/o diez dígitos en el teléfono',
          text: '',
          confirmButtonText: 'Aceptar'
        }
      );
    }
  }

  updatePOI() {
    const isFormValid = this.POIForm.valid;
    if(isFormValid === true){
      this.POIService.updatePOI(this.POIForm.value, this.IdPOI).subscribe((data: any) => {
        alert('El punto de interés ha sido actualizado con éxito');

        const parentURL = this.customizeService.getUrl();
        window.parent.location.href = parentURL + '/PointsPoints/listPoints.html';
      })
    } else {
      Swal.fire(
        {
          icon: 'error',
          title: '¡No has llenado todos los campos requeridos! Asegúrate de registrar los campos obligatorios y/o diez dígitos en el teléfono',
          text: '',
          confirmButtonText: 'Aceptar'
        }
      );
    }
  }

  attachInputHandlers() {
    const uppercaseFields = ['name', 'otherType', 'type', 'street', 'reference',];

    uppercaseFields.forEach(fieldName => {
      const fieldControl = this.POIForm.get(fieldName);

      if (fieldControl) {
        fieldControl.valueChanges.subscribe((newValue: string) => {
          if (newValue) {
            this.POIForm.get(fieldName)?.setValue(newValue.toUpperCase(), { emitEvent: false });
          }
        });
      }
    });
  }

  clearPostalCode() {
    this.postalCodeSelected = false;
    this.postalCodeSearch = [];
    this.POIForm.controls['postalCode'].reset();
  }

  generateRandomString(): string {
    const length = 5;
    const characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const charactersLength = characters.length;
    let randomString = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charactersLength);
      randomString += characters.charAt(randomIndex);
    }
    return randomString;
  }

  onDistrictChange() {
    // if (this.POIForm.controls['federalDistrict'].value) {
    //   this.federalDistrictChanged = true;
    // }
    // if (this.POIForm.controls['localDistrict'].value) {
    //   this.localDistrictChanged = true;
    // }

    // // Verificar si ambos selectores han cambiado
    // if (this.federalDistrictChanged && this.localDistrictChanged) {
    //   // Ambos selectores han cambiado, ejecuta tu función aquí
    //   this.getSecctionByDistricts();
    // }
  }

  getSecctionByDistricts() {
    console.log('Ambos selectores han cambiado');

    this.mapService.getSecctionByDistricts(this.POIForm.controls['federalDistrict'].value, this.POIForm.controls['localDistrict'].value, this.POIForm.controls['entitie'].value).subscribe((data: any) => {
      this.sectionOptions = data;
      console.log(data);
    })
  }

  getDirectionByPostalCode(postalCode: String) {

    this.POIForm.controls['postalCode'].setValue(postalCode)

    this.POIForm.controls['municipalitie'].reset();
    this.POIForm.controls['entitie'].reset();

    this.requestService.getDirectionByPostalCode(postalCode).subscribe(
      (data: any) => {

        this.postalCodeSelected = true;

        this.municipalitieName = data.municipality.name;
        this.POIForm.controls['municipalitie'].setValue(data.municipality.id);

        this.entitieName = data.entity.name;
        this.POIForm.controls['entitie'].setValue(data.entity.id);

        this.getLocalDistrictsByEntities(data.entity.identifier);
        this.getFederalDistrictsByEntities(data.entity.identifier);
        this.getSecctions(data.municipality.identifier, data.entity.identifier);

        // Clear previous neighborhood options
        this.neighborhoodOptions = [];
        console.log(data.neighborhoods.length);

        if (data.neighborhoods.length > 1) {
          this.POIForm.controls['neighborhood'].enable()
          this.neighborhoodOptions = data.neighborhoods;
        } else {
          this.POIForm.controls['neighborhood'].enable()
          this.neighborhoodOptions = data.neighborhoods;
          console.log(this.neighborhoodOptions);
          this.POIForm.controls['neighborhood'].setValue(data.neighborhoods[0].id)
        }
      },
      (error) => {
        console.error(error); // Handle error appropriately
      }
    );
  }

  selectPostalCode(postalCode: String) {
    this.getDirectionByPostalCode(postalCode);
  }

  validateNumbers(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    inputElement.value = inputElement.value.toString().replace(/\D/g, '');
  }

  onNameInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    inputElement.value = this.customizeService.formatInputName(inputElement.value);
  }

  onCapitalizeInput(event: Event) {
    const inputElementCapitalize = event.target as HTMLInputElement;
    inputElementCapitalize.value = this.customizeService.formatInputCapitalize(inputElementCapitalize.value);
  }


  getPOITypes() {
    this.POIService.getPOITypes().subscribe((data: any) => {
      this.types = data;
      console.log(data);
    })
  }

  getLocalDistrictsByEntities(idEntitie: number) {
    this.mapService.getLocalDistrictsByEntities(idEntitie).subscribe((data: any) => {
      this.localDistricts = data;
      console.log(data);
    })
  }

  getFederalDistrictsByEntities(idEntitie: number) {
    this.mapService.getFederalDistrictsByEntities(idEntitie).subscribe((data: any) => {
      this.federalDistricts = data;
      console.log(data);
    })
  }

  searchPostalCode() {
    this.requestService.searchPostalCode(this.POIForm.controls['postalCode'].value).subscribe((data: any) => {
      this.postalCodeSelected = false;

      console.log(data);
      this.postalCodeSearch = data;
    });
  }

  getEntities() {
    this.requestService.getEntities().subscribe((data: any) => {
      console.log(data);
      this.entities = data;

    });
  }

  getMunicipalities(idEntity: number) {
    this.requestService.getMunicipalities(idEntity).subscribe((data: any) => {
      console.log(data);
      this.municipalities = data;

    })
  }
  getNeighboorhoods(idMunicipality: number) {
    this.requestService.getNeighboorhoods(idMunicipality).subscribe((data: any) => {
      console.log(data);
      this.neighboorhoods = data;

    })
  }

  getSecctions(idMunicipality: number, idEntity: number) {

    this.mapService.getSections(idMunicipality, idEntity).subscribe((data: any) => {
      console.log(data);
      this.sectionOptions = data;

    })
  }

  fillByPostalCode() {
    this.requestService.getDirectionByPostalCode(this.POIForm.controls['postalCode'].value).subscribe((data: any) => {
      console.log(data);

    })
  }

  getSelectedSkill() {
    this.selectedSkill = this.POIForm.controls['type'].value;
  }

  goBack() {
    this.location.back();
  }

}
