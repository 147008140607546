import { Component, OnInit } from '@angular/core';
import {Chart} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { bottom } from '@popperjs/core';

import {ActivatedRoute, Router} from '@angular/router';
import { StatsService } from 'src/app/shared/services/stats.service';
import { statusModel } from 'src/app/shared/models/status.model';
import { themeStatsModel } from 'src/app/shared/models/themeStats.model';

@Component({
  selector: 'app-data-complete',
  templateUrl: './data-complete.component.html',
  styleUrls: ['./data-complete.component.scss']
})
export class DataCompleteComponent implements OnInit {
  chartTotalRequest: any;
  chartStatusRequest: any;
  chartData: any;
  theme!: any [];
  stock1!: any [];
  stock2!: any [];
  semaphore: any;
  semaphoreS: any;
  totalRequests: any;

  femenino: any;
  otro: any;
  indefinido: any;
  masculinoB: any;
  femeninoB: any;
  otroB: any;
  totalS!: any;
  totalR!:any;
  indefinidoB: any;
  labels: any[]=[];
  color1 = ['#70319f'];
  color2= ['#fde69a'];
  grafico: any;
  constructor(private router:Router, private statsService: StatsService, private activeRoute: ActivatedRoute){

  }
  ngOnInit(): void {
    this.activeRoute.params.subscribe(routeParams => {
      localStorage.setItem('token', routeParams['token'])
    });
    this.loadData();
  }
  loadData(){
    this.getRequestsDataComplete().then(data=>{
      this.StatusRequestChart();
    })
    this.getSympathizerDataComplete().then(data=>{
      this.totalRequestChart();
    })

  }
  getRequestsDataComplete(){
    const promise = new Promise((resolve, reject) => {
      this.activeRoute.params.subscribe(routeParams => {
      this.statsService.getRequestsDataComplete(routeParams['idWorkGroup']).subscribe(data => {
        console.log(data)


        this.semaphore = data;
        this.totalR = data.total;
        console.log(this.semaphore);
        resolve(true);

      }
      )
    });
  });
    return promise;

  }
  getSympathizerDataComplete(){
    const promise = new Promise((resolve, reject) => {
      this.activeRoute.params.subscribe(routeParams => {
      this.statsService.getSympathizerDataComplete(routeParams['idWorkGroup']).subscribe(data => {
        console.log(data)

        this.semaphoreS = data;
        this.totalS = data.total;
        console.log(this.semaphoreS);
        resolve(true);

      }
      )
    });
  });
    return promise;
  }

  StatusRequestChart(){

    this.chartStatusRequest = new Chart("ChartStatusRequest", {
      type: 'doughnut',

      data: {
        labels:['Datos incompletos','Datos parcialmente completos', 'Datos completos'],
	      datasets: [
          {
            data:[this.semaphore.red,this.semaphore.yellow,this.semaphore.green],
            backgroundColor: ['#ff0000','#ffbf00', '#00af50'],
          },
        ]
      },
      plugins: [ChartDataLabels],
      options: {
        aspectRatio:1.4,
        plugins: {
          legend: {
              position: 'bottom'
          },

          datalabels: {
            color: 'white',
            labels: {
              title: {
                font: {
                  weight: 'bold',
                  size: 20
                }
              },

            }
          }

      },
      parsing: {
        key: 'nested.value'
      }

      }
    });
  }
  totalRequestChart(){

    this.chartTotalRequest = new Chart("ChartTotalRequest", {
      type: 'doughnut',
      data: {
        labels:['Datos incompletos','Datos parcialmente completos', 'Datos completos'],
	      datasets: [
          {
            data:[this.semaphoreS.red,this.semaphoreS.yellow,this.semaphoreS.green],
            backgroundColor: ['#ff0000','#ffbf00', '#00af50'],
          },
        ]
      },
      plugins: [ChartDataLabels],

      options: {

        aspectRatio:1.4,
        parsing: {
          key: 'nested.value'
        },
        plugins: {

          legend: {
              position: 'bottom'
          },
          datalabels: {
            color: 'white',
            labels: {
              title: {
                font: {
                  weight: 'bold',
                  size: 20
                }
              },

            }
          }


      },

      }
    });
  }

}
