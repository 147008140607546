import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CustomeValidationService } from 'src/app/shared/services/custome-validation.service';
import { FormDataService } from 'src/app/shared/services/form-data.service';
import { ProposalService } from 'src/app/shared/services/proposal.service';
import { RequestService } from 'src/app/shared/services/request.service';
import { UserService } from 'src/app/shared/services/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-complete-proposal',
  template: 'Complete Proposal: {{ idUser }}',
  templateUrl: './complete-proposal.component.html',
  styleUrls: ['./complete-proposal.component.scss']
})
export class CompleteProposalComponent implements OnInit {

  @Input() idUser: number = 0;

  formPromoter: FormGroup = this.fb.group({});
  formProposal: FormGroup = this.fb.group({});


  nameComplete: string = '';
  attendProposal: boolean = false;
  workGroup: number = 0;
  usersOptions: any[] = [];
  idWorkGroup: string = '';
  theme_id: number = 0;
  formGroupValid = false;
  idProposal: any;
  idPromoter: any;
  loading: boolean = false;
  allImages: any;
  allPDFs: any
  allvideos: any
  imagesURLs: any;
  nameAssigned: any = '';

  constructor(private fb: FormBuilder,
    private proposalService: ProposalService,
    private formDataService: FormDataService,
    private userService: UserService,
    private activeRoute: ActivatedRoute,
    private requestService: RequestService,
    private customizeService: CustomeValidationService) {
    this.isUpdating();
  }

  completeForm: FormGroup = this.fb.group({
    idUser: [''],
    idAssigned: [''],
    idstatus: [''],
    qrcode: [''],
    missing_data_color: ['']
  });

  ngOnInit(): void {
    this.getIdWorkGroup();
    this.getFormGroupPromoter();
    this.getFormGroupProposal();
    this.getWorkGroupTheme();
  }

  isUpdating() {
    this.idProposal = this.activeRoute.snapshot.paramMap.get('idProposal');
    if (this.idProposal != null) {
      this.proposalService.getProposal(this.idProposal).subscribe((response: any) => {
        const data = response[0];
        console.log(data);
        this.idPromoter = data.idPromoter;

        this.completeForm.patchValue({
          qrcode: data.qrcode,
          idAssigned: data.idAssigned
        });

        this.userService.getUserRequestInfo(this.completeForm.get('idAssigned')?.value).subscribe((data: any) =>{
          this.nameAssigned = data[0].userName ?? '-';
        });
      });
    }
  }

  getIdWorkGroup() {
    this.activeRoute.params.subscribe(routeParams => {
      this.idWorkGroup = routeParams['idWorkGroup'];
    });
    if(this.idProposal == null || this.idProposal == undefined)
      this.completeForm.controls['qrcode'].setValue(this.generateRandomString());
  }

  generateRandomString(): string {
    const length = 5;
    const characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const charactersLength = characters.length;
    let randomString = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charactersLength);
      randomString += characters.charAt(randomIndex);
    }
    return randomString;
  }

  getFormGroupPromoter() {
    this.formDataService.generalFormData$.subscribe(form => {
      if (form) {
        this.formPromoter = form;
        this.nameComplete = (this.formPromoter.get('name')?.value + ' ' + this.formPromoter.get('next_name')?.value + ' ' + this.formPromoter.get('last_name')?.value);
        if (this.nameComplete == 'undefined undefined undefined') {
          this.nameComplete = '';
        }
      }
    });
  }

  getFormGroupProposal() {
    this.formDataService.proposalFormData$.subscribe(form => {
      if (form) {
        this.formProposal = form;
        if (form.get('theme_id')?.value != undefined) {
          this.theme_id = form.get('theme_id')?.value;
          this.usersOptions = [];
          this.getWorkGroupTheme();
        }
        this.disableButton();
      }
    });
  }

  updateMissingDataColor() {
    if (this.formPromoter) {
      const curpValue = this.formPromoter.get('curp')?.value;
      const allFieldsFilled = Object.keys(this.formPromoter.value).every(
        key => this.formPromoter.value[key] !== ''
      );
      if (allFieldsFilled) {
        this.completeForm.controls['missing_data_color'].setValue('green');
      } else if ((curpValue !== '' && curpValue != null) && !allFieldsFilled) {
        this.completeForm.controls['missing_data_color'].setValue('yellow');
      } else {
        this.completeForm.controls['missing_data_color'].setValue('red');
      }
    }
  }



  handleChange() {
    this.completeForm.controls['idUser'].setValue(this.idUser);
    if (this.attendProposal) {
      this.completeForm.controls['idAssigned'].setValue(this.idUser);
      this.completeForm.controls['idstatus'].setValue(2);
    } else if (this.workGroup) {
      this.completeForm.controls['idAssigned'].setValue(this.workGroup);
      this.completeForm.controls['idstatus'].setValue(2);
    } else {
      this.completeForm.controls['idstatus'].setValue(1);
      this.completeForm.controls['idAssigned'].setValue(0);
    }
  }

  getWorkGroupTheme() {
    this.userService.getWorkGroupTheme(this.idUser, this.idWorkGroup, this.theme_id).subscribe(data => {
      this.usersOptions = data;
    });
  }

  disableButton() {
    const formValuesPromoter = this.formPromoter.value;
    const numberOfFields = Object.keys(formValuesPromoter).length;
    let validPromoter = false;
    if (numberOfFields != 0) {
      validPromoter = true;
    }

    const formValuesProposal = this.formPromoter.value;
    const numberOfFieldsProp = Object.keys(formValuesProposal).length;
    let validProposal = false;
    if (numberOfFieldsProp != 0) {
      validProposal = true;
    }
    this.formGroupValid = validPromoter == true && validProposal == true;
  }

  buttonSave() {
    this.loading = true;
    this.updateMissingDataColor();
    this.handleChange();

    if (this.formPromoter.get('POIrelated')?.value == 0) {
      this.formPromoter.controls['idPOI'].setValue('0');
    }

    if (this.formPromoter.get('actor')?.value == 0) {
      this.formPromoter.controls['actorType'].setValue('');
    }

    if (this.formPromoter.get('participation')?.value == 0) {
      this.formPromoter.controls['participationType'].setValue('');
    }

    if (this.idProposal) {
      console.log(this.idPromoter);
      this.proposalService.updatePromoter(this.idPromoter, this.formPromoter.value).subscribe(data => {
        this.updateProposal(this.idProposal);
      });
    } else {
      this.proposalService.savePromoter(this.formPromoter.value).subscribe(data => {
        this.saveProposal(data.idPromoter);
      });
    }
  }

  saveProposal(idPromoter: number) {
    var isUploading = false;
    const allFiles: any = this.formDataService.allUploadables

    const allPDFsFiles = allFiles.filter((file: any) => file.type == 'PDF');
    const allvideosFiles = allFiles.filter((file: any) => file.type == 'video');
    const allImagesFiles = allFiles.filter((file: any) => file.type == 'image');

    const promises = [];

    if (allPDFsFiles) {
      isUploading = true;
      promises.push(this.stageToUpload(allPDFsFiles, 'PDF', idPromoter));
    }
    if (allvideosFiles) {
      isUploading = true;
      promises.push(this.stageToUpload(allvideosFiles, 'video', idPromoter));
    }
    if (allImagesFiles) {
      isUploading = true;
      promises.push(this.stageToUpload(allImagesFiles, 'image', idPromoter));
    }

    if (isUploading == true) {
      this.loaderAlert();
    }


    Promise.all(promises)
      .then(() => {
        // Este código se ejecutará después de que todas las promesas se hayan resuelto
        console.log('Todas las operaciones se completaron');
        if (isUploading == true) {
          Swal.close();
          this.succesAlert();
        }
        const combinedData = {
          ...this.formProposal.value,
          ...this.completeForm.value,
          idlifting: this.formPromoter.get('idlifting')?.value,
          privacyNotice: this.formPromoter.get('privacyNotice')?.value,
          typeManagment: this.formPromoter.get('typeManagment')?.value,
          groupBe: this.formPromoter.get('groupBe')?.value,
          idWorkGroup: this.idWorkGroup,
          idPromoter: idPromoter,
          allPDFs: this.allPDFs,
          allvideos: this.allvideos,
          allImages: this.allImages,
          period: this.formPromoter.get('period')?.value,
        };
        this.proposalService.saveProposal(combinedData, this.allPDFs, this.allvideos, this.allImages).subscribe(data => {
          this.saveHistory(data.idProposal);
        });
        // Puedes realizar cualquier acción que desees aquí
      })
      .catch((error) => {
        // Manejar errores si es necesario
        console.error('Ocurrió un error en una de las operaciones:', error);
        if (isUploading == true) {
          Swal.close();
          this.errorAlert();
        }
      });



  }

  stageToUpload(files: any, type: string, id: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      console.log(files);
      this.uploadImage(files, type, id)
        .then((data) => {
          console.log(data);

          switch (type) {
            case 'PDF':
              this.allPDFs = data;
              console.log(this.allPDFs);
              break;
            case 'video':
              this.allvideos = data;
              break;
            case 'image':
              this.allImages = data;
              break;
          }

          // Avísanos que la operación se ha completado
          resolve();
        })
        .catch((error) => {
          // Maneja errores si es necesario
          reject(error);
        });
    });
  }

  uploadImage(selectedFiles: any[], type: string, id: number) {
    return new Promise((resolve, reject) => {
      const uploadPromises = [];
      const urls: any = [];
      console.log(selectedFiles);

      for (const file of selectedFiles) {
        console.log(file);

        const uploadPromise = new Promise((innerResolve, innerReject) => {
          this.requestService.uploadImage(file.file, 'proposal', type, id).subscribe(
            (data: any) => {
              console.log(data);
              urls.push(data.path);
              innerResolve(data);
            },
            (error) => {
              innerReject(error);
            }
          );
        });

        uploadPromises.push(uploadPromise);
      }

      Promise.all(uploadPromises)
        .then(() => {
          // Todas las imágenes se han cargado con éxito
          resolve(urls);
        })
        .catch((error) => {
          // Al menos una carga de imagen falló
          reject(error);
        });
    });
  }

  saveHistory(idProposal: number) {
    let complement = ' ';
    if (this.completeForm.get('idAssigned')?.value != 0) {
      if (this.completeForm.get('idAssigned')?.value == this.completeForm.get('idUser')?.value) {
        complement = ' y ha decidido atenderla ';
      } else if (this.completeForm.get('idAssigned')?.value != this.completeForm.get('idUser')?.value) {
        const user = this.usersOptions.find(option => option.idUser == this.workGroup);
        complement = ' y la ha asignado a ' + user.userName;
      }
    }
    const message = 'Ha dado de alta la propuesta' + complement + 'desde Web';
    const historyData = {
      idProposal: idProposal,
      idUser: this.idUser,
      message: message,
      note: null,
      type: this.completeForm.get('idstatus')?.value,
      date: null
    };
    this.proposalService.saveProposalHistory(historyData).subscribe(data => {
      alert('Tu registro ha sido guardada con éxito');
      this.loading = false;
      const parentURL = this.customizeService.getUrl();
      window.parent.location.href = parentURL + '/ProposalsProposals/listProposals.html';
    });
  }

  loaderAlert() {
    Swal.fire({
      icon: 'warning',
      title: "Subiendo archivos",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton())
      },
      willClose: () => {

      }
    }).then((result) => {

    })
  }

  succesAlert() {

    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

    Toast.fire({
      icon: 'success',
      title: "Archivos subidos",
    })

  }

  errorAlert() {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

    Toast.fire({
      icon: 'error',
      title: "Erro al subir archivos",
    })
  }

  updateProposal(idProposal: number) {
    var isUploading = false;

    const allFiles: any = this.formDataService.allUploadables;

    const allPDFsFiles = allFiles.filter((file: any) => file.type == 'PDF');
    const allvideosFiles = allFiles.filter((file: any) => file.type == 'video');
    const allImagesFiles = allFiles.filter((file: any) => file.type == 'image');

    const promises = [];

    if (allPDFsFiles) {
      isUploading = true;

      promises.push(this.stageToUpload(allPDFsFiles, 'PDF', this.idPromoter));
    }
    if (allvideosFiles) {
      isUploading = true;

      promises.push(this.stageToUpload(allvideosFiles, 'video', this.idPromoter));
    }
    if (allImagesFiles) {
      isUploading = true;

      promises.push(this.stageToUpload(allImagesFiles, 'image', this.idPromoter));
    }


  if (isUploading == true) {
      this.loaderAlert();
    }

    Promise.all(promises)
      .then(() => {
        // Este código se ejecutará después de que todas las promesas se hayan resuelto
        console.log('Todas las operaciones se completaron');

        if (isUploading == true) {
          Swal.close();
          this.succesAlert();
        }

        const combinedData = {
          ...this.formProposal.value,
          ...this.completeForm.value,
          idlifting: this.formPromoter.get('idlifting')?.value,
          //privacyNotice: this.formPromoter.get('privacyNotice')?.value,
          typeManagment: this.formPromoter.get('typeManagment')?.value,
          groupBe: this.formPromoter.get('groupBe')?.value,
          idWorkGroup: this.idWorkGroup,
          idPromoter: this.idPromoter,
          period: this.formPromoter.get('period')?.value,
        };
        console.log(this.allPDFs);
        this.proposalService.updateProposal(idProposal, combinedData, this.allPDFs, this.allvideos, this.allImages).subscribe(data => {
          this.saveRequestNotification();
          this.updateHistory(idProposal);
        });
        // Puedes realizar cualquier acción que desees aquí
      })
      .catch((error) => {
        // Manejar errores si es necesario
        console.error('Ocurrió un error en una de las operaciones:', error);

        if (isUploading == true) {
          Swal.close();
          this.errorAlert();
        }
      });


  }

  updateHistory(idProposal: number) {
    const message = 'Ha modificado la propuesta desde Web';
    const historyData = {
      idProposal: idProposal,
      idUser: this.idUser,
      message: message,
      note: null,
      type: 0,
      date: null
    };
    this.proposalService.saveProposalHistory(historyData).subscribe(data => {
      alert('Tu registro ha sido actualizada con éxito');
      this.loading = false;
      const parentURL = this.customizeService.getUrl();
      window.parent.location.href = parentURL + '/ProposalsProposals/listProposals.html';
    });
  }

  saveRequestNotification(){
    const dataNotification = {
      date: null,
      link: this.idProposal,
      idUser: this.idUser,
      idUserNotify: 0,
      message: this.nameAssigned + ', propuesta: ' + (this.completeForm.get('idstatus')?.value == 2 ? 'urgente': 'de rápida respuesta'),
      view: 0,
      typeModule: 'proposal'
    };
    this.requestService.saveRequestNotification(dataNotification).subscribe(data =>{
      console.log(data);
    });
  }
}
