import { Component, OnInit } from '@angular/core';
import {Chart} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {ActivatedRoute, Router} from '@angular/router';
import { StatsService } from 'src/app/shared/services/stats.service';

@Component({
  selector: 'app-closed-requests-and-pending-requests-by-theme-and-year',
  templateUrl: './closed-requests-and-pending-requests-by-theme-and-year.component.html',
  styleUrls: ['./closed-requests-and-pending-requests-by-theme-and-year.component.scss']
})
export class ClosedRequestsAndPendingRequestsByThemeAndYearComponent implements OnInit {

  public chartTotalRequest2: any;
  public chartStatusRequest2: any;
  currentYear = new Date().getFullYear();
  lastYear = this.currentYear - 1;
  requestsLast: any;
  requests: any;
  chartData: any;
  chartDataYear: any;

  theme!: any [];
  stock1!: any [];
  stock1Year!: any [];

  stock2!: any [];
  stock2Year!: any [];

  themeYear!: any [];

  labels: any[]=[];
  color1 = ['#0e6d0f'];
  color2= ['#940000'];
  grafico: any;
  public loading = true;
  public loading2 = true;

  constructor(private router:Router, private statsService: StatsService, private activeRoute: ActivatedRoute){

  }

  ngOnInit(): void {
    this.loading = true;

    Promise.all([this.getThemesRequestsByLastYear(), this.getThemesRequestsByYear()])
      .then(() => {

    this.StatusRequestChartTheme2();
    this.totalRequestChartTheme2();
        this.loading = false;
      })
      .catch(error => {
        console.error('Error while loading data:', error);
        this.loading = false; // asegurarse de quitar el loader en caso de error
      });
  }
  getThemesRequestsByLastYear(){
    const promise = new Promise((resolve, reject) => {
      this.activeRoute.params.subscribe(routeParams => {
      this.statsService.getThemesRequestsByLastYear(routeParams['idWorkGroup']).subscribe(data => {
          console.log(data);
          this.theme = [];
          this.stock1=[];
          this.stock2=[];


          for (var i in data) {
            this.theme.push(data[i].themeName);
            this.stock1.push(data[i].requests.openRequests);
            this.stock2.push(data[i].requests.closedRequests);
          }
          console.log(this.theme);
          console.log(this.stock1);
          console.log(this.stock2);

          this.chartData = {
            labels: this.theme,
            datasets:[
              {
              label: 'Solicitudes cerradas',
              backgroundColor: this.color1,
              data: this.stock2
            }, {
              label: 'Solicitudes pendientes',
              backgroundColor: this.color2,
              data: this.stock1
            }
          ]
        };






          resolve(true);

        }
        )
      });
    });

    return promise;
  }

  getThemesRequestsByYear(){
    const promise = new Promise((resolve, reject) => {
      this.activeRoute.params.subscribe(routeParams => {
      this.statsService.getThemesRequestsByYear(routeParams['idWorkGroup']).subscribe(data => {
          console.log(data);
          this.themeYear = [];
          this.stock1Year=[];
          this.stock2Year=[];


          for (var i in data) {
            this.themeYear.push(data[i].themeName);
            this.stock1Year.push(data[i].requests.openRequests);
            this.stock2Year.push(data[i].requests.closedRequests);
          }
          console.log(this.theme);
          console.log(this.stock1);
          console.log(this.stock2);

          this.chartDataYear = {
            labels: this.themeYear,
            datasets:[
              {
              label: 'Solicitudes cerradas',
              backgroundColor: this.color1,
              data: this.stock2Year
            }, {
              label: 'Solicitudes pendientes',
              backgroundColor: this.color2,
              data: this.stock1Year
            }
          ]
        };






          resolve(true);

        }
        )
      });
    });
    return promise;
  }

  StatusRequestChartTheme2(){

    const dataValues = this.stock1;

    const maxDataValue = Math.max(...dataValues); // Valor máximo de los datos

    // Calcular el valor máximo deseado en el eje x (agregar un cuadro adicional)
    const maxXValue = Math.ceil(maxDataValue / 10) * 10 + 10;
    this.grafico = new Chart("StatStatusRequest", {
      type: 'bar',
      data: this.chartData,
      plugins: [ChartDataLabels],
      options: {
        indexAxis: 'y',
        aspectRatio:1.1,
        plugins: {
          legend: {
              position: 'bottom'
          },
          datalabels: {
            color: 'black',
            anchor: 'end',
            align: 'end',
            labels: {
              title: {
                font: {
                  weight: 'bold',
                  size: 13,
                }
              },

            }
          }

      },
      scales: {
        x: {
          min: 0, // Valor mínimo deseado en el eje x
          max: maxXValue, // Valor máximo deseado en el eje x
          ticks: {
            stepSize: 7 // Incremento entre los valores en el eje x
          }
        }
      },
      onClick: (e) => {

        this.router.navigate(['requests-subthemes']);
      }


      }
    });
  }
  totalRequestChartTheme2(){

    const dataValues = this.stock1Year;

    const maxDataValue = Math.max(...dataValues); // Valor máximo de los datos

    // Calcular el valor máximo deseado en el eje x (agregar un cuadro adicional)
    const maxXValue = Math.ceil(maxDataValue / 7) * 7 + 7;

    this.chartTotalRequest2 = new Chart("StatTotalRequest", {
      type: 'bar',
      data: this.chartDataYear,
      plugins: [ChartDataLabels],
      options: {
        indexAxis: 'y',
        aspectRatio:1.1,
        plugins: {
          legend: {
              position: 'bottom'
          },
          datalabels: {
            color: 'black',
            anchor: 'end',
            align: 'end',
            labels: {
              title: {
                font: {
                  weight: 'bold',
                  size: 13,
                }
              },

            }
          }

      },
      scales: {
        x: {
          min: 0, // Valor mínimo deseado en el eje x
          max: maxXValue, // Valor máximo deseado en el eje x
          ticks: {
            stepSize: 7 // Incremento entre los valores en el eje x
          }
        }
      },
      onClick: (e) => {

        this.router.navigate(['requests-subthemes']);
      }


      }
    });
  }

}
