<div>
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel"></h5>
        <span mat-button mat-dialog-close aria-hidden="true" style="margin-right: 1vw;">&times;</span>
    </div>
    <div class="card">

        <ng-container *ngIf="data.selectedMedia !== ''; else showDefault">
            <ng-container *ngIf="isVideo(data.selectedMedia); else showImage">
                <!-- Mostrar video si es un video -->
                <video class="limit" controls [src]="data.selectedMedia">
                    Tu navegador no soporta el elemento de video.
                </video>
            </ng-container>

            <ng-template #showImage>
                <!-- Mostrar imagen si no es un video -->
                <img class="limit" *ngIf="isImage(data.selectedMedia)" [src]="data.selectedMedia" alt="Imagen seleccionada">
            </ng-template>
        </ng-container>

        <ng-template #showDefault>
            <!-- Manejar el caso en que no se haya seleccionado ningún medio -->
            <p>No se ha seleccionado ningún medio.</p>
        </ng-template>


    </div>
</div>