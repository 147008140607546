import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ActivatedRoute, Router } from '@angular/router';
import { StatsService } from 'src/app/shared/services/stats.service';
import { AnnualHistoryComponent } from '../../annual-history.component';

@Component({
  selector: 'app-beneficiary-statistics',
  templateUrl: './beneficiary-statistics.component.html',
  styleUrls: ['./beneficiary-statistics.component.scss']
})
export class BeneficiaryStatisticsComponent {

  public chartTotalRequest2: any;
  public chartStatusRequest2: any;
  currentYear = new Date().getFullYear();
  lastYear = this.currentYear - 1;
  requestsLast: any;
  requests: any;
  chartData: any;
  chartDataYear: any;

  theme!: any[];
  stock1: any;
  stock1Year: any;

  stock2!: any[];
  stock2Year!: any[];

  themeYear!: any[];

  labels: any[] = [];
  color1 = ['#008000'];
  color2 = ['#ff0000'];
  grafico: any;
  public loading = true;
  public loading2 = true;
  showBeneficiaryStatistics: boolean = false;

  constructor(
    private router: Router,
    private statsService: StatsService,
    private activeRoute: ActivatedRoute,
    private annualHistoryComponent: AnnualHistoryComponent
  ) {}

  ngAfterViewInit(): void {
    this.loadData();
  }

  loadData(): void {
    // Lógica para cargar los datos

    Promise.all([this.getTotalBeneficiariesLastYear(), this.getTotalBeneficiariesYear()])
      .then(() => {

        this.StatusRequestChartTheme2();
        this.totalRequestChartTheme2();
        this.loading = false;

        this.annualHistoryComponent.showBeneficiaryStatistics = true;
        this.annualHistoryComponent.updateView();
      })
      .catch(error => {
        console.error('Error while loading data:', error);
      });
  }

  getTotalBeneficiariesLastYear() {
    const promise = new Promise((resolve, reject) => {
      this.activeRoute.params.subscribe(routeParams => {
        this.statsService.getTotalBeneficiariesLastYear(routeParams['idWorkGroup'],routeParams['level'], routeParams['idUser']).subscribe(data => {
          console.log(data);
          this.stock1 = data;
          resolve(true);
        });
      });
    });

    return promise;
  }

  getTotalBeneficiariesYear() {
    const promise = new Promise((resolve, reject) => {
      this.activeRoute.params.subscribe(routeParams => {
        this.statsService.getTotalBeneficiariesYear(routeParams['idWorkGroup'],routeParams['level'], routeParams['idUser']).subscribe(data => {
          console.log(data);

          this.stock1Year = data;

          resolve(true);
        });
      });
    });
    return promise;
  }

  StatusRequestChartTheme2() {
    const maxValue = Math.max(this.stock1Year.Total, this.stock1Year.completedGoals) + 20;

    this.chartStatusRequest2 = new Chart("StatStatusRequest", {
      type: 'bar',
      data: {
        labels: ['Beneficiarios', 'Metas cumplidas'],
        datasets: [
          {
            data: [this.stock1Year.Total, this.stock1Year.completedGoals],
            backgroundColor: ['#0e6d0f', '#940000'],
            label: 'Solicitudes cerradas',
          }
        ],
      },
      plugins: [ChartDataLabels],
      options: {
        indexAxis: 'y',
        aspectRatio: 1.2,
        plugins: {
          legend: {
            display: false
          },
          datalabels: {
            color: 'black',
            anchor: 'end',
            align: 'end',
            labels: {
              title: {
                font: {
                  weight: 'bold',
                  size: 14,
                }
              },
            }
          }
        },
        scales: {
          x: {
            suggestedMax: maxValue,
          }
        },
        onClick: (e, elements) => {
          console.log(elements[0].index);
          switch (elements[0].index) {
            case 0:
              this.router.navigate(['levantamiento']);
              break;
            case 1:
              this.router.navigate(['requests-subthemes']);
              break;
            case 2:
              this.router.navigate(['requests-subthemes']);
              break;
            case 3:
              this.router.navigate(['requests-subthemes']);
              break;
          }
        },
      },
    });
  }

  totalRequestChartTheme2() {
    const maxValue = Math.max(this.stock1.Total, this.stock1.completedGoals) + 20;

    this.chartTotalRequest2 = new Chart("StatTotalRequest", {
      type: 'bar',
      data: {
        labels: ['Beneficiarios', 'Metas cumplidas'],
        datasets: [
          {
            data: [this.stock1.Total, this.stock1.completedGoals],
            backgroundColor: ['#0e6d0f', '#940000'],
            label: 'Sin información',
          },
        ],
      },
      plugins: [ChartDataLabels],
      options: {
        indexAxis: 'y',
        aspectRatio: 1.2,
        plugins: {
          legend: {
            display: false
          },
          datalabels: {
            color: 'black',
            anchor: 'end',
            align: 'end',
            labels: {
              title: {
                font: {
                  weight: 'bold',
                  size: 17,
                }
              },
            }
          }
        },
        scales: {
          x: {
            suggestedMax: maxValue,
          }
        },
        onClick: (e) => {
          this.router.navigate(['requests-subthemes']);
        }
      }
    });
  }
}
