import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl, FormBuilder, Validators, FormGroup } from '@angular/forms';
import { categoriesModel } from 'src/app/shared/models/categories.model';
import { entitiesModel } from 'src/app/shared/models/entities.model';
import { statusModel } from 'src/app/shared/models/status.model';
import { subThemesModel } from 'src/app/shared/models/subthemes.model';
import { tagsModel } from 'src/app/shared/models/tags.model';
import { themesModel } from 'src/app/shared/models/themes.model';
import { MapService } from 'src/app/shared/services/map.service';
import { RequestService } from 'src/app/shared/services/request.service';
import { UserService } from 'src/app/shared/services/user.service';
import { MapFrameComponent } from '../components/map-frame/map-frame.component';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { userInfoModel } from 'src/app/shared/models/user.info.model';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  group,
  query,
  // ...
} from '@angular/animations';




const loaded = trigger('loaded', [
  transition(':enter', [
    style({
      opacity: 0
    }),
    animate('0.5s ease-in', style({
      opacity: 1
    }))
  ]),
  transition(':leave', [
    animate('0.5s ease-out', style({
      opacity: 1,
      display: 'none'

    }))
  ])
]);

@Component({
  selector: 'app-map',
  animations: [
    loaded
  ],
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
  userId = 38;

  public isCollapsed = true;
  counts: statusModel = new statusModel;
  idStatus: number = 0;
  markerCount: number = 0;
  tags: tagsModel[] = [];
  activeStatus: number = 0;
  activefilters: boolean = false;
  themes: Array<themesModel> = [];
  subthemes: Array<subThemesModel> = [];
  categories: Array<categoriesModel> = [];
  entity: entitiesModel = new entitiesModel;
  municipalities: any[] = [];
  neighborhoods: any[] = [];
  profileEntitie: number = 0;
  profileMunicipalitie: number = 0;
  deleteFiltersActive: boolean = true;
  loading: boolean = true;
  idWorkGroup: string = 'a5771bce93e200c36f7cd9dfd0e5deaa';
  results: any;
  search: string = '';
  none: any = 0;

  showSympathizer: boolean = true;
  showPublicServices: boolean = true;
  showMyRequest: boolean = true;
  showBenefiary: boolean = true;
  showVisitBeneficiary: boolean = true;
  showPOI: boolean = true;

  statusCountLoaded: boolean = false;

  proposalOptions = [
    {
      value: 'all',
      name: 'Todas'
    },
    {
      value: '1',
      name: 'Registro'
    },
    {
      value: '2',
      name: 'Asignada'
    },
    {
      value: '3',
      name: 'En proceso'
    },
    {
      value: '4',
      name: 'Cancelada'
    },
    {
      value: '5',
      name: 'No viable'
    },
    {
      value: '6',
      name: 'Meta cumplida'
    }
  ];



  visitsOptions = [
    {
      name: 'Solicitantes'
    },
    {
      name: 'Beneficiarios'
    },
    {
      name: 'Simpatizantes'
    },
    {
      name: 'Promoventes'
    },
  ];

  positionOptions = [
    {
      name: 'Positivo'
    },
    {
      name: 'Neutro'
    },
    {
      name: 'Negativo'
    },
  ];


  filterForm: FormGroup = this.fb.group({
    themes: ['', [Validators.required]],
    subthemes: [{ value: '', disabled: true }, [Validators.required]],
    categories: [{ value: '', disabled: true }, [Validators.required]],
    entity: [{ value: '', disabled: true }, [Validators.required]],
    municipalities: [{ value: '', disabled: true }, [Validators.required]],
    neighborhoods: [{ value: '', disabled: true }, [Validators.required]],
    seccion: ['', [Validators.required]],
    proposals: ['', [Validators.required]],
    visits: ['', [Validators.required]],
    promoted_position: [''],
  });
  markerType: string = "request";
  entities!: any[];
  userProfile: any;
  isMenuOpen = false;

  @ViewChild('menu') menu!: ElementRef;
  test!: boolean;
  last: any = '';
  holderProfile: any;
  showPromoted: any = true;
  promotedActive: boolean = false;

  constructor(private requestService: RequestService,
    private mapService: MapService,
    public userService: UserService,
    private fb: FormBuilder,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2

  ) {

    this.activeRoute.params.subscribe(routeParams => {
      localStorage.setItem('token', routeParams['token']);
      localStorage.setItem('idWorkGroup', routeParams['idWorkgroup']);

    });

  }

  onSearchChange(newValue: string) {
    this.search = newValue.toUpperCase();
  }

  backdropDismiss() {
    this.renderer.listen('window', 'click', (e: Event) => {

      if (e.target !== this.menu.nativeElement) {
        this.isMenuOpen = false;
      }
    });
  }

  animEnd() {
    console.log('Animation Ended');
  }

  toggleMenu() {
    this.isMenuOpen = true;
    console.log(this.search);

  }


  setFilterTags() {

    this.tags = [];
    let model = new tagsModel;
    let find: any;

    if (this.filterForm.controls['themes'].valid && this.filterForm.controls['themes'].value != 0) {
      find = this.themes.find((item) => item.id == this.filterForm.controls['themes'].value);
      model = { id: this.filterForm.controls['themes'].value, name: find.name, type: 'themes' };
      this.tags.push(model);
    }
    if (this.filterForm.controls['subthemes'].valid && this.filterForm.controls['subthemes'].value != 0) {
      find = this.subthemes.find((item) => item.id == this.filterForm.controls['subthemes'].value);
      model = { id: this.filterForm.controls['subthemes'].value, name: find.name, type: 'subthemes' };
      this.tags.push(model);
    }
    if (this.filterForm.controls['categories'].valid && this.filterForm.controls['categories'].value != 0) {
      find = this.categories.find((item) => item.id == this.filterForm.controls['categories'].value);
      model = { id: this.filterForm.controls['categories'].value, name: find.name, type: 'categories' };
      this.tags.push(model);
    }
    console.log(this.filterForm.controls['municipalities'].value);
    if (this.filterForm.controls['municipalities'].valid && this.filterForm.controls['municipalities'].value != 0) {
      find = this.municipalities.find((item) => item.id == this.filterForm.controls['municipalities'].value);
      model = { id: this.filterForm.controls['municipalities'].value, name: find.name, type: 'municipalities' };
      this.tags.push(model);
    }
    if (this.filterForm.controls['neighborhoods'].valid && this.filterForm.controls['municipalities'].value != 0) {
      find = this.neighborhoods.find((item) => item.id == this.filterForm.controls['neighborhoods'].value);
      model = { id: this.filterForm.controls['neighborhoods'].value, name: find.name, type: 'neighborhoods' };
      this.tags.push(model);
    }
    if (this.filterForm.controls['seccion'].valid && this.filterForm.controls['seccion'].value != 0) {
      model = { id: this.filterForm.controls['seccion'].value, name: this.filterForm.controls['seccion'].value, type: 'seccion' };
      this.tags.push(model);
    }
    if (this.filterForm.controls['proposals'].valid && this.filterForm.controls['proposals'].value != 0) {
      find = this.proposalOptions.find((item) => item.value == this.filterForm.controls['proposals'].value);
      model = { id: this.filterForm.controls['proposals'].value, name: find.name, type: 'proposals' };
      this.tags.push(model);
    }
    if (this.filterForm.controls['visits'].valid && this.filterForm.controls['visits'].value != 0) {
      model = { id: this.filterForm.controls['visits'].value, name: "Visitas a " + this.filterForm.controls['visits'].value, type: 'visits' };
      this.tags.push(model);
    }
  }


  filter() {
    this.filterForm.controls['entity'].setValue(this.entity.id);
    this.setFilterTags();
    this.mapService.onFilterSpecific.emit(this.filterForm.value);
  }

  ngOnInit(): void {
    this.getUserToken();
    this.getThemes();

    // this.getProfile().then(() => this.getEntities().then(() => this.getEntity(this.userService.profileEntitie).then(() => this.getMunicipalities(this.userService.profileEntitie).then(() => {
    //   this.setFilterMunicipalitie().then(data => {
    //   });


    //   this.filterAuth(this.userProfile);
    //   this.activeRoute.params.subscribe(routeParams => {
    //     if (routeParams['sympathizer'] == 1) {
    //       this.symapthizerClicked(true);
    //     }
    //   })
    // }))));
    this.getProfile().then((userData: any) => {
      console.log(userData);
      this.userProfile = userData;
      this.formAuth(userData);
    });
    this.checkformChanges();
  }

  getUserToken() {
    this.activeRoute.params.subscribe(routeParams => {
      localStorage.setItem('token', routeParams['token'])
    });
  }

  formAuth(userProfile: any) {
    switch (userProfile.idProfile) {
      case 4:
        this.getEntities().then(() => {

          this.filterAuth(userProfile);
          this.activeRoute.params.subscribe(routeParams => {
            if (routeParams['sympathizer'] == 1) {
              this.symapthizerClicked(true);
            }
          })
        });

        break;

      case 13:
        this.userService.getHolderProfile(userProfile.idHolder).subscribe((holderProfile: any) => {
          console.log(holderProfile);
          this.holderProfile = holderProfile;
          this.formAuth(holderProfile);
        });
        break;


      default:
        console.log(this.userService.profileEntitie);
        this.getEntities().then(() => this.getEntity(this.userService.profileEntitie).then(() => this.getMunicipalities(this.userService.profileEntitie).then(() => {
          this.setFilterMunicipalitie().then(data => {
          });

          this.filterAuth(userProfile);
          this.activeRoute.params.subscribe(routeParams => {
            if (routeParams['sympathizer'] == 1) {
              this.symapthizerClicked(true);
            }
          })
        })));
        break;
    }
  }

  setFilterMunicipalitie() {
    return new Promise((resolve, reject) => {

      const rango_minimo = 2460;
      const rango_maximo = 2491;

      if (this.userService.profileMunicipalitie < rango_minimo && this.userService.profileMunicipalitie > rango_maximo) {
        this.filterForm.controls['municipalities'].setValue(null);
      } else {
        this.filterForm.controls['municipalities'].setValue(this.userService.profileMunicipalitie);
      }
      this.mapService.onMunicipalitieSelected.emit(true);
      resolve(true);
    })
  }

  filterAuth(idProfile: any) {
    switch (idProfile.idProfile) {
      case 4:
        if (this.filterForm.controls['entity'].disabled) {
          this.filterForm.controls['entity'].enable()
        }
        if (this.entity.id) {
          this.filterForm.controls['municipalities'].enable()
        }

        break;
      case 8:
        if (this.filterForm.controls['entity'].disabled) {
          this.filterForm.controls['entity'].enable()
        } this.filterForm.controls['municipalities'].enable()

        break;
      case 9:
        this.filterForm.controls['municipalities'].enable()

        break;
      case 5:
        this.filterForm.controls['municipalities'].enable()


        break;
      case 6:
        this.filterForm.controls['neighborhoods'].enable();


        break;
      case 10:
        this.filterForm.controls['municipalities'].enable()

        break;


      default:
        break;
    }
  }

  getEntities() {
    return new Promise((resolve, reject) => {

      this.requestService.getEntities().subscribe(data => {
        // this.filterForm.controls['entity'].enable()
        this.entities = data;
        resolve(true);
      },
        (error) => { reject(error) }
      )

    });
  }

  checkformChanges() {
    this.filterForm.get('themes')!.valueChanges.subscribe(data => {
      if (data != "" && data != null) {
        console.log('Form changes', data);
        this.getSubthemes(data);
      }
    });

    this.filterForm.get('subthemes')!.valueChanges.subscribe(data => {
      if (data != "" && data != null) {
        console.log('Form changes', data);
        this.getCategories(data);
      }
    });

    this.filterForm.get('entity')!.valueChanges.subscribe(data => {
      if (data != "" && data != null && data != this.last) {
        this.last = data;
        this.entity.id = data;
        this.mapService.onEntitieChange.emit({ idEntitie: data });
        this.filterForm.controls['municipalities'].setValue(null);
        this.filterForm.controls['neighborhoods'].disable();

        console.log('Form changes', data);
        this.getMunicipalities(data).then(data => {
          console.log(this.holderProfile);
          if (this.holderProfile != undefined) {
            this.filterAuth(this.holderProfile);

          } else {
            console.log(this.userProfile)
            this.filterAuth(this.userProfile);

          }
        })
      }
    })

    this.filterForm.get('municipalities')!.valueChanges.subscribe(data => {
      if (data != "" && data != null && data != 0) {
        this.mapService.onMunicipalitieChange.emit({ idMunicipalitie: data, idEntitie: this.entity.id });
        console.log('Form changes', data);
        this.getNeighboorhoods(data);
      }
    });
  }

  symapthizerClicked(value: boolean) {
    this.showSympathizer = !value;
    this.userService.onSympathizer.emit(value);
  }

  promotedClicked(value: boolean) {
    this.showPromoted = !value;
    this.userService.onPromoted.emit(value);
  }

  beneficiaryClicked(value: boolean) {
    this.showBenefiary = !value;

    if (value == true) {

      this.showBenefiary = !value;
      this.activefilters = true;
      this.filterClicked(6);
    } else {
      this.activefilters = false;
      this.activeStatus = 0;
      this.tags = [];
      this.activefilters = false;
      this.activeStatus = 0;
      this.mapService.onClearStatus.emit();

    }

  }

  myRequestClicked(value: boolean) {
    this.showMyRequest = !value;
    if (value) {
      this.filterButtonClicked(1);
    } else {
      this.mapService.onClearStatus.emit();
    }
  }

  promotedValidations() {


    this.visitsOptions.push(
      {
        name: 'Promovidos'
      }
    );
  }

  
  getPromotedControl(): boolean {
    return this.filterForm.get('promoted_position') !== null;
  }

  async getProfile() {

    const promise = new Promise((resolve, reject) => {
      this.activeRoute.params.subscribe(routeParams => {

        this.userService.getProfile(routeParams['idUser'], routeParams['idWorkgroup']).subscribe(data => {
          this.userService.getEnabledModules(routeParams['idUser']).subscribe((modules: any) => {
            this.userService.modules = modules;
            this.promotedActive = modules.promoted

            if (this.promotedActive == true) {
              this.promotedValidations();
            }

            this.userService.profileInfo = data[0];
            this.userService.onProfileInfo.emit(data[0]);
            this.userService.profileEntitie = data[0].idProfileEntity;
            this.userService.profileMunicipalitie = data[0].idProfileMunicipality;
            this.userService.profileLevel = data[0].userLevel;
            this.userProfile = data[0].idProfile;

            this.getStatusCount().then(() => {
              console.log('done load');
              this.statusCountLoaded = true
            });

            resolve(data[0]);
          })
        },
          (error) => { reject(true) }
        )
      });

    });
    return promise;
  }

  getEntity(entityId: number) {
    console.log(entityId);
    const promise = new Promise((resolve, reject) => {
      this.requestService.getEntity(entityId).subscribe(data => {
        this.entity = data[0];
        this.filterForm.controls['entity'].setValue(this.entity.id);
        resolve(true);

        console.log(this.entity)
      }
      )
    });
    return promise;
  }

  getMunicipalities(entitieId: number) {
    return new Promise((resolve, reject) => {

      this.requestService.getMunicipalities(entitieId).subscribe(data => {
        this.municipalities = data;
        resolve(true);
      },
        (error) => { reject(true) }
      )

    });


  }

  getNeighboorhoods(idMunicipality: number) {
    this.requestService.getNeighboorhoods(idMunicipality).subscribe(data => {
      this.filterForm.controls['neighborhoods'].enable();
      this.neighborhoods = data;
    });
  }

  getThemes() {
    this.requestService.getAllThemes().subscribe(data => {
      this.themes = data;
    });
  }

  getSubthemes(themeId: number) {
    console.log(themeId);
    if (themeId != null) {
      this.filterForm.controls['subthemes'].enable()
      this.requestService.getAllSubthemes(themeId).subscribe(data => {
        this.subthemes = data;
      });
    }
  }

  getCategories(subthemeId: number) {
    if (subthemeId != null) {
      this.filterForm.controls['categories'].enable()
      this.requestService.getAllCategories(subthemeId).subscribe(data => {
        this.categories = data;
      });
    }
  }

  setMarkerCount(countInfo: { count: number, type: string }) {

    this.markerCount = countInfo.count;
    this.markerType = countInfo.type;

  }

  filterClicked(idStatus: any) {

    this.mapService.onSelectStatus.emit(idStatus);
    this.activefilters = true;
    this.activeStatus = idStatus;
    this.tags = [];
    this.setStatusTags(idStatus);
  }

  resetForm() {
    this.filterForm.controls['subthemes'].disable();
    this.filterForm.controls['categories'].disable();
    this.filterForm.controls['neighborhoods'].disable();

    this.filterForm.controls['themes'].setValue("");
    this.filterForm.controls['subthemes'].setValue("");
    this.filterForm.controls['categories'].setValue("");
    this.filterForm.controls['neighborhoods'].setValue("");
    if (!this.filterForm.controls['entity'].disabled) {
      this.filterForm.controls['entity'].setValue("");
    }
    if (!this.filterForm.controls['municipalities'].disabled) {
      this.filterForm.controls['municipalities'].setValue("");
      this.filterForm.controls['municipalities'].disable();
    }
    // console.log(this.userService.profileMunicipalitie)
    // this.filterForm.controls['municipalities'].setValue("");
    // this.filterForm.controls['municipalities'].disable();

    this.filterForm.controls['proposals'].setValue("");
    this.filterForm.controls['visits'].setValue("");
    this.filterForm.controls['seccion'].setValue("");

    if (this.holderProfile != undefined) {
      this.filterAuth(this.holderProfile);

    } else {
      console.log(this.userProfile)
      this.filterAuth(this.userProfile);

    }


  }

  resetButtons() {
    this.showPOI = true;
    this.showMyRequest = true;
    this.showSympathizer = true;
    this.showBenefiary = true;
    this.showPublicServices = true;

  }

  clearAllFilter() {
    this.activefilters = false;
    this.activeStatus = 0;
    this.mapService.onClearStatus.emit(this.test);
    this.resetForm();
    this.resetButtons();
    this.test = !this.test
    this.tags = [];
  }

  clearStatusMarker(tag: tagsModel) {
    console.log("Etiqueta " + tag.type);
    if (tag.type === 'seccion') {
      this.activefilters = false;
      this.activeStatus = 0;
      this.tags = this.tags.filter(item => item !== tag);
      this.mapService.onClearSecction.emit(true);
      this.filterForm.controls[tag.type].setValue("");

      this.mapService.onFilterSpecific.emit(this.filterForm.value);
    } else if (tag.type === 'status') {
      this.activefilters = false;
      this.activeStatus = 0;
      console.log("Etiqueta " + tag.type);
      this.tags = this.tags.filter(item => item !== tag);

      this.mapService.onFilterSpecific.emit(this.filterForm.value);
    } else {
      this.activefilters = false;
      this.activeStatus = 0;
      this.tags = this.tags.filter(item => item !== tag);
      this.filterForm.controls[tag.type].setValue("");
      console.log("Etiqueta " + tag.type);

      this.mapService.onFilterSpecific.emit(this.filterForm.value);
    }
  }

  setStatusTags(idStatus: number) {
    var model: tagsModel = new tagsModel;

    switch (idStatus) {
      case 1:
        model = { id: idStatus, name: 'En registro', type: 'status' };
        this.tags.push(model);
        break;
      case 2:
        model = { id: idStatus, name: 'Asignado', type: 'status' };
        this.tags.push(model);

        break;
      case 3:
        model = { id: idStatus, name: 'Noviable', type: 'status' };
        this.tags.push(model);

        break;
      case 4:
        model = { id: idStatus, name: 'Cancelado', type: 'status' };
        this.tags.push(model);

        break;
      case 5:
        model = { id: idStatus, name: 'En proceso', type: 'status' };
        this.tags.push(model);

        break;
      case 6:
        model = { id: idStatus, name: 'Meta cumplida', type: 'status' };
        this.tags.push(model);

        break;
      case 7:
        model = { id: idStatus, name: 'Aceptado', type: 'status' };
        this.tags.push(model);

        break;
      case 8:
        model = { id: idStatus, name: 'Preregistro', type: 'status' };
        this.tags.push(model);

        break;

      default:
        break;
    }
  }

  filterButtonClicked(type: any) {
    this.mapService.onSelectButton.emit(type);
  }

  POIButtonClicked(value: boolean) {
    this.showPOI = !value;
    if (value == true) {
      this.activeRoute.params.subscribe(routeParams => {
        this.mapService.onPOI.emit(true);
      });
    } else {
      this.mapService.onClearStatus.emit();
    }
  }

  publicServiceButtonClicked(value: boolean) {
    this.showPublicServices = !value;
    if (value == true) {
      this.activeRoute.params.subscribe(routeParams => {
        this.mapService.onPublicServiceButton.emit(routeParams['idWorkgroup']);
      });
    } else {
      this.mapService.onClearStatus.emit();
    }
  }

  visitedBeneficiaryButtonClicked(value: boolean) {
    this.showVisitBeneficiary = !value;
    if (value == true) {
      this.activeRoute.params.subscribe(routeParams => {
        this.mapService.onVisitedBeneficiaryButton.emit(routeParams['idUser']);
      })
    } else {
      this.mapService.onClearStatus.emit();
    }
  }

  getStatusCount() {
    return new Promise((resolve) => {
      this.activeRoute.params.subscribe(routeParams => {
        this.requestService.getStatusCount(routeParams['idWorkgroup'], this.userService.profileLevel, routeParams['idUser']).subscribe(data => {

          this.counts = new statusModel().deserialize(data);
          console.log('done')
          resolve(true);
        });
      });
    })
  }
  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  getSearch(event: any) {
    this.backdropDismiss();

    this.loading = true;
    console.log(event.which);
    this.activeRoute.params.subscribe(routeParams => {
      if (this.search.length >= 3) {
        this.isMenuOpen = true;
        this.delay(3000).then(data => {
          this.mapService.getSearch(this.search, routeParams['idWorkgroup']).subscribe(data => {
            this.results = data;
            console.log(this.results);
            this.mapService.dataSearch.emit(this.results);
            this.loading = false;
          });
        });
      } else {
        this.results = [];
        if (this.search.length == 0 && event.which == 8) {
          this.clearAllFilter();
        }
      }
    })
  }

  buttonSearch(results: any) {
    if (this.search.length < 3) {
      this.alertModal();
    }
    if (results.length > 0) {
      this.mapService.dataSearch.emit(results);
    }
  }

  alertModal() {
    Swal.fire({
      title: 'La búsqueda debe tener al menos 3 letras.',
      confirmButtonText: 'Aceptar',
      confirmButtonColor: '#49b6d6',
    })
  }

}
