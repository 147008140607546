import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CustomeValidationService } from 'src/app/shared/services/custome-validation.service';
import { RequestService } from 'src/app/shared/services/request.service';
import { SympathizerService } from 'src/app/shared/services/sympathizer.service';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { PassDataComponent } from '../../components/modal/pass-data/pass-data.component';
import { POIService } from 'src/app/shared/services/poi.service';
import { FormDataService } from 'src/app/shared/services/form-data.service';
import { MapService } from 'src/app/shared/services/map.service';
import Swal from 'sweetalert2';
import { UserService } from 'src/app/shared/services/user.service';
import { PeriodService } from 'src/app/shared/services/period.service';

declare var $: any;
@Component({
  selector: 'app-create-sympathizer',
  templateUrl: './create-sympathizer.component.html',
  styleUrls: ['./create-sympathizer.component.scss']
})
export class CreateSympathizerComponent implements OnInit {
  @ViewChild('menu') menu!: ElementRef;
  @ViewChild('searchMenuSecc') searchMenuSecc!: ElementRef;

  sympathizerForm: FormGroup = this.FB.group({
    qrcode: [''],
    name: ['', [Validators.required]],
    next_name: ['', [Validators.required]],
    last_name: ['', [Validators.required]],
    gender: [''],
    birthDate: [''],
    curp: ['', [this.curpValidator.bind(this)]],
    email: ['', [Validators.email]],
    phone: [''],
    cellPhone: ['', [Validators.required]],
    civilStatus: [''],
    birthPlace: [''],
    birthPlaceM: [{ value: '', disabled: true }],
    scholarship: [''],
    ocupation: [''],
    workplace: [''],
    cellPhoneOffice: [''],
    facebook: [''],
    instagram: [''],
    federalSupport: 0,
    typeSupport: [''],
    street: [''],
    streetNumExt: [''],
    streetNumInt: [''],
    postalCode: [''],
    idPostalCode: [0],
    idNeighborhood: [{ value: '', disabled: true }],
    idMunicipality: [0],
    idEntity: [0],
    electoralSection: [''],
    reference: [''],
    idWorkGroup: [''],
    location: ['0'],
    idUser: [''],
    missing_data_color: ['red'],
    POIrelated: 0,
    idPOI: 0,
    participationType: [''],
    otherType: [''],
    bitacora: [''],
    participation: 0,
    actor: 0,
    actorType: [''],
    otherActor: [''],
    privacyNotice: ['', [Validators.required]],
    idlifting: ['', [Validators.required]],
    interests: [''],
    country: [''],
    entity: [''],
    municipality: [''],
    neighborhood: [''],

    idCountry: [''],
    affiliated: 0,
    affiliated_political_party: [''],
    affiliation_date: [''],
    other_political_party: [''],
    period: ['']
  });

  sectionOptions: any = [];
  qrcode: string = '';
  postalCodeSearch: any = [];
  neighborhoodOptions: any[] = [];
  neighborhoodName: any = "";
  municipalitieName: String = '';
  entitieName: String = '';
  entities: any = '';
  municipalities: any = '';
  postalCodeSelected: boolean = false;
  sectionSelected: boolean = false;

  loading: boolean = false;
  idSympathizer: any;
  POIoptions: any;
  lat!: number;
  long!: number;
  Allurlparticipation: any = [];
  selectedFile: any = [];
  allFiles: any = [];
  newUrlparticipation: any = [];
  uploadingFiles: boolean = false;
  sectionSearch: string = '';
  showSections: boolean = false;
  isReadMore = true;
  idWorkGroup: any;
  international: boolean = false;
  lada: string = '';
  countries: any = [];
  selectedCountry: any;
  number: string = '';
  numberOffice: any = '';
  houseNumber: any = '';

  parties: any = [];
  periodOptions: any = [];
  interestOptions: any = ['Cultura','Deportes','Entretenimiento','Eventos','Gastronomía','Salud'];

  constructor(
    private FB: FormBuilder,
    private customizeService: CustomeValidationService,
    private requestService: RequestService,
    private activeRoute: ActivatedRoute,
    private sympathizerService: SympathizerService,
    public dialog: MatDialog,
    private POIService: POIService,
    private formDataService: FormDataService,
    private mapService: MapService,
    private location: Location,
    private renderer: Renderer2,
    private userService: UserService,
    private periodService: PeriodService
  ) {

    if (this.activeRoute.snapshot.paramMap.get('idWorkGroup') == '25afe9f1c57b8a50b308f94734ffde98') {
      this.international = true;
      this.allCountries();
    }
    this.getUserAuth();
    this.getPeriodOptions();
    this.attachInputHandlers();
    this.getPoliticalParties();
    this.backdropDismiss();
  }

  openModal() {
    $('#exampleModal').modal('show');
  }

  closeModal() {
    $('#exampleModal').modal('hide');
  }

  toggleText() {
    this.isReadMore = !this.isReadMore;
  }

  onInputClick() {
    this.showSections = true;
  }

  backdropDismiss() {
    this.renderer.listen('window', 'click', (e: Event) => {

      if (e.target !== this.menu.nativeElement && e.target !== this.searchMenuSecc.nativeElement) {
        this.showSections = false;
      }
    });
  }

  ngOnInit(): void {
    this.idWorkGroup = this.activeRoute.snapshot.paramMap.get('idWorkGroup');
    this.getEntities();
    this.getPOIs();
    this.fillAfterCreatePOI();


    this.sympathizerForm.valueChanges.subscribe(data => {
      console.log(data);
    });
  }

  getPeriodOptions() {
    this.periodOptions = this.periodService.options;
  }

  getPoliticalParties() {
    this.userService.getPoliticalParties().subscribe(response => {
      this.parties = response;
      this.isUpdating();
    })
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(PassDataComponent, {
      data: {
        form: this.sympathizerForm,
        idUser: this.activeRoute.snapshot.paramMap.get('idUser'),
        idWorkGroup: this.activeRoute.snapshot.paramMap.get('idWorkGroup'),
        token: this.activeRoute.snapshot.paramMap.get('token'),
        environment: this.activeRoute.snapshot.paramMap.get('environment'),
        from: 'symphatizer'
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      //this.animal = result;
    });
  }

  removeFile(url: string) {
    this.Allurlparticipation = this.Allurlparticipation.filter((item: any) => item !== url);
  }

  allCountries() {
    return new Promise((resolve, reject) => {
      this.requestService.getAllCountries().subscribe(
        (data: any) => {
          this.countries = data;
          resolve(data); // Resolvemos la promesa con los datos
        },
        (error) => {
          reject(error); // Rechazamos la promesa en caso de error
        }
      );
    });
  }
  selectCountry(): void {
    const country = this.sympathizerForm.controls['country'].value;
    const idCountry = this.countries.find((option: any) => option.name == country);
    this.sympathizerForm.controls['idCountry'].setValue(idCountry.id);

    this.getInternationalInfo(country);
  }

  parseNumber(number: string, control: string) {
    const format: string = this.lada + " " + number;
    this.sympathizerForm.controls[control].setValue(this.lada + " " + number);
    console.log(format);

    console.log(this.sympathizerForm.controls[control].value);
  }

  getInternationalInfo(country: string) {
    return new Promise((resolve, reject) => {
      this.requestService.getLocationInfo(country).subscribe(
        (data: any) => {
          data = data[0];
          console.log(data);
          this.selectedCountry = data;
          this.lada = data.idd.root + "" + data.idd.suffixes[0]
          resolve(data); // Resolvemos la promesa con los datos
        },
        (error) => {
          reject(error); // Rechazamos la promesa en caso de error
        }
      );
    });
  }

  onFileChange(event: any) {
    const files: FileList = event.target.files;

    for (let i = 0; i < files.length; i++) {
      this.selectedFile.push({ file: files[i] });
    }
    console.log(this.selectedFile);
  }

  stageToUpload(files: any, id: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      console.log(files);
      this.uploadImage(files, id)
        .then((data) => {
          console.log(data);
          this.newUrlparticipation = data;
          // Avísanos que la operación se ha completado
          resolve();
        })
        .catch((error) => {
          // Maneja errores si es necesario
          reject(error);
        });
    });
  }

  openImage(url: string) {
    window.open(url, '_blank');
  }

  uploadImage(selectedFiles: any[], id: number) {
    return new Promise((resolve, reject) => {
      const uploadPromises = [];
      const urls: any = [];
      console.log(selectedFiles);

      for (const file of selectedFiles) {
        console.log(file);

        const uploadPromise = new Promise((innerResolve, innerReject) => {
          this.requestService.uploadImage(file.file, 'participation', 'files', id).subscribe(
            (data: any) => {
              console.log(data);
              urls.push(data.path);
              innerResolve(data);
            },
            (error) => {
              innerReject(error);
            }
          );
        });

        uploadPromises.push(uploadPromise);
      }

      Promise.all(uploadPromises)
        .then(() => {
          // Todas las imágenes se han cargado con éxito
          resolve(urls);
        })
        .catch((error) => {
          // Al menos una carga de imagen falló
          reject(error);
        });
    });
  }


  isUpdating() {

    this.idSympathizer = this.activeRoute.snapshot.paramMap.get('idSympathizer');
    if (this.idSympathizer != null) {
      this.sympathizerService.getSympathizer(this.idSympathizer).subscribe((response: any) => {
        const data = response[0];
        this.qrcode = data.qrcode;

        if (data.location) {
          this.lat = data.location.split(',')[0];
          this.long = data.location.split(',')[1];
        }

        if (data.url_image_participatio) {
          this.Allurlparticipation = JSON.parse(data.url_image_participatio);

        } else {
          this.Allurlparticipation = [];
        }

        const hasMatch = this.parties.some((party: any) => party.name == data.affiliated_political_party);
        this.sympathizerForm.patchValue({
          qrcode: data.qrcode,
          name: data.name,
          next_name: data.next_name,
          last_name: data.last_name,
          gender: data.gender ?? '',
          birthDate: data.birthDate,
          curp: data.curp,
          email: data.email,
          phone: data.phone,
          cellPhone: data.cellPhone,
          civilStatus: data.civilStatus ?? '',
          birthPlace: data.birthPlace ?? '',
          birthPlaceM: data.birthPlaceM ?? '',
          scholarship: data.scholarship ?? '',
          ocupation: data.ocupation ?? '',
          workplace: data.workplace,
          cellPhoneOffice: data.cellPhoneOffice,
          facebook: data.facebook,
          instagram: data.instagram,
          federalSupport: data.federalSupport,
          typeSupport: data.typeSupport,
          street: data.street,
          streetNumExt: data.streetNumExt,
          streetNumInt: data.streetNumInt,
          postalCode: data.postalCode,
          idPostalCode: data.idPostalCode,
          idNeighborhood: data.idNeighborhood ?? '',
          idMunicipality: data.idMunicipality,
          idEntity: data.idEntity,
          electoralSection: data.electoralSection ?? '',
          reference: data.reference,
          idWorkGroup: data.idWorkGroup,
          location: data.location,
          idUser: data.idUser,
          missing_data_color: data.missing_data_color,
          POIrelated: data.idPOI ? 1 : 0,
          idPOI: data.idPOI ?? 0,
          participationType: data.participationType ?? '',
          otherType: data.otherType,
          bitacora: data.bitacora,
          participation: data.participationType ? 1 : 0,
          actor: data.actorType ? 1 : 0,
          actorType: data.actorType,
          otherActor: data.otherActor,
          idlifting: data.idlifting ?? '',
          privacyNotice: data.privacyNotice,

          entity: data.entity,
          country: data.country,
          idCountry: data.idCountry,
          municipality: data.municipality,

          interests: data.interests ? data.interests.split(','): '',
          affiliated: data.affiliated,
          affiliated_political_party: hasMatch == false ? 'OTRO' : data.affiliated_political_party,
          affiliation_date: data.affiliation_date,
          other_political_party: hasMatch == false ? data.affiliated_political_party : '',
          period: data.period ?? '',
        });

        if (this.international == true) {

          this.getInternationalInfo(data.country).then(() => {
            this.requestService.searchInternationalPostalCode(this.sympathizerForm.controls['postalCode'].value, this.selectedCountry.altSpellings[0]).subscribe((data: any) => {
              this.postalCodeSelected = true;
              // this.selectInternationalPostal(data.postalCodes[0]);
            });
          });
          if (data.cellPhone) {
            this.number = data.cellPhone.split(" ")[1];
          }
          if (data.cellPhoneOffice) {
            this.numberOffice = data.cellPhoneOffice.split(" ")[1];
          }
          if (data.phone) {
            this.houseNumber = data.phone.split(" ")[1];
          }
          this.neighborhoodName = data.neighborhood;
          this.municipalitieName = data.municipality;
          this.entitieName = data.entity;
          this.postalCodeSelected = true;

          if (data.postalCode) {
            this.getDirectionByPostalCode(data.postalCode);
          }
          if (data.electoralSection) {
            this.selectSecction(data.electoralSection);
          }
          if (data.birthPlace) {
            this.onFirstSelectChange();
          }

        } else {
          if (data.postalCode) {
            this.getDirectionByPostalCode(data.postalCode);
          }
          if (data.electoralSection) {
            this.selectSecction(data.electoralSection);
          }
          if (data.birthPlace) {
            this.onFirstSelectChange();
          }
        }



      })
    }
  }

  getUserAuth() {
    this.activeRoute.params.subscribe(routeParams => {
      localStorage.setItem('token', routeParams['token']);
      localStorage.setItem('environment', routeParams['environment']);
      localStorage.setItem('idWorkGroup', routeParams['idWorkGroup']);

      this.sympathizerForm.controls['idUser'].setValue(routeParams['idUser']);
      this.sympathizerForm.controls['idWorkGroup'].setValue(routeParams['idWorkGroup']);
      this.sympathizerForm.controls['qrcode'].setValue(this.generateRandomString());
    });
  }

  getPOIs() {
    return new Promise((resolve, reject) => {
      this.POIService.getPOIOptions().subscribe(
        (data: any) => {
          this.POIoptions = data;
          resolve(data); // Resolvemos la promesa con los datos
        },
        (error) => {
          reject(error); // Rechazamos la promesa en caso de error
        }
      );
    });
  }

  getSecctions(idMunicipality: number, idEntity: number) {

    this.mapService.getSections(idMunicipality, idEntity).subscribe((data: any) => {
      console.log(data);
      this.sectionOptions = data;
    })
  }

  fillAfterCreatePOI() {
    if (this.formDataService.lastForm) {

      console.log(this.formDataService.lastForm.value);
      console.log(this.formDataService.selectedPOI);


      const data = this.formDataService.lastForm.value;

      this.sympathizerForm.patchValue({
        name: data.name,
        next_name: data.next_name,
        last_name: data.last_name,
        gender: data.gender ?? '',
        birthDate: data.birthDate,
        curp: data.curp,
        email: data.email,
        phone: data.phone,
        cellPhone: data.cellPhone,
        civilStatus: data.civilStatus ?? '',
        birthPlace: data.birthPlace ?? '',
        birthPlaceM: data.birthPlaceM ?? '',
        scholarship: data.scholarship ?? '',
        ocupation: data.ocupation ?? '',
        workplace: data.workplace,
        cellPhoneOffice: data.cellPhoneOffice,
        facebook: data.facebook,
        instagram: data.instagram,
        street: data.street,
        streetNumExt: data.streetNumExt,
        streetNumInt: data.streetNumInt,
        postalCode: data.postalCode,
        idNeighborhood: data.idNeighborhood ?? '',
        idMunicipality: data.idMunicipality,
        idEntity: data.idEntity,
        electoralSection: data.electoralSection ?? '',
        reference: data.reference,
        idWorkGroup: data.idWorkGroup,
        location: data.location,
        POIrelated: 1,
        idPOI: this.formDataService.selectedPOI,
        participationType: data.participationType ?? '',
        otherType: data.otherType,
        bitacora: data.bitacora,
        participation: data.participation,
        actor: data.actor,
        actorType: data.actorType,
        otherActor: data.otherActor,
        idlifting: data.idlifting,
        privacyNotice: data.privacyNotice,
        affiliated: data.affiliated,
        affiliated_political_party: data.affiliated_political_party,
        affiliation_date: data.affiliation_date,
        other_political_party: data.other_political_party,
        period: data.period,
      });

      if (data.electoralSection) {
        this.selectSecction(data.electoralSection);
      }

    }
  }

  attachInputHandlers() {
    const nameFields = ['name', 'next_name', 'last_name'];
    const uppercaseFields = ['curp', 'workplace', 'typeSupport', 'street', 'reference', 'bitacora', 'otherType', 'otherActor', 'other_political_party'];

    if (this.international == false) {
      const numberFields = ['phone', 'cellPhone', 'cellPhoneOffice', 'postalCode'];
      numberFields.forEach(fieldName => {
        const fieldControl = this.sympathizerForm.get(fieldName);
        if (fieldControl) {
          fieldControl.valueChanges.subscribe((newValue: string) => {
            if (newValue) {
              this.sympathizerForm.get(fieldName)?.setValue(newValue.replace(/\D/g, ''), { emitEvent: false });
            }
          });
        }
      });
    }

    uppercaseFields.forEach(fieldName => {
      const fieldControl = this.sympathizerForm.get(fieldName);
      if (fieldControl) {
        fieldControl.valueChanges.subscribe((newValue: string) => {
          if (newValue) {
            this.sympathizerForm.get(fieldName)?.setValue(newValue.toUpperCase(), { emitEvent: false });
          }
        });
      }
    });

    nameFields.forEach(fieldName => {
      const fieldControl = this.sympathizerForm.get(fieldName);
      if (fieldControl) {
        fieldControl.valueChanges.subscribe((newValue: string) => {
          if (newValue) {
            this.sympathizerForm.get(fieldName)?.setValue(newValue.toUpperCase().replace(/[^A-Z\sÑ]/g, ''), { emitEvent: false });
          }
        });
      }
    });

  }

  generateRandomString(): string {
    const length = 5;
    const characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const charactersLength = characters.length;
    let randomString = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charactersLength);
      randomString += characters.charAt(randomIndex);
    }
    return randomString;
  }

  curpValidator(control: FormControl): { [key: string]: any } | null {
    const curp = control.value;
    if (curp?.length != 0 && curp != null) {
      const isValid = this.customizeService.validCurp(curp);

      if (isValid) {
        return null; // La CURP es válida
      } else {
        return { 'invalidCurp': true }; // La CURP es inválida
      }
    } else {
      return null;
    }
  }

  searchPostalCode() {
    this.requestService.searchPostalCode(this.sympathizerForm.controls['postalCode'].value).subscribe((data: any) => {
      this.postalCodeSelected = false;
      this.postalCodeSearch = data;
    });
  }

  searchInternationalPostalCode() {
    this.requestService.searchInternationalPostalCode(this.sympathizerForm.controls['postalCode'].value, this.selectedCountry.altSpellings[0]).subscribe((data: any) => {
      this.postalCodeSelected = false;
      this.postalCodeSearch = data.postalCodes;
    });
  }

  selectInternationalPostal(data: any) {
    this.postalCodeSelected = true;
    this.sympathizerForm.controls['postalCode'].setValue(data.postalCode);

    this.entitieName = data.adminName1;
    this.municipalitieName = data.adminName2;
    this.neighborhoodName = data.placeName;

    this.sympathizerForm.controls['entity'].setValue(data.adminName1);
    this.sympathizerForm.controls['municipality'].setValue(data.adminName2);
    this.sympathizerForm.controls['neighborhood'].setValue(data.placeName);

  }

  selectPostalCode(postalCode: String) {
    this.getDirectionByPostalCode(postalCode);
  }

  getDirectionByPostalCode(postalCode: String) {
    const idPC = this.postalCodeSearch.find((option: any) => option.identifier == postalCode);
    this.sympathizerForm.controls['postalCode'].setValue(postalCode);
    this.sympathizerForm.controls['idMunicipality'].reset();
    this.sympathizerForm.controls['idEntity'].reset();

    this.requestService.getDirectionByPostalCode(postalCode).subscribe(
      (data: any) => {
        this.postalCodeSelected = true;

        this.municipalitieName = data.municipality.name;
        this.sympathizerForm.controls['idMunicipality'].setValue(data.municipality.id);

        this.entitieName = data.entity.name;
        this.sympathizerForm.controls['idEntity'].setValue(data.entity.id);
        this.getSecctions(data.municipality.identifier, data.entity.identifier);

        // Clear previous neighborhood options
        this.neighborhoodOptions = [];

        if (data.neighborhoods.length > 1) {
          this.sympathizerForm.controls['idNeighborhood'].enable();
          this.neighborhoodOptions = data.neighborhoods;
        } else {
          this.sympathizerForm.controls['idNeighborhood'].enable();
          this.neighborhoodOptions = data.neighborhoods;
          this.sympathizerForm.controls['idNeighborhood'].setValue(data.neighborhoods[0].id);
        }
      },
      (error) => {
        console.error(error); // Maneja el error de manera adecuada
      }
    );
    this.sympathizerForm.controls['idPostalCode'].setValue(idPC.id);
  }

  getEntities(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.requestService.getEntities().subscribe(
        (data: any) => {
          this.entities = data;
          resolve(data); // Resolvemos la promesa con los datos
        },
        (error) => {
          reject(error); // Rechazamos la promesa en caso de error
        }
      );
    });
  }

  getMunicipalities(idEntity: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.requestService.getMunicipalities(idEntity).subscribe(
        (data: any) => {
          this.municipalities = data;
          resolve(data); // Resolvemos la promesa con los datos
        },
        (error) => {
          reject(error); // Rechazamos la promesa en caso de error
        }
      );
    });
  }

  async onFirstSelectChange() {
    this.sympathizerForm.controls['birthPlaceM'].enable();
    try {
      const idEntity = this.sympathizerForm.get('birthPlace')?.value;
      await this.getMunicipalities(idEntity);
      // Aquí puedes continuar con cualquier lógica que dependa de las municipalities
    } catch (error) {
      console.error('Error al obtener las municipalities:', error);
    }
  }

  // Función para verificar si un campo está vacío o nulo
  isNullOrEmpty(control: AbstractControl | null): boolean {
    return control === null || control.value === '' || control.value === null;
  }

  updateMissingDataColor(form: FormGroup) {
    const curp = form.get('curp');
    const requiredFields = [
      'name', 'next_name', 'last_name', 'birthDate', 'cellPhone', 'birthPlace',
      'birthPlaceM', 'idPostalCode', 'gender', 'email', 'civilStatus',
      'scholarship', 'ocupation', 'street', 'streetNumExt',
      'idNeighborhood', 'electoralSection'
    ];
    // Comprobar si todos los campos requeridos están llenos
    const allFieldsFilled = requiredFields.every(fieldName => !this.isNullOrEmpty(form.get(fieldName)));

    let federalSupportCondition = true;
    if (form.get('federalSupport')?.value == 1 && !this.isNullOrEmpty(form.get('typeSupport'))) {
      federalSupportCondition = true;
    } else if (form.get('federalSupport')?.value == 0 && this.isNullOrEmpty(form.get('typeSupport'))) {
      federalSupportCondition = true;
    } else {
      federalSupportCondition = false;
    }
    if (allFieldsFilled && federalSupportCondition) {
      this.sympathizerForm.controls['missing_data_color'].setValue('green');
    } else if (!this.isNullOrEmpty(curp)) {
      this.sympathizerForm.controls['missing_data_color'].setValue('yellow');
    } else {
      this.sympathizerForm.controls['missing_data_color'].setValue('red');
    }
  }

  loaderAlert() {
    this.uploadingFiles = true;
    Swal.fire({
      icon: 'warning',
      title: "Subiendo archivos",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton())
      },
      willClose: () => {

      }
    }).then((result) => {

    })
  }

  succesAlert() {

    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

    Toast.fire({
      icon: 'success',
      title: "Archivos subidos",
    })

  }

  selectSecction(Secction: String) {
    this.sectionSelected = true;
    this.sympathizerForm.controls['electoralSection'].setValue(Secction);
  }

  clearSection() {
    this.sectionSelected = false;
    this.sympathizerForm.controls['electoralSection'].reset();
  }

  errorAlert() {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

    Toast.fire({
      icon: 'error',
      title: "Erro al subir archivos",
    })
  }

  buttonSave() {
    const isFormValid = this.sympathizerForm.valid;
    if (isFormValid === true) {
      this.loading = true;
      this.updateMissingDataColor(this.sympathizerForm);

      if (this.sympathizerForm.get('POIrelated')?.value == 0) {
        this.sympathizerForm.controls['idPOI'].setValue('');
      }

      if (this.sympathizerForm.get('actor')?.value == 0) {
        this.sympathizerForm.controls['actorType'].setValue('');
      }

      if (this.sympathizerForm.get('participation')?.value == 0) {
        this.sympathizerForm.controls['participationType'].setValue('');
      }

      if (this.sympathizerForm.get('affiliated')?.value == 0) {
        this.sympathizerForm.controls['affiliated_political_party'].setValue('');
        this.sympathizerForm.controls['affiliation_date'].setValue('');
        this.sympathizerForm.controls['other_political_party'].setValue('');
      }

      if (this.sympathizerForm.get('affiliated_political_party')?.value == 'OTRO') {
        this.sympathizerForm.controls['affiliated_political_party'].setValue(this.sympathizerForm.get('other_political_party')?.value);
      }

      const promises = [];

      if (this.selectedFile) {
        const file = this.selectedFile.filter((file: any) => {
          const fileType = file.file.type;
          return fileType === 'application/pdf' || fileType.startsWith('image/');
        });

        console.log(file);

        if (file.length > 0) {
          promises.push(this.stageToUpload(file, this.sympathizerForm.controls['qrcode'].value));
          this.loaderAlert();
        }
      }

      Promise.all(promises).then(async () => {

        if (this.uploadingFiles == true) {
          Swal.close();
          this.succesAlert();
        }

        this.Allurlparticipation.forEach((url: any) => {
          this.newUrlparticipation.push(url);
        });
        console.log(this.newUrlparticipation);
        var dataRequest = {
          ...this.sympathizerForm.value,
          url_image_participatio: this.newUrlparticipation
        };

        if (this.idSympathizer) {
          this.sympathizerService.updateSympathizer(this.idSympathizer, dataRequest).subscribe(data => {
            this.updateHistory(this.idSympathizer);
            alert('El simpatizante ha sido actualizado con éxito');
            this.loading = false;
            const parentURL = this.customizeService.getUrl();
            window.parent.location.href = parentURL + '/DirectorySympathizer/directoryList.html';
          });
        } else {
          this.sympathizerService.saveSympathizer(dataRequest).subscribe(data => {
            this.saveHistory(data.idSympathizer);
            this.sendWhatsappNotification(data.idSympathizer);
            alert('El simpatizante se ha guardado con éxito, folio: ' + this.sympathizerForm.get('qrcode')?.value);
            this.loading = false;
            const parentURL = this.customizeService.getUrl();
            window.parent.location.href = parentURL + '/DirectorySympathizer/directoryList.html';
          });
        }

      }).catch((error) => {
        // Manejar errores si es necesario
        console.error('Ocurrió un error en una de las operaciones:', error);
        if (this.uploadingFiles == true) {
          Swal.close();
          this.errorAlert();
        }
      });
    } else {
      Swal.fire(
        {
          icon: 'error',
          title: '¡No has llenado todos los campos requeridos! Asegúrate de registrar los campos obligatorios y/o diez dígitos en el teléfono',
          text: '',
          confirmButtonText: 'Aceptar'
        }
      );
    }
  }

  getFileName(url: string): string {
    const parts = url.split('/');
    return decodeURIComponent(parts[parts.length - 1]);
  }

  cleanForm() {
    const excludedVal = {
      qrcode: this.sympathizerForm.get('qrcode')?.value,
      idUser: this.sympathizerForm.get('idUser')?.value,
      idWorkGroup: this.sympathizerForm.get('idWorkGroup')?.value,
      missing_data_color: this.sympathizerForm.get('missing_data_color')?.value,
    };
    this.sympathizerForm.reset(excludedVal);
    if (this.international) {
      this.lada = '';
      this.entitieName = '';
      this.municipalitieName = '';
      this.neighborhoodName = '';

    }
  }

  goBack() {
    this.location.back();
  }

  sendWhatsappNotification(idSympathizer: number) {
    this.sympathizerService.sendWhatsappNotification(idSympathizer, this.idWorkGroup).subscribe(
      (data) => {
        console.log('Envío de notificación exitoso', data);
      },
      (error) => {
        console.error('Error al enviar la notificación de WhatsApp:', error);
      }
    );
  }

  saveHistory(idSympathizer: number) {
    const historyData = {
      idSympathizer: idSympathizer,
      idUser: this.sympathizerForm.get('idUser')?.value,
      message: 'Ha dado de alta al simpatizante desde Web.',
      date: null
    };
    this.sympathizerService.saveHistory(historyData).subscribe(data => {
      console.log(data);
    });
  }

  updateHistory(idSympathizer: number) {
    const historyData = {
      idSympathizer: idSympathizer,
      idUser: this.sympathizerForm.get('idUser')?.value,
      message: 'Ha modificado al simpatizante desde Web.',
      date: null
    };
    this.sympathizerService.saveHistory(historyData).subscribe(data => {
      console.log(data);
    });
  }

  updateCheckbox(event: Event) {
    if (event && event.target instanceof HTMLInputElement) {
      this.sympathizerForm.get('privacyNotice')?.setValue(event.target.checked ? 1 : null);
    }
  }
}
