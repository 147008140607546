import { Component, OnInit } from '@angular/core';
import { ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { POIService } from 'src/app/shared/services/poi.service';
import { CustomeValidationService } from 'src/app/shared/services/custome-validation.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-poi',
  templateUrl: './poi.component.html',
  styleUrls: ['./poi.component.scss']
})
export class POIComponent implements OnInit {
  @ViewChild('stepper') stepper!: MatStepper;

  selectedTab: number = 0;
  collection: any = [];
  p: number = 1;
  POIData: any = [];
  itemsPerPage: number = 10;
  relatedPOIS: any;
  idUser: any;
  token: any;
  idWorkGroup: any;
  environment: any;

  constructor(private fb: FormBuilder,private location: Location, private customizeService: CustomeValidationService, private POIService: POIService, private activeRoute: ActivatedRoute, private router: Router) {
    this.getUserAuth();
    this.getPOI();
    this.getRelatedToPOI();
  }

  getUserAuth() {
    this.activeRoute.params.subscribe(routeParams => {
      if (routeParams['token'] && routeParams['idWorkGroup'] && routeParams['idUser']) {
        localStorage.setItem('token', routeParams['token']);
        localStorage.setItem('idWorkGroup', routeParams['idWorkGroup']);
        localStorage.setItem('environment ', routeParams['environment']);

      }
    });
  }

  goBack() {
    this.location.back();
  }


  getPOI() {
    this.POIService.getPOI(this.activeRoute.snapshot.paramMap.get('id')).subscribe((response: any) => {
      this.POIData = response;
      console.log(response);
    })
  }

  goToUpdate(id: number, type: string) {
    const parentURL = this.customizeService.getUrl();
    switch (type) {
      case 'sympathizer':
        window.parent.location.href = parentURL + '/DirectorySympathizer/editSympathizer/' + id + '/idx.html';
        break;
      case 'applicant':
        window.parent.location.href = parentURL + '/RequestsGeneral/editRequest/' + id + '/idx.html';
        break;
    }
  }

  updatePOI(){
    this.router.navigate(['/poi/update/' + this.activeRoute.snapshot.paramMap.get('id')]);
  }

  getRelatedToPOI() {
    this.POIService.getRelatedToPOI(this.activeRoute.snapshot.paramMap.get('id'), this.p, this.itemsPerPage).subscribe((response: any) => {
      this.relatedPOIS = response;
      console.log(response);
    })
  }

  goToDetail(idRequest: number) {
    this.idUser = localStorage.getItem('idUser');
    this.token = localStorage.getItem('token');
    this.idWorkGroup = localStorage.getItem('idWorkGroup');
    this.environment = localStorage.getItem('environment');
    this.router.navigate(['/detailRequest/' + idRequest + '/' + this.idUser + '/' + this.idWorkGroup + '/' + this.token + '/' + this.environment])
  }

  ngOnInit(): void {
  }

  onStepChange(event: any) {
    this.selectedTab = event.selectedIndex;
  }

  changeSelected(index: number) {
    this.stepper.selectedIndex = index;
  }

}
