import { NgModule,CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MapComponent } from './pages/map/map/map.component';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { PinStatusPipe } from './shared/pipes/pin-status.pipe';
import { MapModule } from './pages/map/map/map.module';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MatChipsModule } from '@angular/material/chips';
import { PanelControlComponent } from './pages/panel-control/panel-control.component';
import { NgToggleModule } from 'ngx-toggle-button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DashboardComponent } from './pages/components/estadistica/dashboard/dashboard.component';
import {MatGridListModule} from '@angular/material/grid-list';
import { RequestsAndAccomplishedByThemesComponent } from './pages/components/estadistica/requests-and-accomplished-by-themes/requests-and-accomplished-by-themes.component';
import { RequestsAndAccomplishedBySubthemesComponent } from './pages/components/estadistica/requests-and-accomplished-by-subthemes/requests-and-accomplished-by-subthemes.component';
import { RequestsAndAccomplishedByCategoriesComponent } from './pages/components/estadistica/requests-and-accomplished-by-categories/requests-and-accomplished-by-categories.component';
import { AnnualHistoryComponent } from './pages/components/estadistica/annual-history/annual-history.component';
import { ClosedRequestsAndPendingRequestsByThemeAndYearComponent } from './pages/components/estadistica/annual-history/components/closed-requests-and-pending-requests-by-theme-and-year/closed-requests-and-pending-requests-by-theme-and-year.component';
import { ClosedRequestsAndPendingRequestsByMonthComponent } from './pages/components/estadistica/annual-history/components/closed-requests-and-pending-requests-by-month/closed-requests-and-pending-requests-by-month.component';
import { ClosedRequestsAndPendingRequestsByThemeAndMonthComponent } from './pages/components/estadistica/annual-history/components/closed-requests-and-pending-requests-by-theme-and-month/closed-requests-and-pending-requests-by-theme-and-month.component';
import { ClosedRequestsAndPendingRequestsByYearComponent } from './pages/components/estadistica/annual-history/components/closed-requests-and-pending-requests-by-year/closed-requests-and-pending-requests-by-year.component';
import { BeneficiaryStatisticsComponent } from './pages/components/estadistica/annual-history/components/beneficiary-statistics/beneficiary-statistics.component';
import { ApplicantsByGenderComponent } from './pages/components/estadistica/annual-history/components/applicants-by-gender/applicants-by-gender.component';
import { ApplicantsByGroupVulnerableComponent } from './pages/components/estadistica/annual-history/components/applicants-by-group-vulnerable/applicants-by-group-vulnerable.component';
import { ApplicantsByGroupOldComponent } from './pages/components/estadistica/annual-history/components/applicants-by-group-old/applicants-by-group-old.component';
import { PeopleRegisteredSupportersComponent } from './pages/components/estadistica/annual-history/components/people-registered-supporters/people-registered-supporters.component';

import { GeneralStadisticComponent } from './pages/estadistica/general-stadistic/general-stadistic.component';
import { CommonModule } from '@angular/common';
import { EstadisticaModule } from './pages/estadistica/estadistica.module';
import { PlaceOfFilingApplicationsComponent } from './pages/components/estadistica/place-of-filing-applications/place-of-filing-applications.component';
import { TypeOfApplicantsByGenderAndTypeOfBeneficiaryByGenderComponent } from './pages/components/estadistica/type-of-applicants-by-gender-and-type-of-beneficiary-by-gender/type-of-applicants-by-gender-and-type-of-beneficiary-by-gender.component';
import { TypeOfApplicantsByAgeAndTypeOfBeneficiaryByAgeComponent } from './pages/components/estadistica/type-of-applicants-by-age-and-type-of-beneficiary-by-age/type-of-applicants-by-age-and-type-of-beneficiary-by-age.component';
import { ApplicantsAndBeneficiariesByVulnerableGroupComponent } from './pages/components/estadistica/applicants-and-beneficiaries-by-vulnerable-group/applicants-and-beneficiaries-by-vulnerable-group.component';
import { CompleteDataIdCitizenComponent } from './pages/components/estadistica/complete-data-id-citizen/complete-data-id-citizen.component';
import { NgChartsModule } from 'ng2-charts';
import { HistoricalMonthlyComponent } from './pages/components/estadistica/historical-monthly/historical-monthly.component';
import { BeneficiaryMonthComponent } from './pages/components/estadistica/historical-monthly/components/beneficiary-month/beneficiary-month.component';
import { DataCompleteComponent } from './pages/components/estadistica/data-complete/data-complete.component';
import { BeneficiaryMonthsComponent } from './pages/components/estadistica/annual-history/components/beneficiary-months/beneficiary-months.component';
import { ApplicantsGenderMonthComponent } from './pages/components/estadistica/annual-history/components/applicants-gender-month/applicants-gender-month.component';
import { ApplicantsGenderMonthsComponent } from './pages/components/estadistica/annual-history/components/applicants-gender-months/applicants-gender-months.component';
import { ApplicantsGroupVulnerableMonthsComponent } from './pages/components/estadistica/annual-history/components/applicants-group-vulnerable-months/applicants-group-vulnerable-months.component';
import { ApplicantsGroupVulnerableMonthComponent } from './pages/components/estadistica/annual-history/components/applicants-group-vulnerable-month/applicants-group-vulnerable-month.component';
import { ApplicantsGroupOldMonthsComponent } from './pages/components/estadistica/annual-history/components/applicants-group-old-months/applicants-group-old-months.component';
import { ApplicantsGroupOldMonthComponent } from './pages/components/estadistica/annual-history/components/applicants-group-old-month/applicants-group-old-month.component';
import { GeneralDataComponent } from './pages/components/form/general-data/general-data.component';
import { ProposalInitiativeComponent } from './pages/components/form/proposal-initiative/proposal-initiative.component';
import { CompleteProposalComponent } from './pages/components/form/complete-proposal/complete-proposal.component';
import { ProposalFormComponent } from './pages/components/form/proposal-form/proposal-form.component';
import {MatStepperModule} from '@angular/material/stepper';
import { ListComponent } from './pages/components/form/proposal/list/list.component';
import { POIPipe } from './shared/pipes/poi.pipe';
import { NgxPaginationModule } from 'ngx-pagination';
import { InterestPointModule } from './pages/interest-point/interest-point.module';
import { DataProposalComponent } from './pages/components/form/proposal/data-proposal/data-proposal.component';
import { PromoterInformationComponent } from './pages/components/form/proposal/dataProposal/components/promoter-information/promoter-information.component';
import { ProposalInformationComponent } from './pages/components/form/proposal/dataProposal/components/proposal-information/proposal-information.component';
import { HistorialInformationComponent } from './pages/components/form/proposal/dataProposal/components/historial-information/historial-information.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { CreateSympathizerComponent } from './pages/sympathizer/create-sympathizer/create-sympathizer.component';
import { ListSympathizerComponent } from './pages/sympathizer/list-sympathizer/list-sympathizer.component';
import { SympathizerDetailComponent } from './pages/sympathizer/sympathizer-detail/sympathizer-detail.component';
import { OrderModule } from './pages/components/form/order/order.module';
import { RequestsModule } from './pages/requests/requests.module';
import { PassDataComponent } from './pages/components/modal/pass-data/pass-data.component';
import {MatDialogModule} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FormMapModule } from './pages/components/map/form-map/form-map.module';
import { MaxchardecModule } from './pages/components/form/maxchardec/maxchardec.module';
import { SympathizerGeneralDataComponent } from './pages/sympathizer/sympathizerDetail/sympathizer-general-data/sympathizer-general-data.component';
import { SympathizerDocumentationComponent } from './pages/sympathizer/sympathizerDetail/sympathizer-documentation/sympathizer-documentation.component';
import { SympathizerHistoryComponent } from './pages/sympathizer/sympathizerDetail/sympathizer-history/sympathizer-history.component';
import { S3UploaderModule } from './pages/components/s3-uploader/s3-uploader.module';
import { RequestsPipe } from './shared/pipes/requests.pipe';
import { ProposalPipe } from './shared/pipes/proposal.pipe';
import { PromotedFormComponent } from './pages/promoted/promoted-form/promoted-form.component';
import { PromotedListComponent } from './pages/promoted/promoted-list/promoted-list.component';
import { PromotedDetailComponent } from './pages/promoted/promoted-detail/promoted-detail.component';
import { PromotedEditFormComponent } from './pages/promoted/promoted-edit-form/promoted-edit-form.component';
import { VisitModalComponent } from './pages/components/modal/visit-modal/visit-modal.component';
import { HistoryModalComponent } from './pages/components/modal/history-modal/history-modal.component';
import { PlayerComponent } from './pages/components/modal/player/player.component';

import {MatSelectModule} from '@angular/material/select';

@NgModule({
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],

  declarations: [
    AppComponent,
    PinStatusPipe,
    PanelControlComponent,
    DashboardComponent,
    RequestsAndAccomplishedByThemesComponent,
    RequestsAndAccomplishedBySubthemesComponent,
    RequestsAndAccomplishedByCategoriesComponent,
    ClosedRequestsAndPendingRequestsByYearComponent,
    ClosedRequestsAndPendingRequestsByMonthComponent,
    ClosedRequestsAndPendingRequestsByThemeAndMonthComponent,
    ClosedRequestsAndPendingRequestsByThemeAndYearComponent,
    AnnualHistoryComponent,
    BeneficiaryStatisticsComponent,
    ApplicantsByGenderComponent,
    ApplicantsByGroupVulnerableComponent,
    ApplicantsByGroupOldComponent,
    PeopleRegisteredSupportersComponent,
    PlaceOfFilingApplicationsComponent,
    TypeOfApplicantsByGenderAndTypeOfBeneficiaryByGenderComponent,
    TypeOfApplicantsByAgeAndTypeOfBeneficiaryByAgeComponent,
    ApplicantsAndBeneficiariesByVulnerableGroupComponent,
    CompleteDataIdCitizenComponent,
    HistoricalMonthlyComponent,
    BeneficiaryMonthComponent,
    DataCompleteComponent,
    BeneficiaryMonthsComponent,
    ApplicantsGenderMonthComponent,
    ApplicantsGenderMonthsComponent,
    ApplicantsGroupVulnerableMonthsComponent,
    ApplicantsGroupVulnerableMonthComponent,
    ApplicantsGroupOldMonthsComponent,
    ApplicantsGroupOldMonthComponent,
    GeneralDataComponent,
    ProposalInitiativeComponent,
    CompleteProposalComponent,
    ProposalFormComponent,
    ListComponent,
    DataProposalComponent,
    PromoterInformationComponent,
    ProposalInformationComponent,
    HistorialInformationComponent,
    CreateSympathizerComponent,
    ListSympathizerComponent,
    SympathizerDetailComponent,
    PassDataComponent,
    SympathizerGeneralDataComponent,
    SympathizerDocumentationComponent,
    SympathizerHistoryComponent,
    ProposalPipe,
    PromotedFormComponent,
    PromotedListComponent,
    PromotedDetailComponent,
    PromotedEditFormComponent,
    VisitModalComponent,
    HistoryModalComponent,
    PlayerComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LeafletModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MapModule,
    BrowserAnimationsModule,
    NgxSkeletonLoaderModule,
    MatChipsModule,
    NgToggleModule,
    NgChartsModule,
    MatGridListModule,
    CommonModule,
    EstadisticaModule,
    MatStepperModule,
    NgxPaginationModule,
    InterestPointModule,
    FormsModule,
    ReactiveFormsModule,
    NgbDropdownModule,
    OrderModule,
    RequestsModule,
    MatDialogModule,
    MatButtonModule,
    FormMapModule,
    MaxchardecModule,
    S3UploaderModule,
    MatSelectModule

  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
