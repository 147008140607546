import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneralStadisticComponent } from './general-stadistic/general-stadistic.component';



@NgModule({
  declarations: [
    GeneralStadisticComponent,

  ],
  imports: [
    CommonModule
  ]
})
export class EstadisticaModule { }
