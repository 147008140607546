<div class="contenedor">
    <div class="back btn btn-sm ml-3 mt-3">
        <i class="fa fa-arrow-left fa-lg" style="cursor: pointer;" (click)="goBack()" aria-hidden="true"></i>
    </div>
    <h1 class="page-header">Editar solicitud &nbsp;<small></small></h1>
    <hr>
    <div class="row m-3">
        <div class="col data">
            <u style="text-decoration: none">
                <li>Estatus: <b>{{ getStatusLabel(requestData.idstatus) }}</b></li>
                <li>Asignado a: <b>{{ nameAssigned }}</b></li>
                <li>Secretaría: <b>{{ nameSecretary }}</b></li>
                <li>Gestoría coordinador: <b>Sin gestor coordinador</b></li>
                <li>Fecha de inicio: <b>{{requestData.created_at}}</b></li>
            </u>
        </div>
    </div>
    <div class="sectionBody">
        <div class="row tHeader" style="margin: 0 !important; ">
            <div class="col-3 tab" (click)="changeSelected(0)" [ngClass]="{'active': selectedTab == 0}" >
                <div class="sectionForm">
                    <span class="numberNotSelected" *ngIf="selectedTab != 0 else one">
                        <div class="fa-solid fa-check"></div>
                    </span>
                    <ng-template #one>
                        <span class="number">
                            1
                        </span>
                    </ng-template>
                    <div>
                        <span class="info">Datos generales </span>
                        <i class="fa fa-arrow-right"></i>
                        <small>Nombre, Dirección, Teléfono...</small>
                    </div>
                </div>
            </div>
            <div class="col-3 tab" (click)="changeSelected(1)" [ngClass]="{'active': selectedTab == 1}" >
                <div class="sectionForm">
                    <span class="numberNotSelected" *ngIf="selectedTab != 1 else second">
                        <div class="fa-solid fa-check"></div>
                    </span>
                    <ng-template #second>
                        <span class="number">
                            2
                        </span>
                    </ng-template>
                    <span class="info">Clasificación </span>
                    <i class="fa fa-arrow-right"></i>
                    <small>Tema, Subtema, Categoría</small>
                </div>
            </div>
            <div class="col-3 tab" (click)="changeSelected(2)" [ngClass]="{'active': selectedTab == 2}" >
                <div class="sectionForm">
                    <span class="numberNotSelected" *ngIf="selectedTab != 2 else third">
                        <div class="fa-solid fa-check"></div>
                    </span>
                    <ng-template #third>
                        <span class="number">
                            3
                        </span>
                    </ng-template>
                    <div>
                        <span class="info">Documentación </span>
                        <i class="fa fa-arrow-right"></i>
                        <small>Documentación</small>
                    </div>
                </div>
            </div>
            <div class="col-3 tab" (click)="changeSelected(3)" [ngClass]="{'active': selectedTab == 3}" >
                <div class="sectionForm">
                    <span class="numberNotSelected" *ngIf="selectedTab != 3 else fourth">
                        <div class="fa-solid fa-check"></div>
                    </span>
                    <ng-template #fourth>
                        <span class="number">
                            4
                        </span>
                    </ng-template>
                    <div>
                        <span class="info">Completado </span>
                        <i class="fa fa-arrow-right"></i>
                        <small>Registro completo</small>
                    </div>
                </div>
            </div>
        </div>
        <mat-stepper (selectionChange)="onStepChange($event)" #stepper [orientation]="'horizontal'">
            <mat-step>
                <app-request-edit-general-data></app-request-edit-general-data>
                <div class="footer-form">
                    <button class="btn btn-primary" (click)="changeSelected(1)">Siguiente</button>
                </div>
            </mat-step>
            <mat-step>
                <app-request-edit-clasification></app-request-edit-clasification>
                <div class="footer-form">
                    <button class="btn btn-primary" (click)="changeSelected(2)">Siguiente</button>
                    <button class="btn btn-light mr-2" (click)="changeSelected(0)">Anterior</button>
                </div>
            </mat-step>
            <mat-step>
                <app-request-edit-documentation></app-request-edit-documentation>
                <div class="footer-form">
                    <button class="btn btn-primary" (click)="changeSelected(3)">Siguiente</button>
                    <button class="btn btn-light mr-2" (click)="changeSelected(1)">Anterior</button>
                </div>
            </mat-step>
            <mat-step>
                <app-request-edit-complete></app-request-edit-complete>
                <div class="footer-form">
                    <button class="btn btn-light" (click)="changeSelected(2)">Anterior</button>
                </div>
            </mat-step>
        </mat-stepper>
    </div>
</div>
