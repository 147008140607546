<div class="panel-body">
    <div class="row stats">
      <div class="col-md-3">
        <div class="widget widget-stats m-b-10">
            <div class="stats-content">
                <div class="stats-number">{{this.closedRequest}}<div class="fa-solid fa-clipboard"></div></div>
                <div class="stats-title"><b>Solicitudes cerradas</b></div>
            </div>
        </div>
    </div>

        <div class="col-md-3">
            <div class="widget widget-stats m-b-10">
                <div class="stats-content">
                    <div class="stats-number">{{this.pendingRequest}}<div class="fa-solid fa-spinner"></div></div>
                    <div class="stats-title"><b>Solicitudes pendientes</b></div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="widget widget-stats m-b-10">
                <div class="stats-content">
                    <div class="stats-number">{{this.beneficiarys}} <div class="usersColor2"><div class="fa-solid fa-users"></div></div></div>
                    <div class="stats-title"><b>Personas beneficiarios</b>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="widget widget-stats m-b-10">
                <div class="stats-content">
                    <div class="stats-number">125 <div class="usersColor"><div class="fa-sharp fa-solid fa-users"></div></div></div>
                    <div class="stats-title"><b>Personas simpatizantes</b></div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row graphicTwo">
            <div class="col-md-6">
                <h2>Total de solicitudes</h2>
                <div class="chart-container">
                    <canvas id="ChartTotalRequest" >{{ chartTotalRequest }}</canvas>
                </div>
            </div>
            <div class="col-md-6">
                <h2>Solicitudes por estatus</h2>
                <div class="chart-container graphicTwo">
                    <canvas id="ChartStatusRequest">{{ chartStatusRequest }}</canvas>
                </div>
            </div>
        </div>
    </div>
</div>
