<div class="contenedor">
  <h2 class="page-header">
    <p>
      <i class="fa fa-arrow-left" style="cursor: pointer; color: black;" (click)="goBack()" aria-hidden="true"></i>

      Datos del punto de interés
    </p>
    <hr class="mb-3">
    <h5 class="mb-2">{{POIData.name}} </h5>
  </h2>

  <div class="row m-3">
    <div class="col data">
      <u style="text-decoration: none">
        <li>Responsable: <b>{{POIData.assigned_name}}</b></li>
        <!-- <li>Fecha de levantamiento: <b>16/08/2023</b></li> -->
      </u>
    </div>
    <div class="col data">
      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <button class="btn btn-secondary mr-2" (click)="updatePOI()"><i class="fa fa-edit"></i> Editar</button>
      </div>
    </div>
  </div>


  <div class="row tHeader" style="margin: 0 !important; ">
    <div class="col-6 tab" (click)="changeSelected(0)" [ngClass]="{'active': selectedTab == 0}">
      <div class="sectionForm">
        <div>

          <span class="numberNotSelected" *ngIf="selectedTab != 0 else one">
            <div class="fa-solid fa-check"></div>
          </span>
          <ng-template #one>
            <span class="number">
              1
            </span>
          </ng-template>

          <span class="info">Datos generales</span>
          <i class="fa fa-arrow-right"></i>
          <small>Nombre, Dirección, Teléfono...</small>
        </div>
      </div>
    </div>

    <div class="col-6 tab" (click)="changeSelected(1)" [ngClass]="{'active': selectedTab == 1}">
      <div class="sectionForm">
        <div>

          <span class="numberNotSelected" *ngIf="selectedTab != 1 else two">
            <div class="fa-solid fa-check"></div>
          </span>

          <ng-template #two>
            <span class="number">
              2
            </span>
          </ng-template>


          <span class="info">Relacionados </span>
          <i class="fa fa-arrow-right"></i>
          <small>Aplicante simpatizante</small>
        </div>
      </div>
    </div>
  </div>



  <mat-stepper (selectionChange)="onStepChange($event)" #stepper>
    <mat-step>
      <ng-template matStepLabel><i class="fa fa-arrow-right"></i></ng-template>

      <div class="panel panel-white">

        <div class="contenedor">
          <div class="row">
            <div class="col-md-4 col-sm-12">
              <label>Folio:</label>
              <div class="col-md-12">
                <div class="form-control">{{POIData.qrcode|| 'Sin infomación'}}</div>
              </div>
            </div>
            <div class="col-md-4 col-sm-12">
              <label>Periodo:</label>
              <div class="col-md-12">
                <div class="form-control">{{POIData.period|| 'Sin infomación'}}</div>
              </div>
            </div>
          </div>
          <div class="row">

            <div class="col-md-4 col-sm-12">
              <label>Nombre:</label>
              <div class="col-md-12">
                <div class="form-control">{{POIData.name|| 'Sin infomación'}}</div>
              </div>
            </div>
            <div class="col-md-4 col-sm-12">
              <label>Correo electrónico del punto de interés:</label>
              <div class="col-md-12">
                <div class="form-control">{{POIData.email|| 'Sin infomación'}}</div>
              </div>
            </div>
            <div class="col-md-4 col-sm-12">
              <label>Teléfono de contacto:</label>
              <div class="col-md-12">
                <div class="form-control">{{POIData.tel|| 'Sin infomación'}}</div>
              </div>
            </div>

          </div>
          <!-- <div>
                        <div class="panel panel-info">
                            <p class="divData">Datos de responsable</p>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-sm-12">
                                <label>Nombre(s):</label>
                                <div class="col-md-12">
                                                                    <div class="form-control">{{POIData.qrcode|| 'Sin infomación'}}</div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-12">
                                <label>Apellido paterno:</label>
                                <div class="col-md-12">
                                                                    <div class="form-control">{{POIData.qrcode|| 'Sin infomación'}}</div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-12">
                                <label>Apellido materno:</label>
                                <div class="col-md-12">
                                                                    <div class="form-control">{{POIData.qrcode|| 'Sin infomación'}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-sm-12">
                                <label>Fecha de nacimiento:</label>
                                <div class="col-md-12">
                                                                    <div class="form-control">{{POIData.qrcode|| 'Sin infomación'}}</div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-12">
                                <label>Sexo:</label>
                                <div class="col-md-12">
                                                                    <div class="form-control">{{POIData.qrcode|| 'Sin infomación'}}</div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-12">
                                <label>CURP:</label>
                                <div class="col-md-12">
                                                                    <div class="form-control">{{POIData.qrcode|| 'Sin infomación'}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-sm-12">
                                <label>Correo electrónico:</label>
                                <div class="col-md-12">
                                                                    <div class="form-control">{{POIData.qrcode|| 'Sin infomación'}}</div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-12">
                                <label>Teléfono de casa:</label>
                                <div class="col-md-12">
                                                                    <div class="form-control">{{POIData.qrcode|| 'Sin infomación'}}</div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-12">
                                <label>Teléfono celular:</label>
                                <div class="col-md-12">
                                                                    <div class="form-control">{{POIData.qrcode|| 'Sin infomación'}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-sm-12">
                                <label>Estado civil:</label>
                                <div class="col-md-12">
                                                                    <div class="form-control">{{POIData.qrcode|| 'Sin infomación'}}</div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-12">
                                <label>Lugar de nacimiento (Estado):</label>
                                <div class="col-md-12">
                                                                    <div class="form-control">{{POIData.qrcode|| 'Sin infomación'}}</div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-12">
                                <label>Lugar de nacimiento (Municipio):</label>
                                <div class="col-md-12">
                                                                    <div class="form-control">{{POIData.qrcode|| 'Sin infomación'}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-sm-12">
                                <label>Escolaridad:</label>
                                <div class="col-md-12">
                                                                    <div class="form-control">{{POIData.qrcode|| 'Sin infomación'}}</div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-12">
                                <label>Ocupación:</label>
                                <div class="col-md-12">
                                                                    <div class="form-control">{{POIData.qrcode|| 'Sin infomación'}}</div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-12">
                                <label>Lugar de trabajo:</label>
                                <div class="col-md-12">
                                                                    <div class="form-control">{{POIData.qrcode|| 'Sin infomación'}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-sm-12">
                                <label>Teléfono del trabajo:</label>
                                <div class="col-md-12">
                                                                    <div class="form-control">{{POIData.qrcode|| 'Sin infomación'}}</div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-12">
                                <label>Facebook:</label>
                                <div class="col-md-12">
                                                                    <div class="form-control">{{POIData.qrcode|| 'Sin infomación'}}</div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-12">
                                <label>Instagram:</label>
                                <div class="col-md-12">
                                                                    <div class="form-control">{{POIData.qrcode|| 'Sin infomación'}}</div>
                                </div>
                            </div>
                        </div>
                    </div> -->

          <div class="panel panel-info">
            <p class="divData">Dirección</p>
          </div>
          <div class="row">
            <div class="col-md-4 col-sm-12">
              <label>Calle:</label>
              <div class="col-md-12">
                <div class="form-control">{{POIData.street|| 'Sin infomación'}}</div>
              </div>
            </div>
            <div class="col-md-4 col-sm-12">
              <label>Número exterior:</label>
              <div class="col-md-12">
                <div class="form-control">{{POIData.streetNumExt|| 'Sin infomación'}}</div>
              </div>
            </div>
            <div class="col-md-4 col-sm-12">
              <label>Número interior:</label>
              <div class="col-md-12">
                <div class="form-control">{{POIData.streetNumInt|| 'Sin infomación'}}</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 col-sm-12">
              <label>Código postal:</label>
              <div class="col-md-12">
                <div class="form-control">{{POIData.postalCode|| 'Sin infomación'}}</div>
              </div>
            </div>
            <div class="col-md-4 col-sm-12">
              <label>Colonia:</label>
              <div class="col-md-12">
                <div class="form-control">{{POIData.neighborhood|| 'Sin infomación'}}</div>
              </div>
            </div>
            <div class="col-md-4 col-sm-12">
              <label>Municipio:</label>
              <div class="col-md-12">
                <div class="form-control">{{POIData.municipalitie|| 'Sin infomación'}}</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 col-sm-12">
              <label>Estado:</label>
              <div class="col-md-12">
                <div class="form-control">{{POIData.entitie|| 'Sin infomación'}}</div>
              </div>
            </div>
            <div class="col-md-4 col-sm-12">
              <label>Sección electoral:</label>
              <div class="col-md-12">
                <div class="form-control">{{POIData.section || 'Sin infomación'}}</div>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <label class="p-t-2">Distrito local:</label>
              <div class="form-control">
                {{ POIData.local_district || 'Sin información'}}</div>
            </div>
            <div class="col-12 col-md-4">
              <label class="p-t-2">Distrito federal:</label>
              <div class="form-control">
                {{ POIData.federal_district || 'Sin información'}}</div>
            </div>
            <div class="col-md-4 col-sm-12">
              <label>Referencia:</label>
              <div class="col-md-12">
                <div class="form-control">{{POIData.reference || 'Sin infomación'}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-form">
        <button mat-button matStepperNext class="btn btn-primary">Siguiente</button>
      </div>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel><i class="fa fa-arrow-right"></i></ng-template>

      <div class="panel panel-white">
        <div class="contenedor">

          <div class="row">
            <div class="offset-8 col-4">
              <pagination-controls (pageChange)="p = $event" previousLabel="" nextLabel=""></pagination-controls>
            </div>
          </div>

          <div class="row">
            <table class="table table-hover table-striped table-bordered m-b-0">
              <thead>
                <tr>
                  <th class="actions-sortable none">Opciones</th>
                  <th class="actions-sortable none">Folio</th>
                  <th class="actions-sortable">Nombre</th>
                  <th class="actions-sortable">Punto de Interés</th>
                  <th class="actions-sortable">Correo electrónico</th>
                  <th class="actions-sortable">Teléfono de contacto </th>
                  <th class="actions-sortable">Calle</th>
                  <th class="actions-sortable">No. Ext</th>
                  <th class="actions-sortable">No. Int.</th>
                  <th class="actions-sortable">C. P. </th>
                  <th class="actions-sortable">Municipio</th>
                  <th class="actions-sortable">Estado</th>
                  <th class="actions-sortable">Sección electoral</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let POI of relatedPOIS">
                  <td>

                    <div class="row">
                      <div class="col">
                        <div ngbDropdown class="d-inline-block">
                          <button class="btn btn-primary dropdown-toggle" id="dropdownBasic1" ngbDropdownToggle>
                            <i class="fa fa-align-justify">
                            </i>
                          </button>
                          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                            <button ngbDropdownItem>

                              <span (click)="goToUpdate(POI.id,POI.type)"
                                class="btn btn-sm btn-block btn-white selectHover" style="text-align: left;"
                                title="Editar"><i class="fa fa-edit"></i>
                                Editar</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                  </td>
                  <td class="">
                    <a style="font-family: monospace; cursor: pointer; color: #0d6efd;" (click)="goToDetail(POI.id)"> <i
                        class="fa fa-external-link-alt" style="color: #0d6efd;"></i> {{POI.qrcode}}</a>
                  </td>
                  <td class="">
                    <a style="color: #707478;">{{POI.name|| '-'}}</a>
                  </td>
                  <td class="">
                    <a style="color: #707478;">{{POI.namePOI|| '-'}}<br></a>
                  </td>

                  <td class="">
                    <a style="color: #707478;">{{POI.email|| '-'}} <br></a>
                  </td>
                  <td class="">
                    <a style="color: #707478;">{{POI.cellPhone|| '-'}}<br></a>

                  </td>
                  <td class="">
                    <a style="color: #707478;">{{POI.streetNumExt|| '-'}}<br></a>

                  </td>
                  <td class="">
                    <a style="color: #707478;">{{POI.streetNumInt|| '-'}}<br></a>

                  </td>
                  <td class="">
                    <a style="color: #707478;">{{POI.identifier|| '-'}}<br></a>

                  </td>
                  <td class="">
                    <a style="color: #707478;">{{POI.neighborhood|| '-'}}<br></a>

                  </td>
                  <td class="">
                    <a style="color: #707478;">{{POI.municipalitie|| '-'}}<br></a>

                  </td>
                  <td class="">
                    <a style="color: #707478;">{{POI.entitie|| '-'}}<br></a>

                  </td>

                  <td class="">
                    <a style="color: #707478;">{{POI.section|| '-'}}<br></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="footer-form">
          <button mat-button matStepperPrevious class="btn btn-light mr-2">Anterior</button>
        </div>
      </div>


    </mat-step>
  </mat-stepper>
</div>
