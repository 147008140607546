<div *ngIf="loading; else loaded">
  <div class="loader"></div>
</div>
<ng-template #loaded>

<h2>Beneficiarios y metas cumplidas</h2>
</ng-template>
  <div>
      <canvas  id="ChartStatus">{{ chartStatus }}</canvas>
  </div>
