import * as L from 'leaflet';
import 'leaflet-routing-machine';
import { icon, Marker } from 'leaflet';
import { Component, Inject, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { MapService } from 'src/app/shared/services/map.service';
import * as shp from 'shpjs';
export const DEFAULT_LAT = 19.2527808;
export const DEFAULT_LON = -99.6070589;
export const TITULO = 'Proyecto';
const iconRetinaUrl = 'assets/marker-icon-2x.png';
const iconUrl = 'assets/marker-icon.png';
const shadowUrl = 'assets/marker-shadow.png';
import { Observable } from 'rxjs';
import { RequestService } from 'src/app/shared/services/request.service';
import { ThisReceiver } from '@angular/compiler';
import { HttpClient } from '@angular/common/http';
import * as geojson from 'geojson';
import { readFileSync } from 'fs';
import { tagsModel } from 'src/app/shared/models/tags.model';
import { UserService } from 'src/app/shared/services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import 'overlapping-marker-spiderfier-leaflet/dist/oms';
const OverlappingMarkerSpiderfier = (<any>window).OverlappingMarkerSpiderfier;
import Swal from 'sweetalert2';
import { resolve } from 'dns';
import { POIService } from 'src/app/shared/services/poi.service';
import { Location } from '@angular/common';
import { PromotedService } from 'src/app/shared/services/promoted.service';
import 'leaflet-easyprint';
import 'node_modules/leaflet.browser.print/dist/leaflet.browser.print.min.js';

declare module 'leaflet' {
  namespace control {
    function browserPrint(options?: any): Control.BrowserPrint;
  }
  namespace Control {
    interface BrowserPrint {
      addTo(map: L.Map): any;
    }
  }
}

@Component({
  selector: 'app-map-frame',
  templateUrl: './map-frame.component.html',
  styleUrls: ['./map-frame.component.scss']
})
export class MapFrameComponent implements OnInit {

  //private map: any;
  @Input() lat: number = DEFAULT_LAT;
  @Input() lon: number = DEFAULT_LON;
  @Input() titulo: string = TITULO;
  count: number | undefined;

  idStatus: number = 0;


  map: any;
  oms: any;

  json!: geojson.GeoJsonObject;
  markers: any[] = [];
  markerGroup!: L.LayerGroup<any>;
  municipios!: L.GeoJSON<any>;
  localDistricts!: L.GeoJSON<any>;
  neighborhoods!: L.GeoJSON<any>;
  federalDistricts!: L.GeoJSON<any>;
  sections!: L.GeoJSON<any>;
  estates!: L.GeoJSON<any>;
  republic!: L.GeoJSON<any>;
  Filtersections!: L.GeoJSON<any>;

  selectedSection: number = 0;

  idWorkgroup: string = this.userService.idWorkgroup;
  userId: number = this.userService.idUser;
  @Output() markerCount = new EventEmitter<{ count: number, type: string }>();
  //controls: Array<{ name: string, layer: L.GeoJSON }> = [];
  controls = new L.Control.Layers();
  options = {
    layers: [
      L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 18, attribution: '...'
      })
    ],
    zoom: 7,
    center: L.latLng(this.lat, this.lon)
  };
  parentUrl: any;


  userLevel!: number;
  userProfileInfo!: { idProfileEntity: number; idProfileMunicipality: number; userLevel: number; idProfile: number; idTypeProfile: number; idHolder: number };
  mapLoaded = false;
  isSympathizerFilter: boolean = false;
  isPromotedFilter: boolean = false;
  isPoiFilter: boolean = false;

  auxLayersLoaded: boolean = false;
  registeredMarkerGroup!: L.LayerGroup<any>;
  assignedMarkerGroup!: L.LayerGroup<any>;
  onProcessMarkerGroup!: L.LayerGroup<any>;
  accomplishedMarkerGroup!: L.LayerGroup<any>;
  canceledMarkerGroup!: L.LayerGroup<any>;
  notViableMarkerGroup!: L.LayerGroup<any>;
  acceptedMarkerGroup!: L.LayerGroup<any>;
  preRegisterMarkerGroup!: L.LayerGroup<any>;
  SympathizerMarkerGroup!: L.LayerGroup<any>;
  SympathizerMarkers: any[] = [];
  POIMarkerGroup!: L.LayerGroup<any>;
  promotedMarkerGroup!: L.LayerGroup<any>;

  POIMarkers: any[] = [];
  promotedMarkers: any[] = [];

  visitsMarkerGroup!: L.LayerGroup<any>;
  visitsMarkers: any[] = [];

  filteredMarkerGroup!: L.LayerGroup<any>;
  filteredMarkers: any[] = [];

  customEstates!: L.GeoJSON<any>;
  customMunicipios!: L.GeoJSON<any>;
  sectionsJson: any;
  idUser!: string | null;
  token!: string | null;
  idWorkGroup!: string | null;
  environment!: string | null;
  filtersApplied: boolean = false;
  promotedActive: boolean = false;



  constructor(private mapService: MapService,
    private requestService: RequestService,
    private POIservice: POIService,
    private userService: UserService,
    public http: HttpClient,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private location: Location,
    private promotedService: PromotedService


  ) {

  }


  ngOnInit(): void {


    this.activeRoute.params.subscribe(routeParams => {
      localStorage.setItem('token', routeParams['token']);
      localStorage.setItem('idWorkGroup', routeParams['idWorkgroup']);
      localStorage.setItem('idUser', routeParams['idUser']);
      localStorage.setItem('environment', routeParams['parentUrl']);

    });



    this.getUrl();
    this.initMap();
    this.getIdStatus();
    this.getFilterButtons();
    this.printSearchResults();
    this.getSpecificFilter();
    this.getPublicService();
    this.getVisitedBeneficiary();
    this.clearSecction();
    this.clearStatus();

  }


  private getUrl() {


    this.activeRoute.params.subscribe(routeParams => {
      switch (routeParams['parentUrl']) {
        case '1':
          this.parentUrl = 'http://pruebas-integracion.gesco.click/sistema';
          break;
        case '2':
          this.parentUrl = 'https://gesco.click/sistema';
          break;
        case '3':
          this.parentUrl = 'https://gesco.mx/sistema';
          break;
        default:
          break;
      }
    })
  }

  private initMap(): void {
    //configuración del mapa
    this.map = L.map('map', {
      center: [this.lat, this.lon],
      attributionControl: false,
      zoom: 14
    });

    // (L as any).easyPrint({
    //   title: 'Imprimir el mapa',
    //   position: 'bottomleft',
    //   sizeModes: ['A4Portrait', 'A4Landscape']
    // }).addTo(this.map);

    L.control.browserPrint({
      title: 'Imprimir',
      printModesNames: { Portrait: "Retrato", Landscape: "Landscape", Auto:"Auto"},
    }).addTo(this.map);


    //iconos personalizados
    var iconDefault = L.icon({
      iconRetinaUrl,
      iconUrl,
      shadowUrl,
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      tooltipAnchor: [16, -28],
      shadowSize: [41, 41]
    });
    L.Marker.prototype.options.icon = iconDefault;

    //titulo
    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '&copy; <a href="https://1938.com.es">Web Inteligencia Artificial</a>'
    });
    this.loadMapData();


    tiles.addTo(this.map);
    this.oms = new OverlappingMarkerSpiderfier(this.map, { nearbyDistance: 20, keepSpiderfied: true });


  }

  loadMapData() {
    return new Promise((resolve) => {

      this.getUserProfileInfo().then(() => {
        this.getsympathizer().then(() => {

        });
        this.getPOIS().then(() => {

        });

        if (this.promotedActive == true) {
          this.getPromoted().then(() => {

          });

          this.loadPromoted();
        }


        //Toogle de pines
        this.loadSympathizer();
        this.loadPOIs();

        const markers = new Promise((resolve, reject) => {
          this.getMarkers(this.userProfileInfo.userLevel).then((data) => {
            this.setMarkers(data).then((data) => {
              resolve(true);
            }).catch((e) => { console.log(e), reject() });
          });
        });

        const layers = new Promise((resolve, reject) => {
          this.getAllLayertypes().then((data) => {
            resolve(true);
          }).catch((e) => { console.log(e), reject() });
        });


        Promise.all([
          markers, layers

        ]).then((values) => {
          resolve(true)
          this.mapLoaded = true;
          console.log("counter loaded" + values);
        });
      })
    })
  }

  getUserProfileInfo() {
    return new Promise((resolve) => {
      this.userService.onProfileInfo.subscribe((ProfileInfo) => {
        this.promotedActive = this.userService.modules.promoted
        this.userProfileInfo = ProfileInfo;
        resolve(true);
      })
    })
  }

  private addControls() {
    this.map.addControl(this.controls);

  }

  getsympathizer() {
    return new Promise((resolve) => {
      this.activeRoute.params.subscribe(routeParams => {
        this.mapService.getSympathizers(routeParams['idWorkgroup'], this.userProfileInfo.userLevel, routeParams['idUser']).subscribe((data) => {
          this.setSympathizerMarkers(data);
          resolve(true);
        });
      })
    })
  }

  getPOIS() {
    return new Promise((resolve) => {
      this.activeRoute.params.subscribe(routeParams => {
        this.mapService.getPOIsMarkers(routeParams['idWorkgroup'], this.userProfileInfo.userLevel, routeParams['idUser']).subscribe((data) => {
          this.setPOIMarkers(data);
          resolve(true);
        });
      })
    })
  }

  getPromoted() {
    return new Promise((resolve) => {
      this.activeRoute.params.subscribe(routeParams => {
        this.mapService.getPromotedMarkers(routeParams['idWorkgroup'], this.userProfileInfo.userLevel, routeParams['idUser']).subscribe((data) => {
          this.setPromotedMarkers(data);
          resolve(true);
        });
      })
    })
  }

  loadPOIs() {
    this.mapService.onPOI.subscribe((Estatus: boolean) => {
      if (Estatus === true) {
        this.isPoiFilter = true;
        this.markerCount.emit({ count: this.POIMarkers.length, type: "POI" });
        console.log(this.POIMarkerGroup);
        this.clearMarkers();
        this.POIMarkerGroup.addTo(this.map);

      } else {
        this.isPoiFilter = false;
        this.clearMarkers(10);
        this.markerCount.emit({ count: this.markers.length, type: "request" });
        console.log(this.POIMarkerGroup);
        this.addAllLayer();
      }
    })
  }

  loadSympathizer() {
    this.userService.onSympathizer.subscribe((Estatus: boolean) => {
      console.log('load simp');
      if (Estatus === true) {
        this.isSympathizerFilter = true;
        this.markerCount.emit({ count: this.SympathizerMarkers.length, type: "sympathizer" });
        console.log(this.SympathizerMarkerGroup);
        this.clearMarkers();
        this.SympathizerMarkerGroup.addTo(this.map);

      } else {
        this.isSympathizerFilter = false;
        this.markerCount.emit({ count: this.markers.length, type: "request" });
        this.addAllLayer();
      }
    })
  }

  loadPromoted() {
    this.userService.onPromoted.subscribe((Estatus: boolean) => {
      console.log('load prom');
      if (Estatus === true) {
        this.isPromotedFilter = true;
        this.markerCount.emit({ count: this.promotedMarkers.length, type: "promoted" });
        console.log(this.promotedMarkerGroup);
        this.clearMarkers();
        this.promotedMarkerGroup.addTo(this.map);

      } else {
        this.isPromotedFilter = false;
        this.markerCount.emit({ count: this.markers.length, type: "request" });
        this.addAllLayer();
      }
    })
  }

  setPromotedMarkers(markers: any) {
    return new Promise((resolve) => {

      this.promotedMarkerGroup = L.layerGroup();
      this.promotedMarkers = [];
      let sampleRegEx: RegExp = /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/;
      const validMarkers = markers.filter((marker: { location: string; }) => sampleRegEx.test(marker.location.replace(/\s/g, '')));

      validMarkers.forEach((marker: any, index: number) => {

        if (marker.idstatus == 12) {
          var path = 'assets/img/pins/promoted.png';
        } else {
          var path = 'assets/img/pins/promoted_voted.png';
        }

        var myIcon = L.icon({ iconUrl: path });
        var coords = marker.location.split(",");
        coords[0] = parseFloat(coords[0]);
        coords[1] = parseFloat(coords[1]);
        const Currentmarker = L.marker(coords, { icon: myIcon });
        this.oms.addMarker(Currentmarker);

        Currentmarker.on("click", () => this.getPromotedInformation(marker.idrequests, marker.idstatus, Currentmarker));

        Currentmarker.addTo(this.promotedMarkerGroup);
        this.promotedMarkers.push(marker);
        if (validMarkers.length == index + 1) {
          resolve(true);
        }

      })
      this.promotedMarkerGroup.addTo(this.map);
    })
  }

  setPOIMarkers(markers: any) {
    return new Promise((resolve) => {

      this.POIMarkerGroup = L.layerGroup();
      this.POIMarkers = [];
      let sampleRegEx: RegExp = /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/;
      const validMarkers = markers.filter((marker: { location: string; }) => sampleRegEx.test(marker.location.replace(/\s/g, '')));

      validMarkers.forEach((marker: any, index: number) => {
        var path = 'assets/img/pins/POI.png';

        var myIcon = L.icon({ iconUrl: path });
        var coords = marker.location.split(",");
        coords[0] = parseFloat(coords[0]);
        coords[1] = parseFloat(coords[1]);
        const Currentmarker = L.marker(coords, { icon: myIcon });
        this.oms.addMarker(Currentmarker);

        Currentmarker.on("click", () => this.getPOIInformation(marker.idrequests, Currentmarker));

        Currentmarker.addTo(this.POIMarkerGroup);
        this.POIMarkers.push(marker);
        if (validMarkers.length == index + 1) {
          resolve(true);
        }

      })
      this.POIMarkerGroup.addTo(this.map);
    })
  }

  setSympathizerMarkers(markers: any) {
    return new Promise((resolve) => {

      this.SympathizerMarkerGroup = L.layerGroup();
      this.SympathizerMarkers = [];
      let sampleRegEx: RegExp = /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/;
      const validMarkers = markers.filter((marker: { location: string; }) => sampleRegEx.test(marker.location.replace(/\s/g, '')));

      validMarkers.forEach((marker: any, index: number) => {
        var path = 'assets/img/pins/sympathizer.png';

        var myIcon = L.icon({ iconUrl: path });
        var coords = marker.location.split(",");
        coords[0] = parseFloat(coords[0]);
        coords[1] = parseFloat(coords[1]);
        const Currentmarker = L.marker(coords, { icon: myIcon });
        this.oms.addMarker(Currentmarker);

        Currentmarker.on("click", () => this.getMarkerSympathizerInformation(marker.idrequests, Currentmarker));

        Currentmarker.addTo(this.SympathizerMarkerGroup);
        this.SympathizerMarkers.push(marker);
        if (validMarkers.length == index + 1) {
          resolve(true);
        }

      })
      this.SympathizerMarkerGroup.addTo(this.map);
    })
  }

  centerOnMain() {
    switch (this.userProfileInfo.userLevel) {
      case 0:
        console.log(this.republic);

        this.map.fitBounds(this.republic.getBounds());

        break;
      case 1:
        this.map.fitBounds(this.estates.getBounds());

        break;
      case 2:
        this.map.fitBounds(this.estates.getBounds());

        break;
      case 3:
        this.map.fitBounds(this.municipios.getBounds());


        break;
      case 4:
        this.map.fitBounds(this.municipios.getBounds());

        break;

      default:
        this.map.fitBounds(this.municipios.getBounds());


        break;
    }
  }

  clearLayers() {
    switch (this.userProfileInfo.userLevel) {
      case 0:
        this.map.fitBounds(this.republic.getBounds());
        this.customMunicipios.clearLayers();

        this.map.removeLayer(this.customEstates, this.customMunicipios);
        break;
      case 1:
        this.map.fitBounds(this.republic.getBounds());
        this.map.removeLayer(this.customEstates, this.customMunicipios);
        this.map.removeControl(this.controls);
        this.federalDistricts.clearLayers();
        this.localDistricts.clearLayers();
        this.sections.clearLayers();
        if (this.customMunicipios != null) {
          this.customMunicipios.clearLayers();
        }

        break;
      case 2:
        this.map.fitBounds(this.estates.getBounds());
        this.map.removeLayer(this.customEstates, this.customMunicipios);
        this.customMunicipios.clearLayers();

        break;
      case 3:
        this.map.fitBounds(this.municipios.getBounds());
        this.map.removeLayer(this.customEstates, this.customMunicipios);
        this.customMunicipios.clearLayers();

        break;
      case 4:
        this.map.fitBounds(this.municipios.getBounds());
        this.map.removeLayer(this.customEstates, this.customMunicipios);
        this.customMunicipios.clearLayers();

        break;

      default:
        this.map.fitBounds(this.municipios.getBounds());
        this.map.removeLayer(this.customEstates, this.customMunicipios);
        this.customMunicipios.clearLayers();

        break;
    }
  }

  addAllMarker() {
    this.registeredMarkerGroup.addTo(this.map);
    this.assignedMarkerGroup.addTo(this.map);
    this.notViableMarkerGroup.addTo(this.map);
    this.canceledMarkerGroup.addTo(this.map);
    this.onProcessMarkerGroup.addTo(this.map);
    this.accomplishedMarkerGroup.addTo(this.map);
    this.acceptedMarkerGroup.addTo(this.map);
    this.preRegisterMarkerGroup.addTo(this.map);
    this.SympathizerMarkerGroup.addTo(this.map);
    this.POIMarkerGroup.addTo(this.map);

    if (this.promotedMarkerGroup != undefined) {
      this.promotedMarkerGroup.addTo(this.map);
    }

  }

  clearStatus() {
    this.mapService.onClearStatus.subscribe((deleteAll: Boolean) => {
      console.log('clear stats');

      if (this.Filtersections != undefined) {
        this.map.removeLayer(this.Filtersections)
      }

      this.addAllMarker();
      this.clearLayers();


      // this.clearMarkers().then(() => {

      //   this.getsympathizer();
      //   this.POIMarkerGroup.addTo(this.map);
      //   this.SympathizerMarkerGroup.addTo(this.map);

      //   this.getMarkers(this.userProfileInfo.userLevel).then((data) => {
      //     this.setMarkers(data).then((data) => {

      //     })
      //   });


      // });

    })
  }

  clearSecction() {
    this.mapService.onClearSecction.subscribe((clear: Boolean) => {
      console.log(clear);
      console.log(this.sections);
      this.map.removeLayer(this.Filtersections);
      this.centerOnMain();

    })
  }

  getIdStatus() {
    this.activeRoute.params.subscribe(routeParams => {
      this.mapService.onSelectStatus.subscribe((selectedSatus: number) => {
        this.clearMarkers();

        switch (selectedSatus) {

          case 1:
            this.registeredMarkerGroup.addTo(this.map);
            this.markerCount.emit({ count: this.registeredMarkerGroup.getLayers().length, type: "registeredMarkerGroup" });

            break;
          case 2:
            this.assignedMarkerGroup.addTo(this.map);
            this.markerCount.emit({ count: this.assignedMarkerGroup.getLayers().length, type: "assignedMarkerGroup" });

            break;
          case 5:
            this.onProcessMarkerGroup.addTo(this.map);
            this.markerCount.emit({ count: this.onProcessMarkerGroup.getLayers().length, type: "onProcessMarkerGroup" });

            break;
          case 6:
            this.accomplishedMarkerGroup.addTo(this.map);
            this.markerCount.emit({ count: this.accomplishedMarkerGroup.getLayers().length, type: "accomplishedMarkerGroup" });

            break;
          case 4:
            this.canceledMarkerGroup.addTo(this.map);
            this.markerCount.emit({ count: this.canceledMarkerGroup.getLayers().length, type: "canceledMarkerGroup" });

            break;
          case 3:
            this.notViableMarkerGroup.addTo(this.map);
            this.markerCount.emit({ count: this.notViableMarkerGroup.getLayers().length, type: "notViableMarkerGroup" });

            break;
          default:
            break;
        }

      })
    })
  }

  areAllFieldsEmpty(obj: any): boolean {
    for (const key in obj) {
      if (obj[key] !== null && obj[key] !== "") {
        return false; // Si encuentra al menos un campo no vacío, retorna false
      }
    }
    return true; // Si todos los campos están vacíos, retorna true
  }


  getSpecificFilter() {
    this.activeRoute.params.subscribe(routeParams => {
      this.mapService.onFilterSpecific.subscribe((formValue) => {
        console.log(formValue);


        this.mapService.specificFilter(routeParams['idWorkgroup'], formValue, this.userProfileInfo.userLevel, routeParams['idUser']).subscribe((data: any) => {
          console.log(data);
          this.clearMarkers();

          if (this.areAllFieldsEmpty(formValue)) {
            // this.getsympathizer()
            this.SympathizerMarkerGroup.addTo(this.map);
            this.POIMarkerGroup.addTo(this.map);
            this.filtersApplied = false;
          } else {
            this.filtersApplied = true;
          }
          this.setFilteredMarkers(data).then(() => {
            this.filteredMarkerGroup.addTo(this.map);
          })

          if (formValue.seccion != null && formValue.seccion != '') {
            const result = this.sectionsJson.filter((seccion: any) => seccion.properties.SECCION == formValue.seccion);
            this.configLayer(result);
          }

        })
      })
    })
  }

  clearMarkers(selectedSatus?: number) {
    return new Promise((resolve) => {

      switch (selectedSatus) {
        case 1:
          this.map.removeLayer(this.registeredMarkerGroup);
          break;
        case 2:
          this.map.removeLayer(this.assignedMarkerGroup);
          break;
        case 5:
          this.map.removeLayer(this.onProcessMarkerGroup);
          break;
        case 6:
          this.map.removeLayer(this.accomplishedMarkerGroup);
          break;
        case 4:
          this.map.removeLayer(this.canceledMarkerGroup);
          break;
        case 3:
          this.map.removeLayer(this.notViableMarkerGroup);
          break;
        case 9:
          this.map.removeLayer(this.SympathizerMarkerGroup);
          break;
        case 10:
          this.map.removeLayer(this.POIMarkerGroup);
          break;
        case 11:
          this.map.removeLayer(this.visitsMarkerGroup);
          break;
        case 12:
          this.map.removeLayer(this.promotedMarkerGroup);
          break;
        default:
          this.map.removeLayer(this.registeredMarkerGroup);
          this.map.removeLayer(this.assignedMarkerGroup);
          this.map.removeLayer(this.onProcessMarkerGroup);
          this.map.removeLayer(this.accomplishedMarkerGroup);
          this.map.removeLayer(this.canceledMarkerGroup);
          this.map.removeLayer(this.notViableMarkerGroup);
          this.map.removeLayer(this.SympathizerMarkerGroup);
          this.map.removeLayer(this.POIMarkerGroup);
          this.map.removeLayer(this.visitsMarkerGroup);
          this.map.removeLayer(this.promotedMarkerGroup);

          break;
      }
      resolve(true);
    })
  }

  printSearchResults() {
    this.mapService.dataSearch.subscribe((results: any) => {
      this.clearMarkers();
      console.log(results);

      // const applicants = results.filter((result: { type: string; }) => result.type === 'applicant');
      // const symapthizers = results.filter((result: { type: string; }) => result.type === 'symapthizer');
      // this.setSympathizerMarkers(symapthizers);
      this.setFilteredMarkers(results).then(() => {
        this.filteredMarkerGroup.addTo(this.map);
      })
    });
  }

  getFilterButtons() {
    this.mapService.onSelectButton.subscribe((selectedSatus: number) => {

      switch (selectedSatus) {
        case 1:
          this.getAssignedRequest();
          break;
        case 2:
          this.getBeneficiaryFilter();
          break;

        default:
          break;
      }

    });
  }

  getBeneficiaryFilter() {
    this.activeRoute.params.subscribe(routeParams => {
      this.mapService.filterByBenficiary(routeParams['idWorkgroup'], this.userProfileInfo.userLevel, routeParams['idUser']).subscribe((data) => {
        this.clearMarkers();

        this.setMarkers(data);

      })
    })
  }

  getAssignedRequest() {
    this.activeRoute.params.subscribe(routeParams => {
      this.mapService.filterByAssigned(routeParams['idUser'], routeParams['idWorkgroup']).subscribe((data) => {
        this.clearMarkers();
        this.setMarkers(data);

      })
    })
  }

  loaderAlert() {

    Swal.fire({
      title: 'Cargando',
      html: "test",
      timerProgressBar: true,
      didOpen: () => {

      },
      willClose: () => {

      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        console.log('I was closed by the timer')
      }
    })
  }

  sortMarkers(Currentmarker: L.Marker<any>, type: number) {
    switch (type) {
      case 1:
        Currentmarker.addTo(this.registeredMarkerGroup);
        this.registeredMarkerGroup.addTo(this.map);

        break;
      case 2:
        Currentmarker.addTo(this.assignedMarkerGroup);
        this.assignedMarkerGroup.addTo(this.map);

        break;
      case 3:
        Currentmarker.addTo(this.notViableMarkerGroup);
        this.notViableMarkerGroup.addTo(this.map);

        break;
      case 4:
        Currentmarker.addTo(this.canceledMarkerGroup);
        this.canceledMarkerGroup.addTo(this.map);

        break;
      case 5:
        Currentmarker.addTo(this.onProcessMarkerGroup);
        this.onProcessMarkerGroup.addTo(this.map);

        break;
      case 6:
        Currentmarker.addTo(this.accomplishedMarkerGroup);
        this.accomplishedMarkerGroup.addTo(this.map);

        break;
      case 7:
        Currentmarker.addTo(this.acceptedMarkerGroup);
        this.acceptedMarkerGroup.addTo(this.map);

        break;
      case 8:
        Currentmarker.addTo(this.preRegisterMarkerGroup);
        this.preRegisterMarkerGroup.addTo(this.map);
        break;
      case 9:
        Currentmarker.addTo(this.SympathizerMarkerGroup);
        //this.SympathizerMarkerGroup.addTo(this.map);
        break;
      case 10:
        Currentmarker.addTo(this.POIMarkerGroup);
        // this.POIMarkerGroup.addTo(this.map);
        break;
      case 11:
        Currentmarker.addTo(this.visitsMarkerGroup);
        this.visitsMarkerGroup.addTo(this.map);
        break;


      default:
        break;
    }

  }

  addAllLayer() {
    this.registeredMarkerGroup.addTo(this.map);
    this.assignedMarkerGroup.addTo(this.map);
    this.notViableMarkerGroup.addTo(this.map);
    this.canceledMarkerGroup.addTo(this.map);
    this.onProcessMarkerGroup.addTo(this.map);
    this.accomplishedMarkerGroup.addTo(this.map);
    this.acceptedMarkerGroup.addTo(this.map);
    this.preRegisterMarkerGroup.addTo(this.map);
    this.POIMarkerGroup.addTo(this.map);
    this.promotedMarkerGroup.addTo(this.map);

  }

  setFilteredMarkers(markers: any) {
    return new Promise((resolve) => {

      this.filteredMarkerGroup = L.layerGroup();
      this.filteredMarkerGroup.clearLayers();

      this.filteredMarkers = [];

      console.log(this.filteredMarkerGroup);
      let sampleRegEx: RegExp = /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/;
      const validMarkers = markers.filter((marker: { location: string; }) => sampleRegEx.test(marker.location.replace(/\s/g, '')));
      console.log(validMarkers);
      validMarkers.forEach((marker: any, index: number) => {

        var path = "";
        switch (marker.idstatus) {
          case 1:
            path = 'assets/img/pins/registro.png';
            break;
          case 2:
            path = 'assets/img/pins/asignado.png';
            break;
          case 3:
            path = 'assets/img/pins/noviable.png';
            break;
          case 4:
            path = 'assets/img/pins/cancelado.png';
            break;
          case 5:
            path = 'assets/img/pins/enproceso.png';
            break;
          case 6:
            path = 'assets/img/pins/metacumplida.png';
            break;
          case 7:
            path = 'assets/img/pins/aceptado.png';
            break;
          case 8:
            path = 'assets/img/pins/preregistro.png';
            break;
          case 9:
            path = 'assets/img/pins/sympathizer.png';
            break;
          case 10:
            path = 'assets/img/pins/POI.png';
            break;
          case 11:
            path = 'assets/img/pins/sympathizer.png';
            break;
          case 12:
            path = 'assets/img/pins/promoted.png';
            break;
          case 13:
            path = 'assets/img/pins/promoted_voted.png';
            break;
          default:
            path = 'assets/img/pins/preregistro.png';
            break;
        }

        var myIcon = L.icon({ iconUrl: path });
        var coords = marker.location.split(",");
        coords[0] = parseFloat(coords[0]);
        coords[1] = parseFloat(coords[1]);
        const Currentmarker = L.marker(coords, { icon: myIcon });

        //this.oms.addMarker(Currentmarker);

        switch (marker.type) {
          case 'sympathizer':
            Currentmarker.on("click", () => this.getMarkerSympathizerInformation(marker.idrequests, Currentmarker));
            break;
          case 'POI':
            Currentmarker.on("click", () => this.getPOIInformation(marker.idrequests, Currentmarker));

            break;
          case 'proposal':
            Currentmarker.on("click", () => this.getProposalInformation(marker.idrequests, Currentmarker));
            break;
          case 'promoted':
            Currentmarker.on("click", () => this.getPromotedInformation(marker.idrequests, marker.idstatus, Currentmarker));
            break;

          default:
            Currentmarker.on("click", () => this.getMarkerInformation(marker.idrequests, Currentmarker));
            break;
        }

        this.filteredMarkers.push(marker);
        Currentmarker.addTo(this.filteredMarkerGroup);

        if (validMarkers.length == index + 1) {
          resolve(true);
        }

      })


      if (validMarkers.length == 0) {

        resolve(true);
      }

      if (this.filtersApplied == true) {
        this.markerCount.emit({ count: this.filteredMarkers.length, type: "registers" });

      } else {
        this.markerCount.emit({ count: this.filteredMarkers.length, type: "request" });

      }
    })
  }

  setMarkers(markers: any) {
    return new Promise((resolve) => {

      this.registeredMarkerGroup = L.layerGroup().addTo(this.map);
      this.assignedMarkerGroup = L.layerGroup().addTo(this.map);
      this.onProcessMarkerGroup = L.layerGroup().addTo(this.map);
      this.accomplishedMarkerGroup = L.layerGroup().addTo(this.map);
      this.canceledMarkerGroup = L.layerGroup().addTo(this.map);
      this.notViableMarkerGroup = L.layerGroup().addTo(this.map);
      this.acceptedMarkerGroup = L.layerGroup().addTo(this.map);
      this.preRegisterMarkerGroup = L.layerGroup().addTo(this.map);
      this.visitsMarkerGroup = L.layerGroup().addTo(this.map);

      //this.SympathizerMarkerGroup = L.layerGroup().addTo(this.map);
      // this.POIMarkerGroup = L.layerGroup().addTo(this.map);


      this.markers = [];
      console.log(markers);
      let sampleRegEx: RegExp = /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/;
      const validMarkers = markers.filter((marker: { location: string; }) => sampleRegEx.test(marker.location.replace(/\s/g, '')));
      console.log(validMarkers);
      validMarkers.forEach((marker: any, index: number) => {

        var path = "";
        switch (marker.idstatus) {
          case 1:
            path = 'assets/img/pins/registro.png';
            break;
          case 2:
            path = 'assets/img/pins/asignado.png';
            break;
          case 3:
            path = 'assets/img/pins/noviable.png';
            break;
          case 4:
            path = 'assets/img/pins/cancelado.png';
            break;
          case 5:
            path = 'assets/img/pins/enproceso.png';
            break;
          case 6:
            path = 'assets/img/pins/metacumplida.png';
            break;
          case 7:
            path = 'assets/img/pins/aceptado.png';
            break;
          case 8:
            path = 'assets/img/pins/preregistro.png';
            break;
          case 9:
            path = 'assets/img/pins/sympathizer.png';
            break;
          case 10:
            path = 'assets/img/pins/POI.png';
            break;
          case 11:
            path = 'assets/img/pins/sympathizer.png';
            break;
          case 12:
            path = 'assets/img/pins/promoted.png';
            break;
          case 13:
            path = 'assets/img/pins/promoted_voted.png';
            break;
          default:
            path = 'assets/img/pins/preregistro.png';
            break;
        }

        var myIcon = L.icon({ iconUrl: path });
        var coords = marker.location.split(",");
        coords[0] = parseFloat(coords[0]);
        coords[1] = parseFloat(coords[1]);
        const Currentmarker = L.marker(coords, { icon: myIcon });
        //this.oms.addMarker(Currentmarker);

        switch (marker.type) {
          case 'sympathizer':
            Currentmarker.on("click", () => this.getMarkerSympathizerInformation(marker.idrequests, Currentmarker));
            break;
          case 'POI':
            Currentmarker.on("click", () => this.getPOIInformation(marker.idrequests, Currentmarker));

            break;
          case 'proposal':
            Currentmarker.on("click", () => this.getProposalInformation(marker.idrequests, Currentmarker));
            break;
          case 'promoted':
            Currentmarker.on("click", () => this.getPromotedInformation(marker.idrequests, marker.idstatus, Currentmarker));
            break;

          default:
            Currentmarker.on("click", () => this.getMarkerInformation(marker.idrequests, Currentmarker));
            break;
        }

        this.sortMarkers(Currentmarker, marker.idstatus);
        this.markers.push(marker);

        if (validMarkers.length == index + 1) {
          resolve(true);
        }

      })

      if (validMarkers.length == 0) {
        resolve(true);
      }

      if (this.filtersApplied == true) {
        this.markerCount.emit({ count: this.markers.length, type: "registers" });

      } else {
        this.markerCount.emit({ count: this.markers.length, type: "request" });

      }
    })
  }

  getMarkerInformation(idRequest: number, Currentmarker: L.Marker<any>) {


    this.idUser = localStorage.getItem('idUser');
    this.token = localStorage.getItem('token');
    this.idWorkGroup = localStorage.getItem('idWorkGroup');
    this.environment = localStorage.getItem('environment');

    this.mapService.getPinInformation(idRequest).subscribe(data => {
      console.log(data);
      const pinInformation = 'Folio: ' + data[0].qrcode + '</br>' +
        ' Nombre: ' + (data[0].nameBeneficiary == '' ? data[0].nameApplicant : data[0].nameBeneficiary) + '</br>' +
        ' Calle y número: ' + data[0].street + ' ' + data[0].streetNumExt + '</br>' +
        ' Colonia: ' + data[0].nameNB + '</br>' +
        ' Código postal: ' + data[0].nameCP + '</br>' +
        ' Sección electoral: ' + data[0].electoralSection + '</br>' +
        ' Tema: ' + data[0].nameTheme + '</br>' +
        ' Subtema: ' + data[0].nameSubtheme + '</br>' +
        ' Clasificación: ' + data[0].nameCategory + '</br>' +
        ' Descripción: ' + data[0].description + '</br>' +
        ' Estatus: ' + data[0].nameStatus;
      const button = '<p class="popup__header"> <strong style="font-family: Arial">' + pinInformation + '</strong><div class="separator-2"></div>' +
        '<a href="http://' + window.location.host + '/#/detailRequest/' + idRequest + '/' + this.idUser + '/' + this.idWorkGroup + '/' + this.token + '/' + this.environment + '"  style="text-decoration: none;color: white;"><button type="button" id="sendData" class="btn btn-success btn-sm btn-block dataModal sendData" data-toggle="modal" data-target="#dataModal" >' +
        'Ir a la solicitud</button></a> </p>';
      Currentmarker.bindPopup(button).openPopup();
    });
  }

  getProposalInformation(idPromoter: number, Currentmarker: L.Marker<any>) {

    this.idUser = localStorage.getItem('idUser');
    this.token = localStorage.getItem('token');
    this.idWorkGroup = localStorage.getItem('idWorkGroup');
    this.environment = localStorage.getItem('environment');

    this.mapService.getPinProposalInformation(idPromoter).subscribe(data => {
      this.sympathizerLoader();
      console.log(data);
      const pinInformation = 'Folio: ' + data[0].qrcode + '</br>' +
        ' Nombre: ' + data[0].name + ' ' + data[0].next_name + ' ' + data[0].last_name + '</br>' +
        ' Calle y número: ' + data[0].street + ' ' + data[0].streetNumExt + '</br>' +
        ' Colonia: ' + data[0].neighborhoodName + '</br>' +
        ' Código postal: ' + data[0].postalCode + '</br>' +
        ' Sección electoral: ' + data[0].electoralSection + '</br>'
      const button = '<p class="popup__header"> <strong  style="font-family: Arial">' + pinInformation + '</strong><div class="separator-2"></div>' +
        '<a href="http://' + window.location.host + '/#/dataProposal/' + idPromoter + '/' + this.idWorkGroup + '/' + this.token + '" style="text-decoration: none;color: white;"><button type="button" id="sendData" class="btn btn-success btn-sm btn-block dataModal sendData" data-toggle="modal" data-target="#dataModal" >' +
        'Ir a propuesta</button></a> </p>';
      Swal.close();
      Currentmarker.bindPopup(button).openPopup();

    });
  }

  voted() {
    console.log('voted');

  }

  getPromotedInformation(idPromoted: number, idstatus: number, Currentmarker: L.Marker<any>) {
    this.idUser = localStorage.getItem('idUser');
    this.token = localStorage.getItem('token');
    this.idWorkGroup = localStorage.getItem('idWorkGroup');
    this.environment = localStorage.getItem('environment');

    this.mapService.getPinPromotedInformation(idPromoted).subscribe(data => {
      this.sympathizerLoader();
      console.log(data);

      // Construir la cadena de información del pin
      const pinInformation = 'Folio: ' + data[0].qrcode + '</br>' +
        ' Nombre: ' + data[0].name + ' ' + data[0].next_name + ' ' + data[0].last_name + '</br>' +
        ' Calle y número: ' + data[0].street + ' ' + data[0].street_num_ext + '</br>' +
        ' Colonia: ' + data[0].neighborhoodName + '</br>' +
        ' Código postal: ' + data[0].postalCode + '</br>' +
        ' Sección electoral: ' + data[0].electoralSection + '</br>' +
        ' Preferencia partidaria: ' + data[0].position + '</br>';

      // Construir el botón basado en el valor de idstatus
      let button = '';
      if (idstatus === 13) {
        button = '<p class="popup__header"> <strong  style="font-family: Arial">'
          + pinInformation +
          '</strong><div class="separator-2"></div>' +

          '<a style="text-decoration: none;color: white;" ><button id="miBoton" type="button" class="btn btn-success btn-sm btn-block mb-1" style="background-color: green !important;">' +
          'Votó</button></a>';
      } else {
        // Construir el botón sin el botón "Votó" si idstatus no es 13
        button = '<p class="popup__header"> <strong  style="font-family: Arial">'
          + pinInformation +
          '</strong><div class="separator-2"></div>';
      }

      // Agregar el botón "Ir a promovido" común a ambos casos
      button += '<a href="http://' + window.location.host + '/#/promotedDetail/' + idPromoted + '/' + this.idWorkGroup + '/' + this.token + '/' + this.environment + '" style="text-decoration: none;color: white;"><button type="button" id="sendData" class="btn btn-success btn-sm btn-block dataModal sendData">' +
        'Ir a promovido</button></a>' +
        ' </p>';

      Swal.close();
      Currentmarker.bindPopup(button).openPopup();

      // Si el botón "Votó" está presente, agregar el EventListener
      if (idstatus === 13) {
        const miBoton = document.getElementById('miBoton');
        miBoton!.addEventListener('click', () => {
          console.log('El botón fue clickeado usando EventListener');
          this.promotedVoted(idPromoted);
        });
      }
    });
  }



  promotedVoted(idPromoted: number) {
    this.promotedService.promotedVoted(idPromoted).subscribe((data: any) => {
      this.clearMarkers(12);
      this.getPromoted();
      console.log(data);
    })
  }

  getMarkerSympathizerInformation(idSympathizer: number, Currentmarker: L.Marker<any>) {
    this.sympathizerLoader();

    this.idUser = localStorage.getItem('idUser');
    this.token = localStorage.getItem('token');
    this.idWorkGroup = localStorage.getItem('idWorkGroup');
    this.environment = localStorage.getItem('environment');

    this.mapService.getPinSympathizerInformation(idSympathizer).subscribe(data => {
      console.log(data);
      const pinInformation = 'Folio: ' + data[0].qrcode + '</br>' +
        ' Nombre: ' + data[0].name + ' ' + data[0].next_name + ' ' + data[0].last_name + '</br>' +
        ' Calle y número: ' + data[0].street + ' ' + data[0].streetNumExt + '</br>' +
        ' Colonia: ' + data[0].neighborhoodName + '</br>' +
        ' Código postal: ' + data[0].postalCode + '</br>' +
        ' Sección electoral: ' + data[0].electoralSection + '</br>'
      const button = '<p class="popup__header"> <strong  style="font-family: Arial">' + pinInformation + '</strong><div class="separator-2"></div>' +
        '<a href="http://' + window.location.host + '/#/sympathizerDetail/' + idSympathizer + '/' + this.idWorkGroup + '/' + this.token + '/' + this.environment + '" style="text-decoration: none;color: white;"><button type="button" id="sendData" class="btn btn-success btn-sm btn-block dataModal sendData" data-toggle="modal" data-target="#dataModal" >' +
        'Ir a simpatizante</button></a> </p>';
      Swal.close();
      Currentmarker.bindPopup(button).openPopup();

    });
  }

  getPOIInformation(id: string, Currentmarker: L.Marker<any>) {
    this.sympathizerLoader();

    this.idUser = localStorage.getItem('idUser');
    this.token = localStorage.getItem('token');
    this.idWorkGroup = localStorage.getItem('idWorkGroup');
    this.environment = localStorage.getItem('environment');

    this.POIservice.getPOI(id).subscribe((data: any) => {
      console.log(data);
      const pinInformation = 'Folio: ' + data.qrcode + '</br>' +
        ' Nombre de punto: ' + data.name + ' </br>' +
        ' Calle y número: ' + data.street + ' ' + data.streetNumExt + '</br>' +
        ' Colonia: ' + data.neighborhood + '</br>' +
        ' Código postal: ' + data.postalCode + '</br>' +
        ' Sección electoral: ' + data.section + '</br>'
      const button = '<p class="popup__header"> <strong style="font-family: Arial">' + pinInformation + '</strong><div class="separator-2"></div>' +
        '<a href="http://' + window.location.host + '/#/detail/poi/' + id + '/' + this.idUser + '/' + this.idWorkGroup + '/' + this.token + '/' + this.environment + '" style="text-decoration: none;color: white;"><button type="button" id="sendData" class="btn btn-success btn-sm btn-block dataModal sendData" data-toggle="modal" data-target="#dataModal" >' +
        'Ir al punto</button></a> </p>';
      Swal.close();
      Currentmarker.bindPopup(button).openPopup();

    });
  }

  goToDetail(idPOI: number) {
    console.log(idPOI);
    this.idUser = localStorage.getItem('idUser');
    this.token = localStorage.getItem('token');
    this.idWorkGroup = localStorage.getItem('idWorkGroup');
    this.environment = localStorage.getItem('environment');

    // const parentURL = this.customizeService.getUrl();
    // window.parent.location.href = parentURL + '/PointsPoints/listPoints.html';
    this.router.navigate(['/detail/poi/' + idPOI + '/' + this.idUser + '/' + this.idWorkGroup + '/' + this.token + '/' + this.environment]);

  }

  getMarkers(level: number) {
    return new Promise((resolve, rejects) => {
      this.activeRoute.params.subscribe(routeParams => {
        this.mapService.getMarkerCords(routeParams['idWorkgroup'], level, routeParams['idUser']).subscribe(data => {
          this.count = data.length;
          resolve(data);

        }, error => {
          this.errorHandler(error);
          rejects(true)
        })
      });
    });
  }

  errorHandler(error: any) {

    console.log(error.message);

    Swal.fire({
      title: 'Error',
      html: 'Error al cargar información',
      timerProgressBar: true,
      didOpen: () => {

      },
      willClose: () => {

      }
    }).then((result) => {
      /* Read more about handling dismissals below */

    })
  }

  passLayerInfo(data: string) {
    const message = JSON.stringify({
      message: data,
      date: Date.now(),
    });
    window.parent.postMessage(message, '*');
  }

  getAllCountryLayer() {
    return new Promise((resolve, rejects) => {
      this.mapService.getAllCountryLayer().subscribe((republic: any) => {
        this.passLayerInfo(JSON.stringify(republic));
        this.republic = L.geoJson(republic, {
          style: function (feature) {
            return {
              'color': '#646464',
              'fillColor': '#7d7d7d'
            }
          }
        }).addTo(this.map);
        this.map.fitBounds(this.republic.getBounds());
        resolve(true);
      }, error => {
        this.errorHandler(error);
        rejects(true)
      })
    })
  }

  getEntityLayer(entitieId: number) {
    return new Promise((resolve, rejects) => {
      this.mapService.getEntitieLayer(entitieId).subscribe((estates: any) => {
        this.passLayerInfo(JSON.stringify(estates));

        this.estates = L.geoJson(estates, {
          style: function (feature) {
            return {
              'color': '#646464',
              'fillColor': '#7d7d7d'
            }
          }
        }).addTo(this.map);
        this.map.fitBounds(this.estates.getBounds());
        resolve(true);

      }, error => {
        this.errorHandler(error);
        rejects(true)
      })
    })
  }

  getEstateLayer(id: number) {
    return new Promise((resolve, rejects) => {
      this.mapService.getEstatesLayer(id).subscribe((json: any) => {

        this.json = json;
        let myLayer = L.geoJSON().addTo(this.map);
        myLayer.addData(json);
        resolve(true);

      }, error => {
        this.errorHandler(error);
        rejects(true)
      })
    });
  }


  getMunicipalitieLayer(idMunicipalitie: number, idEntitie: number) {
    return new Promise((resolve, rejects) => {
      this.mapService.getNomenclature(idEntitie).subscribe((nomenclature: any) => {
        this.mapService.getMunicipalitieLayer(nomenclature[0].nomenclature, idMunicipalitie, idEntitie).subscribe((municipios: any) => {
          this.passLayerInfo(JSON.stringify(municipios));

          this.municipios = L.geoJson(municipios, {
            style: function (feature) {
              return {
                'color': '#646464',
                'fillColor': '#7d7d7d'
              }
            }
          }).addTo(this.map);
          this.map.fitBounds(this.municipios.getBounds());
          resolve(true);
        }, error => {
          this.errorHandler(error);
          rejects(true)
        })
      }, error => {
        this.errorHandler(error);
        rejects(true)
      })
    });
  }

  getAuxLayers(holder: any) {

    return new Promise((resolve) => {

      switch (holder.idProfile) {

        case 1:
          Promise.all([
            this.getLocalDistrictLayer(holder.idProfileEntity),
            this.getFederalDistrictsLayer(holder.idProfileMunicipality, holder.idProfileEntity),
            this.getSecctionLayer(holder.idProfileMunicipality, holder.idProfileEntity, 0)
          ]).then((values) => {
            this.auxLayersLoaded = true;

            this.changeCustomLayerController();
            resolve(true);
          }).catch((e) => { console.log(e) });
          break;
        case 4:
          Promise.all([

          ]).then((values) => {
            this.auxLayersLoaded = true;

            this.changeCustomLayerController();
            resolve(true);
          }).catch((e) => { console.log(e) });
          break;
        case 5:
          Promise.all([
            this.getLocalDistrictLayer(holder.idProfileEntity),
            this.getFederalDistrictsLayer(holder.idProfileMunicipality, holder.idProfileEntity),
            this.getSecctionLayer(holder.idProfileMunicipality, holder.idProfileEntity, 1)
          ]).then((values) => {
            this.auxLayersLoaded = true;

            this.changeCustomLayerController();
            resolve(true);

          }).catch((e) => { console.log(e) });
          break;
        case 6:
          Promise.all([
            this.getLocalDistrictLayer(holder.idProfileEntity),
            this.getFederalDistrictsLayer(holder.idProfileMunicipality, holder.idProfileEntity),
            this.getSecctionLayer(holder.idProfileMunicipality, holder.idProfileEntity, 0)
          ]).then((values) => {
            this.auxLayersLoaded = true;

            resolve(true);


          }).catch((e) => { console.log(e) });
          break;
        case 9:
          Promise.all([
            this.getLocalDistrictLayer(holder.idProfileEntity),
            this.getFederalDistrictsLayer(holder.idProfileMunicipality, holder.idProfileEntity),
            this.getSecctionLayer(holder.idProfileMunicipality, holder.idProfileEntity, 1)
          ]).then((values) => {
            this.auxLayersLoaded = true;

            this.changeCustomLayerController();
            resolve(true);

          }).catch((e) => { console.log(e) });
          break;

        case 10:
          Promise.all([
            this.getLocalDistrictLayer(holder.idProfileEntity),
            this.getFederalDistrictsLayer(holder.idProfileMunicipality, holder.idProfileEntity),
            this.getSecctionLayer(holder.idProfileMunicipality, holder.idProfileEntity, 1)
          ]).then((values) => {
            this.auxLayersLoaded = true;

            this.changeCustomLayerController();
            resolve(true);

          }).catch((e) => { console.log(e) });
          break;

        case 11:
          Promise.all([
            this.getLocalDistrictLayer(holder.idProfileEntity),
            this.getFederalDistrictsLayer(holder.idProfileMunicipality, holder.idProfileEntity),
            this.getSecctionLayer(holder.idProfileMunicipality, holder.idProfileEntity, 0)
          ]).then((values) => {
            this.auxLayersLoaded = true;


          }).catch((e) => { console.log(e) });
          break;

        case 13:
          Promise.all([
            this.getLocalDistrictLayer(holder.idProfileEntity),
            this.getFederalDistrictsLayer(holder.idProfileMunicipality, holder.idProfileEntity),
            this.getSecctionLayer(holder.idProfileMunicipality, holder.idProfileEntity, 0)
          ]).then((values) => {
            this.auxLayersLoaded = true;

            this.mapService.onMunicipalitieSelected.subscribe(() => {
              this.changeCustomLayerController();
              resolve(true);
            })
          }).catch((e) => { console.log(e) });
          break;

        default:
          Promise.all([
            this.getLocalDistrictLayer(holder.idProfileEntity),
            this.getFederalDistrictsLayer(holder.idProfileMunicipality, holder.idProfileEntity),
            this.getSecctionLayer(holder.idProfileMunicipality, holder.idProfileEntity, 0)
          ]).then((values) => {
            this.auxLayersLoaded = true;

            this.mapService.onMunicipalitieSelected.subscribe(() => {
              this.changeCustomLayerController();
              resolve(true);
            })
          }).catch((e) => { console.log(e) });
          break;
      }
    })
  }


  getAllLayertypes() {
    return new Promise((resolve, rejects) => {
      let authProfile = this.userProfileInfo;

      if (this.userProfileInfo.idHolder != this.userProfileInfo.idProfile) {
        this.userService.getHolderProfile(this.userProfileInfo.idHolder).subscribe((holderProfile: any) => {
          authProfile = holderProfile;
          this.getAuxLayers(authProfile).then(() => {
          })

          Promise.all([
            this.layerAuth(authProfile)
          ]).then((data) => {

            resolve(true)
          }).catch((e) => {
            rejects(true);
            console.log(e)
          });
        })

      } else {
        this.getAuxLayers(authProfile).then(() => {
        })

        Promise.all([
          this.layerAuth(authProfile)
        ]).then((data) => {

          resolve(true)
        }).catch((e) => {
          rejects(true);
          console.log(e)
        });
      }
    })
  }

  addLayerFormStorage() {
    return new Promise((resolve) => {
      const storageLayer: any = localStorage.getItem("MainLayer");
      console.log(JSON.parse(storageLayer));
      this.municipios = L.geoJson(JSON.parse(storageLayer), {
        style: function (feature) {
          return {
            'color': '#646464',
            'fillColor': '#7d7d7d'
          }
        }
      }).addTo(this.map);
      this.map.fitBounds(this.municipios.getBounds());
      resolve(true);
    });
  }

  layerAuth(holderProfile: any) {
    console.log(holderProfile)


    return new Promise((resolve) => {

      if (localStorage.getItem("MainLayer") != null) {

        this.addLayerFormStorage().then((() => {
          resolve(true);
        })).catch((e) => { console.log(e) });

      } else {
        switch (holderProfile.idProfile) {

          case 1:
            this.getAllCountryLayer().then((() => {
              resolve(true);
            })).catch((e) => { console.log(e) });
            break;
          case 4:
            this.getAllCountryLayer().then((() => {
              resolve(true);
            })).catch((e) => { console.log(e) });
            break;
          case 5:
            this.getEntityLayer(holderProfile.idProfileEntity).then((() => {
              resolve(true);
            })).catch((e) => { console.log(e) });
            break;
          case 6:
            this.getMunicipalitieLayer(holderProfile.idProfileMunicipality, holderProfile.idProfileEntity).then((() => {
              resolve(true);
            })).catch((e) => { console.log(e) });
            break;
          case 8:
            this.getAllCountryLayer().then((() => {
              resolve(true);
            })).catch((e) => { console.log(e) });
            break;
          case 9:
            this.getEntityLayer(holderProfile.idProfileEntity).then((() => {
              resolve(true);
            })).catch((e) => { console.log(e) });
            break;

          case 10:
            this.getEntityLayer(holderProfile.idProfileEntity).then((() => {
              resolve(true);
            })).catch((e) => { console.log(e) });
            break;
          case 11:
            this.getMunicipalitieLayer(holderProfile.idProfileMunicipality, holderProfile.idProfileEntity).then((() => {
              resolve(true);
            })).catch((e) => { console.log(e) });
            break;
          case 13:
            if (holderProfile.idProfileMunicipality != 0) {
              this.getMunicipalitieLayer(holderProfile.idProfileMunicipality, holderProfile.idProfileEntity).then((() => {
                resolve(true);
              })).catch((e) => { console.log(e) });
            } else {
              if (holderProfile.idProfileEntity != 0) {
                this.getEntityLayer(holderProfile.idProfileEntity).then((() => {
                  resolve(true);
                })).catch((e) => { console.log(e) });
              }
            }
            break;


          default:

            if (holderProfile.idProfileMunicipality != 0) {
              this.getMunicipalitieLayer(holderProfile.idProfileMunicipality, holderProfile.idProfileEntity).then((() => {
                resolve(true);
              })).catch((e) => { console.log(e) });
            } else {
              if (holderProfile.idProfileEntity != 0) {
                this.getEntityLayer(holderProfile.idProfileEntity).then((() => {
                  resolve(true);
                })).catch((e) => { console.log(e) });
              }
            }
            break;
        }
      }




    });
  }

  //Type: tipo de capa
  //ID: id de feature
  getLayers(type: string, nomenclature: string, Id: number,) {

    this.mapService.getLayer(type, nomenclature, Id).subscribe((json: any) => {

      this.json = json;
      let myLayer = L.geoJSON().addTo(this.map);
      myLayer.addData(json);

    })

  }

  setControls(layer: L.GeoJSON, name: string) {
    this.controls.addOverlay(layer, name);
    this.addControls();
  }

  getFederalDistrictsLayer(idMunicipalitie: number, idEntitie: number) {
    return new Promise((resolve, rejects) => {

      if (this.federalDistricts != undefined) {
        this.map.removeLayer(this.federalDistricts)
      }

      this.mapService.getNomenclature(idEntitie).subscribe((nomenclature: any) => {
        this.mapService.getFederalDistrictsLayer(idEntitie).subscribe((federalDistricts: any) => {
          this.federalDistricts = L.geoJson(federalDistricts, {

            onEachFeature: function (feature, featureLayer) {
              var id: string = feature.properties.DISTRITO_F;
              featureLayer.bindPopup('Distrito federal: ' + id, {
                closeButton: false
              });
            },

            style: function (feature) {
              return {
                'color': '#3c2b1a',
                'fillColor': '#50361c'
              }
            }
          });
          console.log(this.federalDistricts);
          this.setControls(this.federalDistricts, "Distritos federales");
          resolve(true);
        }, error => {
          this.errorHandler(error);
          rejects(true)
        })

      }, error => {
        this.errorHandler(error);
        rejects(true)
      })
    })
  }

  configLayer(sections: any) {

    if (this.Filtersections != undefined) {
      this.map.removeLayer(this.Filtersections)
    }

    this.Filtersections = L.geoJson(sections, {

      onEachFeature: function (feature, featureLayer) {
        var seccion: string = feature.properties.SECCION;
        featureLayer.bindPopup('Sección: ' + seccion, {
          closeButton: false
        });
      },

      style: function (feature) {
        return {
          'color': '#890577',
          'fillColor': '#f250d2'
        }
      }
    });
    this.Filtersections.addTo(this.map);
    this.map.fitBounds(this.Filtersections.getBounds());

  }

  getSecctionLayer(idMunicipalitie: number, idEntitie: number, type: number) {
    return new Promise((resolve, rejects) => {

      if (this.sections != undefined) {
        this.map.removeLayer(this.sections)
      }

      this.mapService.getNomenclature(idEntitie).subscribe((nomenclature: any) => {
        console.log(nomenclature)
        this.mapService.getSecctionLayer(nomenclature[0].nomenclature, idMunicipalitie, idEntitie, type).subscribe((sections: any) => {
          this.sectionsJson = sections;


          if (this.sections != undefined) {
            this.map.removeLayer(this.sections)
          }

          this.sections = L.geoJson(sections, {

            onEachFeature: function (feature, featureLayer) {
              var seccion: string = feature.properties.SECCION;
              featureLayer.bindPopup('Sección: ' + seccion, {
                closeButton: false
              });
            },

            style: function (feature) {
              return {
                'color': '#890506',
                'fillColor': '#fb5657'
              }
            }
          });

          this.setControls(this.sections, "Secciones");
          resolve(true);

        }, error => {
          this.errorHandler(error);
          rejects(true)
        })

      }, error => {
        this.errorHandler(error);
        rejects(true)
      })
    })
  }

  getNeighborhoodsLayer(idMunicipalitie: number, idEntitie: number) {
    this.mapService.getNomenclature(idEntitie).subscribe((nomenclature: any) => {
      console.log(nomenclature)
      this.mapService.getNeighborhoodsLayer(nomenclature[0].nomenclature, idMunicipalitie).subscribe((neighborhoods: any) => {
        this.neighborhoods = L.geoJson(neighborhoods, {
          style: function (feature) {
            return {
              'color': '#4b6043',
              'fillColor': '#658354'
            }
          }
        });
        this.setControls(this.neighborhoods, "Vecindarios");
      })
    })
  }

  getLocalDistrictLayer(id: number) {
    return new Promise((resolve, rejects) => {

      if (this.localDistricts != undefined) {
        this.map.removeLayer(this.localDistricts)
      }

      this.mapService.getNomenclature(id).subscribe((nomenclature: any) => {
        console.log(nomenclature)
        this.mapService.getLocalDistrictLayer('local_districts', nomenclature[0].nomenclature).subscribe((localDistricts: any) => {
          this.localDistricts = L.geoJson(localDistricts, {

            onEachFeature: function (feature, featureLayer) {
              var id: string = feature.properties.DISTRITO_L;
              featureLayer.bindPopup('Distrito local: ' + id, {
                closeButton: false
              });
            },

            style: function (feature) {
              return {
                'color': '#641a67',
                'fillColor': '#7d2181'
              }
            }
          });


          this.setControls(this.localDistricts, 'Distritos Locales');
          resolve(true);
        }, error => {
          this.errorHandler(error);
          rejects(true)
        })

      }, error => {
        this.errorHandler(error);
        rejects(true)
      })
    })
  }


  getFilterEntityLayer() {
    this.mapService.onEntitieChange.subscribe(filterData => {
      console.log(filterData.idEntitie);

      if (this.customMunicipios != undefined) {
        this.map.removeLayer(this.customMunicipios)
      }

      if (filterData.idEntitie != 0) {
        this.map.removeControl(this.controls);
        this.controls = new L.Control.Layers();
        Promise.all([
          this.customEntitieLayer(filterData),
          this.getLocalDistrictLayer(filterData.idEntitie),
          this.getFederalDistrictsLayer(filterData.idEntitie, filterData.idEntitie),
          this.getSecctionLayer(filterData.idEntitie, filterData.idEntitie, 1),
        ]).then((values) => {
          Swal.close();
        }).catch((e) => {
          Swal.close();
          console.log(e)
        });
      }
    });
  }

  getFilterMunicipalitieLayer() {
    console.log('sub to on mun change')
    this.mapService.onMunicipalitieChange.subscribe(filterData => {
      console.log(filterData);
      if (filterData.idMunicipalitie != 0) {
        this.layerLoader();
        this.map.removeControl(this.controls);
        this.controls = new L.Control.Layers();
        Promise.all([
          this.customMunicipalitieLayer(filterData),
          this.getLocalDistrictLayer(filterData.idEntitie),
          this.getFederalDistrictsLayer(filterData.idMunicipalitie, filterData.idEntitie),
          this.getSecctionLayer(filterData.idMunicipalitie, filterData.idEntitie, 0),
        ]).then((values) => {
          Swal.close();
        }).catch((e) => {
          Swal.close();
          console.log(e)
        });
      }
    });
  }

  changeCustomLayerController() {

    this.getFilterEntityLayer();
    this.getFilterMunicipalitieLayer();

  }

  customEntitieLayer(filterData: any) {

    if (this.customEstates != undefined) {
      this.map.removeLayer(this.customEstates)
    }

    return new Promise((resolve, rejects) => {
      this.mapService.getEntitieLayer(filterData.idEntitie).subscribe((estates: any) => {
        this.passLayerInfo(JSON.stringify(estates));
        this.customEstates = L.geoJson(estates, {
          style: function (feature) {
            return {
              'color': '#13005A',
              'fillColor': '#00337C'
            }
          }
        }).addTo(this.map);
        this.map.fitBounds(this.customEstates.getBounds());
        resolve(true);

      }, error => {
        this.errorHandler(error);
        rejects(true)
      })
    });

  }

  customMunicipalitieLayer(filterData: any) {

    return new Promise((resolve, rejects) => {

      if (this.customMunicipios != undefined) {
        this.map.removeLayer(this.customMunicipios)
      }

      this.mapService.getNomenclature(filterData.idEntitie).subscribe((nomenclature: any) => {
        this.mapService.getMunicipalitieLayer(nomenclature[0].nomenclature, filterData.idMunicipalitie, filterData.idEntitie).subscribe((municipios: any) => {

          this.customMunicipios = L.geoJson(municipios, {
            style: function (feature) {
              return {
                'color': '#1a763e',
                'fillColor': '#0e813c'
              }
            }
          }).addTo(this.map);
          this.map.fitBounds(this.customMunicipios.getBounds());
          resolve(true);
        }, error => {
          this.errorHandler(error);
          rejects(true)
        })
      }, error => {
        this.errorHandler(error);
        rejects(true)
      })
    });
  }

  sympathizerLoader() {

    Swal.fire({
      title: 'Cargando información',
      icon: 'warning',
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false,

      didOpen: () => {

      },
      willClose: () => {

      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
      }
    })
  }

  layerLoader() {

    Swal.fire({
      title: 'Cargando capa',
      icon: 'warning',
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false,

      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton())

      },
      willClose: () => {

      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
      }
    })
  }

  getPublicService() {
    this.activeRoute.params.subscribe(routeParams => {
      this.mapService.onPublicServiceButton.subscribe(publicServiceData => {
        this.mapService.getPublicService(publicServiceData.toString(), this.userProfileInfo.userLevel, routeParams['idUser']).subscribe((data) => {
          this.clearMarkers();
          this.setMarkers(data);
          console.log(data);
        });
      });
    });
  }
  getVisitedBeneficiary() {
    this.mapService.onVisitedBeneficiaryButton.subscribe((visitedBeneficiaryData: number) => {
      this.mapService.getBeneficiaryVisited(visitedBeneficiaryData).subscribe((data) => {
        this.clearMarkers();
        this.setMarkers(data);
        console.log(data);
      });
    });
  }
}


