<div class="contenedor">    
    <!-- <div class="row">
        <p>Nombre del solicitante:</p>
        <p>{{ nameComplete }}</p>
    </div> -->
    <div class="row mt-5 mb-3">
        <h2 class="">Registro completo</h2>
    </div>
    <div class="row">
        <div class="col cont">
            <button type="button" class="btn btn-primary" (click)="buttonSave()" [disabled]="!formGroupValid">Guardar</button>
        </div>
    </div>      
</div>
