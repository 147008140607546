import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { statusModel } from 'src/app/shared/models/status.model';
import { StatsService } from 'src/app/shared/services/stats.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-general-stadistic',
  templateUrl: './general-stadistic.component.html',
  styleUrls: ['./general-stadistic.component.scss']
})
export class GeneralStadisticComponent implements OnInit {
  chartStatusRequest: any;
  chartStatusRequestLine: any;
  order: any[] = [];
  dataEntitie: any;
  tops: any[] = [];
  stats: statusModel = new statusModel;
  entitie: any;
  requests: any;
  totalBeneficiares: any;
  isLoading = true;
  idUser!: any;
  token!: any;
  idWorkGroup!: any;
  constructor(private statsService: StatsService, private activeRoute: ActivatedRoute, public userService: UserService,
  private router: Router){
    this.idUser = this.activeRoute.snapshot.params.idUser;
    this.idWorkGroup = this.activeRoute.snapshot.params.idWorkGroup;
    this.token = this.activeRoute.snapshot.params.token;
  }

  ngOnInit(): void {

    
    this.activeRoute.params.subscribe(routeParams => {
      localStorage.setItem('token', routeParams['token'])
    });

    this.getTopThemes();
    this.getEntitiesGeneralStatics();
    this.getTotalRequestsCount();
    this.getProfile().then(()=>{
      this.getBeneficiaryByGender();
    })
  }
  async getProfile() {
    const promise = new Promise((resolve, reject) => {
      this.activeRoute.params.subscribe(routeParams => {

        this.userService.getProfile(routeParams['idUser'], routeParams['idWorkGroup']).subscribe(data => {

          this.statsService.profileLevel = data[0].userLevel;

          this.getStatusCount().then(() => {
            console.log('done load');
          });

          resolve(true);

        },
          (error) => { reject(true) }
        )
      });

    });
    return promise;
  }

  getTopThemes() {
    const promise = new Promise((resolve, reject) => {
      this.activeRoute.params.subscribe(routeParams => {
        this.statsService.getTopThemes(routeParams['idWorkGroup']).subscribe(data => {

          this.order = data;
          resolve(true);
          this.order.sort(function (a, b) {
            var keyA = new Date(a.count),
              keyB = new Date(b.count);
            // Compare the 2 dates
            if (keyA < keyB) return 1;
            if (keyA > keyB) return -1;
            return 0;
          });
          console.log(this.order)
          for (let index = 0; index < 5; index++) {
            this.tops.push(this.order[index]);

          }
          console.log(this.tops);
        }
        )
      });
    });
    return promise;
  }
  getEntitiesGeneralStatics() {
    const promise = new Promise((resolve, reject) => {
      this.activeRoute.params.subscribe(routeParams => {
        this.statsService.getEntitiesGeneralStatics(routeParams['idUser']).subscribe(data => {


          this.dataEntitie = data;
          console.log(this.entitie);
          console.log(this.dataEntitie);
          resolve(true);

        }
        )
      });
    });
    return promise;

  }
  getBeneficiaryByGender() {
    const promise = new Promise((resolve, reject) => {
      this.activeRoute.params.subscribe(routeParams => {
        this.statsService.getTotalBeneficiaries(routeParams['idWorkGroup'],this.statsService.profileLevel, this.idUser).subscribe(data => {
          console.log(data)

          this.totalBeneficiares = data.Total;


        }
        )
      });
    });
    return promise;

  }
  getTotalRequestsCount() {
    const promise = new Promise((resolve, reject) => {
      this.activeRoute.params.subscribe(routeParams => {
        this.statsService.getTotalRequestsCount(routeParams['idWorkGroup']).subscribe(data => {
          this.requests = data;
          console.log(this.requests);
          this.StatusRequestChart(); // Llama al método aquí
          resolve(true);
        }
        )
      });
    });
    return promise;
  }


  getStatusCount() {
    const promise = new Promise((resolve, reject) => {
      this.activeRoute.params.subscribe(routeParams => {
        this.statsService.getStatusCount(routeParams['idWorkGroup'], routeParams['idUser']).subscribe(data => {

          this.stats = new statusModel().deserialize(data);;
          resolve(true);

          console.log(this.stats);
        }
        )
      });
    });
    return promise;
  }
  goStatickGoals(){
    this.router.navigate(['requests-themes', this.idUser, this.idWorkGroup, this.token]);
  }

  StatusRequestChart() {
    this.chartStatusRequest = new Chart("ChartStatusRequest", {
      type: 'doughnut',
      data: {
        labels: ['Cerradas', 'Pendientes'],
        datasets: [{
          data: [this.requests.SolicitudesCerradas, this.requests.SolicitudesAbiertas],
          backgroundColor: ['#0e6d0f', '#940000'],
        }]
      },
      plugins: [ChartDataLabels],
      options: {
        aspectRatio: 1.9,
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'bottom'
          },
          datalabels: {
            color: 'white',
            labels: {
              title: {
                font: {
                  weight: 'bold',
                  size: 20
                }
              },
            }
          }
        },
        legend: {
          display: false
        },
        parsing: {
          key: 'nested.value'
        }
      } as { [key: string]: any }
    });

    // Actualiza el tamaño del gráfico cuando cambia el tamaño del contenedor
    window.addEventListener('resize', () => {
      this.chartStatusRequest.resize();
    });
  }






}
