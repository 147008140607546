<div class="contenedor">
    <div class="back btn btn-sm mt-3 ml-3">
        <i class="fa fa-arrow-left fa-lg" style="cursor: pointer;" (click)="goBack()" aria-hidden="true"></i>
    </div>
    <h1 class="page-header">Datos de la propuesta e iniciativa &nbsp;<small></small></h1>
    <hr>
    <div class="row sectionData">
        <div class="col data">
            <u style="text-decoration: none">
                <li>Estatus: <b>{{ getStatusLabel(proposal.idstatus) }}</b></li>
                <li>Asignado a: <b>{{ nameAssigned }}</b></li>
                <li>Fecha de levantamiento: <b>{{proposal.formatted_created_at}}</b></li>
            </u>
        </div>
        <div class="col data">
            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <button *ngIf="proposal.idstatus == 1 || (proposal.idAssigned != idUser && proposal.idstatus != 6)" class="btn btn-secondary btn-inverse mr-1 buttonOptions" (click)="openModal('attend')"><i class="fa fa-hand-pointer"></i> Atender propuesta</button>
                <button *ngIf="proposal.idstatus == 1" class="btn btn-secondary btn-inverse mr-1 buttonOptions" (click)="openModal('assign')"><i class="fa fa-tasks"></i> Asignar propuesta</button>
                <button *ngIf="proposal.idstatus == 2" class="btn btn-secondary btn-inverse mr-1 buttonOptions" (click)="openModal('reassign')"><i class="fa fa-tasks"></i> Resignar propuesta</button>
                <button *ngIf="proposal.idstatus == 2" class="btn btn-secondary btn-inverse mr-1 buttonOptions" (click)="openModal('seg')"><i class="fa fa-hand-pointer"></i> Seguimiento</button>
                <button *ngIf="proposal.idstatus == 5" class="btn btn-secondary btn-inverse mr-1 buttonOptions" (click)="openModal('close')"><i class="fa fa-sync"></i> Cambiar estatus</button>
                <button class="btn btn-secondary mr-1 buttonOptions" (click)="goToUpdate(proposal.idProposal,proposal.idUser)"><i class="fa fa-edit"></i> Editar</button>
            </div>
        </div>
    </div>
    <div class="sectionBody">
        <div class="row tHeader" style="margin: 0 !important; ">
            <div class="col-4 tab" (click)="changeSelected(0)" [ngClass]="{'active': selectedTab == 0}">
                <div class="sectionForm">

                    <span class="numberNotSelected" *ngIf="selectedTab != 0 else one">
                        <div class="fa-solid fa-check"></div>
                    </span>
                    <ng-template #one>
                        <span class="number">
                            1
                        </span>
                    </ng-template>
                    <div>
                        <span class="info">Datos generales </span>
                        <i class="fa fa-arrow-right"></i>
                        <small>Nombre, Dirección, Teléfono...</small>
                    </div>
                </div>
            </div>
            <div class="col-4 tab" (click)="changeSelected(1)" [ngClass]="{'active': selectedTab == 1}">
                <div class="sectionForm">

                    <span class="numberNotSelected" *ngIf="selectedTab != 1 else two">
                        <div class="fa-solid fa-check"></div>
                    </span>
                    <ng-template #two>
                        <span class="number">
                            2
                        </span>
                    </ng-template>
                    <span class="info">Propuesta o iniciativa </span>
                    <i class="fa fa-arrow-right"></i>
                </div>
            </div>
            <div class="col-4 tab" (click)="changeSelected(2)" [ngClass]="{'active': selectedTab == 2}">
                <div class="sectionForm">
                    <span class="numberNotSelected" *ngIf="selectedTab != 2 else third">
                        <div class="fa-solid fa-check"></div>
                    </span>
                    <ng-template #third>
                        <span class="number">
                            3
                        </span>
                    </ng-template>
                    <div>
                        <span class="info">Historial</span>
                        <i class="fa fa-arrow-right"></i>
                        <small>Registro de actividad</small>
                    </div>
                </div>
            </div>
        </div>
        <mat-stepper (selectionChange)="onStepChange($event)" #stepper>
            <mat-step>
                <app-promoter-information></app-promoter-information>
                <div class="footer-form">
                    <button mat-button matStepperNext class="btn btn-primary">Siguiente</button>
                </div>
            </mat-step>
            <mat-step>
                <app-proposal-information></app-proposal-information>
                <div class="footer-form">
                    <button mat-button matStepperNext class="btn btn-primary">Siguiente</button>
                    <button mat-button matStepperPrevious class="btn btn-light mr-2">Anterior</button>
                </div>
            </mat-step>
            <mat-step>
                <app-historial-information [idPromoter]="idPromoter"></app-historial-information>
                <div class="footer-form">
                    <button mat-button matStepperPrevious class="btn btn-light">Anterior</button>
                </div>
            </mat-step>
        </mat-stepper>
    </div>
</div>
<!-- Modal -->
<div class="modal fade" id="attendModal" tabindex="-1" role="dialog" aria-labelledby="attendModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header" style="background: #49B6D6;">
                <h5 class="modal-title" id="attendModalLabel" style="color:black;">{{title}}</h5>
                <button type="button" class="close" (click)="closeModal()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="color:#707478;font-size:14px;">
                <label>Folio de la solicitud:</label>
                <div class="form-control">{{proposal.qrcode}}</div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-white" style="font-size: 14px;" (click)="closeModal()">Cancelar</button>
                <button type="button" class="btn btn-primary" style="background-color: #49B6D6;border-color: #49B6D6;font-size: 14px;" (click)="typeModal == 'attend' ? saveAttend():(typeModal == 'seg' ? saveSeg(): '')">Aceptar</button>
            </div>
        </div>
    </div>
</div>
<!-- Modal -->
<div class="modal fade" id="assignModal" tabindex="-1" role="dialog" aria-labelledby="visitModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header" style="background: #49B6D6;">
                <h5 class="modal-title" id="exampleModalLabel" style="color:black;font-size: 17px;font-weight: 600;">{{title}}</h5>
                <button type="button" class="close" (click)="closeModal()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="color:#707478;font-size:14px;">
                <form [formGroup]="reassignForm">
                    <div>
                        <span style="color: red">* </span>
                        <label class="p-t-2">Usuario:</label>
                        <select class="form-control form-select" formControlName="idAssigned">
                            <option selected value="">Selecciona una opción</option>
                            <option *ngFor="let user of usersOptions" value="{{user.idUser}}">{{user.workgroup}}</option>
                        </select>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-white" style="font-size: 14px;" (click)="closeModal()">Cancelar</button>
                <button type="button" class="btn btn-primary" (click)="saveAssign()" style="background-color: #49B6D6;border-color: #49B6D6;font-size: 14px;">Aceptar</button>
            </div>
        </div>
    </div>
</div>
<!-- Modal -->
<div class="modal fade" id="closeModal" tabindex="-1" role="dialog" aria-labelledby="visitModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header" style="background: #49B6D6;">
                <h5 class="modal-title" id="exampleModalLabel" style="color:black;font-size: 17px;font-weight: 600;">{{title}}</h5>
                <button type="button" class="close" (click)="closeModal()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="color:#707478;font-size:14px;">
                <form [formGroup]="closeForm">
                    <div class="row">
                        <div class="col-auto pr-5">
                            <span style="color: red">* </span>
                            <label class="p-t-2">Estatus:</label>
                        </div>
                        <div class="col">
                            <select class="form-control form-select" formControlName="idstatus">
                                <option selected value="">Selecciona una opción</option>
                                <option selected value="3">No viable</option>
                                <option selected value="4">Cancelado</option>
                                <option selected value="6">Meta cumplida</option>
                            </select>
                        </div>
                    </div>
                    <ng-container *ngIf="closeForm.get('idstatus')?.value">
                        <label class="p-t-2 pt-2">Nota:</label>
                        <textarea id="note" class="form-control" placeholder="Agregar nota del cambio de estatus" formControlName="note"></textarea>    
                    </ng-container>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-white" style="font-size: 14px;" (click)="closeModal()">Cancelar</button>
                <button type="button" class="btn btn-primary" style="background-color: #49B6D6;border-color: #49B6D6;font-size: 14px;" (click)="saveClose()">Cambiar</button>
            </div>
        </div>
    </div>
</div>