import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute } from '@angular/router';
import { CustomeValidationService } from 'src/app/shared/services/custome-validation.service';
import { FormDataService } from 'src/app/shared/services/form-data.service';
import { MapService } from 'src/app/shared/services/map.service';
import { POIService } from 'src/app/shared/services/poi.service';
import { RequestService } from 'src/app/shared/services/request.service';
import { PassDataComponent } from '../../components/modal/pass-data/pass-data.component';
import { MatDialog } from '@angular/material/dialog';
import { categoriesModel } from 'src/app/shared/models/categories.model';
import { subThemesModel } from 'src/app/shared/models/subthemes.model';
import { themesModel } from 'src/app/shared/models/themes.model';
import { PromotedService } from 'src/app/shared/services/promoted.service';
import Swal from 'sweetalert2';
import { HistoryModalComponent } from '../../components/modal/history-modal/history-modal.component';
import { VisitModalComponent } from '../../components/modal/visit-modal/visit-modal.component';
import { UserService } from 'src/app/shared/services/user.service';
import { PeriodService } from 'src/app/shared/services/period.service';

declare var $: any;
@Component({
  selector: 'app-promoted-form',
  templateUrl: './promoted-form.component.html',
  styleUrls: ['./promoted-form.component.scss']
})


export class PromotedFormComponent implements OnInit {
  formGroupValid: boolean = false;

  @ViewChild('stepper') stepper!: MatStepper;

  selectedTab: number = 0;

  sectionOptions: any = [];
  postalCodeSearch: any = [];
  neighborhoodOptions: any[] = [];
  municipalitieName: String = '';
  entitieName: String = '';
  entities: any = '';
  municipalities: any = '';
  postalCodeSelected: boolean = false;
  sectionSelected: boolean = false;

  isReadMore: boolean = false;
  POIoptions: any = [];

  sectionSearch: string = '';

  idWorkGroup: any = '';
  surveyForm!: FormGroup;
  bucketForm!: FormGroup; // at the top of the class component

  themes: Array<themesModel> = [];
  subthemes: Array<any> = [];
  categories: Array<any> = [];
  selectedFiles: any = [];
  loading: boolean = false;
  promotedForm: FormGroup = this.FB.group({
    qrcode: [''],
    name: ['', [Validators.required]],
    next_name: ['', [Validators.required]],
    last_name: ['', [Validators.required]],
    gender: [''],
    birth_date: [''],
    curp: ['', [this.curpValidator.bind(this)]],
    email: ['', [Validators.email]],
    phone: [''],
    cell_phone: ['', [Validators.required]],
    civil_status: [''],
    birth_place: [''],
    birth_placeM: [{ value: '', disabled: true }],
    scholarship: [''],
    occupation: [''],
    workplace: [''],
    cell_phone_office: [''],
    facebook: [''],
    instagram: [''],
    street: [''],
    street_num_ext: [''],
    street_num_int: [''],
    postal_code: [''],
    id_postal_code: [0],
    id_neighborhood: [{ value: '', disabled: true }],
    id_municipality: [0],
    id_entity: [0],
    electoral_section: [''],
    reference: [''],
    id_work_group: [''],
    location: ['0'],
    id_user: [''],
    missing_data_color: ['red'],
    poi_related: 0,
    id_poi: 0,
    participation_type: [''],
    other_type: [''],
    bitacora: [''],
    participation: 0,
    actor: 0,
    actor_type: [''],
    other_actor: [''],
    privacy_notice: ['', [Validators.required]],
    id_lifting: [''],
    position: [''],
    position_type: [''],
    issues: [''],
    age: ['', [Validators.required]],
    elderly: ['', [Validators.required]],
    vote_preference: [''],
    political_rejection: [''],
    political_militant: [''],
    folio_interno: [''],
    period: ['']
  });

  issue1Form: FormGroup = this.FB.group({
    issue: [1, [Validators.required]],
    theme_id: ['', [Validators.required]],
    subtheme_id: ['', [Validators.required]],
    categorie_id: ['', [Validators.required]],
    description: ['', [Validators.maxLength(250)]],

  });

  issue2Form: FormGroup = this.FB.group({
    issue: [2, [Validators.required]],
    theme_id: ['', [Validators.required]],
    subtheme_id: ['', [Validators.required]],
    categorie_id: ['', [Validators.required]],
    description: ['', [Validators.maxLength(250)]],

  });

  issue3Form: FormGroup = this.FB.group({
    issue: [3, [Validators.required]],
    theme_id: ['', [Validators.required]],
    subtheme_id: ['', [Validators.required]],
    categorie_id: ['', [Validators.required]],
    description: ['', [Validators.maxLength(250)]],

  });

  issue4Form: FormGroup = this.FB.group({
    issue: [4, [Validators.required]],
    theme_id: ['', [Validators.required]],
    subtheme_id: ['', [Validators.required]],
    categorie_id: ['', [Validators.required]],
    description: ['', [Validators.maxLength(250)]],

  });

  // Agrupa los formularios individuales en un formulario principal
  mainIssueFormGroup: FormGroup = this.FB.group({
    issue1: this.issue1Form,
    issue2: this.issue2Form,
    issue3: this.issue3Form,
    issue4: this.issue4Form,
  });

  visitForm: FormGroup = this.FB.group({
    id_user: [''],
    id_user_visitor: [0, [Validators.required]],
    id_promoted: [''],
    visit: [''],
    evidence_image: [''],
    date: ['', [Validators.required, this.dateValidator]],
    note: ['', [Validators.required]],
  });

  questions: any = [];
  answers: any = [];
  formID = 1;
  promotedId = 1;
  originalSurvey: any;
  searchValue: any = ['', '', '', '', ''];
  selectedSearch: any = [false, false, false, false, false];
  responseSearch: any = ['', '', '', '', ''];
  usersOptions: any = [];
  idUser: number = 0;
  URLs: any = [];
  surveyTittle: any;

  periodOptions: any = [];

  constructor(private FB: FormBuilder,
    private customizeService: CustomeValidationService,
    private activeRoute: ActivatedRoute,
    private requestService: RequestService,
    private formDataService: FormDataService,
    private POIService: POIService,
    private mapService: MapService,
    private promotedService: PromotedService,
    private userService: UserService,
    private renderer: Renderer2, private el: ElementRef,
    public dialog: MatDialog,
    private periodService: PeriodService) {
    this.attachInputHandlers();
    this.getPeriodOptions();
    this.getThemes();
  }

  getPeriodOptions(){
    this.periodOptions = this.periodService.options;
  }

  getAccountSurveyId() {
    this.formDataService.getAccountSurveyId(this.idWorkGroup).subscribe((data: any) => {
      this.formID = data.form_id;
      this.getSurvey(data.form_id);
    });
  }

  // Método para actualizar un elemento del arreglo en mayúsculas
  onSearchChange(index: number, newValue: string) {
    this.searchValue[index] = newValue.toUpperCase();
  }

  dateValidator(control: any) {
    const date = new Date(control.value);
    const actualDate = new Date();

    if (date < actualDate) {
      return null; // La fecha es válida
    } else {
      return { invalidDate: true }; // La fecha es inválida
    }
  }


  fillAfterCreatePOI() {
    if (this.formDataService.lastForm) {

      console.log(this.formDataService.lastForm.value);
      console.log(this.formDataService.selectedPOI);

      const data = this.formDataService.lastForm.value;
      const form = this.formDataService.lastForm;

      this.promotedForm.patchValue({
        name: data.name,
        next_name: data.next_name,
        last_name: data.last_name,
        gender: data.gender ?? '',
        birthDate: data.birthDate,
        curp: data.curp,
        email: data.email,
        phone: data.phone,
        cell_phone: data.cell_phone,
        civil_status: data.civil_status ?? '',
        birth_place: data.birth_place ?? '',
        birth_placeM: data.birth_placeM ?? '',
        scholarship: data.scholarship ?? '',
        ocupation: data.ocupation ?? '',
        workplace: data.workplace,
        cell_phone_office: data.cell_phone_office,
        facebook: data.facebook,
        instagram: data.instagram,
        street: data.street,
        birth_date: data.birth_date,
        street_num_ext: data.street_num_ext,
        street_num_int: data.street_num_int,
        postal_code: data.postal_code,
        id_postal_code: data.id_postal_code,
        id_neighborhood: data.id_neighborhood ?? '',
        id_municipality: data.id_municipality,
        id_entity: data.id_entity,
        electoral_section: data.electoral_section ?? '',
        reference: data.reference,
        id_work_group: data.id_work_group,
        location: data.location,
        poi_related: 1,
        id_poi: this.formDataService.selectedPOI,
        participation_type: data.participation_type ?? '',
        other_type: data.other_type,
        bitacora: data.bitacora,
        participation: data.participation,
        actor: data.actor,
        actor_type: data.actor_type,
        other_actor: data.other_actor,
        privacy_notice: data.privacy_notice,
        id_lifting: data.id_lifting,
        position: data.position,
        position_type: data.position_type,
        issues: data.issues,
        age: data.age,
        elderly: data.elderly,
        vote_preference: data.vote_preference,
        political_rejection: data.political_rejection,
        political_militant: data.political_militant,
        period: data.period,
      });

      if (data.electoralSection) {
        this.selectSecction(data.electoral_section);
      }

      //buscar el CP y seleccionarlo
      if (data.postal_code) {
        this.getDirectionByPostalCode(data.postal_code, data.id_postal_code);
      }
    }
    if (this.formDataService.lastFormSurvey) {
      this.surveyForm.patchValue(this.formDataService.lastFormSurvey.value);
      console.log(this.surveyForm.value);
    }

    if (this.formDataService.lastFormVisit) {
      this.visitForm.patchValue(this.formDataService.lastFormVisit.value);
      console.log(this.visitForm.value);
      this.selectedFiles = this.formDataService.allUploadablesPromotedVisit;
      console.log(this.selectedFiles);
    }
  }

  onFileChange(event: any) {
    const files = event.target.files;
    // Verificar el límite
    if (this.selectedFiles.length + files.length <= 3) {
      for (let i = 0; i < files.length; i++) {
        this.selectedFiles.push(files[i]);
      }
      console.log(this.selectedFiles);
    } else {
      Swal.fire({
        title: 'Máximo son 3 archivos',
        text: 'Intente de nuevo',
        icon: 'error',
        confirmButtonText: 'Aceptar',
      });
      // Limpia la entrada de archivos para evitar agregar más de 3 archivos.
      event.target.value = '';
      this.selectedFiles = [];
    }
  }


  closeVisitModal() {
    $('#visitModal').modal('hide');
  }

  saveVisit() {
    this.visitForm.controls['id_user'].setValue(this.idUser);
    this.visitForm.controls['visit'].setValue(1);

    if (this.visitForm.get('id_user_visitor')?.value == 0)
      this.visitForm.controls['id_user_visitor'].setValue(this.idUser);

    if (this.visitForm.valid) {
      $('#visitModal').modal('hide');
    } else {
      Swal.fire(
        {
          icon: 'error',
          title: 'No haz llenado todos los campos requeridos!',
          text: '',
          confirmButtonText: 'Aceptar'
        }
      );
    }
  }

  getThemes() {
    this.requestService.getAllThemes().subscribe(data => {
      this.themes = data;
    });
  }

  async getSubthemes(themeId: number, form: FormGroup) {
    const promise = new Promise((resolve, reject) => {
      if (themeId != null) {
        this.requestService.getAllSubthemes(themeId).subscribe(data => {
          this.subthemes[form.controls['issue'].value] = data;
          resolve(true);
        });
      }
    });
    return promise;
  }

  async getCategories(subthemeId: number, form: FormGroup) {
    const promise = new Promise((resolve, reject) => {
      if (subthemeId != null) {
        this.requestService.getAllCategories(subthemeId).subscribe(data => {
          this.categories[form.controls['issue'].value] = data;
          resolve(true);
        });
      }
    });
    return promise;
  }

  openVisitModal() {
    this.getWorkGroupTheme();
    $('#visitModal').modal('show');
  }

  openHistoryModal() {
    $('#historyModal').modal('show');
  }

  onThemeSelectChange(form: FormGroup) {
    form.controls['subtheme_id'].enable();
    this.getSubthemes(form.get('theme_id')?.value, form);
  }

  onSubthemeSelectChange(form: FormGroup) {
    this.getCategories(form.get('subtheme_id')?.value, form);
  }

  searchCategory(issue: number) {
    this.getSearchCategory(this.searchValue[issue], issue);
  }

  getSearchCategory(search: string, issue: number) {
    this.requestService.getSearchCategory(search).subscribe(data => {

      console.log(this.selectedSearch[issue]);
      this.responseSearch[issue] = data;
      this.selectedSearch[issue] = false;
    });
  }

  selectOption(ids: string, form: FormGroup, issue: number) {
    const idsArray = ids.split(',');
    this.selectedSearch[issue] = true;
    form.controls['theme_id'].setValue(idsArray[0]);
    this.getSubthemes(form.get('theme_id')?.value, form).then(() => {
      form.controls['subtheme_id'].setValue(idsArray[1]);
      this.getCategories(form.get('subtheme_id')?.value, form).then(() => {
        form.controls['categorie_id'].setValue(idsArray[2]);
      });
    });
  }

  async getSurvey(survey_id: number) {
    this.surveyForm = this.FB.group({});
    this.formDataService.getSurvey(survey_id).subscribe((data: any) => {
      console.log(data);
      this.surveyTittle = data.name;
      this.originalSurvey = data.form_questions;
      this.questions = data.form_questions;
      this.questions.forEach((question: any) => {
        // Usa tu clase personalizada en lugar de FormControl estándar
        const formControl = new CustomFormControl();
        this.surveyForm.addControl(question.control, formControl);
      });
      setTimeout(() => {
        this.fillAfterCreatePOI();
      }, 3000);
    })
  }

  toggleHidden(question: any) {
    const childElement = document.getElementById(question.id);

    if (question.hidden == 0) {
      if (childElement) {
        childElement.classList.remove('hidden');
      }
    } else {
      if (childElement) {
        childElement.classList.add('hidden');
      }
    }
  }

  handleOption(question: any) {
    const options = question.form_options;
    const selectedOptionValue = this.surveyForm.controls[question.control].value;

    const selectedOption = options.find((item: any) => item.value === selectedOptionValue);
    console.log(selectedOption);

    // Oculta las preguntas hijas si hay
    if (selectedOption && selectedOption.child_question_ids) {
      const childQuestionIds = JSON.parse(selectedOption.child_question_ids);

      childQuestionIds.forEach((questionId: any) => {
        const childElement = document.getElementById(questionId);

        console.log(childElement);

        // Agrega la clase 'hidden' al elemento si existe
        if (childElement) {
          childElement.classList.remove('hidden');
        }
      });



    } else {
      // Oculta las preguntas hijas de otras opciones
      const childOptions = options.filter((item: any) => item.child_question_ids != null);

      childOptions.forEach((child: any) => {
        const childQuestionIds = JSON.parse(child.child_question_ids);

        childQuestionIds.forEach((id: any) => {
          console.log(id);

          const childElement = document.getElementById(id);

          // Agrega la clase 'hidden' al elemento si existe
          if (childElement) {
            childElement.classList.add('hidden');
          }

          // Elimina la respuesta de la pregunta oculta del array de respuestas
          const indexToRemove = this.answers.findIndex((item: any) => item.question_id === id);
          if (indexToRemove !== -1) {
            this.answers.splice(indexToRemove, 1);
          }

        });
      });
    }

    const answer: Answer = {
      form_id: this.formID,
      id_promoted: this.promotedId,
      question_id: question.id,
      value: selectedOptionValue,
      option_id: 0
    };

    const indexToUpdate = this.answers.findIndex((item: any) => item.question_id === question.id);

    // Actualiza o agrega la respuesta según sea necesario
    if (indexToUpdate !== -1) {
      this.answers[indexToUpdate] = answer;
    } else {
      this.answers.push(answer);
    }
  }

  ngOnInit(): void {
    // this.promotedForm.valueChanges.subscribe(data => {
    //   console.log(data)
    // })
    this.getUserAuth();
    this.getEntities();
    this.getPOIs();
  }

  getWorkGroupTheme() {
    this.userService.getWorkGroupTheme(this.idUser, this.idWorkGroup, 0).subscribe((data) => {
      this.usersOptions = data;
    });
  }

  getUserAuth() {
    this.activeRoute.params.subscribe(routeParams => {
      localStorage.setItem('token', routeParams['token']);
      localStorage.setItem('environment', routeParams['environment']);
      localStorage.setItem('idWorkGroup', routeParams['idWorkGroup']);
      this.idWorkGroup = routeParams['idWorkGroup'];
      this.idUser = routeParams['idUser'];
      this.promotedForm.controls['id_user'].setValue(routeParams['idUser']);
      this.promotedForm.controls['id_work_group'].setValue(routeParams['idWorkGroup']);
      this.promotedForm.controls['qrcode'].setValue(this.generateRandomString());
      this.getAccountSurveyId();

    });
  }

  openModal() {
    $('#exampleModal').modal('show');
  }

  closeModal() {
    $('#exampleModal').modal('hide');
  }

  toggleText() {
    this.isReadMore = !this.isReadMore;
  }

  ngAfterViewInit() {
    const button = $(".more");
    button.on("click", () => {
      // Cambiar la visibilidad de complete
      $(".complete").toggle();
    });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(PassDataComponent, {
      data: {
        form: this.promotedForm,
        survey: this.surveyForm,
        visit: this.visitForm,
        idUser: this.activeRoute.snapshot.paramMap.get('idUser'),
        idWorkGroup: this.activeRoute.snapshot.paramMap.get('idWorkGroup'),
        token: this.activeRoute.snapshot.paramMap.get('token'),
        environment: this.activeRoute.snapshot.paramMap.get('environment'),
        from: 'promoted'
      },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      console.log('The dialog was closed', result);
      //this.animal = result;
    });
    if (this.selectedFiles.length > 0) {
      console.log("Entra");
      this.formDataService.setPromotedVisitEvidence(this.selectedFiles);
    }
  }

  generateRandomString(): string {
    const length = 5;
    const characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const charactersLength = characters.length;
    let randomString = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charactersLength);
      randomString += characters.charAt(randomIndex);
    }
    return randomString;
  }

  attachInputHandlers() {
    const nameFields = ['name', 'next_name', 'last_name'];
    const uppercaseFields = ['curp', 'workplace', 'type_support', 'street', 'reference', 'bitacora', 'other_type', 'other_actor'];
    const numberFields = ['phone', 'cell_phone', 'cell_phone_office', 'postal_code'];
    uppercaseFields.forEach(fieldName => {
      const fieldControl = this.promotedForm.get(fieldName);
      if (fieldControl) {
        fieldControl.valueChanges.subscribe((newValue: string) => {
          if (newValue) {
            this.promotedForm.get(fieldName)?.setValue(newValue.toUpperCase(), { emitEvent: false });
          }
        });
      }
    });
    nameFields.forEach(fieldName => {
      const fieldControl = this.promotedForm.get(fieldName);
      if (fieldControl) {
        fieldControl.valueChanges.subscribe((newValue: string) => {
          if (newValue) {
            this.promotedForm.get(fieldName)?.setValue(newValue.toUpperCase().replace(/[^A-Z\sÑ]/g, ''), { emitEvent: false });
          }
        });
      }
    });
    numberFields.forEach(fieldName => {
      const fieldControl = this.promotedForm.get(fieldName);
      if (fieldControl) {
        fieldControl.valueChanges.subscribe((newValue: string) => {
          if (newValue) {
            this.promotedForm.get(fieldName)?.setValue(newValue.replace(/\D/g, ''), { emitEvent: false });
          }
        });
      }
    });
  }

  getEntities(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.requestService.getEntities().subscribe(
        (data: any) => {
          this.entities = data;
          resolve(data); // Resolvemos la promesa con los datos
        },
        (error) => {
          reject(error); // Rechazamos la promesa en caso de error
        }
      );
    });
  }

  getMunicipalities(idEntity: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.requestService.getMunicipalities(idEntity).subscribe(
        (data: any) => {
          this.municipalities = data;
          resolve(data); // Resolvemos la promesa con los datos
        },
        (error) => {
          reject(error); // Rechazamos la promesa en caso de error
        }
      );
    });
  }

  async onFirstSelectChange() {
    this.promotedForm.controls['birth_placeM'].enable();
    try {
      const idEntity = this.promotedForm.get('birth_place')?.value;
      await this.getMunicipalities(idEntity);
      // Aquí puedes continuar con cualquier lógica que dependa de las municipalities
    } catch (error) {
      console.error('Error al obtener las municipalities:', error);
    }
  }

  clearPostalCode() {
    this.postalCodeSelected = false;
    this.postalCodeSearch = [];
    this.promotedForm.controls['postal_code'].reset();
  }

  searchPostalCode() {
    this.requestService.searchPostalCode(this.promotedForm.controls['postal_code'].value).subscribe((data: any) => {
      this.postalCodeSelected = false;
      this.postalCodeSearch = data;
    });
  }

  selectPostalCode(postalCode: String, id_postal_code: number) {
    this.getDirectionByPostalCode(postalCode, id_postal_code);
  }

  getDirectionByPostalCode(postalCode: String, id_postal_code: number) {
    const idPC = this.postalCodeSearch.find((option: any) => option.identifier == postalCode);

    this.promotedForm.controls['postal_code'].setValue(postalCode);
    this.promotedForm.controls['id_municipality'].reset();
    this.promotedForm.controls['id_entity'].reset();

    this.requestService.getDirectionByPostalCode(postalCode).subscribe(
      (data: any) => {
        this.postalCodeSelected = true;

        this.municipalitieName = data.municipality.name;
        this.promotedForm.controls['id_municipality'].setValue(data.municipality.id);

        this.entitieName = data.entity.name;
        this.promotedForm.controls['id_entity'].setValue(data.entity.id);
        this.getSecctions(data.municipality.identifier, data.entity.identifier);

        // Clear previous neighborhood options
        this.neighborhoodOptions = [];

        if (data.neighborhoods.length > 1) {
          this.promotedForm.controls['id_neighborhood'].enable();
          this.neighborhoodOptions = data.neighborhoods;
        } else {
          this.promotedForm.controls['id_neighborhood'].enable();
          this.neighborhoodOptions = data.neighborhoods;
          this.promotedForm.controls['id_neighborhood'].setValue(data.neighborhoods[0].id);
        }
      },
      (error) => {
        console.error(error); // Maneja el error de manera adecuada
      }
    );
    this.promotedForm.controls['id_postal_code'].setValue(id_postal_code);
  }

  getSecctions(idMunicipality: number, idEntity: number) {

    if (this.idWorkGroup == '95131a90d2da7ce01cac1eeed6da7cf0') {
      this.mapService.getSectionsByUser(this.idUser).subscribe((data: any) => {
        console.log(data);
        this.sectionOptions = data;
      });
    } else {
      this.mapService.getSections(idMunicipality, idEntity).subscribe((data: any) => {
        console.log(data);
        this.sectionOptions = data;
      });
    }
  }

  getSectionsByUser(idUser: number) {
    this.mapService.getSectionsByUser(idUser).subscribe((data: any) => {
      console.log(data);
      this.sectionOptions = data;
    })
  }

  selectSecction(Secction: String) {
    this.sectionSelected = true;
    this.promotedForm.controls['electoral_section'].setValue(Secction);
  }

  clearSection() {
    this.sectionSelected = false;
    this.promotedForm.controls['electoral_section'].reset();
  }

  getPOIs() {
    return new Promise((resolve, reject) => {
      this.POIService.getPOIOptions().subscribe(
        (data: any) => {
          this.POIoptions = data;
          resolve(data); // Resolvemos la promesa con los datos
        },
        (error) => {
          reject(error); // Rechazamos la promesa en caso de error
        }
      );
    });
  }

  updateCheckbox(event: Event) {
    if (event && event.target instanceof HTMLInputElement) {
      this.promotedForm.get('privacy_notice')?.setValue(event.target.checked ? 1 : null);
    }
  }

  curpValidator(control: FormControl): { [key: string]: any } | null {
    const curp = control.value;
    if (curp?.length != 0 && curp != null) {
      const isValid = this.customizeService.validCurp(curp);

      if (isValid) {
        return null; // La CURP es válida
      } else {
        return { 'invalidCurp': true }; // La CURP es inválida
      }
    } else {
      return null;
    }
  }

  cleanForm() {
    const excludedVal = {
      qrcode: this.promotedForm.get('qrcode')?.value,
      idUser: this.promotedForm.get('id_user')?.value,
      idWorkGroup: this.promotedForm.get('id_work_group')?.value,
      missing_data_color: this.promotedForm.get('missing_data_color')?.value,
    };
    this.promotedForm.reset(excludedVal);
    this.promotedForm.patchValue({
      gender: [''],
      age: [''],
      civil_status: [''],
      birth_place: [''],
      birth_placeM: [''],
      scholarship: [''],
      occupation: [''],
      id_neighborhood: [''],
      id_lifting: [''],
      poi_related: 0,
      id_poi: 0,
      participation: 0,
      participation_type: [''],
      other_type: [''],
      actor: 0,
      actor_type: [''],
      other_actor: [''],
      position: 0,
      position_type: [''],
      elderly: [''],
      vote_preference: [''],
      political_rejection: [''],
      political_militant: [''],

    });
  }

  onStepChange(event: any) {
    this.selectedTab = event.selectedIndex;
  }
  changeValues() {
    if (this.promotedForm.get('position')?.value == 'POSITIVO' || this.promotedForm.get('position')?.value == 'NEUTRO') {
      this.promotedForm.controls['participation'].setValue(1);
    } else {
      this.promotedForm.controls['participation'].setValue(0);
    }
  }


  changeSelected(index: number) {
    const isFormValid = this.promotedForm.valid;
    if (isFormValid === true) {
      this.stepper.selectedIndex = index;
    } else {

      Swal.fire({
        icon: 'error',
        title: '¡No has llenado todos los campos requeridos!',
        text: this.promotedForm.controls['cell_phone'].invalid
          ? 'Asegúrate de registrar los campos obligatorios y/o diez dígitos en el teléfono'
          : this.promotedForm.controls['curp'].invalid
            ? 'Asegúrate de que la CURP que se esté ingresando sea correcta'
            : '',
        confirmButtonText: 'Aceptar'
      });
    }

  }

  saveHistory(id_promoted: number) {
    const historyData = {
      id_promoted: id_promoted,
      id_user: this.idUser,
      message: 'Ha dado de alta al promovido desde Web.',
      date: new Date()
    };
    this.promotedService.saveHistory(historyData).subscribe(data => {
      const parentURL = this.customizeService.getUrl();
      this.loading = false;
      alert('Tu registro ha sido actualizada con éxito');

      window.parent.location.href = parentURL + '/PromotedPromoted/listPromoted.html';
    });
  }

  buttonSave() {
    console.log(this.answers);
    console.log(this.mainIssueFormGroup);
    this.loading = true;
    this.promotedForm.controls['issues'].setValue(this.mainIssueFormGroup.value);


    this.promotedService.savePromoted(this.promotedForm.value).subscribe((data: any) => {
      console.log(data);

      this.visitForm.controls['id_promoted'].setValue(data.id_promoted);

      console.log(this.visitForm.value);

      if (this.visitForm.valid) {
        this.uploadImage('visitPromoted', 'image', data.id_promoted).then(async (data: any) => {
          let image = this.URLs.length == 0 ? '' : this.URLs;
          this.visitForm.get('evidence_image')?.patchValue(image);
          this.promotedService.saveVisit(this.visitForm.value).subscribe((data: any) => {
            console.log(data);
          });
        });
      }

      this.saveHistory(data.id_promoted);

      if (this.answers.length > 0) {
        this.answers.forEach((answer: any) => {
          answer.id_promoted = data.id_promoted;
        });

        this.formDataService.storeAnswers(this.answers).subscribe((data: any) => {
          console.log(data);
        });
      }

    });
  }

  uploadImage(module: string, type: string, id: number) {
    if (this.selectedFiles) {
      this.loaderAlert();
      return new Promise((resolve, reject) => {
        const uploadPromises = [];
        console.log(this.selectedFiles);

        for (const file of this.selectedFiles) {
          console.log(file);

          const uploadPromise = new Promise((innerResolve, innerReject) => {
            this.requestService.uploadImage(file, module, type, id).subscribe(
              (data: any) => {
                console.log(data);
                this.URLs.push(data.path);
                innerResolve(data);
              },
              (error) => {
                innerReject(error);
              }
            );
          });

          uploadPromises.push(uploadPromise);
        }

        Promise.all(uploadPromises)
          .then((data) => {
            // Todas las imágenes se han cargado con éxito
            Swal.close();
            this.succesAlert();

            resolve(data);
          })
          .catch((error) => {
            // Al menos una carga de imagen falló
            Swal.close();
            this.errorAlert();

            reject(error);
          });
      });
    } else {
      return new Promise((resolve) => {
        resolve([]);
      })
    }
  }

  succesAlert() {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
    Toast.fire({
      icon: 'success',
      title: "Archivos subidos",
    })
  }

  errorAlert() {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
    Toast.fire({
      icon: 'error',
      title: "Error al subir archivos",
    })
  }

  loaderAlert() {
    Swal.fire({
      icon: 'warning',
      title: "Subiendo archivos",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton())
      },
      willClose: () => {

      }
    }).then((result) => {

    })
  }
}

export class CustomFormControl extends FormControl {
  filterMode?: string;
}

interface Answer {
  form_id: number;
  question_id: number;
  option_id: number;
  id_promoted: number;
  value: string;
}

interface FormData {
  answers: Answer[];
}
