import { Component, OnInit } from '@angular/core';
import {Chart} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { bottom } from '@popperjs/core';

import {ActivatedRoute, Router} from '@angular/router';
import { StatsService } from 'src/app/shared/services/stats.service';
import { statusModel } from 'src/app/shared/models/status.model';
import { themeStatsModel } from 'src/app/shared/models/themeStats.model';

@Component({
  selector: 'app-type-of-applicants-by-gender-and-type-of-beneficiary-by-gender',
  templateUrl: './type-of-applicants-by-gender-and-type-of-beneficiary-by-gender.component.html',
  styleUrls: ['./type-of-applicants-by-gender-and-type-of-beneficiary-by-gender.component.scss']
})
export class TypeOfApplicantsByGenderAndTypeOfBeneficiaryByGenderComponent implements OnInit {
  chartTotalRequest: any;
  chartStatusRequest: any;
  chartData: any;
  theme!: any [];
  stock1!: any [];
  stock2!: any [];
  masculino: any;
  femenino: any;
  otro: any;
  indefinido: any;
  masculinoB: any;
  femeninoB: any;
  otroB: any;
  indefinidoB: any;
  labels: any[]=[];
  color1 = ['#70319f'];
  color2= ['#fde69a'];
  grafico: any;
  constructor(private router:Router, private statsService: StatsService, private activeRoute: ActivatedRoute){

  }
  ngOnInit(): void {
    this.activeRoute.params.subscribe(routeParams => {
      localStorage.setItem('token', routeParams['token'])
    });
    this.loadData();
  }
  loadData(){
    this.getApplicantByGender().then(data=>{
      this.StatusRequestChart();
    })
    this.getBeneficiaryByGender().then(data=>{
      this.totalRequestChart();
    })

  }
  getBeneficiaryByGender(){
    const promise = new Promise((resolve, reject) => {
      this.activeRoute.params.subscribe(routeParams => {
      this.statsService.getBeneficiaryByGender(routeParams['idWorkGroup']).subscribe(data => {
        console.log(data)


        this.femeninoB = data.Femenino;
        this.masculinoB = data.Masculino;
        this.otroB = data.Otro;
        this.indefinidoB = data.Indefinido;
        console.log(this.femenino);
        console.log(this.masculino);
        console.log(this.otro);
        resolve(true);

      }
      )
    });
  });
    return promise;

  }
  getApplicantByGender(){
    const promise = new Promise((resolve, reject) => {
      this.activeRoute.params.subscribe(routeParams => {
      this.statsService.getApplicantByGender(routeParams['idWorkGroup']).subscribe(data => {
        console.log(data)

        this.femenino = data.Femenino;
        this.masculino = data.Masculino;
        this.otro = data.Otro;
        this.indefinido = data.Indefinido;

        console.log(this.femeninoB);
        console.log(this.masculinoB);
        console.log(this.otroB);
        resolve(true);

      }
      )
    });
  });
    return promise;
  }

  StatusRequestChart(){

    this.chartStatusRequest = new Chart("ChartStatusRequest", {
      type: 'doughnut',

      data: {
        labels:['Masculino','Femenino', 'Otro', 'Sin información'],
	      datasets: [
          {
            data:[this.masculino,this.femenino,this.otro,this.indefinido],
            backgroundColor: ['#00afef','#f32af3', '#959595','#ffbf00'],
          },
        ]
      },
      plugins: [ChartDataLabels],
      options: {
        aspectRatio:1.4,
        plugins: {
          legend: {
              position: 'top'
          },

          datalabels: {
            color: 'white',
            labels: {
              title: {
                font: {
                  weight: 'bold',
                  size: 20
                }
              },

            }
          }

      },
      parsing: {
        key: 'nested.value'
      }

      }
    });
  }
  totalRequestChart(){

    this.chartTotalRequest = new Chart("ChartTotalRequest", {
      type: 'doughnut',
      data: {
        labels:['Masculino','Femenino', 'Otro', 'Sin información'],
	      datasets: [
          {
            data:[this.masculinoB,this.femeninoB,this.otroB,this.indefinidoB],
            backgroundColor: ['#00afef','#f32af3', '#959595','#ffbf00'],
          },
        ]
      },
      plugins: [ChartDataLabels],

      options: {

        aspectRatio:1.4,
        parsing: {
          key: 'nested.value'
        },
        plugins: {

          legend: {
              position: 'top'
          },
          datalabels: {
            color: 'white',
            labels: {
              title: {
                font: {
                  weight: 'bold',
                  size: 20
                }
              },

            }
          }


      },

      }
    });
  }

}
