<br />

<div class="parent">
  <div class="div1">
    <div class="tittle">
      <h2>Análisis de solicitudes por:</h2>
      <p>(Dar click en cada rubro para visualizar un análisis pormenorizado)</p>
    </div>
    <div class="parent">
      <div class="div3">
        <br />
        <h1
          (click)="closedRequestsAndPendingRequestsByYear()"
          [ngClass]="{
            'text-primary':
              componentName === 'closed-requests-and-pending-requests-by-year'
          }"
        >
          <i class="fa-regular fa-circle-dot"> </i>
        </h1>
      </div>
      <div class="div5">
        <h1
          (click)="closedRequestsAndPendingRequestsByThemeAndYear()"
          [ngClass]="{
            'text-primary':
              componentName ===
              'closed-requests-and-pending-requests-by-theme-and-year'
          }"
        >
          <i class="fa-regular fa-circle-dot"> </i>
        </h1>
      </div>
      <div class="div7">
        <h1
          (click)="beneficiaryStatistics()"
          [ngClass]="{
            'text-primary': componentName === 'beneficiary-Statistics'
          }"
        >
          <i class="fa-regular fa-circle-dot"> </i>
        </h1>
      </div>
      <div class="div9">
        <h1
          (click)="applicantsByGender()"
          [ngClass]="{
            'text-primary': componentName === 'applicants-by-gender'
          }"
        >
          <i class="fa-regular fa-circle-dot"> </i>
        </h1>
      </div>
      <div class="div11">
        <h1
          (click)="applicantsByGroupVulnerable()"
          [ngClass]="{
            'text-primary': componentName === 'applicants-by-group-vulnerable'
          }"
        >
          <i class="fa-regular fa-circle-dot"> </i>
        </h1>
      </div>
      <div class="div13">
        <h1
          (click)="applicantsByOld()"
          [ngClass]="{
            'text-primary': componentName === 'applicants-by-Old'
          }"
        >
          <i class="fa-regular fa-circle-dot"> </i>
        </h1>
      </div>

      <div class="div4">
        <br />
        <h3>Solicitudes cerradas y solicitudes pendientes</h3>
      </div>
      <div class="div6">
        <h3>Solicitudes por tema</h3>
      </div>
      <div class="div8">
        <h3>Beneficiarios</h3>
      </div>
      <div class="div10">
        <h3>Solicitudes levantadas por sexo</h3>
      </div>
      <div class="div12">
        <h3>Solicitudes levantadas por grupo vulnerable</h3>
      </div>
      <div class="div14">
        <h3>Solicitudes levantadas por grupo de edad</h3>
      </div>

    </div>
  </div>
  <div class="div2">
    <ng-container [ngSwitch]="componentName">
      <h1 *ngSwitchCase="'closed-requests-and-pending-requests-by-year'">
        Histórico anual
      </h1>

      <app-applicants-group-vulnerable-months *ngSwitchCase="'applicants-group-vulnerable-months'">

      </app-applicants-group-vulnerable-months>

      <app-closed-requests-and-pending-requests-by-year
        *ngSwitchCase="'closed-requests-and-pending-requests-by-year'"
      >
      </app-closed-requests-and-pending-requests-by-year>

      <button *ngIf="showClosedRequestsAndPendingRequests" (click)="closedRequestsAndPendingRequestsByMonth()">
        Historico mensual
      </button>


      <h1 *ngSwitchCase="'closed-requests-and-pending-requests-by-month'">
        Historico mensual
      </h1>
      <app-closed-requests-and-pending-requests-by-month *ngSwitchCase="'closed-requests-and-pending-requests-by-month'">
      </app-closed-requests-and-pending-requests-by-month>
      <app-closed-requests-and-pending-requests-by-theme-and-year
        *ngSwitchCase="'closed-requests-and-pending-requests-by-theme-and-year'"
      >
      </app-closed-requests-and-pending-requests-by-theme-and-year>
      <app-beneficiary-statistics *ngSwitchCase="'beneficiary-Statistics'">
      </app-beneficiary-statistics>
      <button *ngIf="showBeneficiaryStatistics" (click)="beneficiaryMonths()">
        Historico mensual
      </button>
      <h1 *ngSwitchCase="'beneficiary-Months'">
        Historico mensual
      </h1>
      <app-beneficiary-months
      *ngSwitchCase="'beneficiary-Months'">
      </app-beneficiary-months>
      <app-applicants-by-gender
        *ngSwitchCase="'applicants-by-gender'"
      ></app-applicants-by-gender>

      <button *ngIf="showApplicantsByGender" (click)="applicantsByGenderMonths()">
        Historico mensual
      </button>
      <h1 *ngSwitchCase="'applicants-by-gender-months'">
        Historico mensual
      </h1>
      <app-applicants-gender-months *ngSwitchCase="'applicants-by-gender-months'">

      </app-applicants-gender-months>

      <app-applicants-by-group-vulnerable
        *ngSwitchCase="'applicants-by-group-vulnerable'"
      >
      </app-applicants-by-group-vulnerable>

      <button *ngIf="showApplicantsByGroupVulnerable" (click)="applicantsByGroupVulnerableMonths()">
        Historico mensual
      </button>

      <app-applicants-by-group-old *ngSwitchCase="'applicants-by-Old'">
      </app-applicants-by-group-old>
      <button *ngIf="showApplicantsByGroupOld" (click)="applicantsgroupoldmonths()">
        Historico mensual
      </button>
      <app-applicants-group-old-months *ngSwitchCase="'applicants-group-old-months'">

      </app-applicants-group-old-months>

    </ng-container>
  </div>
</div>
