import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { StatsService } from 'src/app/shared/services/stats.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-beneficiary-months',
  templateUrl: './beneficiary-months.component.html',
  styleUrls: ['./beneficiary-months.component.scss']
})
export class BeneficiaryMonthsComponent implements OnInit {
public chartStatus: any;
  requestsLast: any;
  requests: any;
  public loading = true;
  id!: any;
  month!: any;
  idUser!: any;
  token!: any;
  idWorkGroup!: any;

  currentYear = new Date().getFullYear();
  lastYear = this.currentYear - 1;
  constructor(private statsService: StatsService, private activeRoute: ActivatedRoute, private router: Router) {
    this.idUser = this.activeRoute.snapshot.params.idUser;
    this.idWorkGroup = this.activeRoute.snapshot.params.idWorkGroup;
    this.token = this.activeRoute.snapshot.params.token;

  }

  ngOnInit(): void {
    this.loading = true;

    Promise.all([this.getCountByMonths()])
      .then(() => {

        this.StatusRequestChart();
        this.loading = false;
      })
      .catch(error => {
        console.error('Error while loading data:', error);
        this.loading = false; // asegurarse de quitar el loader en caso de error
      });
  }
  getCountByMonths() {
    const promise = new Promise((resolve, reject) => {
      this.activeRoute.params.subscribe(routeParams => {
        this.statsService.getTotalBeneficiariesByMonth(routeParams['idWorkGroup']).subscribe(data => {
          console.log(data);
          this.requests = data;
          this.id = data;
          console.log(this.requests);
          resolve(true);
        }
        )
      });
    });
    return promise;
  }
  StatusRequestChart() {
    // Crea los arrays para los datos de solicitudes cerradas y pendientes
    const Total = new Array(12).fill(0);
    const completedGoals = new Array(12).fill(0);

    // Itera sobre los objetos del array de datos y agrega los datos correspondientes en los arrays
    for (let i = 0; i < this.requests.length; i++) {
      const monthIndex = parseInt(this.requests[i].monthId) - 1;
      Total[monthIndex] = this.requests[i].Total;
      completedGoals[monthIndex] = this.requests[i].completedGoals;
    }
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const monthLabels = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'].slice(0, currentMonth + 1); // slice() para obtener los meses hasta el mes actual
    const maxValue = Math.max(this.requests.Total, this.requests.completedGoals) + 20;
    // Crea el gráfico con los datos
    this.chartStatus = new Chart("ChartStatus", {
      type: 'bar',
      data: {
        labels: monthLabels,
        datasets: [
          {
            data: Total,
            backgroundColor: ['#0e6d0f'],
            label: 'Beneficiarios',
          },
          {
            data: completedGoals,
            backgroundColor: ['#940000'],
            label: 'Metas cumplidas',
          }
        ],
      },
      plugins: [ChartDataLabels],
      options: {
        indexAxis: 'y',
        aspectRatio: 2.5,
        plugins: {
          legend: {
            position: 'bottom'
          },
          datalabels: {
            color: 'black',
            anchor: 'end',
            align: 'end',
            labels: {
              title: {
                font: {
                  weight: 'bold',
                  size: 17,
                }
              },
            }
          }
        },
        scales: {
          x: {
            min: 0, // Valor mínimo deseado en el eje x
            max: 55, // Valor máximo deseado en el eje x
          }
        },
        onClick: (e, elements) => {
          console.log(elements[0].index);
          const themeIndex = elements[0].index;
          const themeId = this.id[themeIndex].monthId;
          console.log(themeId);
          this.router.navigate(['historical-theme-month', this.idUser, this.idWorkGroup, this.token, themeId]);
        },
      }
    });
  }


}
