import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { StatsService } from 'src/app/shared/services/stats.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  chartTotalRequest: any;
  chartStatusRequest: any;
  requestsLast: any;
  requests: any;
  closedRequest = 0;
  pendingRequest = 0;
  beneficiarys = 0;
  public loading = true;

  currentYear = new Date().getFullYear();
  lastYear = this.currentYear - 1;
  constructor(private statsService: StatsService, private activeRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.loading = true;
    this.activeRoute.params.subscribe(routeParams => {
      localStorage.setItem('token', routeParams['token'])
    });
    Promise.all([this.getThemesCount(), this.getTotalBeneficiaries()])
      .then(() => {

        this.StatusRequestChart();
        this.totalRequestChart();
        this.loading = false;
      })
      .catch(error => {
        console.error('Error while loading data:', error);
        this.loading = false; // asegurarse de quitar el loader en caso de error
      });
  }
  getThemesCount() {
    const promise = new Promise((resolve, reject) => {
      this.activeRoute.params.subscribe(routeParams => {
        this.statsService.getTotalRequestsCount(routeParams['idWorkGroup']).subscribe(data => {
          console.log(data);
          this.requestsLast = data;
          console.log(this.requestsLast);
          this.closedRequest = data.SolicitudesCerradas;
          this.pendingRequest = data.SolicitudesAbiertas;
          resolve(true);
        }
        )
      });
    });
    return promise;
  }
  getTotalBeneficiaries() {
    const promise = new Promise((resolve, reject) => {
      this.activeRoute.params.subscribe(routeParams => {
        this.statsService.getTotalBeneficiaries(routeParams['idWorkGroup'],routeParams['level'], routeParams['idUser']).subscribe(data => {
          console.log(data);
          this.requests = data;
          this.beneficiarys = data.Total;
          console.log(this.requests);
          resolve(true);
        }
        )
      });
    });
    return promise;
  }
  totalRequestChart() {

    this.chartStatusRequest = new Chart("ChartTotalRequest", {
      type: 'doughnut',

      data: {
        labels: ['Cerradas', 'Pendientes'],
        datasets: [
          {
            data: [this.requestsLast.SolicitudesAbiertas, this.requestsLast.SolicitudesCerradas],
            backgroundColor: ['green', 'red'],
          },
        ]
      },
      plugins: [ChartDataLabels],
      options: {
        aspectRatio: 1.3,
        plugins: {
          legend: {
            position: 'bottom'
          },

          datalabels: {
            color: 'white',
            labels: {
              title: {
                font: {
                  weight: 'bold',
                  size: 20
                }
              },

            }
          }

        },
        parsing: {
          key: 'nested.value'
        }

      }
    });
  }

  StatusRequestChart() {
    this.chartStatusRequest = new Chart("ChartStatusRequest", {
      type: 'bar',
      data: {
        labels: ['Registro', 'Asignado', 'En proceso', 'Meta Cumplida', 'Cancelado', 'No viable'],
        datasets: [
          {
            data: [85, 66, 12, 65, 7, 5],
            backgroundColor: ['#f59c1a', '#32a932', '#348fe2', '#8753de', '#ff5b57', '#707478'],
          },
        ]
      },
      options: {
        indexAxis: 'y',
      }
    });
  }
}
