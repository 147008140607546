import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-beneficiary-month',
  templateUrl: './beneficiary-month.component.html',
  styleUrls: ['./beneficiary-month.component.scss']
})
export class BeneficiaryMonthComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
