import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PanelControlService {
  url = environment.apiUrl;

  constructor(public http: HttpClient) { 
  }

  getPanelControl(userId: number) {
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return this.http.get<any>(this.url + 'getPanelControl/' + userId, options);
  }

  updatePanelControl(userId: number, panelControlData:any){
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return this.http.put<any>(this.url + 'updatePanelControl/' + userId, panelControlData, options);
  }

}
