import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PlayerComponent } from 'src/app/pages/components/modal/player/player.component';
import { CustomeValidationService } from 'src/app/shared/services/custome-validation.service';
import { FormDataService } from 'src/app/shared/services/form-data.service';
import { RequestService } from 'src/app/shared/services/request.service';

@Component({
  selector: 'app-detail-request-documentation',
  templateUrl: './detail-request-documentation.component.html',
  styleUrls: ['./detail-request-documentation.component.scss']
})
export class DetailRequestDocumentationComponent implements OnInit {

  request: any = [];
  urlSP: any;
  urlIne: any;
  urlAcuse: any;
  urlsExtra: any;

  constructor(private formDataService: FormDataService, private CustomeValidationService: CustomeValidationService, private requestService: RequestService, public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.getRequestData();
  }

  getRequestData() {
    this.formDataService.requestDataResults$.subscribe(data => {
      this.request = data;

      this.urlSP = this.CustomeValidationService.checkForServerLocation(data.url_image_sp);
      this.urlIne = this.CustomeValidationService.checkForServerLocation(data.url_image_ine)
      this.urlAcuse = this.CustomeValidationService.checkForServerLocation(data.url_image_Acuse)
      this.urlsExtra = this.CustomeValidationService.checkForServerLocation(data.url_image_Extra)

    });
  }



  openLink(url: string) {
    window.open(url, '_blank');
  }

  getFileName(url: string): string {
    const parts = url.split('/');
    return decodeURIComponent(parts[parts.length - 1]);
  }

  openFile(url: string) {
    if (/\.pdf$/i.test(url)) {
      // Es un archivo PDF, abrir en una nueva ventana
      window.open(url, '_blank');
    } else {
      // No es un archivo PDF, realizar alguna acción o manejar según sea necesario
      let dialogRef = this.dialog.open(PlayerComponent, {
        data: { selectedMedia: url },
        maxHeight: '80%',
        maxWidth: '80%',
        panelClass: 'custom-dialog-container', // Agregar una clase CSS personalizada

      });
      dialogRef.afterClosed().subscribe(result => {

      });
    }
  }
}
