<div class="contenedor">
    <ng-container *ngIf="idProposal == null; else update">
    </ng-container>
    <ng-template #update>
        <div class="back btn btn-sm ml-3 mt-3">
            <i class="fa fa-arrow-left fa-lg" style="cursor: pointer;" (click)="goBack()" aria-hidden="true"></i>
        </div>
    </ng-template>
    <h1 class="page-header">Datos del promovente &nbsp;<small></small></h1>
    <hr>
    <div class="sectionBody">
        <div class="row tHeader" style="margin: 0 !important; ">
            <div class="col-4 tab" (click)="changeSelected(0)" [ngClass]="{'active': selectedTab == 0}">
                <div class="sectionForm">
                    <span class="numberNotSelected" *ngIf="selectedTab != 0 else one">
                        <div class="fa-solid fa-check"></div>
                    </span>
                    <ng-template #one>
                        <span class="number">
                            1
                        </span>
                    </ng-template>
                    <div>
                        <span class="info">Datos generales </span>
                        <i class="fa fa-arrow-right"></i>
                        <small>Nombre, Dirección, Teléfono...</small>
                    </div>
                </div>
            </div>
            <div class="col-4 tab" (click)="changeSelected(1)" [ngClass]="{'active': selectedTab == 1}">
                <div class="sectionForm">

                    <span class="numberNotSelected" *ngIf="selectedTab != 1 else second">
                        <div class="fa-solid fa-check"></div>
                    </span>
                    <ng-template #second>
                        <span class="number">
                            2
                        </span>
                    </ng-template>
                    <span class="info">Propuesta o iniciativa </span>
                    <i class="fa fa-arrow-right"></i>
                </div>
            </div>
            <div class="col-4 tab" (click)="changeSelected(2)" [ngClass]="{'active': selectedTab == 2}">
                <div class="sectionForm">
                    <span class="numberNotSelected" *ngIf="selectedTab != 2 else third">
                        <div class="fa-solid fa-check"></div>
                    </span>
                    <ng-template #third>
                        <span class="number">
                            3
                        </span>
                    </ng-template>
                    <div>
                        <span class="info">Completado </span>
                        <i class="fa fa-arrow-right"></i>
                        <small>Registro completo</small>
                    </div>
                </div>
            </div>
        </div>
        <mat-stepper (selectionChange)="onStepChange($event)" #stepper>
            <mat-step>
                <app-general-data [idWorkGroup]="idWorkGroup"></app-general-data>
                <div class="footer-form">
                    <button class="btn btn-primary" (click)="changeSelected(1)">Siguiente</button>
                </div>
            </mat-step>
            <mat-step>
                <app-proposal-initiative></app-proposal-initiative>
                <div class="footer-form">
                    <button class="btn btn-primary" (click)="changeSelected(2)">Siguiente</button>
                    <button class="btn btn-light mr-2" (click)="changeSelected(0)">Anterior</button>
                </div>
            </mat-step>
            <mat-step>
                <app-complete-proposal [idUser]="idUser"></app-complete-proposal>
                <div class="footer-form">
                    <button class="btn btn-light" (click)="changeSelected(1)">Anterior</button>
                </div>
            </mat-step>
        </mat-stepper>
    </div>
</div>