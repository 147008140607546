import { Component, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { Location } from '@angular/common';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomeValidationService } from 'src/app/shared/services/custome-validation.service';
import { RequestService } from 'src/app/shared/services/request.service';
import { themesModel } from 'src/app/shared/models/themes.model';
import { subThemesModel } from 'src/app/shared/models/subthemes.model';
import { categoriesModel } from 'src/app/shared/models/categories.model';
import { MapService } from 'src/app/shared/services/map.service';
import { POIService } from 'src/app/shared/services/poi.service';
import { ActivatedRoute } from '@angular/router';
import { PromotedService } from 'src/app/shared/services/promoted.service';
import { FormDataService } from 'src/app/shared/services/form-data.service';
import { CustomFormControl } from '../promoted-form/promoted-form.component';
import Swal from 'sweetalert2';
import { PeriodService } from 'src/app/shared/services/period.service';

@Component({
  selector: 'app-promoted-edit-form',
  templateUrl: './promoted-edit-form.component.html',
  styleUrls: ['./promoted-edit-form.component.scss']
})
export class PromotedEditFormComponent implements OnInit {
  @ViewChild('stepper') stepper!: MatStepper;

  selectedTab: number = 0;

  sectionOptions: any = [];
  postalCodeSearch: any = [];
  neighborhoodOptions: any[] = [];
  municipalitieName: String = '';
  entitieName: String = '';
  entities: any = '';
  municipalities: any = '';
  postalCodeSelected: boolean = false;
  sectionSelected: boolean = false;

  isReadMore: boolean = false;
  POIoptions: any = [];

  sectionSearch: string = '';

  idWorkGroup: any = '';
  surveyForm!: FormGroup;
  bucketForm!: FormGroup; // at the top of the class component

  themes: Array<themesModel> = [];
  subthemes: Array<subThemesModel> = [];
  categories: Array<categoriesModel> = [];

  idPromoted: any;
  qrcode: any = '';

  promotedForm: FormGroup = this.FB.group({
    id_promoted: [''],
    qrcode: [''],
    name: ['', [Validators.required]],
    next_name: ['', [Validators.required]],
    last_name: ['', [Validators.required]],
    gender: [''],
    age: [''],
    birth_date: [''],
    curp: ['', [this.curpValidator.bind(this)]],
    email: ['', [Validators.email]],
    phone: [''],
    cell_phone: ['', [Validators.required]],
    civil_status: [''],
    birth_place: [''],
    birth_placeM: [{ value: '', disabled: true }],
    scholarship: [''],
    occupation: [''],
    workplace: [''],
    cell_phone_office: [''],
    facebook: [''],
    instagram: [''],
    street: [''],
    street_num_ext: [''],
    street_num_int: [''],
    postal_code: [''],
    id_postal_code: [0],
    id_neighborhood: [{ value: '', disabled: true }],
    id_municipality: [0],
    id_entity: [0],
    electoral_section: [''],
    reference: [''],
    id_work_group: [''],
    location: ['0'],
    id_user: [''],
    missing_data_color: ['red'],
    poi_related: 0,
    id_poi: 0,
    participation_type: [''],
    other_type: [''],
    bitacora: [''],
    participation: 1,
    actor: 0,
    actor_type: [''],
    other_actor: [''],
    privacy_notice: ['', [Validators.required]],
    id_lifting: [''],
    position: [''],
    position_type: [''],
    issues: [''],
    elderly: 0,
    ine: [''],
    acta_nacimiento: [''],
    pasaporte: [''],
    comprobante_domicilio: [''],
    curp_doc: [''],
    rfc: [''],
    other_file: [''],
    other_file_type: [''],
    url_image_ine: [''],
    url_image_Acuse: [''],
    url_image_Extra: [''],

    vote_preference: [''],
    political_rejection: [''],
    political_militant: [''],
    folio_interno: [''],
    period: ['', [Validators.required]],
    docAcuse: [''],
    docExtra: [''],
  });

  originalSurvey: any;
  questions: any = [];
  answers: any = [];
  formID: any;
  visits: any = [];
  problematics: any = [];

  selectedFileINE: any = [];
  selectedFileAcuse: any = [];
  selectedFileExtra: any = [];

  AllUrlIne: any = [];
  AllUrlAcuse: any = [];
  AllUrlExtra: any = [];


  idUser: any;
  clickButton = true;
  newUrlIne: any = [];
  acuseUrls: any = [];
  extraUrls: any = [];
  uploadingFiles: boolean = false;
  promoted: any;
  surveyTittle: any;

  periodOptions: any = [];

  constructor(private location: Location,
    private FB: FormBuilder,
    private customizeService: CustomeValidationService,
    private requestService: RequestService,
    private POIService: POIService,
    private mapService: MapService,
    private activeRoute: ActivatedRoute,
    private promotedService: PromotedService,
    private formDataService: FormDataService,
    private periodService: PeriodService) {
    this.attachInputHandlers();
  }

  ngOnInit(): void {
    this.idUser = localStorage.getItem('idUser');
    this.getPeriodOptions();
    this.getEntities();
    this.getData();
    this.getPOIs();
    // this.fillAfterCreatePOI();

  }

  getPeriodOptions() {
    this.periodOptions = this.periodService.options;
  }

  getAccountSurveyId() {
    this.formDataService.getAccountSurveyId(this.idWorkGroup).subscribe((data: any) => {
      this.formID = data.form_id;
      this.getSurvey(data.form_id, this.idPromoted);
    });
  }

  fillAfterCreatePOI() {
    if (this.formDataService.lastForm) {

      console.log(this.formDataService.lastForm.value);
      console.log(this.formDataService.selectedPOI);


      const data = this.formDataService.lastForm.value;
      const form = this.formDataService.lastForm;

      this.promotedForm.patchValue({
        name: data.name,
        next_name: data.next_name,
        last_name: data.last_name,
        gender: data.gender ?? '',
        birthDate: data.birthDate,
        curp: data.curp,
        email: data.email,
        phone: data.phone,
        cell_phone: data.cell_phone,
        civil_status: data.civil_status ?? '',
        birth_place: data.birth_place ?? '',
        birth_placeM: data.birth_placeM ?? '',
        scholarship: data.scholarship ?? '',
        ocupation: data.ocupation ?? '',
        workplace: data.workplace,
        cell_phone_office: data.cell_phone_office,
        facebook: data.facebook,
        instagram: data.instagram,
        street: data.street,
        street_num_ext: data.street_num_ext,
        street_num_int: data.street_num_int,
        postal_code: data.postal_code,
        id_neighborhood: data.id_neighborhood ?? '',
        id_municipality: data.id_municipality,
        id_entity: data.id_entity,
        electoral_section: data.electoral_section ?? '',
        reference: data.reference,
        id_work_group: data.id_work_group,
        location: data.location,
        poi_related: 0,
        id_poi: this.formDataService.selectedPOI,
        participation_type: data.participation_type ?? '',
        other_type: data.other_type,
        bitacora: data.bitacora,
        participation: data.participation,
        actor: data.actor,
        actor_type: data.actor_type,
        other_actor: data.other_actor,
        privacy_notice: data.privacy_notice,
        id_lifting: data.id_lifting,
        position: data.position,
        position_type: data.position_type,
        issues: data.issues,
        age: data.age,
        elderly: data.elderly,
        vote_preference: data.vote_preference,
        political_rejection: data.political_rejection,
        political_militant: data.political_militant,
        period: data.period,
      });

      if (data.electoralSection) {
        this.selectSecction(data.electoral_section);
      }

      //buscar el CP y seleccionarlo

      if (data.postal_code) {

        this.requestService.searchPostalCode(this.promotedForm.controls['postal_code'].value).subscribe((data: any) => {
          this.postalCodeSearch = data;
          this.getDirectionByPostalCode(data.postal_code);

        });
      }


    }
  }

  getData() {
    this.idPromoted = this.activeRoute.snapshot.paramMap.get('idPromoted');

    if (this.idPromoted != null) {
      this.promotedService.getPromoted(this.idPromoted).subscribe((response: any) => {
        console.log(response);
        this.qrcode = response.qrcode;
        this.problematics = response.issues;
        this.idWorkGroup = response.id_work_group;

        //console.log(this.problematics[0].theme_id);

        this.AllUrlIne = response.url_image_ine;
        this.AllUrlAcuse = response.url_image_Acuse;
        this.AllUrlExtra = response.url_image_Extra;

        this.promoted = response;

        this.promotedForm.patchValue({
          id_promoted: response.id_promoted,
          qrcode: response.qrcode,
          name: response.name,
          next_name: response.next_name,
          last_name: response.last_name,
          gender: response.gender ?? '',
          birth_date: response.birth_date,
          age: response.age,
          curp: response.curp,
          email: response.email,
          phone: response.phone,
          cell_phone: response.cell_phone,
          civil_status: response.civil_status ?? '',
          birth_place: response.birth_place ?? '',
          birth_placeM: response.birth_placeM ?? '',
          scholarship: response.scholarship ?? '',
          occupation: response.occupation ?? '',
          workplace: response.workplace,
          cell_phone_office: response.cell_phone_office,
          facebook: response.facebook,
          instagram: response.instagram,
          elderly: response.elderly,
          street: response.street,
          street_num_ext: response.street_num_ext,
          street_num_int: response.street_num_int,
          postal_code: response.postal_code,
          id_postal_code: response.id_postal_code,
          id_meighborhood: response.id_neighborhood ?? '',
          id_municipality: response.id_municipality,
          id_entity: response.id_entity,
          electoral_section: response.electoral_section ?? '',
          reference: response.reference,
          id_work_group: response.id_work_group,
          location: response.location,
          id_user: response.id_user,
          missing_data_color: response.missing_data_color,
          poi_related: response.id_poi ? 1 : 0,
          id_poi: response.id_poi ?? 0,
          participation: response.participation,
          participation_type: response.participation_type ?? '',
          other_type: response.other_type,
          bitacora: response.bitacora,
          actor: response.actor_type ? 1 : 0,
          actor_type: response.actor_type,
          other_actor: response.other_actor,
          id_lifting: response.id_lifting,
          privacy_notice: response.privacy_notice,
          position: response.position,
          position_type: response.position_type,
          issues: response.issues,
          ine: response.ine ?? 0,
          acta_nacimiento: response.acta_nacimiento ?? 0,
          pasaporte: response.pasaporte ?? 0,
          comprobante_domicilio: response.comprobante_domicilio ?? 0,
          curp_doc: response.curp_doc ?? 0,
          rfc: response.rfc ?? 0,
          other_file: response.other_file ?? 0,
          other_file_type: response.other_file_type ?? '',
          vote_preference: response.vote_preference ?? '',
          political_rejection: response.political_rejection,
          political_militant: response.political_militant,
          folio_interno: response.folio_interno,
          period: response.period,
          docAcuse: response.docAcuse,
          docExtra: response.docExtra,
        });
        if (response.birth_place) {
          this.onFirstSelectChange();
        }
        if (response.postal_code) {
          this.getDirectionByPostalCode(response.postal_code);
        }
        if (response.electoral_section) {
          this.selectSecction(response.electoral_section);
        }

        this.getAccountSurveyId();
        this.getVisits();
      });
    }
  }

  buttonSave() {


    if (this.promotedForm.controls['cell_phone'].invalid) {
      Swal.fire(
        {
          icon: 'error',
          title: 'Asegúrate de registrar diez dígitos',
          text: '',
          confirmButtonText: 'Aceptar'
        }
      );
    } else {

      if (this.clickButton) {
        this.clickButton = false;
        this.updateMissingDataColor(this.promotedForm);

        const promises = [];

        if (this.selectedFileINE) {
          const file = this.selectedFileINE.filter((file: any) => {
            const fileType = file.file.type;
            return fileType === 'application/pdf' || fileType.startsWith('image/');
          });

          if (file.length > 0) {
            promises.push(this.stageToUpload(file, 'INE', this.promotedForm.controls['qrcode'].value));
            this.loaderAlert();
          }
        }

        if (this.selectedFileAcuse) {
          const file = this.selectedFileAcuse.filter((file: any) => {
            const fileType = file.file.type;
            return fileType === 'application/pdf' || fileType.startsWith('image/');
          });

          if (file)
            promises.push(this.stageToUpload(file, 'Acuse', this.promotedForm.controls['qrcode'].value));
        }

        if (this.selectedFileExtra) {
          const file = this.selectedFileExtra.filter((file: any) => {
            const fileType = file.file.type;
            return fileType === 'application/pdf' || fileType.startsWith('image/');
          });

          if (file)
            promises.push(this.stageToUpload(file, 'Extra', this.promotedForm.controls['qrcode'].value));
        }

        Promise.all(promises).then(async () => {
          if (this.uploadingFiles == true) {
            Swal.close();
            this.succesAlert();
          }

          if (this.AllUrlIne) {
            this.AllUrlIne.forEach((url: any) => {
              this.newUrlIne.push(url);
            });
          }

          if (this.AllUrlAcuse) {
            this.AllUrlAcuse.forEach((url: any) => {
              this.acuseUrls.push(url);
            });
          }

          if (this.AllUrlExtra) {
            this.AllUrlExtra.forEach((url: any) => {
              this.extraUrls.push(url);
            });
          }

          this.promotedForm.get('url_image_ine')?.patchValue(this.newUrlIne);
          this.promotedForm.get('url_image_Acuse')?.patchValue(this.acuseUrls);
          this.promotedForm.get('url_image_Extra')?.patchValue(this.extraUrls);

          this.promotedService.updatePromoted(this.promotedForm.value, this.idPromoted).subscribe(data => {
            const time = 5000;
            let timerInterval: any;

            const swalLoading = Swal.fire({
              title: 'Cargando...',
              timer: time,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading(Swal.getDenyButton());
                timerInterval = setInterval(() => {
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            });
            this.saveHistory(this.idPromoted);
            if (this.answers.length > 0) {
              this.answers.forEach((answer: any) => {
                answer.id_promoted = this.idPromoted;
              });

              this.formDataService.storeAnswers(this.answers).subscribe((data: any) => {
                console.log(data);
              });
            }
          });
        });
      }

    }


  }

  stageToUpload(files: any, type: string, id: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      console.log(files);
      this.uploadImage(files, id)
        .then((data) => {
          console.log(data);
          // Avísanos que la operación se ha completado
          switch (type) {
            case 'INE':
              this.newUrlIne = data;
              break;
            case 'Acuse':
              this.acuseUrls = data;
              break;
            case 'Extra':
              this.extraUrls = data;
              break;

          }
          resolve();
        })
        .catch((error) => {
          // Maneja errores si es necesario
          reject(error);
        });
    });
  }

  uploadImage(selectedFiles: any[], id: number) {
    return new Promise((resolve, reject) => {
      const uploadPromises = [];
      const urls: any = [];
      console.log(selectedFiles);
      for (const file of selectedFiles) {
        console.log(file);
        const uploadPromise = new Promise((innerResolve, innerReject) => {
          this.requestService.uploadImage(file.file, 'promoted', 'files', id).subscribe(
            (data: any) => {
              console.log(data);
              urls.push(data.path);
              innerResolve(data);
            },
            (error) => {
              innerReject(error);
            }
          );
        });
        uploadPromises.push(uploadPromise);
      }
      Promise.all(uploadPromises)
        .then(() => {
          // Todas las imágenes se han cargado con éxito
          resolve(urls);
        })
        .catch((error) => {
          // Al menos una carga de imagen falló
          reject(error);
        });
    });
  }

  saveHistory(id_promoted: number) {
    const historyData = {
      id_promoted: id_promoted,
      id_user: this.idUser,
      message: 'Ha modificado el promovido desde Web.',
      date: new Date()
    };
    this.promotedService.saveHistory(historyData).subscribe(data => {
      Swal.close();
      alert('Tu registro ha sido actualizada con éxito');
      const parentURL = this.customizeService.getUrl();
      window.parent.location.href = parentURL + '/PromotedPromoted/listPromoted.html';
    });
  }

  loaderAlert() {
    this.uploadingFiles = true;
    Swal.fire({
      icon: 'warning',
      title: "Subiendo archivos",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton())
      },
      willClose: () => {

      }
    }).then((result) => {

    })
  }

  succesAlert() {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
    Toast.fire({
      icon: 'success',
      title: "Archivos subidos",
    })

  }

  updateMissingDataColor(form: FormGroup) {
    const curp = form.get('curp');
    const requiredFields = [
      'name',
      'next_name',
      'last_name',
      'birthDate',
      'cellPhone',
      'birthPlace',
      'birthPlaceM',
      'idPostalCode',
      'gender',
      'email',
      'civilStatus',
      'scholarship',
      'ocupation',
      'street',
      'streetNumExt',
      'idNeighborhood',
      'electoralSection',
    ];
    // Comprobar si todos los campos requeridos están llenos
    const allFieldsFilled = requiredFields.every(
      (fieldName) => !this.isNullOrEmpty(form.get(fieldName))
    );

    if (allFieldsFilled) {
      this.promotedForm.controls['missing_data_color'].setValue('green');
    } else if (!this.isNullOrEmpty(curp)) {
      this.promotedForm.controls['missing_data_color'].setValue('yellow');
    } else {
      this.promotedForm.controls['missing_data_color'].setValue('red');
    }
  }

  // Función para verificar si un campo está vacío o nulo
  isNullOrEmpty(control: AbstractControl | null): boolean {
    return control === null || control.value === '' || control.value === null;
  }

  updateCheckbox(event: Event, type: string) {
    if (event && event.target instanceof HTMLInputElement) {
      if (type == 'Ine')
        this.promotedForm.get('ine')?.setValue(event.target.checked ? 1 : null);
      if (type == 'Certificate')
        this.promotedForm.get('acta_nacimiento')?.setValue(event.target.checked ? 1 : null);
      if (type == 'Passport')
        this.promotedForm.get('pasaporte')?.setValue(event.target.checked ? 1 : null);
      if (type == 'Proof')
        this.promotedForm.get('comprobante_domicilio')?.setValue(event.target.checked ? 1 : null);
      if (type == 'Curp')
        this.promotedForm.get('curp_doc')?.setValue(event.target.checked ? 1 : null);
      if (type == 'Rfc')
        this.promotedForm.get('rfc')?.setValue(event.target.checked ? 1 : null);
      if (type == 'Which')
        this.promotedForm.get('docWhich')?.setValue(event.target.checked ? 1 : null);
      if (type == 'Property')
        this.promotedForm.get('propertyTax')?.setValue(event.target.checked ? 1 : null);
      if (type == 'Acuse')
        this.promotedForm.get('docAcuse')?.setValue(event.target.checked ? 1 : null);
      if (type == 'Extra')
        this.promotedForm.get('docExtra')?.setValue(event.target.checked ? 1 : null);
    }
  }

  onFileChange(event: any) {
    const files: FileList = event.target.files;
    for (let i = 0; i < files.length; i++) {
      this.selectedFileINE.push({ file: files[i] });
    }
    console.log(this.selectedFileINE);
  }

  onFileAcuseChange(event: any) {
    const files: FileList = event.target.files;

    for (let i = 0; i < files.length; i++) {
      this.selectedFileAcuse.push({ file: files[i] });
    }
    console.log(this.selectedFileAcuse);
  }

  onFileExtraChange(event: any) {
    const files: FileList = event.target.files;

    for (let i = 0; i < files.length; i++) {
      this.selectedFileExtra.push({ file: files[i] });
    }
    console.log(this.selectedFileExtra);
  }

  openImage(url: string) {
    window.open(url, '_blank');
  }

  getFileName(url: string): string {
    const parts = url.split('/');
    return decodeURIComponent(parts[parts.length - 1]);
  }

  removeFile(url: string, type: string) {


    switch (type) {
      case 'INE':
        this.AllUrlIne = this.AllUrlIne.filter((item: any) => item !== url);

        break;
      case 'Acuse':
        console.log('in');

        this.AllUrlAcuse = this.AllUrlAcuse.filter((item: any) => item !== url);

        break;
      case 'Extra':
        this.AllUrlExtra = this.AllUrlExtra.filter((item: any) => item !== url);

        break;
    }

    this.formDataService.setRequestDocumentationFormData(this.promotedForm, this.selectedFileINE, this.AllUrlIne, this.selectedFileAcuse, this.AllUrlAcuse, this.selectedFileExtra, this.AllUrlExtra);
  }

  attachInputHandlers() {
    const nameFields = ['name', 'next_name', 'last_name'];
    const uppercaseFields = ['curp', 'workplace', 'type_support', 'street', 'reference', 'bitacora', 'other_type', 'other_actor'];
    const numberFields = ['age', 'phone', 'cell_phone', 'cell_phone_office', 'postal_code', 'elderly'];

    const handleUppercaseField = (fieldName: string) => {
      const fieldControl = this.promotedForm.get(fieldName);
      if (fieldControl) {
        fieldControl.valueChanges.subscribe((newValue: string) => {
          if (newValue && typeof newValue === 'string') {
            this.promotedForm.get(fieldName)?.setValue(newValue.toUpperCase(), { emitEvent: false });
          }
        });
      }
    };

    const handleNameField = (fieldName: string) => {
      const fieldControl = this.promotedForm.get(fieldName);
      if (fieldControl) {
        fieldControl.valueChanges.subscribe((newValue: string) => {
          if (newValue && typeof newValue === 'string') {
            this.promotedForm.get(fieldName)?.setValue(newValue.toUpperCase().replace(/[^A-Z\sÑ]/g, ''), { emitEvent: false });
          }
        });
      }
    };

    const handleNumberField = (fieldName: string) => {
      const fieldControl = this.promotedForm.get(fieldName);
      if (fieldControl) {
        fieldControl.valueChanges.subscribe((newValue: string) => {
          if (newValue && typeof newValue === 'string') {
            this.promotedForm.get(fieldName)?.setValue(newValue.replace(/\D/g, ''), { emitEvent: false });
          }
        });
      }
    };

    uppercaseFields.forEach(handleUppercaseField);
    nameFields.forEach(handleNameField);
    numberFields.forEach(handleNumberField);
  }

  getVisits() {
    if (this.idPromoted != undefined) {
      this.promotedService.getVisitData(this.idPromoted).subscribe((data: any) => {
        this.visits = data[0];
      });
    }
  }

  getSurvey(idForm: number, idPromoted: any) {
    this.surveyForm = this.FB.group({});
    this.formDataService.getSurveyUpdate(idForm, idPromoted).subscribe((data: any) => {
      this.surveyTittle = data.name;
      this.originalSurvey = data.form_questions;
      this.questions = data.form_questions;
      this.questions.forEach((question: any) => {
        // Usa tu clase personalizada en lugar de FormControl estándar
        const formControl = new CustomFormControl();
        this.surveyForm.addControl(question.control, formControl);
        if (question.answers.length > 0) {
          this.surveyForm.get(question.control)?.patchValue(question.answers[0].value);
          formControl?.disable();
        } else {
          this.surveyForm.get(question.control)?.patchValue('');
        }
      });
    })
  }

  handleOption(question: any) {
    const options = question.form_options;
    const selectedOptionValue = this.surveyForm.controls[question.control].value;

    const selectedOption = options.find((item: any) => item.value === selectedOptionValue);
    console.log(selectedOption);
    // Oculta las preguntas hijas si hay
    if (selectedOption && selectedOption.child_question_ids) {
      const childQuestionIds = JSON.parse(selectedOption.child_question_ids);
      childQuestionIds.forEach((questionId: any) => {
        const childElement = document.getElementById(questionId);
        console.log(childElement);
        // Agrega la clase 'hidden' al elemento si existe
        if (childElement) {
          childElement.classList.remove('hidden');
        }
      });
    } else {
      // Oculta las preguntas hijas de otras opciones
      const childOptions = options.filter((item: any) => item.child_question_ids != null);
      childOptions.forEach((child: any) => {
        const childQuestionIds = JSON.parse(child.child_question_ids);
        childQuestionIds.forEach((id: any) => {
          console.log(id);
          const childElement = document.getElementById(id);
          // Agrega la clase 'hidden' al elemento si existe
          if (childElement) {
            childElement.classList.add('hidden');
          }
          // Elimina la respuesta de la pregunta oculta del array de respuestas
          const indexToRemove = this.answers.findIndex((item: any) => item.question_id === id);
          if (indexToRemove !== -1) {
            this.answers.splice(indexToRemove, 1);
          }
        });
      });
    }

    const answer: Answer = {
      form_id: this.formID,
      id_promoted: this.idPromoted,
      question_id: question.id,
      value: selectedOptionValue,
      option_id: 0
    };

    const indexToUpdate = this.answers.findIndex((item: any) => item.question_id === question.id);

    // Actualiza o agrega la respuesta según sea necesario
    if (indexToUpdate !== -1) {
      this.answers[indexToUpdate] = answer;
    } else {
      this.answers.push(answer);
    }
  }

  getEntities(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.requestService.getEntities().subscribe(
        (data: any) => {
          this.entities = data;
          resolve(data); // Resolvemos la promesa con los datos
        },
        (error) => {
          reject(error); // Rechazamos la promesa en caso de error
        }
      );
    });
  }

  getMunicipalities(idEntity: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.requestService.getMunicipalities(idEntity).subscribe(
        (data: any) => {
          this.municipalities = data;
          resolve(data); // Resolvemos la promesa con los datos
        },
        (error) => {
          reject(error); // Rechazamos la promesa en caso de error
        }
      );
    });
  }

  async onFirstSelectChange() {
    this.promotedForm.controls['birth_placeM'].enable();
    try {
      const idEntity = this.promotedForm.get('birth_place')?.value;
      await this.getMunicipalities(idEntity);
      // Aquí puedes continuar con cualquier lógica que dependa de las municipalities
    } catch (error) {
      console.error('Error al obtener las municipalities:', error);
    }
  }

  clearPostalCode() {
    this.postalCodeSelected = false;
    this.postalCodeSearch = [];
    this.promotedForm.controls['postal_code'].reset();
  }

  searchPostalCode() {
    this.requestService.searchPostalCode(this.promotedForm.controls['postal_code'].value).subscribe((data: any) => {
      this.postalCodeSelected = false;
      this.postalCodeSearch = data;
    });
  }

  selectPostalCode(postalCode: String) {
    this.getDirectionByPostalCode(postalCode);
  }

  getDirectionByPostalCode(postalCode: String) {
    this.promotedForm.controls['postal_code'].setValue(postalCode);
    this.promotedForm.controls['id_municipality'].reset();
    this.promotedForm.controls['id_entity'].reset();

    this.requestService.getDirectionByPostalCode(postalCode).subscribe(
      (data: any) => {
        this.postalCodeSelected = true;

        this.municipalitieName = data.municipality.name;
        this.promotedForm.controls['id_municipality'].setValue(data.municipality.id);

        this.entitieName = data.entity.name;
        this.promotedForm.controls['id_entity'].setValue(data.entity.id);
        this.getSecctions(data.municipality.identifier, data.entity.identifier);

        // Clear previous neighborhood options
        this.neighborhoodOptions = [];

        if (this.promoted.id_neighborhood) {

          this.promotedForm.controls['id_neighborhood'].enable();
          this.neighborhoodOptions = data.neighborhoods;
          this.promotedForm.controls['id_neighborhood'].setValue(this.promoted.id_neighborhood);
        } else {

          this.promotedForm.controls['id_neighborhood'].enable();
          this.neighborhoodOptions = data.neighborhoods;
          this.promotedForm.controls['id_neighborhood'].setValue(data.neighborhoods[0].id);
        }

      },
      (error) => {
        console.error(error); // Maneja el error de manera adecuada
      }
    );
  }

  getSecctions(idMunicipality: number, idEntity: number) {
    this.mapService.getSections(idMunicipality, idEntity).subscribe((data: any) => {
      console.log(data);
      this.sectionOptions = data;
    })
  }

  selectSecction(Secction: String) {
    this.sectionSelected = true;
    this.promotedForm.controls['electoral_section'].setValue(Secction);
  }

  clearSection() {
    this.sectionSelected = false;
    this.promotedForm.controls['electoral_section'].reset();
  }

  getPOIs() {
    return new Promise((resolve, reject) => {
      this.POIService.getPOIOptions().subscribe(
        (data: any) => {
          this.POIoptions = data;
          resolve(data); // Resolvemos la promesa con los datos
        },
        (error) => {
          reject(error); // Rechazamos la promesa en caso de error
        }
      );
    });
  }

  curpValidator(control: FormControl): { [key: string]: any } | null {
    const curp = control.value;
    if (curp?.length != 0 && curp != null) {
      const isValid = this.customizeService.validCurp(curp);

      if (isValid) {
        return null; // La CURP es válida
      } else {
        return { 'invalidCurp': true }; // La CURP es inválida
      }
    } else {
      return null;
    }
  }

  goBack() {
    this.location.back();
  }

  onStepChange(event: any) {
    this.selectedTab = event.selectedIndex;
  }

  changeSelected(index: number) {
    const isFormValid = this.promotedForm.valid;
    //const isFormValid = true;
    // Obtén los valores actuales del formulario
    const formValues = this.promotedForm.getRawValue();

    // Itera a través de los controles y verifica su estado
    Object.keys(formValues).forEach(controlName => {
      const control: any = this.promotedForm.get(controlName);
      console.log(`Estado de ${controlName}: ${control.valid}`);
    });
    if (this.promotedForm.controls['cell_phone'].invalid) {
      this.showError('Formato de número incorrecto', 'Asegúrate de registrar diez dígitos');
    } else if (this.promotedForm.controls['curp'].invalid) {
      this.showError('CURP inválida', 'Asegúrate de que la CURP que se esté ingresando sea correcta');
    } else {
      if (isFormValid === true) {
        this.stepper.selectedIndex = index;
      } else {
        this.showError('No has llenado todos los campos requeridos!', '');
      }
    }
  }

  showError(title: any, text: any) {
    Swal.fire({
      icon: 'error',
      title: title,
      text: text,
      confirmButtonText: 'Aceptar'
    });
  }

}

interface Answer {
  form_id: number;
  question_id: number;
  option_id: number;
  id_promoted: number;
  value: string;
}

interface FormData {
  answers: Answer[];
}
