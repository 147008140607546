import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { CustomeValidationService } from 'src/app/shared/services/custome-validation.service';
import { FormDataService } from 'src/app/shared/services/form-data.service';
import { MapService } from 'src/app/shared/services/map.service';
import { POIService } from 'src/app/shared/services/poi.service';
import { ProposalService } from 'src/app/shared/services/proposal.service';
import { RequestService } from 'src/app/shared/services/request.service';
import { PassDataComponent } from '../../modal/pass-data/pass-data.component';
import { PeriodService } from 'src/app/shared/services/period.service';

declare var $: any;
@Component({
  selector: 'app-general-data',
  template: 'General Data: {{ idWorkGroup }}',
  templateUrl: './general-data.component.html',
  styleUrls: ['./general-data.component.scss'],
})
export class GeneralDataComponent implements OnInit {
  @Input() idWorkGroup: string = '';
  @ViewChild('menu') menu!: ElementRef;
  @ViewChild('searchMenuSecc') searchMenuSecc!: ElementRef;

  proposalForm: FormGroup = this.FB.group({
    name: ['', [Validators.required]],
    next_name: ['', [Validators.required]],
    last_name: ['', [Validators.required]],
    gender: [''],
    birthDate: [''],
    curp: ['', [this.curpValidator.bind(this)]],
    email: ['', [Validators.email]],
    phone: [''],
    cellPhone: ['', [Validators.required]],
    civilStatus: [''],
    birthPlace: [''],
    birthPlaceM: [{ value: '', disabled: true }],
    scholarship: [''],
    ocupation: [''],
    workplace: [''],
    cellPhoneOffice: [''],
    facebook: [''],
    instagram: [''],
    street: [''],
    streetNumExt: [''],
    streetNumInt: [''],
    postalCode: [''],
    idNeighborhood: [{ value: '', disabled: true }],
    idMunicipality: [''],
    idEntity: [''],
    electoralSection: [''],
    reference: [''],
    idWorkGroup: [''],
    location: ['0'],
    idlifting: ['', [Validators.required]],
    localDistrict: [''],
    federalDistrict: [''],
    POIrelated: 0,
    idPOI: 0,
    participation:0,
    participationType:[''],
    otherType:[''],
    actor:0,
    actorType:[''],
    otherActor:[''],
    privacyNotice: ['', [Validators.required]],
    typeManagment: [''],
    groupBe : [0],
    period: ['']
  });

  postalCodeSearch: any = [];
  neighborhoodOptions: any[] = [];
  municipalitieName: String = '';
  entitieName: String = '';
  entities: any = '';
  municipalities: any = '';
  postalCodeSelected: boolean = false;
  sectionSelected: boolean = false;
  idProposal: any;
  sectionOptions: any = [];
  localDistricts: any;
  federalDistricts: any;
  POIoptions: any = [];
  sectionSearch: string = '';
  showSections: boolean = false;
  isReadMore = true;

  periodOptions: any = [];

  constructor(
    private FB: FormBuilder,
    private customizeService: CustomeValidationService,
    private formDataService: FormDataService,
    private requestService: RequestService,
    private activeRoute: ActivatedRoute,
    private proposalService: ProposalService,
    private mapService: MapService,
    private POIService: POIService,
    public dialog: MatDialog,
    private renderer: Renderer2,
    private periodService: PeriodService
  ) {
    this.attachInputHandlers();
    this.getPeriodOptions();
    this.isUpdating();
    this.backdropDismiss();
  }

  openModal() {
    $('#exampleModal').modal('show');
  }

  closeModal() {
    $('#exampleModal').modal('hide');
  }

  toggleText() {
    this.isReadMore = !this.isReadMore;
  }

  onInputClick() {
    this.showSections = true;
  }

  backdropDismiss() {
    this.renderer.listen('window', 'click', (e: Event) => {

      if (e.target !== this.menu.nativeElement && e.target !== this.searchMenuSecc.nativeElement) {
        this.showSections = false;
      }
    });
  }

  getPeriodOptions(){
    this.periodOptions = this.periodService.options;
  }

  isUpdating() {
    this.idProposal = this.activeRoute.snapshot.paramMap.get('idProposal');

    if (this.idProposal != null) {
      this.proposalService.getProposal(this.idProposal).subscribe((response: any) => {
        const data = response[0];
        this.proposalForm.patchValue({
          name: data.name,
          next_name: data.next_name,
          last_name: data.last_name,
          gender: data.gender ?? '',
          birthDate: data.birthDate,
          curp: data.curp,
          email: data.email,
          phone: data.phone,
          cellPhone: data.cellPhone,
          civilStatus: data.civilStatus ?? '',
          birthPlace: data.birthPlace ?? '',
          birthPlaceM: data.birthPlaceM ?? '',
          scholarship: data.scholarship ?? '',
          ocupation: data.ocupation ?? '',
          workplace: data.workplace,
          cellPhoneOffice: data.cellPhoneOffice,
          facebook: data.facebook,
          instagram: data.instagram,
          street: data.street,
          streetNumExt: data.streetNumExt,
          streetNumInt: data.streetNumInt,
          postalCode: data.postalCode,
          idNeighborhood: data.idNeighborhood ?? '',
          idMunicipality: data.idMunicipality,
          idEntity: data.idEntity,
          electoralSection: data.electoralSection,
          reference: data.reference,
          idWorkGroup: data.idWorkGroup,
          location: data.location,
          idlifting: data.idlifting,
          POIrelated:  data.idPOI ? 1 : 0,
          idPOI: data.idPOI,
          participation: data.participationType ? 1 : 0,
          participationType: data.participationType,
          otherType: data.otherType,
          actor: data.actorType ? 1 : 0,
          actorType: data.actorType,
          otherActor: data.otherActor,
          typeManagment: data.typeManagment ?? '',
          groupBe: data.groupBe,
          privacyNotice: data.privacyNotice ?? 1,
          period: data.period
        });
        if (data.birthPlace) {
          this.onFirstSelectChange();
        }
        if (data.postalCode) {
          this.getDirectionByPostalCode(data.postalCode);
        }
        if (data.electoralSection) {
          this.selectSecction(data.electoralSection);
        }
      })
    }
  }

  attachInputHandlers() {
    const nameFields = ['name', 'next_name', 'last_name'];
    const uppercaseFields = ['curp', 'workplace', 'street', 'reference','otherType','otherActor'];
    const numberFields = ['phone', 'cellPhone', 'cellPhoneOffice', 'postalCode'];
    uppercaseFields.forEach(fieldName => {
      const fieldControl = this.proposalForm.get(fieldName);
      if (fieldControl) {
        fieldControl.valueChanges.subscribe((newValue: string) => {
          if (newValue) {
            this.proposalForm.get(fieldName)?.setValue(newValue.toUpperCase(), { emitEvent: false });
          }
        });
      }
    });
    nameFields.forEach(fieldName => {
      const fieldControl = this.proposalForm.get(fieldName);
      if (fieldControl) {
        fieldControl.valueChanges.subscribe((newValue: string) => {
          if (newValue) {
            this.proposalForm.get(fieldName)?.setValue(newValue.toUpperCase().replace(/[^A-Z\sÑ]/g, ''), { emitEvent: false });
          }
        });
      }
    });
    numberFields.forEach(fieldName => {
      const fieldControl = this.proposalForm.get(fieldName);
      if (fieldControl) {
        fieldControl.valueChanges.subscribe((newValue: string) => {
          if (newValue) {
            this.proposalForm.get(fieldName)?.setValue(newValue.replace(/\D/g, ''), { emitEvent: false });
          }
        });
      }
    });
  }

  ngOnInit(): void {
    this.getUserToken();
    this.setValues();
    this.getEntities();
    this.getPOIs();
    this.proposalForm.valueChanges.subscribe(data => {
      this.formDataService.setGeneralFormData(this.proposalForm);
    });
  }

  getUserToken() {
    this.activeRoute.params.subscribe(routeParams => {
      localStorage.setItem('token', routeParams['token'])
    });
  }
  selectPostalCode(postalCode: String) {
    this.getDirectionByPostalCode(postalCode);
  }

  selectSecction(Secction: String){
    this.sectionSelected = true;
    this.proposalForm.controls['electoralSection'].setValue(Secction);
  }

  clearSection(){
    this.sectionSelected = false;
    this.proposalForm.controls['electoralSection'].reset();
  }

  clearPostalCode() {
    this.postalCodeSelected = false;
    this.postalCodeSearch = [];
    this.proposalForm.controls['postalCode'].reset();
  }
  setValues() {
    this.proposalForm.controls['idWorkGroup'].setValue(this.idWorkGroup);
  }

  curpValidator(control: FormControl): { [key: string]: any } | null {
    const curp = control.value;
    if (curp?.length != 0 && curp != null) {
      const isValid = this.customizeService.validCurp(curp);

      if (isValid) {
        return null; // La CURP es válida
      } else {
        return { 'invalidCurp': true }; // La CURP es inválida
      }
    } else {
      return null;
    }
  }

  searchPostalCode() {
    this.requestService.searchPostalCode(this.proposalForm.controls['postalCode'].value).subscribe((data: any) => {
      this.postalCodeSelected = false;
      this.postalCodeSearch = data;
    });
  }



  getDirectionByPostalCode(postalCode: String) {
    this.proposalForm.controls['postalCode'].setValue(postalCode);
    this.proposalForm.controls['idMunicipality'].reset();
    this.proposalForm.controls['idEntity'].reset();

    this.requestService.getDirectionByPostalCode(postalCode).subscribe(
      (data: any) => {
        this.postalCodeSelected = true;

        this.municipalitieName = data.municipality.name;
        this.proposalForm.controls['idMunicipality'].setValue(data.municipality.id);

        this.entitieName = data.entity.name;
        this.proposalForm.controls['idEntity'].setValue(data.entity.id);
        this.getLocalDistrictsByEntities(data.entity.identifier);
        this.getFederalDistrictsByEntities(data.entity.identifier);
        this.getSecctions(data.municipality.identifier, data.entity.identifier);
        // Clear previous neighborhood options
        this.neighborhoodOptions = [];

        if (data.neighborhoods.length > 1) {
          this.proposalForm.controls['idNeighborhood'].enable();
          this.neighborhoodOptions = data.neighborhoods;
        } else {
          this.proposalForm.controls['idNeighborhood'].enable();
          this.neighborhoodOptions = data.neighborhoods;
          this.proposalForm.controls['idNeighborhood'].setValue(data.neighborhoods[0].id);
        }
      },
      (error) => {
        console.error(error); // Maneja el error de manera adecuada
      }
    );
  }

  getLocalDistrictsByEntities(idEntitie: number) {
    this.mapService.getLocalDistrictsByEntities(idEntitie).subscribe((data: any) => {
      this.localDistricts = data;
      console.log(data);
    })
  }

  getFederalDistrictsByEntities(idEntitie: number) {
    this.mapService.getFederalDistrictsByEntities(idEntitie).subscribe((data: any) => {
      this.federalDistricts = data;
      console.log(data);
    })
  }

  getSecctions(idMunicipality: number, idEntity: number) {

    this.mapService.getSections(idMunicipality, idEntity).subscribe((data: any) => {
      console.log(data);
      this.sectionOptions = data;

    })
  }

  getEntities(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.requestService.getEntities().subscribe(
        (data: any) => {
          this.entities = data;
          resolve(data); // Resolvemos la promesa con los datos
        },
        (error) => {
          reject(error); // Rechazamos la promesa en caso de error
        }
      );
    });
  }

  getMunicipalities(idEntity: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.requestService.getMunicipalities(idEntity).subscribe(
        (data: any) => {
          this.municipalities = data;
          resolve(data); // Resolvemos la promesa con los datos
        },
        (error) => {
          reject(error); // Rechazamos la promesa en caso de error
        }
      );
    });
  }

  async onFirstSelectChange() {
    this.proposalForm.controls['birthPlaceM'].enable();
    try {
      const idEntity = this.proposalForm.get('birthPlace')?.value;
      await this.getMunicipalities(idEntity);
      // Aquí puedes continuar con cualquier lógica que dependa de las municipalities
    } catch (error) {
      console.error('Error al obtener las municipalities:', error);
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(PassDataComponent, {
      data: {
        form: this.proposalForm,
        idUser: this.activeRoute.snapshot.paramMap.get('idUser'),
        idWorkGroup: this.activeRoute.snapshot.paramMap.get('idWorkGroup'),
        token: this.activeRoute.snapshot.paramMap.get('token'),
        environment: this.activeRoute.snapshot.paramMap.get('environment'),
        from: 'proposal'

      },
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      //this.animal = result;
    });
  }

  getPOIs(){
    return new Promise((resolve, reject) => {
      this.POIService.getPOIOptions().subscribe(
        (data: any) => {
          this.POIoptions = data;
          resolve(data); // Resolvemos la promesa con los datos
        },
        (error) => {
          reject(error); // Rechazamos la promesa en caso de error
        }
      );
    });
  }

  updateCheckbox(event: Event) {
    if (event && event.target instanceof HTMLInputElement) {
      this.proposalForm.get('privacyNotice')?.setValue(event.target.checked ? 1 : null);
    }
  }
}
