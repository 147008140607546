import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormDataService } from './form-data.service';

@Injectable({
  providedIn: 'root'
})
export class SympathizerService {
  url = environment.apiUrl;

  constructor(public http: HttpClient, public formDataService: FormDataService) { }

  saveSympathizer(sympathizerData: any) {
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return this.http.post<any>(this.url + 'saveSympathizer', sympathizerData, options);
  }

  saveHistory(historyData: any) {
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return this.http.post<any>(this.url + 'saveSympathizerHistory', historyData, options);
  }

  saveVisit(visitData: any) {
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return this.http.post<any>(this.url + 'saveVisitSympathizer', visitData, options);
  }

  updateSympathizer(idSympathizer: number, sympathizerData: any) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return this.http.put<any>(this.url + 'updateSympathizer/' + idSympathizer, sympathizerData, options);
  }

  getSympathizers(page: number, itemsPerPage: number, idWorkGroup: string, userLevel: string, idUser: string) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get(this.url + 'getSympathizersList/' + page + '/' + itemsPerPage + '/' + idWorkGroup + '/' + userLevel + '/' + idUser, options);
  }

  getSympathizer(idSympathizer: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get(this.url + 'getSympathizer/' + idSympathizer, options);
  }

  getSympathizerData(idSympathizer: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get(this.url + 'getSympathizerData/' + idSympathizer, options);
  }

  getSearchSympathizers(searchTerm: string | null, p: any, itemsPerPage: any,userLevel: string, idUser: string) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get(this.url + 'getSearchSympathizersList/' + searchTerm + '/' + localStorage.getItem('idWorkGroup') + '/' + p + '/' + itemsPerPage + '/' + userLevel + '/' + idUser, options);
  }

  getReport(level: number, identifier: number, idUser: number) {
    let request;
    // const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    // const options = { headers: httpHeaders };
    return request = this.http.get(this.url + 'report/exportSympathizerReport/' + localStorage.getItem('idWorkGroup') + '/' + level + '/' + identifier + '/' + idUser);
  }

  getVisitData(idSympathizer: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get(this.url + 'getVisitSympathizer/' + idSympathizer, options);
  }

  getHistoryData(idSympathizer: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get(this.url + 'getSympathizerHistoryData/' + idSympathizer, options);
  }

  sendWhatsappNotification(idSympathizer: number, idWorkGroup: string) {
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return this.http.get<any>(this.url + 'sendWhatsAppSympathizer/' + idSympathizer + '/' + idWorkGroup, options);
  }
}
