import { Component, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { RequestService } from 'src/app/shared/services/request.service';
import { UserService } from 'src/app/shared/services/user.service';
import { FormDataService } from 'src/app/shared/services/form-data.service';
import { environment } from 'src/environments/environment';
import { CustomeValidationService } from 'src/app/shared/services/custome-validation.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { switchMap } from 'rxjs/operators';

declare var $: any;
@Component({
  selector: 'app-detail-request',
  templateUrl: './detail-request.component.html',
  styleUrls: ['./detail-request.component.scss']
})
export class DetailRequestComponent implements OnInit {
  @ViewChild('stepper') stepper!: MatStepper;

  reassignForm: FormGroup = this.FB.group({
    idAssigned: ['', [Validators.required]],
  });

  closeForm: FormGroup = this.FB.group({
    idstatus: ['', [Validators.required]],
    idReason: ['', [Validators.required]],
    note: [''],
    channel: [''],
    channelInst: [''],
    delivered: 1,
    channeled: 1,
    receipt: 0,
    accepted: [0 ,[Validators.required]]
  });

  usersOptions: any[] = [];
  URLs = new Array;

  selectedTab: number = 0;
  idRequest: any;
  idApplicant: any;
  requestData: any = [];
  nameAssigned: string = '-';
  token: any;
  idWorkGroup: any;
  environment: any;
  idUser: any;
  url: any;
  identifier: any;
  userLevel: any;
  parentURL: any;
  title: string = '';
  typeModal: any;

  selectedFiles: any = [];
  clickButton = true;

  constructor(
    private FB: FormBuilder,
    private activeRoute: ActivatedRoute,
    private customizeService: CustomeValidationService,
    private requestService: RequestService,
    private userService: UserService,
    private formDataService: FormDataService,
    private location: Location,
    private router: Router,
    ) { }

  ngOnInit(): void {
    this.getAndSendChilds();
    this.getUserAuth();
  }

  openModal(type: any) {
    this.typeModal=type;
    if(type == 'attend'){
      $('#attendModal').modal('show');
      this.title = '¿Desea atender esta solicitud?';
    }
    if(type == 'assign'){
      $('#assignModal').modal('show');
      this.title = 'Asignar la solicitud';
      this.getWorkGroupTheme();
    }
    if(type == 'reassign'){
      $('#assignModal').modal('show');
      this.title = 'Reasignar la solicitud';
      this.getWorkGroupTheme();
    }
    if(type == 'seg'){
      $('#attendModal').modal('show');
      this.title = '¿Desea darle seguimiento a esta solicitud?';
    }
    if(type == 'close'){
      $('#closeModal').modal('show');
      this.title = 'Cambiar el estatus de la solicitud';
      this.getWorkGroupTheme();
    }
  }

  saveAttend(){
    if(this.clickButton){
      this.clickButton = false;
      const attend = {
        idAssigned: this.idUser,
        idstatus: 2,
        idInstance: 0,
        idOwner: this.idUser,
      };
      this.requestService.updateStatus(this.idRequest, attend).pipe(
        switchMap(() => {
          const attendHistory = {
            date: null,
            idRequest: this.idRequest,
            idUser: this.idUser,
            message: 'Decidió atender la solicitud, el estatus ha cambiado a: Asignado desde Web',
            type: 2
          };
          return this.requestService.saveRequestHistory(attendHistory);
        }),
        switchMap(async () => this.sendEmailNotification())).subscribe(() => {
          // Todas las operaciones se completaron con éxito, puedes continuar aquí.
          this.sendWhatsappNotification();
        },
        error => {
          console.error('Error en alguna de las operaciones:', error);
          this.clickButton = true;
        }
      );
    }
  }

  saveAssign(){
    if(this.clickButton){
      this.clickButton = false;
      const userA = this.usersOptions.find(option => option.idUser == this.reassignForm.get('idAssigned')?.value);
      const assign = {
        idAssigned: this.reassignForm.get('idAssigned')?.value,
        idstatus: 2,
        idInstance: userA.idInstance,
        idOwner: this.idUser,
      };
      this.requestService.updateStatus(this.idRequest, assign).pipe(
        switchMap(() => {
          const user = this.usersOptions.find(option => option.idUser == this.reassignForm.get('idAssigned')?.value);
          const asignHistory = {
            date: null,
            idRequest: this.idRequest,
            idUser: this.idUser,
            message: this.requestData.idAssigned == 0 ? 'Asignó la solicitud a: ' + user.userName + ' desde Web' : 'Reasignó la solicitud a: ' + user.userName + ' desde Web',
            type: 2
          };
          const asignNotification = {
            date: null,
            link: this.idRequest,
            idUser: this.idUser,
            idUserNotify: this.reassignForm.get('idAssigned')?.value,
            message: 'Se te ha asignado una solicitud por parte de: ' + user.userName,
            view: 0,
            typeModule: 'request'
          };
          this.requestService.saveRequestNotification(asignNotification).subscribe(data =>{
            console.log(data);
          });
          return this.requestService.saveRequestHistory(asignHistory).pipe(
            switchMap(async () => this.sendEmailNotification())
          );
        })).subscribe(() => {
          // Todas las operaciones se completaron con éxito, puedes continuar aquí.
          this.sendWhatsappNotification();
        },
        error => {
          console.error('Error en alguna de las operaciones:', error);
          this.clickButton = true;
        }
      );
    }
  }

  saveSeg(){
    if(this.clickButton){
      this.clickButton = false;
      const seg = {
        idstatus: 5
      };
      this.requestService.updateStatus(this.idRequest, seg).pipe(
        switchMap(() => {
          const attendHistory = {
            date: null,
            idRequest: this.idRequest,
            idUser: this.idUser,
            message: 'Decidió darle seguimiento a la solicitud, el estatus ha cambiado a: En proceso desde Web',
            type: 5
          };
          return this.requestService.saveRequestHistory(attendHistory);
        }),
        switchMap(async () => this.sendEmailNotification())).subscribe(() => {
          // Todas las operaciones se completaron con éxito, puedes continuar aquí.
          this.sendWhatsappNotification();
        },
        error => {
          console.error('Error en alguna de las operaciones:', error);
          this.clickButton = true;
        }
      );
    }
  }

  saveClose(){
    if(this.clickButton){
      this.clickButton = false;
      this.uploadImage('accomplished', 'PDF', this.idRequest).then(async (data:any)=>{
        var close = {};
        if(this.closeForm.get('idstatus')?.value == 3){
          close = {
            idstatus: this.closeForm.get("idstatus")?.value,
            channel: this.closeForm.get("channel")?.value,
            idReason: this.closeForm.get("idReason")?.value,
          }
        }
        if(this.closeForm.get('idstatus')?.value == 4){
          close = {
            idstatus: this.closeForm.get("idstatus")?.value,
            idReason: this.closeForm.get("idReason")?.value,
          }
        }
        if(this.closeForm.get('idstatus')?.value == 6){
          let image = this.URLs.length == 0 ? '' : this.URLs;
          console.log(image);
          close = {
            idstatus: this.closeForm.get("idstatus")?.value,
            receipt: this.closeForm.get("receipt")?.value,
            accepted: this.closeForm.get("accepted")?.value,
            channel: this.closeForm.get("channel")?.value,
            idReason: this.closeForm.get("idReason")?.value,
            url_image_accom: image
          }
        }
        let nameStatus = this.getStatusLabel(parseInt(this.closeForm.get("idstatus")?.value));
        let channeled = '';
        if(this.closeForm.get('delivered')?.value == 0){
          channeled = this.closeForm.get('channeled')?.value == 1 ? 'Municipio' :
            this.closeForm.get('channeled')?.value == 2 ? 'Estado' :
            this.closeForm.get('channeled')?.value == 3 ? 'Federal' :
            this.closeForm.get('channeled')?.value == 4 ? 'Otro' : '';
        }
        let history = {
          date: null,
          idRequest: this.idRequest,
          idUser: this.idUser,
          message: 'Cambio la solicitud al estatus: '+ nameStatus + ' desde Web',
          type: this.closeForm.get("idstatus")?.value,
          channeled: channeled,
          channeledInst: this.closeForm.get("channelInst")?.value,
          note: this.closeForm.get("note")?.value
        }
        if(this.closeForm.get('idstatus')?.value == 4 || this.closeForm.get('idstatus')?.value == 6){
          const closedNotification = {
            date: null,
            link: this.idRequest,
            idUser: this.idUser,
            idUserNotify: 0,
            message: this.nameAssigned + ' cambio la solicitud: ' + this.requestData.qrcode + ' al estatus: ' + nameStatus,
            view: 0,
            typeModule: 'request'
          };
          this.requestService.saveRequestNotification(closedNotification).subscribe(data =>{
            console.log(data);
          });
        }
        this.chargingAlert();
        this.requestService.updateStatus(this.idRequest, close).pipe(
          switchMap(() => {
            return this.requestService.saveRequestHistory(history).pipe(
              switchMap(async () => this.sendEmailNotification())
            );
          })).subscribe(() => {
            // Todas las operaciones se completaron con éxito, puedes continuar aquí.
            this.sendWhatsappNotification();
          },
          error => {
            console.error('Error en alguna de las operaciones:', error);
            this.clickButton = true;
          }
        );
      });
    }
  }

  sendWhatsappNotification() {
    this.requestService.sendWhatsappNotification(this.idRequest, this.idUser, this.idWorkGroup).subscribe(
      data => {
        console.log('Envío de notificación exitoso');
        Swal.close();
        window.location.reload();
      },
      error => {
        console.error('Error al enviar la notificación de WhatsApp:', error);
      }
    );
  }

  sendEmailNotification() {
    this.requestService.sendEmailNotification(this.idRequest, this.idUser, this.idWorkGroup).subscribe(
      data => {
        console.log('Envío de notificación de correo exitoso');
      },
      error => {
        console.error('Error al enviar la notificación de Correo:', error);
      }
    );
  }


  updateCheckbox(event: Event) {
    if (event && event.target instanceof HTMLInputElement) {
      this.closeForm.get('accepted')?.setValue(event.target.checked ? 1 : null);
    }
  }

  chargingAlert() {
    Swal.fire({
      icon: 'warning',
      title: "Subiendo archivos",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton()!)
      },
      willClose: () => {

      }
    }).then((result) => {

    })
  }

  loaderAlert() {
    Swal.fire({
      icon: 'warning',
      title: "Subiendo archivos",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton())
      },
      willClose: () => {

      }
    }).then((result) => {

    })
  }

  succesAlert() {

    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

    Toast.fire({
      icon: 'success',
      title: "Archivos subidos",
    })

  }

  onFileChange(event: any) {
    const files = event.target.files;
    // Verificar el límite
    if (this.selectedFiles.length + files.length <= 3) {
      for (let i = 0; i < files.length; i++) {
        this.selectedFiles.push(files[i]);
      }
      console.log(this.selectedFiles);
    } else {
      Swal.fire({
        title: 'Máximo son 3 archivos',
        text: 'Intente de nuevo',
        icon: 'error',
        confirmButtonText: 'Aceptar',
      });
      // Limpia la entrada de archivos para evitar agregar más de 3 archivos.
      event.target.value = '';
    }
  }

  errorAlert() {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

    Toast.fire({
      icon: 'error',
      title: "Error al subir archivos",
    })
  }


  uploadImage(module: string, type: string, id: number) {
    if(this.selectedFiles){
      this.loaderAlert();
      return new Promise((resolve, reject) => {
        const uploadPromises = [];
        console.log(this.selectedFiles);

        for (const file of this.selectedFiles) {
          console.log(file);

          const uploadPromise = new Promise((innerResolve, innerReject) => {
            this.requestService.uploadImage(file, module, type, id).subscribe(
              (data: any) => {
                console.log(data);
                this.URLs.push(data.path);
                innerResolve(data);
              },
              (error) => {
                innerReject(error);
              }
            );
          });

          uploadPromises.push(uploadPromise);
        }

        Promise.all(uploadPromises)
          .then((data) => {
            // Todas las imágenes se han cargado con éxito
            Swal.close();
            this.succesAlert();

            resolve(data);
          })
          .catch((error) => {
            // Al menos una carga de imagen falló
            Swal.close();
            this.errorAlert();

            reject(error);
          });
      });
    } else {
      return new Promise((resolve) =>{
        resolve([]);
      })
    }
  }

  closeModal() {
    $('#attendModal').modal('hide');
    $('#assignModal').modal('hide');
    $('#closeModal').modal('hide');
  }

  getWorkGroupTheme() {
    this.userService.getWorkGroupTheme(this.idUser, this.idWorkGroup, this.requestData.theme_id).subscribe((data) => {
      this.usersOptions = data;
    });
  }

  getAndSendChilds(){
    this.parentURL = this.customizeService.getUrl();
    this.idRequest = this.activeRoute.snapshot.paramMap.get('idRequest');
    localStorage.setItem('token', this.activeRoute.snapshot.paramMap.get('token')??'');
    this.requestService.getRequestDetail(this.idRequest).subscribe(data =>{
      this.requestData = data;
      this.idApplicant = this.requestData.idApplicant;
      if(this.requestData.idAssigned != 0 &&this.requestData.idAssigned != this.idUser)
        this.reassignForm.controls['idAssigned'].setValue(this.requestData.idAssigned);
      if(this.requestData.idstatus != 1){
        this.userService.getUserRequestInfo(this.requestData.idAssigned).subscribe(data =>{
          this.nameAssigned = data[0].userName ?? '-';
        });
      }
      this.formDataService.eventRequestData(this.requestData);
    });
  }

  onStepChange(event: any) {
    this.selectedTab = event.selectedIndex;
  }

  changeSelected(index: number) {
    this.stepper.selectedIndex = index;
  }

  goBack(){
    this.location.back();
  }

  getStatusLabel(idStatus: number): string {
    switch (idStatus) {
      case 1:
        return 'Registro';
      case 2:
        return 'Asignado';
      case 3:
        return 'No viable';
      case 4:
        return 'Cancelado';
      case 5:
        return 'En proceso';
      case 6:
        return 'Meta cumplida';
      default:
        return 'Desconocido';
    }
  }

  goToUpdate(id: number,idUser : number) {
    this.token =localStorage.getItem('token');
    this.idWorkGroup = this.activeRoute.snapshot.paramMap.get('idWorkGroup');
    this.environment = this.activeRoute.snapshot.paramMap.get('environment');
    this.router.navigate(['/updateRequest/' + id + '/' + idUser +'/' + this.idWorkGroup + '/' + this.token + '/' + this.environment]);
  }

  goToEmail(id: number) {
    window.parent.location.href = this.parentURL + '/EmailReceived/emailCompose/'+ id +'/idx.html';
  }

  getUserAuth() {
    this.activeRoute.params.subscribe(routeParams => {
      localStorage.setItem('token', routeParams['token']);
      this.idWorkGroup = routeParams['idWorkGroup'];
      this.idUser = routeParams['idUser'];
      this.url = environment.apiUrl;
      this.userService.getUserInfo(this.idUser).subscribe(data =>{
        this.identifier = data[0].identifier;
        this.userLevel = data[0].userLevel;
      });
      this.token = routeParams['token'];
      localStorage.setItem('environment', routeParams['environment']);
      localStorage.setItem('idWorkGroup', routeParams['idWorkGroup']);
    });
  }

}
