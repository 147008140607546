import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PanelControlService } from 'src/app/shared/services/panel-control.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-panel-control',
  templateUrl: './panel-control.component.html',
  styleUrls: ['./panel-control.component.scss'],
})
export class PanelControlComponent implements OnInit {
  arrayModulesParams: Array<any> = [];
  arrayNotificationParams: Array<any> = [];
  arrayStatusParams: Array<any> = [];

  emailModule: boolean = false;
  promotedModule: boolean = false;
  othersModule: boolean = false;
  email: boolean = false;
  whatsapp: boolean = false;
  sympathizer: boolean = false;
  register: boolean = false;
  assigned: boolean = false;
  onProcess: boolean = false;
  accomplished: boolean = false;
  canceled: boolean = false;
  noViable: boolean = false;
  idUser: any;

  constructor(
    private panelControlService: PanelControlService,
    private activeRoute: ActivatedRoute
  ) {
    
    // this.loading(2000);
    this.activeRoute.params.subscribe(routeParams => {
      this.idUser = routeParams.idUser;
    });

  }

  loading(time: number) {
    Swal.fire({
      title: 'Cargando...',
      html: '',
      timer: time,
      timerProgressBar: true,
      confirmButtonText: 'Aceptar',
    });
  }

  ngOnInit(): void {
    
    this.activeRoute.params.subscribe(routeParams => {
      localStorage.setItem('token', routeParams['token'])
    });
    this.getPanelControlParams();
  }

  getPanelControlParams() {
    this.panelControlService.getPanelControl(this.idUser).subscribe(
      (res: any) => {
        this.arrayModulesParams = res.modules;
        this.arrayNotificationParams = res.notifications;
        this.arrayStatusParams = res.status;

        this.emailModule =
          this.arrayModulesParams[0].emailModule.toLowerCase() == 'true'
            ? true
            : false;
        this.othersModule =
          this.arrayModulesParams[0].othersModule.toLowerCase() == 'true'
            ? true
            : false;

        this.email =
          this.arrayNotificationParams[0].email.toLowerCase() == 'true'
            ? true
            : false;
        this.whatsapp =
          this.arrayNotificationParams[0].whatsapp.toLowerCase() == 'true'
            ? true
            : false;
        this.sympathizer =
            this.arrayNotificationParams[0].sympathizer.toLowerCase() == 'true'
              ? true
              : false;

        this.register =
          this.arrayStatusParams[0].register.toLowerCase() == 'true'
            ? true
            : false;
        this.assigned =
          this.arrayStatusParams[0].assigned.toLowerCase() == 'true'
            ? true
            : false;
        this.onProcess =
          this.arrayStatusParams[0].onProcess.toLowerCase() == 'true'
            ? true
            : false;
        this.accomplished =
          this.arrayStatusParams[0].accomplished.toLowerCase() == 'true'
            ? true
            : false;
        this.canceled =
          this.arrayStatusParams[0].canceled.toLowerCase() == 'true'
            ? true
            : false;
        this.noViable =
          this.arrayStatusParams[0].noViable.toLowerCase() == 'true'
            ? true
            : false;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  saveParams() {
    this.panelControlService
      .updatePanelControl(this.idUser, {
        modules: [
          {
            mail: this.emailModule,
            promoted: this.promotedModule,
            others: this.othersModule,
          },
        ],
        notifications: [
          {
            email: this.email,
            whatsapp: this.whatsapp,
            sympathizer: this.sympathizer,
          },
        ],
        status: [
          {
            register: this.register,
            assigned: this.assigned,
            onProcess: this.onProcess,
            accomplished: this.accomplished,
            canceled: this.canceled,
            noViable: this.noViable,
          },
        ],
      })
      .subscribe(
        (res) => {
          console.log(res);
          Swal.fire(
            {
              icon: 'success',
              title: 'Información actualizada!',
              text: '',
              confirmButtonText: 'Aceptar'
            }
          ).then(
            (result) => {
              this.loading(2000);
            }
          );
          this.getPanelControlParams();
        },
        (err) => {
          console.log(err);
        }
      );
  }
}
