import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class POIService {
  url = environment.apiUrl;

  constructor(public http: HttpClient) {
  }

  storePOI(FormGroup: FormGroup) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };

    console.log(FormGroup);
    return request = this.http.post(this.url + 'POI/store/' + localStorage.getItem('idWorkGroup'), FormGroup, options);
  }

  updatePOI(FormGroup: FormGroup, idPOI: any) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };

    console.log(FormGroup);
    return request = this.http.put(this.url + 'POI/' + idPOI + '/' + localStorage.getItem('idWorkGroup'), FormGroup, options);
  }

  deletePOI(idPOI: any) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };

    return request = this.http.get(this.url + 'deletePOI/' + idPOI, options);
  }

  searchPOI(searchTerm: string | null, page: number, itemsPerPage: number, orderByField: String, orderByDirection: String, userLevel: string, idUser: any) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };

    return request = this.http.get(this.url + 'searchPOI/' + searchTerm + '/' + localStorage.getItem('idWorkGroup') + '/' + page + '/' + itemsPerPage + '/' + orderByField + '/' + orderByDirection + '/' + userLevel + '/' + idUser, options);
  }

  getRelatedToPOI(id: string | null, page: number, itemsPerPage: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };

    return request = this.http.get(this.url + 'related/' + id + '/' + localStorage.getItem('idWorkGroup') + '/' + page + '/' + itemsPerPage, options);
  }

  getPOI(id: string | null) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };

    return request = this.http.get(this.url + 'POI/' + id + '/' + localStorage.getItem('idWorkGroup'), options);
  }

  getPOITypes() {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };

    return request = this.http.get(this.url + "types/all/POI");
  }

  getPOIs(page: number, itemsPerPage: number, orderByField: String, orderByDirection: String, userLevel: string, idUser: string) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };

    return request = this.http.get(this.url + 'POI/' + page + '/' + itemsPerPage + '/' + localStorage.getItem('idWorkGroup') + '/' + orderByField + '/' + orderByDirection + '/' + userLevel + '/' + idUser, options);
  }

  getPOIOptions() {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };

    return request = this.http.get(this.url + 'options/POI/' + localStorage.getItem('idWorkGroup'), options);
  }
}
