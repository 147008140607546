import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { StatsService } from 'src/app/shared/services/stats.service';

@Component({
  selector: 'app-applicants-group-vulnerable-month',
  templateUrl: './applicants-group-vulnerable-month.component.html',
  styleUrls: ['./applicants-group-vulnerable-month.component.scss']
})
export class ApplicantsGroupVulnerableMonthComponent implements OnInit {

  public chartTotalRequest2: any;
  public chartStatusRequest2: any;
  currentYear = new Date().getFullYear();
  lastYear = this.currentYear - 1;
  requestsLast: any;
  requests: any;
  chartData: any;
  chartDataYear: any;
  monthName: any;
  theme!: any[];
  stock1!: any[];
  stock1Year!: any[];
  idMonth!: any;
  idUser!: any;
  token!: any;
  idWorkGroup!: any;

  stock2!: any[];
  stockLastYear!: any;

  themeYear!: any[];

  labels: any[] = [];
  color1 = ['#0e6d0f'];
  color2 = ['#940000'];
  grafico: any;
  public loading = true;
  public loading2 = true;

  constructor(
    private router: Router,
    private statsService: StatsService,
    private activeRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.loading = true;

    Promise.all([this.getCountByMonth()])
      .then(() => {
        this.loading = false;
      })
      .catch((error) => {
        console.error('Error while loading data:', error);
        this.loading = false; // asegurarse de quitar el loader en caso de error
      });
  }

  getCountByMonth() {
    return new Promise((resolve, reject) => {
      this.activeRoute.params.subscribe((routeParams) => {
        console.log(routeParams);
        this.statsService
          .getApplicantsByVulnerableGroupAndMonth(
            routeParams['idWorkGroup'],
            routeParams['month']
          )
          .subscribe((data) => {
            console.log(data);
            const theme = [];
            const stock1 = [];
            const stock2 = [];
            const monthNames = [
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre'
            ];
            this.stockLastYear = data;
            const monthNumber = parseInt(routeParams['month']) - 1;
            this.monthName =
              monthNames[monthNumber].charAt(0).toUpperCase() +
              monthNames[monthNumber].slice(1);

            console.log(this.monthName); // "mayo"

            // Crear el objeto filtrado con tipos explícitos
const filteredData: { [key: string]: number | string } = {};

// Filtrar los datos para reemplazar los valores cero por cadenas vacías
for (const key in this.stockLastYear.results) {
  if (this.stockLastYear.results.hasOwnProperty(key)) {
    if (this.stockLastYear.results[key] !== 0) {
      filteredData[key] = this.stockLastYear.results[key];
    } else {
      filteredData[key] = ""; // Reemplazar los valores cero por cadenas vacías
    }
  }
}
filteredData['Sin definir'] = this.stockLastYear.sin_definir;

            // Crear el dataset con los datos filtrados
            this.chartTotalRequest2 = new Chart('StatTotalRequest', {
              type: 'bar',
              data: {
                labels: Object.keys(filteredData),
                datasets: [
                  {
                    data: Object.values(filteredData),
                    backgroundColor: [
                      '#ffc000',
                      '#2e75b6',
                      '#afabab',
                      '#00b050',
                      '#002060',
                      '#2e75b6',
                      '#bf9000',
                      '#ffd966',
                      '#8faadc',
                      '#c55a11',
                      '#7030a0'
                    ]
                  }
                ]
              },
              plugins: [ChartDataLabels],
              options: {
                indexAxis: 'y',
                aspectRatio: 3,
                plugins: {
                  legend: {
                    display: false
                  },
                  datalabels: {
                    color: 'black',
                    align: 'end',
                    anchor: 'end',
                    labels: {
                      title: {
                        font: {
                          weight: 'bold',
                          size: 14
                        }
                      }
                    }
                  }
                }
              }
            });

            resolve(true);
          });
      });
    });
  }
}
