import { AfterViewInit, Component, ViewChild, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { POIService } from 'src/app/shared/services/poi.service';
import { CustomeValidationService } from 'src/app/shared/services/custome-validation.service';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UserService } from 'src/app/shared/services/user.service';
import { environment } from 'src/environments/environment';

declare var $: any;
@Component({
  selector: 'app-list-points',
  templateUrl: './list-points.component.html',

  styleUrls: ['./list-points.component.scss'],
})
export class ListPointsComponent implements OnInit {

  POIs: any = [];
  p: number = 1;
  itemsPerPage: number = 10;
  getTotalPoI: number = 10;
  totalRecords: number = 0;
  idUser!: string | null;
  token!: string | null;
  idWorkGroup!: string | null;
  environment!: string | null;
  private searchTerms = new Subject<string>();
  currentArray: any = [];
  search: string = "";
  results: any = [];
  loading: boolean = false;

  currentFilter: String = "none";
  selectedField: String = "id";
  selectedOrder: String = "desc";

  userLevel: any;
  identifier: any;
  enabledReport: any;
  url: any;
  searchText: string = ' ';

  idPOI: any;
  qrcode: any;
  constructor(
    private fb: FormBuilder,
    private customizeService: CustomeValidationService,
    private POIService: POIService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private userService: UserService

  ) {

  }

  ngOnInit(): void {
    this.getUserAuth();
    this.searchhold();
  }

  openModal(idPOI: number,qrcode: any) {
    $('#deleteModal').modal('show');
    this.idPOI = idPOI;
    this.qrcode = qrcode;
  }

  closeModal() {
    $('#deleteModal').modal('hide');
  }

  onSearchChange(newValue: string) {
    this.search = newValue.toUpperCase();
  }

  onPageChange(newPageIndex: number): void {
    this.p = newPageIndex;
    this.searchPOI();
  }

  getUserAuth() {
    this.activeRoute.params.subscribe(routeParams => {
      localStorage.setItem('token', routeParams['token']);
      this.idWorkGroup = routeParams['idWorkGroup'];
      this.idUser = routeParams['idUser'];
      this.url = environment.apiUrl;
      console.log(this.idUser);

      this.userService.getUserInfo(routeParams['idUser']).subscribe(data => {
        this.identifier = data[0].identifier;
        this.enabledReport = data[0].enableReports;
        this.userLevel = data[0].userLevel;
        this.searchPOI();
      });

      if (routeParams['token'] && routeParams['idWorkGroup'] && routeParams['idUser']) {
        localStorage.setItem('idWorkGroup', routeParams['idWorkGroup']);
        localStorage.setItem('environment', routeParams['environment']);
        localStorage.setItem('idUser', routeParams['idUser']);
      }
    });
  }

  deleteRegister(idPOI: number){
    this.POIService.deletePOI(idPOI).subscribe(response =>{
      console.log(response);
      window.location.reload();
    });
  }

  goToUpdate(id: number) {
    console.log(id);
    this.router.navigate(['/poi/update/' + id]);
  }

  getSearch(event: any) {
    console.log('trig');
    const searchTerm = this.search;
    this.searchTerms.next(searchTerm);

    if (searchTerm.trim() === '') {
      this.clearSearch();
    }
  }

  clearSearch() {
    this.search = '';
    this.results = [];
  }

  searchhold() {
    this.searchTerms.pipe(
      debounceTime(2000), // Reducido a 300 ms para una respuesta más rápida
      distinctUntilChanged(),
      switchMap((term: string) => {
        this.searchText = term;
        if (term.trim() === '') {
          //this.toggleArray();
         /// this.currentArray = this.POIs;

          this.loading = false;
          return this.POIService.searchPOI(' ', this.p, this.itemsPerPage, 'id', 'desc',this.userLevel, this.idUser);

        } else if (term.length >= 3) {
          this.loading = true;
          return this.POIService.searchPOI(term, this.p, this.itemsPerPage, 'id', 'desc', this.userLevel, this.idUser);
        } else {
          this.currentArray = this.POIs;
          this.loading = false;
          return [];
        }
      })
    ).subscribe((response: any) => {
      this.totalRecords = response.totalRecords
      this.currentArray = response.data;
      //this.toggleArray();
      this.loading = false;
    });
  }

  toggleArray() {
    this.currentArray = this.currentArray === this.POIs ? this.results : this.POIs;
  }

  goToDetail(idPOI: number) {
    this.idUser = localStorage.getItem('idUser');
    this.token = localStorage.getItem('token');
    this.idWorkGroup = localStorage.getItem('idWorkGroup');
    this.environment = localStorage.getItem('environment');

    // const parentURL = this.customizeService.getUrl();
    // window.parent.location.href = parentURL + '/PointsPoints/listPoints.html';
    this.router.navigate(['/detail/poi/' + idPOI + '/' + this.idUser + '/' + this.idWorkGroup + '/' + this.token + '/' + this.environment]);

  }

  getOrder(field: String, order: any) {
    this.selectedField = field;
    this.selectedOrder = order;

    console.log(field);
    console.log(order);

    if (order == 'default') {
      this.currentFilter = 'none';
      this.searchPOI(this.searchText);
    } else {
      this.currentFilter = field;
      this.searchPOI(this.searchText, field, order);
    }

  }

  // getPOI(orderByField: String = 'id', orderByDirection: String = 'desc') {
  //   this.POIService.getPOIs(this.p, this.itemsPerPage, orderByField, orderByDirection, this.userLevel, this.idUser).subscribe((response: any) => {
  //     this.POIs = response.data;
  //     this.totalRecords = response.totalRecords
  //     console.log(this.totalRecords);
  //     this.POIs = response.data;
  //     this.currentArray = response.data;
  //     this.totalRecords = response.totalRecords
  //     console.log(response);
  //   })
  // }

  searchPOI(term: string = ' ', orderByField: String = 'id', orderByDirection: String = 'desc') {
    this.POIService.searchPOI(term, this.p, this.itemsPerPage, orderByField, orderByDirection,this.userLevel, this.idUser).subscribe((response: any) => {
      this.POIs = response.data;
      this.currentArray = response.data;
      this.totalRecords = response.totalRecords
    })
  }


}

