<div class="contenedor">
    <h1 class="page-header" style="padding-left:15px">Propuestas e iniciativas ciudadanas &nbsp;<small></small></h1>
    <hr>
    <div id="idInfo" class="note note-warning" *ngIf="!totalRecords">
        <div class="note-icon"><i class="fa fa-user-check"></i></div>
        <div class="note-content">
            <h4><b>Administración de las propuestas</b></h4>
            <p>En esté apartado podrás consultar y editar a las propuestas en el sistema.</p>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3" style="margin-bottom :10px;">
            <div class="totalProposals">
                <p class="dataBenf">TOTAL:<br> <b style="font-size: 1.8em;">{{totalRecords}}</b></p>
            </div>
        </div>
    </div>
    <br>
    <div class="row" style="text-align: center;">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4" style=" display: inherit; margin-bottom:5px;">
            <span class="spanSearch">Buscar</span>
            <input id="FiltrarContenido" type="search" [(ngModel)]="search" (ngModelChange)="getSearch($event)" (ngModelChange)="onSearchChange($event)" class="" placeholder=" Ingrese su búsqueda" aria-controls="data-table-autofill">
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6" style="font-size: 16px;">
            <pagination-controls (pageChange)="onPageChange($event)" previousLabel="" nextLabel=""></pagination-controls>
        </div>
    </div>
    <div class="row">
        <div class="row showGral m-t-10">
            <div class="col-sm-12 col-md-6">
                <h5 class="m-t-10 m-b-10 m-r-5 showCount">
                    <i class="fa fa-list"></i><span> Mostrando <strong>{{proposals.length}}</strong> de <strong>{{totalRecords}}</strong> registros</span>
                </h5>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="panel-heading-btn">
                    <div class="btn-group m-b-5" *ngIf="identifier == 7 || identifier == 1; else denied">
                        <ng-container *ngIf="enabledReport == 1 else notLevel">
                            <a class="btn btn-light showCount" style="color: black;" href="{{url}}report/exportProposalsReport/{{idWorkGroup}}/{{userLevel}}/{{identifier}}/{{idUser}}/{{search}}" target="_blank"> <i class="fa fa-file-download"></i> Descargar
                            </a>
                        </ng-container>
                    </div>
                    <ng-template #denied>
                        <div class="row">
                            <div class="col">
                                <div ngbDropdown class="d-inline-block">
                                    <button class="btn btn-light dropdown-toggle showCount" id="dropdownBasic1" ngbDropdownToggle><i class="fa fa-file-download"></i> Descargar</button>
                                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                        <p ngbDropdownItem style="text-align: left;">Funcionalidad no disponible para esta cuenta</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template #notLevel>
                        <div class="row">
                            <div class="col">
                                <div ngbDropdown class="d-inline-block">
                                    <button class="btn btn-light dropdown-toggle showCount" id="dropdownBasic1" ngbDropdownToggle><i class="fa fa-file-download"></i> Descargar Directorio</button>
                                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                        <p ngbDropdownItem style="text-align: left;">Funcionalidad no disponible para cuentas gratuitas</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="table-responsive" style="padding: 0%;">
            <table class="table table-hover table-striped table-bordered mb-5">
                <thead class="truncate-text">
                    <tr>
                        <th class="actions-sortable none" style="width: 1%;">Opciones</th>
                        <th class="actions-sortable none" style="width: 2%;">Datos faltantes</th>
                        <th class="actions-sortable none" style="width: 2%;">Puntos de interés</th>
                        <th class="actions-sortable none">Folio</th>
                        <th>Nombre del promovente
                            <app-order (valueResponse)="getOrder('namePromoter',$event)"></app-order>
                        </th>
                        <th>Tema
                            <app-order (valueResponse)="getOrder('themeName',$event)"></app-order>
                        </th>
                        <th>Subtema
                            <app-order (valueResponse)="getOrder('subthemeName',$event)"></app-order>
                        </th>
                        <th>Categoría
                            <app-order (valueResponse)="getOrder('categoryName',$event)"></app-order>
                        </th>
                        <th>Clasificación</th>
                        <th>Prioridad
                            <app-order (valueResponse)="getOrder('urgent',$event)"></app-order>
                        </th>
                        <th>Estatus
                            <app-order (valueResponse)="getOrder('idstatus',$event)"></app-order>
                        </th>
                        <th>Asignado a
                            <app-order (valueResponse)="getOrder('assigned',$event)"></app-order>
                        </th>
                        <th>Cargo
                            <!-- <app-order  (valueResponse)="getOrder('name',$event)"></app-order> -->
                        </th>
                        <th>Fecha de registro
                            <app-order (valueResponse)="getOrder('created_at',$event)"></app-order>
                        </th>
                        <th style="width: 1%;">Visitas</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let proposal of currentArray | paginate: { itemsPerPage: itemsPerPage, currentPage: p, totalItems: totalRecords }">
                        <td>
                            <div class="row">
                                <div class="col">
                                    <div ngbDropdown class="d-inline-block">
                                        <button class="btn btn-primary btn-sm dropdown-toggle" id="dropdownBasic1" ngbDropdownToggle><i class="fa fa-align-justify"></i></button>
                                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                            <button ngbDropdownItem>
                                                <span (click)="goToUpdate(proposal.idProposal)" class="btn btn-sm btn-block btn-white selectHover" style="text-align: left;" title="Editar"><i class="fa fa-edit"></i> Editar</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td class="">
                            <span [title]="getTooltip(proposal.missing_data_color)" [style.background]="getBackgroundColor(proposal.missing_data_color)" class="urgents"></span>
                        </td>
                        <td class="">
                            <span *ngIf="proposal.idPOI != 0 else NotPoi" title="Promovente relacionado a un punto de interés" class="urgents" style="background: #1B1464;"></span>
                            <ng-template #NotPoi>
                                <span class="noturgents"></span>
                            </ng-template>
                        </td>
                        <td class="">
                            <a style="font-family: monospace; color: #348ee3; cursor: pointer;" (click)="goToDetail(proposal.idProposal)"><i class="fa fa-external-link-alt"></i> {{proposal.qrcode}}</a>
                        </td>
                        <td class="">
                            <a style="color: #707478;">{{proposal.namePromoter}}</a>
                        </td>
                        <td class="">
                            <a style="color: #707478;">{{proposal.themeName}}<br></a>
                        </td>
                        <td class="">
                            <a style="color: #707478;">{{proposal.subthemeName}}<br></a>
                        </td>
                        <td class="">
                            <a style="color: #707478;">{{proposal.categoryName}}<br></a>
                        </td>
                        <td class="">
                            <a style="color: #707478;">{{ (proposal.classification_participation && proposal.classification_actor) ? proposal.classification_participation + '/' + proposal.classification_actor : (proposal.classification_participation || proposal.classification_actor || '-') }}<br></a>
                        </td>                          
                        <td class="">
                            <span *ngIf="proposal.urgent === null" class="noturgents"> - </span>
                            <span *ngIf="proposal.urgent === 1" title="Petición del titular" style="background: #83ad47;" class="urgents"> PT </span>
                        </td>
                        <td class="">
                            <ng-container [ngSwitch]="proposal.idstatus">
                                <b class="stsre" *ngSwitchCase="1">Registro</b>
                                <b class="stsas" *ngSwitchCase="2">Asignado</b>
                                <b class="stsno" *ngSwitchCase="3">No viable</b>
                                <b class="stsca" *ngSwitchCase="4">Cancelado</b>
                                <b class="stsen" *ngSwitchCase="5">En proceso</b>
                                <b class="stsme" *ngSwitchCase="6">Meta cumplida</b>
                                <b *ngSwitchDefault>{{ proposal.idstatus }}</b>
                            </ng-container>
                        </td>
                        <td class="">
                            <a style="color: #707478;">{{proposal.assigned || '-'}}</a>
                        </td>
                        <td class="">
                            <a style="color: #707478;">{{proposal.position || '-'}}</a>
                        </td>
                        <td class="">
                            <p style="color: #707478;">{{proposal.created_at}}</p>
                        </td>
                        <td class="">
                            <span class="btn btn-sm width-32 btn btn-sm width-32" (click)="openModal(proposal.theme_id,proposal.idPromoter)" [ngClass]="{'btn-primaryInverse': proposal.idVisit !== null, 'btn-secondaryInverse btn-lg': proposal.idVisit === null}" title="Agregar visita"><i class="fa fa-home"></i></span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<!-- Modal -->
<div class="modal fade" id="visitModal" tabindex="-1" role="dialog" aria-labelledby="visitModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header" style="background: #49b6d6;">
                <h5 class="modal-title" id="exampleModalLabel" style="color:black;font-size: 17px;font-weight: 600;">Visitas hechas al promovente</h5>
                <button type="button" class="close" (click)="closeModal()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="color:#707478;font-size:14px;">
                <form [formGroup]="visitForm">
                    <div>
                        <label><span style="color: red">* </span>Fecha de la visita:</label>
                        <input class="form-control" type="date" placeholder="dd/mm/aaaa" formControlName="date" [ngClass]="{'is-invalid': visitForm.controls['date'].invalid && visitForm.controls['date'].touched}"/>
                        <div id="validationServerUsernameFeedback" class="invalid-feedback">
                            Favor de ingresar una fecha y que sea menor a la actual.
                        </div>
                    </div>
                    <div>
                        <label class="p-t-2">¿Quien realizó la visita?</label>
                        <select class="form-control" formControlName="idUserVisitor">
                            <option selected value=0>Selecciona una opción</option>
                            <option *ngFor="let user of usersOptions" value="{{user.idUser}}">{{user.workgroup}}</option>
                        </select>
                    </div>
                    <div>
                        <label class="col-md-12 pl-1"><span style="color: red">* </span>Nota:</label>
                        <textarea id="description" class="form-control" placeholder="Ingresa una descripción de la visita" formControlName="note" [ngClass]="{'is-invalid': visitForm.controls['note'].invalid && visitForm.controls['note'].touched}"></textarea>    
                        <div id="validationServerUsernameFeedback" class="invalid-feedback">
                            Por favor ingresa una nota.
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light showCount"(click)="closeModal()" style="font-size: 14px;">Cancelar</button>
                <button type="button" class="btn btn-primary" (click)="saveVisit()" [disabled]="!visitForm.valid" style="font-size: 14px;">Guardar</button>
            </div>
        </div>
    </div>
</div>