import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AccesGuard implements CanActivate {

  constructor(private userService: UserService, private router: Router) { };


  canActivate(

    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let acces: string | null = route.paramMap.get('token');

    console.log();
    return this.accesService(acces).then(
      (val) => {
        console.log(val);
        return true
      },

      (err) => {

        this.alertModal();
        return false
      }

    ).catch((error) => {
      console.log(error)
      this.alertModal();

      return false

    })

  }


  alertModal() {
    Swal.fire({
      title: 'La sesión caducó',
      text: 'Vuelve a iniciar sesión',
      icon: 'warning',
      confirmButtonText: 'Aceptar',
      confirmButtonColor: '#49b6d6',
    })
  }

  private accesService(token: string | null) {

    return new Promise((resolve, reject) => {
      this.userService.checkToken(token).subscribe(data => {
        if (data.confirm == true) {
          resolve(true);
        } else {
          reject(false);
        }
      },
        (error) => {
          reject(false);
        },
        () => {
          reject(false);
        }
      );
    });

  }

}
