<div class="contenedor">
    <div class="row" *ngIf="AllurlParticipation != null">
        <div class="col" *ngIf="AllurlParticipation.length > 0">
            <label>Evidencia de participación:</label>
            <div class="row ml-3">
                <label class="col-md-12 p-t-2">
                    Archivos cargados:
                </label>
                <a *ngFor="let url of AllurlParticipation" style=" color: #348ee3;"
                    class="align-middle col-md-12 p-t-2 cursor-pointer" (click)="openFile(url)" target="_blank">
                    {{getFileName(url)}}
                </a>
            </div>
        </div>
    </div>
    <div class="panel panel-info">
        <p class="divData"></p>
    </div>
    <div class="col-12 col-md-4">
        <label>Bitácora:</label>
        <div class="form-control">{{ sympathizer.bitacora || 'Sin información' }}</div>
    </div>
</div>
