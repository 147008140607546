import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { Route, Router } from '@angular/router';
import { FormDataService } from 'src/app/shared/services/form-data.service';


export interface DialogData {
  form: FormGroup;
  survey?: FormGroup | undefined; // Promovidos (opcional)
  visit?: FormGroup | undefined; // Promovidos (opcional)
  idUser: string;
  idWorkGroup: string;
  token: string;
  environment: string;
  from: string;
}


@Component({
  selector: 'app-pass-data',
  templateUrl: './pass-data.component.html',
  styleUrls: ['./pass-data.component.scss']
})


export class PassDataComponent implements OnInit {

  constructor(    
    public dialogRef: MatDialogRef<PassDataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private formDataService: FormDataService,
    private router: Router,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
  }

   passData(passing: boolean) {
    if (passing) {
      this.dialogRef.close();

      console.log(this.data.form);
      this.formDataService.setPassing(true);
      this.formDataService.setFormData(this.data.form,this.data.from);
      // Puedes también pasar el FormGroup survey si está presente
      if (this.data.survey) {
        this.formDataService.setSurveyFormData(this.data.survey,this.data.from);
      }
      if (this.data.visit) {
        this.formDataService.setVisitFormData(this.data.visit,this.data.from);
      }
      this.router.navigate(['/add/poi/'+this.data.idUser+'/'+this.data.idWorkGroup+'/'+this.data.token+'/'+this.data.environment]);
    } else {
      this.dialogRef.close();

      this.formDataService.setPassing(false);
      this.formDataService.setFormData(this.data.form,this.data.from);
      // Puedes también pasar el FormGroup survey si está presente
      if (this.data.survey) {
        this.formDataService.setSurveyFormData(this.data.survey,this.data.from);
      }
      if (this.data.visit) {
        this.formDataService.setVisitFormData(this.data.visit,this.data.from);
      }
      this.router.navigate(['/add/poi/'+this.data.idUser+'/'+this.data.idWorkGroup+'/'+this.data.token+'/'+this.data.environment]);
    }
  }

}

