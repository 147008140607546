<div class="contenedor">
    <form [formGroup]="applicantFormEdit">
        <div class="row">
            <div class="col-12 col-md-3">
                <label>Folio asignado:</label>
                <div class="form-control-disable">{{applicantFormEdit.get('qrcode')?.value}}</div>
            </div>
            <div class="col-12 col-md-3">
                <label>Periodo:</label>
                <select class="form-control" formControlName="period">
                    <option selected value="">Selecciona una opción</option>
                    <option *ngFor="let period of periodOptions" value="{{period.value}}">{{period.name}}</option>
                </select>
            </div>
            <div class="col-12 col-md-3">
                <label>Tipo de gestoría:</label>
                <select class="form-control" formControlName="typeManagment">
                    <option selected value="">Selecciona una opción</option>
                    <option value="Individual">Individual</option>
                    <option value="Grupal">Grupal</option>
                    <option value="Servidor Público">Servidor Público</option>
                </select>
            </div>
            <div class="col-12 col-md-3" *ngIf="applicantFormEdit.get('typeManagment')?.value == 'Grupal'">
                <label>Número de beneficiarios:</label>
                <input class="form-control" type="text" formControlName="groupBe" />
            </div>
            <div class="col-12 col-md-3">
                <label><span style="color: red">* </span>Lugar de levantamiento:</label>
                <select class="form-control" formControlName="idlifting">
                    <option selected value="">Selecciona una opción</option>
                    <option value="Campaña">Campaña</option>
                    <option value="Oficina">Oficina</option>
                    <option value="Página de internet">Página de internet</option>
                    <option value="Llamada telefónica">Llamada telefónica</option>
                    <option value="Correo electrónico">Correo electrónico</option>
                    <option value="Facebook">Facebook</option>
                    <option value="WhatsApp">WhatsApp</option>
                    <option value="Campo">Campo</option>
                    <option value="SolicitApp">SolicitApp</option>
                    <option value="Chatbot">Chatbot</option>
                    <option value="Micrositio">Micrositio</option>
                </select>
            </div>
            <div class="panel panel-info">
                <p class="divData">Datos del solicitante</p>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-4">
                <span style="color: red">* </span>
                <label>Nombre(s):</label>
                <input class="form-control" type="text" placeholder="Ingrese el nombre del solicitante"
                    formControlName="name"
                    [ngClass]="{'is-invalid': applicantFormEdit.controls['name'].invalid && applicantFormEdit.controls['name'].touched}" />
                <div id="validationServerUsernameFeedback" class="invalid-feedback">
                    Por favor ingresa el nombre.
                </div>
            </div>
            <div class="col-12 col-md-4">
                <span style="color: red">* </span>
                <label>Apellido paterno:</label>
                <input class="form-control" type="text" placeholder="Ingrese apellido paterno"
                    formControlName="next_name"
                    [ngClass]="{'is-invalid': applicantFormEdit.controls['next_name'].invalid && applicantFormEdit.controls['next_name'].touched}" />
                <div id="validationServerUsernameFeedback" class="invalid-feedback">
                    Por favor ingresa el apellido paterno.
                </div>
            </div>
            <div class="col-12 col-md-4">
                <span style="color: red">* </span>
                <label>Apellido materno:</label>
                <input class="form-control" type="text" placeholder="Ingrese apellido materno"
                    formControlName="last_name"
                    [ngClass]="{'is-invalid': applicantFormEdit.controls['last_name'].invalid && applicantFormEdit.controls['last_name'].touched}" />
                <div id="validationServerUsernameFeedback" class="invalid-feedback">
                    Por favor ingresa el apellido materno.
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-4">
                <label>Fecha de nacimiento:</label>
                <input class="form-control" type="date" placeholder="dd/mm/aaaa" formControlName="birthDate" />
            </div>
            <div class="col-12 col-md-4">
                <label>Sexo:</label>
                <select class="form-control" formControlName="gender">
                    <option selected value="">Selecciona una opción</option>
                    <option value="Femenino">Femenino</option>
                    <option value="Masculino">Masculino</option>
                    <option value="Otro">Otro</option>
                </select>
            </div>
            <div class="col-12 col-md-4">
                <label>CURP:</label>
                <input class="form-control" type="text" placeholder="Ingrese el CURP" formControlName="curp"
                    maxlength="18"
                    [ngClass]="{'is-invalid': applicantFormEdit.controls['curp'].invalid && applicantFormEdit.controls['curp'].touched}" />
                <div id="validationServerUsernameFeedback" class="invalid-feedback">
                    Por favor ingresa una CURP válida.
                </div>
                <div class="row m-b-10 text-right p-r-20" style="display: block; color: blue;">
                    <a href="https://consultas.curp.gob.mx/CurpSP/gobmx/inicio.jsp" target="_blank">Consultar CURP</a>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-4">
                <label>Correo electrónico:</label>
                <input class="form-control" type="email" placeholder="Ingrese un correo electrónico"
                    formControlName="email"
                    [ngClass]="{'is-invalid': applicantFormEdit.controls['email'].invalid && applicantFormEdit.controls['email'].touched}" />
                <div id="validationServerUsernameFeedback" class="invalid-feedback">
                    Por favor ingresa un correo electrónico válido.
                </div>
            </div>
            <div class="col-12 col-md-4">
                <label>Teléfono de casa:</label>
                <input class="form-control" type="text" placeholder="Ingrese un teléfono local" minlength="10"
                    maxlength="10" formControlName="phone" />
            </div>
            <div class="col-12 col-md-4">
                <span style="color: red">* </span>
                <label>Teléfono celular:</label>
                <input class="form-control" type="text" placeholder="Ingrese un teléfono celular" minlength="10"
                    maxlength="10" formControlName="cellPhone"
                    [ngClass]="{'is-invalid': applicantFormEdit.controls['cellPhone'].invalid && applicantFormEdit.controls['cellPhone'].touched}" />
                <div id="validationServerUsernameFeedback" class="invalid-feedback">
                    Por favor ingresa un teléfono celular.
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-4">
                <label>Estado civil:</label>
                <select class="form-control" formControlName="civilStatus">
                    <option selected value="">Selecciona una opción</option>
                    <option value="Casado/a">Casado/a</option>
                    <option value="Divorciado/a">Divorciado/a</option>
                    <option value="Separado/a">Separado/a</option>
                    <option value="Soltero/a">Soltero/a</option>
                    <option value="Unión libre">Unión libre</option>
                    <option value="Viudo/a">Viudo/a</option>
                </select>
            </div>
            <div class="col-12 col-md-4">
                <label>Lugar de nacimiento (Estado):</label>
                <select class="form-control" formControlName="birthPlace" (change)="onFirstSelectChange()">
                    <option selected value="">Selecciona una opción</option>
                    <option *ngFor="let entitie of entities" value="{{entitie.id}}">{{entitie.name}}</option>
                </select>
            </div>
            <div class="col-12 col-md-4">
                <label>Lugar de nacimiento (Municipio):</label>
                <select class="form-control" formControlName="birthPlaceM">
                    <option selected value="">Selecciona una opción</option>
                    <option *ngFor="let municipalitie of municipalities" value="{{municipalitie.id}}">
                        {{municipalitie.name}}
                    </option>
                </select>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-4">
                <label>Escolaridad:</label>
                <select class="form-control" formControlName="scholarship">
                    <option selected value="">Selecciona una opción</option>
                    <option value="Primaria">Primaria</option>
                    <option value="Secundaria">Secundaria</option>
                    <option value="Preparatoria">Preparatoria</option>
                    <option value="Estudios técnicos">Estudios técnicos</option>
                    <option value="Licenciatura">Licenciatura</option>
                    <option value="Posgrado">Posgrado</option>
                    <option value="Ninguna">Ninguna</option>
                </select>
            </div>
            <div class="col-12 col-md-4">
                <label>Ocupación:</label>
                <select class="form-control" formControlName="ocupation">
                    <option selected value="">Selecciona una opción</option>
                    <option value="Agricultor/a y/o Productor/a rural">Agricultor/a y/o Productor/a rural</option>
                    <option value="Labores domésticas">Labores domésticas</option>
                    <option value="Comerciante">Comerciante</option>
                    <option value="Docente">Docente</option>
                    <option value="Empleado/a">Empleado/a</option>
                    <option value="Empresario/a">Empresario/a</option>
                    <option value="Estudiante">Estudiante</option>
                    <option value="Obrero/a">Obrero/a</option>
                    <option value="Profesionista">Profesionista</option>
                    <option value="Servidor público">Servidor público</option>
                    <option value="Técnico/a">Técnico/a</option>
                    <option value="Ni estudia ni trabaja">Ni estudia ni trabaja</option>
                    <option value="Otro">Otro</option>
                </select>
            </div>
            <div class="col-12 col-md-4">
                <label>Lugar de trabajo:</label>
                <input class="form-control" type="text" placeholder="Nombre de la empresa"
                    formControlName="workplace" />
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-4">
                <label>Teléfono del trabajo:</label>
                <input class="form-control" type="text" placeholder="Ingrese un teléfono del trabajo" minlength="10"
                    maxlength="10" formControlName="cellPhoneOffice" />
            </div>
            <div class="col-12 col-md-4">
                <label>Facebook:</label>
                <input class="form-control" type="text" placeholder="Ingrese Facebook" formControlName="facebook" />
            </div>
            <div class="col-12 col-md-4">
                <label>Instagram:</label>
                <input class="form-control" type="text" placeholder="Ingrese Instagram" formControlName="instagram" />
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-4">
                <label>
                    <span class="reqForm">*</span> Recibe apoyos federales:
                </label>
                <div class="col-md-12">
                    <div class="radio radio-css radio-inline">
                        <input id="federalSupportyes" formControlName="federalSupport" type="radio" [value]="1">
                        <label for="federalSupportyes">Sí</label>
                    </div>
                    <div class="radio radio-css radio-inline">
                        <input id="federalSupportNot" formControlName="federalSupport" type="radio" [value]="0"
                            checked="">
                        <label for="federalSupportNot">No</label>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4" *ngIf="applicantFormEdit.get('federalSupport')?.value">
                <label>¿Cuáles?</label>
                <div class="col-md-12">
                    <input class="form-control" type="text" placeholder="Ingrese el tipo de apoyo"
                        formControlName="typeSupport" />
                </div>
            </div>
        </div>
        <div class="panel panel-info">
            <p class="divData">Dirección</p>
        </div>
        <div class="row">
            <div class="col-12 col-md-4">
                <label>Calle:</label>
                <input class="form-control" type="text" placeholder="Ingrese la calle" formControlName="street" />
            </div>
            <div class="col-12 col-md-4">
                <label>Número exterior:</label>
                <input class="form-control" type="text" placeholder="Ingrese el número exterior"
                    formControlName="streetNumExt" />
            </div>
            <div class="col-12 col-md-4">
                <label>Número interior:</label>
                <input class="form-control" type="text" placeholder="Ingrese el número interior"
                    formControlName="streetNumInt" />
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-4" *ngIf="postalCodeSelected == false else selectedPostal">
                <label>Código postal:</label>
                <div class="input-group">
                    <input type="text" formControlName="postalCode" placeholder="Ingrese el código postal"
                        class="form-control"
                        [ngClass]="{'is-invalid': applicantFormEdit.controls['postalCode'].invalid && applicantFormEdit.controls['postalCode'].touched}">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary" type="button" (click)="searchPostalCode()">
                            <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
                <ng-container #menu *ngIf="postalCodeSearch.length != 0 && postalCodeSelected == false">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 content-seach searchList">
                        <ng-container *ngIf="postalCodeSearch.length == 0 else searchMenu">
                            <label class="darkGray">No hay registros</label>
                        </ng-container>
                        <ng-template #searchMenu>
                            <ng-container *ngFor="let result of postalCodeSearch; let i = index">
                                <ng-container>
                                    <label (click)="selectPostalCode(result.identifier)"
                                        [ngClass]=" i%2==0 ? 'darkGray' : 'lowGray'">{{result.identifier}}</label>
                                </ng-container>
                            </ng-container>
                            <br>
                        </ng-template>
                    </div>
                </ng-container>
            </div>
            <ng-template #selectedPostal>
                <div class="col">
                    <label>Código postal:</label>
                    <div class="alert fade show">
                        <span class="close" (click)="clearPostalCode()" data-dismiss="alert">×</span><span
                            style="color: #707478; font-size: 15px;">{{applicantFormEdit.controls['postalCode'].value}}</span>
                    </div>
                </div>
            </ng-template>
            <div class="col-12 col-md-4">
                <label>Colonia:</label>
                <select class="form-control" formControlName="idNeighborhood"
                    [ngClass]="{'is-invalid': applicantFormEdit.controls['idNeighborhood'].invalid && applicantFormEdit.controls['idNeighborhood'].touched}">
                    <option selected value="">Seleccione una colonia</option>
                    <option *ngFor="let neighborhoodOption of neighborhoodOptions" value="{{neighborhoodOption.id}}">
                        {{neighborhoodOption.name}}</option>
                </select>
                <div id="validationServerUsernameFeedback" class="invalid-feedback">
                    Escoge una opción.
                </div>
            </div>
            <div class="col-12 col-md-4">
                <label>Municipio:</label>
                <input class="form-control" placeholder="Cargando listado..." type="text" [value]="municipalitieName">
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-4">
                <label>Estado:</label>
                <input class="form-control" type="text" placeholder="Cargando listado..." [value]="entitieName">
            </div>

            <div class="col-12 col-md-4">
                <label>Referencia:</label>
                <input class="form-control" type="text" placeholder="Ingrese referencia al domicilio"
                    formControlName="reference" />
            </div>
        </div>
        <!-- <div class="row">
            <div class="col-12 col-md-4">
                <label>
                    <span class="reqForm">*</span> ¿El solicitante representa un punto de interés?
                </label>
                <div class="col-md-12">
                    <div class="radio radio-css radio-inline">
                        <input id="POIyes" formControlName="POIrelated" type="radio" [value]="1">
                        <label for="POIyes">Sí</label>
                    </div>
                    <div class="radio radio-css radio-inline">
                        <input id="POInot" formControlName="POIrelated" type="radio" [value]="0" checked="">
                        <label for="POInot">No</label>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4" *ngIf="applicantFormEdit.get('POIrelated')?.value">
                <label>¿Cuál?</label>
                <div class="col-md-12">
                    <select class="form-control" formControlName="idPOI">
                        <option selected value="0">Selecciona una opción</option>
                        <option value="{{POI.id}}" *ngFor="let POI of POIoptions">{{POI.name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-12 col-md-4" *ngIf="applicantFormEdit.get('POIrelated')?.value">
                <label>Agregar un punto</label>
                <div class="col-md-12">
                    <button type="submit" (click)="openDialog()" class="btn btn-primary btn-lg btn-block">+</button>
                </div>
            </div>
        </div> -->

        <div class="row mt-3" *ngIf="long != null;">
            <app-form-map #request [lat]="lat" [lon]="long"></app-form-map>
        </div>
        <div class="panel panel-info">
            <p class="divData"></p>
        </div>
        <div class="row mt-1">
            <div class="cardClas">
                <div style="text-align: center;"><label
                        style="font-weight: 600; font-size: 16px !important;">Clasificación del solicitante</label>
                </div>
                <div>
                    <div class="row">
                        <div class="col">
                            <div class="col-12">
                                <label>¿Punto de interés?</label>
                                <div class="col-md-12">
                                    <div class="radio radio-css radio-inline">
                                        <input id="POIyes" formControlName="POIrelated" type="radio" [value]="1">
                                        <label for="POIyes">Sí</label>
                                    </div>
                                    <div class="radio radio-css radio-inline">
                                        <input id="POInot" formControlName="POIrelated" type="radio" [value]="0"
                                            checked="">
                                        <label for="POInot">No</label>
                                    </div>
                                </div>
                            </div>
                            <!-- Punto de interés -->
                            <div class="col-12" *ngIf="applicantFormEdit.get('POIrelated')?.value">
                                <label>¿Cuál?</label>
                                <div class="col-md-12">
                                    <select class="form-control" formControlName="idPOI">
                                        <option selected value="0">Selecciona una opción</option>
                                        <option value="{{POI.id}}" *ngFor="let POI of POIoptions">{{POI.name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-12" *ngIf="applicantFormEdit.get('POIrelated')?.value">
                                <label>Agregar un punto</label>
                                <div class="col-md-6">
                                    <button type="submit" (click)="openDialog()"
                                        class="btn btn-primary btn-lg btn-block">+</button>
                                </div>
                            </div>
                            <!-- Fin de opciones de Punto de interés -->
                        </div>
                        <div class="col">
                            <div class="col-12">
                                <label>¿Tipo de participación?</label>
                                <div class="col-md-12">
                                    <div class="radio radio-css radio-inline">
                                        <input id="Participationyes" formControlName="participation" type="radio"
                                            [value]="1">
                                        <label for="Participationyes">Sí</label>
                                    </div>
                                    <div class="radio radio-css radio-inline">
                                        <input id="Participationnot" formControlName="participation" type="radio"
                                            [value]="0" checked="">
                                        <label for="Participationnot">No</label>
                                    </div>
                                </div>
                            </div>
                            <!-- Tipo de participación -->
                            <div class="col-12" *ngIf="applicantFormEdit.get('participation')?.value">
                                <label style="padding-top: 14px;"> </label>
                                <div class="col-md-12">
                                    <select class="form-control" formControlName="participationType">
                                        <option selected value="">Seleccione una opción</option>
                                        <option value="BRIGADISTA (ENCUESTADOR Y/O PROMOTOR DEL VOTO)">BRIGADISTA (ENCUESTADOR Y/O PROMOTOR DEL VOTO)</option>
                                        <option value="PUBLICIDAD (COLOCACIÓN Y DISTRIBUCIÓN DE PROPAGANDA)">PUBLICIDAD (COLOCACIÓN Y DISTRIBUCIÓN DE PROPAGANDA)</option>
                                        <option value="PUBLICIDAD (DIFUSIÓN PERSONALIZADA)">PUBLICIDAD (DIFUSIÓN PERSONALIZADA)</option>
                                        <option value="ESTRUCTURA ELECTORAL (REPRESENTANTE DE CASILLA)">ESTRUCTURA ELECTORAL (REPRESENTANTE DE CASILLA)</option>
                                        <option value="ESTRUCTURA ELECTORAL (SECCIONAL)">ESTRUCTURA ELECTORAL (SECCIONAL)</option>
                                        <option value="ESTRUCTURA ELECTORAL (MOVILIZADOR)">ESTRUCTURA ELECTORAL (MOVILIZADOR)</option>
                                        <option value="ESTRUCTURA ELECTORAL (COORDINADOR DE COLONIA)">ESTRUCTURA ELECTORAL (COORDINADOR DE COLONIA)</option>
                                        <option value="ESTRUCTURA ELECTORAL (ZONAL)">ESTRUCTURA ELECTORAL (ZONAL)</option>
                                        <option value="OTRO">OTRO</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-12" *ngIf="applicantFormEdit.get('participationType')?.value == 'OTRO'">
                                <label>¿Cuál?</label>
                                <input class="form-control" type="text" placeholder="" formControlName="otherType" />
                            </div>
                            <!-- Fin de opciones de Tipo de participación -->
                        </div>
                        <div class="col">
                            <div class="col-12">
                                <label>Actor importante</label>
                                <div class="col-md-12">
                                    <div class="radio radio-css radio-inline">
                                        <input id="Actoryes" formControlName="actor" type="radio" [value]="1">
                                        <label for="Actoryes">Sí</label>
                                    </div>
                                    <div class="radio radio-css radio-inline">
                                        <input id="Actornot" formControlName="actor" type="radio" [value]="0"
                                            checked="">
                                        <label for="Actornot">No</label>
                                    </div>
                                </div>
                            </div>
                            <!-- Actor importante -->
                            <div class="col-12" *ngIf="applicantFormEdit.get('actor')?.value">
                                <label>¿Qué tipo de actor es?</label>
                                <div class="col-md-12">
                                    <select class="form-control" formControlName="actorType">
                                        <option selected value="">Seleccione una opción</option>
                                        <option value="EMPRESARIO">EMPRESARIO</option>
                                        <option value="LÍDER EDUCATIVO">LÍDER EDUCATIVO</option>
                                        <option value="LÍDER RELIGIOSO">LÍDER RELIGIOSO</option>
                                        <option value="LÍDER DE MEDIO DE COMUNICACIÓN">LÍDER DE MEDIO DE COMUNICACIÓN</option>
                                        <option value="LÍDER DE CÁMARA O ASOCIACIÓN EMPRESARIAL">LÍDER DE CÁMARA O ASOCIACIÓN EMPRESARIAL</option>
                                        <option value="LÍDER DEL SECTOR SALUD">LÍDER DEL SECTOR SALUD</option>
                                        <option value="LÍDER SINDICAL">LÍDER SINDICAL</option>
                                        <option value="LÍDER POLÍTICO">LÍDER POLÍTICO</option>
                                        <option value="LÍDER DE COLONIA">LÍDER DE COLONIA</option>
                                        <option value="REPRESENTANTE DE A.C. / ONG">REPRESENTANTE DE A.C. / ONG</option>
                                        <option value="LÍDER JUVENIL">LÍDER JUVENIL</option>
                                        <option value="LÍDER DE MUJERES">LÍDER DE MUJERES</option>
                                        <option value="OTRO">OTRO</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-12" *ngIf="applicantFormEdit.get('actorType')?.value == 'OTRO'">
                                <label>¿Cuál?</label>
                                <input class="form-control" type="text" placeholder="" formControlName="otherActor" />
                            </div>
                            <!-- Fin de opciones de Actor importante -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
