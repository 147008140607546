<div *ngIf="loading; else loaded">
  <div class="loader"></div>
</div>

  <div>

    <div class="container">
      <div class="row graphicTwo">
        <div class="col-md-6">

          <ng-template #loaded>
            <div class="parent">
            <div class="div1">
            <h1>
              Solicitudes levantadas por grupo de edad <br />
             {{ currentYear }}

          </h1>
            </div>
            <div class="div2">
          <h1>
            Solicitudes levantadas por grupo de edad <br />
             {{ lastYear }}

          </h1>

        </div>
      </div>
        </ng-template>
          <div class="chart-container">
            <canvas id="StatTotalRequest">{{ chartTotalRequest2 }}</canvas>

          </div>
        </div>
        <div class="col-md-6">
          <div class="chart-container">
            <canvas id="StatStatusRequest">{{ chartStatusRequest2 }}</canvas>

          </div>
        </div>
      </div>
    </div>
  </div>
