import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core'

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {

  @Input () field: String = "default";
  @Output () valueResponse: EventEmitter<String> = new EventEmitter();

  type: String = "default";

  constructor() { }

  ngOnInit(): void {
    console.log(this.field);
  }

  order(field: String= "default", type: String) {
    this.type = type;
    this.valueResponse.emit(type);

  }

}
