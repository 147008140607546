<br>
<div>
  <div class="container">
    <div class="row graphicTwo">
      <div class="col-md-6">
    <h2>Tipo de solicitantes por género
    </h2>
    <br>
    <div class="chart-container">
      <canvas  id="ChartStatusRequest">{{ chartStatusRequest }}</canvas>
  </div>
</div>
<div class="col-md-6">
  <h2> Tipo de beneficiarios por género
  </h2>
  <br>
  <div class="chart-container">
    <canvas  id="ChartTotalRequest">{{ chartTotalRequest }}</canvas>
</div>
</div>
    </div>
</div>
</div>
