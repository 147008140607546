export class statusModel {
    Asignado: number | undefined = 0;
    Cancelado: number | undefined = 0;
    EnProceso: number | undefined = 0;
    MetaCumplida: number | undefined = 0;
    NoViable: number | undefined = 0;
    Registro: number | undefined = 0;
    Total: number | undefined = 0;
    Simpatizante: number | undefined = 0;
    Promoted: number | undefined = 0;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}


