<div class="contenedor">
  <div class="row">
      <div class="col-md-3">
          <label>Folio:</label>
          <div class="col-md-12">
              <div class="form-control">{{promoter.qrcode}}</div>
          </div>
      </div>
      <div class="col-12 col-md-3">
          <label class="p-t-2">Periodo: </label>
          <div class="form-control">{{ promoter.period || 'Sin información' }}</div>
      </div>
      <div class="col-md-3">
          <label>Tipo de propuesta:</label>
          <div class="col-md-12">
              <div class="form-control">{{promoter.typeManagment || 'Sin información'}}</div>
          </div>
      </div>
      <div class="col-md-3" *ngIf="promoter.typeManagment == 'Grupal'">
          <label>Número de beneficiarios:</label>
          <div class="col-md-12">
              <div class="form-control">{{promoter.groupBe || 'Sin información'}}</div>
          </div>
      </div>
      <div class="col-md-3">
          <label>Lugar de levantamiento:</label>
          <div class="col-md-12">
              <div class="form-control">{{promoter.idlifting}}</div>
          </div>
      </div>
  </div>
  <div class="panel panel-info">
      <p class="divData">Datos del promovente</p>
  </div>
  <div class="row">
      <div class="col">
          <label>Nombre(s):</label>
          <div class="col-md-12">
              <div class="form-control">{{promoter.name}}</div>
          </div>
      </div>
      <div class="col">
          <label>Apellido paterno:</label>
          <div class="col-md-12">
              <div class="form-control">{{promoter.next_name}}</div>
          </div>
      </div>
      <div class="col">
          <label>Apellido materno:</label>
          <div class="col-md-12">
              <div class="form-control">{{promoter.last_name}}</div>
          </div>
      </div>
  </div>
  <div class="row">
      <div class="col">
          <label>Fecha de nacimiento:</label>
          <div class="col-md-12">
              <div class="form-control">{{ (promoter.birthDate | date:'dd/MM/yyyy') || 'Sin información'}}</div>
          </div>
      </div>
      <div class="col">
          <label>Sexo:</label>
          <div class="col-md-12">
              <div class="form-control">{{promoter.gender || 'Sin información'}}</div>
          </div>
      </div>
      <div class="col">
          <label>CURP:</label>
          <div class="col-md-12">
              <div class="form-control">{{promoter.curp || 'Sin información'}}</div>
          </div>
      </div>
  </div>
  <div class="row">
          <div class="col">
          <label>Correo electrónico:</label>
          <div class="col-md-12">
              <div class="form-control">{{promoter.email || 'Sin información'}}</div>
          </div>
      </div>
      <div class="col">
          <label>Teléfono de casa:</label>
          <div class="col-md-12">
              <div class="form-control">{{promoter.phone || 'Sin información'}}</div>
          </div>
      </div>
      <div class="col">
          <label>Teléfono celular:</label>
          <div class="col-md-12">
              <div class="form-control">{{promoter.cellPhone || 'Sin información'}}</div>
          </div>
      </div>
  </div>
  <div class="row">
      <div class="col">
          <label>Estado civil:</label>
          <div class="col-md-12">
              <div class="form-control">{{promoter.civilStatus || 'Sin información'}}</div>
          </div>
      </div>
      <div class="col">
          <label>Lugar de nacimiento (Estado):</label>
          <div class="col-md-12">
              <div class="form-control">{{promoter.birth_entity_name || 'Sin información'}}</div>
          </div>
      </div>
      <div class="col">
          <label>Lugar de nacimiento (Municipio):</label>
          <div class="col-md-12">
              <div class="form-control">{{promoter.birth_municipality_name || 'Sin información'}}</div>
          </div>
      </div>
  </div>
  <div class="row">
      <div class="col">
          <label>Escolaridad:</label>
          <div class="col-md-12">
              <div class="form-control">{{promoter.scholarship || 'Sin información'}}</div>
          </div>
      </div>
      <div class="col">
          <label>Ocupación:</label>
          <div class="col-md-12">
              <div class="form-control">{{promoter.ocupation || 'Sin información'}}</div>
          </div>
      </div>
      <div class="col">
          <label>Lugar de trabajo:</label>
          <div class="col-md-12">
              <div class="form-control">{{promoter.workplace || 'Sin información'}}</div>
          </div>
      </div>
  </div>
  <div class="row">
          <div class="col">
          <label>Teléfono del trabajo:</label>
          <div class="col-md-12">
              <div class="form-control">{{promoter.cellPhoneOffice || 'Sin información'}}</div>
          </div>
      </div>
      <div class="col">
          <label>Facebook:</label>
          <div class="col-md-12">
              <div class="form-control">{{promoter.facebook || 'Sin información'}}</div>
          </div>
      </div>
      <div class="col">
          <label>Instagram:</label>
          <div class="col-md-12">
              <div class="form-control">{{promoter.instagram || 'Sin información'}}</div>
          </div>
      </div>
  </div>
  <div class="panel panel-info">
      <p class="divData">Dirección</p>
  </div>
  <div class="row">
      <div class="col">
          <label>Calle:</label>
          <div class="col-md-12">
              <div class="form-control">{{promoter.street || 'Sin información'}}</div>
          </div>
      </div>
      <div class="col">
          <label>Número exterior:</label>
          <div class="col-md-12">
              <div class="form-control">{{promoter.streetNumExt || 'Sin información'}}</div>
          </div>
      </div>
      <div class="col">
          <label>Número interior:</label>
          <div class="col-md-12">
              <div class="form-control">{{promoter.streetNumInt || 'Sin información'}}</div>
          </div>
      </div>
  </div>
  <div class="row">
      <div class="col">
          <label>Código postal:</label>
          <div class="col-md-12">
              <div class="form-control">{{promoter.postalCode || 'Sin información'}}</div>
          </div>
      </div>
      <div class="col">
          <label>Colonia:</label>
          <div class="col-md-12">
              <div class="form-control">{{promoter.neighborhood_name || 'Sin información'}}</div>
          </div>
      </div>
      <div class="col">
          <label>Municipio:</label>
          <div class="col-md-12">
              <div class="form-control">{{promoter.municipality_name || 'Sin información'}}</div>
          </div>
      </div>
  </div>
  <div class="row">
      <div class="col">
          <label>Estado:</label>
          <div class="col-md-12">
              <div class="form-control">{{promoter.entity_name || 'Sin información'}}</div>
          </div>
      </div>
      <div class="col">
          <label>Sección electoral:</label>
          <div class="col-md-12">
              <div class="form-control">{{promoter.electoralSection || 'Sin información'}}</div>
          </div>
      </div>
      <div class="col">
          <label>Referencia:</label>
          <div class="col-md-12">
              <div class="form-control">{{promoter.reference || 'Sin información'}}</div>
          </div>
      </div>
  </div>
  <div class="panel panel-info">
      <p class="divData">Clasificación del Promovente</p>
  </div>
  <div class="row">
      <div class="col">
          <label>Punto de interés:</label>
          <div class="col-md-12">
              <div class="form-control">{{promoter.POIname || 'NO'}}</div>
          </div>
      </div>
      <div class="col">
          <label>Tipo de participación:</label>
          <div class="col-md-12">
              <div class="form-control">{{promoter.participationType || 'NO'}}</div>
          </div>
      </div>
      <div class="col">
          <label>Actor importante:</label>
          <div class="col-md-12">
              <div class="form-control">{{promoter.actorName || 'NO'}}</div>
          </div>
      </div>
  </div>
</div>
