import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { S3ListComponent } from '../s3-list/s3-list.component';
import { S3UploaderComponent } from './s3-uploader.component';



@NgModule({
  declarations: [
  S3UploaderComponent,
    S3ListComponent
  ],
  imports: [
    CommonModule
  ],
  exports:[
    S3UploaderComponent,
    S3ListComponent
  ],
  providers: [{provide: S3UploaderComponent}],

})
export class S3UploaderModule { }
