import { Component, OnInit } from '@angular/core';
import { FormDataService } from 'src/app/shared/services/form-data.service';
import { RequestService } from 'src/app/shared/services/request.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-detail-request-clasification',
  templateUrl: './detail-request-clasification.component.html',
  styleUrls: ['./detail-request-clasification.component.scss']
})
export class DetailRequestClasificationComponent implements OnInit {

  request: any = [];
  answersID: any = [];
  answersName: any = [];
  lat: number = 25.794452215964732;
  long: number = -100.37729304123305;

  constructor(private formDataService: FormDataService, private requestService: RequestService) { }

  ngOnInit(): void {
    this.getRequestData();
  }

  getRequestData(){
    this.formDataService.requestDataResults$.subscribe(data =>{
      this.request = data;
      if (data.ubication) {
        this.lat = data.ubication.split(',')[0];
        this.long = data.ubication.split(',')[1];
      }
      this.answersID = {
        vulnerable:data.vulnerable,
        institution:data.institution,
        enrollment:data.enrollment,
        information:data.information,
        sport:data.sport,
        level:data.level,
        health:data.health,
        social:data.social,
        educationLevel:data.educationLevel,
        typeDiscipline:data.typeDiscipline,
        purposeScholarship:data.purposeScholarship,        
        typeScholarship:data.typeScholarship,        
        population:data.population,
        federalSupport:data.federalSupportForm,
        typeSupport:data.typeSupportForm,
        categorie:data.categorie,
        disciplineClass:data.disciplineClass,
        typeartCult:data.typeartCult,
        disciplineNivel:data.disciplineNivel,
        espVulnerable:data.espVulnerable == '' ? 0 : data.espVulnerable
      };
      this.getAnswers();
    });
  }

  getAnswers() {
    this.answersName = []; // Inicializa un array vacío
    
    for (const key in this.answersID) {
      if (this.answersID.hasOwnProperty(key)) {
        const item = this.answersID[key];
        // Verifica si el valor actual no es nulo
        if (item !== null && item != 0) {
          const observable = this.requestService.getPollAnswers(item);
          observable.subscribe(response => {
            this.answersName = { ...this.answersName, [key]: response };
          });
        }
      }
    }
  }  

  isObjectEmpty(obj: any): boolean {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

}
