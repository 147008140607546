import { Component, OnInit } from '@angular/core';
import { FormDataService } from 'src/app/shared/services/form-data.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-sympathizer-general-data',
  templateUrl: './sympathizer-general-data.component.html',
  styleUrls: ['./sympathizer-general-data.component.scss']
})
export class SympathizerGeneralDataComponent implements OnInit {

  sympathizer: any = [];
  parties: any = [];
  party: any = '';

  constructor(private formDataService: FormDataService,private userService: UserService) { }

  ngOnInit(): void {
    this.getSympathizerData();
  }

  getPoliticalParties() {
    this.userService.getPoliticalParties().subscribe(response =>{
      this.parties = response;
    })
  }

  getSympathizerData(){
    this.formDataService.sympathizerDataResults$.subscribe(data =>{
      console.log(data);
      this.sympathizer= data;
      this.sympathizer.interests = this.addSpaceAfterComma(this.sympathizer.interests)
    });
  }

  addSpaceAfterComma(text: string): string {
    return text.replace(/,/g, ', ');
  }
}
