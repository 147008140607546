<div class="contenedor">
    <div class="row" *ngIf="!idProposal">
        <div class="panel panel-info">
            <p class="divData">Atender o asignar la propuesta</p>
        </div>
        <div class="col-12 col-md-4">
            <label class="col-md-12 p-t-2">Atender la propuesta:</label>
            <div class="checkbox checkbox-css checkbox-inline ml-3">
                <input id="checkboxAtender" type="checkbox" [(ngModel)]="attendProposal" (change)="handleChange()" />
                <label for="checkboxAtender">¿Atender personalmente la propuesta?</label>
            </div>
        </div>
        <div class="col-12 col-md-4">
            <div class="row m-b-5">
                <label class="p-t-2">Enviar a mi equipo de trabajo:</label>
                <select class="form-control" [(ngModel)]="workGroup" (change)="handleChange()">
                    <option selected value=0>Selecciona una opción</option>
                    <option *ngFor="let user of usersOptions" value="{{user.idUser}}">{{user.workgroup}}</option>
                </select>
            </div>
        </div>  
        <div class="panel panel-info">
            <p class="divData"></p>
        </div>      
    </div>    
    <div class="row">
        <p>Nombre del promovente:</p>
        <p>{{ nameComplete }}</p>
    </div>
    <div class="row">
        <p>Registro completo</p>
    </div>
    <div class="row">
        <div class="col cont">
            <button type="button" class="btn btn-primary" (click)="buttonSave()" [disabled]="!formGroupValid">Guardar</button>
        </div>
    </div>      
</div>
