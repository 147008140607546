import { Component, OnInit } from '@angular/core';
import {Chart, registerables } from 'chart.js'
import {Router} from '@angular/router';
import ChartDataLabels from 'chartjs-plugin-datalabels';
@Component({
  selector: 'app-people-registered-supporters',
  templateUrl: './people-registered-supporters.component.html',
  styleUrls: ['./people-registered-supporters.component.scss']
})
export class PeopleRegisteredSupportersComponent implements OnInit {
  public chartStatusRequestPeople: any;

  constructor(private router:Router){

  }
  ngOnInit(): void {

    this.StatusRequestChart();
  }
  StatusRequestChart(){
    this.chartStatusRequestPeople = new Chart("ChartStatusRequestPeople", {
       type: 'bar',
      data: {
        labels:['Enero','Febrero','Marzo','Abril','Mayo','Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
	      datasets: [
          {
            data:[25,6,21,12,8,15,9,23,11,12,16,14,30],
            backgroundColor: ['red'],
            label:'Pendientes' ,
          }, {
            data:[17,3,9,5,3,4,7,16,9,4,9,7],
            backgroundColor: ['green'],
            label:'Cerradas',
          }
        ],

      },
      plugins: [ChartDataLabels],
      options: {
        indexAxis: 'y',
        aspectRatio:2,
        plugins: {
          legend: {
              position: 'bottom'
          },
          datalabels: {
            color: 'black',
            anchor: 'end',
            align: 'end',
            labels: {
              title: {
                font: {
                  weight: 'bold',
                  size: 17,
                }
              },

            }
          }

      },
      onClick: (e) => {

        this.router.navigate(['monthly-history']);
      }


      }
    });

  }

}
