import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { FormGroup } from '@angular/forms';
import { FormDataService } from './form-data.service';

@Injectable({
  providedIn: 'root'
})
export class RequestService {
  url = environment.apiUrl;
  userName = environment.geonamesUserName;

  constructor(public http: HttpClient, public formDataService: FormDataService) {
  }

  saveApplicant(applicantData: FormGroup) {
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return this.http.post<any>(this.url + 'saveApplicant', applicantData, options);
  }

  saveRequest(requestData: FormGroup) {
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return this.http.post<any>(this.url + 'saveRequest', requestData, options);
  }
  getAllCountries() {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any[]>(this.url + 'countries/all', options);
  }

  getLocationInfo(country: string) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any[]>('https://restcountries.com/v3.1/translation/' + country + '?lang=es', options);
  }

  updateApplicant(idApplicant: number, promoterData: any) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return this.http.put<any>(this.url + 'updateApplicant/' + idApplicant, promoterData, options);
  }

  updateRequest(idRequest: number, proposalData: any) {

    if (this.formDataService.AllurlINE) {
      this.formDataService.AllurlINE.forEach((url: any) => {
        proposalData.url_image_ine = url;
      });
    }

    if (this.formDataService.AllurlAcuse.length > 0) {
      console.log(this.formDataService.AllurlAcuse);

      proposalData.url_image_Acuse = this.formDataService.AllurlAcuse;
    }

    if (this.formDataService.AllurlExtra) {
      this.formDataService.AllurlExtra.forEach((url: any) => {
        proposalData.url_image_Extra.push(url)

      });
    }

    if (this.formDataService.AllurlSP) {

      this.formDataService.AllurlSP.forEach((url: any) => {
        proposalData.url_image_sp.push(url)
      });
    }
    console.log(proposalData);
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return this.http.put<any>(this.url + 'updateRequest/' + idRequest, proposalData, options);
  }

  updateStatus(idRequest: number, statusData: any) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return this.http.put<any>(this.url + 'updateStatus/' + idRequest, statusData, options);
  }

  saveRequestHistory(historyData: any) {
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return this.http.post<any>(this.url + 'saveRequestHistory', historyData, options);
  }

  saveRequestNotification(notificationData: any) {
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return this.http.post<any>(this.url + 'saveRequestNotification', notificationData, options);
  }

  saveForm(formData: any) {
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return this.http.post<any>(this.url + 'saveRequestForm', formData, options);
  }

  updateForm(idForm: number, formData: any) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return this.http.put<any>(this.url + 'updateRequestForm/' + idForm, formData, options);
  }

  searchApplicant(name: string) {
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return this.http.get<any>(this.url + 'findApplicant/' + name + '/' + localStorage.getItem('idWorkGroup'), options);
  }

  getApplicantData(idApplicant: number) {
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return this.http.get<any>(this.url + 'getApplicantData/' + idApplicant, options);
  }

  getRequestData(idRequest: number) {
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return this.http.get<any>(this.url + 'getRequestData/' + idRequest, options);
  }

  getRequestDetail(idRequest: number) {
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return this.http.get<any>(this.url + 'getRequestDetail/' + idRequest, options);
  }

  getPollAnswers(idA: number) {
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return this.http.get<any>(this.url + 'getPollAnswers/' + idA, options);
  }

  getStatusCount(idworkgroup: string, level: number, idUser: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any[]>(this.url + 'stats/' + idworkgroup + '/' + level + '/' + idUser, options);
  }


  searchPostalCode(search: any) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any[]>(this.url + 'postalcode/search/' + search, options);
  }

  searchInternationalPostalCode(search: any, country: any) {
    let request;
    return request = this.http.get<any>("http://secure.geonames.org/postalCodeSearchJSON?maxRows=3&username=" + this.userName + "&country=" + country + "&postalcode_startsWith=" + search);
  }

  getDirectionByPostalCode(postalCode: String) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any[]>(this.url + 'postalcode/direction/' + postalCode, options);
  }

  getEntities() {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any[]>(this.url + 'entities/all', options);
  }

  getAllThemes() {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any[]>(this.url + 'themes', options);
  }

  getAllSubthemes(themeId: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any[]>(this.url + 'subthemes/' + themeId, options);
  }

  getAllCategories(subthemeId: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any[]>(this.url + 'categories/' + subthemeId, options);
  }


  getEntity(idEntity: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any>(this.url + 'getEntity/' + idEntity, options);
  }

  getMunicipalities(idEntity: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any[]>(this.url + 'getMunicipality/' + idEntity, options);
  }

  getNeighboorhoods(idMunicipality: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any[]>(this.url + 'getNeighboorhoods/' + idMunicipality, options);
  }

  getSearchCategory(category: string) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get<any[]>(this.url + 'categoryFind/' + category, options);
  }

  uploadImage(recivedFile: any, module: string, type: string, id: number) {

    const formData: FormData = new FormData();
    formData.append('archive', recivedFile, recivedFile.name);
    // Agrega el token de autorización a las cabeceras
    const httpHeaders = new HttpHeaders()
      .append('Authorization', 'Bearer ' + localStorage.getItem('token'));

    const options = { headers: httpHeaders };

    return this.http.post<any>(this.url + 'uploadImage/' + module + '/' + type + '/' + id, formData, options);
  }

  getVisitData(idApplicant: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get(this.url + 'getVisitApplicant/' + idApplicant, options);
  }

  getRequestHistoryData(idRequest: number) {
    let request;
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return request = this.http.get(this.url + 'getRequestHistoryData/' + idRequest, options);
  }

  sendWhatsappNotification(idRequest: number, idUser: number, idWorkGroup: string) {
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return this.http.get<any>(this.url + 'sendWhatsAppNotification/' + idRequest + '/' + idUser + '/' + idWorkGroup, options);
  }

  sendEmailNotification(idRequest: number, idUser: number, idWorkGroup: string) {
    const httpHeaders = new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const options = { headers: httpHeaders };
    return this.http.post<any>(this.url + 'sendEmailNotification/' + idRequest + '/' + idUser + '/' + idWorkGroup, options);
  }
}
