import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormMapComponent } from './form-map.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';



@NgModule({
  declarations: [FormMapComponent],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    NgxSkeletonLoaderModule,
  ],
  exports: [FormMapComponent],

})
export class FormMapModule { }
