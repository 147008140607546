<div class="contenedor">
  <div class="row">
    <div class="col-12 col-md-4">
      <label class="p-t-2">Periodo: </label>
      <div class="form-control">{{ sympathizer.period || 'Sin información' }}</div>
    </div>
    <div class="col-12 col-md-4">
      <label class="p-t-2">Lugar de levantamiento: </label>
      <div class="form-control">{{ sympathizer.idlifting || 'Sin información' }}</div>
    </div>
  </div>
  <div class="panel panel-info">
    <p class="divData">Datos del simpatizante</p>
  </div>
  <div class="row">
    <div class="col-12 col-md-4">
      <label>Nombre completo:</label>
      <div class="form-control">{{ sympathizer.name }} {{ sympathizer.next_name }} {{ sympathizer.last_name }}
      </div>
    </div>
    <div class="col-12 col-md-4">
      <label>Fecha de nacimiento:</label>
      <div class="form-control">{{ (sympathizer.birthDate | date:'dd/MM/yyyy') || 'Sin información' }}</div>
    </div>
    <div class="col-12 col-md-4">
      <label>Sexo:</label>
      <div class="form-control"> {{ sympathizer.gender || 'Sin información' }}</div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-4">
      <label>Curp:</label>
      <div class="form-control">{{ sympathizer.curp == 0 ? 'Sin información' : sympathizer.curp
        || 'Sin información' }}</div>
    </div>
    <div class="col-12 col-md-4">
      <label>Correo electrónico:</label>
      <div class="form-control"> {{ sympathizer.email || 'Sin información' }}</div>
    </div>
    <div class="col-12 col-md-4">
      <label>Teléfono de casa:</label>
      <div class="form-control">{{ sympathizer.phone == 0 ? 'Sin información' : sympathizer.phone ||
        'Sin información' }}</div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-4">
      <label>Teléfono celular:</label>
      <div class="form-control"> {{ sympathizer.cellPhone || 'Sin información' }}</div>
    </div>
    <div class="col-12 col-md-4">
      <label>Estado civil:</label>
      <div class="form-control">{{ sympathizer.civilStatus || 'Sin información' }}</div>
    </div>
    <div class="col-12 col-md-4">
      <label>Lugar de nacimiento (Estado):</label>
      <div class="form-control">{{ sympathizer.birth_entity_name || 'Sin información' }}</div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-4">
      <label>Lugar de nacimiento (Municipio):</label>
      <div class="form-control">{{ sympathizer.birth_municipality_name || 'Sin información' }}</div>
    </div>
    <div class="col-12 col-md-4">
      <label>Escolaridad:</label>
      <div class="form-control">{{ sympathizer.scholarship || 'Sin información' }}</div>
    </div>
    <div class="col-12 col-md-4">
      <label>Ocupación:</label>
      <div class="form-control">{{ sympathizer.ocupation || 'Sin información' }}</div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-4">
      <label>Lugar de trabajo:</label>
      <div class="form-control">{{ sympathizer.workplace == 0 ? 'Sin información' : sympathizer.workplace
        || 'Sin información' }}</div>
    </div>
    <div class="col-12 col-md-4">
      <label>Teléfono del trabajo:</label>
      <div class="form-control">{{ sympathizer.cellPhoneOffice == 0 ? 'Sin información' :
        sympathizer.cellPhoneOffice || 'Sin información' }}</div>
    </div>
    <div class="col-12 col-md-4">
      <label>Facebook:</label>
      <div class="form-control">{{ sympathizer.facebook == 0 ? 'Sin información' :
        sympathizer.facebook || 'Sin información' }}</div>
    </div>
    <div class="col-12 col-md-4">
      <label>Instagram:</label>
      <div class="form-control">{{ sympathizer.instagram == 0 ? 'Sin información' :
        sympathizer.instagram || 'Sin información' }}</div>
    </div>
  </div>
  <div class="panel panel-info">
    <p class="divData">Dirección</p>
  </div>
  <div class="row">
    <div class="col-12 col-md-4">
      <label>Calle:</label>
      <div class="form-control">{{ sympathizer.street == 0 ? 'Sin información' : sympathizer.street
        || 'Sin información' }}</div>
    </div>
    <div class="col-12 col-md-4">
      <label>Número exterior:</label>
      <div class="form-control">{{ sympathizer.streetNumExt == 0 ? 'Sin información' : sympathizer.streetNumExt ||
        'Sin información' }}</div>
    </div>
    <div class="col-12 col-md-4">
      <label>Número interior:</label>
      <div class="form-control">{{ sympathizer.streetNumInt == 0 ? 'Sin información' : sympathizer.streetNumInt ||
        'Sin información' }}</div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-4">
      <label>Código postal:</label>
      <div class="form-control">{{ sympathizer.postalCode || 'Sin información' }}</div>
    </div>

    <div class="col-12 col-md-4">
      <label>Colonia:</label>
      <div class="form-control">{{ sympathizer.neighborhood_name || 'Sin información' }}</div>
    </div>
    <div class="col-12 col-md-4">
      <label>Municipio:</label>
      <div class="form-control">{{ sympathizer.municipality_name || 'Sin información' }}</div>
    </div>
    <div class="col-12 col-md-4">
      <label>Estado:</label>
      <div class="form-control">{{ sympathizer.entity_name || 'Sin información' }}</div>
    </div>
    <div class="col-12 col-md-4" *ngIf="sympathizer.country">
      <label>País:</label>
      <div class="form-control">{{ sympathizer.country || 'Sin información' }}</div>
    </div>

    <div class="col-12 col-md-4">
      <label>Sección electoral:</label>
      <div class="form-control">{{ sympathizer.electoralSection || 'Sin información' }}</div>
    </div>
    <div class="col-12 col-md-4">
      <label>Distrito local:</label>
      <div class="form-control">
        {{ sympathizer.local_district || 'Sin información'}}</div>
    </div>
    <div class="col-12 col-md-4">
      <label>Distrito federal:</label>
      <div class="form-control">
        {{ sympathizer.federal_district || 'Sin información'}}</div>
    </div>
    <div class="col-12 col-md-4">
      <label>Referencia:</label>
      <div class="form-control">{{ sympathizer.reference || 'Sin información' }}</div>
    </div>
  </div>
  <div class="panel panel-info">
    <p class="divData">Datos adicionales</p>
  </div>


  <div class="row mb-3">
    <div class="col-12 col-md-4">
      <label>Afiliado a algún partido político:</label>
      <div class="form-control">{{ sympathizer.affiliated == 1 ? 'SÍ' : 'NO' }}</div>
    </div>
    <ng-container *ngIf="sympathizer.affiliated">
      <div class="col-12 col-md-4">
        <label>Partido político:</label>
        <div class="form-control">{{ sympathizer.affiliated_political_party || 'Sin información' }}</div>
      </div>
      <div class="col-12 col-md-4">
        <label>Fecha de afiliación:</label>
        <div class="form-control">{{ (sympathizer.affiliation_date | date:'dd/MM/yyyy') || 'Sin información' }}
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="sympathizer.interests">
      <div class="col-12 col-md-4">
        <label>Temas de interés: </label>
        <div class="form-control">{{ sympathizer.interests || 'Sin información' }}</div>
      </div>
      <div class="col-12 col-md-4">
        <label>Recibe apoyos federales:</label>
        <div class="form-control">{{ sympathizer.federalSupport == 1 ? 'SÍ' : 'NO' }}</div>
      </div>
      <ng-container *ngIf="sympathizer.federalSupport">
        <div class="col-12 col-md-4">
          <label>¿Cuáles?</label>
          <div class="form-control">{{ sympathizer.typeSupport || 'Sin información' }}</div>
        </div>
      </ng-container>
    </ng-container>
  </div>




  <div class="panel panel-info">
    <p class="divData">Clasificación del Simpatizante</p>
  </div>
  <div class="row">
    <div class="col-12 col-md-4">
      <label>Punto de interés:</label>
      <div class="form-control">{{ sympathizer.POI_name || 'NO' }}</div>
    </div>
    <div class="col-12 col-md-4">
      <label>Tipo de participación:</label>
      <div class="form-control">{{ sympathizer.participationType || 'NO' }}</div>
    </div>
    <div class="col">
      <label>Actor importante:</label>
      <div class="col-md-12">
        <div class="form-control">{{sympathizer.actorName || 'NO'}}</div>
      </div>
    </div>
  </div>
</div>
