import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core'
import Swal from 'sweetalert2';

@Component({
  selector: 'app-maxchardec',
  templateUrl: './maxchardec.component.html',
  styleUrls: ['./maxchardec.component.scss']
})
export class MaxchardecComponent implements OnInit {

  @Input() inputValue: string = "";
  @Output() valueResponse: EventEmitter<String> = new EventEmitter();

  maxLength = 250;

  constructor() { }

  get remainingCharacters(): number {
    //console.log(this.inputValue.length);
    const remain = this.maxLength - this.inputValue.length;
    if (remain > this.maxLength) {
      this.fireMaxAlert();
    }
    return this.maxLength - this.inputValue.length;
  }

  fireMaxAlert() {
    Swal.fire(
      {
        icon: 'error',
        title: 'Excediste el numero de caracteres permitidos',
        text: '',
        confirmButtonText: 'Aceptar'
      }
    );
  }

  ngOnInit(): void {
  }

}
