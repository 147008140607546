import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SympathizerService } from 'src/app/shared/services/sympathizer.service';
import { Location } from '@angular/common';
import { FormDataService } from 'src/app/shared/services/form-data.service';
import { MatStepper } from '@angular/material/stepper';


@Component({
  selector: 'app-sympathizer-detail',
  templateUrl: './sympathizer-detail.component.html',
  styleUrls: ['./sympathizer-detail.component.scss']
})
export class SympathizerDetailComponent implements OnInit {
  @ViewChild('stepper') stepper!: MatStepper;

  idSympathizer: any;
  sympathizer: any = [];
  visits: any = [];
  AllurlParticipation: any = [];
  selectedTab: number = 0;
  token: any;
  idWorkGroup: any;
  environment: any;

  constructor(private sympathizerService: SympathizerService, private activeRoute: ActivatedRoute, private location: Location, private formDataService: FormDataService, private router: Router) { }


  ngOnInit(): void {
    this.getAndSendChilds();
  }

  getAndSendChilds() {
    this.idSympathizer = this.activeRoute.snapshot.paramMap.get('idSympathizer');
    this.idWorkGroup = this.activeRoute.snapshot.paramMap.get('idWorkGroup');
    this.environment = this.activeRoute.snapshot.paramMap.get('environment');

    if (this.idSympathizer != null) {
      this.sympathizerService.getSympathizerData(this.idSympathizer).subscribe((response: any) => {
        this.sympathizer = response;
        this.formDataService.eventSympathizerData(this.sympathizer);
      });
    }
  }

  goToUpdate(id: number, idUser: number) {
    this.token = localStorage.getItem('token');
    this.router.navigate(['/updateSympathizer/' + id + '/' + idUser + '/' + this.idWorkGroup + '/' + this.token + '/' + this.environment]);
  }

  goBack() {
    this.location.back();
  }

  onStepChange(event: any) {
    this.selectedTab = event.selectedIndex;
  }

  changeSelected(index: number) {
    this.stepper.selectedIndex = index;
  }
}
