<div class="contenedor">
  <div class="row" style="margin-top: 10px;">
    <ng-container *ngIf="idSympathizer == null; else update">
    </ng-container>
    <ng-template #update>
      <div class="back btn btn-sm">
        <i class="fa fa-arrow-left fa-lg" style="cursor: pointer;" (click)="goBack()" aria-hidden="true"></i>
      </div>
    </ng-template>
    <div class="col-md-6">
      <div *ngIf="!idSympathizer">
        <div class="form-group m-b-5">
          <h1 class="page-header" style="padding-left: 15px;">Agregar simpatizante &nbsp;<small></small></h1>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div *ngIf="!idSympathizer" class="d-flex flex-column justify-content-end align-items-end h-100">
        <div class="m-b-5">
          <button type="button" class="btn btn-secondary" style="padding-top: 10px; padding-bottom: 10px;"
            (click)="cleanForm()">
            Vaciar formulario
          </button>
        </div>
      </div>
    </div>
  </div>
  <hr>

  <ng-container *ngIf="international == false;else internationalForm">

    <form [formGroup]="sympathizerForm">
      <div class="row">
        <div class="col-12 col-md-4" *ngIf="idSympathizer">
          <label>Folio asignado:</label>
          <div class="form-control-disable">{{qrcode}}</div>
        </div>
        <div class="col-12 col-md-4" *ngIf="!idSympathizer">
          <label class="align-middle col-md-12 p-t-2">
            <span class="reqForm">*</span>
            <a (click)="openModal()" class="privacy">Aviso de privacidad <cite>click aquí</cite></a>
          </label>
          <div class="col-md-12">
            <div class="checkbox checkbox-css">
              <input id="privacyNotice" formControlName="privacyNotice" type="checkbox"
                [checked]="sympathizerForm.get('privacyNotice')?.value === 1" (change)="updateCheckbox($event)">
              <label for="privacyNotice">Aceptar</label>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <label>Periodo:</label>
          <select class="form-control" formControlName="period">
            <option selected value="">Selecciona una opción</option>
            <option *ngFor="let period of periodOptions" value="{{period.value}}">{{period.name}}</option>
          </select>
        </div>
        <div class="col-12 col-md-4">
          <label class="col-md-12"><span style="color: red">* </span>Lugar de levantamiento:</label>
          <select class="form-control" formControlName="idlifting">
            <option selected value="">Selecciona una opción</option>
            <option value="Oficina">Oficina</option>
            <option value="Página de internet">Página de internet</option>
            <option value="Llamada telefónica">Llamada telefónica</option>
            <option value="Correo electrónico">Correo electrónico</option>
            <option value="Facebook">Facebook</option>
            <option value="WhatsApp">WhatsApp</option>
            <option value="Campo">Campo</option>
            <option value="Micrositio">Micrositio</option>
          </select>
        </div>
      </div>
      <div class="">
        <p class="divData">Datos del simpatizante</p>
      </div>
      <div class="row">
        <div class="col-12 col-md-4">
          <span style="color: red">* </span>
          <label>Nombre(s):</label>
          <input class="form-control" type="text" placeholder="Ingrese el nombre del simpatizante"
            formControlName="name"
            [ngClass]="{'is-invalid': sympathizerForm.controls['name'].invalid && sympathizerForm.controls['name'].touched}" />
          <div id="validationServerUsernameFeedback" class="invalid-feedback">
            Por favor ingresa el nombre.
          </div>
        </div>
        <div class="col-12 col-md-4">
          <span style="color: red">* </span>
          <label>Apellido paterno:</label>
          <input class="form-control" type="text" placeholder="Ingrese apellido paterno" formControlName="next_name"
            [ngClass]="{'is-invalid': sympathizerForm.controls['next_name'].invalid && sympathizerForm.controls['next_name'].touched}" />
          <div id="validationServerUsernameFeedback" class="invalid-feedback">
            Por favor ingresa el apellido paterno.
          </div>
        </div>
        <div class="col-12 col-md-4">
          <span style="color: red">* </span>
          <label>Apellido materno:</label>
          <input class="form-control" type="text" placeholder="Ingrese apellido materno" formControlName="last_name"
            [ngClass]="{'is-invalid': sympathizerForm.controls['last_name'].invalid && sympathizerForm.controls['last_name'].touched}" />
          <div id="validationServerUsernameFeedback" class="invalid-feedback">
            Por favor ingresa el apellido materno.
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-4">
          <label>Fecha de nacimiento:</label>
          <input class="form-control" type="date" placeholder="dd/mm/aaaa" formControlName="birthDate" />
        </div>
        <div class="col-12 col-md-4">
          <label>Sexo:</label>
          <div class="col-md-12">
            <select class="form-control" formControlName="gender">
              <option selected value="">Selecciona una opción</option>
              <option value="Femenino">Femenino</option>
              <option value="Masculino">Masculino</option>
              <option value="Otro">Otro</option>
            </select>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <label>CURP:</label>
          <div class="col-md-12">
            <input class="form-control" type="text" placeholder="Ingrese el CURP" formControlName="curp" maxlength="18"
              [ngClass]="{'is-invalid': sympathizerForm.controls['curp'].invalid && sympathizerForm.controls['curp'].touched}" />
            <div id="validationServerUsernameFeedback" class="invalid-feedback">
              Por favor ingresa una CURP válida.
            </div>
          </div>
          <div class="row m-b-10 text-right p-r-20" style="display: block;font-size: 15px;">
            <a href="https://consultas.curp.gob.mx/CurpSP/gobmx/inicio.jsp" target="_blank">Consultar
              CURP</a>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-4">
          <label>Correo electrónico:</label>
          <div class="col-md-12">
            <input class="form-control" type="email" placeholder="Ingrese un correo electrónico" formControlName="email"
              [ngClass]="{'is-invalid': sympathizerForm.controls['email'].invalid && sympathizerForm.controls['email'].touched}" />
            <div id="validationServerUsernameFeedback" class="invalid-feedback">
              Por favor ingresa un correo electrónico válido.
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4" *ngIf="international == false;else interphone">
          <label>Teléfono de casa:</label>
          <div class="col-md-12">
            <input class="form-control" type="text" placeholder="Ingrese un teléfono local" minlength="10"
              maxlength="10" formControlName="phone" />
          </div>
        </div>
        <ng-template #interphone>
          <div class="col-12 col-md-4">
            <label>Teléfono de casa:</label>
            <div class="row">
              <div class="col-md-4">
                <input class="form-control" type="text" disabled [(ngModel)]="lada"
                  [ngModelOptions]="{standalone: true}" minlength="10" maxlength="10" />
                <div id="validationServerUsernameFeedback" class="invalid-feedback">
                  Por favor ingresa un teléfono celular.
                </div>
              </div>
              <div class="col-md-8">
                <input class="form-control" type="text" placeholder="Ingrese un teléfono local" minlength="10"
                  maxlength="10" [(ngModel)]="houseNumber" [ngModelOptions]="{standalone: true}"
                  (change)="parseNumber(houseNumber,'phone')"
                  [ngClass]="{'is-invalid': sympathizerForm.controls['phone'].invalid && sympathizerForm.controls['phone'].touched}" />
                <div id="validationServerUsernameFeedback" class="invalid-feedback">
                  Por favor ingresa un teléfono celular.
                </div>
              </div>
            </div>

          </div>
        </ng-template>
        <div class="col-12 col-md-4" *ngIf="international == false;else intercellphone">
          <span style="color: red">* </span>
          <label>Teléfono celular:</label>
          <div class="col-md-12">
            <input class="form-control" type="text" placeholder="Ingrese un teléfono celular" minlength="10"
              maxlength="10" formControlName="cellPhone"
              [ngClass]="{'is-invalid': sympathizerForm.controls['cellPhone'].invalid && sympathizerForm.controls['cellPhone'].touched}" />
            <div id="validationServerUsernameFeedback" class="invalid-feedback">
              Por favor ingresa un teléfono celular.
            </div>
          </div>
        </div>
        <ng-template #intercellphone>
          <div class="col-12 col-md-4">
            <span style="color: red">* </span>
            <label>Teléfono celular:</label>
            <div class="row">
              <div class="col-md-4">
                <input class="form-control" type="text" disabled [(ngModel)]="lada"
                  [ngModelOptions]="{standalone: true}" minlength="10" maxlength="10" />
                <div id="validationServerUsernameFeedback" class="invalid-feedback">
                  Por favor ingresa un teléfono celular.
                </div>
              </div>
              <div class="col-md-8">
                <input class="form-control" type="text" placeholder="Ingrese un teléfono celular" minlength="10"
                  maxlength="10" [(ngModel)]="number" (change)="parseNumber(number,'cellPhone')"
                  [ngModelOptions]="{standalone: true}"
                  [ngClass]="{'is-invalid': sympathizerForm.controls['cellPhone'].invalid && sympathizerForm.controls['cellPhone'].touched}" />
                <div id="validationServerUsernameFeedback" class="invalid-feedback">
                  Por favor ingresa un teléfono celular.
                </div>
              </div>
            </div>

          </div>
        </ng-template>
      </div>
      <div class="row">
        <div class="col-12 col-md-4">
          <label>Estado civil:</label>
          <div class="col-md-12">
            <select class="form-control" formControlName="civilStatus">
              <option selected value="">Selecciona una opción</option>
              <option value="Casado/a">Casado/a</option>
              <option value="Divorciado/a">Divorciado/a</option>
              <option value="Separado/a">Separado/a</option>
              <option value="Soltero/a">Soltero/a</option>
              <option value="Unión libre">Unión libre</option>
              <option value="Viudo/a">Viudo/a</option>
            </select>
          </div>
        </div>
        <div class="col-12 col-md-4" *ngIf="international == false">
          <label>Lugar de nacimiento (Estado):</label>
          <div class="col-md-12">
            <select class="form-control" formControlName="birthPlace" (change)="onFirstSelectChange()">
              <option selected value="">Selecciona una opción</option>
              <option *ngFor="let entitie of entities" value="{{entitie.id}}">{{entitie.name}}</option>
            </select>
          </div>
        </div>
        <div class="col-12 col-md-4" *ngIf="international == false">
          <label>Lugar de nacimiento (Municipio):</label>
          <div class="col-md-12">
            <select class="form-control" formControlName="birthPlaceM">
              <option selected value="">Selecciona una opción</option>
              <option *ngFor="let municipalitie of municipalities" value="{{municipalitie.id}}">
                {{municipalitie.name}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-4">
          <label>Escolaridad:</label>
          <div class="col-md-12">
            <select class="form-control" formControlName="scholarship">
              <option selected value="">Selecciona una opción</option>
              <option value="Primaria">Primaria</option>
              <option value="Secundaria">Secundaria</option>
              <option value="Preparatoria">Preparatoria</option>
              <option value="Estudios técnicos">Estudios técnicos</option>
              <option value="Licenciatura">Licenciatura</option>
              <option value="Posgrado">Posgrado</option>
              <option value="Ninguna">Ninguna</option>
            </select>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <label>Ocupación:</label>
          <div class="col-md-12">
            <select class="form-control" formControlName="ocupation">
              <option selected value="">Selecciona una opción</option>
              <option value="Agricultor/a y/o Productor/a rural">Agricultor/a y/o Productor/a rural
              </option>
              <option value="Labores domésticas">Labores domésticas</option>
              <option value="Comerciante">Comerciante</option>
              <option value="Docente">Docente</option>
              <option value="Empleado/a">Empleado/a</option>
              <option value="Empresario/a">Empresario/a</option>
              <option value="Estudiante">Estudiante</option>
              <option value="Obrero/a">Obrero/a</option>
              <option value="Profesionista">Profesionista</option>
              <option value="Servidor público">Servidor público</option>
              <option value="Técnico/a">Técnico/a</option>
              <option value="Ni estudia ni trabaja">Ni estudia ni trabaja</option>
              <option value="Otro">Otro</option>
            </select>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <label>Lugar de trabajo:</label>
          <div class="col-md-12">
            <input class="form-control" type="text" placeholder="Nombre de la empresa" formControlName="workplace" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-4" *ngIf="!international;else intercellPhoneOffice">
          <label>Teléfono del trabajo:</label>
          <div class="col-md-12">
            <input class="form-control" type="text" placeholder="Ingrese un teléfono del trabajo" minlength="10"
              maxlength="10" formControlName="cellPhoneOffice" />
          </div>
        </div>

        <ng-template #intercellPhoneOffice>
          <div class="col-12 col-md-4">
            <label>Teléfono del trabajo:</label>
            <div class="row">
              <div class="col-md-4">
                <input class="form-control" type="text" disabled [(ngModel)]="lada"
                  [ngModelOptions]="{standalone: true}" minlength="10" maxlength="10" />
                <div id="validationServerUsernameFeedback" class="invalid-feedback">
                  Por favor ingresa un teléfono celular.
                </div>
              </div>
              <div class="col-md-8">
                <input class="form-control" type="text" placeholder="Ingrese un teléfono del trabajo" minlength="10"
                  maxlength="10" [(ngModel)]="numberOffice" [ngModelOptions]="{standalone: true}"
                  (change)="parseNumber(numberOffice,'cellPhoneOffice')"
                  [ngClass]="{'is-invalid': sympathizerForm.controls['cellPhoneOffice'].invalid && sympathizerForm.controls['cellPhoneOffice'].touched}" />
                <div id="validationServerUsernameFeedback" class="invalid-feedback">
                  Por favor ingresa un teléfono celular.
                </div>
              </div>
            </div>

          </div>
        </ng-template>
        <div class="col-12 col-md-4">
          <label>Facebook:</label>
          <div class="col-md-12">
            <input class="form-control" type="text" placeholder="Ingrese Facebook" formControlName="facebook" />
          </div>
        </div>
        <div class="col-12 col-md-4">
          <label>Instagram:</label>
          <div class="col-md-12">
            <input class="form-control" type="text" placeholder="Ingrese Instagram" formControlName="instagram" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-4">
          <!-- <label>¿Cuáles son los temas de tu interés?:</label>
          <div class="col-md-12">
            <select class="form-control" formControlName="interests">
              <option selected value="">Selecciona una opción</option>
              <option>Cultura</option>
              <option>Deportes</option>
              <option>Entretenimiento</option>
              <option>Eventos </option>
              <option>Gastronomía</option>
              <option>Salud</option>
            </select>
          </div> -->

          <label>¿Cuáles son los temas de tu interés?:</label>

          <mat-select formControlName="interests" class="form-control" multiple disableUnderline="true">
            <mat-option *ngFor="let interest of interestOptions" [value]="interest">{{interest}}</mat-option>
          </mat-select>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-4">
          <label>
            <span class="reqForm">*</span> Recibe apoyos federales:
          </label>
          <div class="col-md-12">
            <div class="radio radio-css radio-inline">
              <input id="federalSupportyes" formControlName="federalSupport" type="radio" [value]="1">
              <label for="federalSupportyes">Sí</label>
            </div>
            <div class="radio radio-css radio-inline">
              <input id="federalSupportNot" formControlName="federalSupport" type="radio" [value]="0" checked="">
              <label for="federalSupportNot">No</label>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4" *ngIf="sympathizerForm.get('federalSupport')?.value">
          <label>¿Cuáles?</label>
          <div class="col-md-12">
            <input class="form-control" type="text" placeholder="Ingrese el tipo de apoyo"
              formControlName="typeSupport" />
          </div>
        </div>
      </div>
      <ng-container *ngIf="international == false">
        <div class="">
          <p class="divData">Dirección</p>
        </div>
        <div class="row">
          <div class="col-12 col-md-4">
            <label>Calle:</label>
            <div class="col-md-12">
              <input class="form-control" type="text" placeholder="Ingrese la calle" formControlName="street" />
            </div>
          </div>
          <div class="col-12 col-md-4">
            <label>Número exterior:</label>
            <div class="col-md-12">
              <input class="form-control" type="text" placeholder="Ingrese el número exterior"
                formControlName="streetNumExt" />
            </div>
          </div>
          <div class="col-12 col-md-4">
            <label>Número interior:</label>
            <div class="col-md-12">
              <input class="form-control" type="text" placeholder="Ingrese el número interior"
                formControlName="streetNumInt" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-4">
            <label>Código postal:</label>
            <div class="col-md-12">
              <div class="input-group">
                <input type="text" formControlName="postalCode" placeholder="Ingrese el código postal"
                  class="form-control"
                  [ngClass]="{'is-invalid': sympathizerForm.controls['postalCode'].invalid && sympathizerForm.controls['postalCode'].touched}">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" type="button" (click)="searchPostalCode()">
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <ng-container #menu *ngIf="postalCodeSearch.length != 0 && postalCodeSelected == false">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 content-seach searchList">
                  <ng-container *ngIf="postalCodeSearch.length == 0 else searchMenu">
                    <label class="darkGray">No hay registros</label>
                  </ng-container>
                  <ng-template #searchMenu>
                    <ng-container *ngFor="let result of postalCodeSearch; let i = index">
                      <ng-container>
                        <label (click)="selectPostalCode(result.identifier)"
                          [ngClass]=" i%2==0 ? 'darkGray' : 'lowGray'">{{result.identifier}}</label>
                      </ng-container>
                    </ng-container>
                    <br>
                  </ng-template>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <label>Colonia:</label>
            <div class="col-md-12">
              <select class="form-control" formControlName="idNeighborhood"
                [ngClass]="{'is-invalid': sympathizerForm.controls['idNeighborhood'].invalid && sympathizerForm.controls['idNeighborhood'].touched}">
                <option selected value="">Selecciona una colonia</option>
                <option *ngFor="let neighborhoodOption of neighborhoodOptions" value="{{neighborhoodOption.id}}">
                  {{neighborhoodOption.name}}</option>
              </select>
              <div id="validationServerUsernameFeedback" class="invalid-feedback">
                Escoge una opción.
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <label>Municipio:</label>
            <div class="col-md-12">
              <input class="form-control" placeholder="Cargando listado..." type="text" disabled
                [value]="municipalitieName">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-4">
            <label>Estado:</label>
            <div class="col-md-12">
              <input class="form-control" type="text" placeholder="Cargando listado..." disabled [value]="entitieName">
            </div>
          </div>

          <div class="col-12 col-md-4">
            <label>Referencia:</label>
            <div class="col-md-12">
              <input class="form-control" type="text" placeholder="Ingrese referencia al domicilio"
                formControlName="reference" />
            </div>
          </div>
        </div>

      </ng-container>
      <div class="">
        <p class="divData">Datos adicionales</p>
      </div>
      <div class="row pb-3">
        <div class="col-12">
          <div class="row">
            <div class="col-12 col-md-4">
              <label>¿Estás afiliado a algún partido político?</label>
              <div class="col-md-12">
                <div class="radio radio-css radio-inline">
                  <input id="affiliateYes" formControlName="affiliated" type="radio" [value]="1">
                  <label for="affiliateYes">Sí</label>
                </div>
                <div class="radio radio-css radio-inline">
                  <input id="affiliateNo" formControlName="affiliated" type="radio" [value]="0" checked="">
                  <label for="affiliateNo">No</label>
                </div>
              </div>
            </div>
            <ng-container *ngIf="sympathizerForm.get('affiliated')?.value">
              <div class="col-12 col-md-4">
                <span style="color: red">* </span>
                <label>Partido político:</label>
                <div class="col-md-12">
                  <select class="form-control" formControlName="affiliated_political_party">
                    <option selected value="">Selecciona una opción</option>
                    <option value="{{party.name}}" *ngFor="let party of parties">{{party.name}}
                    </option>
                    <option value="OTRO">OTRO</option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <label>Fecha de afiliación:</label>
                <input class="form-control" type="date" placeholder="dd/mm/aaaa" formControlName="affiliation_date" />
              </div>
              <div class="col-12 col-md-4" *ngIf="sympathizerForm.get('affiliated_political_party')?.value == 'OTRO'">
                <label>¿Cuál?</label>
                <input class="form-control" type="text" placeholder="" formControlName="other_political_party" />
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="row mt-1">
        <div class="cardClas">
          <div style="text-align: center;"><label style="font-weight: 600; font-size: 16px !important;">Clasificación
              del simpatizante</label>
          </div>
          <div>
            <div class="row">
              <div class="col">
                <div class="col-12">
                  <label>¿Punto de interés?</label>
                  <div class="col-md-12">
                    <div class="radio radio-css radio-inline">
                      <input id="POIyes" formControlName="POIrelated" type="radio" [value]="1">
                      <label for="POIyes">Sí</label>
                    </div>
                    <div class="radio radio-css radio-inline">
                      <input id="POInot" formControlName="POIrelated" type="radio" [value]="0" checked="">
                      <label for="POInot">No</label>
                    </div>
                  </div>
                </div>
                <!-- Punto de interés -->
                <div class="col-12" *ngIf="sympathizerForm.get('POIrelated')?.value">
                  <label>¿Cuál?</label>
                  <div class="col-md-12">
                    <select class="form-control" formControlName="idPOI">
                      <option selected value="0">Selecciona una opción</option>
                      <option value="{{POI.id}}" *ngFor="let POI of POIoptions">{{POI.name}}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-12" *ngIf="sympathizerForm.get('POIrelated')?.value">
                  <label>Agregar un punto</label>
                  <div class="col-md-6">
                    <button type="submit" (click)="openDialog()" class="btn btn-primary btn-lg btn-block">+</button>
                  </div>
                </div>
                <!-- Fin de opciones de Punto de interés -->
              </div>
              <div class="col">
                <div class="col-12">
                  <label>¿Tipo de participación?</label>
                  <div class="col-md-12">
                    <div class="radio radio-css radio-inline">
                      <input id="Participationyes" formControlName="participation" type="radio" [value]="1">
                      <label for="Participationyes">Sí</label>
                    </div>
                    <div class="radio radio-css radio-inline">
                      <input id="Participationnot" formControlName="participation" type="radio" [value]="0" checked="">
                      <label for="Participationnot">No</label>
                    </div>
                  </div>
                </div>
                <!-- Tipo de participación -->
                <div class="col-12" *ngIf="sympathizerForm.get('participation')?.value">
                  <label style="padding-top: 14px;"> </label>
                  <div class="col-md-12">
                    <select class="form-control" formControlName="participationType">
                      <option selected value="">Seleccione una opción</option>
                      <option value="BRIGADISTA (ENCUESTADOR Y/O PROMOTOR DEL VOTO)">BRIGADISTA
                        (ENCUESTADOR Y/O PROMOTOR DEL VOTO)</option>
                      <option value="PUBLICIDAD (COLOCACIÓN Y DISTRIBUCIÓN DE PROPAGANDA)">
                        PUBLICIDAD (COLOCACIÓN Y DISTRIBUCIÓN DE PROPAGANDA)</option>
                      <option value="PUBLICIDAD (DIFUSIÓN PERSONALIZADA)">PUBLICIDAD (DIFUSIÓN
                        PERSONALIZADA)</option>
                      <option value="ESTRUCTURA ELECTORAL (REPRESENTANTE DE CASILLA)">ESTRUCTURA
                        ELECTORAL (REPRESENTANTE DE CASILLA)</option>
                      <option value="ESTRUCTURA ELECTORAL (SECCIONAL)">ESTRUCTURA ELECTORAL
                        (SECCIONAL)</option>
                      <option value="ESTRUCTURA ELECTORAL (MOVILIZADOR)">ESTRUCTURA ELECTORAL
                        (MOVILIZADOR)</option>
                      <option value="ESTRUCTURA ELECTORAL (COORDINADOR DE COLONIA)">ESTRUCTURA
                        ELECTORAL (COORDINADOR DE COLONIA)</option>
                      <option value="ESTRUCTURA ELECTORAL (ZONAL)">ESTRUCTURA ELECTORAL (ZONAL)
                      </option>
                      <option value="OTRO">OTRO</option>
                    </select>
                  </div>
                </div>
                <div class="col-12" *ngIf="sympathizerForm.get('participationType')?.value == 'OTRO'">
                  <label>¿Cuál?</label>
                  <input class="form-control" type="text" placeholder="" formControlName="otherType" />
                </div>
                <!-- Fin de opciones de Tipo de participación -->
              </div>
              <div class="col">
                <div class="col-12">
                  <label>Actor importante</label>
                  <div class="col-md-12">
                    <div class="radio radio-css radio-inline">
                      <input id="Actoryes" formControlName="actor" type="radio" [value]="1">
                      <label for="Actoryes">Sí</label>
                    </div>
                    <div class="radio radio-css radio-inline">
                      <input id="Actornot" formControlName="actor" type="radio" [value]="0" checked="">
                      <label for="Actornot">No</label>
                    </div>
                  </div>
                </div>
                <!-- Actor importante -->
                <div class="col-12" *ngIf="sympathizerForm.get('actor')?.value">
                  <label>¿Qué tipo de actor es?</label>
                  <div class="col-md-12">
                    <select class="form-control" formControlName="actorType">
                      <option selected value="">Seleccione una opción</option>
                      <option value="EMPRESARIO">EMPRESARIO</option>
                      <option value="LÍDER EDUCATIVO">LÍDER EDUCATIVO</option>
                      <option value="LÍDER RELIGIOSO">LÍDER RELIGIOSO</option>
                      <option value="LÍDER DE MEDIO DE COMUNICACIÓN">LÍDER DE MEDIO DE
                        COMUNICACIÓN</option>
                      <option value="LÍDER DE CÁMARA O ASOCIACIÓN EMPRESARIAL">LÍDER DE CÁMARA O
                        ASOCIACIÓN EMPRESARIAL</option>
                      <option value="LÍDER DEL SECTOR SALUD">LÍDER DEL SECTOR SALUD</option>
                      <option value="LÍDER SINDICAL">LÍDER SINDICAL</option>
                      <option value="LÍDER POLÍTICO">LÍDER POLÍTICO</option>
                      <option value="LÍDER DE COLONIA">LÍDER DE COLONIA</option>
                      <option value="REPRESENTANTE DE A.C. / ONG">REPRESENTANTE DE A.C. / ONG
                      </option>
                      <option value="LÍDER JUVENIL">LÍDER JUVENIL</option>
                      <option value="LÍDER DE MUJERES">LÍDER DE MUJERES</option>
                      <option value="OTRO">OTRO</option>
                    </select>
                  </div>
                </div>
                <div class="col-12" *ngIf="sympathizerForm.get('actorType')?.value == 'OTRO'">
                  <label>¿Cuál?</label>
                  <input class="form-control" type="text" placeholder="" formControlName="otherActor" />
                </div>
                <!-- Fin de opciones de Actor importante -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <!-- <div class="col-12 col-md-4">
                <label>
                    <span class="reqForm">*</span> ¿El solicitante representa un punto de interés?
                </label>
                <div class="col-md-12">
                    <div class="radio radio-css radio-inline">
                        <input id="POIyes" formControlName="POIrelated" type="radio" [value]="1">
                        <label for="POIyes">Sí</label>
                    </div>
                    <div class="radio radio-css radio-inline">
                        <input id="POInot" formControlName="POIrelated" type="radio" [value]="0" checked="">
                        <label for="POInot">No</label>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4" *ngIf="sympathizerForm.get('POIrelated')?.value">
                <label>¿Cuál?</label>
                <div class="col-md-12">
                    <select class="form-control" formControlName="idPOI" placeholder="Cargando listado...">
                        <option selected value="0">Seleccione una opción</option>
                        <option value="{{POI.id}}" *ngFor="let POI of POIoptions">{{POI.name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-12 col-md-4" *ngIf="sympathizerForm.get('POIrelated')?.value">
                <label>Agregar un punto</label>
                <div class="col-md-12">
                    <button type="submit" (click)="openDialog()" class="btn btn-primary btn-lg btn-block">+</button>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <label>Tipo de participación:</label>
                <div class="col-md-12">
                    <select class="form-control" formControlName="participationType">
                        <option selected value="">Seleccione una opción</option>
                        <option value="Lider de colonia">Lider de colonia</option>
                        <option value="Brigadista">Brigadista</option>
                        <option value="Publicidad">Publicidad</option>
                        <option value="Otro">Otro</option>
                    </select>
                </div>
            </div> -->

        <div class="row">
          <div class="col-10 offset-1" *ngIf="Allurlparticipation.length == 0 ">
            <div class="cont-image">
              <label class="block mb-2 text-sm text-gesco dark:text-white" for="multiple_files">Agregar
                evidencia</label>
              <input class="block w-full text-sm text-gesco border-gesco rounded-lg cursor-pointer" type="file"
                id="multiple_files" accept=".pdf,.doc,.png,.jpg,.jpeg" multiple (change)="onFileChange($event)" />
              <p class="mt-1 text-sm text-gesco" id="file_input_help">(JPEG/PNG/PDF)</p>
            </div>
          </div>

          <div class="row" *ngIf="Allurlparticipation.length > 0">
            <div class="col">
              <label>Archivo de evidencia:</label>
              <div class="row ml-3">
                <label class="col-md-12 p-t-2">
                  Archivos cargados:
                </label>


                <ng-container *ngFor="let url of Allurlparticipation; let i = index">
                  <div class="drop-file" (click)="openImage(url)">
                    <a target="_blank" class="alert alert-drop-file show text-left cursor-pointer">
                      <span class="close text-danger" data-idx="0" data-dismiss="alert"
                        (click)="removeFile(url)">×</span>
                      <i style="margin-top:-10px;" class="fa fa-file-image text-purple p-r-5 fa-3x pull-left"></i>
                      {{getFileName(url)}}
                    </a>
                  </div>
                </ng-container>

              </div>
            </div>
          </div>
        </div>

        <!-- <div class="col-12 col-md-4" *ngIf="sympathizerForm.get('participationType')?.value == 'Otro'">
                <label>¿Cuál?</label>
                <input class="form-control" type="text" placeholder="" formControlName="otherType" />
            </div> -->
        <div class="col-12 col-md-4">
          <label>Bitácora:</label>
          <textarea class="form-control" formControlName="bitacora" placeholder=""></textarea>
        </div>
      </div>

      <div class="row mt-3" *ngIf="idSympathizer != null && long != null;">
        <app-form-map [lat]="lat" [lon]="long"></app-form-map>
      </div>

    </form>

  </ng-container>

  <ng-template #internationalForm>
    <form [formGroup]="sympathizerForm">
      <div class="row">
        <div class="col-12 col-md-4" *ngIf="idSympathizer">
          <label>Folio asignado:</label>
          <div class="form-control-disable">{{qrcode}}</div>
        </div>
        <div class="col-12 col-md-4" *ngIf="!idSympathizer">
          <label class="align-middle col-md-12 p-t-2">
            <span class="reqForm">*</span>
            <a (click)="openModal()" class="privacy">Aviso de privacidad <cite>click aquí</cite></a>
          </label>
          <div class="col-md-12">
            <div class="checkbox checkbox-css">
              <input id="privacyNotice" formControlName="privacyNotice" type="checkbox"
                [checked]="sympathizerForm.get('privacyNotice')?.value === 1" (change)="updateCheckbox($event)">
              <label for="privacyNotice">Aceptar</label>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <label>Periodo:</label>
          <select class="form-control" formControlName="period">
            <option selected value="">Selecciona una opción</option>
            <option *ngFor="let period of periodOptions" value="{{period.value}}">{{period.name}}</option>
          </select>
        </div>
        <div class="col-12 col-md-4">
          <label class="col-md-12"><span style="color: red">* </span>Lugar de levantamiento:</label>
          <select class="form-control" formControlName="idlifting">
            <option selected value="">Selecciona una opción</option>
            <option value="Oficina">Oficina</option>
            <option value="Página de internet">Página de internet</option>
            <option value="Llamada telefónica">Llamada telefónica</option>
            <option value="Correo electrónico">Correo electrónico</option>
            <option value="Facebook">Facebook</option>
            <option value="WhatsApp">WhatsApp</option>
            <option value="Campo">Campo</option>
            <option value="Micrositio">Micrositio</option>
          </select>
        </div>
      </div>
      <div class="">
        <p class="divData">Datos del simpatizante</p>
      </div>
      <div class="row">
        <div class="col-12 col-md-4">
          <span style="color: red">* </span>
          <label>Nombre(s):</label>
          <input class="form-control" type="text" placeholder="Ingrese el nombre del simpatizante"
            formControlName="name"
            [ngClass]="{'is-invalid': sympathizerForm.controls['name'].invalid && sympathizerForm.controls['name'].touched}" />
          <div id="validationServerUsernameFeedback" class="invalid-feedback">
            Por favor ingresa el nombre.
          </div>
        </div>
        <div class="col-12 col-md-4">
          <span style="color: red">* </span>
          <label>Apellido paterno:</label>
          <input class="form-control" type="text" placeholder="Ingrese apellido paterno" formControlName="next_name"
            [ngClass]="{'is-invalid': sympathizerForm.controls['next_name'].invalid && sympathizerForm.controls['next_name'].touched}" />
          <div id="validationServerUsernameFeedback" class="invalid-feedback">
            Por favor ingresa el apellido paterno.
          </div>
        </div>
        <div class="col-12 col-md-4">
          <span style="color: red">* </span>
          <label>Apellido materno:</label>
          <input class="form-control" type="text" placeholder="Ingrese apellido materno" formControlName="last_name"
            [ngClass]="{'is-invalid': sympathizerForm.controls['last_name'].invalid && sympathizerForm.controls['last_name'].touched}" />
          <div id="validationServerUsernameFeedback" class="invalid-feedback">
            Por favor ingresa el apellido materno.
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-4">
          <label>Fecha de nacimiento:</label>
          <input class="form-control" type="date" placeholder="dd/mm/aaaa" formControlName="birthDate" />
        </div>
        <div class="col-12 col-md-4">
          <label>Sexo:</label>
          <div class="col-md-12">
            <select class="form-control" formControlName="gender">
              <option selected value="">Selecciona una opción</option>
              <option value="Femenino">Femenino</option>
              <option value="Masculino">Masculino</option>
              <option value="Otro">Otro</option>
            </select>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <label>CURP:</label>
          <div class="col-md-12">
            <input class="form-control" type="text" placeholder="Ingrese el CURP" formControlName="curp" maxlength="18"
              [ngClass]="{'is-invalid': sympathizerForm.controls['curp'].invalid && sympathizerForm.controls['curp'].touched}" />
            <div id="validationServerUsernameFeedback" class="invalid-feedback">
              Por favor ingresa una CURP válida.
            </div>
          </div>
          <div class="row m-b-10 text-right p-r-20" style="display: block;font-size: 15px;">
            <a href="https://consultas.curp.gob.mx/CurpSP/gobmx/inicio.jsp" target="_blank">Consultar
              CURP</a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-4">
          <label>Estado civil:</label>
          <div class="col-md-12">
            <select class="form-control" formControlName="civilStatus">
              <option selected value="">Selecciona una opción</option>
              <option value="Casado/a">Casado/a</option>
              <option value="Divorciado/a">Divorciado/a</option>
              <option value="Separado/a">Separado/a</option>
              <option value="Soltero/a">Soltero/a</option>
              <option value="Unión libre">Unión libre</option>
              <option value="Viudo/a">Viudo/a</option>
            </select>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <label>Escolaridad:</label>
          <div class="col-md-12">
            <select class="form-control" formControlName="scholarship">
              <option selected value="">Selecciona una opción</option>
              <option value="Primaria">Primaria</option>
              <option value="Secundaria">Secundaria</option>
              <option value="Preparatoria">Preparatoria</option>
              <option value="Estudios técnicos">Estudios técnicos</option>
              <option value="Licenciatura">Licenciatura</option>
              <option value="Posgrado">Posgrado</option>
              <option value="Ninguna">Ninguna</option>
            </select>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <label>Ocupación:</label>
          <div class="col-md-12">
            <select class="form-control" formControlName="ocupation">
              <option selected value="">Selecciona una opción</option>
              <option value="Agricultor/a y/o Productor/a rural">Agricultor/a y/o Productor/a rural
              </option>
              <option value="Labores domésticas">Labores domésticas</option>
              <option value="Comerciante">Comerciante</option>
              <option value="Docente">Docente</option>
              <option value="Empleado/a">Empleado/a</option>
              <option value="Empresario/a">Empresario/a</option>
              <option value="Estudiante">Estudiante</option>
              <option value="Obrero/a">Obrero/a</option>
              <option value="Profesionista">Profesionista</option>
              <option value="Servidor público">Servidor público</option>
              <option value="Técnico/a">Técnico/a</option>
              <option value="Ni estudia ni trabaja">Ni estudia ni trabaja</option>
              <option value="Otro">Otro</option>
            </select>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <label>Lugar de trabajo:</label>
          <div class="col-md-12">
            <input class="form-control" type="text" placeholder="Nombre de la empresa" formControlName="workplace" />
          </div>
        </div>
      </div>
      <ng-container>


        <div class="">
          <p class="divData">Dirección</p>
        </div>
        <div class="row">

          <div class="col-12 col-md-4">
            <span style="color: red">* </span>
            <label>País:</label>
            <div class="col-md-12">
              <select class="form-control" formControlName="country" (change)="selectCountry()">
                <option selected value="">Selecciona una opción</option>
                <option *ngFor="let country of countries" value="{{country.name}}">{{country.name}}
                </option>
              </select>
            </div>
          </div>

          <div class="col-12 col-md-4" *ngIf="sympathizerForm.controls['country'].value != 'México';else localPostal">
            <label>Código postal:</label>
            <div class="col-md-12">
              <div class="input-group">
                <input type="text" formControlName="postalCode" placeholder="Ingrese el código postal"
                  class="form-control"
                  [ngClass]="{'is-invalid': sympathizerForm.controls['postalCode'].invalid && sympathizerForm.controls['postalCode'].touched}">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" type="button" (click)="searchInternationalPostalCode()">
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <ng-container #menu *ngIf="postalCodeSearch.length != 0 && postalCodeSelected == false">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 content-seach searchList">
                  <ng-container *ngIf="postalCodeSearch.length == 0 else searchMenu">
                    <label class="darkGray">No hay registros</label>
                  </ng-container>
                  <ng-template #searchMenu>
                    <ng-container *ngFor="let result of postalCodeSearch; let i = index">
                      <ng-container>
                        <label (click)="selectInternationalPostal(result)" [ngClass]=" i%2==0 ? 'darkGray' : 'lowGray'">
                          {{result.postalCode}}
                        </label>
                      </ng-container>
                    </ng-container>
                    <br>
                  </ng-template>
                </div>
              </ng-container>
            </div>
          </div>

          <ng-template #localPostal>
            <div class="col-12 col-md-4">
              <label>Código postal:</label>
              <div class="col-md-12">
                <div class="input-group">
                  <input type="text" formControlName="postalCode" placeholder="Ingrese el código postal"
                    class="form-control"
                    [ngClass]="{'is-invalid': sympathizerForm.controls['postalCode'].invalid && sympathizerForm.controls['postalCode'].touched}">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary" type="button" (click)="searchPostalCode()">
                      <i class="fa fa-search" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
                <ng-container #menu *ngIf="postalCodeSearch.length != 0 && postalCodeSelected == false">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 content-seach searchList">
                    <ng-container *ngIf="postalCodeSearch.length == 0 else searchMenu">
                      <label class="darkGray">No hay registros</label>
                    </ng-container>
                    <ng-template #searchMenu>
                      <ng-container *ngFor="let result of postalCodeSearch; let i = index">
                        <ng-container>
                          <label (click)="selectPostalCode(result.identifier)"
                            [ngClass]=" i%2==0 ? 'darkGray' : 'lowGray'">{{result.identifier}}</label>
                        </ng-container>
                      </ng-container>
                      <br>
                    </ng-template>
                  </div>
                </ng-container>
              </div>
            </div>
          </ng-template>

          <div class="col-12 col-md-4">
            <label>Estado:</label>
            <div class="col-md-12">
              <input class="form-control" type="text" placeholder="Cargando listado..." disabled [value]="entitieName">
            </div>
          </div>
          <div class="col-12 col-md-4">
            <label>Municipio:</label>
            <div class="col-md-12">
              <input class="form-control" placeholder="Cargando listado..." type="text" disabled
                [value]="municipalitieName">
            </div>
          </div>
          <div class="col-12 col-md-4"
            *ngIf="sympathizerForm.controls['country'].value != 'México';else localNeighborhood">
            <label>Colonia:</label>
            <div class="col-md-12">
              <input class="form-control" placeholder="Cargando listado..." type="text" disabled
                [value]="neighborhoodName">
            </div>
          </div>
          <ng-template #localNeighborhood>
            <div class="col-12 col-md-4">
              <label>Colonia:</label>
              <div class="col-md-12">
                <select class="form-control" formControlName="idNeighborhood"
                  [ngClass]="{'is-invalid': sympathizerForm.controls['idNeighborhood'].invalid && sympathizerForm.controls['idNeighborhood'].touched}">
                  <option selected value="">Selecciona una colonia</option>
                  <option *ngFor="let neighborhoodOption of neighborhoodOptions" value="{{neighborhoodOption.id}}">
                    {{neighborhoodOption.name}}</option>
                </select>
                <div id="validationServerUsernameFeedback" class="invalid-feedback">
                  Escoge una opción.
                </div>
              </div>
            </div>
          </ng-template>
          <div class="col-12 col-md-4">
            <label>Calle:</label>
            <div class="col-md-12">
              <input class="form-control" type="text" placeholder="Ingrese la calle" formControlName="street" />
            </div>
          </div>
          <div class="col-12 col-md-4">
            <label>Número exterior:</label>
            <div class="col-md-12">
              <input class="form-control" type="text" placeholder="Ingrese el número exterior"
                formControlName="streetNumExt" />
            </div>
          </div>
          <div class="col-12 col-md-4">
            <label>Número interior:</label>
            <div class="col-md-12">
              <input class="form-control" type="text" placeholder="Ingrese el número interior"
                formControlName="streetNumInt" />
            </div>
          </div>
          <div class="col-12 col-md-4">
            <label>Referencia:</label>
            <div class="col-md-12">
              <input class="form-control" type="text" placeholder="Ingrese referencia al domicilio"
                formControlName="reference" />
            </div>
          </div>
        </div>
        <div class="row mb-4">
        </div>
      </ng-container>

      <div class="">
        <p class="divData">Datos de contacto</p>
      </div>
      <div class="row">
        <div class="col-12 col-md-4">
          <label>Correo electrónico:</label>
          <div class="col-md-12">
            <input class="form-control" type="email" placeholder="Ingrese un correo electrónico" formControlName="email"
              [ngClass]="{'is-invalid': sympathizerForm.controls['email'].invalid && sympathizerForm.controls['email'].touched}" />
            <div id="validationServerUsernameFeedback" class="invalid-feedback">
              Por favor ingresa un correo electrónico válido.
            </div>
          </div>
        </div>

        <div class="col-12 col-md-4">
          <label>Teléfono de casa:</label>
          <div class="row">
            <div class="col-4">
              <input class="form-control" type="text" disabled [(ngModel)]="lada" [ngModelOptions]="{standalone: true}"
                minlength="10" maxlength="10" />
              <div id="validationServerUsernameFeedback" class="invalid-feedback">
                Por favor ingresa un teléfono celular.
              </div>
            </div>
            <div class="col-8">
              <input class="form-control" type="text" placeholder="Ingrese un teléfono local" minlength="10"
                maxlength="10" [(ngModel)]="houseNumber" [ngModelOptions]="{standalone: true}"
                (change)="parseNumber(houseNumber,'phone')"
                [ngClass]="{'is-invalid': sympathizerForm.controls['phone'].invalid && sympathizerForm.controls['phone'].touched}" />
              <div id="validationServerUsernameFeedback" class="invalid-feedback">
                Por favor ingresa un teléfono celular.
              </div>
            </div>
          </div>

        </div>


        <div class="col-12 col-md-4">
          <span style="color: red">* </span>
          <label>Teléfono celular:</label>
          <div class="row">
            <div class="col-4">
              <input class="form-control" type="text" disabled [(ngModel)]="lada" [ngModelOptions]="{standalone: true}"
                minlength="10" maxlength="10" />
              <div id="validationServerUsernameFeedback" class="invalid-feedback">
                Por favor ingresa un teléfono celular.
              </div>
            </div>
            <div class="col-8">
              <input class="form-control" type="text" placeholder="Ingrese un teléfono celular" minlength="10"
                maxlength="10" [(ngModel)]="number" (change)="parseNumber(number,'cellPhone')"
                [ngModelOptions]="{standalone: true}"
                [ngClass]="{'is-invalid': sympathizerForm.controls['cellPhone'].invalid && sympathizerForm.controls['cellPhone'].touched}" />
              <div id="validationServerUsernameFeedback" class="invalid-feedback">
                Por favor ingresa un teléfono celular.
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="row">


      </div>

      <div class="row">

        <div class="col-12 col-md-4">
          <label>Teléfono del trabajo:</label>
          <div class="row">
            <div class="col-4">
              <input class="form-control" type="text" disabled [(ngModel)]="lada" [ngModelOptions]="{standalone: true}"
                minlength="10" maxlength="10" />
              <div id="validationServerUsernameFeedback" class="invalid-feedback">
                Por favor ingresa un teléfono celular.
              </div>
            </div>
            <div class="col-8">
              <input class="form-control" type="text" placeholder="Ingrese un teléfono del trabajo" minlength="10"
                maxlength="10" [(ngModel)]="numberOffice" [ngModelOptions]="{standalone: true}"
                (change)="parseNumber(numberOffice,'cellPhoneOffice')"
                [ngClass]="{'is-invalid': sympathizerForm.controls['cellPhoneOffice'].invalid && sympathizerForm.controls['cellPhoneOffice'].touched}" />
              <div id="validationServerUsernameFeedback" class="invalid-feedback">
                Por favor ingresa un teléfono celular.
              </div>
            </div>
          </div>

        </div>
        <div class="col-12 col-md-4">
          <label>Facebook:</label>
          <div class="col-md-12">
            <input class="form-control" type="text" placeholder="Ingrese Facebook" formControlName="facebook" />
          </div>
        </div>
        <div class="col-12 col-md-4">
          <label>Instagram:</label>
          <div class="col-md-12">
            <input class="form-control" type="text" placeholder="Ingrese Instagram" formControlName="instagram" />
          </div>
        </div>
      </div>


      <div class="">
        <p class="divData">Datos adicionales</p>
      </div>
      <div class="row pb-3">
        <div class="col-12">
          <div class="row">
            <div class="col-4 col-md-4">
              <label>
                <span class="reqForm">*</span> Recibe apoyos federales:
              </label>
              <div class="col-md-12">
                <div class="radio radio-css radio-inline">
                  <input id="federalSupportyes" formControlName="federalSupport" type="radio" [value]="1">
                  <label for="federalSupportyes">Sí</label>
                </div>
                <div class="radio radio-css radio-inline">
                  <input id="federalSupportNot" formControlName="federalSupport" type="radio" [value]="0" checked="">
                  <label for="federalSupportNot">No</label>
                </div>
              </div>
            </div>
            <div class="col-4 col-md-4" *ngIf="sympathizerForm.get('federalSupport')?.value">
              <label>¿Cuáles?</label>
              <div class="col-md-12">
                <input class="form-control" type="text" placeholder="Ingrese el tipo de apoyo"
                  formControlName="typeSupport" />
              </div>
            </div>

            <div class="col-12 col-md-4">
              <label>¿Estás afiliado a algún partido político?</label>
              <div class="col-md-12">
                <div class="radio radio-css radio-inline">
                  <input id="affiliateYes" formControlName="affiliated" type="radio" [value]="1">
                  <label for="affiliateYes">Sí</label>
                </div>
                <div class="radio radio-css radio-inline">
                  <input id="affiliateNo" formControlName="affiliated" type="radio" [value]="0" checked="">
                  <label for="affiliateNo">No</label>
                </div>
              </div>
            </div>
            <ng-container *ngIf="sympathizerForm.get('affiliated')?.value">
              <div class="col-4 col-md-4">
                <span style="color: red">* </span>
                <label>Partido político:</label>
                <div class="col-md-12">
                  <select class="form-control" formControlName="affiliated_political_party">
                    <option selected value="">Selecciona una opción</option>
                    <option value="{{party.name}}" *ngFor="let party of parties">{{party.name}}
                    </option>
                    <option value="OTRO">OTRO</option>
                  </select>
                </div>
              </div>
              <div class="col-4 col-md-4">
                <label>Fecha de afiliación:</label>
                <input class="form-control" type="date" placeholder="dd/mm/aaaa" formControlName="affiliation_date" />
              </div>
              <div class="col-4 col-md-4" *ngIf="sympathizerForm.get('affiliated_political_party')?.value == 'OTRO'">
                <label>¿Cuál?</label>
                <input class="form-control" type="text" placeholder="" formControlName="other_political_party" />
              </div>
            </ng-container>
          </div>
          <div class="row mt-3">
            <div class="col-12 col-md-4">
              <!-- <label>¿Cuáles son los temas de tu interés?:</label>
              <div class="col-md-12">
                <select class="form-control" formControlName="interests">
                  <option selected value="">Selecciona una opción</option>
                  <option>Cultura</option>
                  <option>Deportes</option>
                  <option>Entretenimiento</option>
                  <option>Eventos </option>
                  <option>Gastronomía</option>
                  <option>Salud</option>
                </select>
              </div> -->
              <label>¿Cuáles son los temas de tu interés?:</label>

              <mat-select formControlName="interests" class="form-control" multiple disableUnderline="true">
                <mat-option *ngFor="let interest of interestOptions" [value]="interest">{{interest}}</mat-option>
              </mat-select>
            </div>
          </div>

        </div>
      </div>
      <div class="row mt-1">
        <div class="cardClas">
          <div style="text-align: center;"><label style="font-weight: 600; font-size: 16px !important;">Clasificación
              del simpatizante</label>
          </div>
          <div>
            <div class="row">
              <div class="col">
                <div class="col-12">
                  <label>¿Punto de interés?</label>
                  <div class="col-md-12">
                    <div class="radio radio-css radio-inline">
                      <input id="POIyes" formControlName="POIrelated" type="radio" [value]="1">
                      <label for="POIyes">Sí</label>
                    </div>
                    <div class="radio radio-css radio-inline">
                      <input id="POInot" formControlName="POIrelated" type="radio" [value]="0" checked="">
                      <label for="POInot">No</label>
                    </div>
                  </div>
                </div>
                <!-- Punto de interés -->
                <div class="col-12" *ngIf="sympathizerForm.get('POIrelated')?.value">
                  <label>¿Cuál?</label>
                  <div class="col-md-12">
                    <select class="form-control" formControlName="idPOI">
                      <option selected value="0">Selecciona una opción</option>
                      <option value="{{POI.id}}" *ngFor="let POI of POIoptions">{{POI.name}}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-12" *ngIf="sympathizerForm.get('POIrelated')?.value">
                  <label>Agregar un punto</label>
                  <div class="col-md-6">
                    <button type="submit" (click)="openDialog()" class="btn btn-primary btn-lg btn-block">+</button>
                  </div>
                </div>
                <!-- Fin de opciones de Punto de interés -->
              </div>
              <div class="col">
                <div class="col-12">
                  <label>¿Tipo de participación?</label>
                  <div class="col-md-12">
                    <div class="radio radio-css radio-inline">
                      <input id="Participationyes" formControlName="participation" type="radio" [value]="1">
                      <label for="Participationyes">Sí</label>
                    </div>
                    <div class="radio radio-css radio-inline">
                      <input id="Participationnot" formControlName="participation" type="radio" [value]="0" checked="">
                      <label for="Participationnot">No</label>
                    </div>
                  </div>
                </div>
                <!-- Tipo de participación -->
                <div class="col-12" *ngIf="sympathizerForm.get('participation')?.value">
                  <label style="padding-top: 14px;"> </label>
                  <div class="col-md-12">
                    <select class="form-control" formControlName="participationType">
                      <option selected value="">Seleccione una opción</option>
                      <option value="BRIGADISTA (ENCUESTADOR Y/O PROMOTOR DEL VOTO)">BRIGADISTA
                        (ENCUESTADOR Y/O PROMOTOR DEL VOTO)</option>
                      <option value="PUBLICIDAD (COLOCACIÓN Y DISTRIBUCIÓN DE PROPAGANDA)">
                        PUBLICIDAD (COLOCACIÓN Y DISTRIBUCIÓN DE PROPAGANDA)</option>
                      <option value="PUBLICIDAD (DIFUSIÓN PERSONALIZADA)">PUBLICIDAD (DIFUSIÓN
                        PERSONALIZADA)</option>
                      <option value="ESTRUCTURA ELECTORAL (REPRESENTANTE DE CASILLA)">ESTRUCTURA
                        ELECTORAL (REPRESENTANTE DE CASILLA)</option>
                      <option value="ESTRUCTURA ELECTORAL (SECCIONAL)">ESTRUCTURA ELECTORAL
                        (SECCIONAL)</option>
                      <option value="ESTRUCTURA ELECTORAL (MOVILIZADOR)">ESTRUCTURA ELECTORAL
                        (MOVILIZADOR)</option>
                      <option value="ESTRUCTURA ELECTORAL (COORDINADOR DE COLONIA)">ESTRUCTURA
                        ELECTORAL (COORDINADOR DE COLONIA)</option>
                      <option value="ESTRUCTURA ELECTORAL (ZONAL)">ESTRUCTURA ELECTORAL (ZONAL)
                      </option>
                      <option value="OTRO">OTRO</option>
                    </select>
                  </div>
                </div>
                <div class="col-12" *ngIf="sympathizerForm.get('participationType')?.value == 'OTRO'">
                  <label>¿Cuál?</label>
                  <input class="form-control" type="text" placeholder="" formControlName="otherType" />
                </div>
                <!-- Fin de opciones de Tipo de participación -->
              </div>
              <div class="col">
                <div class="col-12">
                  <label>Actor importante</label>
                  <div class="col-md-12">
                    <div class="radio radio-css radio-inline">
                      <input id="Actoryes" formControlName="actor" type="radio" [value]="1">
                      <label for="Actoryes">Sí</label>
                    </div>
                    <div class="radio radio-css radio-inline">
                      <input id="Actornot" formControlName="actor" type="radio" [value]="0" checked="">
                      <label for="Actornot">No</label>
                    </div>
                  </div>
                </div>
                <!-- Actor importante -->
                <div class="col-12" *ngIf="sympathizerForm.get('actor')?.value">
                  <label>¿Qué tipo de actor es?</label>
                  <div class="col-md-12">
                    <select class="form-control" formControlName="actorType">
                      <option selected value="">Seleccione una opción</option>
                      <option value="EMPRESARIO">EMPRESARIO</option>
                      <option value="LÍDER EDUCATIVO">LÍDER EDUCATIVO</option>
                      <option value="LÍDER RELIGIOSO">LÍDER RELIGIOSO</option>
                      <option value="LÍDER DE MEDIO DE COMUNICACIÓN">LÍDER DE MEDIO DE
                        COMUNICACIÓN</option>
                      <option value="LÍDER DE CÁMARA O ASOCIACIÓN EMPRESARIAL">LÍDER DE CÁMARA O
                        ASOCIACIÓN EMPRESARIAL</option>
                      <option value="LÍDER DEL SECTOR SALUD">LÍDER DEL SECTOR SALUD</option>
                      <option value="LÍDER SINDICAL">LÍDER SINDICAL</option>
                      <option value="LÍDER POLÍTICO">LÍDER POLÍTICO</option>
                      <option value="LÍDER DE COLONIA">LÍDER DE COLONIA</option>
                      <option value="REPRESENTANTE DE A.C. / ONG">REPRESENTANTE DE A.C. / ONG
                      </option>
                      <option value="LÍDER JUVENIL">LÍDER JUVENIL</option>
                      <option value="LÍDER DE MUJERES">LÍDER DE MUJERES</option>
                      <option value="OTRO">OTRO</option>
                    </select>
                  </div>
                </div>
                <div class="col-12" *ngIf="sympathizerForm.get('actorType')?.value == 'OTRO'">
                  <label>¿Cuál?</label>
                  <input class="form-control" type="text" placeholder="" formControlName="otherActor" />
                </div>
                <!-- Fin de opciones de Actor importante -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <!-- <div class="col-12 col-md-4">
                <label>
                    <span class="reqForm">*</span> ¿El solicitante representa un punto de interés?
                </label>
                <div class="col-md-12">
                    <div class="radio radio-css radio-inline">
                        <input id="POIyes" formControlName="POIrelated" type="radio" [value]="1">
                        <label for="POIyes">Sí</label>
                    </div>
                    <div class="radio radio-css radio-inline">
                        <input id="POInot" formControlName="POIrelated" type="radio" [value]="0" checked="">
                        <label for="POInot">No</label>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4" *ngIf="sympathizerForm.get('POIrelated')?.value">
                <label>¿Cuál?</label>
                <div class="col-md-12">
                    <select class="form-control" formControlName="idPOI" placeholder="Cargando listado...">
                        <option selected value="0">Seleccione una opción</option>
                        <option value="{{POI.id}}" *ngFor="let POI of POIoptions">{{POI.name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-12 col-md-4" *ngIf="sympathizerForm.get('POIrelated')?.value">
                <label>Agregar un punto</label>
                <div class="col-md-12">
                    <button type="submit" (click)="openDialog()" class="btn btn-primary btn-lg btn-block">+</button>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <label>Tipo de participación:</label>
                <div class="col-md-12">
                    <select class="form-control" formControlName="participationType">
                        <option selected value="">Seleccione una opción</option>
                        <option value="Lider de colonia">Lider de colonia</option>
                        <option value="Brigadista">Brigadista</option>
                        <option value="Publicidad">Publicidad</option>
                        <option value="Otro">Otro</option>
                    </select>
                </div>
            </div> -->

        <div class="row">
          <div class="col-10 offset-1" *ngIf="Allurlparticipation.length == 0 ">
            <div class="cont-image">
              <label class="block mb-2 text-sm text-gesco dark:text-white" for="multiple_files">Agregar
                evidencia</label>
              <input class="block w-full text-sm text-gesco border-gesco rounded-lg cursor-pointer" type="file"
                id="multiple_files" accept=".pdf,.doc,.png,.jpg,.jpeg" multiple (change)="onFileChange($event)" />
              <p class="mt-1 text-sm text-gesco" id="file_input_help">(JPEG/PNG/PDF)</p>
            </div>
          </div>

          <div class="row" *ngIf="Allurlparticipation.length > 0">
            <div class="col">
              <label>Archivo de evidencia:</label>
              <div class="row ml-3">
                <label class="col-md-12 p-t-2">
                  Archivos cargados:
                </label>


                <ng-container *ngFor="let url of Allurlparticipation; let i = index">
                  <div class="drop-file" (click)="openImage(url)">
                    <a target="_blank" class="alert alert-drop-file show text-left cursor-pointer">
                      <span class="close text-danger" data-idx="0" data-dismiss="alert"
                        (click)="removeFile(url)">×</span>
                      <i style="margin-top:-10px;" class="fa fa-file-image text-purple p-r-5 fa-3x pull-left"></i>
                      {{getFileName(url)}}
                    </a>
                  </div>
                </ng-container>

              </div>
            </div>
          </div>
        </div>

        <!-- <div class="col-12 col-md-4" *ngIf="sympathizerForm.get('participationType')?.value == 'Otro'">
                <label>¿Cuál?</label>
                <input class="form-control" type="text" placeholder="" formControlName="otherType" />
            </div> -->
        <div class="col-12 col-md-4">
          <label>Bitácora:</label>
          <textarea class="form-control" formControlName="bitacora" placeholder=""></textarea>
        </div>
      </div>

      <div class="row mt-3" *ngIf="idSympathizer != null && long != null;">
        <app-form-map [lat]="lat" [lon]="long"></app-form-map>
      </div>

    </form>
  </ng-template>

  <div class="row justify-content-end pt-3">
    <button type="submit" (click)="buttonSave()" class="btn btn-primary btn-block">Guardar</button>
  </div>
</div>
<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header" style="background: #348fe2;">
        <h5 class="modal-title" id="exampleModalLabel" style="color:black;">Aviso de privacidad</h5>
        <button type="button" class="close" (click)="closeModal()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="color:#707478;font-size:14px;" [ngSwitch]="idWorkGroup">
        <ng-container *ngSwitchCase="'e028af7a1f904614d856cfe4113cd51a'">
          <p>
          <p>
            La Oficina de Atención Ciudadana del Diputado Local Waldo Fernández González, a través de la
            persona moral ExprésateCon S.A. de C.V., y sus filiales y/o subsidiarias recabarán los datos
            personales que Usted proporcione, estos estarán protegidos en términos de lo que dispone la
            Ley Federal de Protección de Datos Personales en Posesión de Particulares y su Reglamento.
            Para conocer el contenido completo del Aviso de Privacidad puede consultar el siguiente sitio
            de internet: www.gesco.mx. También lo puedes consultar en: <a
              href="https://waldofernandez.com/aviso-de-privacidad/">https://waldofernandez.com/aviso-de-privacidad/</a>
            <br>
            <span class="more" (click)="toggleText()">{{ isReadMore ? 'Leer más...' : 'Leer menos...' }}</span>
          </p>
          <div [hidden]="isReadMore">
            <p><b>AVISO DE PRIVACIDAD</b></p>
            <p>
              Cuidamos de tus datos personales al igual que nos preocupa tu seguridad, la protección de tu
              privacidad y de tus datos personales también es una prioridad para nosotros. Este Aviso de
              Privacidad explica qué tipo de datos personales solicitamos o recabamos, cómo, para qué fines
              y con quién pueden ser compartidos o revelados.
              <br>
              De conformidad con lo establecido en la Ley Federal de Protección de Datos Personales en
              Posesión de los Particulares, en lo sucesivo se denominará “La Ley”, en concordancia con su
              reglamento, hacemos de conocimiento al “Usuario” que la Oficina de Atención Ciudadana del
              Diputado Local Waldo Fernández González, ubicada en: Calle José Benítez Núm. 1925, Col.
              Obispado, C.P. 64060, Monterrey, Nuevo León, a través de la persona moral ExprésateCon S.A.
              de C.V., sus filiales y/o subsidiarias, con domicilio en Av. Paseo de la Reforma, Núm. 180,
              Piso 12, Col. Juárez, Alcaldía Cuauhtémoc, C.P. 06600, Ciudad de México; que en lo sucesivo
              se conocerán como los “Responsables”.
              <br>
              Recabarán y mantendrán los datos personales que el Usuario proporcione en los términos del presente Aviso.
              Asegurando que los datos personales se encuentren resguardados bajo estrictas MEDIDAS DE SEGURIDAD
              ADMINISTRATIVAS,
              TÉCNICAS y FÍSICAS las cuales han sido implementadas, con el objeto de preservar y garantizar sus datos
              personales contra daño, pérdida, alteración, destrucción o el uso, acceso o tratamiento no autorizados.
            </p>
            <p><b>¿PARA QUÉ FINES RECABAMOS Y UTILIZAMOS SUS DATOS PERSONALES?</b></p>
            <p>Los datos personales serán utilizados con las siguientes finalidades, mismas que podrán ser
              utilizadas de manera individual o bien en su conjunto, siendo:
              <br>
              • Finalidades primarias: (a) Administrar, almacenar, gestionar, dar seguimiento, controlar
              y mapear las solicitudes realizadas por la ciudadanía o las propias. (b) Atender solicitudes
              de Derechos ARCO (Acceso, Rectificación, Cancelación u Oposición).
              <br>
              • Finalidades secundarias: Brindar la atención y seguimiento correspondiente a sus dudas,
              quejas y/o aclaraciones sobre nuestros servicios.
            </p>
            <p><b>¿CÓMO OBTENEMOS SUS DATOS PERSONALES?</b></p>
            <p>Para llevar a cabo las finalidades señaladas en el presente aviso de privacidad, se podrán
              recabar los datos personales de diferentes maneras; cuando el Usuario los proporciona ya
              sea de manera física, vía telefónica, digital o mediante alguna aplicación tecnológica de
              comunicación; así como cuando vacía sus datos a través del formato de registro gesco.mx,
              nuestro sitio de Internet en la sección de “Contacto”; cuando envíe un correo electrónico;
              a través de fuentes de acceso público y/o a través de otras fuentes permitidas previstas
              en el artículo 10 de la “Ley”.</p>
            <p> <b>DATOS PERSONALES QUE PODRÁN SER RECABADOS Y TRATADOS.</b></p>
            <p>Los datos personales a recabar son: nombre completo; apellidos, fecha de nacimiento, sexo,
              CURP, estado civil, ocupación, domicilio completo, número de teléfono fijo y/o celular,
              correo electrónico; en caso de que aplique, cargo o función que desempeña. Así como documentos
              que se llegarán a solicitar en caso de realizar algún trámite.</p>
            <p><b>TRANSFERENCIA DE DATOS PERSONALES.</b></p>
            <p> Los Responsables se comprometen a no transferir la información personal a terceros sin su previo
              consentimiento, salvo las excepciones previstas en el artículo 37 de la “Ley” y el artículo 38 de
              su Reglamento, así como a realizar esta transferencia en los términos que fija esa Ley.</p>
            <p><b>DERECHOS ARCO Y REVOCACIÓN DEL CONSENTIMIENTO.</b></p>
            <p>
              En todo momento el Usuario tiene derecho de acceder, rectificar y cancelar sus datos personales, así
              como de oponerse al tratamiento de los mismos o revocar el consentimiento que nos ha otorgado, a fin
              de que dejemos de hacer uso de ellos; para lo cual deberá solicitar nuestro formato de “Solicitud de
              Ejercicio de Derechos ARCO (Acceso, Rectificación, Cancelación y Oposición)” ante nuestro Departamento
              de Datos Personales enviando un correo a la dirección: contacto@gesco.mx con el asunto “Formato ARCO”
              y con gusto le enviaremos el formato correspondiente para que sea debidamente llenado, y nos sea enviado
              de vuelta por la misma vía. Para dar trámite a la solicitud, el Usuario deberá adjuntar copia de su
              documento de identificación vigente (credencial para votar, pasaporte emitido por la Secretaría de
              Relaciones Exteriores, cartilla del Servicio Militar Nacional o cédula profesional) y exhibir el original
              para su cotejo, a fin de que pueda llevarse a cabo la autenticación de quien ejerce el Derecho ARCO. En
              los casos en que el ejercicio de los Derechos ARCO se realice a través de su representante legal, además
              de la acreditación de la identidad de ambos, se deberá entregar el poder notarial correspondiente, carta
              poder firmada ante dos testigos o declaración en comparecencia personal de usted. Cuando se quiera ejercer
              el derecho de rectificación, se tendrá que entregar la documentación que acredite el cambio solicitado
              de acuerdo a los datos personales a rectificar La respuesta a su solicitud se llevará a cabo dentro de
              los 20 días hábiles siguientes contados a partir de la fecha en que la misma haya sido recibida. “Los
              responsables” podrán ampliar este plazo hasta por 20 días hábiles más, cuando el caso lo amerite, previa
              notificación de esto a usted. La resolución adoptada por “Los responsables” será comunicada a usted a
              través de la opción que elija de las que se encuentran establecidas en el formato de Solicitud de
              Ejercicio
              de Derechos ARCO. En aquellos casos en los que se desee ejercer el derecho de Acceso a sus Datos
              Personales,
              la información solicitada será proporcionada a través del medio que usted haya decidido en el formato de
              “Solicitud de Ejercicio de Derechos ARCO” y en el supuesto en el que “El responsable” no cuente con sus
              datos personales, se lo informaremos por los medios a través de los cuáles realizó la solicitud. Para
              cualquier duda o aclaración respecto del presente procedimiento favor de enviar un correo a nuestro
              Departamento de Datos Personales a las siguientes direcciones: contacto@gesco.mx y
              agueda.ale.gestiones@gmail.com
              y con gusto lo atenderemos. </p>
            <p><b>OPCIONES Y MEDIOS PARA LIMITAR EL USO DE SUS DATOS PERSONALES.</b></p>
            <p>Para limitar el uso o divulgación de sus datos, para no recibir promociones de nuestros productos y/o
              servicios, puede solicitar a nuestro Departamento de Datos Personales a través del correo electrónico
              que se indica en el párrafo anterior, la inscripción gratuita en nuestros listados internos de exclusión,
              indicándonos su nombre completo, número(s) telefónico(s) y acreditar su identidad en los mismos términos
              señalados para el ejercicio de sus Derechos ARCO.</p>
            <p><b>MODIFICACIONES AL AVISO DE PRIVACIDAD.</b></p>
            <p>
              Nos reservamos el derecho de efectuar en cualquier momento modificaciones o actualizaciones al presente
              aviso de privacidad, para la atención de novedades legislativas, políticas internas o nuevos
              requerimientos
              para la prestación u ofrecimiento de nuestros productos y servicios. En tal caso, “Los Responsables”
              publicarán dichas modificaciones en su sitio de internet www.gesco.mx. De cualquier manera, le
              recomendamos
              que revise nuestro aviso de privacidad cada vez que haga uso de nuestra página de Internet, a efecto de
              conocer los cambios, modificaciones o actualizaciones que se realicen al mismo.
            </p>
            <p>
              Revisamos regularmente este Aviso de Privacidad. La última versión fue actualizada en abril de 2023.
            </p>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'79acf91235e1f1b5395e58619078f113'">
          <p>
            La Oficina de Atención Ciudadana de la Regidora Águeda Ale Valdes, a través de la persona moral ExprésateCon
            S.A. de C.V.,
            y sus filiales y/o subsidiarias recabarán los datos personales que Usted proporcione, estos estarán
            protegidos en términos
            de lo que dispone la Ley Federal de Protección de Datos Personales en Posesión de Particulares y su
            Reglamento. Para conocer
            el contenido completo del Aviso de Privacidad puede consultar el siguiente sitio de internet:
            www.gesco.mx<br>
            <span class="more" (click)="toggleText()">{{ isReadMore ? 'Leer más...' : 'Leer menos...' }}</span>
          </p>
          <div [hidden]="isReadMore">
            <p><b>AVISO DE PRIVACIDAD </b></p>
            <p>Cuidamos de tus datos personales al igual que nos preocupa tu seguridad, la protección de tu privacidad y
              de tus datos
              personales también es una prioridad para nosotros. Este Aviso de Privacidad explica qué tipo de datos
              personales solicitamos
              o recabamos, cómo, para qué fines y con quién pueden ser compartidos o revelados.
              <br>
              De conformidad con lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de los
              Particulares,
              en lo sucesivo se denominará “La Ley”, en concordancia con su reglamento, hacemos de conocimiento al
              “Usuario” que la
              Oficina de Atención Ciudadana de la Regidora del Municipio de Monterrey, N. L. Águeda Ale Valdés, ubicada
              en: C. Ignacio
              Zaragoza Sur S/N, 1er piso Regidores, Centro, 64000 Monterrey, N.L, a través de la persona moral
              ExprésateCon S.A. de
              C.V., sus filiales y/o subsidiarias, con domicilio en Av. Paseo de la Reforma, Núm. 180, Piso 12, Col.
              Juárez, Alc.
              Cuauhtémoc, C.P. 06600, Ciudad de México; que en lo sucesivo se conocerán como los “Responsables”.
              <br>
              Recabarán y mantendrán los datos personales que el Usuario proporcione en los términos del presente Aviso.
              Asegurando
              que los datos personales se encuentren resguardados bajo estrictas MEDIDAS DE SEGURIDAD ADMINISTRATIVAS,
              TÉCNICAS y
              FÍSICAS las cuales han sido implementadas, con el objeto de preservar y garantizar sus datos personales
              contra daño,
              pérdida, alteración, destrucción o el uso, acceso o tratamiento no autorizados.

            </p>
            <p><b>¿PARA QUÉ FINES RECABAMOS Y UTILIZAMOS SUS DATOS PERSONALES?</b></p>
            <p>Los datos personales serán utilizados con las siguientes finalidades, mismas que podrán ser utilizadas de
              manera
              individual o bien en su conjunto, siendo:
              <br>
              • Finalidades primarias: (a) administrar, almacenar, gestionar, dar seguimiento, controlar y mapear las
              solicitudes
              realizadas por la ciudadanía o las propias. (b) Atender solicitudes de Derechos ARCO (Acceso,
              Rectificación,
              Cancelación u Oposición).
              <br>
              • Finalidades secundarias: Brindar la atención y seguimiento correspondiente a sus dudas,
              quejas y/o aclaraciones sobre nuestros servicios.
            </p>
            <p><b>¿CÓMO OBTENEMOS SUS DATOS PERSONALES?</b></p>
            <p>Para llevar a cabo las finalidades señaladas en el presente aviso de privacidad, se podrán recabar los
              datos
              personales de diferentes maneras; cuando el Usuario los proporciona ya sea de manera física, vía
              telefónica,
              digital o mediante alguna aplicación tecnológica de comunicación; así como cuando vacía sus datos a través
              del formato de registro gesco.mx, nuestro sitio de Internet en la sección de “Contacto”; cuando envíe un
              correo electrónico; a través de fuentes de acceso público y/o a través de otras fuentes permitidas
              previstas en el artículo 10 de la “Ley”.</p>
            <p> <b>DATOS PERSONALES QUE PODRÁN SER RECABADOS Y TRATADOS.</b></p>
            <p>Los datos personales a recabar son: nombre completo; apellidos, fecha de nacimiento, sexo, CURP, estado
              civil,
              ocupación, domicilio completo, número de teléfono fijo y/o celular, correo electrónico; en caso de que
              aplique,
              cargo o función que desempeña. Así como documentos que se llegarán a solicitar en caso de realizar algún
              trámite.</p>
            <p><b>TRANSFERENCIA DE DATOS PERSONALES.</b></p>
            <p> Los Responsables se comprometen a no transferir la información personal a terceros sin su previo
              consentimiento,
              salvo las excepciones previstas en el artículo 37 de la “Ley” y el artículo 38 de su Reglamento, así como
              a
              realizar esta transferencia en los términos que fija esa Ley.</p>
            <p><b>DERECHOS ARCO Y REVOCACIÓN DEL CONSENTIMIENTO.</b></p>
            <p>En todo momento el Usuario tiene derecho de acceder, rectificar y cancelar sus datos personales, así como
              de oponerse
              al tratamiento de los mismos o revocar el consentimiento que nos ha otorgado, a fin de que dejemos de
              hacer uso
              de ellos; para lo cual deberá solicitar nuestro formato de “Solicitud de Ejercicio de Derechos ARCO
              (Acceso, Rectificación, Cancelación y Oposición)” ante nuestro Departamento de Datos Personales enviando
              un
              correo a la dirección: contacto@gesco.mx con el asunto “Formato ARCO” y con gusto le enviaremos el formato
              correspondiente para que sea debidamente llenado, y nos sea enviado de vuelta por la misma vía. Para dar
              trámite
              a la solicitud, el Usuario deberá adjuntar copia de su documento de identificación vigente
              (credencial para votar, pasaporte emitido por la Secretaría de Relaciones Exteriores, cartilla del
              Servicio
              Militar Nacional o cédula profesional) y exhibir el original para su cotejo, a fin de que pueda llevarse a
              cabo la autenticación de quien ejerce el Derecho ARCO. En los casos en que el ejercicio de los Derechos
              ARCO
              se realice a través de su representante legal, además de la acreditación de la identidad de ambos, se
              deberá
              entregar el poder notarial correspondiente, carta poder firmada ante dos testigos o declaración en
              comparecencia
              personal de usted. Cuando se quiera ejercer el derecho de rectificación, se tendrá que entregar la
              documentación
              que acredite el cambio solicitado de acuerdo a los datos personales a rectificar La respuesta a su
              solicitud
              se llevará a cabo dentro de los 20 días hábiles siguientes contados a partir de la fecha en que la misma
              haya
              sido recibida. “Los Responsables” podrán ampliar este plazo hasta por 20 días hábiles más, cuando el caso
              lo
              amerite, previa notificación de esto a usted. La resolución adoptada por “Los Responsables” será
              comunicada a
              usted a través de la opción que elija de las que se encuentran establecidas en el formato de Solicitud de
              Ejercicio de Derechos ARCO. En aquellos casos en los que se desee ejercer el derecho de Acceso a sus Datos
              Personales, la información solicitada será proporcionada a través del medio que usted haya decidido en el
              formato de “Solicitud de Ejercicio de Derechos ARCO” y en el supuesto en el que “El Responsable” no cuente
              con sus datos personales, se lo informaremos por los medios a través de los cuáles realizó la solicitud.
              Para cualquier duda o aclaración respecto del presente procedimiento favor de enviar un correo a nuestro
              Departamento de Datos Personales a las siguientes direcciones: contacto@gesco.mx y
              agueda.ale.gestiones@gmail.com
              y con gusto lo atenderemos.
            </p>
            <p><b>OPCIONES Y MEDIOS PARA LIMITAR EL USO DE SUS DATOS PERSONALES.</b></p>
            <p>Para limitar el uso o divulgación de sus datos, para no recibir promociones de nuestros productos y/o
              servicios,
              puede solicitar a nuestro Departamento de Datos Personales a través del correo electrónico que se indica
              en el párrafo anterior, la inscripción gratuita en nuestros listados internos de exclusión, indicándonos
              su
              nombre completo, número(s) telefónico(s) y acreditar su identidad en los mismos términos señalados
              para el ejercicio de sus Derechos ARCO.</p>
            <p><b>MODIFICACIONES AL AVISO DE PRIVACIDAD.</b></p>
            <p>Nos reservamos el derecho de efectuar en cualquier momento modificaciones o actualizaciones al presente
              aviso de
              privacidad, para la atención de novedades legislativas, políticas internas o nuevos requerimientos para la
              prestación u ofrecimiento de nuestros productos y servicios. En tal caso, “Los Responsables” publicaran
              dichas modificaciones en su sitio de internet www.gesco.mx. De cualquier manera, le recomendamos que
              revise
              nuestro aviso de privacidad cada vez que haga uso de nuestra página de Internet, a efecto de conocer los
              cambios, modificaciones o actualizaciones que se realicen al mismo.</p>
            <p>
              Revisamos regularmente este Aviso de Privacidad. La última versión fue actualizada en abril de 2023.
            </p>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'d41d8cd98f00b204e9800998ecf8427e'">
          <p>
          <p>
            La Oficina de Atención Ciudadana de la Diputada Local Daniela de los Santos Torres, a través de la persona
            moral
            ExprésateCon S.A. de C.V., y sus filiales y/o subsidiarias recabarán los datos personales que Usted
            proporcione,
            estos estarán protegidos en términos de lo que dispone la Ley Federal de Protección de Datos Personales en
            Posesión
            de Particulares y su Reglamento. Para conocer el contenido completo del Aviso de Privacidad puede consultar
            el
            siguiente sitio de internet: www.gesco.mx
            <br>
            <span class="more" (click)="toggleText()">{{ isReadMore ? 'Leer más...' : 'Leer menos...' }}</span>
          </p>
          <div [hidden]="isReadMore">
            <p><b>AVISO DE PRIVACIDAD</b></p>
            <p>Cuidamos de tus datos personales al igual que nos preocupa tu seguridad, la protección de tu privacidad y
              de tus
              datos personales también es una prioridad para nosotros. Este Aviso de Privacidad explica qué tipo de
              datos
              personales solicitamos o recabamos, cómo, para qué fines y con quién pueden ser compartidos o revelados.
              <br>
              De conformidad con lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de los
              Particulares,
              en lo sucesivo se denominará “La Ley”, en concordancia con su reglamento, hacemos de conocimiento al
              “Usuario” que
              la Oficina de Atención Ciudadana del Diputada Local Daniela de los Santos Torres, ubicada en: Av.
              Francisco I. Madero
              Ote 97, Centro Histórico de Morelia, C. P. 58000 Morelia, Michoacán, a través de la persona moral
              ExprésateCon S.A.
              de C.V., sus filiales y/o subsidiarias, con domicilio en Av. Paseo de la Reforma, Núm. 180, Piso 12, Col.
              Juárez, Alc.
              Cuauhtémoc, C.P. 06600, Ciudad de México; que en lo sucesivo se conocerán como los “Responsables”.
              <br>
              Recabarán y mantendrán los datos personales que el Usuario proporcione en los términos del presente Aviso.
              Asegurando
              que los datos personales se encuentren resguardados bajo estrictas MEDIDAS DE SEGURIDAD ADMINISTRATIVAS,
              TÉCNICAS y
              FÍSICAS las cuales han sido implementadas, con el objeto de preservar y garantizar sus datos personales
              contra daño,
              pérdida, alteración, destrucción o el uso, acceso o tratamiento no autorizados.
            </p>
            <p><b>¿PARA QUÉ FINES RECABAMOS Y UTILIZAMOS SUS DATOS PERSONALES?</b></p>
            <p>Los datos personales serán utilizados con las siguientes finalidades, mismas que podrán ser utilizadas de
              manera
              individual o bien en su conjunto, siendo:
              <br>
              • Finalidades primarias: (a) administrar, almacenar, gestionar, dar seguimiento, controlar y mapear las
              solicitudes
              realizadas por la ciudadanía o las propias. (b) Atender solicitudes de Derechos ARCO (Acceso,
              Rectificación,
              Cancelación u Oposición).
              <br>
              • Finalidades secundarias: Brindar la atención y seguimiento correspondiente a sus dudas,
              quejas y/o aclaraciones sobre nuestros servicios.
            </p>
            <p><b>¿CÓMO OBTENEMOS SUS DATOS PERSONALES?</b></p>
            <p>Para llevar a cabo las finalidades señaladas en el presente aviso de privacidad, se podrán recabar los
              datos
              personales de diferentes maneras; cuando el Usuario los proporciona ya sea de manera física, vía
              telefónica,
              digital o mediante alguna aplicación tecnológica de comunicación; así como cuando vacía sus datos a través
              del formato de registro gesco.mx, nuestro sitio de Internet en la sección de “Contacto”; cuando envíe un
              correo electrónico; a través de fuentes de acceso público y/o a través de otras fuentes permitidas
              previstas en el artículo 10 de la “Ley”.</p>
            <p> <b>DATOS PERSONALES QUE PODRÁN SER RECABADOS Y TRATADOS.</b></p>
            <p>Los datos personales a recabar son: nombre completo; apellidos, fecha de nacimiento, sexo, CURP, estado
              civil,
              ocupación, domicilio completo, número de teléfono fijo y/o celular, correo electrónico; en caso de que
              aplique,
              cargo o función que desempeña. Así como documentos que se llegarán a solicitar en caso de realizar algún
              trámite.</p>
            <p><b>TRANSFERENCIA DE DATOS PERSONALES.</b></p>
            <p> Los Responsables se comprometen a no transferir la información personal a terceros sin su previo
              consentimiento,
              salvo las excepciones previstas en el artículo 37 de la “Ley” y el artículo 38 de su Reglamento, así como
              a
              realizar esta transferencia en los términos que fija esa Ley.</p>
            <p><b>DERECHOS ARCO Y REVOCACIÓN DEL CONSENTIMIENTO.</b></p>
            <p>En todo momento el Usuario tiene derecho de acceder, rectificar y cancelar sus datos personales, así como
              de oponerse
              al tratamiento de los mismos o revocar el consentimiento que nos ha otorgado, a fin de que dejemos de
              hacer uso
              de ellos; para lo cual deberá solicitar nuestro formato de “Solicitud de Ejercicio de Derechos ARCO
              (Acceso, Rectificación, Cancelación y Oposición)” ante nuestro Departamento de Datos Personales enviando
              un
              correo a la dirección: contacto@gesco.mx con el asunto “Formato ARCO” y con gusto le enviaremos el formato
              correspondiente para que sea debidamente llenado, y nos sea enviado de vuelta por la misma vía. Para dar
              trámite
              a la solicitud, el Usuario deberá adjuntar copia de su documento de identificación vigente
              (credencial para votar, pasaporte emitido por la Secretaría de Relaciones Exteriores, cartilla del
              Servicio
              Militar Nacional o cédula profesional) y exhibir el original para su cotejo, a fin de que pueda llevarse a
              cabo la autenticación de quien ejerce el Derecho ARCO. En los casos en que el ejercicio de los Derechos
              ARCO
              se realice a través de su representante legal, además de la acreditación de la identidad de ambos, se
              deberá
              entregar el poder notarial correspondiente, carta poder firmada ante dos testigos o declaración en
              comparecencia
              personal de usted. Cuando se quiera ejercer el derecho de rectificación, se tendrá que entregar la
              documentación
              que acredite el cambio solicitado de acuerdo a los datos personales a rectificar La respuesta a su
              solicitud
              se llevará a cabo dentro de los 20 días hábiles siguientes contados a partir de la fecha en que la misma
              haya
              sido recibida. “Los Responsables” podrán ampliar este plazo hasta por 20 días hábiles más, cuando el caso
              lo
              amerite, previa notificación de esto a usted. La resolución adoptada por “Los Responsables” será
              comunicada a
              usted a través de la opción que elija de las que se encuentran establecidas en el formato de Solicitud de
              Ejercicio de Derechos ARCO. En aquellos casos en los que se desee ejercer el derecho de Acceso a sus Datos
              Personales, la información solicitada será proporcionada a través del medio que usted haya decidido en el
              formato de “Solicitud de Ejercicio de Derechos ARCO” y en el supuesto en el que “El Responsable” no cuente
              con sus datos personales, se lo informaremos por los medios a través de los cuáles realizó la solicitud.
              Para cualquier duda o aclaración respecto del presente procedimiento favor de enviar un correo a nuestro
              Departamento de Datos Personales a la siguiente dirección: contacto@gesco.mx con gusto lo atenderemos.
            </p>
            <p><b>OPCIONES Y MEDIOS PARA LIMITAR EL USO DE SUS DATOS PERSONALES.</b></p>
            <p>Para limitar el uso o divulgación de sus datos, para no recibir promociones de nuestros productos y/o
              servicios,
              puede solicitar a nuestro Departamento de Datos Personales a través del correo electrónico que se indica
              en el párrafo anterior, la inscripción gratuita en nuestros listados internos de exclusión, indicándonos
              su
              nombre completo, número(s) telefónico(s) y acreditar su identidad en los mismos términos señalados
              para el ejercicio de sus Derechos ARCO.</p>
            <p><b>MODIFICACIONES AL AVISO DE PRIVACIDAD.</b></p>
            <p>Nos reservamos el derecho de efectuar en cualquier momento modificaciones o actualizaciones al presente
              aviso de
              privacidad, para la atención de novedades legislativas, políticas internas o nuevos requerimientos para la
              prestación u ofrecimiento de nuestros productos y servicios. En tal caso, “Los Responsables” publicaran
              dichas modificaciones en su sitio de internet www.gesco.mx. De cualquier manera, le recomendamos que
              revise
              nuestro aviso de privacidad cada vez que haga uso de nuestra página de Internet, a efecto de conocer los
              cambios, modificaciones o actualizaciones que se realicen al mismo.</p>
            <p>
              Revisamos regularmente este Aviso de Privacidad. La última versión fue actualizada en abril de 2023.
            </p>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'57d58eb28773276c0a9b963e7d7bc59a'">
          <p>
            La Oficina de Atención Ciudadana de la Diputada Federal Cecilia Patrón Laviada, a través de la persona moral
            ExprésateCon S.A. de C.V., y sus filiales y/o subsidiarias recabarán los datos personales que Usted
            proporcione,
            estos estarán protegidos en términos de lo que dispone la Ley Federal de Protección de Datos Personales en
            Posesión de Particulares y su Reglamento. Para conocer el contenido completo del Aviso de Privacidad puede
            consultar el siguiente sitio de internet: www.gesco.mx
            <br>
            <span class="more" (click)="toggleText()">{{ isReadMore ? 'Leer más...' : 'Leer menos...' }}</span>
          </p>
          <div [hidden]="isReadMore">
            <p><b>AVISO DE PRIVACIDAD</b></p>
            <p>
              Cuidamos de tus datos personales al igual que nos preocupa tu seguridad, la protección de tu privacidad y
              de
              tus datos personales también es una prioridad para nosotros. Este Aviso de Privacidad explica qué tipo de
              datos
              personales solicitamos o recabamos, cómo, para qué fines y con quién pueden ser compartidos o revelados.
              <br>
              De conformidad con lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de los
              Particulares,
              en lo sucesivo se denominará “La Ley”, en concordancia con su reglamento, hacemos de conocimiento al
              “Usuario”
              que la Oficina de Atención Ciudadana de la Diputada Federal Cecilia Patrón Laviada, ubicada en: calle 23
              #201 X
              Av. Universidad Pedagógica Fracc. Vergel II C. P. 97173, Mérida Yucatán, a través de la persona moral
              ExprésateCon
              S.A. de C.V., sus filiales y/o subsidiarias, con domicilio en Av. Paseo de la Reforma, Núm. 180, Piso 12,
              Col. Juárez,
              Alc. Cuauhtémoc, C.P. 06600, Ciudad de México; que en lo sucesivo se conocerán como los “Responsables”.
              <br>
              Recabarán y mantendrán los datos personales que el Usuario proporcione en los términos del presente Aviso.
              Asegurando
              que los datos personales se encuentren resguardados bajo estrictas MEDIDAS DE SEGURIDAD ADMINISTRATIVAS,
              TÉCNICAS y
              FÍSICAS las cuales han sido implementadas, con el objeto de preservar y garantizar sus datos personales
              contra daño,
              pérdida, alteración, destrucción o el uso, acceso o tratamiento no autorizados.
            </p>
            <p><b>¿PARA QUÉ FINES RECABAMOS Y UTILIZAMOS SUS DATOS PERSONALES?</b></p>
            <p>Los datos personales serán utilizados con las siguientes finalidades, mismas que podrán ser utilizadas de
              manera
              individual o bien en su conjunto, siendo:
              <br>
              • Finalidades primarias: (a) administrar, almacenar, gestionar, dar seguimiento, controlar y mapear las
              solicitudes
              realizadas por la ciudadanía o las propias. (b) Atender solicitudes de Derechos ARCO (Acceso,
              Rectificación,
              Cancelación u Oposición).
              <br>
              • Finalidades secundarias: Brindar la atención y seguimiento correspondiente a sus dudas,
              quejas y/o aclaraciones sobre nuestros servicios.
            </p>
            <p><b>¿CÓMO OBTENEMOS SUS DATOS PERSONALES?</b></p>
            <p>Para llevar a cabo las finalidades señaladas en el presente aviso de privacidad, se podrán recabar los
              datos
              personales de diferentes maneras; cuando el Usuario los proporciona ya sea de manera física, vía
              telefónica,
              digital o mediante alguna aplicación tecnológica de comunicación; así como cuando vacía sus datos a través
              del formato de registro gesco.mx, nuestro sitio de Internet en la sección de “Contacto”; cuando envíe un
              correo electrónico; a través de fuentes de acceso público y/o a través de otras fuentes permitidas
              previstas en el artículo 10 de la “Ley”.</p>
            <p> <b>DATOS PERSONALES QUE PODRÁN SER RECABADOS Y TRATADOS.</b></p>
            <p>Los datos personales a recabar son: nombre completo; apellidos, fecha de nacimiento, sexo, CURP, estado
              civil,
              ocupación, domicilio completo, número de teléfono fijo y/o celular, correo electrónico; en caso de que
              aplique,
              cargo o función que desempeña. Así como documentos que se llegarán a solicitar en caso de realizar algún
              trámite.</p>
            <p><b>TRANSFERENCIA DE DATOS PERSONALES.</b></p>
            <p> Los Responsables se comprometen a no transferir la información personal a terceros sin su previo
              consentimiento,
              salvo las excepciones previstas en el artículo 37 de la “Ley” y el artículo 38 de su Reglamento, así como
              a
              realizar esta transferencia en los términos que fija esa Ley.</p>
            <p><b>DERECHOS ARCO Y REVOCACIÓN DEL CONSENTIMIENTO.</b></p>
            <p>
              En todo momento el Usuario tiene derecho de acceder, rectificar y cancelar sus datos personales, así como
              de
              oponerse al tratamiento de los mismos o revocar el consentimiento que nos ha otorgado, a fin de que
              dejemos de
              hacer uso de ellos; para lo cual deberá solicitar nuestro formato de “Solicitud de Ejercicio de Derechos
              ARCO
              (Acceso, Rectificación, Cancelación y Oposición)” ante nuestro Departamento de Datos Personales enviando
              un correo
              a la dirección: contacto@gesco.mx con el asunto “Formato ARCO” y con gusto le enviaremos el formato
              correspondiente
              para que sea debidamente llenado, y nos sea enviado de vuelta por la misma vía. Para dar trámite a la
              solicitud,
              el Usuario deberá adjuntar copia de su documento de identificación vigente (credencial para votar,
              pasaporte emitido
              por la Secretaría de Relaciones Exteriores, cartilla del Servicio Militar Nacional o cédula profesional) y
              exhibir
              el original para su cotejo, a fin de que pueda llevarse a cabo la autenticación de quien ejerce el Derecho
              ARCO.
              En los casos en que el ejercicio de los Derechos ARCO se realice a través de su representante legal,
              además de la
              acreditación de la identidad de ambos, se deberá entregar el poder notarial correspondiente, carta poder
              firmada
              ante dos testigos o declaración en comparecencia personal de usted. Cuando se quiera ejercer el derecho de
              rectificación,
              se tendrá que entregar la documentación que acredite el cambio solicitado de acuerdo a los datos
              personales a rectificar
              La respuesta a su solicitud se llevará a cabo dentro de los 20 días hábiles siguientes contados a partir
              de la fecha en
              que la misma haya sido recibida. “Los responsables” podrán ampliar este plazo hasta por 20 días hábiles
              más, cuando el
              caso lo amerite, previa notificación de esto a usted. La resolución adoptada por “Los responsables” será
              comunicada a
              usted a través de la opción que elija de las que se encuentran establecidas en el formato de Solicitud de
              Ejercicio de
              Derechos ARCO. En aquellos casos en los que se desee ejercer el derecho de Acceso a sus Datos Personales,
              la información
              solicitada será proporcionada a través del medio que usted haya decidido en el formato de “Solicitud de
              Ejercicio de Derechos
              ARCO” y en el supuesto en el que “El responsable” no cuente con sus datos personales, se lo informaremos
              por los medios a
              través de los cuáles realizó la solicitud. Para cualquier duda o aclaración respecto del presente
              procedimiento favor de
              enviar un correo a nuestro Departamento de Datos Personales a las siguientes direcciones:
              contacto@gesco.mx y casadeenlacecpl@gmail.com
              y con gusto lo atenderemos.
            </p>
            <p><b>OPCIONES Y MEDIOS PARA LIMITAR EL USO DE SUS DATOS PERSONALES.</b></p>
            <p>
              Para limitar el uso o divulgación de sus datos, para no recibir promociones de nuestros productos y/o
              servicios, puede
              solicitar a nuestro Departamento de Datos Personales a través del correo electrónico que se indica en el
              párrafo anterior,
              la inscripción gratuita en nuestros listados internos de exclusión, indicándonos su nombre completo,
              número(s) telefónico(s)
              y acreditar su identidad en los mismos términos señalados para el ejercicio de sus Derechos ARCO.
            </p>
            <p><b>MODIFICACIONES AL AVISO DE PRIVACIDAD.</b></p>
            <p>
              Nos reservamos el derecho de efectuar en cualquier momento modificaciones o actualizaciones al presente
              aviso de privacidad,
              para la atención de novedades legislativas, políticas internas o nuevos requerimientos para la prestación
              u ofrecimiento de
              nuestros productos y servicios. En tal caso, “Los Responsables” publicarán dichas modificaciones en su
              sitio de internet
              www.gesco.mx. De cualquier manera, le recomendamos que revise nuestro aviso de privacidad cada vez que
              haga uso de nuestra
              página de Internet, a efecto de conocer los cambios, modificaciones o actualizaciones que se realicen al
              mismo.
            </p>
            <p>
              Revisamos regularmente este Aviso de Privacidad. La última versión fue actualizada en abril de 2023.
            </p>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'70cc1093c0eaf4acff459500da656080'">
          <p>
            La Oficina de Atención Ciudadana de la Dirección de Obras Públicas del municipio de Apaseo el Grande,
            Guanajuato, a
            través de la persona moral ExprésateCon S.A. de C.V. y sus filiales y/o subsidiarias, recabarán los datos
            personales
            que usted proporcione, estos estarán protegidos en términos de lo que dispone la Ley Federal de Protección
            de Datos
            Personales en Posesión de Particulares y su Reglamento. Para conocer el contenido completo del Aviso de
            Privacidad
            puede consultar el siguiente sitio de internet: www.gesco.mx.
            <br>
            <span class="more" (click)="toggleText()">{{ isReadMore ? 'Leer más...' : 'Leer menos...' }}</span>
          </p>
          <div [hidden]="isReadMore">
            <p><b>AVISO DE PRIVACIDAD</b></p>
            <p>
              Cuidamos de tus datos personales al igual que nos preocupa tu seguridad, la protección de tu privacidad y
              de
              tus datos personales también es una prioridad para nosotros. Este Aviso de Privacidad explica qué tipo de
              datos
              personales solicitamos o recabamos, cómo, para qué fines y con quién pueden ser compartidos o revelados.
              <br>
              De conformidad con lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de los
              Particulares,
              en lo sucesivo se denominará “La Ley”, en concordancia con su reglamento, hacemos de conocimiento al
              “Usuario” que la
              Oficina de Atención Ciudadana de la Dirección de Obras Públicas del municipio de Apaseo el Grande,
              Guanajuato, ubicada
              en Jardín Hidalgo 105, Zona Centro, 38160, Apaseo el Grande, Guanajuato, a través de la persona moral
              ExprésateCon S.A.
              de C.V., sus filiales y/o subsidiarias, con domicilio en Av. Paseo de la Reforma, Núm. 180, Piso 12, Col.
              Juárez, Alc.
              Cuauhtémoc, C.P. 06600, Ciudad de México; que en lo sucesivo se conocerán como los “Responsables”.
              <br>
              Recabarán y mantendrán los datos personales que el Usuario proporcione en los términos del presente Aviso.
              Asegurando
              que los datos personales se encuentren resguardados bajo estrictas MEDIDAS DE SEGURIDAD ADMINISTRATIVAS,
              TÉCNICAS y
              FÍSICAS las cuales han sido implementadas, con el objeto de preservar y garantizar sus datos personales
              contra daño,
              pérdida, alteración, destrucción o el uso, acceso o tratamiento no autorizados.
            </p>
            <p><b>¿PARA QUÉ FINES RECABAMOS Y UTILIZAMOS SUS DATOS PERSONALES?</b></p>
            <p>Los datos personales serán utilizados con las siguientes finalidades, mismas que podrán ser utilizadas de
              manera
              individual o bien en su conjunto, siendo:
              <br>
              • Finalidades primarias: (a) administrar, almacenar, gestionar, dar seguimiento, controlar y mapear las
              solicitudes
              realizadas por la ciudadanía o las propias. (b) Atender solicitudes de Derechos ARCO (Acceso,
              Rectificación,
              Cancelación u Oposición).
              <br>
              • Finalidades secundarias: Brindar la atención y seguimiento correspondiente a sus dudas,
              quejas y/o aclaraciones sobre nuestros servicios.
            </p>
            <p><b>¿CÓMO OBTENEMOS SUS DATOS PERSONALES?</b></p>
            <p>Para llevar a cabo las finalidades señaladas en el presente aviso de privacidad, se podrán recabar los
              datos
              personales de diferentes maneras; cuando el Usuario los proporciona ya sea de manera física, vía
              telefónica,
              digital o mediante alguna aplicación tecnológica de comunicación; así como cuando vacía sus datos a través
              del formato de registro gesco.mx, nuestro sitio de Internet en la sección de “Contacto”; cuando envíe un
              correo electrónico; a través de fuentes de acceso público y/o a través de otras fuentes permitidas
              previstas en el artículo 10 de la “Ley”.</p>
            <p> <b>DATOS PERSONALES QUE PODRÁN SER RECABADOS Y TRATADOS.</b></p>
            <p>Los datos personales a recabar son: nombre completo; apellidos, fecha de nacimiento, sexo, CURP, estado
              civil,
              ocupación, domicilio completo, número de teléfono fijo y/o celular, correo electrónico; en caso de que
              aplique,
              cargo o función que desempeña. Así como documentos que se llegarán a solicitar en caso de realizar algún
              trámite.</p>
            <p><b>TRANSFERENCIA DE DATOS PERSONALES.</b></p>
            <p> Los Responsables se comprometen a no transferir la información personal a terceros sin su previo
              consentimiento,
              salvo las excepciones previstas en el artículo 37 de la “Ley” y el artículo 38 de su Reglamento, así como
              a
              realizar esta transferencia en los términos que fija esa Ley.</p>
            <p><b>DERECHOS ARCO Y REVOCACIÓN DEL CONSENTIMIENTO.</b></p>
            <p>
              En todo momento el Usuario tiene derecho de acceder, rectificar y cancelar sus datos personales, así como
              de
              oponerse al tratamiento de los mismos o revocar el consentimiento que nos ha otorgado, a fin de que
              dejemos de
              hacer uso de ellos; para lo cual deberá solicitar nuestro formato de “Solicitud de Ejercicio de Derechos
              ARCO
              (Acceso, Rectificación, Cancelación y Oposición)” ante nuestro Departamento de Datos Personales enviando
              un correo
              a la dirección: contacto@gesco.mx con el asunto “Formato ARCO” y con gusto le enviaremos el formato
              correspondiente
              para que sea debidamente llenado, y nos sea enviado de vuelta por la misma vía. Para dar trámite a la
              solicitud,
              el Usuario deberá adjuntar copia de su documento de identificación vigente (credencial para votar,
              pasaporte emitido
              por la Secretaría de Relaciones Exteriores, cartilla del Servicio Militar Nacional o cédula profesional) y
              exhibir
              el original para su cotejo, a fin de que pueda llevarse a cabo la autenticación de quien ejerce el Derecho
              ARCO.
              En los casos en que el ejercicio de los Derechos ARCO se realice a través de su representante legal,
              además de la
              acreditación de la identidad de ambos, se deberá entregar el poder notarial correspondiente, carta poder
              firmada
              ante dos testigos o declaración en comparecencia personal de usted. Cuando se quiera ejercer el derecho de
              rectificación,
              se tendrá que entregar la documentación que acredite el cambio solicitado de acuerdo a los datos
              personales a rectificar
              La respuesta a su solicitud se llevará a cabo dentro de los 20 días hábiles siguientes contados a partir
              de la fecha en
              que la misma haya sido recibida. “Los responsables” podrán ampliar este plazo hasta por 20 días hábiles
              más, cuando el
              caso lo amerite, previa notificación de esto a usted. La resolución adoptada por “Los responsables” será
              comunicada a
              usted a través de la opción que elija de las que se encuentran establecidas en el formato de Solicitud de
              Ejercicio de
              Derechos ARCO. En aquellos casos en los que se desee ejercer el derecho de Acceso a sus Datos Personales,
              la información
              solicitada será proporcionada a través del medio que usted haya decidido en el formato de “Solicitud de
              Ejercicio de Derechos
              ARCO” y en el supuesto en el que “El responsable” no cuente con sus datos personales, se lo informaremos
              por los medios a
              través de los cuáles realizó la solicitud. Para cualquier duda o aclaración respecto del presente
              procedimiento favor de
              enviar un correo a nuestro Departamento de Datos Personales a la siguiente dirección: contacto@gesco.mx.
              Con gusto lo atenderemos.
            </p>
            <p><b>OPCIONES Y MEDIOS PARA LIMITAR EL USO DE SUS DATOS PERSONALES.</b></p>
            <p>
              Para limitar el uso o divulgación de sus datos, para no recibir promociones de nuestros productos y/o
              servicios, puede
              solicitar a nuestro Departamento de Datos Personales a través del correo electrónico que se indica en el
              párrafo anterior,
              la inscripción gratuita en nuestros listados internos de exclusión, indicándonos su nombre completo,
              número(s) telefónico(s)
              y acreditar su identidad en los mismos términos señalados para el ejercicio de sus Derechos ARCO.
            </p>
            <p><b>MODIFICACIONES AL AVISO DE PRIVACIDAD.</b></p>
            <p>
              Nos reservamos el derecho de efectuar en cualquier momento modificaciones o actualizaciones al presente
              aviso de privacidad,
              para la atención de novedades legislativas, políticas internas o nuevos requerimientos para la prestación
              u ofrecimiento de
              nuestros productos y servicios. En tal caso, “Los Responsables” publicarán dichas modificaciones en su
              sitio de internet
              www.gesco.mx. De cualquier manera, le recomendamos que revise nuestro aviso de privacidad cada vez que
              haga uso de nuestra
              página de internet, a efecto de conocer los cambios, modificaciones o actualizaciones que se realicen al
              mismo.
            </p>
            <p>
              El Responsable también podrá comunicar cambios a este Aviso de Privacidad por medio de correo electrónico,
              cuando dicho medio
              hubiese sido establecido como canal de comunicación, durante la vigencia de cualquier relación jurídica.
              En todo momento podrás
              solicitar una copia de la última versión de este Aviso de Privacidad a nuestro Departamento de Datos
              Personales, a través del
              correo electrónico contacto@gesco.mx.
            </p>
            <p>
              El Aviso de Privacidad que regula el tratamiento de tus datos personales será el que aparezca publicado en
              el sitio web https://www.gesco.mx.
            </p><br>
            <p>
              Revisamos regularmente este Aviso de Privacidad. La última versión fue actualizada en septiembre de 2023.
            </p>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'c232844f8b17a16e8c4e7b106dc34d42'">
          <p>
            La Oficina de Atención Ciudadana de Alejandra Ortíz García, a través de la persona moral ExprésateCon S.A.
            de C.V. y
            sus filiales y/o subsidiarias, recabarán los datos personales que usted proporcione, estos estarán
            protegidos en
            términos de lo que dispone la Ley Federal de Protección de Datos Personales en Posesión de Particulares y su
            Reglamento.
            Para conocer el contenido completo del Aviso de Privacidad puede consultar el siguiente sitio de internet:
            www.gesco.mx.
            <br>
            <span class="more" (click)="toggleText()">{{ isReadMore ? 'Leer más...' : 'Leer menos...' }}</span>
          </p>
          <div [hidden]="isReadMore">
            <p><b>AVISO DE PRIVACIDAD</b></p>
            <p>
              Cuidamos de tus datos personales al igual que nos preocupa tu seguridad, la protección de tu privacidad y
              de
              tus datos personales también es una prioridad para nosotros. Este Aviso de Privacidad explica qué tipo de
              datos
              personales solicitamos o recabamos, cómo, para qué fines y con quién pueden ser compartidos o revelados.
              <br>
              De conformidad con lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de los
              Particulares,
              en lo sucesivo se denominará “La Ley”, en concordancia con su reglamento, hacemos de conocimiento al
              “Usuario” que la
              Oficina de Atención Ciudadana de la C. Alejandra Ortíz García, ubicada en calle And III, núm. 180, 25150,
              Alfredo V
              Bonfil, Saltillo, Coahuila, a través de la persona moral ExprésateCon S.A. de C.V., sus filiales y/o
              subsidiarias, con
              domicilio en Av. Paseo de la Reforma, núm. 180, piso 12, col. Juárez, Alc. Cuauhtémoc, 06600, Ciudad de
              México; que en
              lo sucesivo se conocerán como los “Responsables”.
              <br>
              Recabarán y mantendrán los datos personales que el Usuario proporcione en los términos del presente Aviso.
              Asegurando
              que los datos personales se encuentren resguardados bajo estrictas MEDIDAS DE SEGURIDAD ADMINISTRATIVAS,
              TÉCNICAS y
              FÍSICAS las cuales han sido implementadas, con el objeto de preservar y garantizar sus datos personales
              contra daño,
              pérdida, alteración, destrucción o el uso, acceso o tratamiento no autorizados.
            </p>
            <p><b>¿PARA QUÉ FINES RECABAMOS Y UTILIZAMOS SUS DATOS PERSONALES?</b></p>
            <p>Los datos personales serán utilizados con las siguientes finalidades, mismas que podrán ser utilizadas de
              manera
              individual o bien en su conjunto, siendo:
              <br>
              • Finalidades primarias: (a) administrar, almacenar, gestionar, dar seguimiento, controlar y mapear las
              solicitudes
              realizadas por la ciudadanía o las propias. (b) Atender solicitudes de Derechos ARCO (Acceso,
              Rectificación,
              Cancelación u Oposición).
              <br>
              • Finalidades secundarias: Brindar la atención y seguimiento correspondiente a sus dudas,
              quejas y/o aclaraciones sobre nuestros servicios.
            </p>
            <p><b>¿CÓMO OBTENEMOS SUS DATOS PERSONALES?</b></p>
            <p>Para llevar a cabo las finalidades señaladas en el presente aviso de privacidad, se podrán recabar los
              datos
              personales de diferentes maneras; cuando el Usuario los proporciona ya sea de manera física, vía
              telefónica,
              digital o mediante alguna aplicación tecnológica de comunicación; así como cuando vacía sus datos a través
              del formato de registro gesco.mx, nuestro sitio de Internet en la sección de “Contacto”; cuando envíe un
              correo electrónico; a través de fuentes de acceso público y/o a través de otras fuentes permitidas
              previstas en el artículo 10 de la “Ley”.</p>
            <p> <b>DATOS PERSONALES QUE PODRÁN SER RECABADOS Y TRATADOS.</b></p>
            <p>Los datos personales a recabar son: nombre completo; apellidos, fecha de nacimiento, sexo, CURP, estado
              civil,
              ocupación, domicilio completo, número de teléfono fijo y/o celular, correo electrónico; en caso de que
              aplique,
              cargo o función que desempeña. Así como documentos que se llegarán a solicitar en caso de realizar algún
              trámite.</p>
            <p><b>TRANSFERENCIA DE DATOS PERSONALES.</b></p>
            <p> Los Responsables se comprometen a no transferir la información personal a terceros sin su previo
              consentimiento,
              salvo las excepciones previstas en el artículo 37 de la “Ley” y el artículo 38 de su Reglamento, así como
              a
              realizar esta transferencia en los términos que fija esa Ley.</p>
            <p><b>DERECHOS ARCO Y REVOCACIÓN DEL CONSENTIMIENTO.</b></p>
            <p>
              En todo momento el Usuario tiene derecho de acceder, rectificar y cancelar sus datos personales, así como
              de
              oponerse al tratamiento de los mismos o revocar el consentimiento que nos ha otorgado, a fin de que
              dejemos de
              hacer uso de ellos; para lo cual deberá solicitar nuestro formato de “Solicitud de Ejercicio de Derechos
              ARCO
              (Acceso, Rectificación, Cancelación y Oposición)” ante nuestro Departamento de Datos Personales enviando
              un correo
              a la dirección: contacto@gesco.mx con el asunto “Formato ARCO” y con gusto le enviaremos el formato
              correspondiente
              para que sea debidamente llenado, y nos sea enviado de vuelta por la misma vía. Para dar trámite a la
              solicitud,
              el Usuario deberá adjuntar copia de su documento de identificación vigente (credencial para votar,
              pasaporte emitido
              por la Secretaría de Relaciones Exteriores, cartilla del Servicio Militar Nacional o cédula profesional) y
              exhibir
              el original para su cotejo, a fin de que pueda llevarse a cabo la autenticación de quien ejerce el Derecho
              ARCO.
              En los casos en que el ejercicio de los Derechos ARCO se realice a través de su representante legal,
              además de la
              acreditación de la identidad de ambos, se deberá entregar el poder notarial correspondiente, carta poder
              firmada
              ante dos testigos o declaración en comparecencia personal de usted. Cuando se quiera ejercer el derecho de
              rectificación,
              se tendrá que entregar la documentación que acredite el cambio solicitado de acuerdo a los datos
              personales a rectificar
              La respuesta a su solicitud se llevará a cabo dentro de los 20 días hábiles siguientes contados a partir
              de la fecha en
              que la misma haya sido recibida. “Los responsables” podrán ampliar este plazo hasta por 20 días hábiles
              más, cuando el
              caso lo amerite, previa notificación de esto a usted. La resolución adoptada por “Los responsables” será
              comunicada a
              usted a través de la opción que elija de las que se encuentran establecidas en el formato de Solicitud de
              Ejercicio de
              Derechos ARCO. En aquellos casos en los que se desee ejercer el derecho de Acceso a sus Datos Personales,
              la información
              solicitada será proporcionada a través del medio que usted haya decidido en el formato de “Solicitud de
              Ejercicio de Derechos
              ARCO” y en el supuesto en el que “El responsable” no cuente con sus datos personales, se lo informaremos
              por los medios a
              través de los cuáles realizó la solicitud. Para cualquier duda o aclaración respecto del presente
              procedimiento favor de
              enviar un correo a nuestro Departamento de Datos Personales a la siguiente dirección: contacto@gesco.mx.
              Con gusto lo atenderemos.
            </p>
            <p><b>OPCIONES Y MEDIOS PARA LIMITAR EL USO DE SUS DATOS PERSONALES.</b></p>
            <p>
              Para limitar el uso o divulgación de sus datos, para no recibir promociones de nuestros productos y/o
              servicios, puede
              solicitar a nuestro Departamento de Datos Personales a través del correo electrónico que se indica en el
              párrafo anterior,
              la inscripción gratuita en nuestros listados internos de exclusión, indicándonos su nombre completo,
              número(s) telefónico(s)
              y acreditar su identidad en los mismos términos señalados para el ejercicio de sus Derechos ARCO.
            </p>
            <p><b>MODIFICACIONES AL AVISO DE PRIVACIDAD.</b></p>
            <p>
              Nos reservamos el derecho de efectuar en cualquier momento modificaciones o actualizaciones al presente
              aviso de privacidad,
              para la atención de novedades legislativas, políticas internas o nuevos requerimientos para la prestación
              u ofrecimiento de
              nuestros productos y servicios. En tal caso, “Los Responsables” publicarán dichas modificaciones en su
              sitio de internet
              www.gesco.mx. De cualquier manera, le recomendamos que revise nuestro aviso de privacidad cada vez que
              haga uso de nuestra
              página de internet, a efecto de conocer los cambios, modificaciones o actualizaciones que se realicen al
              mismo.
            </p>
            <p>
              El Responsable también podrá comunicar cambios a este Aviso de Privacidad por medio de correo electrónico,
              cuando dicho medio
              hubiese sido establecido como canal de comunicación, durante la vigencia de cualquier relación jurídica.
              En todo momento podrás
              solicitar una copia de la última versión de este Aviso de Privacidad a nuestro Departamento de Datos
              Personales, a través del
              correo electrónico contacto@gesco.mx.
            </p>
            <p>
              El Aviso de Privacidad que regula el tratamiento de tus datos personales será el que aparezca publicado en
              el sitio web https://www.gesco.mx.
            </p><br>
            <p>
              Revisamos regularmente este Aviso de Privacidad. La última versión fue actualizada en septiembre de 2023.
            </p>
          </div>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <p>
          <p><b>AVISO DE PRIVACIDAD INTEGRAL RESPONSABLE DEL TRATAMIENTO DE SUS DATOS PERSONALES.</b></p>
          <p>De conformidad con lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de los
            Particulares (en lo sucesivo la “Ley”) y su Reglamento le informamos que
            ExprésateCon S.A. de C.V., sus filiales y/o subsidiarias (en lo sucesivo el “Responsable”), con domicilio en
            Avenida Paseo de la Reforma, No. 180, piso 12, Col. Juárez, Del. Cuauhtémoc,
            C.P. 06600, Ciudad de México, recabará los datos personales alojados en la plataforma en términos del
            presente Aviso de Privacidad (en lo sucesivo “Aviso”). MEDIDAS DE SEGURIDAD. Los
            datos personales se encuentran resguardados bajo estrictas medidas de seguridad administrativas, técnicas y
            físicas las cuales han sido implementadas, en términos del Reglamento, con
            el objeto de preservar y garantizar los datos personales contra daño, pérdida, alteración, destrucción o el
            uso, acceso o tratamiento no autorizados.</p>
          <p>Los datos personales podrán ser utilizados para llevar a cabo alguna o todas las finalidades siguientes: •
            Finalidades primarias: (a) administrar, almacenar, gestionar, dar seguimiento,
            controlar y mapear las solicitudes realizadas por la ciudadanía o las propias. (b) Atender solicitudes de
            Derechos ARCO (Acceso, Rectificación, Cancelación y Oposición). • Finalidades
            secundarias: Brindar la atención y seguimiento correspondiente a sus dudas, quejas y/o aclaraciones sobre
            nuestros servicios.</p>
          <p><b>¿CÓMO OBTENEMOS SUS DATOS PERSONALES?</b></p>
          <p>Para las finalidades señaladas en este aviso de privacidad, podemos recabar datos personales cuando nos
            sean proporcionados mediante el ingreso de los mismos a la plataforma Gesco, Gesco
            App o SolicitApp y/o a través de otras fuentes permitidas previstas en el artículo 10 “Ley”.</p>
          <p><b>DATOS PERSONALES QUE PODRÁN SER RECABADOS Y TRATADOS.</b></p>
          <p>Los datos personales que se recabarán son: nombre completo; apellidos, fecha de nacimiento, sexo, CURP,
            estado civil, ocupación, domicilio completo, número de teléfono fijo y/o celular,
            correo electrónico; en caso de que aplique, cargo o función que desempeña. Así como documentos que se
            llegarán a solicitar en caso de realizar algún trámite.</p>
          <p><b>TRANSFERENCIA DE DATOS PERSONALES.</b></p>
          <p>Nos comprometemos a no transferir la información personal a terceros sin su previo consentimiento, salvo
            las excepciones previstas en el artículo 37 de la “Ley” y el artículo 38 de su
            Reglamento, así como a realizar esta transferencia en los términos que fija esa Ley.</p>
          <p><b>DERECHOS ARCO Y REVOCACIÓN DEL CONSENTIMIENTO.</b></p>
          <p>En todo momento la persona dueña de los datos personales tiene derecho de acceder, rectificar y cancelar
            sus datos, así como de oponerse al tratamiento de los mismos o revocar el
            consentimiento que nos ha otorgado, a fin de que dejemos de hacer uso de ellos; para lo cual deberá
            solicitar nuestro formato de “Solicitud de Ejercicio de Derechos ARCO (Acceso,
            Rectificación, Cancelación y Oposición)” ante nuestro Departamento de Datos Personales enviando un correo a
            la dirección: contacto@gesco.mx con el asunto “Formato ARCO” y con gusto
            le enviaremos el formato correspondiente para que sea debidamente llenado, y nos sea enviado de vuelta por
            la misma vía. Para dar trámite a su solicitud, deberá adjuntar copia de su
            documento de identificación vigente (credencial para votar, pasaporte emitido por la Secretaría de
            Relaciones Exteriores, cartilla del Servicio Militar Nacional o cédula profesional)
            y exhibir el original para su cotejo, a fin de que pueda llevarse a cabo la autenticación de quien ejerce el
            Derecho ARCO. En los casos en que el ejercicio de los Derechos ARCO se realice
            a través de su representante legal, además de la acreditación de la identidad de ambos, se deberá entregar
            el poder notarial correspondiente, carta poder firmada ante dos testigos o
            declaración en comparecencia personal de usted. Cuando se quiera ejercer el derecho de rectificación, se
            tendrá que entregar la documentación que acredite el cambio solicitado de acuerdo
            a los datos personales a rectificar La respuesta a su solicitud se llevará a cabo dentro de los 20 días
            hábiles siguientes contados a partir de la fecha en que la misma haya sido recibida.
            “El responsable” podrá ampliar este plazo hasta por 20 días hábiles más, cuando el caso lo amerite, previa
            notificación de esto a usted. La resolución adoptada por “El responsable” será
            comunicada a usted a través de la opción que elija de las que se encuentran establecidas en el formato de
            Solicitud de Ejercicio de Derechos ARCO. En aquellos casos en los que se desee
            ejercer el derecho de Acceso a sus Datos Personales, la información solicitada será proporcionada a través
            del medio que usted haya decidido en el formato de “Solicitud de Ejercicio de
            Derechos ARCO” y en el supuesto en el que “El responsable” no cuente con sus datos personales, se lo
            informaremos por los medios a través de los cuáles realizó la solicitud. Para cualquier
            duda o aclaración respecto del presente procedimiento favor de enviar un correo a nuestro Departamento de
            Datos Personales a la siguiente dirección: contacto@gesco.mx y con gusto lo
            atenderemos.</p>
          <p><b>OPCIONES Y MEDIOS PARA LIMITAR EL USO DE SUS DATOS PERSONALES.</b></p>
          <p>Para limitar el uso o divulgación de datos, para no recibir promociones de nuestros productos y/o
            servicios, puede solicitar a nuestro Departamento de Datos Personales a través del correo
            electrónico que se indica en el párrafo anterior, la inscripción gratuita en nuestros listados internos de
            exclusión, indicándonos su nombre completo, número(s) telefónico(s) y acreditar
            su identidad en los mismos términos señalados para el ejercicio de sus Derechos ARCO.</p>
          <p><b>MODIFICACIONES AL AVISO DE PRIVACIDAD.</b></p>
          <p>Nos reservamos el derecho de efectuar en cualquier momento modificaciones o actualizaciones al presente
            aviso de privacidad, para la atención de novedades legislativas, políticas internas
            o nuevos requerimientos para la prestación u ofrecimiento de nuestros productos y servicios. En tal caso,
            “El responsable” publicará dichas modificaciones en su sitio de internet www.gesco.mx
            De cualquier manera, le recomendamos que revise nuestro aviso de privacidad cada vez que haga uso de nuestra
            página de Internet, a efecto de conocer los cambios, modificaciones o
            actualizaciones que se realicen al mismo.<br><br>Revisamos regularmente este Aviso de Privacidad. La última
            versión fue actualizada en abril de 2023.</p>
        </ng-container>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="closeModal()" style="font-size: 14px;">Cerrar</button>
      </div>
    </div>
  </div>
</div>
