<head>
    <link href="https://cdnjs.cloudflare.com/ajax/libs/flowbite/1.8.0/flowbite.min.css" rel="stylesheet" />
</head>

<div class="contenedor">
    <form [formGroup]="proposalInitiativeForm">
        <div class="row">
            <div class="col-12 col-md-3">
                <label>Buscador de categorías (Opcional):</label>
                <div class="input-group">
                    <input type="text" placeholder="Buscar Categoría" class="form-control" [(ngModel)]="searchValue" (ngModelChange)="onSearchChange($event)"
                        [ngModelOptions]="{standalone: true}">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary" type="button" (click)="searchCategory()">
                            <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
                <ng-container #menu *ngIf="responseSearch.length != 0 && selectedSearch == false">
                    <div class="col-12 content-seach searchList">
                        <ng-container *ngIf="responseSearch.length == 0 else searchMenu">
                            <label class="darkGray">No hay registros</label>
                        </ng-container>
                        <ng-template #searchMenu>
                            <ng-container *ngFor="let result of responseSearch; let i = index">
                                <ng-container>
                                    <label (click)="selectOption(result.ids)"
                                        [ngClass]=" i%2==0 ? 'darkGray' : 'lowGray'">{{result.name}}</label>
                                </ng-container>
                            </ng-container>
                            <br>
                        </ng-template>
                    </div>
                </ng-container>
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col-12 col-md-4">
                <span style="color: red">* </span>
                <label>Tema:</label>
                <select class="form-control" formControlName="theme_id" (change)="onThemeSelectChange()">
                    <option value="">Selecciona una opción</option>
                    <option *ngFor="let theme of themes" value="{{theme.id}}">{{theme.name}}</option>
                </select>
            </div>
            <div class="col-12 col-md-4">
                <span style="color: red">* </span>
                <label>Subtema:</label>
                <select class="form-control" formControlName="subtheme_id" (change)="onSubthemeSelectChange()">
                    <option value="">Selecciona una opción</option>
                    <option *ngFor="let subtheme of subthemes" value="{{subtheme.id}}">{{subtheme.name}}</option>
                </select>
            </div>
            <div class="col-12 col-md-4">
                <span style="color: red">* </span>
                <label>Categoría:</label>
                <select class="form-control" formControlName="category_id">
                    <option value="">Selecciona una opción</option>
                    <option *ngFor="let category of categories" value="{{category.id}}">{{category.name}}</option>
                </select>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-4">
                <label>Descripción:</label>
                <textarea id="description" class="form-control" placeholder="Ingresa una descripción" maxlength="250"
                    formControlName="description"></textarea>
                <app-maxchardec [inputValue]="proposalInitiativeForm.controls['description'].value ?? ''">
                </app-maxchardec>
            </div>
            <div class="col-12 col-md-4">
                <div class="col-md-12">
                    <label>Resolver a petición del titular:</label>
                </div>
                <div class="col-md-12 offset-md-1 checkbox checkbox-css checkbox-inline">
                    <input id="checkboxUrgent" type="checkbox"
                        [checked]="proposalInitiativeForm.get('urgent')?.value === 1"
                        (change)="updateCheckbox($event)" />
                    <label for="checkboxUrgent">Si</label>
                </div>
            </div>
            <div class="col"></div>
        </div>
        <br />
        <div class="row">

            <div class="col">



                <div class="col-md-6 cont-image">
                    <label class="block mb-2 text-sm text-gesco dark" for="multiple_files">Agregar archivo(s) de
                        iniciativa</label>

                    <ng-container>
                        <input class="block w-full text-sm text-gesco border-gesco rounded-lg cursor-pointer"
                            id="multiple_files" type="file" accept=".pdf,.doc" multiple
                            (change)="onFileChange($event,'PDF')">
                    </ng-container>
                    <p class="mt-1 text-sm text-gesco" id="file_input_help">Cargar archivo (PDF).</p>

                    <ng-container *ngFor="let url of AllurlDocument; let i = index">
                        <div class="drop-file">
                            <a (click)="openImage(url)" target="_blank"
                                class="alert alert-drop-file show text-left cursor-pointer">
                                <span class="close text-danger" data-idx="0" data-dismiss="alert"
                                    (click)="removeFile('doc',url)">×</span>
                                <i style="margin-top:-10px;"
                                    class="fa fa-file-image text-purple p-r-5 fa-3x pull-left"></i>
                                {{getFileName(url)}}
                            </a>
                        </div>
                    </ng-container>

                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="col-md-6 cont-image">
                    <label class="block mb-2 text-sm text-gesco dark:text-white" for="file_input">Agregar video de
                        propuesta ciudadana</label>
                    <input class="block w-full text-sm text-gesco border-gesco rounded-lg cursor-pointer"
                        formControlName="urlVideo" type="file" accept="video/*"
                        (change)="onFileChange($event,'video')" />
                    <p class="mt-1 text-sm text-gesco" id="file_input_help">Cargar video.</p>

                    <ng-container *ngFor="let url of AllurlVideo; let i = index">
                        <div class="drop-file">
                            <a (click)="openImage(url)" target="_blank"
                                class="alert alert-drop-file show text-left cursor-pointer">
                                <span class="close text-danger" data-idx="0" data-dismiss="alert"
                                    (click)="removeFile('vid',url)">×</span>
                                <i style="margin-top:-10px;"
                                    class="fa fa-file-image text-purple p-r-5 fa-3x pull-left"></i>
                                Video adjunto {{i}}
                            </a>
                        </div>
                    </ng-container>

                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="col-md-6 cont-image">
                    <label class="block mb-2 text-sm text-gesco dark:text-white" for="file_input">Agregar foto de
                        participación</label>
                    <input class="block w-full text-sm text-gesco border-gesco rounded-lg cursor-pointer"
                        formControlName="urlImage" type="file" accept="image/*"
                        (change)="onFileChange($event,'image')" />
                    <p class="mt-1 text-sm text-gesco" id="file_input_help">Cargar foto.</p>

                    <ng-container *ngFor="let url of AllurlImage; let i = index">
                        <div class="drop-file">
                            <a (click)="openImage(url)" target="_blank"
                                class="alert alert-drop-file show text-left cursor-pointer">
                                <span class="close text-danger" data-idx="0" data-dismiss="alert"
                                    (click)="removeFile('img',url)">×</span>
                                <i style="margin-top:-10px;"
                                    class="fa fa-file-image text-purple p-r-5 fa-3x pull-left"></i>
                                Foto adjunta {{i}}
                            </a>
                        </div>
                    </ng-container>

                </div>
            </div>
        </div>
    </form>
</div>