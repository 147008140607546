import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {Chart} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-complete-data-id-citizen',
  templateUrl: './complete-data-id-citizen.component.html',
  styleUrls: ['./complete-data-id-citizen.component.scss']
})
export class CompleteDataIdCitizenComponent implements OnInit {
  chartTotalRequest: any;
  chartStatusRequest: any;
  constructor(    private activeRoute: ActivatedRoute,
    ) { }

  ngOnInit(): void {
    
    this.activeRoute.params.subscribe(routeParams => {
      localStorage.setItem('token', routeParams['token'])
    });
    this.StatusRequestChart();
    this.totalRequestChart();
  }

  StatusRequestChart(){

    this.chartStatusRequest = new Chart("ChartStatusRequest", {
      type: 'doughnut',

      data: {
        labels:['Datos completos','Datos parcialmente completos', 'Datos incompletos'],
	      datasets: [
          {
            data:[58,142,14],
            backgroundColor: ['#00af50','#ffbf00', '#ff0000'],
          },
        ]
      },
      plugins: [ChartDataLabels],
      options: {
        aspectRatio:1,
        plugins: {
          legend: {
              position: 'bottom'
          },

          datalabels: {
            color: 'white',
            labels: {
              title: {
                font: {
                  weight: 'bold',
                  size: 20
                }
              },

            }
          }

      },
      parsing: {
        key: 'nested.value'
      }

      }
    });
  }
  totalRequestChart(){

    this.chartTotalRequest = new Chart("ChartTotalRequest", {
      type: 'doughnut',
      data: {
        labels:['Datos completos','Datos parcialmente completos', 'Datos incompletos'],
	      datasets: [
          {
            data:[58,142,14],
            backgroundColor: ['#00af50','#ffbf00', '#ff0000'],
          },
        ]
      },
      plugins: [ChartDataLabels],

      options: {

        aspectRatio:1,
        parsing: {
          key: 'nested.value'
        },
        plugins: {

          legend: {
              position: 'bottom'
          },
          datalabels: {
            color: 'white',
            labels: {
              title: {
                font: {
                  weight: 'bold',
                  size: 20
                }
              },

            }
          }


      },

      }
    });
  }

}
